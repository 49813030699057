import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { Store } from '@ngrx/store';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterStateService } from '../../../services/route-state/route-state.service';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.

import _moment from 'moment';

// tslint:disable-next-line:no-duplicate-imports

import { default as _rollupMoment } from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ControlContainer, NgForm } from '@angular/forms';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-bank-certified-cheque',
  templateUrl: './bank-certified-cheque.component.html',
  styleUrls: ['./bank-certified-cheque.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BankCertifiedChequeComponent implements OnInit {
  accounts;
  selectedAccount;
  customerId;
  collectionBranches;
  collectionBranchesData;
  date;
  minDate;
  scheduleDate = moment();
  amount;
  recipientName;
  remark;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Bank Certified Cheque',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.accounts = this.accountsIterator.getPaymentFromAccounts('all');
    this.selectedAccount = this.accounts[0];

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });

    this.serviceRequestService.collectionBranch().subscribe((response) => {
      this.collectionBranchesList(response);
    });

    this.date = this.scheduleDate;
    this.minDate = this.scheduleDate;
  }

  

  collectionBranchChanged(data) {
    this.collectionBranchesData = data;
  }

  accountValueChanged(newValue) {
    this.selectedAccount = newValue;
  }

  dateChanged(event) {
    this.date = event._d;
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      bankCertifiedCheque: {
        amount: {
          amount: this.amount,
          currency: this.selectedAccount.availableBalance.currency,
        },
        collectionBranchRoute: {
          routingNumber: this.collectionBranchesData.code,
        },
        debitAccount: this.selectedAccount,
        debitDate: this.date,
        recipientName: this.recipientName,
        remarks: this.remark,
      },
    };
    this.serviceRequestService.bankCertifiedCheque(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Request Demand Draft',
        collectionBranch: this.collectionBranchesData,
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }

  private collectionBranchesList(data) {
    this.collectionBranches = data.body.branches;
  }
}
