import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { ChangeUsernameRequest } from '../../../services/RegistrationService/data/change-username-request';
import { McaHeader } from '../../../core/data/mca-header';
import { McaResponseType } from '../../../core/data/mca-response-type';
import { ChangeUsernameSuccessModel } from '../../../shared/components/change-username-success/change-username-success.model';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss'],
})
export class UsernameComponent implements OnInit {
  constructor(
    private dataSharing: DataSharingService,
    private router: Router,
    private registrationService: RegistrationService,
  ) {}

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Change Your User Name',
      subTitle: '',
      showBack: false,
      showClose: false,
      steps: false,
      module: 'registration',
    });
  }

  completeChangeUsername(newUsername: string): void {
    this.postService({ newUsername });
    this.dataSharing.setPostService(this.postService.bind(this));
  }

  postService(payload: ChangeUsernameRequest) {
    return this.registrationService.changeUsername(payload).subscribe((response) => {
      this.dataSharing.dataFromFirstStep = { path: '', username: payload.newUsername } as ChangeUsernameSuccessModel;
      if (response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS) {
        this.router.navigate(['/forcedChange/username/usernameChangeSuccess']);
      }
    });
  }
}
