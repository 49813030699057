import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-give-notice',
  templateUrl: './give-notice.component.html',
  styleUrls: ['./give-notice.component.scss']
})
export class GiveNoticeComponent implements OnInit {
  customerId;
  remarks;
  selectedAccount;
  disbursementSelectedAccount;
  noticeAmount;
  noticeAccoutList: Array<any>;
  disbursementAccountList: Array<any>;
  constructor(private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
    ) { }

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Give Notice On Notice Deposit',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
      
    });
    this.noticeAccoutList = this.accountsIterator.filterkeyValType('noticeAccount');
    this.selectedAccount = this.noticeAccoutList[0];
    this.disbursementAccountList = this.accountsIterator.filterAccountsByType();
    this.disbursementSelectedAccount = this.disbursementAccountList[0];
  }

  noticeAccountValueChanged(newValue) {
    this.selectedAccount = newValue;
  }

  disbursementAccountValueChanged(newValue) {
    this.disbursementSelectedAccount = newValue;
  }
  
  submitRequest() {
    const payload = {
      customerId: this.customerId,
      notice: {
        disbursementAccount: {
          number: this.disbursementSelectedAccount.number
        },
        noticeAmount: {
          currency: this.selectedAccount.accountCurrency.currencyCode,
          amount: this.noticeAmount
        },
        noticeDepositAccount: {
          number: this.selectedAccount.number
        },

        remarks: this.remarks
      },
    };
    this.serviceRequestService.giveNoticeOnNoticeDeposit(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Give Notice On Notice Deposit',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
}
