import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { NameInitialsService } from '../../../../core/name-initials/name-initials.service';
import { TransactionTypeService } from '../../../../core/transaction-type/transaction-type.service';
//  import { _ } from 'underscore';

@Component({
  selector: 'sbg-list-table-debitcards',
  templateUrl: './list-table-debitcards.component.html',
  styleUrls: ['./list-table-debitcards.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListTableDebitcardsComponent implements OnInit, AfterViewInit {
 
  @ViewChild('listTableIdentifier') listTableIdentifierEl: ElementRef;

  @Input() debitcard: object[];
  @Input() selectButtonType: string; // is it radio or checkbox
  @Input() nameInitialKey: string; // initial to be displayed in list
  @Input() nameSubText: string; // subtxt to be displayed in list
  @Input() cardNumber: string;
  @Input() debitCardStatus: string;
  @Input() accountNumber: string;
  @Input() selectPromptMessage: string;
  @Input() isInfoIconRequired: boolean;

  @Output() clickInfoFunction = new EventEmitter<any>();
  @Output() goToDebitCardsFunction = new EventEmitter<any>();

  selected = [];
  selectItem;
  onceOffBarWidth;
  panelOpenState = false;
  disableSelectedCard;
  selectedDebitCardsName: string;
  constructor(private nameInitialsService: NameInitialsService, private transactionTypeService: TransactionTypeService) {}

  ngOnInit() {
    this.selectItem = this.selectButtonType === 'checkbox' ? this.selectCheckboxItems : this.selectRadioItems;
  }

  getNameInitials(name): string {
    return this.nameInitialsService.getInitials(name);
  }
  getInfo(debitcards) {
    this.clickInfoFunction.emit(debitcards);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToDebitCards(event) {
    this.goToDebitCardsFunction.emit(this.selected);
  }

  getCardStatus(cardStatus): string {
   const status = this.transactionTypeService.getDebitCardStatus(cardStatus);
    return status;
  }
  setElementWidth() {
    return {
      width: this.onceOffBarWidth,
    };
  }
  ngAfterViewInit() {
    this.onceOffBarWidth = document.getElementById('listTableIdentifier').offsetWidth + 'px';
  }
  private greyOutCard(cardStatus) {
    const status = this.transactionTypeService.getDebitCardStatus(cardStatus);
    if (status === 'Undefined' || status === 'Stopped') {
      this.disableSelectedCard = true;
    } else if (status === 'Temporarily Blocked' || status === 'Active' || status === 'Issued') {
      this.disableSelectedCard = false;
    }
    return this.disableSelectedCard;
  }

  

  private selectCheckboxItems(item) {
    if (this.selected.indexOf(item) !== -1) {
      this.selected.splice(this.selected.indexOf(item), 1);
    } else {
      this.selected.push(item);
    }
    this.getDebitCardsButtonText();
  }

  private isPresentInSelectedList(item) {
    return this.selected.indexOf(item) !== -1;
  }

  private selectRadioItems(item) {
    const inut = document.querySelector('input[type="radio"]:checked') as HTMLInputElement;
    if (this.isPresentInSelectedList(item)) {
      inut.checked = false;
      this.selected = [];
    } else {
      this.selected = [];
      this.selected.push(item);
    }
    this.getDebitCardsButtonText();
  }

  private getDebitCardsButtonText() {
    if (this.selected.length === 0) {
      this.selectedDebitCardsName = 'Stop Card';
    } else {
      this.selectedDebitCardsName = 'Stop Card';
    }
  }
}
