import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';

@Injectable()
export class PaymentLimitService {
  config = environment.config;

  accountsData: any;
  limits: any = {
    dailyWithdrawlLimit: {
      amount: 0,
    },
    usedLimit: { amount: 0 },
    availableDailyLimit: {},
  };

  constructor(private store: Store<any>) {
    this.store.select('appReducer').subscribe((stateData) => {
      this.accountsData = stateData.dashboardReducer.accounts;
    });
  }

  checkLimit(newValue, availableTransferLimit?: any, charges?: any, isNotValidatingAmount?: any, noDailyLimit?: any) {
    const cardProfile = this.accountsData.body.cardProfile;
    if (this.config.showMonthlyTransactionLimit) {
      this.limits.dailyWithdrawlLimit = cardProfile.monthlyWithdrawalLimit;
      this.limits.usedLimit = cardProfile.monthlyUsedLimit;
    } else {
      this.limits.dailyWithdrawlLimit = cardProfile.dailyWithdrawalLimit;
      this.limits.usedLimit = cardProfile.usedEAPLimit;
    }
    if (this.limits.dailyWithdrawlLimit && this.limits.usedLimit && cardProfile.dailyWithdrawalLimit !== '' && cardProfile.usedLimit !== '') {
      this.limits.availableDailyLimit = this.limits.dailyWithdrawlLimit.amount - this.limits.usedLimit.amount;
      this.limits.availableDailyLimit = parseFloat(parseFloat(this.limits.availableDailyLimit).toFixed(2));
    } else {
      this.limits.availableDailyLimit = 'invalid';
    }

    const amountValidator = {
      isAmountValid: true,
      triggerOnDailyWithdrawlLimit: false,
      exeededAvailableBalance: false,
      amountEnteredIsInvalid: false,
    };
    if (parseInt(newValue, 10) === 0) {
      amountValidator.amountEnteredIsInvalid = true;
      amountValidator.isAmountValid = false;
    }
    const appliedCharges = charges || 0;
    const amountWithoutCharge = parseFloat(newValue) - appliedCharges;

    if (this.limits.availableDailyLimit !== 'invalid' && amountWithoutCharge > this.limits.availableDailyLimit && noDailyLimit === undefined) {
      amountValidator.triggerOnDailyWithdrawlLimit = true;
      amountValidator.isAmountValid = false;
    }

    if (parseFloat(newValue) > availableTransferLimit) {
      amountValidator.exeededAvailableBalance = true;
      amountValidator.isAmountValid = false;
    }
    if (isNotValidatingAmount) {
      amountValidator.triggerOnDailyWithdrawlLimit = false;
      amountValidator.exeededAvailableBalance = false;
      amountValidator.isAmountValid = true;
    }
    return amountValidator;
  }
}
