import { ChooseApproverComponent } from './components/choose-approver/choose-approver.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

// import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { DuplicateValidatorDirective } from './directives/duplicate-validator/duplicate-validator.directive';
import { MobileNumberValidatorDirective } from './directives/mobilenumber-validator/mobilenumber-validator.directive';

import { AmountValidatorDirective } from './directives/amount-validator/amount-validator.directive';
import { NumbersOnlyDirective } from './directives/numbers-only/numbers-only.directive';
import { HeaderComponent } from './components/header/header.component';
import { CapitalizeFirstLetterOfWordPipe } from './pipes/capitalize-first-letter/capitalize-first-letter-of-word.pipe';
import { CustomProgressBarComponent } from './components/custom-progress-bar/custom-progress-bar.component';
import { ListFilterComponent } from './components/list-filter/list-filter.component';
import { AccountIconComponent } from './components/account-icon/account-icon.component';

import { SearchFilterPipe } from './pipes/search-filter/search-filter.pipe';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';

import { HeadPaginationComponent } from './components/head-pagination/head-pagination.component';
import { ButtonComponent } from './components/form-elements/buttons/buttons.component';
import { LabelButtonComponent } from './components/form-elements/label-button/label-button.component';
import { NameInitialsComponent } from './components/name-initials/name-initials.component';
import { StatusIconComponent } from './components/status-icon/status-icon.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { ValidationErrorComponent } from './components/form-elements/validation-error/validation-error.component';
import { ListTableComponent } from './components/list-table/list-table/list-table.component';
import { ListTableBuyComponent } from './components/list-table/list-table-buy/list-table-buy.component';
import { ListRecipientTableComponent } from './components/list-table/list-table-recipient/list-table-recipient.component';

import { ListTableDetailComponent } from './components/list-table-detail/list-table-detail.component';
import { TranslatePipe } from './pipes/translate-pipe/translate.pipe';
import { TabsGroupComponent } from './components/tabs-group/tabs-group.component';
import { SchedulePaymentComponent } from './components/schedule-payment/schedule-payment.component';
import { ListTableDebitcardsComponent } from './components/list-table/list-table-debitcards/list-table-debitcards.component';
import { PaymentRepeaterComponent } from './components/payment-repeater/payment-repeater.component';
import { DateFormatPipe } from '../components/account-details/shared/account-transactions-table/date-format.pipe';
import { ListFullFilterComponent } from './components/list-full-filter/list-full-filter.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { StatementDayRangeFilterComponent } from './components/statement-day-range-filter/statement-day-range-filter.component';
import { AccountSummaryDetailsComponent } from './components/account-summary-details/account-summary-details.component';
import { ServiceBlockComponent } from './components/service-block/service-block.component';
import { PaginationBlockComponent } from './components/pagination-block/pagination-block.component';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { SortByPipe } from './pipes/sort/sort-by.pipe';
import { PrintLayoutComponent } from './components/print-layout/print-layout.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CopyrightFooterComponent } from './components/copyright-footer/copyright-footer.component';
import { ReviewHeaderComponent } from './components/review-header/review-header.component';
import { DisplayApprovalRulesComponent } from './components/display-approval-rules/display-approval-rules.component';
import { RemarkModalComponent } from './components/remark-modal/remark-modal.component';
import { ExchangeRatesComponent } from './components/exchange-rates/exchange-rates.component';
import { FromAccountdataComponent } from './components/from-account-data/from-account-data.component';
import { DragDropComponent } from '../components/drag-and-drop/drag-and-drop.component';
import { PrintDownloadComponent } from './components/print-download/print-download.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { RegistrationFooterComponent } from './components/registration-footer/registration-footer.component';
import { ChangeSecurityQuestionsComponent } from './components/change-security-questions/change-security-questions.component';
import { FormNotifyByComponent } from './components/form-notify-by/form-notify-by.component';
import { PaymentAmountComponent } from './components/payment-amount/payment-amount.component';
import { LimitDisplayComponent } from './components/limit-display/limit-display.component';
import { ChangeUsernameComponent } from './components/change-username/change-username.component';
import { ChangeUsernameSuccessComponent } from './components/change-username-success/change-username-success.component';
import { SizeDetectorDirective } from './directives/size-detector/size-detector.directive';
import { MobileMenuComponent } from '../shared/components/mobile-menu/mobile-menu.component';
import { PhonePipe } from './pipes/phone-format/phone.pipe';
import { ReceiptHeadingInfoComponentComponent } from './components/receipt-heading-info/receipt-heading-info.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NumberFormatPipe } from './pipes/number-format/number-format.pipe';
import { PrecisionLimiterDirective } from './directives/presion-limiter/precision-limiter.directive';
import { SpaceRemoverDirective } from './directives/space-remover.directive';
import { DocumentUploadComponent } from '../components/documentUploadFile/documentUpload.component';
import { PrintAllReceiptsComponent } from './components/print-all-receipts/print-all-receipts.component';
import { SpecialCharacterValidationDirective } from './directives/special-character-validator/special-character-validation.directive';
import { BlockCopyPasteDirective } from './directives/block-copypaste/block-copypaste.directive';
import { NewUploadDocumentComponent } from '../components/new-upload-document/new-upload-document.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    // components
    AccountIconComponent,
    HeaderComponent,
    CustomProgressBarComponent,
    ListFilterComponent,
    SubHeaderComponent,
    HeadPaginationComponent,
    ButtonComponent,
    LabelButtonComponent,
    NameInitialsComponent,
    StatusIconComponent,
    DropDownComponent,
    ValidationErrorComponent,
    ListTableComponent,
    ListTableDetailComponent,
    ListTableBuyComponent,
    ListRecipientTableComponent,
    ListTableDebitcardsComponent,
    ChooseApproverComponent,
    DragDropComponent,
    DocumentUploadComponent,
    ReceiptHeadingInfoComponentComponent,
    NewUploadDocumentComponent,
    // pipes
    CapitalizeFirstLetterOfWordPipe,
    SearchFilterPipe,
    PhonePipe,
    // directives
    AmountValidatorDirective,
    NumbersOnlyDirective,
    MobileNumberValidatorDirective,
    SizeDetectorDirective,
    TranslatePipe,
    TabsGroupComponent,
    SchedulePaymentComponent,
    ListTableDebitcardsComponent,
    PaymentRepeaterComponent,
    DateFormatPipe,
    ListFullFilterComponent,
    SearchBoxComponent,
    StatementDayRangeFilterComponent,
    AccountSummaryDetailsComponent,
    ServiceBlockComponent,
    PaginationBlockComponent,
    FilterPipe,
    SortByPipe,
    PrintLayoutComponent,
    ReceiptComponent,
    CopyrightFooterComponent,
    DuplicateValidatorDirective,
    ReviewHeaderComponent,
    DisplayApprovalRulesComponent,
    RemarkModalComponent,
    ExchangeRatesComponent,
    FromAccountdataComponent,
    PrintDownloadComponent,
    ChangePasswordComponent,
    RegistrationFooterComponent,
    ChangeSecurityQuestionsComponent,
    FormNotifyByComponent,
    PaymentAmountComponent,
    LimitDisplayComponent,
    ChangeUsernameComponent,
    ChangeUsernameSuccessComponent,
    MobileMenuComponent,
    NumberFormatPipe,
    PrecisionLimiterDirective,
    SpaceRemoverDirective,
    PrintAllReceiptsComponent,
    SpecialCharacterValidationDirective,
    BlockCopyPasteDirective
  ],
  imports: [
    FlexLayoutModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatExpansionModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    FormsModule,
    CommonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatSliderModule,
    MatMenuModule,
    RouterModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    MatCardModule,
    MatButtonToggleModule,
  ],
  exports: [
    // components
    AccountIconComponent,
    HeaderComponent,
    CustomProgressBarComponent,
    ListFilterComponent,
    SubHeaderComponent,
    HeadPaginationComponent,
    StatusIconComponent,
    ButtonComponent,
    LabelButtonComponent,
    NameInitialsComponent,
    DropDownComponent,
    ValidationErrorComponent,
    ListTableComponent,
    ListTableDetailComponent,
    ListTableDebitcardsComponent,
    ListRecipientTableComponent,
    DragDropComponent,
    DocumentUploadComponent,
    ReceiptHeadingInfoComponentComponent,
    NewUploadDocumentComponent,
    // pipes
    CapitalizeFirstLetterOfWordPipe,
    TranslatePipe,
    SearchFilterPipe,
    PhonePipe,
    // directives
    AmountValidatorDirective,
    NumbersOnlyDirective,
    MobileNumberValidatorDirective,
    SizeDetectorDirective,
    PrecisionLimiterDirective,
    // modules
    MatSliderModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatExpansionModule,
    MatTableModule,
    MatTabsModule,
    MatListModule,
    MatMenuModule,
    MatButtonToggleModule,
    FormsModule,
    CommonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TabsGroupComponent,
    SchedulePaymentComponent,
    MatStepperModule,
    ListTableBuyComponent,
    FlexLayoutModule,
    ListTableDebitcardsComponent,
    PaymentRepeaterComponent,
    DateFormatPipe,
    ListFullFilterComponent,
    SearchBoxComponent,
    StatementDayRangeFilterComponent,
    ChangePasswordComponent,
    RegistrationFooterComponent,
    ChangeSecurityQuestionsComponent,
    AccountSummaryDetailsComponent,
    ServiceBlockComponent,
    PaginationBlockComponent,
    FilterPipe,
    SortByPipe,
    RouterModule,
    ReactiveFormsModule,
    CopyrightFooterComponent,
    ReviewHeaderComponent,
    ChooseApproverComponent,
    DisplayApprovalRulesComponent,
    RemarkModalComponent,
    ExchangeRatesComponent,
    FromAccountdataComponent,
    PrintDownloadComponent,
    FormNotifyByComponent,
    PaymentAmountComponent,
    LimitDisplayComponent,
    ChangeUsernameComponent,
    ChangeUsernameSuccessComponent,
    MobileMenuComponent,
    ReceiptComponent,
    NgxMatSelectSearchModule,
    NumberFormatPipe,
    MatCardModule,
    SpaceRemoverDirective,
    PrintAllReceiptsComponent,
    SpecialCharacterValidationDirective,
    BlockCopyPasteDirective
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
