import { Component, Output, Input, OnChanges, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnChanges {
  @Input() searchVal: any;
  @Input() insideFilter: any;
  @Input() isReset: any;
  @Input() itemsCount: any;
  @Input() filterActiveFlag: any;
  @Output() filterValues: EventEmitter<any> = new EventEmitter();

  searchText = '';
  filterActive = false;
  getFilterValues = {} as any;

 

  ngOnChanges(change: any) {
    if (change.insideFilter) {
      this.searchText = _.cloneDeep(this.searchVal);
    }
    if (change.filterActiveFlag) {
      this.filterActive = this.filterActiveFlag;
      this.getFilterValues.filterActive = this.filterActive;
    }
    if (change.isReset && change.isReset.currentValue) {
      this.searchText = '';
      this.inputValueChanged('');
    }
  }

  inputValueChanged(text: any) {
    this.searchText = text;
    this.getFilterValues.searchText = text;
    this.filterValues.emit(this.getFilterValues);
  }

  // resetTextBox() {
  //   this.toTextBoxActive = false;
  //   this.fromTextBoxActive = false;
  //   this.filterAmountIsInvalid = false;
  // }

  toggleFilteringOption(isFilterRequired: boolean) {
    this.filterActive = isFilterRequired;

    this.getFilterValues.filterActive = this.filterActive;

    this.filterValues.emit(this.getFilterValues);
    // if (isSmallDevice()) {
    //   $scope.revealit = isFilterRequired;
    //   $scope.animatethisUp = isFilterRequired;

    // } else {
    //   if (isFilterRequired) {
    //     $scope.revealit = !isFilterRequired;
    //   }
    // }

    // if (!isFilterRequired) {
    //   this.resetTextBox();
    // }
  }
}
