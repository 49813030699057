import { ServiceEndpointsService } from './../../../core/service-endpoints/service-endpoints.service';
import { SelectedOffer, GetOffer, Mvp2UplOfferDetails, Mvp2UplFreeInsurance, Mvp2UplRedrawLoadPayload } from './../actions/offers.action';
import { AuthenticateService } from './../../../core/authenticate-service/authenticate-service.service';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { OfferInterface } from '../interfaces/offer';
import { Injectable } from '@angular/core';
export class OfferStateModel {
  offers: OfferInterface[];
  selectOffer: OfferInterface;
  mvp2UplOfferDetails: any;
  mvp2UplFreeInsurance: any;
  mvp2UplRedrawLoadPayload: any;
}
@Injectable()
@State<OfferStateModel>({
  name: 'offers',
  defaults: {
    offers: [],
    selectOffer: null,
    mvp2UplOfferDetails: null,
    mvp2UplFreeInsurance: null,
    mvp2UplRedrawLoadPayload: null,
  },
})
export class OfferState {
  constructor(private authenticateService: AuthenticateService, private serviceEndPoint: ServiceEndpointsService) {}
  @Selector()
  static getOfferList(state: OfferStateModel) {
    return state.offers;
  }
  @Selector()
  static getSelectedOffer(state: OfferStateModel) {
    return state.selectOffer;
  }
  @Selector()
  static getMvp2UplOfferDetails(state: OfferStateModel) {
    return state.mvp2UplOfferDetails;
  }
  @Selector()
  static mvp2UplFreeInsurance(state: OfferStateModel) {
    return state.mvp2UplFreeInsurance;
  }
  @Selector()
  static mvp2UplRedrawLoadPayload(state: OfferStateModel) {
    return state.mvp2UplRedrawLoadPayload;
  }
  @Action(GetOffer)
  addOffer({ getState, setState }: StateContext<OfferStateModel>) {
    return this.authenticateService.fetchOffer().pipe(
      tap((result) => {
        const data = this.serviceEndPoint.parseDataToParser(result);
        const state = getState();
        setState({
          ...state,
          offers: data,
        });
      })
    );
  }
  @Action(SelectedOffer)
  setSelectedOffer({ getState, setState }: StateContext<OfferStateModel>, { payload }: SelectedOffer) {
    const state = getState();
    setState({
      ...state,
      selectOffer: payload,
    });
  }
  @Action(Mvp2UplOfferDetails)
  setUplDetailsOffer({ getState, setState }: StateContext<OfferStateModel>, { payload }: Mvp2UplOfferDetails) {
    const state = getState();
    setState({
      ...state,
      mvp2UplOfferDetails: payload,
    });
  }
  @Action(Mvp2UplFreeInsurance)
  setmvp2UplFreeInsurance({ getState, setState }: StateContext<OfferStateModel>, { payload }: Mvp2UplFreeInsurance) {
    const state = getState();
    setState({
      ...state,
      mvp2UplFreeInsurance: payload,
    });
  }
  @Action(Mvp2UplRedrawLoadPayload)
  setmvp2UplRedrawLoadPayload({ getState, setState }: StateContext<OfferStateModel>, { payload }: Mvp2UplRedrawLoadPayload) {
    const state = getState();
    setState({
      ...state,
      mvp2UplRedrawLoadPayload: payload,
    });
  }
}
