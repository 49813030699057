import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Component, OnInit, Inject } from '@angular/core';
import { environment } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sbg-need-help',
  styleUrls: ['./needhelp.component.scss'],
  templateUrl: '../templates/ModelNeedHelp.html',
})
export class NeedHelpComponent implements OnInit {
  config = environment.config;

  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;

  title = ' need help template';
  help = false;
  newAccount = false;
  preLoginSmeMode = this.document['preLoginMode'] === 'SME';
  
  constructor(private dataShare: DataSharingService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit() {
    if (this.preLoginSmeMode) {
      this.contactNumber = this.config.localSMEcontactNumber;
      this.mailId = this.config.localSMEEmail;
    }

    if (this.dataShare.modelDetails === 'help') {
      this.help = true;
    } else if (this.dataShare.modelDetails === 'newAccount') {
      this.newAccount = true;
    }
  }
}
