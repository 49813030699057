import { environment } from '../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
@Component({
  selector: 'sbg-review-header',
  templateUrl: './review-header.component.html',
  styleUrls: ['./review-header.component.scss'],
})
export class ReviewHeaderComponent implements OnInit, OnChanges {
  @Input() dataModel: any;
  @Input() hideDetails: boolean;
  @Input() showToggle: boolean;
  @Input() showEdit: boolean;
  @Output() expandIconClicked = new EventEmitter<any>();
  @Output() editIconClicked = new EventEmitter<any>();
  config = environment.config;
  toggleStatus = 'open';
  toggleIcon = 'keyboard_arrow_up';
  model = {} as any;
  initials;


  ngOnInit() {

    if (
      (this.dataModel.componentName && this.dataModel.componentName === 'DetailsBiller') ||
      this.dataModel.componentName === 'StopDebitCardDetail'
    ) {
      this.initials = this.dataModel.first || this.dataModel.nameInit;
    } else if(this.dataModel.componentName === "unayo") {
      this.initials = "Unayo Payment";
    } else {
      this.initials = this.dataModel.name || this.dataModel.nameInit;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataModel) {
      this.model = this.dataModel;
    }
  }

  toggleIconClicked() {
    if (this.toggleStatus === 'close') {
      this.toggleStatus = 'open';
      this.toggleIcon = 'keyboard_arrow_up';
    } else {
      this.toggleStatus = 'close';
      this.toggleIcon = 'keyboard_arrow_down';
    }
    this.expandIconClicked.emit(this.toggleStatus);
  }

  editClicked() {
    this.editIconClicked.emit(this.toggleStatus);
  }
}
