import { Injectable } from '@angular/core';
import { FinancialRule } from '@core/data/financial-rule';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { TvModel } from '../../components/buy/prepaid-models.module';
import { TaxPaymentModel } from '../../components/payments/tax-payment/tax-payment.model';
import { VasServiceInputValidationRequest } from '../prepaid-service/data/vas-service-input-validation-request';
import { RecipientManagementRequest } from '../recipient-service/data/recipient-management-request';
import { UuidGeneratorService } from '../UUID-generator/uuid-generator.service';
import { DataSharingService } from './../data-sharing/data-sharing.service';

@Injectable()
export class CreatePayloadService {
  loginStateData;
  config = environment.config;

  constructor(private uuidGenerator: UuidGeneratorService, private store: Store<any>, private dataSharingService: DataSharingService) {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      this.loginStateData = stateData;
    });
  }

  instantMoney(data) {
    const countryCode = this.config.mobileCountryCode;
    const trimmedCountryCode = countryCode.substr(1);
    const instantMoneyPayload: any = {
      account: data.selectedFromAccount,
      transactions: {
        instantMoneyPayments: [
          {
            mobileNumber: trimmedCountryCode + data.mobileNumber,
            purpose: data.purposeType,
            amount: {
              currency: data.selectedFromAccount.availableBalance.currency,
              amount: data.amount,
            },
            transactionId: this.uuidGenerator.getUUID(),
          },
        ],
      },
    };
    if(data.sendPin) {
      instantMoneyPayload.transactions.instantMoneyPayments[0].sendPin=data.sendPin;
    }

    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      instantMoneyPayload.financialRule = this.dataSharingService.selectedApprover;
    }

    if (!this.config.purposeOfFunds) {
      delete instantMoneyPayload.transactions.instantMoneyPayments[0].purpose;
    }
    return instantMoneyPayload;
  }

  getInstantBulkFileUploadData(modalData) {
    const instantBulkFileUploadPayload: any = {
      document: {
        name:
          modalData.fileDetail.fileName +
          modalData.fileDetail.fileType.slice(modalData.fileDetail.fileType.length - 4, modalData.fileDetail.fileType.length),
      },
      processingDate: modalData.currentDate,
      totalAmount: {
        amount: modalData.fileDetail.totalTransferAmount,
        currency: modalData.selectedFromAccount.accountCurrency.currency,
      },
      account: modalData.selectedFromAccount,
      bulkInstantMoney: modalData.fileDetail.fileContent,
    };

    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      instantBulkFileUploadPayload.financialRule = this.dataSharingService.selectedApprover;
    }

    return instantBulkFileUploadPayload;
  }

  getOnceOffPaymentPayload(data): object {
    const payload = {
      account: data.selectedAccount,
      transactions: {
        onceOffPayments: [
          {
            transactionId: this.uuidGenerator.getUUID(),
            amount: {
              amount: data.amount,
              currency: data.selectedAccount.availableBalance.currency,
            },
            futureDatedInstruction: {
              fromDate: new Date(),
              repeatInterval: 'SINGLE',
              repeatNumber: 1,
            },
            beneficiary: {
              name: data.benefName,
              accountNumber: data.accountNumber,
              route: {
                branch: data.selectedBranch,
                networkType: data.selectedRoute.networkType,
                routingNumber: data.selectedRoute.routingNumber,
              },
              favourite: false,
              customerReference: data.yourReference,
              recipientReference: data.theirReference,
              paymentConfirmation: data.paymentConfirmation,
            },
          },
        ],
      },
    };
    const beneficiaryDetails: any = payload.transactions.onceOffPayments[0].beneficiary;
    beneficiaryDetails.route.branch.bank = data.selectedBank;
    if (data.benefAddress) {
      beneficiaryDetails.address = {
        addressLine1: data.benefAddress,
      };
    }
    return payload;
  }

  getEditBiller(billerData, isBillerCDI) {
    let dataToReview;
    if (isBillerCDI) {
      dataToReview = [
        {
          accountCurrency: billerData.accountCurrency,
          accountNumber: billerData.accountNumber,
          nickName: billerData.detailsNickName,
          payeeId: billerData.payeeId,
          recentPayment: billerData.recentPayment,
          recipientReferenceLength: 25,
          customerReference: billerData.detailsMyReference,
          keyValueMetadata: [],
          name: billerData.name,
          paymentConfirmation: billerData.paymentConfirmation,
          recipientGroup: {
            keyValueMetadata: [],
            name: billerData.detailsGroup.name,
            oldName: billerData.detailsGroup.oldName,
            orderIndex: billerData.detailsGroup.orderIndex,
          },
          recipientId: billerData.recipientId,
          recipientReference: billerData.recipientReference,
        },
      ];
    } else {
      dataToReview = {
        billers: [
          {
            category: billerData.category,
            categoryId: billerData.category.categoryId,
            roaBillerId: billerData.roaBillerId,
            name: billerData.name,
            userRef: billerData.detailsMyReference,
            nickName: billerData.nickName,
            products: billerData.products,
            group: billerData.detailsGroup,
            customFields: billerData.customFields,
            recentPayments: billerData.recentPayments,
            upcomingPayments: billerData.upcomingPayments,
          },
        ],
      };
    }
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      dataToReview = { ...dataToReview, ...rule };
    }
    return dataToReview;
  }

  getAddBiller(billerData, isBillerCDI) {
    let dataToReview;
    if (isBillerCDI) {
      dataToReview = {
        recipients: [
          {
            accountCurrency: billerData.accountCurrency,
            accountNumber: billerData.accountNumber,
            nickName: billerData.detailsNickName,
            payeeId: billerData.payeeId,
            recentPayment: billerData.recentPayment,
            recipientReferenceLength: 25,
            customerReference: billerData.detailsMyReference,
            keyValueMetadata: [],
            name: billerData.name,
            paymentConfirmation: billerData.paymentConfirmation,
            recipientGroup: {
              keyValueMetadata: [],
              name: billerData.detailsGroup.name,
              oldName: billerData.detailsGroup.oldName,
              orderIndex: billerData.detailsGroup.orderIndex,
            },
            recipientId: billerData.recipientId,
            recipientReference: billerData.recipientReference,
          },
        ],
      };
    } else {
      dataToReview = {
        billers: [
          {
            category: billerData.category,
            roaBillerId: billerData.roaBillerId,
            name: billerData.name,
            userRef: billerData.detailsMyReference,
            nickName: billerData.nickName,
            products: billerData.products,
            group: billerData.detailsGroup,
            customFields: billerData.customFields,
            recentPayments: billerData.recentPayments,
            upcomingPayments: billerData.upcomingPayments,
          },
        ],
      };
    }
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      dataToReview = { ...dataToReview, ...rule };
    }

    return dataToReview;
  }

  getPayBeneficiaryPayload(data): object {
    const payload = {
      account: data.selectedFromAccount,
      transactions: {
        beneficiaryPayments: [],
      },
    };

    const selectedUserDetailsCopy = JSON.parse(JSON.stringify(data.selectedUserDetails));

    for (let i = 0; i < selectedUserDetailsCopy.length; i++) {
      const currentUser = selectedUserDetailsCopy[i];
      currentUser.paymentConfirmation = data.paymentConfirmation;
      currentUser.customerReference = data.yourReference;
      currentUser.recipientReference = data.theirReference;
      currentUser.route.networkType = data.selectedRoute.networkType;
      currentUser.route.routingNumber = data.selectedRoute.routingNumber;
      if (data.benefAddress) {
        currentUser.address = {
          addressLine1: data.benefAddress,
        };
      }
      payload.transactions.beneficiaryPayments[i] = {
        beneficiary: currentUser,
        futureDatedInstruction: {
          repeatInterval: 0,
          repeatNumber: 0,
          fromDate: new Date(),
        },
        transactionId: this.uuidGenerator.getUUID(),
        amount: data.amount,
      };
    }
    return payload;
  }

  getSelfFundPayload(modelData) {
    const selfFundPayload = {
      account: modelData.selectedFromAccount,
      transactions: {
        transfers: [
          {
            customerReference: modelData.reference,
            transactionId: this.uuidGenerator.getUUID(),
            amount: {
              currency: modelData.selectedFromAccount.availableBalance.currency,
              amount: modelData.amount,
            },
            futureDatedInstruction: modelData.paymentScheduleFrequency,
            toAccount: modelData.selectedToAccount,
          },
        ],
      },
    };
    return selfFundPayload;
  }

  getAddBeneficiary(data, model) {
    let payload = {
      beneficiaries: [
        {
          recentPayment: [],
          name: model.benefName,
          accountNumber: model.accountNumber,
          recipientGroup: model.selectBeneGroup,
          customerReference: model.myReference,
          emailAddress: model.emailAddress,
          phoneNumber: data.payeeAddress,
          keyValueMetadata: [],
          favourite: false,
          recipientReference: model.theirReference,
          route: {
            branch: {
              address: model.selectedBranch.address,
              bank: model.selectedBank,
              code: model.selectedBranch.code,
              name: model.selectedBranch.name,
            },
            networkType: model.firstRoutes.networkType,
            routingNumber: model.firstRoutes.routingNumber,
          },
        },
      ],
      keyValueMetadata: [],
    };
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      payload = { ...payload, ...rule };
    }

    if (data.isOutsideBank) {
      // payload.beneficiaries[0].accountHolderFullName = data.accHolderName;
    }

    if (model.beneficiaryNickname) {
      // dataToReview.beneficiaries[0].nickName = addBeneficiaryModel.beneficiaryNickname;
    }
    if (this.dataSharingService.selectedApprover) {
      payload = { ...payload, ...{ nonFinancialRule: this.dataSharingService.selectedApprover } };
      // .nonFinancialRule = this.dataSharingService.selectedApprover;
    }

    return payload;
  }

  getAddCMABeneficiary(data, model) {
    let payload = {
      beneficiaries: [
        {
          // recentPayment: [],
          name: model.benefName,
          surname: model.benefSurName,
          accountNumber: model.accountNumber,
          recipientGroup: model.selectBeneCMAGroup,
          customerReference: model.myReference,
          emailAddress: model.emailAddress,
          phoneNumber: data.payeeAddress,
          address: {
            streetName: model.street,
            city: model.city,
            town: model.town,
          },
          keyValueMetadata: [],
          favourite: false,
          recipientReference: model.theirReference,
          route: {
            branch: {
              bank: {
                code: model.selectedBank.code,
                name: model.selectedBank.name,
                country: {
                  code: model.country.code,
                },
              },
              code: model.selectedBranch.code,
              name: model.selectedBranch.name,
            },
            networkType: model.routes.networkType,
            routingNumber: model.routes.routingNumber,
          },
          cmaBeneficiaryType: model.beneType,
        },
      ],
      keyValueMetadata: [],
    };

    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = { nonFinancialRule: this.dataSharingService.selectedApprover };
      payload = { ...payload, ...rule };
    }
    return payload;
  }

  getEditBeneficiary(data, model) {
    let payload = {
      beneficiaries: [
        {
          accountCurrency: model.accountCurrency,
          accountHolderFullName: model.accountHolderFullName,
          recentPayment: model.recentPayment,
          name: model.name,
          accountNumber: model.accountNumber,
          recipientGroup: model.selectedBeneGroup,
          customerReference: model.customerReference,
          emailAddress: model.emailAddress,
          phoneNumber: data.payeeAddress,
          keyValueMetadata: model.keyValueMetadata,
          favourite: model.favourite,
          recipientId: model.recipientId,
          payeeId: model.payeeId,
          recipientReference: model.recipientReference,
          paymentConfirmation: model.paymentConfirmation,
          route: model.route,
        },
      ],
    };
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = { nonFinancialRule: this.dataSharingService.selectedApprover };
      payload = { ...payload, ...rule };
    }
    return payload;
  }

  getEditCMABeneficiary(data, model) {
    const payload = {
      beneficiaries: [
        {
          accountCurrency: model.accountCurrency,
          accountHolderFullName: model.accountHolderFullName,
          recentPayment: model.recentPayment,
          name: model.name,
          address: model.address,
          accountNumber: model.accountNumber,
          recipientGroup: model.selectedCMABeneGroup,
          customerReference: model.customerReference,
          emailAddress: model.emailAddress,
          phoneNumber: data.payeeAddress,
          keyValueMetadata: model.keyValueMetadata,
          favourite: model.favourite,
          recipientId: model.recipientId,
          payeeId: model.payeeId,
          recipientReference: model.recipientReference,
          paymentConfirmation: model.paymentConfirmation,
          route: model.route,
        },
      ],
      keyValueMetadata: [],
    };
    return payload;
  }

  getDeleteBeneficiary(data): RecipientManagementRequest {
    return {
      beneficiaries: [data],
    };
  }

  getDeleteCMABeneficiary(data) {
    let selectedApproval = {};
    const dataToReview: any = {
      beneficiaries: [data],
    };
    // SME financial rule
    if (data.selectedApprover) {
      selectedApproval = { ...data.selectedApprover };
      dataToReview.nonFinancialRule = selectedApproval;
    }
    return dataToReview;
  }

  getPayOneOffBillerPayload(model) {
    const payload = {
      account: model.selectedFromAccount,
      transactions: {
        onceOffCdiPayments: [
          {
            recipient: {
              recentPayment: model.selectedBiller.recentPayment,
              customerReference: model.myReference,
              keyValueMetadata: [],
              name: model.selectedBiller.name,
              paymentConfirmation: model.selectedBiller.paymentConfirmation,
              recipientGroup: model.selectedBiller.recipientGroup,
              recipientId: model.selectedBiller.recipientId,
              recipientReference: model.theirReference,
            },
            futureDatedInstruction: {
              repeatInterval: 'SINGLE',
              repeatNumber: 1,
              fromDate: new Date(),
            },
            transactionId: this.uuidGenerator.getUUID(),
            amount: {
              currency: model.selectedFromAccount.accountCurrency.currencyCode,
              amount: model.amount,
            },
          },
        ],
      },
    };
    return payload;
  }

  getBuyAirtimePayload(data, type) {
    const prepaidProviderId = data.selectedProvider.prepaidProviderId;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const selectedProduct = data.selectedProductModel || {};
    const providerName = data.selectedProvider.friendlyName;

    let airtimePayload: any = {
      account: data.selectedAccount,
      transactions: {
        prepaidPurchases: [
          {
            transactionId: this.uuidGenerator.getUUID(),
            basePrepaidProvider: {
              friendlyName: providerName,
              prepaidProviderId: prepaidProviderId.toString(),
              prepaidType: type,
            },
            paymentConfirmation: {
              notifications: [
                {
                  address: data.paymentConfirmation.notifications[0].address,
                  type: data.paymentConfirmation.notifications[0].type,
                },
              ],
            },
            productCode: '',
            rechargeNumber: data.rechargeNumber,
            amount: {
              amount: data.amount.amount,
              currency: data.selectedAccount.availableBalance.currency,
            },
            autoSave: false,
          },
        ],
      },
    };
    if (data.selectedVoucher) {
      airtimePayload.transactions.prepaidPurchases[0].voucher = {
        amount: {
          amount: data.selectedVoucher.voucherPreset[0].amount.amount,
          currency: data.selectedVoucher.voucherPreset[0].amount.currency,
        },
        period: data.selectedVoucher.voucherPreset[0].period,
        voucherId: data.selectedVoucher.voucherPreset[0].voucherId,
        voucherType: data.selectedVoucher.voucherType,
      };
    }

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;
      if (this.config.isAllowedMultipleVasPaymentsForSme) {
        airtimePayload.transactions.prepaidPurchases = [
          ...airtimePayload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        airtimePayload = { ...airtimePayload, financialRule: financialRule };
      }
    }

    return airtimePayload;
  }

  getBuyAirtime(model) {
    const selectedProduct = model.selectedProduct || {};
    let airtimePayload: any = {
      account: model.selectedAccount,
      transactions: {
        prepaidPurchases: [
          {
            transactionId: this.uuidGenerator.getUUID(),
            basePrepaidProvider: {
              friendlyName: model.basePrepaidProvider.friendlyName,
              prepaidProviderId: model.basePrepaidProvider.prepaidProviderId,
              prepaidType: model.basePrepaidProvider.prepaidType,
            },
            productCode: selectedProduct.code || selectedProduct.productCode || '',
            rechargeNumber: model.rechargeNumber.replace(/ /g, ''),
            autoSave: false,
            amount: model.amount,
            paymentConfirmation: model.paymentConfirmation,
          },
        ],
      },
    };

    if (model.selectedVoucher) {
      airtimePayload.transactions.prepaidPurchases[0].voucher = {
        amount: {
          amount: model.selectedVoucher.voucherPreset[0].amount.amount,
          currency: model.selectedVoucher.voucherPreset[0].amount.currency,
        },
        period: model.selectedVoucher.voucherPreset[0].period,
        voucherId: model.selectedVoucher.voucherPreset[0].voucherId,
        voucherType: model.selectedVoucher.voucherType,
      };
    }

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;
      if (this.config.isAllowedMultipleVasPaymentsForSme) {
        airtimePayload.transactions.prepaidPurchases = [
          ...airtimePayload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        airtimePayload = { ...airtimePayload, financialRule: financialRule };
      }
    }

    // Special UseCase : SZ specific manual change for MTN Bundles Payload
    if (model.basePrepaidProvider.friendlyName === 'MTN' && this.config.countryName === 'Swaziland' && model.bundles && model.selectedVoucher) {
      airtimePayload.transactions.prepaidPurchases[0].basePrepaidProvider.prepaidProviderId = '39';
      airtimePayload.transactions.prepaidPurchases[0].basePrepaidProvider.prepaidType = 'DATA';
    }
    return airtimePayload;
  }

  getSelfFundTransfer(model) {
    const oftenSchedule = this.getOftenScheduleData(model.oftenSchedule);
    let selfFundPayload = {
      account: model.selectedFromAccount,
      transactions: {
        transfers: [
          {
            customerReference: model.reference,
            transactionId: this.uuidGenerator.getUUID(),
            amount: {
              currency: model.selectedFromAccount ? model.selectedFromAccount.availableBalance.currency : '',
              amount: model.amount,
            },
            futureDatedInstruction: {
              repeatInterval: oftenSchedule,
              repeatNumber: model.numberOfPayments,
              fromDate: model.scheduleDate,
            },
            toAccount: model.selectedToAccount,
          },
        ],
      },
    };
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = { financialRule: this.dataSharingService.selectedApprover };
      selfFundPayload = { ...selfFundPayload, ...rule };
    }
    const moneyTransferTaxCode = {moneyTransferTaxCode : model.moneyTransferTaxCode};
    if(this.config.countryName === 'Zimbabwe'){
      selfFundPayload.transactions.transfers[0] = {...selfFundPayload.transactions.transfers[0], ...moneyTransferTaxCode}
    }
    return selfFundPayload;
  }

  getUnayoPayment(model) {
    // const oftenSchedule = this.getOftenScheduleData(model.oftenSchedule);
    let unayoPayload = {
      account: model.selectedFromAccount,
      transactions: {
        prepaidPurchases: [
          {
            amount: {
              amount: model.amount,
              currency: model.selectedFromAccount.accountCurrency.currency,
            },
            mobileNumber: '232323',
            basePrepaidProvider: {
              friendlyName: 'UNAYO',
              prepaidProviderId: '90',
              prepaidType: 'UNAYO',
            },
            rechargeNumber: model.rechargeNumber,
            customerReference: model.customerReference,
            recipientReference: model.recipientReference,
            transactionId: this.uuidGenerator.getUUID(),
            autoSave: false,
          },
        ],
      },
    };
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;
      if (this.config.isAllowedMultipleVasPaymentsForSme) {
        unayoPayload.transactions.prepaidPurchases = [
          ...unayoPayload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        unayoPayload = { ...unayoPayload, ...{ financialRule: financialRule } };
      }
    }
    return unayoPayload;
  }

  getTransactionTaxPaymentPayload(data) {
    const currency = data.selectedFromAccount.accountCurrency.currency;
    const payload = {
      account: data.selectedFromAccount,
      transactions: {
        prepaidPurchases: [
          {
            transactionId: this.uuidGenerator.getUUID(),
            amount: {
              currency,
              amount: data.taxPaymentAmount.amount,
            },
            basePrepaidProvider: {
              friendlyName: data.providerDetails[currency].friendlyName,
              prepaidProviderId: data.providerDetails[currency].prepaidProviderId.toString(),
              prepaidType: 'TAXES',
            },
            taxDetails: this.getValidTaxPaymentInputData(data).taxDetails,
          },
        ],
      },
    };
    return payload;
  }

  getValidTaxPaymentInputData(data: TaxPaymentModel) {
    const taxMode = data.taxMode;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const taxType = data.selectedTaxType;
    const currency = data.selectedFromAccount.accountCurrency.currency;
    const ValidateVasInputPayload: VasServiceInputValidationRequest = {
      basePrepaidProvider: {
        friendlyName: data.providerDetails[currency].friendlyName,
        prepaidProviderId: Number(data.providerDetails[currency].prepaidProviderId),
        prepaidType: 'TAXES',
      },
      taxDetails: {
        mode: taxMode === 'bpn' ? 'BP' : 'AS',
        account: data.zimraTaxAccounts[currency],
        taxOfficeCode: data.selectedTaxOfficeCode,
        region: data.region,
      },
    };
    // adding business Partner number or Assessment Number
    switch (taxMode) {
      case 'bpn':
        ValidateVasInputPayload.taxDetails.businessPartnerNumber = data.businessPartnerNumber;
        ValidateVasInputPayload.taxDetails.taxCode = data.selectedTaxCode.taxCode;
        ValidateVasInputPayload.taxDetails.businessPartnerName = data.businessPartnerName;
        break;
      case 'an':
        ValidateVasInputPayload.taxDetails.assessmentNumber = data.assessmentNumber;
        ValidateVasInputPayload.taxDetails.assessmentYear = data.selectAssessmentYear.toString();
    }
    return ValidateVasInputPayload;
  }

  getOftenScheduleData(oftenSchedule) {
    if (oftenSchedule.value === 'SINGLE' || oftenSchedule.value === 'Just Once') {
      return 'SINGLE';
    } else {
      return oftenSchedule.value.toUpperCase();
    }
  }

  getBulkFileUploadData(modalData) {
    let bulkFileUploadPayload = {
      document: {
        description: modalData.fileDetail.fileName,
        path: '',
        name: modalData.fileDetail.fileName,
      },
      processingDate: modalData.paymentDate,
      totalAmount: {
        amount: modalData.fileDetail.totalTransferAmount,
        currency: modalData.selectedFromAccount.accountCurrency.currency,
      },
      account: modalData.selectedFromAccount,
    };
    if (modalData.moneyTransferTaxCharge) {
      const moneyTransferTaxCharge = { moneyTransferTaxCharge: modalData.moneyTransferTaxCharge === 'Yes' };
      bulkFileUploadPayload = { ...bulkFileUploadPayload, ...moneyTransferTaxCharge };
    }
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = { financialRule: this.dataSharingService.selectedApprover };
      bulkFileUploadPayload = { ...bulkFileUploadPayload, ...rule };
    }
    return bulkFileUploadPayload;
  }

  getBuyTVSubscription(model: TvModel) {
    let tvSubPayload = {
      account: model.selectedAccount,
      transactions: {
        prepaidPurchases: [model.payload],
      },
    };
    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;
      if (this.config.isAllowedMultipleVasPaymentsForSme) {
        tvSubPayload.transactions.prepaidPurchases = [
          ...tvSubPayload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        tvSubPayload = { ...tvSubPayload, ...{ financialRule: financialRule } };
      }
    }

    return tvSubPayload;
  }

  getBuyElectricity(model) {
    const selectedProduct = model.selectedProduct || {};
    let electricityPlayload = {
      account: model.selectedAccount,
      transactions: {
        prepaidPurchases: [
          {
            transactionId: this.uuidGenerator.getUUID(),
            basePrepaidProvider: model.basePrepaidProvider || model.selectedProvider,
            productCode: selectedProduct.code || selectedProduct.productCode || '',
            rechargeNumber: model.rechargeNumber.replace(/ /g, ''),
            autoSave: false,
            customerName: model.customerName,
            amount: model.amount,
            paymentConfirmation: model.paymentConfirmation,
          },
        ],
      },
    };

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;
      if (this.config.isAllowedMultipleVasPaymentsForSme) {
        electricityPlayload.transactions.prepaidPurchases = [
          ...electricityPlayload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        electricityPlayload = { ...electricityPlayload, ...{ financialRule: financialRule } };
      }
    }

    return electricityPlayload;
  }

  getOpenCallDepositAccount(model) {
    let payload = {
      customerId: this.loginStateData.authenticateResponse.userProfile.customerId,
      currency: model.currency,
      statementNotificationMethod: model.statementNotificationMethod,
    };

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      delete payload.currency;
      delete payload.customerId;

      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      payload = { ...payload, ...rule };
    }
    return payload;
  }

  getOpenPureSaveAccount(model) {
    let payload = {
      customerId: this.loginStateData.authenticateResponse.userProfile.customerId,
      currency: model.currency,
      statementNotificationMethod: {
        key: model.statementNotificationMethod.key,
        value: model.statementNotificationMethod.value,
      },
    };

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      delete payload.currency;
      delete payload.customerId;

      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      payload = { ...payload, ...rule };
    }
    return payload;
  }

  getOpenFixedDeposityAccount(model) {
    let payload = {
      customerId: this.loginStateData.authenticateResponse.userProfile.customerId,
      depositAmount: {
        currency: model.debitAccount.accountCurrency.currency,
        amount: parseFloat(model.depositAmount.amount).toFixed(2),
      },
      depositPeriod: model.depositPeriod,
      autoRenewal: model.autoRenewal,
      renewalOption: model.renewalOption,
      statementNotificationMethod: model.statementNotificationMethod,
      debitAccount: {
        number: model.debitAccount.number,
      },
      repayAccount: {
        number: model.repayAccount.number,
      },
      interestCreditAccount: {
        number: model.interestCreditAccount.number,
      },
    };

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      payload = { ...payload, ...rule };
    }

    return payload;
  }

  getOpenBonusInvestmentAccount(model) {
    return {
      openBonusInvestmentAccountData: {
        debitAccount: {
          number: model.debitAccount.number,
        },
        depositAmount: {
          amount: parseFloat(model.depositAmount.amount).toFixed(2),
          currency: model.debitAccount.accountCurrency.currency,
        },
        depositPeriod: model.depositPeriod,
        maturityDate: model.maturityDate,
        autoRenewal: model.autoRenewal,
        renewalOption: model.renewalOption,
        repayInitialFundsAccount: {
          number: model.repayInitialFundsAccount.number,
        },
        repayInterestAccount: {
          number: model.repayInterestAccount.number,
        },
      },
      customerId: this.loginStateData.authenticateResponse.userProfile.customerId,
      statementNotificationMethod: model.statementNotificationMethod,
    };
  }

  getOpenSibekeloAccount(model) {
    return {
      customerId: this.loginStateData.authenticateResponse.userProfile.customerId,
      depositAmount: {
        amount: model.depositAmount.amount,
        currency: model.debitAccount.accountCurrency.currency,
      },
      instalmentAmount: {
        amount: model.instalmentAmount.amount,
        currency: model.debitAccount.accountCurrency.currency,
      },
      depositPeriod: model.depositPeriod,
      debitAccount: {
        number: model.debitAccount.number,
      },
      repayAccount: {
        number: model.repayAccount.number,
      },
      statementNotificationMethod: model.statementNotificationMethod,
    };
  }
  getQrPayment(reviewData) {
    const transactionPayload = {
      account: reviewData.qrDetails.selectedAccount,
      transactions: {
        ghipssQrPayments: [
          {
            amount: reviewData.qrDetails.amount,
            transactionId: this.uuidGenerator.getUUID(),
            name: reviewData.validateQrDetails.name,
            accountNumber: reviewData.validateQrDetails.accountNumber,
            terminalId: reviewData.validateQrDetails.terminalId,
            qrString: reviewData.validateQrDetails.qrString,
            merchantType: reviewData.validateQrDetails.merchantType,
            customerReference: reviewData.qrDetails.myReference,
          },
        ],
      },
    };
    return transactionPayload;
  }

  getContractSaveAccount(model) {
    return {
      customerId: this.loginStateData.authenticateResponse.userProfile.customerId,
      depositAmount: {
        amount: model.depositAmount.amount,
        currency: model.debitAccount.accountCurrency.currency,
      },
      instalmentAmount: {
        amount: model.instalmentAmount.amount,
        currency: model.debitAccount.accountCurrency.currency,
      },
      depositPeriod: model.depositPeriod,
      debitAccount: {
        number: model.debitAccount.number,
      },
      repayAccount: {
        number: model.repayAccount.number,
      },
      statementNotificationMethod: model.statementNotificationMethod,
    };
  }

  getCurrentAccount(model) {
    let payload = {
      openCurrentAccountData: {
        accountType: model.accountType,
      },
      statementNotificationMethod: model.statementNotificationMethod,
    };

    // if approval is required
    if (this.loginStateData.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = {
        nonFinancialRule: this.dataSharingService.selectedApprover,
      };
      payload = { ...payload, ...rule };
    }
    return payload;
  }

  getIndicativeReinvestmentPayload(previousReinvestDetails, modalData) {
    const tdInstructions = modalData.TDInstructions;
    const depositPeriod = parseInt(tdInstructions.renewPeriod.replace(this.config.depositPeriodRange, ''), 10);
    const termDepositIndivativePayload = {
      account: previousReinvestDetails,
      termDepositMaturityInstructions: {
        renewalOption: tdInstructions.renewalOption.key,
      },
    };
    if (this.config.depositPeriodRange === 'Month') {
      termDepositIndivativePayload.termDepositMaturityInstructions['renewalPeriodMonths'] = depositPeriod;
    } else if (this.config.depositPeriodRange === 'Days') {
      termDepositIndivativePayload.termDepositMaturityInstructions['renewalPeriodDays'] = depositPeriod;
    }
    if (tdInstructions.renewalOption.key === 'A' || tdInstructions.renewalOption.key === 'F') {
      termDepositIndivativePayload.termDepositMaturityInstructions['renewalAdditionalAmount'] = tdInstructions.renewAmount;
    }
    return termDepositIndivativePayload;
  }

  updateReinvestmentPayload(previousReinvestDetails, modalData) {
    const updatePayload = {
      account: previousReinvestDetails,
      termDepositMaturityInstructions: {
        autoRenew: modalData.renewal === 'true',
        repaymentAccount: {
          number: modalData.repaymentAccount && modalData.repaymentAccount.number ? modalData.repaymentAccount.number : '',
        },
      },
    };
    if (modalData.renewal === 'true') {
      const depositPeriodRange = this.config.depositPeriodRange;
      const depositPeriod = parseInt(modalData.TDInstructions.renewPeriod.replace(depositPeriodRange, ''), 10);
      if (depositPeriodRange === 'Month') {
        updatePayload.termDepositMaturityInstructions['renewalPeriodMonths'] = depositPeriod;
      } else if (depositPeriodRange === 'Days') {
        updatePayload.termDepositMaturityInstructions['renewalPeriodDays'] = depositPeriod;
      }
      updatePayload.termDepositMaturityInstructions['renewalOption'] = modalData.TDInstructions.renewalOption.key;
      if (modalData.TDInstructions.renewalOption.key === 'A') {
        updatePayload.termDepositMaturityInstructions['renewalAdditionalAmount'] = modalData.TDInstructions.renewAmount;
        updatePayload.termDepositMaturityInstructions['sourceAccount'] = modalData.TDInstructions.sourceAccount;
      } else if (modalData.TDInstructions.renewalOption.key === 'F') {
        updatePayload.termDepositMaturityInstructions['renewalAmount'] = modalData.TDInstructions.renewAmount;
      }
    }
    if (this.dataSharingService.selectedApprover) {
      updatePayload['nonFinancialRule'] = this.dataSharingService.selectedApprover;
    }
    return updatePayload;
  }
}
