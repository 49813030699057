import {
  OverDraftAction,
  OverDraftReceipt,
} from './../actions/overdarft.action';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';

export class OverdraftModel {
  overdraftData: any;
  overdraftReceipt: any;
}
@Injectable()
@State<OverdraftModel>({
  name: 'overdraft',
  defaults: {
    overdraftData: null,
    overdraftReceipt: null,
  },
})
export class OverDraftDetails {
  @Selector()
  static getSelectedOverDraft(state: OverdraftModel) {
    return state.overdraftData;
  }
  @Selector()
  static getOverDraftReceipt(state: OverdraftModel) {
    return state.overdraftReceipt;
  }
  @Action(OverDraftAction)
  setSelectedOverDraftDetails(
    { getState, setState }: StateContext<OverdraftModel>,
    { payload }: OverDraftAction
  ) {
    const state = getState();
    setState({
      ...state,
      overdraftData: payload,
    });
  }
  @Action(OverDraftReceipt)
  setSelectedOverDraftReceipt(
    { getState, setState }: StateContext<OverdraftModel>,
    { payload }: OverDraftReceipt
  ) {
    const state = getState();
    setState({
      ...state,
      overdraftReceipt: payload,
    });
  }
}
