import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SchoolFeesDetailsComponent } from './schoolfees-details/schoolfees-details.component';
import { SchoolFeesInvoiceComponent } from './schoolfees-invoice/schoolfees-invoice.component';
import { SchoolFeesListComponent } from './schoolfees-list/schoolfees-list.component';
import { SchoolFeesReceiptComponent } from './schoolfees-receipt/schoolfees-receipt.component';
import { SchoolFeesReviewComponent } from './schoolfees-review/schoolfees-review.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'details',
        component: SchoolFeesDetailsComponent,
      },
      {
        path: 'list',
        component: SchoolFeesListComponent,
      },
      {
        path: 'review',
        component: SchoolFeesReviewComponent,
      },
      {
        path: 'receipt',
        component: SchoolFeesReceiptComponent,
      },
      {
        path: 'invoice',
        component: SchoolFeesInvoiceComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SchoolfeesRoutingModule {}
