import { Injectable } from '@angular/core';

@Injectable()
export class NameInitialsServices {
  nameLetters: any;
  lastWord: any;

 

  getLetters(benName: any) {
    benName = benName.replace(/[^A-Z ]+/gi, '');
    benName = benName.replace(/\s*$/, '');
    benName = benName.replace(/^\s*/, '');
    this.lastWord = benName.substring(benName.lastIndexOf(' ') + 1);
    if (benName.indexOf(' ') >= 0) {
      this.nameLetters = benName.charAt(0) + this.lastWord.charAt(0);
      return this.nameLetters.toUpperCase();
    } else {
      this.nameLetters = benName.charAt(0);
      return this.nameLetters.toUpperCase();
    }
  }
}
