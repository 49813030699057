import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { ChangeDetectorRef } from '@angular/core';
import { SignOutService } from '../../core/sign-out/sign-out.service';

@Component({
  selector: 'app-idle-timeout-module',
  templateUrl: './idle-timeout-module.component.html',
  styleUrls: ['./idle-timeout-module.component.scss'],
})
export class IdleTimeoutModuleComponent implements OnInit {
  showIdleModal;
  timer;
  interval;
  constructor(
    private dataSharingService: DataSharingService,
    private signOutService: SignOutService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dataSharingService.getIdleTimeout().subscribe((value) => {
      this.showIdleModal = value;
      if (value) {
        this.timer = 60;
        this.interval = setInterval(this.startTicker.bind(this), 1000);
      }
      this.ref.detectChanges();
    });
  }

  startTicker() {
    if (this.timer > 0) {
      this.timer = this.timer - 1;
    } else {
      this.logOut();
    }
  }

  logOut() {
    this.signOutService.signout();
    this.dataSharingService.setIdleTimeout(false);
    this.reset();
  }

  closeIdleTimeoutModal() {
    this.dataSharingService.setIdleTimeout(false);
    this.reset();
  }

  reset() {
    this.timer = 60;
    clearInterval(this.interval);
  }
}
