import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-stop-cheque-request',
  templateUrl: './stop-cheque-request.component.html',
  styleUrls: ['./stop-cheque-request.component.scss'],
})
export class StopChequeRequestComponent implements OnInit {
  accounts: Array<{}>;
  selectedAccount;
  stopChequeReason;
  startChequeNumber;
  customerId;
  stopChequeType: Array<{}>;
  endChequeNumber;
  chequeType;
  stopReason;
  stopReasonData;
  chequeNumber;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Stop Cheque',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.stopChequeType = ['Single Cheque', 'Multiple Cheque'];
    this.chequeType = this.stopChequeType[0];
    this.serviceRequestService.stopChequeReason().subscribe((value) => {
      this.getReasons(value);
    });
    this.accounts = this.accountsIterator.filterAccountsByType();
    this.selectedAccount = this.accounts[0];

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });
  }

  getReasons(data) {
    this.stopReason = data.body.stopReasons;
  }

  stopReasonChanged(value) {
    this.stopReasonData = value;
  }

  accountValueChanged(data) {
    this.selectedAccount = data;
  }

  submitRequest() {
    let payload;
    if (this.chequeType === 'Single Cheque') {
      payload = {
        customerId: this.customerId,
        stopChequeData: {
          account: this.selectedAccount,
          chequeNumber: this.chequeNumber,
          stopReason: this.stopReasonData,
        },
      };
    } else {
      payload = {
        customerId: this.customerId,
        stopChequeData: {
          account: this.selectedAccount,
          startChequeNumber: this.startChequeNumber,
          endChequeNumber: this.endChequeNumber,
          stopReason: this.stopReasonData,
        },
      };
    }
    this.serviceRequestService.stopCheque(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Stop Cheque',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
}
