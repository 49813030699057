import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../cms/footer-navigation/footer-navigation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../services/RegistrationService/registration.service';
import { Store } from '@ngrx/store';
import { setRegisterSecurityQuestions, accountNumberEntered } from '../../shared/store-utilities/actions/registration.action';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  showCheckboxError = true;
  checkboxChecked = true;
  registrationAccNumber;
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  checked;
  constructor(
    private dataSharing: DataSharingService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
    });

    this.dataSharing.setHeadPagination({
      mainTitle: 'Registration',
      subTitle: 'Create a new User ID',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'registration',
    });

    this.store.select('appReducer', 'registrationReducer').subscribe((value) => {
      this.registrationAccNumber = value.accountNumberEntered;
    });
  }

  openDialog(type: string, condition: string): void {
    this.dataSharing.dialogType = type;
    this.dataSharing.modelDetails = condition;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }

  backToLogin = function () {
    this.router.navigate(['/login']);
  };

  checkBoxClicked = function (flag) {
    if (!flag) {
      this.showCheckboxError = true;
      this.checkboxChecked = false;
    } else {
      this.checkboxChecked = true;
    }
  };

  goToSecurityQuestions = function (event) {
    if (!this.checked) {
      this.showCheckboxError = false;
    }

    if (event && this.checked) {
      const payload = {
        account: {
          number: this.registrationAccNumber,
        },
      };
      this.registrationService.registerIBUser(payload).subscribe((response) => {
        this.store.dispatch(accountNumberEntered({ accountNumberEntered: this.registrationAccNumber }));
        this.store.dispatch(setRegisterSecurityQuestions({ registerSecurityQuestions: response.body }));
        this.router.navigate(['./securityQuestions'], {
          relativeTo: this.activatedRoute,
        });
      });
    }
  };
}
