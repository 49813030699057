import { Component, Input } from '@angular/core';
import { DownloadService } from '@core/download-service/download.service';

@Component({
  selector: 'sbg-print-all-receipts',
  templateUrl: './print-all-receipts.component.html',
  styleUrls: ['./print-all-receipts.component.scss'],
})
export class PrintAllReceiptsComponent {
  @Input() show = false;

  constructor(private downloadService: DownloadService) {}

  printAllReceipt() {
    this.downloadService.printAllReceipts();
  }

  downloadAllReceipt() {
    this.downloadService.downloadAllReceipts();
  }
}
