import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ServiceRequestTypeMapping } from './service-request-type-mapping';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-individual-service-request',
  templateUrl: './individual-service-request.component.html',
  styleUrls: ['./individual-service-request.component.scss'],
})
export class IndividualServiceRequestComponent implements OnInit {
  selectedServiceRequest;
  individualRequestData;
  typeSelected;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private serviceRequestTypeMapping: ServiceRequestTypeMapping,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.dataSharingService.getIndividualServiceRequestData().subscribe((value) => {
      this.selectedServiceRequest = value;
    });
    this.typeSelected = this.serviceRequestTypeMapping.serviceRequestTypes.filter((s) => s.key === this.selectedServiceRequest.type.key);

    this.dataSharingService.setHeadPagination({
      mainTitle: this.typeSelected[0].title,
      subTitle: '',
      showBack: true,
      showClose: true,
      steps: true,
      module: 'servicesRequest',
    });

    const payload = {
      details: {
        referenceId: this.selectedServiceRequest.referenceId,
        type: {
          key: this.selectedServiceRequest.type.key,
        },
      },
    };
    this.serviceRequestService.getIndividualServiceRequests(payload).subscribe((response) => {
      this.getIndividualRequestData(response);
    });
  }

  getIndividualRequestData(RequestData) {
    this.individualRequestData = RequestData.body[this.typeSelected[0].responseObject];
    // console.log(this.individualRequestData);
  }

  goToNewServiceRequest() {
    this.router.navigate(['./newServiceRequest'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
