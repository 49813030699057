import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { environment } from '../../../../environments/environment';
import { TransactionService } from '../../../core/transaction-service/transaction.service';

import { ReceiptDetail } from '../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../shared/components/receipt/receipt-headers';
import { DecimalPipe } from '@angular/common';
import { PaymentUtilitiesService } from '../../../core/payment-utilities/payment-utilities.service';

@Component({
  selector: 'app-international-payment-approval-receipt',
  templateUrl: './international-payment-approval-receipt.component.html',
  styleUrls: ['./international-payment-approval-receipt.component.scss'],
})
export class InternationalPaymentApprovalReceiptComponent implements OnInit, OnDestroy {
  postApprovalRespone;
  response;
  approvalData;
  preInitiatedInternationalPaymentData;
  selectedAccount;
  receiptDetails;
  bopCodes;
  config = environment.config;
  transactionAmount = {
    amount: '',
    currency: '',
  };
  subs$1: Subscription;
  constructor(
    private store: Store<any>,
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private transactionService: TransactionService,
    private decimalPipe: DecimalPipe,
    private paymentUtilities: PaymentUtilitiesService
  ) {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Approve',
      subTitle: 'Receipt',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'internationalPaymentsApproval',
    });
  }

  ngOnInit() {
    this.subs$1 = this.store.select('appReducer', 'approvalReducer').subscribe((stateData) => {
      this.approvalData = { ...stateData.selectedApprovalData };
      this.transactionAmount = { ...this.approvalData.financialTransaction.amount };
      this.postApprovalRespone = { ...stateData.postApprovalData };
      this.selectedAccount = this.accountsIterator.getAccountFromNumber(this.approvalData.financialTransaction.fromAccount.number);
   if(!this.approvalData.financialTransaction.cmaCountry){
      this.preInitiatedInternationalPaymentData = { ...stateData.preInitiatedInternationalPaymentData.body.preInitiatedData };
    }
      this.response = this.transactionService.getResponseData(this.postApprovalRespone);
      this.receiptDetails = this.getReceiptData();
    });
  }
  getReceiptData(): ReceiptDetail[] {
    const receiptData = [
      {
        header: ReceiptHeaders.AMOUNT_IN_LOCAL_CURRENCY,
        value: `${this.approvalData.financialTransaction.amount.currency}
         ${this.decimalPipe.transform(this.approvalData.financialTransaction.amount.amount, '1.2-2')}`,
      },
      {
        header: ReceiptHeaders.TRANSACTION_AMOUNT,
        value: `${this.approvalData.financialTransaction.accountCurrencyTransactionAmount.currency}
         ${this.decimalPipe.transform(this.approvalData.financialTransaction.accountCurrencyTransactionAmount.amount, '1.2-2')}`,
      },
      {
        header: ReceiptHeaders.EXCHANGE_RATE,
        value: this.decimalPipe.transform(this.preInitiatedInternationalPaymentData?.negotiatedRate, '1.2-2'),
      },
      {
        header: ReceiptHeaders.FROM,
        value: `${this.selectedAccount[0].customName} - ${this.selectedAccount[0].number}`,
        // value: this.transactionResultData.beneDetails.beneCountry.name,
      },
      {
        header: ReceiptHeaders.TO,
        value: this.approvalData.financialTransaction.toBeneficiary.name,
      },
      {
        header: ReceiptHeaders.ADDRESS,
        value: this.approvalData.financialTransaction.toBeneficiary.address.addressLine1,
      },
      {
        header: ReceiptHeaders.COUNTRY,
        value: this.approvalData.financialTransaction.toBeneficiary.route.branch.bank.country.name,
      },
      {
        header: ReceiptHeaders.BANK_NAME,
        value: this.approvalData.financialTransaction.toBeneficiary.route.branch.bank.name,
      },
      {
        header: ReceiptHeaders.SWIFT_CODE,
        value: this.approvalData.financialTransaction.toBeneficiary.route.routingNumber,
      },
      {
        header: ReceiptHeaders.MY_REFERENCE,
        value: this.approvalData.financialTransaction.toBeneficiary.customerReference,
      },
      {
        header: ReceiptHeaders.THEIR_REFERENCE,
        value: this.approvalData.financialTransaction.toBeneficiary.recipientReference,
      },
    ];

    if (this.config.localCurrency === 'LSL' || this.config.localCurrency === 'ZMW') {
      this.bopCodes = this.paymentUtilities.getPurposeCode(this.approvalData);
    } else {
      this.bopCodes = null;
    }
    // this.subs$1 = this.dataSharingService.getBopCode().subscribe((this.bopCodes) => {

    // });

    // // removing first three values and adding purpose code and subpurpose code
    receiptData.splice(0, 3);
    receiptData.splice(
      6,
      0,
      {
        header: ReceiptHeaders.PURPOSE_CODE,
        value: `${
          this.bopCodes?.purposeCode
            ? this.bopCodes?.purposeCode
            : this.approvalData.financialTransaction.reasonForTransaction.value
        }`,
      },
      {
        header: ReceiptHeaders.SUB_PURPOSE_CODE,
        value: `${
          this.bopCodes?.subPurposeCode
            ? this.bopCodes?.subPurposeCode
            : this.approvalData.financialTransaction.subReasonForTransaction.value
        }`,
      }
    );
    if(this.config.countryName === 'Namibia'){
      receiptData.splice(0,0,
        {
          header: ReceiptHeaders.TRANSACTION_AMOUNT,
          value: `${this.approvalData.financialTransaction.accountCurrencyTransactionAmount.currency}
           ${this.decimalPipe.transform(this.approvalData.financialTransaction.accountCurrencyTransactionAmount.amount, '1.2-2')}`,
        },);
  }
    if (this.config.localCurrency === 'ZMW') {
      receiptData.splice(1, 0, {
        header: ReceiptHeaders.TRADE_ID,
        value: `${this.preInitiatedInternationalPaymentData.metroTradeId}`,
      });
    }
    return receiptData;
  }
  ngOnDestroy() {
    this.subs$1.unsubscribe();
  }
}
