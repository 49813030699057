import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Notification } from '../../../shared/store-utilities/interfaces/notification.model';
import { Observable } from 'rxjs';
import { NotificationDetailsState } from '../../../shared/store-utilities/state/notification.state';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { OnInit, Component } from '@angular/core';
import { Select } from '@ngxs/store';
@Component({
  selector: 'notify',
  templateUrl: './notifylist.component.html',
  styleUrls: ['./notifylist.component.scss'],
})
export class NotifyComponent implements OnInit {
  @Select(NotificationDetailsState.getNotificationDetails) $notify: Observable<Notification>;
  headerValue = {
    mainTitle: 'Notifications',
    subTitle: '',
    showBack: false,
    showClose: true,
    steps: false,
  };
  offerbar;
  env = environment.config;
  navigateToOfferModule = {
    UPL: ['/offers/consent'],
    NUPL: ['/offers/consent'],
    OVERDRAFT: ['/overdraft'],
    TERM_DEPOSIT_MATURITY: '',
    CARD_EXPIRY: ['./debitCards/stopDebitCards/list'],
    NEW_PRODUCTS: 'MainView.Notification.newFeatureIntroduction.newFeatureDetails',
  };
  notifyLimit = 10;
  displayOfferList: any;
  showLoadMore: boolean;
  typesOfOffers = ['OVERDRAFT', 'UPL'];
  constructor(private datasharing: DataSharingService, private router: Router) {}
  ngOnInit() {
    this.datasharing.setHeadPagination(this.headerValue);
    this.notifyLimit = 10;
    this.$notify.subscribe((data) => {
      if (data) {
        this.displayOfferList = data.offerlist;
        if (this.displayOfferList.length > 10) {
          this.showLoadMore = true;
        }
      }
    });
  }
  removeLimit() {
    this.showLoadMore = false;
    this.notifyLimit = this.displayOfferList.length;
  }
  goToApply(selectedOffer) {
    if (selectedOffer.type === 'OVERDRAFT') {
      this.router.navigate(['/overdraft']);
    } else {
      if (selectedOffer.borrowingType === 'NUPL' && this.env.isMvp2 === true) {
        this.router.navigate(['/offers/increaseAmt']);
      } else {
        this.router.navigate(['/offers/consent']);
      }
    }
  }
  giftIconInNotiList(offerType) {
    return this.typesOfOffers.indexOf(offerType) > -1 ? 'notifications_none' : 'notifications_active';
  }
}
