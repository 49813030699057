import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RouterStateService } from '../../../services/route-state/route-state.service';
@Component({
  selector: 'app-cancel-notice-given-account',
  templateUrl: './cancel-notice-given-account.component.html',
  styleUrls: ['./cancel-notice-given-account.component.scss']
})
export class CancelNoticeGivenAccountComponent implements OnInit {
  customerId;
  referenceId;
  remarks;
  selectedAccount;
  noticeAccoutList: Array<any>;
  constructor( private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
    ) { }

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Cancel Notice Given Account',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
      
    });
    this.noticeAccoutList = this.accountsIterator.filterkeyValType('noticeAccount');
    this.selectedAccount = this.noticeAccoutList[0];
  }

  noticeAccountValueChanged(newValue) {
    this.selectedAccount = newValue;
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      notice: {
        noticeDepositAccount: {
          accountCurrency: { 
            currencyCode: this.selectedAccount.accountCurrency.currencyCode 
          },
          number: this.selectedAccount.number
        },
        referenceNumber: this.referenceId,
        remarks: this.remarks
      },
    };
    this.serviceRequestService.cancelNoticeGivenAccount(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Cancel Notice Given Account',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }

}
