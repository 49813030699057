import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataSharingService } from "../../../../core/data-sharing/data-sharing.service";
import { setSelectedCmaBeneficiaryData } from "../../../../shared/store-utilities/actions/recipient.action";
import { CmaRecipientService } from "../../../../core/recipient-service/cma-recipient.service";
import { Beneficiary } from "../../../../core/recipient-service/data/beneficiary";
import { Store } from "@ngrx/store";
import { RecipientGroup } from "../../../../core/recipient-service/data/recipient-group";
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { delay } from 'rxjs/operators';

@Component({
  selector: "sbg-pay-cma-beneficiary-list",
  templateUrl: "./cma-beneficiary-list.component.html",
  styleUrls: ["./cma-beneficiary-list.scss"],
})
export class CmaBeneficiaryListComponent implements OnInit {

  private static readonly ONCE_OFF_BUTTON_LABEL = "Once Off CMA Payment";
  recipientList: Beneficiary[] = [];
  clearGroupItem: RecipientGroup = {
    name: "Clear",
    orderIndex: 0,
  };
  displayedGroups = [];
  selectedGroup: RecipientGroup;
  filteredData: Beneficiary[] = [];
  selectedBeneficiary: Beneficiary;
  payButtonLabel = CmaBeneficiaryListComponent.ONCE_OFF_BUTTON_LABEL;
  screenSize: string;

  private groups: RecipientGroup[];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private cmaRecipientService: CmaRecipientService,
    private store: Store<{ selectedCmaBeneficiary }>,
    private deviceDetectorService: DeviceDetectorService

  ) {    
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
    this.screenSize = val;
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay',
      subTitle: 'Details',
      showBack: this.screenSize === 'small',
      showClose: true,
      steps: false,
      module: 'payCmaBeneficiary',
      showBackFn: this.goBack.bind(this),

    });
  });
  }

  ngOnInit() {
    this.cmaRecipientService.getCmaRecipients({ pagination: true, startIndex: 0 })
      .subscribe(response => {
        if (response) {
          this.recipientList = response.beneficiaries;
          this.filteredData = [...this.recipientList];
        }
      });
    this.cmaRecipientService.getCmaGroups().subscribe(response => {
      if (response) {
        this.groups = [...response.groups];
        this.displayedGroups = this.groups;
      }
    });
  }

  goBack() {
    return this.router.navigate(["/menu/payments"]);
  }

  applyGroupFilter(group: RecipientGroup) {
    if (group.orderIndex === this.clearGroupItem.orderIndex) {
      this.selectedGroup = undefined;
      this.filteredData = [...this.recipientList];
      this.displayedGroups = this.groups;
    } else {
      this.displayedGroups = [this.clearGroupItem, ...this.groups];
      this.selectedGroup = group;
      this.filteredData = this.recipientList.filter(recipient => recipient.recipientGroup.name === group.name);
    }
  }

  selectBeneficiary(data) {
    if (data.data) {
      this.selectedBeneficiary = data.data;
      this.payButtonLabel = "Pay " + data.data.name;
    } else {
      this.selectedBeneficiary = undefined;
      this.payButtonLabel = CmaBeneficiaryListComponent.ONCE_OFF_BUTTON_LABEL;
    }
  }

  navigateToPaymentDetails() {
    this.dataSharingService.resetStepData();
    this.store.dispatch(setSelectedCmaBeneficiaryData({ selectedCmaBeneficiary: this.selectedBeneficiary }));
    return this.router.navigate(["../details"], { relativeTo: this.activatedRoute });
  }

  showBeneficiaryInfo(beneficiary: Beneficiary) {
    this.dataSharingService.currentModuleName = 'Pay';
    this.store.dispatch(setSelectedCmaBeneficiaryData({ selectedCmaBeneficiary: beneficiary }));
    return this.router.navigate(["/recipients-management/cma-beneficiary/details-cma-beneficiary"]);
  }

  addBeneficiary() {
    this.dataSharingService.resetStepData();
    return this.router.navigate(["/recipients-management/cma-beneficiary/add-cma-beneficiary-details"]);
  }
}
