import { Subscription } from 'rxjs';
import { Component, OnInit, Output, EventEmitter, HostListener, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { FlowsService } from '../../../core/flows/flows.service';
import { Store } from '@ngrx/store';
import { RouterStateService } from './../../../services/route-state/route-state.service';

@Component({
  selector: 'sbg-head-pagination',
  templateUrl: './head-pagination.component.html',
  styleUrls: ['./head-pagination.component.scss'],
})
export class HeadPaginationComponent implements OnInit, OnDestroy {
 
  @Input() goBackFunc;
  @Output() showBackFunc = new EventEmitter<boolean>();

  steps = [];
  activeStep: string;
  activeStepIndex: number;
  mainTitle: string;
  subTitle: string;
  thisStateIndex: 2;
  showBack: boolean;
  stateIndex: number;
  subscribe: Subscription;
  hideButtons: boolean;
  showLogout: boolean;
  headerPaginationData: {
    module;
    showClose;
    steps;
    showBack;
    showBackFn;
    hideHeadPagination;
    mainTitle;
    showEdit;
    subTitle;
  };
  innerWidth;
  showBackCallbackPages = this.appConstant.showBackCallbackPages;
  isLoggedIn;
  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private routeState: RouterStateService,
    private appConstant: AppConstantService,
    private Flows: FlowsService,
    private store: Store<{ appReducer }>
  ) {}
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event) {
    this.innerWidth = window.innerWidth;
    // this.showBackFn();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    // this.showBackFn();
    this.subscribe = this.dataSharingService.getHeadPagination().subscribe((value) => {
      this.headerPaginationData = { ...this.headerPaginationData, ...{ module: undefined }, ...value };

      this.showLogout = this.router.url === '/forcedChange/username';
      this.hideButtons = this.router.url === '/forcedChange/username/usernameChangeSuccess';

      this.headerPaginationData.showClose = this.appConstant.ReceiptPages.indexOf(this.router.url) === -1;

      this.steps =
        this.headerPaginationData.steps && this.Flows.flows[this.headerPaginationData.module]
          ? this.Flows.flows[this.headerPaginationData.module]
          : [];

      if (
        this.Flows.flowConfigurations[this.headerPaginationData.module] &&
        this.Flows.flowConfigurations[this.headerPaginationData.module].notificationType
      ) {
        const dummy = [...this.Flows.flows[this.headerPaginationData.module]];
        dummy.splice(this.Flows.flows[this.headerPaginationData.module].length - 1);
        this.steps = dummy;
      }

      const currentRoute = this.router.url;
      this.stateIndex = this.calculateSteps();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const payload = {
        steps: this.steps,
        currentRoutes: currentRoute,
      };
    });
  }
  calculateSteps(): number {
    const currentRoute = this.router.url;
    let stateIndex = -1;
    this.steps.forEach((element, i) => {
      if (Array.isArray(element)) {
        stateIndex = element.indexOf(currentRoute) === -1 ? -1 : i;
      } else if (element === currentRoute) {
        stateIndex = i;
      }
    });
    return stateIndex;
  }
  showBackFn() {
    this.showBack = this.innerWidth < 640;
  }

  closeModule() {
    const module = this.appConstant.HeadPropagationClosePromptModules.find((mod) => mod.path === this.routeState.getCurrentUrl());
    if (module) {
      this.resetAll();
      this.dataSharingService.setCloseCurrentModule({
        message: {
          line1: module.message,
        },
        buttonOneText: 'Yes',
        buttonTwoText: 'No',
        routeBackTo: '/dashboard',
        showModule: true,
      });
    } else {
      this.goHome();
    }
  }

  goHome() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.isLoggedIn = value.loggedIn;
    });
    // destroy the flow and redirect back to Dashboard/Login page
    this.resetAll();
    this.dataSharingService.dataFromFirstStep = undefined;
    if (this.isLoggedIn) {
      let route = this.appConstant.ClosePages[this.headerPaginationData.module];
      route = route ? route : '/dashboard';
      if (this.routeState.getCurrentUrl() === '/otp' && this.routeState.getPreviousUrl() === '/login') {
        this.router.navigate([this.routeState.getPreviousUrl()]);
      } else {
        this.dataSharingService.setErrorMessage(null);
        this.router.navigate([route]);
      }
    } else {
      this.dataSharingService.setErrorMessage(null);
      this.router.navigate(['/login']);
    }
  }

  goBack() {
    if (this.headerPaginationData.showBackFn) {
      this.headerPaginationData.showBackFn();
      return;
    }
    const flow = this.Flows.flows[this.headerPaginationData.module];
    const currentRoute = this.router.url;
    if (flow && flow.indexOf(currentRoute)) {
      flow.forEach((element, index) => {
        if (element === currentRoute) {
          this.router.navigate([flow[index - 1]]);
        }
      });
    } else {
      // fallback if no conditions satisfy
      this.router.navigate([this.routeState.getPreviousUrl()]);
    }
  }

  editClicked() {
    // this.editIconClick.next();
    this.dataSharingService.editIconClick.next();
    //  this.editIconClicked.emit(this.headerPaginationData.mainTitle);
  }

  resetAll() {
    this.dataSharingService.setCloseCurrentModule({});
    this.dataSharingService.setBulkFileData({});
    this.dataSharingService.selectedApprover = null;
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
}
