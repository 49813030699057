import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { setFinalRecieptData, setPayBeneReviewData } from '../../../../shared/store-utilities/actions/pay-beneficiary.action';

import { RouterStateService } from '../../../../services/route-state/route-state.service';

import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CutOffTimeService } from '../../../../services/cut-off-time-service/cut-off-time.service';
import { BankingMetadataService } from '../../../../core/banking-metadata/banking-metadata.service';
import { DateUtil } from '../../../../core/utility-classes/date.util';

@Component({
  selector: 'sbg-pay-beneficiary-review',
  templateUrl: './pay-beneficiary-review.component.html',
  styleUrls: ['./pay-beneficiary-review.component.scss'],
})
export class PayBeneficiaryReviewComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  prevModel: any;
  reviewModel: any;
  mainHeading: any;
  localBank: any;
  isBenePayment: any;
  dataToRepeat: any;
  onceoffAddFlow: any;
  showPaymentPriority: any;
  backState: any;
  objectCopy: any;
  currentFlow: any;
  paymentAmount = 0;
  totalPayment = 0;
  isSmeMode = false;
  isSmeSoleProp = false;
  isEditRoute: any;
  routeHistory: any[] = [];
  btnTxt: string;
  beneficiaryName: string;
  isOnceOffModule = false;
  showNamPayMsg = false;
  config = environment.config;
  readonly ZIMBABWE = 'Zimbabwe';
  readonly LESOTHO = 'Lesotho';
  readonly SWAZILAND = 'Swaziland';
  readonly TANZANIA = 'Tanzania';

  startTimeToBeShown: string;
  endTimeToBeShown: string;
  showRtgCutOffTimeError: boolean;
  readonly INFO_EXPRESS_NEXT_DAY = 'Please be informed that the express payment will only be processed on next business day.';
  private readonly ONCE_OFF_PAY_FLOW = 'once-off-pay';
  private RTGS_AMOUNT_LIMIT = 100000;
  

  constructor(
    private cutOffTimeService: CutOffTimeService,
    private bankingMetadataService: BankingMetadataService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private routeState: RouterStateService,
    private store: Store<{ appReducer }>
  ) {
    this.routeHistory = this.routeState.getHistory();
    this.isEditRoute = this.isEditRouteFn();

    const prevUrl = _.last(this.routeHistory);
    if (prevUrl.search('once-off-beneficiary-details') !== -1) {
      this.isOnceOffModule = true;
      store
        .select('appReducer', 'onceOffPayBeneficiaryReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          this.reviewModel = _.cloneDeep(stateData.onceOffPayBeneDetailData);
          this.prevModel = this.reviewModel.paymentModel;
          this.prevModel.scheduleDate = this.reviewModel.paymentModel.currentDate;
          this.currentFlow = this.reviewModel.currentFlow;
          this.totalPayment = this.prevModel.totalPayableAmount || 0;
          this.beneficiaryName = this.reviewModel.transactions.onceOffPayments[0].beneficiary.name;
          this.setShowNamPayMsg();
        });
    } else {
      store
        .select('appReducer', 'payBeneficiaryReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          this.reviewModel = _.cloneDeep(stateData.payBeneDetailData);
          this.currentFlow = this.reviewModel.currentFlow;

          this.prevModel = this.reviewModel.paymentModel;
          this.totalPayment = this.prevModel && this.prevModel.totalPayableAmount ? this.prevModel.totalPayableAmount : 0;
          this.beneficiaryName = this.reviewModel.transactions.beneficiaryPayments[0].beneficiary.name;
          this.setShowNamPayMsg();
        });
    }
    store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
        const userIdentity = data.authenticateResponse;
        this.isSmeSoleProp = userIdentity && userIdentity.userProfile.isSmeSoleProprietor;
      }
    });

    if (this.currentFlow === 'new-transaction') {
      this.mainHeading = 'Pay beneficiary';
    } else if (this.currentFlow === this.ONCE_OFF_PAY_FLOW) {
      this.mainHeading = 'Pay new recipient';
    } else {
      this.mainHeading = `Edit ${this.beneficiaryName} schedule`;
    }

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'payBeneficiary',
    });
  }

  

  ngOnInit() {
    this.localBank = this.config.localBank;

    if (this.config.fetchRtgsCutOffTime || this.config.allowNrtCutOffTime) {
      this.fetchRtgsCutOffTimings();
    }

    // Todo : for edit flow
    this.reviewModel = { ...this.reviewModel, ...{ totalCalculatedCharge: 0 } };

    this.dataForConfirmation(this.reviewModel);
    this.setBtnTxt();

    if (this.config.countryName === this.ZIMBABWE) {
      this.showPaymentPriority = true;
    }

    if (this.config.countryName === 'Zambia') {
      this.RTGS_AMOUNT_LIMIT = 500000;
    }
  }

  setBtnTxt() {
    if (this.isSmeMode && !this.isSmeSoleProp) {
      this.btnTxt = 'Send for Approval';
    } else {
      this.btnTxt = this.dataToRepeat[0].beneficiary.isFlowEditSchedule || this.prevModel.isFlowEditSchedule ? 'Update' : 'Pay';
    }
  }

  changePositionFieldMoneyTransferTaxCode() {
    if (
      this.config.intermediatedMoneyTransferTax &&
      this.currentFlow !== this.ONCE_OFF_PAY_FLOW &&
      this.reviewModel.transactions.beneficiaryPayments
    ) {
      delete this.reviewModel.transactions.beneficiaryPayments[0].beneficiary.moneyTransferTaxCode;
      delete this.reviewModel.transactions.beneficiaryPayments[0].beneficiary.taxCodeMessage;
    }
  }

  fetchRtgsCutOffTimings() {
    this.bankingMetadataService.getCutOffTimes().subscribe((response: any) => {
      const cutOffTimeInfo = this.cutOffTimeService.checkCutOffTime(response.body.setting);
      this.showRtgCutOffTimeError = !cutOffTimeInfo.inOnCutOffTime;
      const startTime = this.config.useWeekdayRtgsTime ? cutOffTimeInfo.weekdayStartTime : cutOffTimeInfo.startTime;
      const endTime = this.config.useWeekdayRtgsTime ? cutOffTimeInfo.weekdayEndTime : cutOffTimeInfo.endTime;
      this.startTimeToBeShown = DateUtil.changeFormat(startTime, DateUtil.HH_MM_SS, DateUtil.HH_MM);
      this.endTimeToBeShown = DateUtil.changeFormat(endTime, DateUtil.HH_MM_SS, DateUtil.HH_MM);
    });
  }

  isOverRtgsLimit(amount) {
    return parseInt(amount, 10) >= this.RTGS_AMOUNT_LIMIT;
  }

  isEditRouteFn() {
    const prevUrl = this.routeHistory[this.routeHistory.length - 2];
    return prevUrl.search('edit') !== -1;
  }

  dataForConfirmation(paymentReview: any) {
    for (const key in paymentReview.transactions) {
      if (paymentReview.transactions.hasOwnProperty(key)) {
        if (key === 'beneficiaryPayments') {
          this.isBenePayment = true;
          this.setBeneficiaryPaymentsData(paymentReview);
        } else if (key === 'onceOffPayments') {
          this.isBenePayment = false;
          this.setOnceOffPaymentsData(paymentReview);
        } else {
          /* $log.error(
          "Response contains key " +
          key +
          " which is not accepted for this flow"
        ); */
        }
      }
    }

    this.paymentAmount = this.totalPayment - this.reviewModel.totalCalculatedCharge;
    this.paymentAmount = parseFloat(this.paymentAmount.toFixed(2));
  }

  setOnceOffPaymentsData(PaymentReview) {
    this.reviewModel = {
      ...this.reviewModel,
      ...{
        multibeneSelected: false,
      },
    };

    this.dataToRepeat = PaymentReview.transactions.onceOffPayments;
    this.dataToRepeat = _.map(this.dataToRepeat, (dataObj: any) => {
      const tmepObj: any = {
        headerDetails: {
          name: dataObj.beneficiary.name,
          first: dataObj.beneficiary.route.branch.bank.name,
          second: dataObj.beneficiary.accountNumber,
        },
        showPaymentPriority: false,
      };

      if (this.config.countryName === this.ZIMBABWE) {
        tmepObj.moneyTransferTaxCode = dataObj.moneyTransferTaxCode || {};
      }

      if (
        this.config.recipientsManagement.branchDropdown &&
        this.reviewModel.transactions.onceOffPayments &&
        !((dataObj.beneficiary.bank && dataObj.beneficiary.bank.branch.networkType === 'RTG') || dataObj.expressPay)
      ) {
        tmepObj.headerDetails.third = dataObj.beneficiary.route.branch.name;
        if (this.config.countryName !== this.ZIMBABWE) {
          tmepObj.headerDetails.fourth = dataObj.beneficiary.route.branch.number;
        }
      }

      if (
        dataObj.beneficiary.paymentConfirmation &&
        dataObj.beneficiary.paymentConfirmation.notifications &&
        dataObj.beneficiary.paymentConfirmation.notifications.length
      ) {
        dataObj.beneficiary.paymentConfirmation.notifications.forEach((element) => {
          if (element.type === 'SMS') {
            tmepObj.mobile = element.address;
          } else if (element.type === 'EMAIL') {
            tmepObj.email = element.address;
          }
        });
      }

      if (this.config.recipientPaymentPriority) {
        if (this.dataToRepeat.length > 1) {
          if (!this.config.multipleRtgPaymentAllowed) {
            tmepObj.showPaymentPriority = false;
          }
        } else {
          tmepObj.showPaymentPriority = dataObj.beneficiary.route.networkType !== 'WIB';
        }
      }

      dataObj = {
        ...dataObj,
        ...tmepObj,
      };

      return dataObj;
    });

    this.onceoffAddFlow = true;
  }

  setBeneficiaryPaymentsData(PaymentReview) {
    this.reviewModel = {
      ...this.reviewModel,
      ...{
        multibeneSelected: PaymentReview.transactions.beneficiaryPayments.length > 1,
      },
    };

    this.dataToRepeat = PaymentReview.transactions.beneficiaryPayments;

    this.dataToRepeat = _.map(this.dataToRepeat, (dataObj: any, dataInd: any) => {
      const tmepObj: any = {
        headerDetails: {
          name: dataObj.beneficiary.name,
          first: dataObj.beneficiary.route.branch.bank.name,
          second: dataObj.beneficiary.accountNumber,
        },
        showPaymentPriority: false,
      };

      if (this.config.countryName === this.ZIMBABWE) {
        tmepObj.moneyTransferTaxCode = dataObj.moneyTransferTaxCode;
      }

      if (
        dataObj.beneficiary.paymentConfirmation &&
        dataObj.beneficiary.paymentConfirmation.notifications &&
        dataObj.beneficiary.paymentConfirmation.notifications.length
      ) {
        dataObj.beneficiary.paymentConfirmation.notifications.forEach((element) => {
          if (element.type === 'SMS') {
            tmepObj.mobile = element.address;
          } else if (element.type === 'EMAIL') {
            tmepObj.email = element.address;
          }
        });
      }

      if (this.config.recipientPaymentPriority) {
        if (this.dataToRepeat.length > 1) {
          if (!this.config.multipleRtgPaymentAllowed) {
            tmepObj.showPaymentPriority = false;
          }
        } else {
          tmepObj.showPaymentPriority = dataObj.beneficiary.route.networkType !== 'WIB';
        }
      }

      dataObj = {
        ...dataObj,
        ...tmepObj,
      };

      if (this.reviewModel.thisTxnCharge && this.reviewModel.thisTxnCharge[dataInd]) {
        dataObj.charges = this.reviewModel.thisTxnCharge[dataInd];
        this.reviewModel.totalCalculatedCharge = this.reviewModel.totalCalculatedCharge + parseFloat(this.reviewModel.thisTxnCharge[dataInd]);
      }
      return dataObj;
    });
    this.onceoffAddFlow = false;
  }

  checkDataSource(PaymentReview: any) {
    for (const key in PaymentReview.transactions) {
      if (PaymentReview.transactions.hasOwnProperty(key)) {
        if (key === 'beneficiaryPayments') {
          this.objectCopy.transactions.beneficiaryPayments.forEach((value: any) => {
            delete value.beneficiary.headerDetails;
            if (value.beneficiary.hasOwnProperty('isChecked')) {
              delete value.beneficiary.isChecked;
            }
          });

          this.objectCopy.transactions.beneficiaryPayments.forEach((value: any) => {
            value.beneficiary.paymentConfirmation.notifications.forEach((notiVal: any) => {
              notiVal.type = notiVal.type.toUpperCase();
            });
          });
        } else if (key === 'onceOffPayments') {
          delete this.objectCopy.transactions.onceOffPayments[0].beneLetters;
          delete this.objectCopy.transactions.onceOffPayments[0].charges;
        } else {
          /*  $log.error(
          "Response contains key " +
          key +
          " which is not accepted for this flow"
        ); */
        }
      }
    }
  }

  goToReceipt(): void {
    this.changePositionFieldMoneyTransferTaxCode();
    if (this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off') {
      this.prevModel.editScheduleType = this.prevModel.type || this.reviewModel.transactions.beneficiaryPayments[0].beneficiary.type;
      const editSchedulePayload = {
        scheduledTransaction: this.transactionService.getEditSchedulePayload(
          this.reviewModel,
          this.prevModel.editScheduleType,
          this.prevModel.editReviewModel
        ),
      };

      const receiptPayload: any = {
        scheduledTransaction: editSchedulePayload.scheduledTransaction,
      };
      if (this.dataSharingService.selectedApprover) {
        receiptPayload.financialRule = this.dataSharingService.selectedApprover;
      }

      this.postServiceEdit(receiptPayload);
      this.dataSharingService.setPostService(this.postServiceEdit.bind(this));
    } else {
      this.objectCopy = _.cloneDeep(this.reviewModel);
      this.checkDataSource(this.reviewModel);

      if (this.objectCopy.transactions['onceOffPayments']) {
        this.objectCopy.transactions['onceOffPayments'][0].paymentNetworkSwitch = null;
      }

      let payloadData = {
        account: this.objectCopy.account,
        transactions: this.objectCopy.transactions,
      };
      if (this.dataSharingService.selectedApprover) {
        if (payloadData.transactions.beneficiaryPayments && !this.config.payments.payBeneficiary.oldApprovalContracts) {
          for (let index = 0; index < payloadData.transactions.beneficiaryPayments.length; index++) {
            payloadData.transactions.beneficiaryPayments[index] = {
              ...payloadData.transactions.beneficiaryPayments[index],
              ...{ financialRule: this.dataSharingService.selectedApprover },
            };
          }
        } else {
          payloadData = { ...payloadData, ...{ financialRule: this.dataSharingService.selectedApprover } };
        }
      }

      this.postService(payloadData);
      this.dataSharingService.setPostService(this.postService.bind(this));
    }
  }

  postServiceEdit(receiptPayload) {
    return this.transactionService.getEditTransactions(receiptPayload).subscribe((res: any) => {
      let response: any = res.body;
      response = { ...response, ...{ currentFlow: this.currentFlow } };

      if (this.prevModel.thisTxnCharge && this.prevModel.thisTxnCharge[0]) {
        response.data.scheduledTransaction.charges = this.prevModel.thisTxnCharge[0];
      }
      if (this.prevModel.isReccuringFirstDateToday) {
        response.data.scheduledTransaction.isReccuringFirstDateToday = this.prevModel.isReccuringFirstDateToday;
      }

      this.store.dispatch(setPayBeneReviewData({ payBeneReviewData: response }));

      this.router.navigate(['/payments/beneficiary/receipt'], {
        relativeTo: this.activatedRoute,
      });
    });
  }

  postService(payload) {
    return this.transactionService.getTransactions(payload).subscribe((response: any) => {
      const res = _.cloneDeep(response.body);
      res.reviewModel = this.reviewModel;
      res.currentFlow = this.currentFlow;

      this.store.dispatch(setPayBeneReviewData({ payBeneReviewData: res }));
      this.store.dispatch(setFinalRecieptData({ finalRecieptData: { isData: false } }));

      this.router.navigate(['/payments/beneficiary/receipt']);
    });
  }

  needsAccountFetch(response) {
    let needAccountFetch = false;
    if (response.body.keyValueMetadata && response.body.keyValueMetadata.length > 0) {
      for (let i = response.body.keyValueMetadata.length - 1; i >= 0; i--) {
        const value = response.body.keyValueMetadata[i];
        if (value.key === 'BALANCE_LIMIT_REFRESH_REQUIRED' && value.value === 'Y') {
          needAccountFetch = true;
        }
      }
    }
    return needAccountFetch;
  }

  goBack() {
    return this.router.navigate([this.routeState.getPreviousUrl()]);

    // return this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  private setShowNamPayMsg() {
    if (this.config.localCurrency === 'NAD') {
      if (this.prevModel.selectedUserDetails) {
        this.showNamPayMsg = !!this.prevModel.selectedUserDetails.find((beneficiary) => beneficiary.route.networkType !== 'WIB');
      } else if (this.prevModel.selectedRoute) {
        this.showNamPayMsg = this.prevModel.selectedRoute.networkType !== 'WIB';
      }
    }
  }
}
