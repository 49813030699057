import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { SchoolfeesRoutingModule } from './schoolfees-routing.module';
import { SchoolFeesListComponent } from './schoolfees-list/schoolfees-list.component';
import { SchoolFeesDetailsComponent } from './schoolfees-details/schoolfees-details.component';
import { SchoolFeesReviewComponent } from './schoolfees-review/schoolfees-review.component';
import { SchoolFeesReceiptComponent } from './schoolfees-receipt/schoolfees-receipt.component';
import { TransactionManagementService } from '../../../core/transaction-management/transaction-management.service';
import { PrepaidService } from '../../../core/prepaid-service/prepaid-service.service';
import { PrepaidUtilitiesService } from '../../../core/prepaid-utilities/prepaid-utilities.service';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';

import { SchoolFeesInvoiceComponent } from './schoolfees-invoice/schoolfees-invoice.component';

@NgModule({
  declarations: [
    SchoolFeesListComponent,
    SchoolFeesDetailsComponent,
    SchoolFeesReviewComponent,
    SchoolFeesReceiptComponent,
    SchoolFeesInvoiceComponent,
  ],
  imports: [CommonModule, SharedModule, SchoolfeesRoutingModule],
  providers: [
    TransactionManagementService,
    PrepaidService,
    PrepaidUtilitiesService,
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    },
  ],
})
export class SchoolFeesModule {}
