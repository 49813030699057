import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ChangePassword } from './data/change-password';
import { Store } from '@ngrx/store';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  @Input() header;
  @Input() buttonAlignment = 'align-right';
  @Input() isForcedPrompt = true;
  @Input() passwordsMismatch = false;
  @Input() minifiedViewFlag = false;
  @Output() childSubmit = new EventEmitter<ChangePassword>();
  routeHistory;
  checkURL;

  maxLength = 30;
  showPasswordValidationOverlay: boolean;
  minLength: boolean;
  capitalChar: boolean;
  smallChar: boolean;
  numCheck: boolean;
  specialCharCheck: boolean;
  validPassword: boolean;
  changePasswordModel: ChangePassword = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  oldPwd: any;
  constructor(
    private store: Store<{ appReducer }>,
    private routeState: RouterStateService
  ) {
    this.routeHistory = this.routeState.getHistory();
    this.checkURL = this.isEditRouteFn();
  }
  isEditRouteFn() {
    const prevUrl = this.routeHistory[this.routeHistory.length - 1];
    return prevUrl.search('/profileAndSettings') !== -1;
  }
  showPasswordValidationPopup() {
    this.showPasswordValidationOverlay = true;
  }

  hidePasswordValidationPopup() {
    this.showPasswordValidationOverlay = false;
    this.validPassword =
      this.minLength &&
      this.capitalChar &&
      this.smallChar &&
      this.numCheck &&
      this.specialCharCheck;
  }

  newPasswordValidityCheck(passCode: string) {
    this.minLength = passCode.length >= 8;
    this.capitalChar = /[A-Z]/.test(passCode);
    this.smallChar = /[a-z]/.test(passCode);
    this.numCheck = /[0-9]/.test(passCode);
    this.specialCharCheck = /[@#$%\^]/.test(passCode);
  }

  removeMismatchLabel() {
    this.passwordsMismatch = false;
  }

  childFormSubmit() {
    if (this.isForcedPrompt) {
      this.store.select('appReducer', 'loginReducer').subscribe((value) => {
        this.oldPwd = value.loginUser.digitalId.password;
      });
      this.changePasswordModel.currentPassword = this.oldPwd;
    }
    this.childSubmit.emit(this.changePasswordModel);
  }
}
