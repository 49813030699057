import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { ScheduleTransactionComponent } from './schedule-transaction.component';
import { ScheduleTransactionReceiptComponent } from './schedule-transaction-receipt/schedule-transaction-receipt.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ScheduleTransactionComponent,
      },
      {
        path: 'receipt',
        component: ScheduleTransactionReceiptComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class ScheduleTransactionRoutingModule {}
