import { Component, OnInit } from '@angular/core';
import { BankAccount } from '../../../../core/account-service/data/bank-account';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Amount } from '../../../../core/data/amount';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { PreparedBiller } from '../gepg-payment.model';

@Component({
  selector: 'sbg-gepg-payment-amount-confirmation',
  templateUrl: './gepg-payment-amount-confirmation.component.html',
  styleUrls: ['./gepg-payment-amount-confirmation.component.scss'],
})
export class GepgPaymentAmountConfirmationComponent implements OnInit {

  validBiller;
  selectedBiller: PreparedBiller;
  headerObj;
  accounts: BankAccount[];
  transactionRequest: TransactionsRequest = {
    account: undefined,
    transactions: {},
  };
  displayingLabelAmount: Amount;
  paymentType: string;
  requestAmount;
  buttonText: string;
  private readonly ACCOUNT_CURRENCY = 'all';

  constructor(
    private store: Store<any>,
    private accountsIterator: AccountsIteratorService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private uuidGeneratorService: UuidGeneratorService
  ) {}

  ngOnInit() {
    this.selectedBiller = this.dataSharingService.dataFromFirstStep.selectedBiller;
    this.getAccounts();
    this.updateHeader();
    this.headerObj = {
      name: this.selectedBiller.serviceProviderCode,
      first: this.selectedBiller.controlNumber,
    };
    this.validateBiller();
  }

  validateBiller() {
    const biller = {
      serviceProvider: {
        serviceProviderCode: this.selectedBiller.serviceProviderCode,
      },
      controlNumber: this.selectedBiller.controlNumber,
    };
    this.transactionService
      .validateGepgBiller(biller)
      .pipe(take(1))
      .subscribe((response) => {
        this.validBiller = response.billerPayment;
        this.paymentType = this.validBiller.paymentType;
        this.displayingLabelAmount = this.isPaymentTypeExact() ? this.selectedBiller.amount : this.validBiller.amount;
        this.setButtonText();
      });
  }

  getAccounts() {
    this.accounts = this.accountsIterator.getPaymentFromAccounts(this.ACCOUNT_CURRENCY);
    this.transactionRequest.account = this.accounts[0];
  }

  updateAccount(account: BankAccount) {
    this.transactionRequest.account = account;
  }

  updateHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Biller Payment (GEPG)',
      subTitle: 'Payment Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'gepg',
    });
  }

  isPaymentTypeExact(): boolean {
    return this.paymentType === 'EXACT';
  }

  goToNextPage() {
    this.makeTransactionRequest();
    if (this.isPaymentTypeExact()) {
      this.goToReceipt();
    } else {
      this.goToReview();
    }
  }

  goBack() {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  makeTransactionRequest() {
    this.transactionRequest = {
      ...this.transactionRequest,
      transactions: {
        gepgBillerPayments: [
          {
            ...this.validBiller,
            transactionId: this.uuidGeneratorService.getUUID(),
            amount:
              this.isPaymentTypeExact()
                ? this.selectedBiller.amount
                : {
                    amount: this.requestAmount,
                    currency: this.selectedBiller.amount.currency,
                  },
          },
        ],
      },
    };
  }

  goToReceipt() {
    this.makeTransaction();
    this.dataSharingService.setPostService(this.makeTransaction.bind(this));
  }

  goToReview() {
    this.dataSharingService.dataFromSecondStep = { transactionRequest: this.transactionRequest, amountDue: this.validBiller.amount };
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  makeTransaction() {
    this.transactionService.makeTransaction(this.transactionRequest).subscribe((response) => {
      this.dataSharingService.dataFromThirdStep = { transactionRequest: this.transactionRequest, transactionResponse: response };
      this.router.navigate(['../receipt'], { relativeTo: this.activatedRoute });
    });
  }

  setButtonText() {
    this.buttonText = this.isPaymentTypeExact() ? 'Confirm' : 'Next';
  }

  goToDetailsPage() {
    this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }
}
