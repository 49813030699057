import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankingMetadataService } from '@core/banking-metadata/banking-metadata.service';
import { Institution } from '@core/banking-metadata/data/institution';
import { Subject } from 'rxjs';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';

@Component({
  selector: 'sbg-schoolfees-details',
  templateUrl: './schoolfees-details.component.html',
  styleUrls: ['./schoolfees-details.component.scss'],
})
export class SchoolFeesDetailsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  institutions: Institution[] = [];
  selectedInstitution: Institution;
  rollNumber: string;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private bankingMetadataService: BankingMetadataService
  ) {
    dataSharingService.setHeadPagination({
      mainTitle: 'Pay Fee',
      subTitle: 'Details',
      showBack: true,
      showClose: true,
      showBackFn: this.goBack.bind(this),
      steps: true,
      module: 'schoolFees',
    });
  }

  ngOnInit() {
    if (this.dataSharingService.dataFromSecondStep) {
      this.selectedInstitution = this.dataSharingService.dataFromSecondStep.selectedInstitution;
      this.rollNumber = this.dataSharingService.dataFromSecondStep.rollNumber;
    }
    this.getInstitutions();
  }

  selectInstitution(value) {
    this.selectedInstitution = this.institutions.find((institution) => value == institution.name);
  }

  getInstitutions(): void {
    this.bankingMetadataService.getInstitutions().subscribe((value) => (this.institutions = value.institutions));
  }

  goBack() {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  goToInvoice() {
    this.dataSharingService.dataFromSecondStep = { selectedInstitution: this.selectedInstitution, rollNumber: this.rollNumber };
    this.router.navigate(['../invoice'], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
