import { createReducer, on } from '@ngrx/store';
import {
  setSelectedDebitCardData,
  setSelectedDebitCardConfirmData,
  setSelectedDebitCardReceiptData,
} from '../actions/debitCard.action';
import { DebitCardState } from '../state/debitCard.state';

const selectedDebitCardDataInitState = {};
const selectedDebitCardConfirmDataInitState = {};
const selectedDebitCardReceiptDataInitState = {};

const debitCardData: DebitCardState = {
  selectedDebitCardData: selectedDebitCardDataInitState,
  selectedDebitCardConfirmData: selectedDebitCardConfirmDataInitState,
  selectedDebitCardReceiptData: selectedDebitCardReceiptDataInitState,
};
export const debitCardReducer = createReducer(
  debitCardData,

  on(setSelectedDebitCardData, (state, { selectedDebitCardData }) => ({
    ...state,
    selectedDebitCardData,
  })),
  on(
    setSelectedDebitCardConfirmData,
    (state, { selectedDebitCardConfirmData }) => ({
      ...state,
      selectedDebitCardConfirmData,
    })
  ),
  on(
    setSelectedDebitCardReceiptData,
    (state, { selectedDebitCardReceiptData }) => ({
      ...state,
      selectedDebitCardReceiptData,
    })
  )
);
