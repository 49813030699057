import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NameInitialsServices } from '../../../services/name-initials-service/name-initials.service';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RecipientService } from '../../../core/recipient-service/recipient-service.service';
import { TransactionService } from '../../../core/transaction-service/transaction.service';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';

import { SnackBarService } from '../../../services/snack-bar-service/snackBarService';

import {
  setEditOnceOffScheduleData,
  setEditPayBillerScheduleData,
  setEditScheduleData,
  setEditSelfFundScheduleData,
} from '../../../shared/store-utilities/actions/edit-txn-schedule.action';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SmeHelperService } from '../../../services/sme-helper/sme-helper.service';
import { HttpResponse } from '@angular/common/http';
import { McaHeader } from '../../../core/data/mca-header';
import { McaResponseType } from '../../../core/data/mca-response-type';
import { environment } from '../../../../environments/environment';
import { setOnceOffPayBeneDetailData } from '../../../shared/store-utilities/actions/once-off-pay-beneficiary.action';

@Component({
  selector: 'app-schedule-transaction-receipt',
  templateUrl: './schedule-transaction-receipt.component.html',
  styleUrls: ['./schedule-transaction-receipt.component.scss'],
})
export class ScheduleTransactionReceiptComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  config = environment.config;
  reviewModel = {} as any;
  selectedTxn;
  initiatedByMe;
  isSmeMode = false;
  isSmeSoleProprietor = false;
  accounts;
  moduleData: any;
  dataForEditSchedule: any;
  reviewModalObj: any;

  approversData = {
    BILLER: {
      transactionType: 'BILLER',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    COMPANY: {
      transactionType: 'COMPANY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    ONCE_OFF_COMPANY: {
      transactionType: 'ONCE_OFF_COMPANY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    BANK_BENEFICIARY: {
      transactionType: 'BANK_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    SELF_FUNDS: {
      transactionType: 'SELF_FUNDS',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    CMA_BENEFICIARY: {
      transactionType: 'CMA_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    ONCE_OFF_CMA_BENEFICIARY: {
      transactionType: 'ONCE_OFF_CMA_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    ONCE_OFF_BANK_BENEFICIARY: {
      transactionType: 'ONCE_OFF_BANK_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
  };
  maskEditButton: boolean;

  private readonly MALAWI = 'Malawi';
  
  constructor(
    private nameInitialsService: NameInitialsServices,
    private recipientService: RecipientService,
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private transactionService: TransactionService,
    private snack: SnackBarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{ appReducer }>,
    private smeHelper: SmeHelperService
  ) {}

  ngOnInit() {
    this.store
      .select('appReducer', 'scheduleTxnReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        if (stateData) {
          this.selectedTxn = stateData.selectedScheduledTxn;
          if (stateData.txnReceiptData && stateData.txnReceiptData.body) {
            this.reviewModel = _.cloneDeep(stateData.txnReceiptData.body.scheduledTransaction);
            if (this.config.countryName === this.MALAWI) {
              this.reviewModel.nextPaymentDate = this.selectedTxn.nextPaymentDate;
              this.reviewModel.futureDatedInstruction.repeatNumber = this.reviewModel.futureDatedInstruction.repeatNumber -
                stateData.txnReceiptData.body.stoppedTransactionsDates.length;
            }
          }
        }
      });

    this.isSmeMode = this.smeHelper.isSmeMode();
    this.isSmeSoleProprietor = this.smeHelper.isUserSmeSoleProprietor();
    if (this.isSmeMode) {
      this.initiatedByMe = this.selectedTxn && this.selectedTxn.initiatedByMe;
    } else {
      this.initiatedByMe = true;
    }

    this.dataSharingService
      .getCloseCurrentModule()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.moduleData = value;

        if (this.moduleData && this.moduleData.stopScheduleFnCall) {
          this.stopSelectedScheduleFn();
        }
      });

    this.accounts = this.accountsIterator.getAllAccounts();

    this.dataSharingService.setHeadPagination({
      mainTitle: 'Transaction',
      subTitle: 'Details',
      showBack: true,
      showBackFn: this.goBack.bind(this),
      showClose: true,
      steps: false,
      module: 'scheduleTransferDetails',
    });

    // Get data from previous states
    this.setDataFn();

    this.dataSharingService.getMaskFinancialFlows().subscribe((data) => {
      this.maskEditButton = data;
    });
  }

  stopSelectedScheduleFn() {
    this.dataSharingService.setCloseCurrentModule({});

    const payloadObj: any = {
      futureDatedInstruction: {
        repeatInterval: this.selectedTxn.futureDatedInstruction.repeatInterval ,
        repeatNumber: this.reviewModel.futureDatedInstruction ? this.reviewModel.futureDatedInstruction.repeatNumber : 1,
      },
      futureDatedId: this.reviewModel.futureDatedId,
      type: this.reviewModel.type,
    };

    const receiptPayload: any = {
      scheduledTransaction: payloadObj,
    };

    if (this.isSmeMode) {
      receiptPayload.financialRule = this.dataSharingService.selectedApprover;
      this.dataSharingService.selectedApprover = null;
    }

    this.postService(receiptPayload);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    return this.transactionService.cancelNextScheduleTransactions(payload).subscribe((response: HttpResponse<any>) => {
      if (response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS) {
        const responseCode = this.transactionService.getResponseData(response);

        const successMessage = this.transactionService.getToastMessageForStopScheduleTransaction(
          this.isSmeMode && !this.isSmeSoleProprietor,
          this.selectedTxn.futureDatedInstruction.repeatInterval,
          1
        );
        this.snack.open(successMessage, responseCode.type);
        this.router.navigate(['./scheduleTransaction']);
      }
    });
  }

  getAccountnickName(accNo) {
    let accNickName: any;
    accNickName = _.result(
      _.find(this.accounts, (data: any) => {
        return data.number === accNo;
      }),
      'customName'
    );
    if (!accNickName) {
      accNickName = accNo;
    }
    return accNickName;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByFn(index, item) {
    return index; //  or item.id
  }

  setDataFn() {
    let itemNameInitial: any;
    let displayName: any;
    let subData1: any;
    let subData2: any;
    if (
      this.reviewModel.type === 'BANK_BENEFICIARY' ||
      this.reviewModel.type === 'ONCE_OFF_BANK_BENEFICIARY' ||
      this.reviewModel.type === 'MOBILE_WALLET' ||
      this.reviewModel.type === 'ONCE_OFF_MOBILE_WALLET' ||
      this.reviewModel.type === 'CMA_BENEFICIARY' ||
      this.reviewModel.type === 'ONCE_OFF_CMA_BENEFICIARY'
    ) {
      itemNameInitial = this.reviewModel.beneficiary.name;
    } else if (this.reviewModel.type === 'BILLER') {
      itemNameInitial = this.reviewModel.roaBiller.name;
    } else if (this.reviewModel.type === 'COMPANY' || this.reviewModel.type === 'ONCE_OFF_COMPANY') {
      itemNameInitial = this.reviewModel.cdiRecipient.name;
    } else if (this.reviewModel.type === 'SELF_FUNDS') {
      itemNameInitial = this.selectedTxn.toAccount.number;
    }

    if (
      this.reviewModel.type === 'BANK_BENEFICIARY' ||
      this.reviewModel.type === 'ONCE_OFF_BANK_BENEFICIARY' ||
      this.reviewModel.type === 'MOBILE_WALLET' ||
      this.reviewModel.type === 'ONCE_OFF_MOBILE_WALLET' ||
      this.reviewModel.type === 'CMA_BENEFICIARY' ||
      this.reviewModel.type === 'ONCE_OFF_CMA_BENEFICIARY'
    ) {
      displayName = this.reviewModel.beneficiary.name;
      if (this.reviewModel.beneficiary.route.branch.bank.name) {
        subData1 = this.reviewModel.beneficiary.route.branch.bank.name;
      }
      if (this.reviewModel.beneficiary.accountNumber) {
        subData2 = this.reviewModel.beneficiary.accountNumber;
      }
    } else if (this.reviewModel.type === 'BILLER') {
      displayName = this.reviewModel.roaBiller.nickName;

      if (this.reviewModel.roaBiller.name) {
        subData1 = this.reviewModel.roaBiller.name;
      }
    } else if (this.reviewModel.type === 'COMPANY' || this.reviewModel.type === 'ONCE_OFF_COMPANY') {
      displayName = this.reviewModel.cdiRecipient.name;
    } else if (this.reviewModel.type === 'SELF_FUNDS') {
      displayName = this.getAccountnickName(this.selectedTxn.toAccount.number);
      // initialInfo.toAccount.accountType
      if (this.selectedTxn.toAccount.accountType) {
        subData1 = this.selectedTxn.toAccount.accountType;
      }
      if (this.selectedTxn.toAccount.number) {
        subData2 = this.selectedTxn.toAccount.number;
      }
    }

    this.reviewModalObj = {
      // name: itemNameInitial,
      name: displayName,
      first: subData1,
      second: subData2,
    };

    if (itemNameInitial) {
      const tempObj = {
        itemName: itemNameInitial,
        typeWiseName: displayName || '',
        itemSubData1: subData1 || '',
        itemSubData2: subData2 || '',
      };
      this.reviewModel = { ...this.reviewModel, ...tempObj };
    }
  }

  stopScheduleFn(valid) {
    if (valid) {
      const buttonTwoText = 'Stop transaction';

      const lineTwoText = 'this transaction schedule?';

      this.dataSharingService.setCloseCurrentModule({
        message: {
          line1: 'Are you sure want to stop',
          line2: lineTwoText,
          line3: 'These action can not be undone.',
        },
        buttonOneText: 'Cancel',
        buttonTwoText,
        buttonTwoDanger: true,
        routeBackTo: '/scheduleTransaction/receipt',
        showModule: true,
        smeTransactionType: this.approversData[this.reviewModel.type].transactionType,
        financeType: this.approversData[this.reviewModel.type].financeType,
        amount: this.reviewModel.amount,
      });
    } else {
      this.snack.open('Only an Initiator can Stop Schedule', this.snack.TYPE_ERROR);
    }
  }

  editDetailsPageData(mainData: any) {
    const data = {
      scheduledAmount: this.reviewModel.amount,
      paymentConfirmation: this.reviewModel.paymentConfirmation,
      nextPaymentDate: this.reviewModel.nextPaymentDate,
      futureDatedInstruction: this.reviewModel.futureDatedInstruction || this.selectedTxn.futureDatedInstruction,
      recentPayments: this.reviewModel.recentPayments,
      futureDatedId: this.reviewModel.futureDatedId,
      futureDatedItems: this.reviewModel.futureDatedItems,
      fromAccount: this.reviewModel.fromAccount,
      toAccount: this.selectedTxn.toAccount,
      type: this.reviewModel.type,
      selectedRadio: this.reviewModel.paymentConfirmation && this.reviewModel.paymentConfirmation.confirmationType,
      isFlowEditSchedule: true,
    };
    this.dataForEditSchedule = { ...mainData, ...data };
  }

  goToEditSchedule(valid) {
    if (valid) {
      switch (this.reviewModel.type) {
        case 'BANK_BENEFICIARY':
          this.dataForEditSchedule = this.reviewModel.beneficiary;
          this.editDetailsPageData(this.dataForEditSchedule);

          this.recipientService.getRecipients('BANK_ACCOUNT').subscribe((response: any) => {
            if (response) {
              const setRecepintId = response.beneficiaries.filter((value: any) => {
                return parseInt(value.payeeId, 10) === parseInt(this.dataForEditSchedule.payeeId, 10);
              });

              this.dataForEditSchedule = {
                ...this.dataForEditSchedule,
                ...{ recipientId: setRecepintId[0].recipientId },
              };

              // dispatch info for edit
              this.store.dispatch(
                setEditScheduleData({
                  editBankBeneficiary: this.dataForEditSchedule,
                })
              );
              this.router.navigate(['./payments/beneficiary/details']);
            }
          });
          break;
        case 'CMA_BENEFICIARY':
          break;
        case 'MOBILE_WALLET':
          break;
        case 'ONCE_OFF_BANK_BENEFICIARY':
          this.dataForEditSchedule = this.reviewModel.beneficiary;
          this.editDetailsPageData(this.dataForEditSchedule);

          // dispatch info for edit
          this.store.dispatch(
            setEditOnceOffScheduleData({
              editOnceOffBankBeneficiary: this.dataForEditSchedule,
            })
          );
          this.router.navigate(['./payments/beneficiary/once-off-beneficiary-details']);

          break;
        case 'ONCE_OFF_MOBILE_WALLET':
          break;
        case 'SELF_FUNDS':
          this.dataForEditSchedule = this.reviewModel.beneficiary;
          this.editDetailsPageData(this.dataForEditSchedule);

          this.store.dispatch(
            setEditSelfFundScheduleData({
              editSelfFund: this.dataForEditSchedule,
            })
          );
          this.router.navigate(['./payments/selfFund/details']);
          break;
        case 'BILLER':
        case 'COMPANY':
          this.dataForEditSchedule = this.config.isBillerCDI ? this.reviewModel.cdiRecipient : this.reviewModel.roaBiller;
          this.editDetailsPageData(this.dataForEditSchedule);

          this.recipientService.getBillers({}).subscribe((response: any) => {
            if (response) {
              let setRecepintId;
              if (this.config.isBillerCDI) {
                setRecepintId = response.body.recipients.filter((value: any) => {
                  return parseInt(value.payeeId, 10) === parseInt(this.dataForEditSchedule.payeeId, 10);
                });
              } else {
                setRecepintId = response.body.billers.filter((value: any) => {
                  return parseInt(value.roaBillerId, 10) === parseInt(this.dataForEditSchedule.roaBillerId, 10);
                });
              }

              this.dataForEditSchedule = {
                ...this.dataForEditSchedule,
                ...{ recipientId: this.config.isBillerCDI ? null : setRecepintId[0].roaBillerId },
              };

              // dispatch info for edit
              this.store.dispatch(
                setEditPayBillerScheduleData({
                  editPayBiller: this.dataForEditSchedule,
                })
              );
              this.router.navigate(['./payments/biller/details']);
            }
          });
          break;

        case 'ONCE_OFF_COMPANY':
          this.dataForEditSchedule = this.reviewModel.cdiRecipient;
          this.editDetailsPageData(this.dataForEditSchedule);
          this.store.dispatch(setOnceOffPayBeneDetailData({ onceOffPayBeneDetailData: this.dataForEditSchedule }));
          this.store.dispatch(setEditOnceOffScheduleData({ editOnceOffBankBeneficiary: this.dataForEditSchedule }));
          this.router.navigate(['./payments/biller/once-off-biller-details']);
          break;
      }
    } else {
      this.snack.open('Only an Initiator can Edit Schedule', this.snack.TYPE_ERROR);
    }
  }
  goBack() {
    return this.router.navigate(['/scheduleTransaction']);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
