/**
 * Credits:Based on Lawrence Nyakiso's gist https://gist.github.com/lawrencepn/15be3895e7e21a7de864
 */
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class MakeCsvService {
  export(TableData, columnMap) {
    // print every row on a new line
    const headers = TableData.columns;
    const body = TableData.data;
    const un = [];
    const un_k = [];
    let un_t = [];

    // extract table headers
    for (let i = 0; i < headers.length; i++) {
      un.push(headers[i].header);
    }
    // extract rows
    for (let k = 0; k < body.length; k++) {
      let gh;
      if (columnMap) {
        gh = columnMap;
      } else {
        gh = Object.keys(body[k]);
      }
      // extract columns
      for (const prop in gh) {
        if (gh.hasOwnProperty(prop)) {
          // create new array
          let dataItem = body[k][gh[prop]];
          if (dataItem) {
            dataItem = dataItem.toString().replace(/,/g, '');
          }
          un_t.push(dataItem);
        }
      }
      un_k.push(new Array(un_t));
      un_t = [];
    }
    // write to file
    let fg = '';
    // add the extracted headers to the new csv content array
    fg += un.join();
    fg += '\r\n';
    // add the extracted columns to the csv
    for (let y = 0; y < un_k.length; y++) {
      fg += un_k[y].join() + '\r\n';
    }
    return new Blob([fg], {
      type: 'text/csv',
    });
  }
}
