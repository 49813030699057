import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'searchFilter',
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, property: any): any {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    const filteredItems = items.filter((item) => {
      let result = false;
      if (Array.isArray(property)) {
        property.forEach((element) => {
          const check = item[element] && item[element].toLowerCase().includes(searchText.toLowerCase());
          if (check) {
            result = check;
          }
        });
      } else {
        if (item[property]) {
          return item[property].toLowerCase().includes(searchText.toLowerCase());
        } 
      }
      return result;
    });
    return filteredItems;
  }
  private searchItem(item, property, searchText) {
    return item[property].toLowerCase().includes(searchText.toLowerCase());
  }
}
