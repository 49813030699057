import { createAction, props } from '@ngrx/store';
import { TaxPaymentModel } from '../../../components/payments/tax-payment/tax-payment.model';

export const setTaxPaymentFormData = createAction(
  '[TaxPayment Component] setTaxPaymentFormData',
  props<{ taxPaymentModel: TaxPaymentModel }>()
);

export const setTaxPaymentReceiptData = createAction(
  '[TaxPayment Component] setTaxPaymentReceiptData',
  props<{ taxPaymentReceiptData: any }>()
);
