import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../core/transaction-service/transaction.service';
import { AccountsIteratorService } from '../../core/accounts-iterator/accounts-iterator.service';
import { SortByPipe } from '../../shared/pipes/sort/sort-by.pipe';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { SmeHelperService } from '../../services/sme-helper/sme-helper.service';
import { MaskingService } from '../../core/masking-service/masking.service';
import {
  Modules,
  Payments,
} from '../../core/masking-service/masking-constants';
import { environment } from '../../../environments/environment';
import { DateUtil } from '../../core/utility-classes/date.util';
import { setSelectedTab } from '../../shared/store-utilities/actions/activeTab.action';

@Component({
  selector: 'app-schedule-transaction',
  templateUrl: './schedule-transaction.component.html',
  styleUrls: ['./schedule-transaction.component.scss'],
})
export class ScheduleTransactionComponent implements OnInit {
  isSmeMode = false;
  currentTab: string;
  accounts = [] as any;
  filterValues = {} as any;
  payload = {} as any;
  transactionList = [] as any;
  sortedTransactionHistory = [] as any;
  transactionsCopy = [] as any;
  txnTypeWiseData = [] as any;
  SortedTxnDateArray = [] as any;
  selection = [] as any;
  selectedCount: number;
  obj = {} as any;
  uniqueModel = {
    uniqueAccountsModel: {
      nickNamefilters: {},
    },
    uniqueVasTypeModel: {
      Vasfilters: {},
    },
  };
  moduleName = 'Schedule transaction and transfer';
  isBillerCDI = environment.config.isBillerCDI;
  transactionTypes: any = [
    {
      feature: Payments.BENEFICIARY_PAYMENTS,
      name: 'Bank beneficiaries',
      type: 'BANK_BENEFICIARY',
    },
    {
      feature: Payments.CMA_BENEFICIARY_PAYMENTS,
      name: 'CMA beneficiaries',
      type: 'CMA_BENEFICIARY',
    },
    {
      feature: Payments.BENEFICIARY_PAYMENTS,
      name: 'Once off beneficiary payments',
      type: 'ONCE_OFF_BANK_BENEFICIARY',
    },
    {
      feature: Payments.ONCE_OFF_COMPANY,
      name: 'Once off company payments',
      type: 'ONCE_OFF_COMPANY',
    },
    {
      feature: Payments.CMA_BENEFICIARY_PAYMENTS,
      name: 'Once off CMA payments',
      type: 'ONCE_OFF_CMA_BENEFICIARY',
    },
    {
      feature: Payments.ACCOUNT_TRANSFERS,
      name: 'Account transfers',
      type: 'SELF_FUNDS',
    },
    {
      feature: Payments.BILLER_PAYMENTS,
      name: this.isBillerCDI ? 'Companies' : 'Billers',
      type: this.isBillerCDI ? 'COMPANY' : 'BILLER',
    },
  ];
  selectedFeature;
  selectedTab;
  config = environment.config;
  constructor(
    private sortBy: SortByPipe,
    private accountsIterator: AccountsIteratorService,
    private store: Store<{ appReducer }>,
    private transactionService: TransactionService,
    private smeHelper: SmeHelperService,
    private maskingService: MaskingService
  ) {
    window['inner']('other');
  }

  ngOnInit() {
    this.isSmeMode = this.smeHelper.isSmeMode();
    this.transactionTypes = this.maskingService.removeMaskedFeatures(
      this.transactionTypes,
      Modules.SCHEDULED_PAYMENTS
    );

    this.accounts = this.accountsIterator.getAllAccounts();

    this.store.select('appReducer', 'selectedTabReducer').subscribe((data) => {
      this.selectedTab = data.selectedTab;
      this.currentTab = this.transactionTypes[this.selectedTab].type;
      this.selectedFeature = this.transactionTypes[this.selectedTab];
    });
    this.recallDataFn(true);
  }

  recallDataFn(isDataRecall: any) {
    if (isDataRecall) {
      const nextDate = new Date();
      const numberOfDays = this.config.countryName === 'Namibia' ? 360 : 180;
      nextDate.setDate(nextDate.getDate() + numberOfDays);

      this.payload = {
        pagination: false,
        dateFrom: new Date(),
        dateTo: nextDate,
      };

      this.transactionService
        .getFutureTransactions(this.payload)
        .subscribe((response) => {
          this.showTransactions(response);
        });
    }
  }

  getAccountnickName(accountData: any, accNo: any) {
    let accNickName: any;
    accNickName = _.result(
      _.find(accountData, (data: any) => {
        return data.number === accNo;
      }),
      'customName'
    );
    if (!accNickName) {
      accNickName = accNo;
    }
    return accNickName;
  }

  segregateOnType(findTransactions: any, currentTabType: any, index: any) {
    const txnData = findTransactions[index];

    if (currentTabType === 'BILLER') {
      this.obj = _.cloneDeep(txnData.roaBiller);
      this.obj.name = txnData.roaBiller.nickName;
    } else if (currentTabType === 'SELF_FUNDS') {
      this.obj.name = this.getAccountnickName(
        this.accounts,
        txnData.toAccount.number
      );
    } else if (
      currentTabType === 'COMPANY' ||
      currentTabType === 'ONCE_OFF_COMPANY'
    ) {
      this.obj = _.cloneDeep(txnData.cdiRecipient);
      this.obj.name = txnData.cdiRecipient.name;
    } else {
      this.obj = txnData.beneficiary || {};
    }
    // Object.preventExtensions(this.obj);

    /*   var tempProps = JSON.parse(JSON.stringify(this.obj));
    tempProps.legendPosition = "right";
    Object.preventExtensions(tempProps); */
    this.obj = JSON.parse(JSON.stringify(this.obj));

    this.obj.TransDate = txnData.nextPaymentDate;
    this.obj.futureDatedInstruction = txnData.futureDatedInstruction;
    this.obj.amount = txnData.amount;
    this.obj.nextPaymentDate = txnData.nextPaymentDate;
    this.obj.futureDatedId = txnData.futureDatedId;
    this.obj.fromAccount = txnData.fromAccount.number;
    this.obj.toAccount = txnData.toAccount;
    this.obj.type = currentTabType;
    this.obj.fromAccountNickName = this.getAccountnickName(
      this.accounts,
      this.obj.fromAccount
    );

    if (this.isSmeMode) {
      this.obj.initiatedByMe = txnData.requestedByMe;
    }
    this.sortedTransactionHistory.push(this.obj);
  }

  sortTransactionHistory(findTransactions: any, currentTabType: any) {
    //  Uniq Dates : like
    //  SortedTxnDateArray = ["02/01/2017", "11/03/2016", "11/02/2016", "11/05/2016"]

    this.SortedTxnDateArray = [];
    _.forEach(findTransactions, (txn: any) => {
      const nextPaymentDate = DateUtil.format(
        txn.nextPaymentDate,
        DateUtil.SLASH_MM_DD_YYYY,
        0
      );
      if (!_.includes(this.SortedTxnDateArray, nextPaymentDate)) {
        this.SortedTxnDateArray.push(nextPaymentDate);
      }
    });

    _.forEach(this.SortedTxnDateArray, (date: any) => {
      _.forEach(findTransactions, (txn: any, index: any) => {
        this.obj = {};
        const nextPaymentDate = DateUtil.format(
          txn.nextPaymentDate,
          DateUtil.SLASH_MM_DD_YYYY,
          0
        );
        if (nextPaymentDate === date) {
          this.segregateOnType(findTransactions, currentTabType, index);
        }
      });

      this.transactionsCopy = _.cloneDeep(this.sortedTransactionHistory);

      this.sortedTransactionHistory = this.sortBy.transform(
        this.sortedTransactionHistory,
        'desc',
        'nextPaymentDate'
      );
    });
  }

  segregateTransactions(transactionList, type) {
    const filteredData = transactionList.filter((value) => {
      return value.type === type;
    });
    this.txnTypeWiseData.push({
      type,
      transactions: filteredData,
    });
  }

  featureValueChanged() {
    //  this.getScheduleTransactions( _.findIndex(this.receiptList, this.selectedFeature), this.selectedFeature.type);
    this.getScheduleTransactions(
      this.selectedFeature.type,
      _.findIndex(this.transactionTypes, this.selectedFeature)
    );
  }

  getScheduleTransactions(currentTabType: any, tabIndex) {
    this.currentTab = currentTabType;
    this.selectedTab = tabIndex;
    this.store.dispatch(setSelectedTab({ selectedTab: this.selectedTab }));
    this.selectedCount = 0;
    this.selection = [];
    this.sortedTransactionHistory = [];

    const findData = _.find(this.txnTypeWiseData, (dataTxn: any) => {
      return dataTxn.type === currentTabType;
    });
    if (findData && findData.transactions) {
      this.sortTransactionHistory(findData.transactions, currentTabType);
    }
    //  to ensure when switched to  mobile device the dropdown is updated

    this.selectedFeature =
      this.transactionTypes[
        _.findIndex(this.transactionTypes, { type: currentTabType })
      ];
  }

  showTransactions(res: any) {
    if (res.statusText === 'OK') {
      if (res.body.transactions && res.body.transactions.length) {
        this.transactionList = res.body.transactions;
        this.txnTypeWiseData = [] as any;

        _.forEach(this.transactionTypes, (txnType: any) => {
          this.segregateTransactions(this.transactionList, txnType.type);
        });

        this.getScheduleTransactions(this.currentTab, this.selectedTab);
      }
    }
  }
}
