import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';

@Component({
  selector: 'app-username-success',
  templateUrl: './username-success.component.html',
  styleUrls: ['./username-success.component.scss']
})
export class UsernameSuccessComponent implements OnInit {

  constructor(private dataSharing: DataSharingService) { }

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: '',
      subTitle: '',
      showBack: false,
      showClose: false,
      steps: false,
      module: 'registration',
    });
  }

}
