import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from './../../../../environments/environment';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-ebanking-limit-adjustment',
  templateUrl: './ebanking-limit-adjustment.component.html',
  styleUrls: ['./ebanking-limit-adjustment.component.scss'],
})
export class EbankingLimitAdjustmentComponent implements OnInit {
  selectedContactTime: any;
  countryCode = environment.config.mobileCountryCode;
  contactTimeList: Array<any>;
  contactTimeData;
  customerId;
  email;
  contactNumber;
  additionalInfo;
  disableServiceField = environment.config.disableServiceField;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'eBanking Limit Adjustment',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
      this.email = value.authenticateResponse.userProfile.emailAddress;
      this.contactNumber = this.serviceRequestService.getMobileNumber(value.authenticateResponse.userProfile.mobileNumber);
    });

    this.serviceRequestService.contactTimeList().subscribe((response) => {
      this.getContactTimeList(response);
    });
  }


  contactTimeChanged(data) {
    this.contactTimeData = data;
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      contactInfoData: {
        emailAddress: this.email,
        mobileNumber: this.countryCode.slice(1) + this.contactNumber,
        contactTime: this.contactTimeData,
        comments: this.additionalInfo,
      },
    };
    this.serviceRequestService.ebankingLimitAdjustment(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'eBanking Limit Adjustment',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
  
  private getContactTimeList(data) {
    this.contactTimeList = data.body.contactTimeList;
  }
}
