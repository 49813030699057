import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sbg-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class ListFilterComponent {
  @Input() input = '';
  @Input() placeholder = '';
  @Input() options: string;
  @Input() isSearchButtonVisible: boolean;
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  showSearchErrorMsg = false;

 

  inputValueChanged(event) {
    if (!this.isSearchButtonVisible) {
      this.inputChange.emit(event);
    }
    if (this.input.length >= 3) {
      this.showSearchErrorMsg = false;
    }
  }

  searchInputHandler() {
    if (this.input.length < 3) {
      this.showSearchErrorMsg = true;
    } else {
      this.inputChange.emit(this.input);
    }
  }
}
