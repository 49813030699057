import { createReducer, on } from '@ngrx/store';
import { setSelectedTab } from '../actions/activeTab.action';

const selectedTabInitData = {
  selectedTab: 0,
};
export const selectedTabReducer = createReducer(
  selectedTabInitData,
  on(setSelectedTab, (state, { selectedTab }) => ({
    ...state,
    selectedTab,
  }))
);
