import { Directive, ElementRef, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Directive({
  selector: 'input[referenceFieldValidation]'
})
export class SpecialCharacterValidationDirective {

  validationRegex: any;
  config = environment.config
  constructor(private el: ElementRef) { }
  
  @HostListener('input',['$event']) onInputChange(event){
    this.validationRegex = this.config.InputValidation['inputRefrence']
    this.validationRegex = new RegExp(this.validationRegex, 'g')
    const inputValue = this.el.nativeElement.value
    const valueChange = inputValue.replace(this.validationRegex, '')
    this.el.nativeElement.value = valueChange.replace(/\s{2,}/g, ' ')
    if(valueChange !== inputValue){
      event.stopPropagation();
    }
  }
}
