
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable()
export class TokenService {
  private token: string;
  constructor(private ngrxStore: Store<{ appReducer }>) {
  }

  setToken(token: string) {
    this.token = token;
  }
  getToken() {
    return this.token;
  }
}
