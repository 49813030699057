import { NgModule } from "@angular/core";

import { SharedModule } from "../../../shared/shared.module";
import { CmaBeneficiaryListComponent } from "./cma-beneficiary-list/cma-beneficiary-list.component";
import { CmaBeneficiaryPaymentRoutingModule } from "./cma-beneficiary-payment-routing.module";
import { CmaBeneficiaryPaymentDetailsComponent } from './cma-beneficiary-payment-details/cma-beneficiary-payment-details.component';
import { CmaBeneficiaryPaymentReviewComponent } from './cma-beneficiary-payment-review/cma-beneficiary-payment-review.component';
import { CmaBeneficiaryPaymentReceiptComponent } from './cma-beneficiary-payment-receipt/cma-beneficiary-payment-receipt.component';

@NgModule({
  declarations: [
    CmaBeneficiaryListComponent,
    CmaBeneficiaryPaymentDetailsComponent,
    CmaBeneficiaryPaymentReviewComponent,
    CmaBeneficiaryPaymentReceiptComponent,
  ],
  imports: [SharedModule, CmaBeneficiaryPaymentRoutingModule],
  providers: [],
})
export class CmaBeneficiaryPaymentModule {
}
