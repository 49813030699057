import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable()
export class FormService {
  config = environment.config;

  

  formTouched(formRef) {
    const arr = formRef.controls;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const arrd = formRef.markAsTouched();
    /*   Object.keys(formRef.form.controls).forEach(key => {
      key.control.markAsTouched();
    });
     */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.values(arr).forEach((value) => {
      // value.control.markAsTouched();
    });

    formRef.form.controls.forEach((value) => {
      value.control.markAsTouched();
    });
  }
}
