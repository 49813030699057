import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AccountService } from '../../../../core/account-service/account-service.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { FinancialRule } from '../../../../core/data/financial-rule';
import { CmaPayment } from '../../../../core/transaction-service/data/cma-payment';
import { OnceOffCmaPayment } from '../../../../core/transaction-service/data/once-off-cma-payment';
import { PaymentSchedule } from '../../../../core/transaction-service/data/payment-schedule';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { SmeHelperService } from '../../../../services/sme-helper/sme-helper.service';
import { environment } from '../../../../../environments/environment';
import { DateUtil } from '../../../../core/utility-classes/date.util';

@Component({
  selector: 'app-cma-beneficiary-payment-review',
  templateUrl: './cma-beneficiary-payment-review.component.html',
  styleUrls: ['./cma-beneficiary-payment-review.component.scss'],
})
export class CmaBeneficiaryPaymentReviewComponent implements OnInit {
  
  isSme = false;
  transactionRequest: TransactionsRequest;
  payment: CmaPayment | OnceOffCmaPayment;
  isPaymentScheduled = false;
  paymentCountDescription = { value: 'Just Once', placeholder: 'once', id: '1' };
  headerModel: any;
  proceedButtonText = 'Pay';
  private readonly ENTITY_NUMBER = 'Entity Number';
  private readonly PASSPORT_NUMBER = 'Passport Number';
  private isEntityNumberDisplayed = false;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionsService: TransactionService,
    private accountService: AccountService,
    private smeHelper: SmeHelperService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay',
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'payCmaBeneficiary',
    });
    this.isSme = this.smeHelper.isSmeMode();
    this.isEntityNumberDisplayed = 
      environment.config.payments.cmaBeneficiaryPayment.isEntityNumberDisplayed && this.isSme && !this.smeHelper.isUserSmeSoleProprietor();
    this.updateProceedButtonText(this.smeHelper.isSmeMode(), this.smeHelper.isUserSmeSoleProprietor());
    this.transactionRequest = this.dataSharingService.dataFromFirstStep;
    if (this.isSme && this.dataSharingService.selectedApprover) {
      this.transactionRequest.financialRule = this.dataSharingService.selectedApprover as FinancialRule;
    }
    if (this.transactionRequest.transactions.cmaPayments) {
      this.payment = this.transactionRequest.transactions.cmaPayments[0];
    }
    if (this.transactionRequest.transactions.onceOffCmaPayments) {
      this.payment = this.transactionRequest.transactions.onceOffCmaPayments[0];
    }
    this.headerModel = this.createHeaderModel();
    this.checkPaymentSchedule(this.payment.futureDatedInstruction);
  }

  get passNumLabel(): string {
    return this.isEntityNumberDisplayed ? this.ENTITY_NUMBER : this.PASSPORT_NUMBER;
  }

  goBack() {
    this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  proceedToReceipt() {
    this.makeTransaction(this.transactionRequest);
    this.dataSharingService.setPostService(this.makeTransaction.bind(this));
  }

  private updateProceedButtonText(isSme: boolean, isSmeSoleProprietor: boolean) {
    if (isSme && !isSmeSoleProprietor) {
      this.proceedButtonText = 'Send for Approval';
    } else {
      this.proceedButtonText = 'Pay';
    }
  }

  private makeTransaction(request: TransactionsRequest) {
    this.transactionsService.makeTransaction(request).subscribe((value) => {
      if (!value.stepUp) {
        if (value.cardProfile) {
          this.accountService.cardProfile = value.cardProfile;
        }
        this.dataSharingService.dataFromSecondStep = value;
        this.dataSharingService.selectedApprover = null;
        this.router.navigate(['/payments/cma/receipt']);
      }
    });
  }

  private createHeaderModel() {
    const headerModel = {} as any;
    headerModel.name = this.payment.beneficiary.name;
    headerModel.first = this.payment.beneficiary.route.branch.bank.name;
    headerModel.second = this.payment.beneficiary.accountNumber;
    headerModel.third = this.payment.beneficiary.route.branch.name;
    headerModel.fourth = this.payment.beneficiary.route.branch.number;
    return headerModel;
  }

  private checkPaymentSchedule(schedule: PaymentSchedule) {
    const today = new Date();
    this.isPaymentScheduled = !DateUtil.areSame(today, schedule.fromDate, 'days') && DateUtil.diff(today, schedule.fromDate) <= 0;
  }
}
