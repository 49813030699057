import { ApprovalDetailsComponent } from './approval-details/approval-details.component';
import { ApprovalReceiptComponent } from './approval-receipt/approval-receipt.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ApprovalsComponent } from './approvals.component';
import { InternationalPaymentApprovalComponent } from './international-payment-approval/international-payment-approval.component';
// tslint:disable-next-line: max-line-length
import { InternationalPaymentApprovalReceiptComponent } from './international-payment-approval-receipt/international-payment-approval-receipt.component';
const approvalsRoutes: Routes = [
  {
    path: '',
    component: ApprovalsComponent,
    children: [],
  },
  {
    path: 'approval-details',
    component: ApprovalDetailsComponent,
    children: [],
  },
  {
    path: 'approval-receipt',
    component: ApprovalReceiptComponent,
    children: [],
  },
  {
    path: 'international-payment-approval',
    component: InternationalPaymentApprovalComponent,
  },
  {
    path: 'international-payment-approval-receipt',
    component: InternationalPaymentApprovalReceiptComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(approvalsRoutes)],
  exports: [RouterModule],
})
export class ApprovalsRoutingModule {}
