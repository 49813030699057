import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Institution } from '@core/banking-metadata/data/institution';
import { McaResponseType } from '@core/data/mca-response-type';
import { InstitutionBillDetails } from '@core/transaction-service/data/school-institutions/institution-bill-details';
import { TransactionResult } from '@core/transaction-service/data/transaction-result';
import { TransactionsResponse } from '@core/transaction-service/data/transactions-response';
import { VasPayment } from '@core/transaction-service/data/vas-payment';
import { ReceiptDetail } from '@shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '@shared/components/receipt/receipt-headers';
import { DataSharingService } from '../../../.../../../core/data-sharing/data-sharing.service';
import { TransactionsRequest } from './../../../../core/transaction-service/data/transactions-request';

@Component({
  selector: 'sbg-schoolfees-receipt',
  templateUrl: './schoolfees-receipt.component.html',
  styleUrls: ['./schoolfees-receipt.component.scss'],
})
export class SchoolFeesReceiptComponent implements OnInit {
  transactionResults: TransactionResult[];
  transactionResponse: TransactionsResponse;
  transactionRequest: TransactionsRequest;
  billDetails: InstitutionBillDetails;
  selectedInstitution: Institution;
  date = new Date();
  isInvoicePayment: boolean;
  totalAmount: number;
  message: string;

  private readonly DATE_FORMAT = 'dd MMMM yyyy';

  private readonly LOCALE = 'en-US';

  private readonly REFERENCE_TRANSACTION_RESULT_KEY = 'REFERENCE';

  private readonly SCHOOL_FEE_PAYMENT = 'Fees Payment';

  private readonly REQUEST_TRANSACTION_ID_RESULT_KEY = 'REQUEST_TRANSACTION_ID';

  constructor(private dataSharingService: DataSharingService, private router: Router) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay Fee',
      subTitle: 'Confirmation',
      showBack: false,
      showClose: true,
      steps: false,
      module: 'schoolFees',
    });

    this.transactionResponse = this.dataSharingService.dataForReceiptPage.responseData;
    this.transactionResults = this.transactionResponse.transactionResults;
    this.transactionRequest = this.dataSharingService.dataForReceiptPage.requestData;
    this.billDetails = this.dataSharingService.dataForReceiptPage.billDetails;
    this.selectedInstitution = this.dataSharingService.dataForReceiptPage.selectedInstitution;
    this.isInvoicePayment = this.dataSharingService.dataForReceiptPage.isInvoicePayment;
    this.totalAmount = this.dataSharingService.dataForReceiptPage.totalAmount;
    if (this.isInvoicePayment) {
      this.message = `${this.countOfSuccessfulTransaction()} out of ${
        this.transactionRequest.transactions.prepaidPurchases.length
      } Transactions Successful`;
    }
  }


  getInvoiceType(vasPayment: VasPayment): string {
    if (vasPayment && vasPayment.schoolFee && vasPayment.schoolFee.invoice) {
      return vasPayment.schoolFee.invoice.title;
    }
  }

  

  getAmount(index: number) {
    const matchedPayment: VasPayment = this.getVasPaymentByRequestTransactionIdFromResponse(index);
    if (matchedPayment) {
      return matchedPayment.amount;
    }
  }

  getStatus(index: number) {
    return this.transactionResults[index].responseCode.responseType;
  }

  getMessage(index: number) {
    return this.transactionResults[index].responseCode.message;
  }

  getTransactionIdFromResponse(index: number) {
    const transactionId = this.transactionResults[index].transactionResultMetaData.find(
      (value) => this.REQUEST_TRANSACTION_ID_RESULT_KEY === value.transactionResultKey
    );

    if (transactionId) {
      return transactionId.value;
    }
  }

  getTransactionReference(index: number) {
    const reference = this.transactionResults[index].transactionResultMetaData.find(
      (value) => this.REFERENCE_TRANSACTION_RESULT_KEY === value.transactionResultKey
    );
    if (reference) {
      return reference.value;
    }
  }

  getReceiptDetails(index: number): ReceiptDetail[] {
    const details: ReceiptDetail[] = [
      {
        header: ReceiptHeaders.FROM,
        value: this.transactionRequest.account.customName,
      },
      {
        header: ReceiptHeaders.DATE,
        value: formatDate(this.date, this.DATE_FORMAT, this.LOCALE),
      },
      {
        header: ReceiptHeaders.REFERENCE_NUMBER,
        value: this.billDetails.entityReferenceId,
      },
      {
        header: ReceiptHeaders.INSTITUTION_NAME,
        value: this.selectedInstitution.name,
      },
      {
        header: ReceiptHeaders.TRANSACTION_TYPE,
        value: this.SCHOOL_FEE_PAYMENT,
      },
      {
        header: ReceiptHeaders.NAME,
        value: this.billDetails.entityName,
      },
    ];

    if (this.getTransactionReference(index)) {
      details.unshift({
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.getTransactionReference(index),
      });
    }

    const matchedPayment = this.getVasPaymentByRequestTransactionIdFromResponse(index);
    if (this.isInvoicePayment && matchedPayment) {
      details.push({
        header: ReceiptHeaders.INVOICE_TYPE,
        value: this.getInvoiceType(matchedPayment),
      });
    }

    return details;
  }

  navigateToNewPayment() {
    this.dataSharingService.resetStepData();
    return this.router.navigate(['/payments/schoolfees/list']);
  }

  private getVasPaymentByRequestTransactionIdFromResponse(index: number): VasPayment {
    return this.transactionRequest.transactions.prepaidPurchases.find(
      (payment) => this.getTransactionIdFromResponse(index) === payment.transactionId
    );
  }
  
  private countOfSuccessfulTransaction() {
    return this.transactionResults.filter((transaction) => transaction.responseCode.responseType === McaResponseType.SUCCESS).length;
  }
}
