import { Injectable } from '@angular/core';

@Injectable()
export class AppUtilitiesService {
  public capitalizeFirstLetter(dummyName) {
    return (
      dummyName.charAt(0).toUpperCase() + dummyName.substr(1).toLowerCase()
    );
  }
}
