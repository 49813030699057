import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-over-draft-request',
  templateUrl: './over-draft-request.component.html',
  styleUrls: ['./over-draft-request.component.scss'],
})
export class OverDraftRequestComponent implements OnInit {
  accounts: Array<{}>;
  selectedAccount;
  customerId;
  limit;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Overdraft Limit Change Request',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.accounts = this.accountsIterator.filterkeyValType('overDraft');
    this.selectedAccount = this.accounts[0];

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });
  }

  accountValueChanged(data) {
    this.selectedAccount = data;
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      overdraftLimitChangeData: {
        account: {
          number: this.selectedAccount.number,
        },
        limitAmount: {
          amount: this.limit,
          currency: this.selectedAccount.availableBalance.currency,
        },
      },
    };
    this.serviceRequestService.overDraftLimitUpdate(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Change Overdraft Limit',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
}
