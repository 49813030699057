import { createReducer, on } from '@ngrx/store';
import {
  setMobileWalletDetailsData,
  setMobileWalletReceiptData,
} from '../actions/mobileWallet.action';
import { MobileWalletState } from '../state/mobileWallet.state';

const mobileWalletInitState = {};

const mobileWalletReviewInitState = {};

const mobileWalletData: MobileWalletState = {
  mobileWalletFormData: mobileWalletInitState,
  mobileWalletReviewData: mobileWalletReviewInitState,
};
export const mobileWalletReducer = createReducer(
  mobileWalletData,
  on(setMobileWalletDetailsData, (state, { mobileWalletFormData }) => ({
    ...state,
    mobileWalletFormData,
  })),

  on(setMobileWalletReceiptData, (state, { mobileWalletReviewData }) => ({
    ...state,
    mobileWalletReviewData,
  }))
);
