import { Component, OnInit } from '@angular/core';
import {DataSharingService} from "../../../core/data-sharing/data-sharing.service";
import {Router} from "@angular/router";

@Component({
  selector: 'sbg-change-username-success',
  templateUrl: './change-username-success.component.html',
  styleUrls: ['./change-username-success.component.scss']
})
export class ChangeUsernameSuccessComponent implements OnInit {
  public newUsername: string;

  constructor( private dataSharing: DataSharingService,
               private router: Router) { }

  ngOnInit() {
    this.newUsername = this.dataSharing.dataFromFirstStep.username;
    this.dataSharing.resetStepData();
  }

  closeScreen(): void {
    this.router.navigate(['/dashboard']);
  }

}
