import { ActivatedRoute, Router } from '@angular/router';
import { setPayBillerData } from '../../../../shared/store-utilities/actions/payment.action';
import { SortByPipe } from '../../../../shared/pipes/sort/sort-by.pipe';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { environment } from '../../../../../environments/environment';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { Store } from '@ngrx/store';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import * as _ from 'lodash';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { NgForm, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'sbg-pay-biller-details',
  templateUrl: './pay-biller-details.component.html',
  styleUrls: ['./pay-biller-details.component.scss'],
})
export class PayBillerDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('paymentDetailsForm') paymentDetailsForm: NgForm;
  withdrawlLimit;
  availableLimit;
  isSmeMode = false;
  mainHeading: any;
  routeHistory: any;

  mainTitle: string;
  userAccountDetails;
  modifyArray;
  refineProducts;
  toggleStatus = 'open';
  manualEntryAmount;
  permissibleTransferAmount;
  initialUserBalance;
  paymentScheduleFrequency;
  lastProduct;
  reviewHeader;
  limits = {
    dailyWithdrawlLimit: {
      amount: 0,
    },
    usedLimit: { amount: 0 },
    availableDailyLimit: {},
  };
  totalPayableAmount = 0;
  dataForNextPage: any;
  payBillerData;
  destroyed$ = new Subject<boolean>();

  isAllowedScheduledPayment = this.paymentUtilities.checkIfScheduledPaymentsIsAllowedForTransactionType('BILLER');

  config = environment.config;
  paymentModel: {
    scheduleDate;
    oftenSchedule;
    isPaymentScheduled;
    numberOfPayments;
    selectedFromAccount;
    selectedUserDetails;
    name;
    eachListAmountEntry;
    permissibleTransferAmount;
    currentDate;
    currentFlow;
    scheduleData;
  } = {
    scheduleDate: '',
    oftenSchedule: '',
    isPaymentScheduled: false,
    numberOfPayments: '',
    selectedFromAccount: {},
    selectedUserDetails: {},
    name: '',
    eachListAmountEntry: [],
    permissibleTransferAmount: '',
    currentDate: new Date(),
    currentFlow: 'new-transaction',
    scheduleData: {},
  };

  private readonly BOTSWANA = 'Botswana';

  constructor(
    private store: Store<any>,
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private paymentUtilities: PaymentUtilitiesService,
    private recipientService: RecipientService,
    private sortBy: SortByPipe,
    private uuidService: UuidGeneratorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private routeState: RouterStateService,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe
  ) {
    this.routeHistory = this.routeState.getHistory();
    const prevUrl = _.last(this.routeHistory);

    this.store
      .select('appReducer', 'loginReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (data) {
          this.isSmeMode = data.isSmeMode;
        }
      });

    this.store
      .select('appReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        if (prevUrl.search('review') !== -1) {
          if (Object.keys(stateData.billerPaymentsReducer.payBillerData).length) {
            const tempBillerData = _.cloneDeep(stateData.billerPaymentsReducer.payBillerData);
            this.payBillerData = tempBillerData.transactions.rOABillerPayments || tempBillerData.transactions.cdiPayments;
          }

          const tempDataPaymentModel = _.cloneDeep(stateData.billerPaymentsReducer.payBillerData.paymentModel);
          this.modifyArray = _.cloneDeep(tempDataPaymentModel.modifyArray);
          delete tempDataPaymentModel.modifyArray;
          this.paymentModel = _.cloneDeep(tempDataPaymentModel);
          this.paymentModel.selectedFromAccount = stateData.billerPaymentsReducer.payBillerData.account;
        } else if (prevUrl.search('scheduleTransaction/receipt') !== -1) {
          this.modifyArray = _.cloneDeep([stateData.editTxnScheduleReducer.editPayBiller]);
          if (this.paymentModel.currentFlow !== 'edit-transaction') {
            this.paymentModel.currentFlow = 'edit-transaction';
          }
        } else {
          this.modifyArray = _.cloneDeep(stateData.billerPaymentsReducer.selectedBiller);

          if (Object.keys(stateData.billerPaymentsReducer.payBillerData).length) {
            const tempBillerData = _.cloneDeep(stateData.billerPaymentsReducer.payBillerData);
            this.payBillerData = tempBillerData.transactions.rOABillerPayments || tempBillerData.transactions.cdiPayments;
          }
        }
      });

    this.getAmountWithdrawalLimit();
    this.mainHeading = this.paymentModel.currentFlow === 'new-transaction' ? 'Pay' : 'Edit Pay';

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
      module: 'payBiller',
    });
  }

  ngOnInit() {
    this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts('local');
    if (!Object.keys(this.paymentModel.selectedFromAccount).length) {
      this.paymentModel.selectedFromAccount = this.userAccountDetails[0];
    }

    if (!Object.keys(this.paymentModel.selectedUserDetails).length) {
      // edit schedule condition here
      if (this.modifyArray[0].isFlowEditSchedule) {
        this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts(this.modifyArray[0].fromAccount.accountCurrency.currencyCode);

        const initialEditScheduleAcc = this.userAccountDetails.filter((value) => {
          return value.number === this.modifyArray[0].fromAccount.number;
        });

        this.paymentModel.selectedFromAccount = initialEditScheduleAcc[0] || this.userAccountDetails[0];
      }

      try {
        this.iteratorModifyArray(this.modifyArray);
        if (this.modifyArray[0].products) {
          this.modifyArray[0].products = this.refineProducts;
        }

        this.paymentModel.selectedUserDetails = this.modifyArray;

        this.paymentModel.eachListAmountEntry = [];
        this.permissibleTransferAmount = this.userAccountDetails[0].availableBalance.amount;
        this.initialUserBalance = this.permissibleTransferAmount;
        this.paymentModel.permissibleTransferAmount = this.permissibleTransferAmount;
      } catch (ex) {
        console.error(ex);
        // error message for no accounts
      }
    }
    this.reviewHeader = {
      name: this.paymentModel.selectedUserDetails[0].nickName || this.paymentModel.selectedUserDetails[0].name,
      nameInit: this.paymentModel.selectedUserDetails[0].name.split('')[0],
      componentName: 'DetailsBiller',
    };

    if (!this.config.isBillerCDI) {
      this.getPastPayments();
    }

    if (this.modifyArray[0].isFlowEditSchedule) {
      this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts(this.paymentModel.selectedFromAccount.accountCurrency.currencyCode);
      const initialEditScheduleAccount = this.userAccountDetails.filter((value) => {
        return value.number === this.paymentModel.selectedFromAccount.number;
      });
      this.paymentModel.selectedFromAccount = initialEditScheduleAccount[0];
      this.permissibleTransferAmount = this.paymentModel.selectedFromAccount;
    }
    this.initialUserBalance = this.permissibleTransferAmount;
    this.paymentModel.permissibleTransferAmount = this.permissibleTransferAmount;
    if (this.config.hideFromAccFromReciept) {
      this.paymentModel.selectedFromAccount = '';
    }

    // set data for next page
    if (this.config.isBillerCDI) {
      this.dataForNextPage = {
        account: this.userAccountDetails[0],
        transactions: {
          cdiPayments: [],
        },
      };
    } else {
      this.dataForNextPage = {
        account: this.userAccountDetails[0],
        transactions: {
          rOABillerPayments: [],
        },
      };
    }
    this.getAmountWithdrawalLimit();
  }

  accountValueChanged(newValue) {
    this.paymentModel.selectedFromAccount = newValue;
    this.getAmountWithdrawalLimit();
  }

  getAmountWithdrawalLimit() {
    const account = this.paymentModel.selectedFromAccount;
    const currency = account && account.accountCurrency ? account.accountCurrency.currencyCode : this.config.localCurrency;
    const monthly = this.accountService.getLimit('MONTHLY', currency);
    const monthlyUtilised = this.accountService.getLimit('MONTHLY_UTILIZED', currency);
    const daily = this.accountService.getLimit('DAILY', currency);
    const dailyUtilised = this.accountService.getLimit('DAILY_UTILIZED', currency);

    if (this.config.showMonthlyTransactionLimit) {
      this.withdrawlLimit = monthly;
      this.limits.dailyWithdrawlLimit = monthly;
      this.limits.usedLimit = monthlyUtilised;
    } else {
      if (daily) {
        this.withdrawlLimit = daily;
        this.limits.dailyWithdrawlLimit = daily;
      }
      if (dailyUtilised) {
        this.limits.usedLimit = dailyUtilised;
      }
    }

    if (this.withdrawlLimit && this.limits.usedLimit && parseFloat(this.withdrawlLimit.amount) && this.limits.usedLimit.amount) {
      this.availableLimit = this.withdrawlLimit.amount - this.limits.usedLimit.amount;
      this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
    } else {
      this.availableLimit = null;
    }
  }

  amountChanged(amount) {
    this.totalPayableAmount = amount ? amount : 0;
  }

  getPastPayments() {
    let recipientType;
    let recipientId;
    if (this.config.isBillerCDI === true) {
      recipientType = 'COMPANY';
      recipientId = this.modifyArray[0].payeeId.toString();
    } else {
      recipientType = 'BILLER';
      recipientId = this.modifyArray[0].roaBillerId || this.modifyArray[0].recipientId;
    }
    this.modifyArray[0].recipientId = recipientId;
    const pastPaymentsRequest = this.paymentUtilities.fetchPreviousPaymentDetails(this.modifyArray, recipientType);
    this.recipientService
      .callRecipient(pastPaymentsRequest)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        const data = response.body;
        if (data.recipientPayments[0] && data.recipientPayments[0].recentPayments.length > 0) {
          const sortPayByDate = this.sortBy.transform(data.recipientPayments[0].recentPayments, 'desc', 'date');
          this.paymentModel.selectedUserDetails[0].pastDetails = sortPayByDate[0];

          if (this.BOTSWANA === this.config.countryName && sortPayByDate[0] && sortPayByDate[0].amount) {
            this.reviewHeader = {
              ...this.reviewHeader,
              first: `${sortPayByDate[0].amount.currency} ${this.decimalPipe.transform(sortPayByDate[0].amount.amount, '1.2-2')}`,
              second: `${this.datePipe.transform(sortPayByDate[0].date, 'd MMMM y HH:mm')}`,
            };
          }
        }
      });
  }

  iteratorModifyArray(data) {
    data.forEach((value, index) => {
      if (!this.modifyArray[0].isFlowEditSchedule) {
        this.modifyArray[index].amount = {
          currency: this.userAccountDetails[0].availableBalance.currency,
          amount: this.payBillerData ? this.payBillerData[index].amount.amount : '',
        };
      } else {
        this.modifyArray[index].amount = {
          currency: this.userAccountDetails[0].availableBalance.currency,
          amount: this.modifyArray[index].scheduledAmount.amount.toString(),
        };
      }
      if (value.products && value.products[0]) {
        this.refineProducts = [];
        this.lastProduct = value.products[value.products.length - 1];
        value.products.forEach((val) => {
          if (val.name === 'Specify Amount') {
            this.manualEntryAmount = val;
          } else {
            this.refineProducts.push(val);
          }
        });
        this.sortProducts();
      }
    });
  }

  sortProducts() {
    this.refineProducts = this.sortBy.transform(this.refineProducts, 'asc', 'name');
    this.refineProducts.push(this.manualEntryAmount);
  }

  goBack() {
    if (this.paymentModel.currentFlow === 'edit-transaction') {
      return this.router.navigate(['scheduleTransaction/receipt']);
    } else {
      return this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
    }
  }

  isReadonly(fieldName) {
    let returnBool = false;
    if (fieldName === 'Phone Number') {
      returnBool = this.paymentModel.selectedUserDetails[0].name === 'Airtel Bill Payment (Postpaid)';
    }
    return returnBool;
  }

  getScheduleValues(values) {
    if (values) {
      this.paymentModel = {
        ...this.paymentModel,
        ...values,
      };
        this.paymentModel.selectedUserDetails.forEach((elem, index) => {
          if (this.paymentDetailsForm.form.value[`amount${index}`]) {
            (this.paymentDetailsForm.form.controls[`amount${index}`] as FormGroup).setErrors(null);
            (this.paymentDetailsForm.form.controls[`amount${index}`] as FormGroup).markAsTouched();
          }
        });
      this.paymentModel.scheduleData = values;
    }
  }

  goToConfirmation() {
    const objectCopy = _.cloneDeep(this.paymentModel.selectedUserDetails);
    objectCopy.forEach((value, index) => {
      let thisProduct;
      const thisAmount = value.amount;
      if (!value.products) {
        thisProduct = value.products;
      }
      delete value.amount;
      delete value.products;
      delete value.recentPayment;
      delete value.beneLetters;
      delete value.pastDetails;
      delete value.recipientId;

      if (!this.paymentModel.oftenSchedule.value || this.paymentModel.oftenSchedule.value === 'Just Once') {
        this.paymentModel.oftenSchedule = {
          value: 'Single',
          placeholder: 'once',
          id: 1,
        };
        this.paymentModel.numberOfPayments = 1;
        if (!this.paymentModel.scheduleDate) {
          this.paymentModel.scheduleDate = new Date();
        }
      }
      const futureDatedData = {
        repeatInterval: this.paymentModel.oftenSchedule.value.toUpperCase(),
        repeatNumber: parseInt(this.paymentModel.numberOfPayments, 10),
        fromDate: this.paymentModel.scheduleDate,
      };
      if (this.config.isBillerCDI === true) {
        this.dataForNextPage.transactions.cdiPayments[index] = {
          recipient: value,
          futureDatedInstruction: futureDatedData,
          transactionId: this.uuidService.getUUID(),
          amount: thisAmount,
          product: thisProduct,
        };
        if (this.modifyArray[0].isFlowEditSchedule) {
          this.dataForNextPage.transactions.cdiPayments[index].recentPayments = this.paymentModel.selectedUserDetails[0].recentPayments;
        }
      } else {
        this.dataForNextPage.transactions.rOABillerPayments[index] = {
          biller: value,
          futureDatedInstruction: futureDatedData,
          transactionId: this.uuidService.getUUID(),
          amount: thisAmount,
          product: thisProduct,
        };
        if (this.modifyArray[0].isFlowEditSchedule) {
          this.dataForNextPage.transactions.rOABillerPayments[index].recentPayments = this.paymentModel.selectedUserDetails[0].recentPayments;
        }
      }
    });
    this.dataForNextPage.paymentModel = this.paymentModel;
    this.dataForNextPage.paymentModel.modifyArray = this.modifyArray;
    this.dataForNextPage.paymentModel.amount = this.paymentModel.selectedUserDetails[0].amount;
    this.dataForNextPage.account = this.paymentModel.selectedFromAccount;
    if (this.dataForNextPage.paymentModel && !this.dataForNextPage.paymentModel.editData) {
      this.dataForNextPage.paymentModel.editData = this.paymentModel.selectedUserDetails[0];
    }
    this.store.dispatch(setPayBillerData({ payBillerData: this.dataForNextPage }));
    return this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  toggleClicked(data) {
    this.toggleStatus = data;
  }
  restrictLength(event) {
    if (event.target.value.length == 10 || event.key === 'e') return false;
  }
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
