import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TaxPaymentDetailsComponent } from './tax-payment-details/tax-payment-details.component';
import { TaxPaymentReceiptComponent } from './tax-payment-receipt/tax-payment-receipt.component';
import { TaxPaymentConfirmationComponent } from './tax-payment-confirmation/tax-payment-confirmation.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'details',
        component: TaxPaymentDetailsComponent,
      },
      {
        path: 'confirmation',
        component: TaxPaymentConfirmationComponent,
      },
      {
        path: 'receipt',
        component: TaxPaymentReceiptComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaxPaymentRoutingModule {}
