import { environment } from './../../../../environments/environment';
import { Directive, Input, Output, EventEmitter, OnChanges, ElementRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn, NgModel } from '@angular/forms';

@Directive({
  selector: '[sbgAmountValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: AmountValidatorDirective,
      multi: true,
    },
  ],
})
export class AmountValidatorDirective implements Validator, OnChanges {
  @Input() amountValidateOperator: any;
  @Input() fromAccount: any;
  @Input() availableLimit: any;
  @Input() amountShouldBeMultipleOf: any;
  @Input() amountRangeFrom: any;
  @Input() amountRangeTo: any;

  @Output() amountValueChanged = new EventEmitter<number>();
  @Input() afcRequired = true;
  @Input() private controlField: NgModel;
  config = environment.config;
  controlValue;
  validator: ValidatorFn;

  constructor(private element: ElementRef) {
    element.nativeElement.addEventListener('wheel', (e) => e.preventDefault());
    this.validator = this.amountValidator();
  }

  ngOnChanges(change: any) {
    if (change.fromAccount) {
      // this.fromAccount = change.fromAccount.currentValue;
      this.amountValidationFn(this.controlValue, true);
    }
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  separateAmount(Number) {
    return Intl.NumberFormat('en-US').format(Number);
  }

  amountValidationFn(controlValue, control = null) {
    const message = {
      ...this.checkBoundariesValue(controlValue),
      ...this.checkInsufficientFund(controlValue),
      ...this.checkMonthlyLimit(controlValue),
      ...this.checkDailyLimit(controlValue),
      ...this.checkInvalidAmount(controlValue),
    };
    
    if (control && this.controlField && this.controlField.control) {
      this.controlField.control.setErrors(message && Object.keys(message).length ? message : null);
    }
    return message ? message : null;
  }

  checkBoundariesValue(controlValue) {
    const amountRangeFrom = parseFloat(this.amountRangeFrom);
    const amountRangeTo = parseFloat(this.amountRangeTo);
    let invalidBoundariesValue = {};
    if (controlValue && amountRangeFrom >= 0) {
      if (amountRangeTo >= 0) {
        if (controlValue < amountRangeFrom || controlValue > amountRangeTo) {
          invalidBoundariesValue = {
            errorMsg: {
              message: `Please enter an amount between ${this.separateAmount(amountRangeFrom)}
                     and ${this.separateAmount(amountRangeTo)}`,
            },
          };
          if (this.amountValidateOperator) {
            invalidBoundariesValue = {
              errorMsg: {
                message: `Please enter an amount between ${this.separateAmount(amountRangeFrom)}
                     and ${this.separateAmount(amountRangeTo)} for ${this.amountValidateOperator}`,
              },
            };
          }
        }
        if (this.amountShouldBeMultipleOf) {
          const amountShouldBeMultipleOf = parseFloat(this.amountShouldBeMultipleOf);
          const remainder = controlValue % amountShouldBeMultipleOf;
          if (controlValue < amountRangeFrom || controlValue > amountRangeTo || remainder !== 0) {
            invalidBoundariesValue = {
              errorMsg: {
                message: `Voucher amount must be between ${this.separateAmount(amountRangeFrom)} and
                  ${this.separateAmount(amountRangeTo)} and in multiples of
                  ${this.separateAmount(amountShouldBeMultipleOf)}`,
              },
            };
          }
        }
      } else {
        if (controlValue < amountRangeFrom) {
          invalidBoundariesValue = {
            errorMsg: {
              message: `Please enter an amount from ${this.separateAmount(amountRangeFrom)}`,
            },
          };
        }
      }
    } else {
      if (controlValue && amountRangeTo > 0) {
        if (controlValue > amountRangeTo) {
          invalidBoundariesValue = {
            errorMsg: {
              message: `Amount can not exceed ${this.separateAmount(amountRangeTo)}`,
            },
          };
        }
      }
    }
    return invalidBoundariesValue;
  }

  checkInsufficientFund(controlValue) {
    let insufficientFund = {};
    if (this.afcRequired && this.fromAccount && this.fromAccount.availableBalance && controlValue > this.fromAccount.availableBalance.amount) {
      insufficientFund = {
        insufficientFunds: {
          message: 'Insufficient funds',
        },
      };
    }
    return insufficientFund;
  }

  checkMonthlyLimit(controlValue) {
    const localCurrency = this.config.localCurrency;
    const availableLimit = parseFloat(this.availableLimit);
    let monthlyLimit = {};
    if (
      this.fromAccount &&
      this.fromAccount.availableBalance &&
      localCurrency === this.fromAccount.availableBalance.currency &&
      this.config.showMonthlyTransactionLimit &&
      availableLimit &&
      controlValue > availableLimit
    ) {
      monthlyLimit = {
        monthlyWithdrawalLimitCrossed: {
          message: 'Monthly withdrawal limit exceeded',
        },
      };
    }
    return monthlyLimit;
  }

  checkDailyLimit(controlValue) {
    const localCurrency = this.config.localCurrency;
    const availableLimit = parseFloat(this.availableLimit);

    let dailyLimit = {};
    if (
      this.fromAccount &&
      this.fromAccount.availableBalance &&
      localCurrency === this.fromAccount.availableBalance.currency &&
      !this.config.showMonthlyTransactionLimit &&
      availableLimit &&
      controlValue > availableLimit
    ) {
      dailyLimit = {
        dailyWithdrawalLimitCrossed: {
          message: 'Daily withdrawal limit exceeded',
        },
      };
    }
    return dailyLimit;
  }

  checkInvalidAmount(controlValue) {
    let invalidAmount = {};
    if ((controlValue && controlValue < 0) || controlValue === 0) {
      invalidAmount = {
        amountInvalid: {
          message: 'Amount is invalid',
        },
      };
    }
    return invalidAmount;
  }

  amountValidator(): ValidatorFn {
    return (control: FormControl) => {
      this.controlValue = parseFloat(control.value);
      this.amountValueChanged.emit(this.controlValue);
      return this.amountValidationFn(this.controlValue);
    };
  }
}
