import { Component, NgZone, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoaderService } from '../loader/loader.service';
import { RouterStateService } from '../../services/route-state/route-state.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;

  errorOccured: boolean;
  isLoggedIn;
  errorMessage;
  routeHistory;
  constructor(
    private dataSharingService: DataSharingService,
    private ref: ChangeDetectorRef,
    private store: Store<{ appReducer }>,
    private ngZone: NgZone,
    private router: Router,
    private routeState: RouterStateService,
    private loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
    });

    this.dataSharingService.getErrorModal().subscribe((value) => {
      this.errorOccured = value;
      this.ref.detectChanges();
    });
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Error',
      subTitle: '',
      showBack: false,
      showClose: false,
      steps: false,
    });
    this.dataSharingService.getErrorMessage().subscribe((value) => {
      this.errorMessage = value;
    });
  }

  closeErrorModal() {
    this.dataSharingService.setErrorMessage('');
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.isLoggedIn = value.loggedIn;
      if (value.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
    });

    this.dataSharingService.setErrorModal(false);
    this.dataSharingService.setErrorMessage(null);

    this.loaderService.hide();
    this.ngZone.run(() => {
      if (this.isLoggedIn) {
        this.routeHistory = this.routeState.getHistory();
        const prevUrl = _.last(this.routeHistory);
        if (this.navigateErrorModal(prevUrl) === 'samepage') {
          // do nothing
        } else if (this.navigateErrorModal(prevUrl) === 'login') {
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      } else {
        this.router.navigate(['/login']);
      }
    });
  }
  navigateErrorModal(prevUrl) {
    let result = '';
    const toSame = [
      '/sendMoney/cancelInstantMoney/list',
      '/review',
      '/payments/biller/details',
      '/recipients-management/biller/details-biller',
      '/approvals',
      '/transaction-management',
      '/payments/beneficiary/once-off-beneficiary-details',
      '/registration/securityQuestions',
      '/registration/verifyUser',
      '/registration/createPassword',
      '/profileAndSettings/username',
      '/otp',
      '/forcedChange/securityQuestions'
    ];
    const toLogin = ['/forcedChange', '/forgotPassword', '/registration'];
    toSame.forEach((element) => {
      if (element === prevUrl) {
        result = 'samepage';
      }
    });
    toLogin.forEach((element) => {
      if (element === prevUrl) {
        result = 'login';
      }
    });
    return result;
  }
}
