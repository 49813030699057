import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AnswerSecurityQuestionsComponent } from './answer-security-questions/answer-security-questions.component';
import { ForgotPasswordComponent } from './forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ForgotPasswordComponent,
      },
      {
        path: 'answerSecurityQuestions',
        component: AnswerSecurityQuestionsComponent,
      },
      {
        path: 'changePassword',
        component: ResetPasswordComponent,
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class ForgotPasswordRoutingModule { }
