import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FormService } from '../../../core/form-service/form-service.service';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// tslint:disable-next-line:no-duplicate-imports
import _moment, { default as _rollupMoment } from 'moment';
import { ControlContainer, NgForm } from '@angular/forms';
import { DateUtil } from '../../../core/utility-classes/date.util';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface ScheduleTimes {
  value?: string;
  placeholder?: string;
  id?: string;
}

@Component({
  selector: 'app-schedule-payment',
  templateUrl: './schedule-payment.component.html',
  styleUrls: ['./schedule-payment.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SchedulePaymentComponent implements OnInit, OnChanges {
  @Input() allFieldsShow: any;
  @Input() isPaymentScheduled: any;
  @Input() moduleName: any;
  @Input() editData: any;
  @Input() amount: any;
  @Input() numberOfPayments: any;
  @Input() oftenSchedule: any;
  @Input() scheduleDate: any;
  @Input() currency: any;
  @Input() isDetails: boolean;
  @Output() scheduleValues: EventEmitter<any> = new EventEmitter();

  isReccuringFirstDateToday: any;
  reccurenceDataFetched: any;
  hideOtherFields = false;
  isEditPaymentScheduled = false;

  editScheduleType: any;
  module: any;

  scheduleFrequency: ScheduleTimes[] = [
    { value: 'Just Once', placeholder: 'once', id: '1' },
    { value: 'Daily', placeholder: 'daily', id: '2' },
    { value: 'Weekly', placeholder: 'weekly', id: '3' },
    { value: 'Monthly', placeholder: 'monthly', id: '4' },
    { value: 'Quarterly', placeholder: 'quarterly', id: '5' },
    { value: 'Yearly', placeholder: 'yearly', id: '6' },
  ];

  todayDate = moment();
  minDate = moment();

  constructor(private formService: FormService) {}

  ngOnInit() {
    if (this.isPaymentScheduled) {
      if (this.oftenSchedule && this.oftenSchedule.value) {
        const oftenSchedule = this.scheduleFrequency.filter((val: any) => {
          return val.value.toUpperCase() === this.oftenSchedule.value.toUpperCase();
        });
        this.oftenSchedule = oftenSchedule[0];
      } else {
        this.oftenSchedule = this.scheduleFrequency[0];
      }
    } else {
      this.oftenSchedule = this.scheduleFrequency[0];
      this.scheduleDate = moment();
      this.allFieldsShow = false;
    }

    if (this.editData && this.moduleName === 'onceOff') {
      if (this.moduleName === 'onceOff') {
        this.hideOtherFields = true;
      }
      this.allFieldsShow = true;
      this.isPaymentScheduled = true;

      if (this.editData && this.editData.currentDate) {
        this.scheduleDate = moment(this.editData.currentDate);
      } else if (this.editData && this.editData.nextPaymentDate) {
        this.scheduleDate = moment(this.editData.nextPaymentDate);
      } else {
        this.scheduleDate = moment(this.scheduleDate);
      }

      if (DateUtil.areSame(new Date(), this.scheduleDate, 'days')) {
        if (this.oftenSchedule.value.toUpperCase() === 'JUST ONCE') {
          this.isPaymentScheduled = false;
        }
        this.isReccuringFirstDateToday = true;
      }
    } else if (this.editData) {
      this.allFieldsShow = false;
      this.isEditPaymentScheduled = true;
      this.isPaymentScheduled = true;

      /* Often schedule */
      const repeatIntervalName = this.editData.futureDatedInstruction.repeatInterval.toUpperCase();
      if (repeatIntervalName === 'SINGLE') {
        this.oftenSchedule = this.scheduleFrequency[0];
      } else {
        const oftenSchedule = this.scheduleFrequency.filter((val: any) => {
          return val.value.toUpperCase() === repeatIntervalName;
        });
        this.oftenSchedule = oftenSchedule[0];
      }

      this.numberOfPayments = this.editData.futureDatedInstruction.repeatNumber;

      this.scheduleDate = moment(this.editData.nextPaymentDate);

      if (DateUtil.areSame(new Date(), this.scheduleDate, 'days')) {
        if (this.oftenSchedule.value.toUpperCase() === 'JUST ONCE') {
          this.isPaymentScheduled = false;
        }
        this.isReccuringFirstDateToday = true;
      }

      this.editScheduleType = this.editData.type;
      this.module = 'editSchedule';
      this.sendValues();
    } else if (this.isPaymentScheduled) {
      this.hideOtherFields = false;
      this.allFieldsShow = true;
      this.isPaymentScheduled = true;

      if (this.editData && this.editData.currentDate) {
        this.scheduleDate = moment(this.editData.currentDate);
      } else if (this.editData && this.editData.nextPaymentDate) {
        this.scheduleDate = moment(this.editData.nextPaymentDate);
      } else {
        this.scheduleDate = moment(this.scheduleDate);
      }

      if (DateUtil.areSame(new Date(), this.scheduleDate, 'days')) {
        if (this.oftenSchedule.value.toUpperCase() === 'JUST ONCE') {
          this.isPaymentScheduled = false;
        }
        this.isReccuringFirstDateToday = true;
      }
    }
  }

  ngOnChanges(change: any) {
    if (change.amount) {
      this.amount = this.amount || 0;
    }
  }

  oftenScheduleTimeChanged() {
    this.isPaymentScheduled = !DateUtil.areSame(new Date(), this.scheduleDate, 'days');
    if (this.oftenSchedule && this.oftenSchedule.id !== '1') {
      this.isPaymentScheduled = true;
    }
    this.sendValues();
  }

  /**
   * Call on changes in number of payment
   */
  numberOfPaymentsChanged() {
    this.sendValues();
  }

  /**
   * Date change function
   * data : changed date
   */
  dateChanged() {
    this.scheduleDate = this.dateToISO(this.scheduleDate);
    this.oftenScheduleTimeChanged();
  }

  dateToISO(scheduleDateReq) {
    const date = scheduleDateReq._d;

    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    const result = date.toISOString();

    return result;
  }

  get isShowFiled() {
    if (this.oftenSchedule && this.oftenSchedule.id) {
      return this.oftenSchedule.id !== '1';
    }
  }

  cancelPaymentScheduleFn() {
    if (!this.editData) {
      this.isPaymentScheduled = false;
      this.numberOfPayments = 1;
      this.oftenSchedule = {};
      this.sendValues();
    }
    this.allFieldsShow = false;
  }

  openPaymentScheduleFn() {
    if (this.moduleName === 'onceOff') {
      this.hideOtherFields = true;
    }

    this.allFieldsShow = true;

    if (!this.isEditPaymentScheduled) {
      this.oftenSchedule = this.scheduleFrequency[0];
      this.numberOfPayments = 1;
    }
    this.oftenScheduleTimeChanged();
  }

  sendValues() {
    if (this.editData) {
      this.editData.amount=parseFloat(this.editData.amount)
      if (!this.editData.amount) {
        this.editData.amount = { amount: this.amount, currency: this.currency };
      } else {
        if (this.editData.amount && this.editData.amount.amount) {
          this.editData.amount.amount = this.amount;
          this.editData.amount.currency = this.currency;
        }
      }
      if (this.editData.futureDatedInstruction) {
        this.editData.futureDatedInstruction.repeatNumber = this.numberOfPayments || 1;
        this.editData.futureDatedInstruction.repeatInterval = this.oftenSchedule.value.toUpperCase();
      }

      this.editData.nextPaymentDate = this.scheduleDate;
    }

    const values: any = {
      allFieldsShow: this.allFieldsShow,
      isPaymentScheduled: this.isPaymentScheduled,
      oftenSchedule: this.oftenSchedule,
      numberOfPayments: this.numberOfPayments,
      scheduleDate: this.scheduleDate,
    };

    if (this.module === 'editSchedule') {
      values.module = 'editSchedule';
      values.editData = this.editData;
    }
    this.scheduleValues.emit(values);
  }
}
