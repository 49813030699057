import { Component, Input } from '@angular/core';

@Component({
  selector: 'sbg-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.scss'],
})
export class StatusIconComponent {
  @Input() status: string;
  

  getBorderClass(statusValue) {
    return statusValue === 'SUCCESS' ? 'status__success' : 'status__failed';
  }
}
