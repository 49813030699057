import { Component, OnInit } from "@angular/core";
import { DataSharingService } from "../../core/data-sharing/data-sharing.service";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-close-current-module",
  templateUrl: "./close-current-module.component.html",
  styleUrls: ["./close-current-module.component.scss"],
})
export class CloseCurrentModuleComponent implements OnInit {
  moduleData;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private store: Store<{ appReducer: { loginReducer: { isSmeMode; authenticateResponse } } }>,
  ) {
  }

  ngOnInit() {
    this.dataSharingService.getCloseCurrentModule().subscribe((value) => {
      this.moduleData = value;

      if (this.moduleData.financeType === "FINANCIAL_RULES") {
        this.moduleData.financeValue = "financialRules";
      } else if (this.moduleData.financeType === "NON_FINANCIAL_RULES") {
        this.moduleData.financeValue = "nonFinancialRules";
      }
    });
  }

  clearModuleData() {
    this.dataSharingService.setCloseCurrentModule({});
  }

  rightButtonClickedFn() {
    this.dataSharingService.setCloseCurrentModule({
      stopScheduleFnCall: true,
    });
  }

  leftButtonClickedFn() {
    if (this.moduleData.routeBackTo) {
      this.router.navigate([this.moduleData.routeBackTo]);
    }
    this.clearModuleData();
  }
}
