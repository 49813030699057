import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import { AppConstantService } from '../../../../core/app-constant/app-constant.service';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { formatDate } from '@angular/common';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TransactionService } from '../../../../core/transaction-service/transaction.service';

@Component({
  selector: 'app-tax-payment-receipt',
  templateUrl: './tax-payment-receipt.component.html',
  styleUrls: ['./tax-payment-receipt.component.scss'],
})
export class TaxPaymentReceiptComponent implements OnInit {
  timeoutMessage: any;
  bankLogo: string;
  taxReceiptModel;
  currentDate = new Date();
  transactionId: any;
  beneficiaryName: string;
  beneficiaryAccountNumber: string;
  taxType: string;

  receiptDetails: ReceiptDetail[];
  payloadData: any;
  private readonly DATE_FORMAT = 'dd MMMM yyyy';
  private readonly DATE_LOCALE = 'en-US';
  constructor(
    private dataSharingService: DataSharingService,
    private TransactionService: TransactionService,
    private appConstant: AppConstantService,
    private store: Store<{ appReducer }>
  ) {
    dataSharingService.setHeadPagination({
      mainTitle: 'Pay & Transfer',
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'taxPayment',
    });

    store.select('appReducer', 'taxPaymentReducer').subscribe((stateData) => {
      if (stateData) {
        this.taxReceiptModel = stateData.taxPaymentReceiptData;
        this.payloadData = stateData.taxPaymentReceiptData.payload;
        this.beneficiaryName = stateData.taxPaymentReceiptData.beneficiaryName;
        this.beneficiaryAccountNumber = stateData.taxPaymentReceiptData.beneficiaryAccountNumber;
        this.taxType = stateData.taxPaymentReceiptData.taxType;
        this.timeoutMessage =
          'Your transaction is still being processed with the TAX authority, please check your account after 10 minutes to confirm payment';

        this.transactionId = this.taxReceiptModel ? this.TransactionService.getTxnId(this.taxReceiptModel.transactionResults[0]) : '';

        if (!this.transactionId) {
          const newTransactionResults = [
            {
              responseCode: { responseType: 'WARNING', message: this.timeoutMessage },
            },
          ];
          this.taxReceiptModel = { ...this.taxReceiptModel, ...{ transactionResults: newTransactionResults } };
        }
      }
    });
  }

  ngOnInit() {
    this.bankLogo = this.appConstant.BankLogo;
    this.receiptDetails = this.getReceiptData();
  }

  getTaxReceiptModelAmount() {
    return this.payloadData.transactions.prepaidPurchases[0].amount;
  }

  getReceiptData(): ReceiptDetail[] {
    return [
      {
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.taxReceiptModel.transactionResults[0].transactionId,
      },
      {
        header: ReceiptHeaders.FROM,
        value: this.taxReceiptModel.account[0].accountFullName,
      },
      {
        header: ReceiptHeaders.FROM_ACCOUNT,
        value: this.taxReceiptModel.account[0].number,
      },
      {
        header: ReceiptHeaders.DATE,
        value: formatDate(this.currentDate, this.DATE_FORMAT, this.DATE_LOCALE),
      },
      {
        header: ReceiptHeaders.BENEFICIARY_NAME,
        value: this.beneficiaryName,
      },
      {
        header: ReceiptHeaders.BENEFICIARY_ACCOUNT_NUMBER,
        value: this.beneficiaryAccountNumber,
      },
      {
        header: ReceiptHeaders.TAX_MODE,
        value: this.payloadData.transactions.prepaidPurchases[0].taxDetails.mode,
      },
      {
        header: ReceiptHeaders.BUSINESS_PARTNER_NAME,
        value: this.payloadData.transactions.prepaidPurchases[0].taxDetails.businessPartnerNumber,
      },
      {
        header: ReceiptHeaders.TYPE,
        value: this.taxType,
      },
      {
        header: ReceiptHeaders.PROVINCE,
        value: this.payloadData.transactions.prepaidPurchases[0].taxDetails.region,
      },
      {
        header: this.taxReceiptModel.taxType === 'DOMESTIC' ? ReceiptHeaders.DOMESTIC_TAX_OFFICE : ReceiptHeaders.PORT_OF_ENTRY,
        value: `${this.payloadData.transactions.prepaidPurchases[0].taxDetails.taxOfficeCode.key} -
                ${this.payloadData.transactions.prepaidPurchases[0].taxDetails.taxOfficeCode.value}`,
      },
    ];
  }
}
