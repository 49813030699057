import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SortByPipe } from '../../../shared/pipes/sort/sort-by.pipe';
import * as _ from 'lodash';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// tslint:disable-next-line:no-duplicate-imports
import _moment, { default as _rollupMoment } from 'moment';
import { DateUtil } from '../../../core/utility-classes/date.util';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
export interface ScheduleTimes {
  value?: string;
  placeholder?: string;
  id?: string;
}
@Component({
  selector: 'app-payment-repeater',
  templateUrl: './payment-repeater.component.html',
  styleUrls: ['./payment-repeater.component.scss'],
})
export class PaymentRepeaterComponent implements OnInit, OnChanges {
  @Input() editFlow: any;
  @Input() detailedInfo: any;
  @Input() oftenSchedule: any;
  @Input() amount: any;
  @Input() currency: any;
  @Input() scheduleDate: any;
  @Input() numberOfPayments: any;
  @Input() isReccuringFirstDateTodayFlag: any;

  scheduleFrequency: ScheduleTimes[] = [
    { value: 'Just Once', placeholder: 'once', id: '1' },
    { value: 'Daily', placeholder: 'daily', id: '2' },
    { value: 'Weekly', placeholder: 'weekly', id: '3' },
    { value: 'Monthly', placeholder: 'monthly', id: '4' },
    { value: 'Quarterly', placeholder: 'quarterly', id: '5' },
    { value: 'Yearly', placeholder: 'yearly', id: '6' },
  ];

  detailedInfoCopy: any;
  isInitialized: any;
  filterNextpayment: any;
  filterNextpaymentIndex: any;
  reccurenceDataFetched: any;

  constructor(private sortBy: SortByPipe) {}

  ngOnInit() {
    if (!this.amount) {
      this.amount = 0;
    }

    if (!this.isInitialized) {
      if (this.detailedInfo) {
        this.detailedInfoCopy = _.cloneDeep(this.detailedInfo);

        this.getDetailTxnRepeatData();
      } else {
        this.getRecurringData();
      }
    }
  }

  ngOnChanges(change: any) {
    if (change['amount']) {
      this.amount = parseFloat(this.amount)|| 0;
      if (this.detailedInfo && this.detailedInfo.amount) {
        if (this.detailedInfo.amount && parseFloat(this.detailedInfo.amount.amount)) {
          this.detailedInfo.amount.amount = this.amount;
        }
        if (this.detailedInfoCopy && this.detailedInfoCopy.amount) {
          if (this.detailedInfoCopy.amount && parseFloat(this.detailedInfoCopy.amount.amount)){
            this.detailedInfoCopy.amount.amount = this.amount;
          } else {
            const amountObj = {
              amount: this.amount,
              currency: this.detailedInfo.currency,
            };
            this.detailedInfoCopy.amount = amountObj;
          }
        }
      }
    }
    if (change['oftenSchedule'] || change['numberOfPayments'] || change['scheduleDate']) {
      this.isInitialized = true;
      if (this.detailedInfo) {
        this.detailedInfoCopy = _.cloneDeep(this.detailedInfo);

        this.getDetailTxnRepeatData();
      } else {
        this.getRecurringData();
      }
    }
  }

  getRecurringData(returnArray?: any, completedTxnArray?: any, upcomingTxn?: any) {
    let scheduleTypeName: any;
    let targetDate: any;

    const frequencyConfig = {
      DAILY: 'days',
      WEEKLY: 'weeks',
      MONTHLY: 'months',
      QUARTERLY: 'quarters',
      YEARLY: 'years',
    };

    let isReccuringFirstDateToday: any;
    this.numberOfPayments = upcomingTxn || this.numberOfPayments || 0;
    this.amount = this.amount || 0;
    const noOfRepitions = this.numberOfPayments;

    if (!this.oftenSchedule) {
      this.oftenSchedule = this.scheduleFrequency[0];
    }
    const scheduleType = this.oftenSchedule.id;
    scheduleTypeName = this.oftenSchedule.placeholder;

    if ((noOfRepitions || noOfRepitions !== 0) && scheduleType !== '1' && scheduleType !== '') {
      const dates = [];
      const dateObj: any = {};

      scheduleTypeName = scheduleTypeName.toUpperCase();

      // Make 1st object of array
      dateObj.dateOfSchedule = DateUtil.format(this.scheduleDate, DateUtil.DEFAULT_DATE_FORMAT);
      dateObj.formattedDate = DateUtil.format(this.scheduleDate, DateUtil.DD_MMM_YY);
      dateObj.formattedFullDate = DateUtil.format(this.scheduleDate, DateUtil.DDDD_DO_MMMM_YYYY);
      dateObj.ordinal = 'First';

      // Push 1st alue in the array
      dates.push(dateObj);

      // Next date
      let nextDate = this.scheduleDate;

      for (let i = 0; i < noOfRepitions - 1; i++) {
        nextDate = DateUtil.add(nextDate, 1, frequencyConfig[scheduleTypeName]);

        // make next object
        const nxtDateObj: any = {};
        nxtDateObj.dateOfSchedule = DateUtil.format(nextDate, DateUtil.DEFAULT_DATE_FORMAT);
        nxtDateObj.formattedDate = DateUtil.format(nextDate, DateUtil.DD_MMM_YY);
        nxtDateObj.formattedFullDate = DateUtil.format(this.scheduleDate, DateUtil.DDDD_DO_MMMM_YYYY);

        if (i + 2 < 10) {
          nxtDateObj.ordinal = '0' + (i + 2).toString();
        } else {
          nxtDateObj.ordinal = (i + 2).toString();
        }

        if (i === noOfRepitions - 2) {
          nxtDateObj.ordinal = 'Last';
        }

        dates.push(nxtDateObj);
      }

      this.reccurenceDataFetched = dates;
      targetDate = this.reccurenceDataFetched[0].dateOfSchedule;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isReccuringFirstDateToday = DateUtil.areSame(new Date(), targetDate, 'days');
    }

    if (!noOfRepitions || noOfRepitions === 0) {
      this.reccurenceDataFetched = [];
    }

    if (this.isReccuringFirstDateTodayFlag) {
      this.reccurenceDataFetched.splice(0, 1);
    }

    if (returnArray) {
      if (!completedTxnArray) {
        completedTxnArray = [];
      }

      if (this.reccurenceDataFetched && this.reccurenceDataFetched.length) {
        this.reccurenceDataFetched = completedTxnArray.concat(this.reccurenceDataFetched);
      }
      const nextPaymentDate = DateUtil.format(this.detailedInfoCopy.nextPaymentDate, DateUtil.DD_MMM_YY);

      const totalLenght = this.reccurenceDataFetched ? this.reccurenceDataFetched.length : 0;

      if (this.reccurenceDataFetched && this.reccurenceDataFetched.length) {
        this.reccurenceDataFetched = _.map(this.reccurenceDataFetched, (itm, ind) => {
          const tempObj = {
            ordinal: '',
            formattedDate: itm.formattedDate || DateUtil.format(itm.date, DateUtil.DD_MMM_YY),
          };

          if (ind === 0) {
            tempObj.ordinal = 'First';
          } else if (ind <= 8) {
            tempObj.ordinal = '0' + (ind + 1).toString();
          } else {
            tempObj.ordinal = (ind + 1).toString();
          }

          if (ind === totalLenght - 1) {
            tempObj.ordinal = 'Last';
          }

          if (itm.formattedDate === nextPaymentDate) {
            if (!itm.transactionStatus) {
              tempObj.ordinal = 'Next';
            } else {
              tempObj.ordinal = '0' + (ind + 1).toString();
            }
          }

          itm = { ...itm, ...tempObj };
          return itm;
        });

        this.filterNextpayment = _.find(this.reccurenceDataFetched, (itm, ind) => {
          if (itm.formattedDate === nextPaymentDate) {
            if (!itm.transactionStatus) {
              itm.ordinal = 'Next';
            } else {
              itm.ordinal = '0' + (ind + 1).toString();
            }
            return true;
          }
        });

        this.filterNextpaymentIndex = _.findIndex(this.reccurenceDataFetched, (itm) => {
          return itm.formattedDate === nextPaymentDate && !itm.transactionStatus;
        });
      }
    }
  }

  getRecurringDataFn(completedTxnArray: any, upcomingTxn: any) {
    if (!this.editFlow) {
      this.currency = this.detailedInfoCopy.amount.currency;
      this.amount = parseFloat(this.detailedInfoCopy.amount.amount).toFixed(2) || 0;
      this.scheduleDate = moment(this.detailedInfoCopy.nextPaymentDate);

      if (this.detailedInfoCopy.futureDatedInstruction && this.detailedInfoCopy.futureDatedInstruction.repeatInterval) {
        let repeatIntervalName = this.detailedInfoCopy.futureDatedInstruction.repeatInterval.toUpperCase();
        if (repeatIntervalName === 'SINGLE') {
          repeatIntervalName = 'Just Once'.toUpperCase();
        }
        this.oftenSchedule = _.find(this.scheduleFrequency, (item) => {
          return item.value.toUpperCase() === repeatIntervalName;
        });
      }
    } else {
      this.detailedInfoCopy.amount = {
        currency: this.currency,
        amount: this.amount,
      };
    }

    if (this.oftenSchedule) {
      this.getRecurringData(true, completedTxnArray, upcomingTxn);
    } else {
      this.reccurenceDataFetched = [];
    }
  }

  getDetailTxnRepeatData() {
    let upcomingTxn = 0;
    let completedTxnArray = [];

    if (this.detailedInfoCopy.futureDatedInstruction && this.detailedInfoCopy.futureDatedInstruction.repeatNumber) {
      upcomingTxn = parseInt(this.detailedInfoCopy.futureDatedInstruction.repeatNumber, 10);
    }

    completedTxnArray = this.sortBy.transform(this.detailedInfoCopy.recentPayments, 'asc', 'date');

    if (!this.detailedInfoCopy.processedAmount) {
      this.detailedInfoCopy.processedAmount = {
        amount: 0,
      };
    } else {
      this.detailedInfoCopy.processedAmount.amount = 0;
    }

    if (this.detailedInfoCopy.recentPayments && this.detailedInfoCopy.recentPayments.length) {
      upcomingTxn = upcomingTxn - this.detailedInfoCopy.recentPayments.length;

      let totalAmount = 0;
      _.forEach(completedTxnArray, (itm) => {
        const tempObj = {
          formattedDate: DateUtil.format(itm.date, DateUtil.DD_MMM_YY),
        };
        itm = { ...itm, ...tempObj };

        totalAmount = totalAmount + parseFloat(itm.amount.amount);
      });
      totalAmount = parseFloat(totalAmount.toFixed(2));
      this.detailedInfoCopy.processedAmount.amount = totalAmount;
    }

    this.detailedInfoCopy.remainingTxn = upcomingTxn;

    this.getRecurringDataFn(completedTxnArray, upcomingTxn);
  }
}
