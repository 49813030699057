import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { SortByPipe } from '../../shared/pipes/sort/sort-by.pipe';

import { ScheduleTransactionComponent } from './schedule-transaction.component';
import { ScheduleTransactionRoutingModule } from './schedule-transaction-routing.module';
import { ScheduleTransactionListComponent } from './schedule-transaction-list/schedule-transaction-list.component';
import { ScheduleTransactionReceiptComponent } from './schedule-transaction-receipt/schedule-transaction-receipt.component';

@NgModule({
  declarations: [ScheduleTransactionComponent, ScheduleTransactionListComponent, ScheduleTransactionReceiptComponent],
  imports: [SharedModule, CommonModule, ScheduleTransactionRoutingModule],
  providers: [SortByPipe],
})
export class ScheduleTransactionModule {}
