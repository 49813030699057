import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DataSharingService } from '../data-sharing/data-sharing.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private dataSharing: DataSharingService) {}

  handleError(error: Error | HttpErrorResponse) {
    if (window.location.pathname.search('login') === -1) {
      if (error instanceof HttpErrorResponse) {
        this.dataSharing.setErrorModal(true);
        // Server or connection error happened
        if (!navigator.onLine) {
          // this.dataSharing.setErrorModal(true);
          // Handle offline error
        } else {
          // this.dataSharing.setErrorModal(true);
        }
      } else {
        // this.dataSharing.setErrorModal(true);
      }
    }

    // Log the error anyway
    console.error(error);
  }
}
