import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ForcedScenariosComponent } from './forced-scenarios.component';
import { ForcedScenariosRoutingModule } from './forced-scenarios-routing.module';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { PasswordComponent } from './password/password.component';
import { UsernameComponent } from './username/username.component';
import { UsernameSuccessComponent } from './username-success/username-success.component';

@NgModule({
  declarations: [ForcedScenariosComponent, SecurityQuestionsComponent, PasswordComponent, UsernameComponent, UsernameSuccessComponent],
  imports: [SharedModule, ForcedScenariosRoutingModule]
})
export class ForcedScenariosModule { }
