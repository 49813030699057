import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FooterNavigationComponent } from '../../../cms/footer-navigation/footer-navigation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { userNameRegistered, oldPassword, securityQuestionAnswers } from '../../../shared/store-utilities/actions/registration.action';
import { SecurityQuestion } from '../../../services/RegistrationService/data/security-question';
import { SetSecurityQuestionsRequest } from '../../../services/RegistrationService/data/set-security-questions-request';
import { take } from 'rxjs/operators';
import { RouterStateService } from './../../../services/route-state/route-state.service';

@Component({
  selector: 'app-register-security-questions',
  templateUrl: './register-security-questions.component.html',
  styleUrls: ['./register-security-questions.component.scss'],
})
export class RegisterSecurityQuestionsComponent implements OnInit {
  securityQuestions: SecurityQuestion[];
  customerID: string;
  requestID: string;
  accNumber: string;
  registration_module = 'Registration';

  constructor(
    private dataSharing: DataSharingService,
    private routeState: RouterStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private store: Store<{ appReducer: { registrationReducer } }>,
    private location: Location
  ) {}

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Registration',
      subTitle: 'Register Security Questions',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'registration',
    });

    this.store
      .select('appReducer', 'registrationReducer')
      .pipe(take(1))
      .subscribe((value) => {
        this.securityQuestions = value.registerSecurityQuestions.securityQuestions;
        this.accNumber = value.accountNumberEntered;
        this.customerID = value.registerSecurityQuestions.customerNumber;
        this.requestID = value.registerSecurityQuestions.requestId;
      });
  }

  goBack() {
    this.router.navigate(['../registration']);
  }

  goToNext(questionAndAnswerMap: Map<number, SecurityQuestion>) {
    const payload: SetSecurityQuestionsRequest = this.createSetSecurityQuestionsRequest(questionAndAnswerMap);
    this.store.dispatch(securityQuestionAnswers({ securityQuestionAnswers: [...questionAndAnswerMap.values()] }));
    this.registrationService.registerSecurityQuestions(payload).subscribe((response) => {
      this.store.dispatch(userNameRegistered({ userNameRegistered: response.body.username }));
      this.store.dispatch(oldPassword({ oldPassword: response.body.password }));
      this.router.navigate(['./verifyUser'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  private createSetSecurityQuestionsRequest(questionAndAnswerMap: Map<number, SecurityQuestion>): SetSecurityQuestionsRequest {
    return {
      account: {
        number: this.accNumber,
      },
      customerNumber: this.customerID,
      requestId: this.requestID,
      securityQuestions: [...questionAndAnswerMap.values()],
    };
  }
}
