import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Modules } from './masking-constants';
import { SmeHelperService } from '../../services/sme-helper/sme-helper.service';

@Injectable({
  providedIn: 'root',
})
export class MaskingService {

  private featuresToMask = environment.config.isFeatureHide;

  constructor(private smeHelper: SmeHelperService) {
  }

  removeMaskedFeatures<T extends { feature: string }>(menuItems: T[], module: Modules): T[] {
    const userType = this.smeHelper.isSmeMode() ? 'sme' : 'retail';
    return menuItems.filter(item => !this.featuresToMask[module][userType].find(feature => feature === item.feature));
  }

  isFeatureEnabled(feature: string, module: Modules): boolean {
    const userType = this.smeHelper.isSmeMode() ? 'sme' : 'retail';
    return !this.featuresToMask[module][userType].find(item => item === feature);
  }
}
