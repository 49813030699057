import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { NameInitialsService } from '../../../core/name-initials/name-initials.service';
import { TransactionService } from '../../../core/transaction-service/transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { SnackBarService } from '../../../services/snack-bar-service/snackBarService';
import { RouterStateService } from '../../../services/route-state/route-state.service';
import { CommonServiceService } from '../../../core/common-service/common-service.service';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';

import { Store } from '@ngrx/store';
import { setSelectedScheduledTxnData, setTxnReceiptData } from '../../../shared/store-utilities/actions/txn-receipt.action';

import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SmeHelperService } from '../../../services/sme-helper/sme-helper.service';

@Component({
  selector: 'app-schedule-transaction-list',
  templateUrl: './schedule-transaction-list.component.html',
  styleUrls: ['./schedule-transaction-list.component.scss'],
})
export class ScheduleTransactionListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() sortedTransactionHistory: any;
  @Output() recallData = new EventEmitter<any>();
  
  destroyed$ = new Subject<boolean>();
  accounts;
  disableRightButton = false;
  isSmeMode = false;
  isSmeSoleProprietor = false;
  uniqAccountsData = [] as any;
  searchText: string;

  sortByFilterValues: any;
  fromDate: any;
  txnReveiptPayload: any;
  toDate: any;
  moduleData: any;

  transactionList: any[] = [];
  selected: any[] = [];
  leftButtonText = 'Select All';

  ifPaginationHide = true;

  resetFilter = false;
  showRightButton = false;
  rightButtonText: string;
  uniqAccountsDataFiltered;
  approversData = {
    BILLER: {
      transactionType: 'BILLER',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    COMPANY: {
      transactionType: 'COMPANY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    ONCE_OFF_COMPANY: {
      transactionType: 'ONCE_OFF_COMPANY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    BANK_BENEFICIARY: {
      transactionType: 'BANK_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    SELF_FUNDS: {
      transactionType: 'SELF_FUNDS',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    CMA_BENEFICIARY: {
      transactionType: 'CMA_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    ONCE_OFF_CMA_BENEFICIARY: {
      transactionType: 'ONCE_OFF_CMA_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
    ONCE_OFF_BANK_BENEFICIARY: {
      transactionType: 'ONCE_OFF_BANK_BENEFICIARY',
      financeType: 'FINANCIAL_RULES',
      financeValue: 'financialRules',
    },
  };

  constructor(
    private ref: ChangeDetectorRef,
    private dataSharing: DataSharingService,
    private transactionService: TransactionService,
    private commonService: CommonServiceService,
    private nameInitialsService: NameInitialsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snack: SnackBarService,
    private routeState: RouterStateService,
    private accountsIterator: AccountsIteratorService,
    private smeHelper: SmeHelperService,
    private store: Store<any>
  ) {}

  ngOnInit() {
    this.isSmeMode = this.smeHelper.isSmeMode();
    this.isSmeSoleProprietor = this.smeHelper.isUserSmeSoleProprietor();
    this.accounts = this.accountsIterator.getAllAccounts();

    this.dataSharing
      .getCloseCurrentModule()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        const currentUrl = this.routeState.getCurrentUrl();
        if (currentUrl.search('/scheduleTransaction') !== -1) {
          this.moduleData = value;

          if (this.moduleData && this.moduleData.stopScheduleFnCall) {
            this.stopSelectedScheduleFn();
          }
        }
      });
  }

  ngOnChanges(change: any) {
    if (change['sortedTransactionHistory']) {
      this.transactionList = this.sortedTransactionHistory || [];
      this.resetFilter = false;
      setTimeout(() => {
        this.resetFilter = true;
      }, 0);
    }
    this.selected = [];

    this.changeButtonText(this.selected);
    this.getUniqueAccounts(this.transactionList);
  }

  getAccountnickName(accNo) {
    let accNickName: any;
    accNickName = _.result(
      _.find(this.accounts, (data: any) => {
        return data.number === accNo;
      }),
      'customName'
    );
    if (!accNickName) {
      accNickName = accNo;
    }
    return accNickName;
  }

  getUniqueAccounts(data: any) {
    this.uniqAccountsData = [];
    const uniqueAccounts = _.uniqBy(data, 'fromAccount');

    _.forEach(uniqueAccounts, (account: any) => {
      const nickName = this.getAccountnickName(account.fromAccount);
      const accountData = {
        accountNumber: account.fromAccount,
        name: nickName || account.fromAccount,
        checked: true,
      };
      this.uniqAccountsData.push(accountData);
    });
  }

  setElementWidth() {
    return {
      width: document.getElementById('list-items').offsetWidth + 'px',
    };
  }

  getNameInitials(name): string {
    return this.nameInitialsService.getInitials(name);
  }

  isPresentInSelectedList(item) {
    return this.selected.indexOf(item) !== -1;
  }

  listItemClicked(dataObj) {
    const sendDataObject = [];
    if (dataObj.type === 'checkbox') {
      sendDataObject.push(dataObj.selected);
      // pls raise objections if any, it has been replaced becoz [...dataObj.selected] not creating new array with rest operator
      // this.changeButtonText([...dataObj.selected]);
      this.changeButtonText(sendDataObject);
    } else if (dataObj.type === 'radio') {
      sendDataObject.push(dataObj.data);
      this.changeButtonText(sendDataObject);
    }
  }

  stopSelectedScheduleFn() {
    this.dataSharing.setCloseCurrentModule({});

    let payloadArray = [];

    payloadArray = _.map(this.selected, (itm: any) => {
      return {
        futureDatedInstruction: itm.futureDatedInstruction,
        futureDatedId: itm.futureDatedId,
        type: itm.type,
      };
    });

    const receiptPayload: any = {
      transactions: payloadArray,
    };
    if (this.isSmeMode) {
      receiptPayload.financialRule = this.dataSharing.selectedApprover;
      this.dataSharing.selectedApprover = null;
    }

    this.postService(receiptPayload);
    this.dataSharing.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    return this.transactionService.cancelScheduleTransactions(payload).subscribe((response: any) => {
      if (this.commonService.isResponseTypeSuccess(response)) {
        const successMessage = this.transactionService.getToastMessageForStopScheduleTransaction(
          this.isSmeMode && !this.isSmeSoleProprietor,
          this.selected[0].futureDatedInstruction.repeatInterval,
          this.selected.length
        );

        this.router.navigate(['./scheduleTransaction']);

        this.snack.open(successMessage, 'success');

        this.selected = [];
        this.changeButtonText(this.selected);
        this.recallData.emit(true);
      }
    });
  }

  stopScheduleFn(dataObj) {
    this.selected = dataObj;

    const buttonTwoText = dataObj.length === 1 ? 'Stop Schedule' : 'Stop ' + dataObj.length + ' Schedules';

    const warningText = dataObj.length === 1 ? 'this transaction schedule?' : `these ${dataObj.length} transactions schedules?`;

    this.dataSharing.setCloseCurrentModule({
      message: {
        line1: `Are you sure want to stop ${warningText}`,
        line2: 'This action can not be undone.',
      },
      buttonOneText: 'Cancel',
      buttonTwoText,
      buttonTwoDanger: true,
      routeBackTo: null,
      showModule: true,
      smeTransactionType: this.approversData[dataObj[0].type].transactionType,
      financeType: this.approversData[dataObj[0].type].financeType,
      amount: dataObj[0].amount,
    });
  }

  selectAll(dataObj) {
    this.changeButtonText([...dataObj.selected]);
  }

  changeButtonText(selected) {
    // left button
    this.leftButtonText = selected.length === this.transactionList.length ? 'De-Select All' : 'Select All';

    // right button
    if (selected.length) {
      if (this.isSmeMode && !selected[0].initiatedByMe) {
        this.rightButtonText = 'Only Initiator can stop a schedule';
        this.disableRightButton = true;
      } else {
        this.disableRightButton = false;

        this.rightButtonText = selected.length === 1 ? 'Stop Schedule' : 'Stop ' + selected.length + ' Schedules';
      }
      this.showRightButton = true;
    } else {
      this.disableRightButton = false;
      this.showRightButton = false;
    }
  }

  searchFilter(searchValue: any) {
    if (searchValue) {
      this.searchText = searchValue.searchText;
    }
  }

  statementMainFilter(mainFilterValues: any) {
    if (mainFilterValues) {
      this.sortByFilterValues = mainFilterValues.txnDateOrder;
      this.fromDate = mainFilterValues.fromDate;
      this.toDate = mainFilterValues.toDate;
      this.uniqAccountsDataFiltered = mainFilterValues.uniqAccountsData;
    }
  }

  getInfoFunction(receiptData: any) {
    if (receiptData) {
      this.store.dispatch(setSelectedScheduledTxnData({ selectedScheduledTxn: receiptData }));
      this.txnReveiptPayload = {
        transactionType: receiptData.type,
        referenceId: receiptData.futureDatedId,
      };
      this.transactionService.getScheduleTxnReceipt(this.txnReveiptPayload).subscribe((response) => {
        if (response) {
          this.store.dispatch(setTxnReceiptData({ txnReceiptData: response }));
          this.router.navigate(['./receipt'], { relativeTo: this.activatedRoute });
        }
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
