import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Component, OnInit, Inject } from '@angular/core';
import { environment } from './../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sbg-terms-and-conditions',
  styleUrls: ['./termsandconditions.component.scss'],
  templateUrl: '../templates/ModelTermsAndConditions.html',
})
export class TermsAndConditionComponent implements OnInit {
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  title = ' Privacy and security template';
  newAccount = false;
  internationPay = false;
  mvp2Content = false;
  login = false;
  viewMvpTcFirst = false;
  preLoginSmeMode = this.document['preLoginMode'] === 'SME';
  viewTcNewAccount;
  viewTcPayInternational;
  constructor(public dataShare: DataSharingService, @Inject(DOCUMENT) private document: Document) {}
  ngOnInit() {
    if (this.preLoginSmeMode) {
      this.contactNumber = this.config.localSMEcontactNumber;
      this.mailId = this.config.localSMEEmail;
    }
    if (this.dataShare.modelDetails === 'login') {
      this.login = true;
    } else if (this.dataShare.modelDetails === 'newAccount') {
      this.newAccount = true;
    } else if (this.dataShare.modelDetails === 'internationalPayments') {
      this.internationPay = true;
    } else if (this.dataShare.modelDetails === 'mvp2') {
      this.viewMvpTcFirst = true;
    }
  }
}
