import { SelectedBulFile } from '../../../../../shared/store-utilities/actions/bulkFile.action';
import { FieldValidationService } from '../../../../../services/fieldValidation/fieldValidation.service';
import { AccountsIteratorService } from '../../../../../core/accounts-iterator/accounts-iterator.service';
import { environment } from '../../../../../../environments/environment';
import { Router } from '@angular/router';

import { DataSharingService } from '../../../../../core/data-sharing/data-sharing.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CreatePayloadService } from '../../../../../core/create-payload/create-payload.service';
import { Store } from '@ngxs/store';
import { Store as NgrxStore } from '@ngrx/store';
import { BankingMetadataService } from '../../../../../core/banking-metadata/banking-metadata.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface BulkFileType {
  fileErrors: string;
  showRules: boolean;
  uploadSuccess: boolean;
  selectedFileMetaData: any;
  scheduleDate: any;
  fileDetail: {
    fileName: string;
  };
  showErrorMsg: boolean;
}

@Component({
  templateUrl: './bulkFileUploadDetails.component.html',
  styleUrls: ['./bulkFileUploadDetails.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BulkFileUploadDetailsComponent implements OnInit {
  bulkType: BulkFileType;
  config = environment.config;
  isSmeMode: boolean;
  imttApplicable: boolean;
  isDataLoaded = false;
  bulkFileModel: {
    disableNextButton: boolean;
    moneyTransferTaxCharge: string;
    selectedFromAccount;
    currentDate;
    paymentDate;
    selectedFileMetaData: any;
    fileDetail: {
      fileName: string;
      totalTransferAmount: number;
    };
  } = {
    disableNextButton: false,
    moneyTransferTaxCharge: '',
    selectedFromAccount: {},
    currentDate: new Date(),
    paymentDate: new Date(),
    selectedFileMetaData: {},
    fileDetail: {
      fileName: '',
      totalTransferAmount: 0,
    },
  };
  currentDate = new Date();
  fileError;
  showRules;
  mainTitle: string;
  formSubmitted = false;
  userAccountDetails;
  uploadSuccess = false;
  modifyArray;
  refineProducts;
  toggleStatus = 'open';
  manualEntryAmount;
  minDate = new Date();
  initialUserBalance;
  paymentScheduleFrequency;
  lastProduct;
  reviewHeader: Array<{}> = [{}];
  limits = {
    dailyWithdrawlLimit: {
      amount: 0,
    },
    usedLimit: { amount: 0 },
    availableDailyLimit: {},
  };
  totalPayableAmount = 0;
  dataForNextPage;
  isEditPaymentScheduled = false;
  envConfig = environment.config;
  columnDetails;
  showErrorMsg = false;
  approversAmountErrorMsg: string;
  rulesDetails: boolean;
  details;
  validationMsg;
  env = environment.config;
  bulFileData: BulkFileType;
  private readonly ZIMBABWE = 'Zimbabwe';

  constructor(
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private router: Router,
    private fieldValidationService: FieldValidationService,
    private bankingMetadata: BankingMetadataService,
    private createPayload: CreatePayloadService,
    private store: Store,
    private ngrxStore: NgrxStore<any>
  ) {
    ngrxStore.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });
  }

  ngOnInit() {
    this.validationMsg = this.fieldValidationService;
    this.bulkFileModel.disableNextButton = false;
    this.approversAmountErrorMsg =
      'The total amount of the bulk file is not in range. Please upload a file that is within the approval amount range.';
    this.rulesDetails = false;
    this.bulkFileModel.currentDate = new Date();
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Bulk file upload',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'bulkFile',
    });

    if (this.envConfig.payments.bulkFileUpload.validateEightFieldsBulkFile) {
      this.columnDetails = [
        'Beneficiary account number should only be in local currency',
        'Amount',
        'Beneficiary name',
        "Sender's reference",
        'Beneficiary reference',
        'Bank sort codes',
        'Bank reference Number',
        'Address',
      ];
    } else {
      this.columnDetails = [
        'Beneficiary account number should only be in Local currency',
        'Amount',
        'Beneficiary name',
        "Sender's reference",
        'Beneficiary reference',
        'Bank sort codes',
      ];
    }

    this.getSourceAccounts();

    // for zimbabwe USD is also allowed and a currency field is added
    if (this.envConfig.countryName === this.ZIMBABWE) {
      this.columnDetails[0] = 'Beneficiary account number should only be in source account currency';
      this.columnDetails.push('Currency');
    }
    this.dataSharingService.getBulkFileData().subscribe((data) => {
      this.bulFileData = _.cloneDeep(data);
      this.fileError = data.fileErrors;
      this.showRules = data.showRules;
      this.uploadSuccess = data.uploadSuccess;
      if (Object.keys(data).length && this.bulFileData.fileDetail.fileName) {
        this.bulkFileModel.fileDetail.fileName = data.fileDetail.fileName;
        this.bulkFileModel.fileDetail = data.fileDetail;
        this.bulkFileModel.selectedFileMetaData = data.selectedFileMetaData;
        this.bulkFileModel.currentDate = this.currentDate;
      }
    });
  }

  getSourceAccounts() {
    const countryName = this.envConfig.countryName;
    switch (countryName) {
      case this.ZIMBABWE:
        this.bankingMetadata.getFileUploadSourceAccountCurrencies().subscribe((response) => {
          if (response) {
            this.isDataLoaded = true;
            this.userAccountDetails = this.accountsIterator.filterAccountsBasedOnCurrency(response.availableCurrencyList);
            this.bulkFileModel.selectedFromAccount = Object.keys(this.bulkFileModel.selectedFromAccount).length
              ? this.bulkFileModel.selectedFromAccount
              : this.userAccountDetails[0];

            // IMTT applicable only for ZWL transactions
            this.imttApplicable = this.isIMTTApplicable(this.bulkFileModel.selectedFromAccount);
          }
        });
        break;
      default:
        this.isDataLoaded = true;
        this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts('local');
        this.bulkFileModel.selectedFromAccount = Object.keys(this.bulkFileModel.selectedFromAccount).length
          ? this.bulkFileModel.selectedFromAccount
          : this.userAccountDetails[0];
        break;
    }
  }

  isIMTTApplicable(account) {
    if (this.envConfig.countryName !== this.ZIMBABWE) {
      return false;
    }
    return this.envConfig.localCurrency === account.accountCurrency.currency || account.accountCurrency.currency === 'USD';
  }

  getRules($event: boolean) {
    this.showRules = $event;
  }

  trackByFn(error) {
    return error.uniqueValue;
  }

  scheduleDate(event: MatDatepickerInputEvent<Date>) {
    this.bulkFileModel.paymentDate = event.value;
  }

  accountDropDownChanged(data) {
    this.bulkFileModel.selectedFromAccount = data;

    // IMTT applicable only for Zimbabwe for both ZWL and USD transactions
    this.imttApplicable = this.isIMTTApplicable(this.bulkFileModel.selectedFromAccount);
    if (!this.imttApplicable) {
      this.bulkFileModel.moneyTransferTaxCharge = undefined;
    }
  }

  goBack() {
    return this.router.navigate(['payments/bulkFileUpload/list']);
  }

  goToConfirmation() {
    const finalBulkFileData = this.createPayload.getBulkFileUploadData(this.bulkFileModel);
    this.dataSharingService.setSelectedFile(this.bulkFileModel.selectedFileMetaData);
    this.store.dispatch(new SelectedBulFile(finalBulkFileData));
    if (Object.keys(this.bulFileData).length) {
      this.showErrorMsg = true;
    }
    if (!this.bulkFileModel.selectedFileMetaData) {
      this.showErrorMsg = true;
    }
    return this.router.navigate(['/payments/bulkFileUpload/review']);
  }

  toggleClicked(data) {
    this.toggleStatus = data;
  }
}
