import { WindowRefService } from '../../core/window-ref/window-ref.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  windowInnerWidth;
  deviceType = '';
  userAgent = this.windows.nativeWindow.navigator.userAgent;
  iosDeviceType;
  private widthBreakpoints = {
    small: 0,
    medium: 640,
    large: 1024,
    xlarge: 1200,
    xxlarge: 1440,
  };
  private resizeSubject: BehaviorSubject<any>;

  constructor(private windows: WindowRefService) {
    const deviceType = this.checkDevice();
    this.resizeSubject = new BehaviorSubject(deviceType);
  }
  get onResize$(): Observable<any> {
    // this.onResize();
    return this.resizeSubject.asObservable().pipe(distinctUntilChanged());
  }
  onResize() {
    const deviceType = this.checkDevice();
    this.resizeSubject.next(deviceType);
  }
  checkDevice() {
    this.windowInnerWidth = this.windows.nativeWindow.innerWidth;
    if (this.windowInnerWidth <= this.widthBreakpoints.medium) {
      this.deviceType = 'small';
    } else if (
      this.windowInnerWidth > this.widthBreakpoints.medium &&
      this.windowInnerWidth <= this.widthBreakpoints.large
    ) {
      this.deviceType = 'meduim';
    } else if (
      this.windowInnerWidth > this.widthBreakpoints.large &&
      this.windowInnerWidth <= this.widthBreakpoints.xlarge
    ) {
      this.deviceType = 'large';
    } else if (
      this.windowInnerWidth > this.widthBreakpoints.xlarge &&
      this.windowInnerWidth <= this.widthBreakpoints.xxlarge
    ) {
      this.deviceType = 'xlarge';
    } else if (this.windowInnerWidth > this.widthBreakpoints.xxlarge) {
      this.deviceType = 'xxlarge';
    } else {
      this.deviceType = 'Unrecognised device type';
      console.error('Unrecognised device type');
    }
    return this.deviceType;
  }
  public isSmall() {
    this.windowInnerWidth = this.windows.nativeWindow.innerWidth;
    return this.windowInnerWidth <= this.widthBreakpoints.medium;
  }
  public isMedium() {
    this.windowInnerWidth = this.windows.nativeWindow.innerWidth;
    return (
      this.windowInnerWidth > this.widthBreakpoints.medium &&
      this.windowInnerWidth <= this.widthBreakpoints.large
    );
  }
  public isLarge() {
    this.windowInnerWidth = this.windows.nativeWindow.innerWidth;
    return (
      this.windowInnerWidth > this.widthBreakpoints.large &&
      this.windowInnerWidth <= this.widthBreakpoints.xlarge
    );
  }
  isIphone() {
    if (this.userAgent.indexOf('iPhone') >= 0) {
      this.iosDeviceType = true;
    }
    return this.iosDeviceType;
  }
  isIpad() {
    if (this.userAgent.indexOf('iPad') >= 0) {
      this.iosDeviceType = true;
    }
    return this.iosDeviceType;
  }
  getDeviceheight() {
    return this.windows.nativeWindow.innerHeight;
  }
  getDeviceWidth() {
    return this.windows.nativeWindow.innerWidth;
  }
}
