import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { OnceOffBillerModel } from '../../../../core/utility-classes/utility';

@Component({
  selector: 'sbg-once-off-mobile-wallet-payment',
  templateUrl: './once-off-mobile-wallet-payment.component.html',
  styleUrls: ['./once-off-mobile-wallet-payment.component.scss'],
})
export class OnceOffMobileWalletPaymentComponent implements OnInit {
  onceoffPaymentModel;
  exchangeRates;
  availableMonthlyLimits;
  dailyWithdrawalLimit;
  amountValidator = {};
  tinNumber;
  availableLimit;

  // country constants
  private showMonthlyTransactionLimit = true;
  private weights = [23, 19, 17, 13, 7, 5, 3];
  private tinLength = 8;
  //
  // country constants

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.onceoffPaymentModel = this.dataSharingService.dataFromFirstStep || new OnceOffBillerModel();
    this.onceoffPaymentModel.selectedBiller = this.dataSharingService.dataFromFirstStep;
    this.onceoffPaymentModel.fromAccounts = this.accountsIterator.getPaymentFromAccounts('local');
    this.onceoffPaymentModel.choosenBank = null;
    this.getAmountWithdrawalLimit();
  }

  fromAccountChange(fromAccount): void {
    this.onceoffPaymentModel.selectedFromAccount = fromAccount;
    this.onceoffPaymentModel.amount = null;
    this.amountValidator = {};
  }

  goBackToBillerList(): void {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  goToBillerConfirmation(): void {
    this.dataSharingService.dataFromFirstStep = this.onceoffPaymentModel;
    this.router.navigate(['../confirm'], { relativeTo: this.activatedRoute });
  }

  tinValidation(tin): boolean {
    const numArr = [];
    for (let i = 0; i < this.tinLength; i++) {
      numArr.unshift(tin % 10);
      tin = Math.floor(tin / 10);
    }
    const remain = numArr[this.tinLength - 1];
    numArr.pop();
    let sum = 0;
    for (let j = 0; j < this.tinLength - 1; j++) {
      sum = sum + numArr[j] * this.weights[j];
    }
    return Math.floor(sum % 11) === remain;
  }

  getTaxRefNumber(paymentType, regOfficeCode, taxType, taxPeriod) {
    return (
      paymentType.toString() +
      regOfficeCode.toString() +
      (this.padZeroForTinValidation(this.tinNumber, this.tinLength, '0') + taxType.toString()) +
      taxPeriod.toString()
    );
  }

  private getAmountWithdrawalLimit(): void {
    const cardProfileData = this.accountService.accountsObject.body.cardProfile;
    if (this.showMonthlyTransactionLimit) {
      this.availableLimit =
        (cardProfileData.monthlyWithdrawalLimit &&
          cardProfileData.monthlyUsedLimit &&
          (cardProfileData.monthlyWithdrawalLimit.amount - cardProfileData.monthlyUsedLimit.amount).toFixed(2)) ||
        'invalid';
    } else {
      this.availableLimit =
        (cardProfileData.dailyWithdrawalLimit &&
          cardProfileData.usedEAPLimit &&
          (cardProfileData.dailyWithdrawalLimit.amount - cardProfileData.usedEAPLimit.amount).toFixed(2)) ||
        'invalid';
    }
  }

  private padZeroForTinValidation(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
}
