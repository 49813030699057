import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TranslateService {
  data: any = {};
  constructor(private http: HttpClient) {}
  use(lang: string): Promise<{}> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        (translation) => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (error) => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}
