import { Injectable } from '@angular/core';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';

import { map } from 'rxjs/operators';
import { VasProviderDetailsResponse } from './data/vas-provider-details-response';
import { VasServiceInputValidationRequest } from './data/vas-service-input-validation-request';
import { VasServiceInputValidationResponse } from './data/vas-service-input-validation-response';
import {
  BundleByProviderRequest,
  BundleByProviderResponse,
} from './data/bundle-by-provider-request-response';
import {
  VoucherByBundleRequest,
  VoucherByBundleResponse,
} from './data/voucher-request-response';
import { Observable } from 'rxjs';
import { VoucherAirtime } from '../../components/buy/prepaid-models.module';
import { AmountListResponse } from './data/amount-list-response';

@Injectable()
export class PrepaidService {
  readonly VOUCHER_TYPE_DELIMITER = '-';
  readonly BOX_OFFICE = 'BOX OFFICE';
  readonly DSTV_Box_Office = 'DSTV Box Office';

  constructor(private serviceEndpoint: ServiceEndpointsService) {}

  getProviderDetails() {
    return this.serviceEndpoint
      .get<VasProviderDetailsResponse>('getProviderDetails')
      .pipe(map((response) => response.body));
  }

  validateVasInput(request: VasServiceInputValidationRequest) {
    return this.serviceEndpoint
      .post<
        VasServiceInputValidationRequest,
        VasServiceInputValidationResponse
      >('validateVasInput', request)
      .pipe(map((response) => response.body));
  }

  getBundleListByProvider(request: BundleByProviderRequest) {
    return this.serviceEndpoint
      .post<BundleByProviderRequest, BundleByProviderResponse>(
        'fetchBundleListByProvider',
        request
      )
      .pipe(map((response) => response.body));
  }

  getVoucherListByBundleAirtime(
    request: VoucherByBundleRequest
  ): Observable<VoucherAirtime[]> {
    return this.getVoucherListByBundle(request).pipe(
      map((response: VoucherByBundleResponse) => {
        const bundleName = request.bundles[0].replace(/ Bundle/g, '');
        return response.vouchers.map((it) => {
          const voucherType = it.voucherType.split(this.VOUCHER_TYPE_DELIMITER);
          const newVoucherObject: VoucherAirtime = { ...it };
          newVoucherObject.displayValue = `${bundleName} - ${voucherType[0]} - ${voucherType[1]}`;
          newVoucherObject.dropdownValue =
            `${newVoucherObject.displayValue} for ${it.voucherPreset[0].amount.currency} ` +
            `${it.voucherPreset[0].amount.amount}`;
          return newVoucherObject;
        });
      })
    );
  }

  getVoucherListByBundleTvSub(
    request: VoucherByBundleRequest
  ): Observable<VoucherAirtime[]> {
    return this.getVoucherListByBundle(request).pipe(
      map((response: VoucherByBundleResponse) =>
        response.vouchers
          .filter((value) => value.voucherType !== this.BOX_OFFICE)
          .map((value) => {
            const period =
              value.voucherPreset[0].period > 1
                ? value.voucherPreset[0].period + ' months'
                : 'month';

            const newVoucherObject: VoucherAirtime = { ...value };
            newVoucherObject.displayValue =
              value.voucherType === this.BOX_OFFICE
                ? this.DSTV_Box_Office
                : value.voucherType;
            newVoucherObject.dropdownValue =
              `${newVoucherObject.displayValue} for ${value.voucherPreset[0].amount.currency} ` +
              `${value.voucherPreset[0].amount.amount}/${period}`;
            return newVoucherObject;
          })
      )
    );
  }

  getVoucherListByBundle(request: VoucherByBundleRequest) {
    return this.serviceEndpoint
      .post<VoucherByBundleRequest, VoucherByBundleResponse>(
        'fetchVoucherListByBundle',
        request
      )
      .pipe(map((response) => response.body));
  }

  getMobileAmountList(): Observable<AmountListResponse> {
    return this.serviceEndpoint
      .get<AmountListResponse>('mobileTopUpAmountList')
      .pipe(map((response) => response.body));
  }
}
