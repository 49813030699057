import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GepgPaymentListComponent } from './gepg-payment-list/gepg-payment-list.component';
import { GepgPaymentDetailsComponent } from './gepg-payment-details/gepg-payment-details.component';
import { GepgPaymentReviewComponent } from './gepg-payment-review/gepg-payment-review.component';
import { GepgPaymentReceiptComponent } from './gepg-payment-receipt/gepg-payment-receipt.component';
import { GepgPaymentAmountConfirmationComponent } from './gepg-payment-amount-confirmation/gepg-payment-amount-confirmation.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: GepgPaymentListComponent,
      },
      {
        path: 'details',
        component: GepgPaymentDetailsComponent,
      },
      {
        path: 'review',
        component: GepgPaymentReviewComponent,
      },
      {
        path: 'amount',
        component: GepgPaymentAmountConfirmationComponent,
      },
      {
        path: 'receipt',
        component: GepgPaymentReceiptComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GepgPaymentRoutingModule { }
