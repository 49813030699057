import { BankingMetadataService } from './../../../core/banking-metadata/banking-metadata.service';
import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { environment } from './../../../../environments/environment';
import { RouterStateService } from '../../../services/route-state/route-state.service';
@Component({
  selector: 'app-request-insurance',
  templateUrl: './request-insurance.component.html',
  styleUrls: ['./request-insurance.component.scss'],
})
export class RequestInsuranceComponent implements OnInit {
  selectedPurpose: any;
  purposeList: Array<any>;
  purposeData;
  selectedContactTime: any;
  countryCode = environment.config.mobileCountryCode;
  disableServiceField = environment.config.disableServiceField;
  contactTimeList: Array<any>;
  contactTimeData;
  customerId;
  email;
  contactNumber;
  additionalInfo;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private bankingMetaDataService: BankingMetadataService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Apply for Isurance',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
      this.email = value.authenticateResponse.userProfile.emailAddress;
      this.contactNumber = value.authenticateResponse.userProfile.mobileNumber;
    });

    this.bankingMetaDataService.getInsuranceReasons().subscribe((response) => {
      this.getLoanPurpose(response);
    });
    this.serviceRequestService.contactTimeList().subscribe((response) => {
      this.getContactTimeList(response);
    });
  }
  contactTimeChanged(data) {
    this.contactTimeData = data;
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      bankAssuranceData: {
        product: this.purposeData,
        emailAddress: this.email,
        mobileNumber: this.countryCode.slice(1) + this.contactNumber,
        contactTime: this.contactTimeData,
        comments: this.additionalInfo,
      },
    };
    this.serviceRequestService.requestInsurance(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Apply for Insurance',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }
  goBack() {
    this.serviceRequestService.goBackHandler();
  }
  purposeChanged(data) {
    this.purposeData = data;
  }
  private getLoanPurpose(data) {
    this.purposeList = data.body.bankAssuranceProductList;
    this.purposeData = this.purposeList[0];
  }  
  private getContactTimeList(data) {
    this.contactTimeList = data.body.contactTimeList;
  }  
}
