import { createReducer, on } from '@ngrx/store';

import { setQrId, setQrResponse, setValidateQrDetails, setQrDetails } from '../actions/qrPayments.action';

const qrPayments = {
  qrId: '',
  validateQrDetails: {},
  qrDetails: {},
};

export const qrPaymentsReducer = createReducer(
  qrPayments,
  on(setQrId, (state, { qrId }) => ({ ...state, qrId })),
  on(setValidateQrDetails, (state, { validateQrDetails }) => {
    return { ...state, validateQrDetails };
  }),
  on(setQrDetails, (state, { qrDetails }) => {
    const st = {
      qrDetails,
    };
    return { ...state, ...st };
  }),
  on(setQrResponse, (state, { qrResponse }) => {
    return { ...state, qrResponse };
  })
);
