import { BankingMetadataService } from './../../../core/banking-metadata/banking-metadata.service';
import { environment } from './../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import _moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { default as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RouterStateService } from '../../../services/route-state/route-state.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-loan-request',
  templateUrl: './loan-request.component.html',
  styleUrls: ['./loan-request.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class LoanRequestComponent implements OnInit {
  selectedContactTime: any;
  purposeList: Array<any>;
  purposeData;
  contactTimeList: Array<any>;
  contactTimeData;
  customerId;
  additionalInfo;
  tcCheck = false;
  appConstant = environment.config;
  newLoanRequestFlow = this.appConstant.newLoanRequestFlow;
  loanCurrency: string;
  loanCurrenciesList: Array<any>;
  amount: string;
  scheduleDate = moment();
  currentDate;
  minDate;
  mode = 'To account';
  paymentMode = 'From account';
  loan: any = {};
  fromAccount;
  filteredCurencyAccounts;
  accountData: any;
  disbursementAccount: any = [];
  noneLoanAccount: any = [];
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private bankingMetaDataService: BankingMetadataService,
    private routeState: RouterStateService

  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Apply for Loan',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });

    this.serviceRequestService.loanProductList().subscribe((response) => {
      this.getLoanPurpose(response);
    });

    this.serviceRequestService.contactTimeList().subscribe((response) => {
      this.getContactTimeList(response);
    });
    this.accountData = this.accountsIterator.getAccountsMainObj();
    this.loanCurrency = this.loanCurrency || this.appConstant.localCurrency;
    this.loan.currency = this.loanCurrency;
    this.currentDate = this.scheduleDate;
    this.minDate = this.currentDate;
    this.filterNoneLoanAcccount();
    this.filterByType();
    this.desiredCurrency();
    if (this.appConstant.localCurrency === 'NGN') {
      this.fetchLoanCurrency();
    } else {
      this.loanCurrenciesList = [this.appConstant.localCurrency];
    }
  }

  desiredCurrency() {
    this.filteredCurencyAccounts = this.getAccountByCurrency(this.loanCurrency);
    this.fromAccount = this.accountsIterator.getPaymentFromAccounts(this.loanCurrency);
    this.loan.fromAcc = this.fromAccount[0];
    this.loan.toAcc = this.filteredCurencyAccounts[0];
    if (this.appConstant.hideFromAccFromReciept) {
      this.loan.fromAcc = null;
      this.loan.toAcc = null;
    }
  }
  getAccountByCurrency(desiredCurrency) {
    this.filteredCurencyAccounts = [];
    const accounts = this.disbursementAccount;
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].accountCurrency && accounts[i].accountCurrency.currencyCode === desiredCurrency) {
        this.filteredCurencyAccounts.push(accounts[i]);
      }
    }
    return this.filteredCurencyAccounts;
  }
  filterNoneLoanAcccount() {
    const accounts = this.accountData.body.accounts;
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].accountType && accounts[i].accountType !== 'TERM_LOAN' && accounts[i].accountType !== 'HOME_LOAN') {
        this.noneLoanAccount.push(accounts[i]);
      }
    }
  }
  filterByType() {
    const accounts = this.noneLoanAccount;
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].accountStatus && accounts[i].accountStatus !== 'DORMANT') {
        this.disbursementAccount.push(accounts[i]);
      }
    }
  }

  fetchLoanCurrency() {
    this.bankingMetaDataService.getAvailableCurrencyList().subscribe((response) => {
      if (response) {
        this.loanCurrenciesList = response.availableCurrencyList;
      }
    });
  }

  loanCurrenyChanged(data) {
    this.loanCurrency = data;
    this.amount = '';
    this.loan.currency = data;
    this.desiredCurrency();
  }

  dateChanged(event) {
    this.currentDate = event._d;
  }

  disbursementAccountValueChanged(data) {
    this.loan.toAcc = data;
  }

  FromAccountValueChanged(data) {
    this.loan.fromAcc = data;
  }

 

  purposeChanged(data) {
    this.purposeData = data;
  }

  contactTimeChanged(data) {
    this.contactTimeData = data;
  }

  submitRequest() {
    let payload;
    if (!this.newLoanRequestFlow) {
      payload = {
        customerId: this.customerId,
        loanData: {
          amount: {
            amount: this.amount,
            currency: this.loanCurrency,
          },
          disburseToAcc: this.loan.toAcc,
          disbursementMode: 'Credit To Account',
          loanStartDate: this.currentDate,
          payFromAcc: this.loan.fromAcc,
          paymentMode: 'Debit To Account',
          purpose: this.purposeData,
        },
      };

      if (this.mode === 'To account' && this.paymentMode !== 'From account') {
        payload.loanData.payFromAcc = null;
        payload.loanData.paymentMode = 'Debit To Cheque';
      } else if (this.mode !== 'To account' && this.paymentMode === 'From account') {
        payload.loanData.disburseToAcc = null;
        payload.loanData.disbursementMode = 'Credit To Cheque';
      } else if (this.mode !== 'To account' && this.paymentMode !== 'From account') {
        payload.loanData.disburseToAcc = null;
        payload.loanData.disbursementMode = 'Credit To Cheque';
        payload.loanData.payFromAcc = null;
        payload.loanData.paymentMode = 'Debit To Cheque';
      }
    } else {
      payload = {
        customerId: this.customerId,
        simpleLoanData: {
          purpose: this.purposeData,
          contactTime: this.contactTimeData,
          comments: this.additionalInfo,
        },
      };
    }
    this.serviceRequestService.applyForLoan(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Apply for Loan',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
  private getContactTimeList(data) {
    this.contactTimeList = data.body.contactTimeList;
  }

  private getLoanPurpose(data) {
    this.purposeList = data.body.loanProductList;
    this.purposeData = this.purposeList[0];
  }
}
