import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ForgotPasswordRoutingModule } from "./forgot-password-routing.module";
import { AnswerSecurityQuestionsComponent } from './answer-security-questions/answer-security-questions.component';
import { ForgotPasswordComponent } from './forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


@NgModule({
  declarations: [ForgotPasswordComponent, AnswerSecurityQuestionsComponent, ResetPasswordComponent],
  imports: [SharedModule, ForgotPasswordRoutingModule],
})
export class ForgotPasswordModule { }
