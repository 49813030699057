import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import { selectBillerData } from '../../../../shared/store-utilities/actions/payment.action';
import { BillerManagementModel } from '../../../../components/recipients-management/partials/biller/models/biller-management-model';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { delay } from 'rxjs/operators';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { environment } from '../../../../../environments/environment';
import { setOnceOffPayBeneDetailData } from '../../../../shared/store-utilities/actions/once-off-pay-beneficiary.action';

@Component({
  selector: 'sbg-pay-biller-list',
  templateUrl: './pay-biller-list.component.html',
  styleUrls: ['./pay-biller-list.component.scss'],
})
export class PayBillerListComponent implements OnInit {
  registeredBillerList;
  storeState;
  groups;
  allBillers;
  showUnregisteredBillers = false;
  selectedBiller;
  clearGroupFilter = {
    name: 'Clear',
    oldName: 'Clear',
    orderIndex: 0,
  };
  filteredData;
  screenSize: string;
  config = environment.config;
  rightBtnTxt = this.config.isBillerCDI ? 'Once Off Company Payment' : '';
  rightButtonLeftSideIcon = 'add';
  addBillerTxt = this.config.isBillerCDI ? 'Add Company' : 'Add Biller';
  onceOffView = false;
  isMaxDataExceed: boolean;
  nameInitialKey: string;
  isSmeMode: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recipientService: RecipientService,
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private routeState: RouterStateService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.dataSharingService.resetStepData();
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay & Transfer',
      subTitle: '',
      showBack: false,
      showClose: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;
      this.dataSharingService.setHeadPagination({
        mainTitle: 'Pay & Transfer',
        subTitle: '',
        showBack: this.screenSize === 'small',
        showClose: true,
        steps: false,
        showBackFn: this.goBack.bind(this),
      });
    });

    this.store
      .select('appReducer', 'loginReducer')
      .subscribe((data) => {
        if (data) {
          this.isSmeMode = data.isSmeMode;
        }
      });
  }

  ngOnInit() {
    this.config.isBillerCDI && this.isSmeMode ? '': this.getBillers();
    this.getBillerGroupService();
    this.dataSharingService.flowOriginatorUrl = this.routeState.getCurrentUrl();
    this.setNameInitialKey();
  }

  addBiller() {
    this.dataSharingService.currentFlow = 'BILLER_MANAGEMENT';
    return this.router.navigate(['/recipients-management/biller/add-biller']);
  }

  goBack() {
    return this.router.navigate(['/menu/payments']);
  }

  setNameInitialKey() {
    if ((this.config.isBillerCDI && !this.onceOffView) || this.config.countryName === 'Malawi' || this.config.countryName === 'Uganda') {
      this.nameInitialKey = 'nickName';
    } else {
      this.nameInitialKey = 'name';
    }
  }

  billerClicked(billerData) {
    if (!this.onceOffView) {
      if (billerData.data) {
        this.selectedBiller = billerData.data;
        this.rightBtnTxt = `Pay ${billerData.data[this.nameInitialKey]}`;
        this.rightButtonLeftSideIcon = '';
      } else {
        this.rightBtnTxt = 'Once Off Company Payment';
        this.rightButtonLeftSideIcon = 'add';
      }
    } else {
      this.store.dispatch(setOnceOffPayBeneDetailData({ onceOffPayBeneDetailData: billerData }));
      return this.router.navigate(['../once-off-biller-details'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  goToOnceOff() {
    this.recipientService.getAllBillers().subscribe((response) => {
      this.onceOffView = true;
      this.setNameInitialKey();
      if (response.body.hasMoreElements) {
        this.recipientService.getAllBillersLazyLoad().subscribe((responseData) => {
          this.isMaxDataExceed = this.config.maxedBeneficiaryLength < responseData.body.recipients.length;
          this.filteredData = [...responseData.body.recipients];
          if (this.isMaxDataExceed) {
            this.dataSharingService.setCloseCurrentModule({
              message: {
                line1: `You have over ${this.config.maxedBeneficiaryLength}
                  companies in order to improve your experience you will need to search your companies in order to view them.`,
              },
              buttonOneText: 'Continue',
              routeBackTo: '/payments/biller/list',
              showModule: true,
            });
          } else {
            this.filteredData = [...responseData.body.recipients];
          }
        });
      } else {
        this.filteredData = [...response.body.recipients];
      }
    });
  }

  goToBillerDetails(selectedArr) {
    if (selectedArr.length === 0) {
      return this.goToOnceOff();
    } else {
      this.store.dispatch(selectBillerData({ selectedBiller: [this.selectedBiller] }));
      this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
    }
  }

  groupChangedFn(group) {
    if (this.clearGroupFilter.orderIndex === group.orderIndex) {
      this.filteredData = [...this.registeredBillerList];
    } else {
      this.filteredData = this.registeredBillerList.filter((biller) => !!(biller.group && biller.group.orderIndex === group.orderIndex));
    }
  }

  infoIconClicked(data) {
    this.dataSharingService.currentModuleName = 'Pay';
    this.dataSharingService.currentFlow = 'BILLER_MANAGEMENT';
    this.dataSharingService.dataFromFirstStep = { selectedBiller: data } as BillerManagementModel;
    this.router.navigate(['/recipients-management/biller/details-biller']);
  }

  private getBillers(): void {
    this.recipientService.getBillers({}).subscribe((response) => {
      this.onceOffView = false;
      this.registeredBillerList = response.body.billers || response.body.recipients;
      this.filteredData = [...this.registeredBillerList];
    });
  }

  private getBillerGroupService(): void {
    this.recipientService.getBillerGroup('billerGroup').subscribe((response) => {
      if (response.body && response.body.groups) {
        this.groups = [...response.body.groups, this.clearGroupFilter];
      }
    });
  }
}
