import { NgModule } from '@angular/core';
import { GepgPaymentListComponent } from './gepg-payment-list/gepg-payment-list.component';
import { GepgPaymentDetailsComponent } from './gepg-payment-details/gepg-payment-details.component';
import { GepgPaymentReviewComponent } from './gepg-payment-review/gepg-payment-review.component';
import { GepgPaymentReceiptComponent } from './gepg-payment-receipt/gepg-payment-receipt.component';
import { GepgPaymentRoutingModule } from './gepg-payment-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { GepgPaymentAmountConfirmationComponent } from './gepg-payment-amount-confirmation/gepg-payment-amount-confirmation.component';

@NgModule({
  declarations: [
    GepgPaymentListComponent,
    GepgPaymentDetailsComponent,
    GepgPaymentReviewComponent,
    GepgPaymentReceiptComponent,
    GepgPaymentAmountConfirmationComponent,
  ],
  imports: [GepgPaymentRoutingModule, SharedModule],
})
export class GepgPaymentModule {}
