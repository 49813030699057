import { BundleByProviderResponse } from '../../core/prepaid-service/data/bundle-by-provider-request-response';
import { VasVoucher } from '../../core/prepaid-service/data/vas-voucher';
import { BankAccount } from '../../core/account-service/data/bank-account';
import { BaseVasProvider } from '../../core/prepaid-service/data/base-vas-provider';
import { Amount } from '../../core/data/amount';
import { VasServiceInputValidationResponse } from '../../core/prepaid-service/data/vas-service-input-validation-response';

export interface SelectedProvider {
  friendlyName: string;
  prepaidProviderId: string;
  providerMode: string;
  prepaidProduct: {
    prepaidPreset: PrepaidPreset[];
    range: {
      max: { amount: 0; currency: string };
      min: { amount: 0; currency: string };
    };
    vouchers: [];
  };
  validate: false;
}

export interface PrepaidPreset {
  amount: {
    amount: 0;
    currency: string;
  };
  friendlyName: string;
}

export interface Duration {
  key: number;
  value: string;
}

export enum TvSubPrepaidType {
  TOP_UP = 'Top Up',
  BOUQUET = 'Bouquet',
}

export enum BouquetByType {
  DURATION = 'duration',
  AMOUNT = 'amount',
}

export enum BuyTabRoutes {
  AIRTIME = 'airtime',
  DATA = 'data',
  TV_SUBSCRIPTION = 'tvsubscription',
  ELECTRICITY = 'electricity',
  WATER = 'water',
  RATESANDTAXES = 'ratesandtaxes',
}

export interface TvModel {
  amount: {
    amount: string;
    currency: string;
  };
  selectedAccount: BankAccount;
  verified: boolean;
  showVoucher: boolean;
  vasData?: VasServiceInputValidationResponse;
  selectedProvider: SelectedProvider;
  selectedBundle?: string;
  selectedPrepaidType?: TvSubPrepaidType;
  bouquetBy?: BouquetByType;
  selectedVoucher?: VoucherAirtime;
  bundles?: BundleByProviderResponse;
  vouchers?: VoucherAirtime[];

  payload: BuyPrepaidPayload;
}

export interface BuyPrepaidPayload {
  amount?: {
    amount: string;
    currency: string;
  };
  autoSave: boolean;
  customerName: string | undefined;
  basePrepaidProvider?: BaseVasProvider;
  productCode: string | '';
  rechargeNumber?: string;
  transactionId?: string;
  voucher?: VoucherModel;

  paymentConfirmation: {
    notifications: any[];
  };

  financialRule?: any;
}

export interface VoucherModel {
  amount: Amount;
  period: number;
  voucherId: string;
  voucherType: string;
}

export interface AirtimeModel {
  amount: {
    amount: string;
    currency: string;
  };
  selectedAccount: BankAccount;
  rechargeNumber: string;
  selectedProvider: SelectedProvider;
  basePrepaidProvider: {};
  paymentConfirmation: {
    notifications: any[];
  };
  selectedVoucher?: VoucherAirtime;
  selectedBundle?: string;
  bundles?: BundleByProviderResponse;
  vouchers?: VoucherAirtime[];
  selectedProductModel?: PrepaidPreset;
  isMobileNumber?: boolean;
  amountList?: Amount[];
  selectedAmount?: Amount;
}

export interface VoucherAirtime extends VasVoucher {
  displayValue?: string;
  dropdownValue?: string;
}

export interface ElectricityModel {
  amount: {
    amount: string;
    currency: string;
  };
  customerName: string;
  selectedAccount: BankAccount;
  rechargeNumber: string;
  selectedProvider: SelectedProvider;
  basePrepaidProvider?: BaseVasProvider;
  paymentConfirmation: {
    notifications: any[];
  };
  verified?: boolean;
  outstandingBalance:{
    amount: number;
    currency: string;
    }
}

export interface BuyTabModel {
  name: string;
  index: number;
}
