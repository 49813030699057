// defining constants for file validations
export const InputValidation = {
  inputRefrence: "[^a-zA-Z0-9*@()=#;:?,.& /\\\\\\-\\\\']",
  nickName: /[@#]/g,
  ipAddress: /[^a-zA-Z0-9,.'\n ]/g,
  remitaRefrenceNumber: /[^a-zA-Z0-9]/g,
  allowedPasswordSpecialChar: ['@', '#', '$', '%', '^', '\\'],
  fileName: /[\/:*??"'\\;<>|&]/gi,
  allowOnlyAlphabets: /[^a-zA-Z ]/g,
  document_upload_filename: /^[\w\s]+$/ms,
};
