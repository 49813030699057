import { Component, OnInit } from '@angular/core';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { Amount } from '../../../../core/data/amount';

@Component({
  selector: 'sbg-gepg-payment-review',
  templateUrl: './gepg-payment-review.component.html',
  styleUrls: ['./gepg-payment-review.component.scss'],
})
export class GepgPaymentReviewComponent implements OnInit {
  transactionRequest: TransactionsRequest;
  amount: Amount;
  headerObj = {
    name: '',
    first: ''
  };
  amountDue: Amount;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService
  ) {}

  ngOnInit() {
    this.transactionRequest = this.dataSharingService.dataFromSecondStep.transactionRequest;
    this.amountDue = this.dataSharingService.dataFromSecondStep.amountDue;
    const payment = this.transactionRequest.transactions.gepgBillerPayments[0];
    this.amount = payment.amount;
    this.updateHeader();
    this.headerObj = {
      name: payment.paymentDetails.serviceProvider.serviceProviderCode,
      first: payment.paymentDetails.controlNumber,
    };
  }

  updateHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Biller Payment (GEPG) ',
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'gepg',
    });
  }

  goToNextPage() {
    this.makeTransaction();
    this.dataSharingService.setPostService(this.makeTransaction.bind(this));
  }

  goBack() {
    this.router.navigate(['../amount'], { relativeTo: this.activatedRoute });
  }

  private makeTransaction() {
    this.transactionService.makeTransaction(this.transactionRequest).subscribe((response) => {
      this.dataSharingService.dataFromThirdStep = { transactionRequest: this.transactionRequest, transactionResponse: response };
      this.router.navigate(['../receipt'], { relativeTo: this.activatedRoute });
    });
  }
}
