import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { OnceOffBillerModel } from '../../../../core/utility-classes/utility';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { setPayBillerData } from '../../../../shared/store-utilities/actions/payment.action';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { Subject } from 'rxjs';
@Component({
  selector: 'sbg-once-off-biller-payment',
  templateUrl: './once-off-biller-payment.component.html',
  styleUrls: ['./once-off-biller-payment.component.scss'],
})
export class OnceOffBillerPaymentComponent implements OnInit, OnDestroy {
  onceoffPaymentModel: any = {
    selectedFromAccount: {},
  };
  exchangeRates;
  amountValidator = {};
  tinNumber;

  availableLimit;
  // availableLimit: any;
  withdrawalLimit: any;
  config = environment.config;
  avilableDailyLimit: any;
  usedLimit: any;

  onceOffCompanyData: any;
  dataForNextPage: any;
  isAllowedScheduledPayment =
    this.paymentUtilities.checkIfScheduledPaymentsIsAllowedForTransactionType(
      'BILLER'
    );
  isPaymentScheduled = false;
  showSchedulePayment = false;
  currentDate = new Date();
  scheduledPayment: any;
  routeHistory: any;
  payBillerData;
  destroyed$ = new Subject<boolean>();
  editReviewModel: any;
  editSelectedBranch: any;
  mainHeading: any;
  prevUrl: any;
  editFlow = false;
  isBillerCDI = this.config.isBillerCDI;
  isSmeMode = false;
  // country constants

  private weights = [23, 19, 17, 13, 7, 5, 3];
  private tinLength = 8;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private accountService: AccountService,
    private store: Store<any>,
    private uuidService: UuidGeneratorService,
    private paymentUtilities: PaymentUtilitiesService,
    private routeState: RouterStateService
  ) {
    this.routeHistory = this.routeState.getHistory();
    this.prevUrl = _.last(this.routeHistory);
    this.store
      .select('appReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        if (this.prevUrl.search('review') !== -1) {
          if (
            Object.keys(stateData.billerPaymentsReducer.payBillerData).length
          ) {
            const tempBillerData = _.cloneDeep(
              stateData.billerPaymentsReducer.payBillerData
            );
            this.payBillerData = tempBillerData.transactions.onceOffCdiPayments;
          }
          this.onceoffPaymentModel.selectedFromAccount =
            stateData.billerPaymentsReducer.payBillerData.account;
        }
      });
    this.store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });
  }

  private static padZeroForTinValidation(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  ngOnInit() {
    this.onceoffPaymentModel.currentDate = this.currentDate;
    if (this.prevUrl.search('scheduleTransaction/receipt') !== -1) {
      this.store
        .select('appReducer', 'editTxnScheduleReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          this.editReviewModel = _.cloneDeep(
            stateData.editOnceOffBankBeneficiary
          );
          this.onceoffPaymentModel.editReviewModel = this.editReviewModel;
          this.onceoffPaymentModel.isFlowEditSchedule =
            this.editReviewModel.isFlowEditSchedule;
          this.onceoffPaymentModel.isPaymentScheduled =
            this.editReviewModel.isFlowEditSchedule;
          this.onceoffPaymentModel.amount =
            this.editReviewModel.scheduledAmount.amount;
          this.onceoffPaymentModel.currency =
            this.editReviewModel.scheduledAmount.currency;
          this.onceoffPaymentModel.benefName = this.editReviewModel.name;
          this.onceoffPaymentModel.isPaymentScheduled = true;
          this.onceoffPaymentModel.accountNumber =
            this.editReviewModel.accountNumber;
          this.onceoffPaymentModel.yourReference =
            this.editReviewModel.customerReference;
          this.onceoffPaymentModel.theirReference =
            this.editReviewModel.recipientReference;
          this.onceoffPaymentModel.currentDate =
            this.editReviewModel.nextPaymentDate;
          this.onceoffPaymentModel.type = this.editReviewModel.type;
          this.onceoffPaymentModel.myReference =
            this.editReviewModel.customerReference;
          this.onceoffPaymentModel.theirReference =
            this.editReviewModel.recipientReference;
          this.onceoffPaymentModel.paymentConfirmation =
            this.editReviewModel.paymentConfirmation || {};
          this.onceoffPaymentModel.fromAccounts =
            this.accountsIterator.getPaymentFromAccounts('local');
          // this.onceoffPaymentModel.selectedFromAccount = this.onceoffPaymentModel.fromAccounts[0];
          this.onceoffPaymentModel.futureDatedId =
            this.editReviewModel.futureDatedId;
          this.onceoffPaymentModel.nextPaymentDate =
            this.editReviewModel.nextPaymentDate;
          if (
            this.editReviewModel.route &&
            this.editReviewModel.route.branch &&
            this.editReviewModel.route.branch.name
          ) {
            this.editSelectedBranch = this.editReviewModel.route.branch.name;
          }

          // selecting the perticular user of transaction from the list of users, replaced for line 115
          this.onceoffPaymentModel.fromAccounts.map((account: any) => {
            if (account.number === this.editReviewModel.fromAccount.number) {
              this.onceoffPaymentModel.selectedFromAccount = account;
            }
          });

          this.onceoffPaymentModel.currentFlow = 'edit-onceOff';
          this.showSchedulePayment = true;
        });
    } else {
      this.onceoffPaymentModel =
        this.dataSharingService.dataFromFirstStep || new OnceOffBillerModel();
      this.onceoffPaymentModel.selectedBiller =
        this.dataSharingService.dataFromFirstStep;
      this.onceoffPaymentModel.fromAccounts =
        this.accountsIterator.getPaymentFromAccounts('local');
      this.onceoffPaymentModel.choosÍenBank = null;
    }
    this.mainHeading = !this.onceoffPaymentModel.isFlowEditSchedule
      ? 'Pay new recipient'
      : `Edit ${this.onceoffPaymentModel.benefName} schedule`;

    this.getAmountWithdrawalLimit();
    this.store
      .select('appReducer', 'onceOffPayBeneficiaryReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        this.onceOffCompanyData = stateData;
      });

    if (!this.onceoffPaymentModel.selectedFromAccount) {
      this.onceoffPaymentModel.selectedFromAccount =
        this.onceoffPaymentModel.fromAccounts[0];
    }

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      showBackFn: this.goBack.bind(this),
      module: 'onceOffCompanyPayment',
    });
  }

  fromAccountChange(fromAccount): void {
    this.onceoffPaymentModel.selectedFromAccount = fromAccount;
    this.onceoffPaymentModel.amount = null;
    this.amountValidator = {};
  }

  goBackToBillerList(): void {
    if (this.onceoffPaymentModel.currentFlow === 'edit-onceOff') {
      this.router.navigate(['scheduleTransaction/receipt']);
    } else {
      this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
    }
  }

  goToEditBillerConfirmation(): void {
    this.dataSharingService.dataFromFirstStep = this.onceoffPaymentModel;
    this.dataForNextPage = {
      account: this.onceoffPaymentModel.selectedFromAccount,
      transactions: {
        onceOffCdiPayments: [],
      },
    };
    const paymentModel = {
      currentFlow: 'edit-onceOff',
      scheduleDate: this.currentDate,
    };

    this.onceoffPaymentModel = {
      ...this.onceoffPaymentModel,
      ...{ paymentModel },
    };
    this.dataForNextPage.paymentModel = paymentModel;
    this.dataForNextPage.paymentModel = {
      ...this.dataForNextPage.paymentModel,
      ...this.scheduledPayment,
    };
    this.dataForNextPage.paymentModel.editData =
      this.onceoffPaymentModel.editReviewModel;
    this.dataForNextPage.paymentModel.isPaymentScheduled = true;

    this.dataForNextPage.transactions.onceOffCdiPayments = [
      {
        recipient: {
          recentPayment: this.onceoffPaymentModel.editReviewModel.recentPayment,
          customerReference: this.onceoffPaymentModel.myReference,
          keyValueMetadata: [],
          name: this.onceoffPaymentModel.editReviewModel.name,
          recipientId:
            this.onceoffPaymentModel.editReviewModel.recipientId || null,
          recipientReference: this.onceoffPaymentModel.theirReference,
          paymentConfirmation: this.onceoffPaymentModel.paymentConfirmation,
          futureDatedId: this.onceoffPaymentModel.futureDatedId,
          nextPaymentDate: this.onceoffPaymentModel.nextPaymentDate,
        },
        isFlowEditSchedule: this.onceoffPaymentModel.isFlowEditSchedule,
        amount: {
          currency:
            this.onceoffPaymentModel.selectedFromAccount.availableBalance
              .currency,
          amount: this.onceoffPaymentModel.amount,
        },

        futureDatedInstruction: {
          fromDate: this.onceoffPaymentModel.currentDate,
          repeatInterval: 'SINGLE',
          repeatNumber: 1,
        },
        transactionId: this.uuidService.getUUID(),
      },
    ];

    this.dataForNextPage.paymentModel = {
      ...this.dataForNextPage.paymentModel,
      ...{
        amount: this.dataForNextPage.transactions.onceOffCdiPayments[0].amount,
      },
    };

    this.dataForNextPage.paymentModel.editData = {
      ...this.dataForNextPage.paymentModel.editData,
      ...{
        amount: this.dataForNextPage.transactions.onceOffCdiPayments[0].amount,
      },
    };

    this.store.dispatch(
      setPayBillerData({ payBillerData: this.dataForNextPage })
    );

    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  goToBillerConfirmation(): void {
    this.dataSharingService.dataFromFirstStep = this.onceoffPaymentModel;
    this.dataForNextPage = {
      account: this.onceoffPaymentModel.selectedFromAccount,
      transactions: {
        onceOffCdiPayments: [],
      },
    };
    const paymentModel = {
      currentFlow: 'once-off-pay',
      scheduleDate: this.currentDate,
    };
    this.onceoffPaymentModel = {
      ...this.onceoffPaymentModel,
      ...{ paymentModel },
    };
    this.dataForNextPage.paymentModel = paymentModel;
    this.dataForNextPage.paymentModel = {
      ...this.dataForNextPage.paymentModel,
      ...this.scheduledPayment,
    };

    this.dataForNextPage.transactions.onceOffCdiPayments = [
      {
        recipient: {
          recentPayment:
            this.onceOffCompanyData.onceOffPayBeneDetailData.data.recentPayment,
          customerReference: this.onceoffPaymentModel.myReference,
          keyValueMetadata: [],
          name: this.onceOffCompanyData.onceOffPayBeneDetailData.data.name,
          recipientId:
            this.onceOffCompanyData.onceOffPayBeneDetailData.data.recipientId,
          recipientReference: this.onceoffPaymentModel.theirReference,
        },
        amount: {
          currency:
            this.onceoffPaymentModel.selectedFromAccount.availableBalance
              .currency,
          amount: this.onceoffPaymentModel.amount,
        },
        futureDatedInstruction: {
          fromDate: this.onceoffPaymentModel.currentDate
            ? this.onceoffPaymentModel.currentDate
            : this.currentDate,
          repeatInterval: 'SINGLE',
          repeatNumber: 1,
        },
        transactionId: this.uuidService.getUUID(),
      },
    ];

    this.dataForNextPage.paymentModel = {
      ...this.dataForNextPage.paymentModel,
      ...{
        amount: this.dataForNextPage.transactions.onceOffCdiPayments[0].amount,
      },
    };
    this.store.dispatch(
      setPayBillerData({ payBillerData: this.dataForNextPage })
    );
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  tinValidation(tin): boolean {
    const numArr = [];
    for (let i = 0; i < this.tinLength; i++) {
      numArr.unshift(tin % 10);
      tin = Math.floor(tin / 10);
    }
    const remain = numArr[this.tinLength - 1];
    numArr.pop();
    let sum = 0;
    for (let j = 0; j < this.tinLength - 1; j++) {
      sum = sum + numArr[j] * this.weights[j];
    }
    return Math.floor(sum % 11) === remain;
  }

  getTaxRefNumber(paymentType, regOfficeCode, taxType, taxPeriod) {
    return (
      paymentType.toString() +
      regOfficeCode.toString() +
      (OnceOffBillerPaymentComponent.padZeroForTinValidation(
        this.tinNumber,
        this.tinLength,
        '0'
      ) +
        taxType.toString()) +
      taxPeriod.toString()
    );
  }
  getScheduleValues(values) {
    if (values) {
      this.scheduledPayment = {
        isPaymentScheduled: values.isPaymentScheduled,
        oftenSchedule: values.oftenSchedule,
        numberOfPayments: values.numberOfPayments,
        scheduleDate: values.scheduleDate,
        module: values.module || '',
      };
      // this.onceoffPaymentModel.paymentModel.currentDate = values.scheduleDate;
      this.onceoffPaymentModel.currentDate = values.scheduleDate;
      this.onceoffPaymentModel.nextPaymentDate = values.scheduleDate;
      this.onceoffPaymentModel.oftenSchedule = values.oftenSchedule;
    }
  }
  goBack() {
    return this.router.navigate(['../list'], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getAmountWithdrawalLimit(): void {
    this.withdrawalLimit = this.config.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyWithdrawalLimit
      : this.accountService.cardProfile.dailyWithdrawalLimit;

    this.usedLimit = this.config.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyUsedLimit
      : this.accountService.cardProfile.usedEAPLimit;

    if (this.withdrawalLimit && this.usedLimit) {
      this.availableLimit = this.withdrawalLimit.amount - this.usedLimit.amount;
      this.availableLimit = parseFloat(
        parseFloat(this.availableLimit).toFixed(2)
      );
    }
  }
}
