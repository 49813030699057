import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';

// import {ReceiptRequest} from './data/receipt-request';
// import {InstantMoneyReceiptsResponse} from './data/instant-money-receipts-response';
// import {BulkInstantMoneyReceiptsResponse} from './data/bulk-instant-money-receipts-response';
// import {TransactionsRequest} from './data/transactions-request';
// import {GenericResponse} from '../data/generic-response';

// @Injectable()
// export class TransactionService {

//   constructor(private serviceEndpoint: ServiceEndpointsService) {
//   }

//   getTransactions(payload: object): Observable<{}> {
//     return this.serviceEndpoint.makeRequest(payload, 'transactions').pipe(map(res => res));
//   }

//   getInstantMoneyReceipts(payload: ReceiptRequest): Observable<InstantMoneyReceiptsResponse> {
//     return this.serviceEndpoint.post<ReceiptRequest, InstantMoneyReceiptsResponse>('instantMoneyReceipts', payload)
//       .pipe(map((response) => response.body));
//   }

//   getBulkInstantMoneyReceipts(payload: ReceiptRequest): Observable<BulkInstantMoneyReceiptsResponse> {
//     return this.serviceEndpoint.post<ReceiptRequest, BulkInstantMoneyReceiptsResponse>('bulkInstantMoneyReceipts', payload)
//       .pipe(map((response) => response.body));
//   }

//   makeTransaction(payload: TransactionsRequest): Observable<GenericResponse> {
//     return this.serviceEndpoint.put<TransactionsRequest, GenericResponse>('transactions', payload).pipe(map(response => response.body));
//   }
// }

@Injectable({
  providedIn: 'root',
})
export class TransactionManagementService {
  constructor(private serviceEndpoint: ServiceEndpointsService) {}
  getTransactions(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'transactions').pipe(map((res) => res));
  }
  public getVasReceipt(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'transactionManagementVasReceipt').pipe(map((res) => res));
  }
  public uploadDocument(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'uploadDocument').pipe(map((res) => res));
  }
  public getBulkInstantMoneyIndividualReceipt(payload): Observable<any> {
    return this.serviceEndpoint.makeRequest(payload, 'transactionManagementIndividualReceiptBulkInstantMoney').pipe(map((res) => res));
  }
}
