import { createReducer, on } from '@ngrx/store';
import {
  setOnceOffPayBeneDetailData,
  setPayBeneReviewData,
} from '../actions/once-off-pay-beneficiary.action';
import { SelectedBeneState } from '../state/once-off-pay-beneficiary.state';

const onceOffPayBeneDetailDataInitState = {};
const payBeneReviewDataInitState = {};

const selectedBeneData: SelectedBeneState = {
  onceOffPayBeneDetailData: onceOffPayBeneDetailDataInitState,
  payBeneReviewData: payBeneReviewDataInitState,
};

export const onceOffPayBeneficiaryReducer = createReducer(
  selectedBeneData,

  on(setOnceOffPayBeneDetailData, (state, { onceOffPayBeneDetailData }) => ({
    ...state,
    onceOffPayBeneDetailData,
  })),

  on(setPayBeneReviewData, (state, { payBeneReviewData }) => ({
    ...state,
    payBeneReviewData,
  }))
);
