import { Directive } from '@angular/core';
import { Validator, ValidatorFn, FormControl, NG_VALIDATORS } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[duplicateValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: DuplicateValidatorDirective,
      multi: true,
    },
  ],
})
export class DuplicateValidatorDirective implements Validator {
  validator: ValidatorFn;
  config = environment.config;
  constructor() {
    this.validator = this.duplicateValidator();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
  duplicateValidator(): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (control: FormControl) => {
      // console.log(control);
      return { duplicate: { status: false } };
      //     const val = control.value;
      //     if (val != null && val !== '') {
      //         if (val.charAt(0) === '0') {
      //             return {emailvalidator: { status: 'Mobile number should not start with a 0' } };
      //         } else {
      //             return val.length < this.config.mobileNumberLength ?
      //             {emailvalidator: { status: 'Minimum of ' + this.config.mobileNumberLength + ' digits are required' } } : null;
      //         }
      //         // let isValid = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(control.value);

      //   } else {
      //     return null;
      //   }
    };
  }
}
