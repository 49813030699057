import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { Store } from '@ngrx/store';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-account-modification-request',
  templateUrl: './account-modification-request.component.html',
  styleUrls: ['./account-modification-request.component.scss'],
})
export class AccountModificationRequestComponent implements OnInit {
  accounts;
  selectedAccount;
  modification;
  customerId;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Account Modification Request',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.accounts = this.accountsIterator.getPaymentFromAccounts('all');
    this.selectedAccount = this.accounts[0];

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });
  }

  accountValueChanged(newValue) {
    this.selectedAccount = newValue;
  }

  submitRequest() {
    const payload = {
      accountNumber: this.selectedAccount.number,
      customerId: this.customerId,
      remarks: this.modification,
    };
    this.serviceRequestService.accountModification(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        selectedAccount: this.selectedAccount,
        requestType: 'Account Modification',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
}
