import { AppConstantService } from '../../core/app-constant/app-constant.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { Modules, Payments } from '../../core/masking-service/masking-constants';
import { MaskingService } from '../../core/masking-service/masking.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { setSelectedVasType } from '../../shared/store-utilities/actions/payment.action';

@Component({
  selector: 'sbg-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  

  innerWidth: number;
  list;
  listOfApplicableVasPayment;
  subListOpened = false;
  isSmeMode: boolean;
  config = environment.config;
  flows = {
    payments: {
      name: 'paymentFlows',
      goBack: '/dashboard',
      mainTitle: 'Pay & Transfer',
    },
    buy: {
      name: 'buyFlows',
      goBack: '/dashboard',
      mainTitle: 'Buy',
    },
    debit: {
      name: 'debitCardFlows',
      goBack: '/dashboard',
      mainTitle: 'Debit Cards',
    },
    scheduleTransactions: {
      name: 'scheduleTransactions',
      goBack: '/dashboard',
      mainTitle: 'Schedule Transactions',
    },
  };
  listOfSubBillPayments = [
    {
      feature: Payments.PAY_ELECTRICITY,
      name: 'Electricity',
      type: 'ELECTRICITY',
    },
    {
      feature: Payments.PAY_RATES_AND_TAXES,
      name: 'Rates And Taxes',
      type: 'RATESANDTAXES',
    },
    {
      feature: Payments.PAY_WATER,
      name: this.config.countryName ==='Tanzania'? 'Government payments (GePG)': 'Water',
      type: 'WATER',
    },
    {
      feature: Payments.BILLER_PAYMENTS,
      name: 'Biller',
      type: 'BILLER',
    },
  ];
  selectedFlow;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appConstant: AppConstantService,
    private dataSharing: DataSharingService,
    private maskingService: MaskingService,
    private store: Store<any>
  ) {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      this.isSmeMode = stateData.isSmeMode;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkWidth(event.target.innerWidth);
  }
  ngOnInit() {
    const flow = this.activatedRoute.snapshot.paramMap.get('flow');
    this.selectedFlow = this.flows[flow];
    this.list = this.getList(flow);
    this.checkWidth(window.innerWidth);
    this.setHeadPaginationData();
  }
  getList(flow) {
    let list;
    switch (flow) {
      case 'buy':
        list = this.maskingService.removeMaskedFeatures(this.appConstant[this.selectedFlow.name], Modules.BUY);
        break;
      case 'payments':
        this.listOfApplicableVasPayment = this.maskingService.removeMaskedFeatures(this.listOfSubBillPayments, Modules.PAYMENTS);

        if (this.listOfApplicableVasPayment.length === 1) {
          if (this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS)) {
            this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS).name =
              this.listOfApplicableVasPayment[0].feature === Payments.BILLER_PAYMENTS
                ? this.config.isBillerCDI
                  ? 'Companies'
                  : 'Billers'
                : `Pay ${this.listOfApplicableVasPayment[0].name}`;
          }
        }
        // Prevent removal of biller payments menu item in case of vas payments presence
        if (
          this.listOfApplicableVasPayment.length &&
          this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS && item.name !=='Companies' )
        ) {
          const featureItem = this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS);
          featureItem.feature = undefined;
          featureItem.nav = undefined;
          // featureItem.hasSubMenu = undefined;
        }
        if (
          this.listOfApplicableVasPayment.length &&
          this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS && item.name ==='Companies' )
        ) {
          let featureItem = this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.BILLER_PAYMENTS);
          featureItem.hasSubMenu = undefined;
        }
        if(this.config.countryName === 'Namibia' && this.isSmeMode){
          let featureItem = this.appConstant[this.selectedFlow.name].find((item) => item.feature === Payments.NAMIBIA_TAX_PAYMENT);
          featureItem.nav = '/payments/na-tax-payment/list';
        }
        list = this.maskingService.removeMaskedFeatures(this.appConstant[this.selectedFlow.name], Modules.PAYMENTS);

        break;
      case 'debit':
        list = this.maskingService.removeMaskedFeatures(this.appConstant[this.selectedFlow.name], Modules.DEBIT_CARDS);
        break;
    }
    return list;
  }
  checkWidth(width) {
    this.innerWidth = width;
    // check if width is greater than 600 then redirect to first index
    if (this.innerWidth > 600) {
      this.listItemClicked(0);
    }
  }
  listItemClicked(index) {
    if (this.list[index].hasSubMenu) {
      if (this.listOfApplicableVasPayment.length == 1) {
        this.store.dispatch(
          setSelectedVasType({
            selectedVasType: { type: this.listOfApplicableVasPayment[0].type, description: this.listOfApplicableVasPayment[0].name, subIndexTab: index },
          })
        );
        return this.router.navigateByUrl('/payments/vas', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/payments/vas/list'], { relativeTo: this.activatedRoute });
        });
      } else {
        return;
      }
    } else {
      if (this.list[index].nav) {
        this.router.navigate([this.list[index].nav]);
      } else {
        this.selectedBuy(this.list[index]);
      }
    }
  }
  subListItemClicked(index) {
    const type = this.listOfApplicableVasPayment[index];
    if (Payments.BILLER_PAYMENTS === type.feature) {
      return this.router.navigate(['./payments/biller/list']);
    } else {
      this.store.dispatch(setSelectedVasType({ selectedVasType: { type: type.type, description: type.name, subIndexTab: index } }));
      return this.router.navigateByUrl('/payments/vas', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/payments/vas/list'], { relativeTo: this.activatedRoute });
      });
    }
  }
  selectedBuy(buyType: any) {
    if (buyType.name) {
      buyType = buyType.name;
    }
    switch (buyType) {
      case 'Airtime':
        this.router.navigate(['./buy/airtime/list']);
        break;
      case 'Data':
        this.router.navigate(['./buy/data/list']);
        break;
      case 'TV Subscription':
        this.router.navigate(['./buy/tvsubscription/list']);
        break;
      case 'Prepaid Water':
        // this.router.navigate(['./buy/electricity/list']);
        break;
      case 'Prepaid Electricity':
        this.router.navigate(['./buy/electricity/list']);
        break;
    }
  }
  goBack() {
    const flow = this.activatedRoute.snapshot.paramMap.get('flow');
    this.router.navigate([this.flows[flow].goBack]);
  }
  setHeadPaginationData() {
    this.dataSharing.setHeadPagination({
      mainTitle: this.selectedFlow.mainTitle,
      subTitle: '',
      showBack: false,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
  }
}
