import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import {} from '@ngrx/store';

@Component({
  selector: 'sbg-remark-modal',
  templateUrl: './remark-modal.component.html',
  styleUrls: ['./remark-modal.component.scss'],
})
export class RemarkModalComponent implements OnInit {
  @Input() inputHeader: string;
  @Input() inputPlaceholder: string;
  @Input() leftButtonText: string;
  @Input() rightButtonText: string;
  @Input() dataToCompare: Array<{}>;
  @Input() value: string;
  @Input() isRequired: boolean;

  @Output() leftButtonFunc = new EventEmitter<any>();
  @Output() rightButtonFunc = new EventEmitter<any>();
  @Output() inputChangedFunc = new EventEmitter<any>();
  dataToCompareArray = [];
  groupName: FormControl;
  groupNameDisabled: boolean;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(route: ActivatedRoute) {
    if (!this.value) {
      this.value = '';
    }
  }

  ngOnInit() {
    if (this.dataToCompare && this.dataToCompare.length) {
      this.dataToCompare.forEach((value: any) => {
        this.dataToCompareArray.push(value.name.toLowerCase());
      });
    }
    if (this.isRequired) {
      this.groupName = new FormControl('', [Validators.required, this.validateReq.bind(this), this.validateDuplicate.bind(this)]);
    } else {
      this.groupName = new FormControl('');
    }
    this.groupNameDisabled = this.isRequired;
  }
  leftBtnFunc() {
    this.leftButtonFunc.emit();
  }
  rightBtnFunc() {
    this.rightButtonFunc.emit(this.groupName.value.trim());
  }
  // To Do - Make this as a global validator
  validateDuplicate(c: FormControl) {
    const validation = this.dataToCompareArray.indexOf(c.value.toLowerCase().trim()) === -1
      ? null
      : {
          duplicate: true,
        };
    if (validation) {
      this.groupNameDisabled = validation.duplicate && !c.value.trim().length;
    }
    return validation;
  }
  validateReq(c: FormControl) {
    this.inputChanged(c.value);
    return null;
  }
  inputChanged(value) {
    if ( this.isRequired) {
      this.groupNameDisabled = value.trim().length ? false : true;
    } else {
       this.groupNameDisabled = false;
    }
    this.inputChangedFunc.emit(value.trim());
  }
}
