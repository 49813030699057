import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SmeHelperService {
  private isSme = false;
  private isSmeSoleProprietor = false;

  constructor(
    private store: Store<{
      appReducer: { loginReducer: { authenticateResponse; isSmeMode } };
    }>
  ) {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData) {
        this.isSme = !!stateData.isSmeMode;
        if (
          stateData.authenticateResponse &&
          stateData.authenticateResponse.userProfile
        ) {
          this.isSmeSoleProprietor =
            !!stateData.authenticateResponse.userProfile.isSmeSoleProprietor;
        }
      }
    });
  }

  isSmeMode(): boolean {
    return this.isSme;
  }

  isUserSmeSoleProprietor(): boolean {
    return this.isSmeSoleProprietor;
  }
}
