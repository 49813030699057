import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../cms/footer-navigation/footer-navigation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../services/RegistrationService/registration.service';
import { Store } from '@ngrx/store';
import { accountNumberEntered, userNameRegistered, setRegisterSecurityQuestions } from '../../shared/store-utilities/actions/registration.action';
import { environment } from './../../../environments/environment';
import { RouterStateService } from '../../services/route-state/route-state.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  userName;
  accNo;
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  constructor(
    private dataSharing: DataSharingService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private store: Store<any>,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
    });

    this.dataSharing.setHeadPagination({
      mainTitle: 'Forgot Password',
      subTitle: 'Identify Yourself',
      showBack: true,
      showClose: true,
      steps: true,
      showBackFn: this.goBack.bind(this),
      module: 'forgotPassword',
    });

    if (this.routeState.getPreviousUrl() !== '/login') {
      this.store.select('appReducer', 'registrationReducer').subscribe((value) => {
        if (value.userNameRegistered !== '') {
          this.userName = value.userNameRegistered;
        }
        if (value.accountNumberEntered !== '') {
          this.accNo = value.accountNumberEntered;
        }
      });
    }
  }

  openDialog(type: string, condition: string): void {
    this.dataSharing.dialogType = type;
    this.dataSharing.modelDetails = condition;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }

  goToAnswerSecurityQuestions() {
    const payload = {
      userId: this.userName,
      accountNumber: this.accNo,
    };

    this.registrationService.fetchSecurityQuestions(payload).subscribe((response) => {
      this.store.dispatch(userNameRegistered({ userNameRegistered: this.userName }));
      this.store.dispatch(accountNumberEntered({ accountNumberEntered: this.accNo }));
      this.store.dispatch(setRegisterSecurityQuestions({ registerSecurityQuestions: response.body }));
      this.router.navigate(['./answerSecurityQuestions'], {
        relativeTo: this.activatedRoute,
      });
    });
  }

  goBack() {
    this.store.dispatch(userNameRegistered({ userNameRegistered: '' }));
    this.store.dispatch(accountNumberEntered({ accountNumberEntered: '' }));
    this.router.navigate(['/login']);
  }
}
