import { map, catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApiEndpointService } from './../../core/api-endpoint/api-endpoint.service';
import { Subscription, of } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { HttpClient, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { InputValidation } from '../../constants/InputValidation/inputValidation.service';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { environment } from '../../../environments/environment';
import { McaHeader } from '../../core/data/mca-header';

export interface FileUploadModule {
  data: File;
  state: boolean;
  uploadUrl?: string;
  uploadProgress: boolean;
  progress: number;
  reUpload: boolean;
  error: string[];
  type?: string;
}
export interface docDetails {
  bopRefKeys: string;
  ochDocRef: string;
}

@Component({
  selector: 'sbg-new-upload-document',
  templateUrl: './new-upload-document.component.html',
  styleUrls: ['./new-upload-document.component.scss'],
})
export class NewUploadDocumentComponent implements OnInit, OnDestroy {
  @ViewChild('file') file: ElementRef;
  @ViewChild('otherDocument') otherDocument: ElementRef;
  @Input() title: string;
  @Input() key: string;
  @Input() SegmentData: any;
  @Input() commonUUID: string;
  @Output() outputDetails: EventEmitter<docDetails> = new EventEmitter<docDetails>();
  @Output() captureDelProp: EventEmitter<string> = new EventEmitter<string>();

  @Input() documentDetails: object;
  @Output() updateDetails: EventEmitter<object> = new EventEmitter<object>();
  @Output() removeFile: EventEmitter<object> = new EventEmitter<object>();

  errorInFile = [];
  is_replace = false;
  processingState: FileUploadModule;
  cancelRequests$ = new BehaviorSubject<boolean>(false);
  destroyed$ = new Subject<boolean>();
  supportedExtensions = ['pdf', 'png', 'jpg', 'jpeg'];
  fileMeta: {
    name: string;
    size: number;
    type: string;
  };
  segements: any;
  segmentSubs$: Subscription;
  selectedFile: File;
  uploadSubs$: Subscription;
  docRef$: Subscription;
  config = environment;
  isHybrid: boolean;
  files: any = [];
  DocRefMap = [];

  otherDocuments = false;
  otherDocumentName = '';
  docMimeType: string;

  // Incase testing with local servies e.g with bulkfile upload file services you might need it
  private baseUrl = ApiEndpointService.API_ENDPOINTS.baseURL;

  private endPoints = ApiEndpointService.API_ENDPOINTS.endpoints;

  constructor(
    private http: HttpClient,
    private ngrxStore: Store<any>,
    private dataSharingServices: DataSharingService
  ) {}
  ngOnInit() {
    this.processingState = {
      state: false,
      data: null,
      uploadProgress: true,
      progress: 0,
      reUpload: false,
      error: [],
      type: '',
    };
    this.outputDetails.emit(null);
    this.captureDelProp.emit(null);
    this.segmentSubs$ = this.ngrxStore.select('appReducer', 'loginReducer').subscribe((value) => {
      this.segements = value.authenticateResponse.userProfile.customerSegment;
      if (Object.prototype.hasOwnProperty.call(value, 'isMobileActive')) {
        this.isHybrid = value.isMobileActive.isHybrid;
      }
    });
    // console.log(this.documentDetails);
    this.dataSharingServices.setSuccessFullFileUploaded(this.DocRefMap);
  }

  selectFile(parmReplace: boolean) {
    if (this.is_replace === parmReplace) {
      const inputElement: HTMLElement = this.file.nativeElement as HTMLElement;
      inputElement.click();
      ``;
    } else {
      this.is_replace = parmReplace;
      const inputElement: HTMLElement = this.file.nativeElement as HTMLElement;
      inputElement.click();
      ``;
    }
  }

  convertBytesToMegabytes(bytes) {
    bytes = (bytes / 1048576).toFixed(2);
    return bytes;
  }

  handleUpload(file: File, code: string) {
    const errorMsg = [];
    this.processingState = {
      data: file,
      state: false,
      uploadProgress: true,
      progress: 0,
      reUpload: false,
      error: [],
      type: code,
    };
    if (this.processingState.data !== undefined) {
      const fileMetaData = this.processingState.data;
      const splitFileName = fileMetaData.name.split('.');
      const splitFileNameSize = splitFileName.length;
      const extension = splitFileName[splitFileNameSize - 1];
      this.docMimeType = extension;
      const specialChars = InputValidation.document_upload_filename;
      // splitFileName consists of filename
      const checkSpecialChars = splitFileName[0].match(specialChars);
      if (this.supportedExtensions.findIndex((e) => e === extension.toLowerCase()) === -1) {
        errorMsg.push('Invalid file type');
      }

      if (fileMetaData.name.length > 35) {
        errorMsg.push(
          'File name should not be longer than 35 characters. Current length : ' + fileMetaData.name.length
        );
      }
      if (this.convertBytesToMegabytes(fileMetaData.size) > 3) {
        errorMsg.push(
          'File size should not be greater than 4 MB. Current size : ' +
            this.convertBytesToMegabytes(fileMetaData.size) +
            ' MB'
        );
      }
      if (checkSpecialChars == null) {
        errorMsg.push(`File name should only contain numbers and letters`);
      }

      this.checkError(errorMsg, file);
    }
  }

  checkError(errorMsg: string[], data?: File) {
    if (errorMsg.length > 0) {
      this.captureDelProp.emit(this.key);
      this.processingState = {
        data: data,
        state: false,
        uploadProgress: true,
        progress: 0,
        reUpload: false,
        error: errorMsg,
      };
    }
  }

  updateService() {
    let docID = null;
    this.docRef$ = this.dataSharingServices.getSuccessFullFileUploaded().subscribe((data: docDetails[]) => {
      if (data) {
        if (this.checkBopKeysDetails(data, this.key).length === 1) {
          const existingKey = this.checkBopKeysDetails(data, this.key);
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          docID = existingKey[0].ochDocRef;
        }
      }
    });
    const documentPayload = {
      document: {
        // segment: {
        //   segment: this.segements.segment,
        //   subSegment: this.segements.subSegment,
        // },
        // description: this.key + '/' + this.segements.segment,
        documentType: this.processingState.type,
        transactionType: this.dataSharingServices.getTxnType(),
        // replacedDocument: true,

        commonIdForTransaction: 'someRemarks',
        // commonIdForTransaction: this.commonUUID,
        replacedDocument: this.is_replace ? true : false,
        // documentId: docID,
        // TXN_TYPE: '',
        // DOC_TYPE: this.key,
        // FILE_NAME: this.processingState.data.name,
        // IS_REPLACE: this.is_replace,
        // DOC_MIME_TYPE: this.docMimeType,
        // REMARKS: '',
        // DOC_CONTENTS: '',
      },
    };
    const formdata = new FormData();
    // testing url for checking payload

    // const endpointDetails = this.endPoints['uploadDocument'];
    // const url = this.baseUrl + endpointDetails.url;
    formdata.set('uploadedFile', this.processingState.data);
    formdata.set('uploadedFileDetails', JSON.stringify(documentPayload));

    // real env test uncomment this
    // const endpointDetails = this.endPoints['bopUploadDocument'];
    // const url = this.baseUrl + endpointDetails.url;

    // Url used by data power the url with change dynamically based on enviroment i.e sit , uat and prod

    const url = this.baseUrl + '/SmeService/UploadInternationalPaymentDocument';
    // const url = this.config.bopUrl;
    this.processingState.uploadProgress = false;
    this.processingState.state = false;
    if (this.processingState.error.length === 0) {
      this.uploadSubs$ = this.http
        .post(url, formdata, {
          observe: 'events',
        })
        .pipe(
          map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                return event;
              case HttpEventType.Response:
                return event;
              case HttpEventType.Sent:
                return event;
              case HttpEventType.ResponseHeader:
                return event;
            }
          }),
          catchError((error: HttpErrorResponse) => {
            return of(error);
          })
        )
        .subscribe(
          (event: any) => {
            // console.log(event);
            this.progressBar(event);
            this.redundantChecks(event);
          },
          (err) => {
            if (err) {
              this.processingState.error.push(err);
            }
          }
        );
    }
  }

  redundantChecks(event) {
    const errMsg = [];
    this.docRef$ = this.dataSharingServices.getSuccessFullFileUploaded().subscribe((data: docDetails[]) => {
      if (data) {
        if (event.status === 200) {
          // if (event.type === 0) {
          if (this.checkBopKeysDetails(data, this.key).length === 0) {
            // File with no virus msg from Datapower
            if (event.hasOwnProperty('body') && !event['body'].hasOwnProperty('msg')) {
              this.processingState.state = true;
              this.emitOutputData(this.key, event.body.documentReference);
              this.updateDetails.emit(this.processingState);
              this.files.push(this.processingState);
            } else if (event.hasOwnProperty('body') && event['body'].hasOwnProperty('msg')) {
              // datapower sending {msg:'virus found !'} pre check
              errMsg.push(event['body']['msg']);
              this.checkError(errMsg);
              this.processingState.state = true;
            }
          } else if (event.status === 204) {
            errMsg.push(event.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE));
            this.checkError(errMsg);
            // this.errorInFile.push(event.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE));
            // this.checkError(event.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE));
            // this.processingState.error.push(event.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE));
            this.processingState.state = true;
            // this.emitOutputData(this.key, null);
          }
        }
      }
      // console.log(this.processingState);
    });
  }

  emitOutputData(key: string, ochRefKeys: string) {
    this.outputDetails.emit({
      bopRefKeys: key,
      ochDocRef: ochRefKeys,
    });
  }

  checkBopKeysDetails(data: docDetails[], key: string) {
    return data.filter((item) => item.bopRefKeys === key);
  }

  // this looks into existing upload file with bopRefKeys to check `ochDocRef`
  fileUploadedMap(key: string) {
    this.dataSharingServices.getSuccessFullFileUploaded().subscribe((data) => {
      if (data) {
        return data.filter((item) => item.bopRefkey === key);
      }
    });
  }

  progressBar(event: any) {
    let loaded = 0;
    let interval;
    if (event.type === 0) {
      interval = setInterval(() => {
        if (loaded < 2900) {
          loaded = loaded + 4;
          this.processingState.progress = Math.round((loaded / 2900) * 100);
        } else {
          loaded = 0;
          clearInterval(interval);
        }
      });
    }
  }

  fileUpload(rawfileDetail: any, code: string) {
    this.processingState = {
      data: null,
      state: false,
      uploadProgress: true,
      progress: 0,
      reUpload: false,
      error: [],
      type: code,
    };
    let files: File;
    if (rawfileDetail.hasOwnProperty('file')) {
      files = rawfileDetail.file.nativeElement.files;
    } else {
      files = rawfileDetail.files;
    }

    let currentFile = files[0];
    if (this.title === 'Others') {
      const splitFileName = currentFile.name.split('.');
      const splitFileNameSize = splitFileName.length;
      const extension = splitFileName[splitFileNameSize - 1];
      let name = this.otherDocumentName + '.' + extension;
      // currentFile = {
      //   ...currentFile,
      //   name: `${this.otherDocumentName}.${extension}`,
      // };
      let fuel = new File([currentFile], name, {
        type: currentFile.type,
        lastModified: currentFile.lastModified,
      });
      currentFile = fuel;
      this.otherDocumentName = '';
      // console.log(fuel);
    }

    // console.log(currentFile);

    this.handleUpload(currentFile, code);
    this.updateService();
  }

  reUploadFile(file) {
    if (this.title === 'Others') {
      const inputElementOtherDocument: HTMLElement = this.otherDocument.nativeElement as HTMLElement;
      inputElementOtherDocument.focus();
      this.deleteFile(file);
      this.is_replace = true;
    } else {
      this.deleteFile(file);
      this.selectFile(true);
    }
  }

  deleteFile(file) {
    // console.log('Delete File', file);
    this.files = this.files.filter((item) => {
      return item.data.name !== file.data.name;
    });
    this.removeFile.emit(file);
    // console.log(this.files);
  }

  uploadOtherDocument() {
    this.otherDocuments = !this.otherDocuments;
  }

  ngOnDestroy() {
    this.segmentSubs$.unsubscribe();
    if (this.uploadSubs$ !== undefined) {
      this.uploadSubs$.unsubscribe();
    }
    if (this.docRef$ !== undefined) {
      this.docRef$.unsubscribe();
    }
  }
}
