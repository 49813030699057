import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FinancialRule } from '@core/data/financial-rule';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CreatePayloadService } from '../../../../core/create-payload/create-payload.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { TransactionsResponse } from '../../../../core/transaction-service/data/transactions-response';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { setTaxPaymentFormData, setTaxPaymentReceiptData } from '../../../../shared/store-utilities/actions/taxPayment.action';
import { TaxPaymentModel } from '../tax-payment.model';

enum taxMode {
  BPN = 'bpn',
  AN = 'an',
}

@Component({
  selector: 'app-tax-payment-confirmation',
  templateUrl: './tax-payment-confirmation.component.html',
  styleUrls: ['./tax-payment-confirmation.component.scss'],
})
export class TaxPaymentConfirmationComponent implements OnInit {
  destroyed$ = new Subject<boolean>();
  isSmeMode: boolean;
  reviewModel: TaxPaymentModel;
  taxPaymentFormData: TaxPaymentModel;
  reviewHeaderObj: {
    name: string;
    first: string;
  };
  taxMode: {
    heading: string;
    value: string;
  };
  taxFeild: {
    heading: string;
    value: string | number;
  };
  taxType: string;
  buttonText: string;
  isAllowedMultipleVasPaymentsForSme = environment.config.isAllowedMultipleVasPaymentsForSme;

  constructor(
    private dataSharingService: DataSharingService,
    private createPayloadService: CreatePayloadService,
    private transactionService: TransactionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<{ appReducer }>
  ) {
    store.select('appReducer', 'taxPaymentReducer').subscribe((stateData) => {
      this.taxPaymentFormData = _.cloneDeep(stateData.taxPaymentModel);
    });

    store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData) {
        this.isSmeMode = stateData.isSmeMode;
      }
    });

    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay & Transfer',
      subTitle: 'Confirmation',
      showClose: true,
      showBack: false,
      steps: true,
      module: 'taxPayment',
    });
  }

  ngOnInit() {
    if (this.taxPaymentFormData) {
      this.reviewModel = this.taxPaymentFormData;
    }
    this.reviewHeaderObj = {
      name: this.reviewModel.beneficiaryName,
      first: this.reviewModel.beneficiaryAccountNumber,
    };
    this.taxMode = {
      heading: this.reviewModel.taxMode === taxMode.BPN ? 'Business Partner Number' : 'Assessment Number',
      value: this.reviewModel.taxMode === taxMode.BPN ? this.reviewModel.businessPartnerNumber : this.reviewModel.assessmentNumber,
    };
    this.taxFeild = {
      heading: this.reviewModel.taxMode === taxMode.BPN ? 'Tax Code' : 'Assessment Year',
      value:
        this.reviewModel.taxMode === taxMode.BPN
          ? this.reviewModel.selectedTaxCode.taxCode.key + ' - ' + this.reviewModel.selectedTaxCode.taxCode.value
          : this.reviewModel.selectAssessmentYear,
    };
    this.taxType = this.setTaxType(this.reviewModel.selectedTaxType);
    if (this.isSmeMode) {
      this.buttonText = 'Send for Approval';
    } else {
      this.buttonText = 'Pay';
    }
  }

  

  goToReceipt(): void {
    const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;

    let payload = this.createPayloadService.getTransactionTaxPaymentPayload(this.reviewModel);
    if (this.isSmeMode && this.dataSharingService.selectedApprover) {
      if (this.isAllowedMultipleVasPaymentsForSme) {
        payload.transactions.prepaidPurchases = [
          ...payload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        payload = { ...payload, ...{ financialRule: financialRule } };
      }
    }

    this.postService(payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
    this.dataSharingService.selectedApprover = null;
  }

  postService(payload) {
    return this.transactionService
      .makeTransaction(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: TransactionsResponse) => {
        response = {
          ...response,
          payload: payload,
        };
        response.beneficiaryName = this.reviewModel.beneficiaryName;
        response.beneficiaryAccountNumber = this.reviewModel.beneficiaryAccountNumber;
        response.taxType = this.taxType;
        this.store.dispatch(setTaxPaymentFormData({ taxPaymentModel: null }));
        this.store.dispatch(setTaxPaymentReceiptData({ taxPaymentReceiptData: response }));
        this.router.navigate(['/payments/taxPayment/receipt'], { relativeTo: this.activatedRoute });
      });
  }

  getTaxType(): string {
    return this.reviewModel.selectedTaxType === 'DOMESTIC' ? 'Domestic Tax Office' : 'Port of Entry';
  }

  goBack(): void {
    this.router.navigate(['./payments/taxPayment/details']);
  }

  private setTaxType(type) {
    switch (type) {
      case 'DOMESTIC':
        return 'Domestic';
      case 'CUSTOM':
        return 'Customs';
    }
  }
}
