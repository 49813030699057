import { Injectable } from '@angular/core';

@Injectable()
export class UuidGeneratorService {
 

  getUUID() {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      d = Math.floor(d / 16);
      const r = (d + Math.random() * 16) % 16 | 0;
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
}
