import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sbg-information',
  styleUrls: ['./information.component.scss'],
  templateUrl: '../templates/ModelInformation.html',
})
export class InformationComponent implements OnInit {
 
  title = ' Information template';
  viewMvpTcFirst = false;
  preLoginSmeMode = this.document['preLoginMode'] === 'SME';
  constructor(
    // TODO - move this to a service
    @Inject(DOCUMENT) private document: Document,
    private dataShararing: DataSharingService
  ) {}
  ngOnInit() {
    if (this.dataShararing.modelDetails === 'mvp2') {
      this.viewMvpTcFirst = true;
    }
  }
}
