import { NgModule } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { PrepaidService } from '../../../core/prepaid-service/prepaid-service.service';

import { PayBillerRoutingModule } from './pay-biller-routing.module';
import { PayBillerListComponent } from './pay-biller-list/pay-biller-list.component';
import { PayBillerDetailsComponent } from './pay-biller-details/pay-biller-details.component';
import { PayBillerReviewComponent } from './pay-biller-review/pay-biller-review.component';
import { PayBillerReceiptComponent } from './pay-biller-receipt/pay-biller-receipt.component';
import { OnceOffBillerPaymentComponent } from './once-off-biller-payment/once-off-biller-payment.component';

@NgModule({
  providers: [DecimalPipe, DatePipe, PrepaidService],
  declarations: [
    PayBillerListComponent,
    PayBillerDetailsComponent,
    PayBillerReviewComponent,
    PayBillerReceiptComponent,
    OnceOffBillerPaymentComponent,
  ],
  imports: [SharedModule, PayBillerRoutingModule],
})
export class PayBillerModule {}
