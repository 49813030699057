import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApprovalsComponent } from './approvals.component';
import { ApprovalsRoutingModule } from './approvals-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { PrepaidUtilitiesService } from '../../core/prepaid-utilities/prepaid-utilities.service';
import { ApprovalDetailsComponent } from './approval-details/approval-details.component';
import { ApprovalReceiptComponent } from './approval-receipt/approval-receipt.component';
import { InternationalPaymentApprovalComponent } from './international-payment-approval/international-payment-approval.component';
// tslint:disable-next-line: max-line-length
import { InternationalPaymentApprovalReceiptComponent } from './international-payment-approval-receipt/international-payment-approval-receipt.component';

@NgModule({
  declarations: [
    ApprovalsComponent,
    ApprovalDetailsComponent,
    ApprovalReceiptComponent,
    InternationalPaymentApprovalComponent,
    InternationalPaymentApprovalReceiptComponent,
  ],
  imports: [CommonModule, SharedModule, ApprovalsRoutingModule],
  providers: [PrepaidUtilitiesService],
})
export class ApprovalsModule {}
