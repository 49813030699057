import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { Store } from '@ngrx/store';
import { SecurityQuestion } from '../../../services/RegistrationService/data/security-question';
import { SetSecurityQuestionsRequest } from '../../../services/RegistrationService/data/set-security-questions-request';
import { take } from 'rxjs/operators';
import { McaHeader } from '../../../core/data/mca-header';
import { McaResponseType } from '../../../core/data/mca-response-type';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss'],
})
export class SecurityQuestionsComponent implements OnInit {
  securityQuestions: SecurityQuestion[];
  userName: string;
  keyValueMetadata;


  constructor(private dataSharing: DataSharingService,
    private router: Router,
    private registrationService: RegistrationService,
    private store: Store<any>) { }

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: '',
      subTitle: 'Change Security Questions',
      showBack: false,
      showClose: false,
      steps: false,
      module: 'registration',
    });

    this.registrationService.fetchRetailSecurityChallenge({}).pipe(take(1)).subscribe(response => {
      this.securityQuestions = response.securityChallenge.securityChallengeItems;
    });

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.userName = value.userName.userName;
      this.keyValueMetadata = value.authenticateResponse.keyValueMetadata;
    });
  }

  createPayloadForSetSecurityQuestion(questionAndAnswerMap: Map<number, SecurityQuestion>): SetSecurityQuestionsRequest {
    const payload: SetSecurityQuestionsRequest = {
      userId: this.userName,
      securityQuestions: [...questionAndAnswerMap.values()],
    };
    return payload;
  }

  goToNext(questionAndAnswerMap: Map<number, SecurityQuestion>) {
    const payload = this.createPayloadForSetSecurityQuestion(questionAndAnswerMap);
    this.postService(payload);
    this.dataSharing.setPostService(this.postService.bind(this));
  }

  public postService(payload: SetSecurityQuestionsRequest) {
    this.registrationService.setRetailSecurityQuestions(payload).subscribe((response) => {
      if (response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS) {
        this.responseProcessor();
      }
    });
  }

responseProcessor() {
  for (let i = 0; i < this.keyValueMetadata.length; i++) {
    if (this.keyValueMetadata[i].key === 'FIRST_LOGIN' && this.keyValueMetadata[i].value === 'Y') {
      this.router.navigate(['/forcedChange/password']);
      return;
    } else {
      this.router.navigate(['/dashboard']);
      return;
    }
  }
}

}
