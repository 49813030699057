import { BulkFileUploadDetailsComponent } from './partials/bulkFileUploadDetails/bulkFileUploadDetails.component';
import { BulkFileReceiptComponent } from './partials/bulkFileUploadReceipt/bulkFileUploadReceipt.component';
import { BulkFileRoutingModule } from './bulkFile-routing.module';
import { BulkFileUploadListComponent } from './partials/bulkFileUploadList/bulkFileUploadList.component';
import { SharedModule } from '../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { BulkFileUploadReviewComponent } from './partials/bulkFileUploadReview/bulkFileUploadReview.component';

@NgModule({
  declarations: [
    BulkFileUploadListComponent,
    BulkFileReceiptComponent,
    BulkFileUploadDetailsComponent,
    BulkFileUploadReviewComponent,
  ],
  imports: [SharedModule, BulkFileRoutingModule],
  providers: [],
})
export class BulkFileUploadModule {}
