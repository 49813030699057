import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

import { environment } from '../../../../../environments/environment';
import { AppConstantService } from '../../../../core/app-constant/app-constant.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { DownloadService } from '../../../../core/download-service/download.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { formatDate } from '@angular/common';
import { Amount } from '../../../../core/data/amount';
import { DateUtil } from '../../../../core/utility-classes/date.util';
import { McaHeader } from '../../../../core/data/mca-header';
import { McaResponseType } from '../../../../core/data/mca-response-type';

@Component({
  selector: 'sbg-pay-mobile-wallet-receipt',
  templateUrl: './pay-mobile-wallet-receipt.component.html',
  styleUrls: ['./pay-mobile-wallet-receipt.component.scss'],
})
export class PayMobileWalletReceiptComponent implements OnInit {
  fromAccountUpdatedData;
  mainHeading;
  date = new Date();
  transData;
  bankLogo;
  finalArray = [];
  totalPaymentAmount = 0;
  responseType = '';
  responseMessage = '';
  amount: Amount;
  reciept;
  successArray = [];
  errorArray = [];
  totalTransactions;
  completedTransactions;
  config = environment.config;
  receiptTransData;
  receiptModel;
  transferData;
  payloadData;
  transactionStatus;
  transactionMessage;
  responseMsg;
  initiateItToday;
  targetsName;
  transPayload;
  PaymentReciept;
  accToMobtxnId;
  paymentModel;
  reviewModel;
  receiptDetails: ReceiptDetail[];
  isSmeMode = false;
  constructor(
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private transactionService: TransactionService,
    private appConstant: AppConstantService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private downloadService: DownloadService
  ) {
    this.store.select('appReducer').subscribe((stateData) => {
      this.transData = _.cloneDeep(stateData.mobileWalletReducer.mobileWalletReviewData);
    });

    store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });
    if (this.transData.reviewModel) {
      this.mainHeading = this.transData.reviewModel.paymentModel.currentFlow === 'new-transaction' ? 'Pay' : 'Edit Pay';
    } else {
      this.mainHeading = 'Pay';
    }

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'payMobileWallet',
    });
  }

  ngOnInit() {
    this.bankLogo = this.appConstant.BankLogo;

    // Get data from previous states
    if (this.transData.reviewModel.paymentModel.currentFlow === 'new-transaction') {
      this.fromAccountUpdatedData = this.transData.body.account[0];

      this.transPayload = this.transData.payload;
      this.responseType = this.transData.body.transactionResults[0].responseCode.responseType;
      this.responseMessage = this.transData.body.transactionResults[0].responseCode.message;

      this.reviewModel = this.transData.reviewModel;
      this.paymentModel = this.transData.reviewModel.paymentModel;

      this.accToMobtxnId = this.transactionService.getTxnId(this.transData.body.transactionResults[0]);
    } else {
      if (this.transData) {
        this.receiptTransData = this.transData.body;
        this.receiptModel = this.receiptTransData.scheduledTransaction;

        this.transferData = this.transData.payload;
        this.payloadData = this.transferData.scheduledTransaction;

        this.transactionStatus = this.transData.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);

        this.responseMsg = this.transData.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);

        if (this.transactionStatus === McaResponseType.SUCCESS) {
          this.transactionMessage = 'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' processed successfully';
        } else {
          this.transactionMessage = this.responseMsg || 'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' failed';
        }
        if (DateUtil.areSame(new Date(), this.receiptModel.nextPaymentDate, 'days')) {
          this.initiateItToday = true;
        } else {
          if(this.isSmeMode){
            this.transactionMessage =
              'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' submitted successfully and is pending for corporate user approval';
          }
          else{
            this.transactionMessage =
              'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' submitted successfully and is pending for processing';
          }
        }

        if (this.receiptModel.beneficiary && this.receiptModel.beneficiary.name) {
          this.targetsName = this.receiptModel.beneficiary.name;
        }
      }
    }
    this.receiptDetails = this.getReceiptData();
    this.amount = this.getAmount();
  }

  getAmount(): Amount {
    return {
      amount: this.paymentModel.amount,
      currency: this.reviewModel.account.availableBalance.currency,
    };
  }

  getReceiptData(): ReceiptDetail[] {
    const resultData = [];
    if (this.accToMobtxnId) {
      resultData.push({
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.accToMobtxnId,
      });
    }
    if (this.transPayload.account.accountFullName) {
      resultData.push({
        header: ReceiptHeaders.FROM,
        value: this.transPayload.account.accountFullName,
      });
    }
    resultData.push(
      {
        header: ReceiptHeaders.DATE,
        value: formatDate(this.date, 'mediumDate', 'en-US'),
      },
      {
        header: this.config.isBillerCDI ? ReceiptHeaders.PAYPULSE_NUMBER : ReceiptHeaders.MOBILE_WALLET_NUMBER,
        value: this.transPayload.transactions.prepaidPurchases[0].rechargeNumber,
      },
      {
        header: this.config.isBillerCDI ? ReceiptHeaders.WALLET_OPERATOR : ReceiptHeaders.MOBILE_WALLET_OPERATOR,
        value: this.transPayload.transactions.prepaidPurchases[0].basePrepaidProvider.friendlyName,
      },
      {
        header: ReceiptHeaders.TRANSACTION_TYPE,
        value: 'Account to Wallet',
      }
    );
    return resultData;
  }

  calculateAmount() {
    const obj =
      this.transData.payload.transactions.rOABillerPayments ||
      this.transData.payload.transactions.onceOffCdiPayments ||
      this.transData.payload.transactions.cdiPayments;
    obj.forEach((value) => {
      this.totalPaymentAmount += value.amount.amount;
    });
    this.totalPaymentAmount = parseFloat(this.totalPaymentAmount.toFixed(2));
  }

  displayReceipts() {
    this.transData.response.body.transactionResults.forEach((val, index) => {
      const mergedObject = { tileInfo: { txnId: '' }, tileDetailInfo: {} };
      if (val.responseCode.responseType === McaResponseType.ERROR) {
        mergedObject.tileInfo = val;
        mergedObject.tileInfo.txnId = this.transactionService.getTxnId(val);
        mergedObject.tileDetailInfo = this.reciept[index];
        this.errorArray.push(mergedObject);
      } else {
        mergedObject.tileInfo = val;
        mergedObject.tileInfo.txnId = this.transactionService.getTxnId(val);
        mergedObject.tileDetailInfo = this.reciept[index];
        this.successArray.push(mergedObject);
      }
    });
    this.successArray.forEach((val) => {
      this.errorArray.push(val);
    });
    this.finalArray = this.errorArray;

    this.totalTransactions = this.finalArray.length;
    this.completedTransactions = this.successArray.length;
  }

  makeNewPayment() {
    return this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  goToDetails() {
    return this.router.navigate(['./scheduleTransaction']);
  }

  printReceipt() {
    this.downloadService.printReceipt();
  }

  downloadReceipt() {
    this.downloadService.downloadReceipt();
  }
}
