import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PayMobileWalletListComponent } from './pay-mobile-wallet-list/pay-mobile-wallet-list.component';
import { PayMobileWalletDetailsComponent } from './pay-mobile-wallet-details/pay-mobile-wallet-details.component';
import { PayMobileWalletReviewComponent } from './pay-mobile-wallet-review/pay-mobile-wallet-review.component';
import { PayMobileWalletReceiptComponent } from './pay-mobile-wallet-receipt/pay-mobile-wallet-receipt.component';
import { OnceOffMobileWalletPaymentComponent } from './once-off-mobile-wallet-payment/once-off-mobile-wallet-payment.component';

const payMobileWalletRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'once-off-mobile-wallet-details',
      },
      {
        path: 'once-off-mobile-wallet-details',
        component: OnceOffMobileWalletPaymentComponent,
      },
      {
        path: 'list',
        component: PayMobileWalletListComponent,
      },
      {
        path: 'details',
        component: PayMobileWalletDetailsComponent,
      },
      {
        path: 'review',
        component: PayMobileWalletReviewComponent,
      },
      {
        path: 'receipt',
        component: PayMobileWalletReceiptComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(payMobileWalletRoutes)],
  exports: [RouterModule],
})
export class PayMobileWalletRoutingModule {}
