import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';



@Directive({
  selector: '[limitPrecision]'
})
export class PrecisionLimiterDirective {

  @Input() precision = 2;
  config = environment.config;

  constructor(private element: ElementRef) {}

  @HostListener('input') onInput() {
    this.fixInputValue();
  }

  private fixInputValue() {
    if(this.config.countryName === 'Uganda'){
      const initalValue = this.element.nativeElement.value;
      this.element.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9]*/g, '');
      if (parseFloat(initalValue) !== parseFloat(this.element.nativeElement.value)) {
        event.stopPropagation();
      }
    }
    const values: string[] = String(this.element.nativeElement.value).split('.');
    if (values[1] && values[1].length > this.precision) {
      this.element.nativeElement.value = Number(`${values[0]}.${values[1].slice(0, 2)}`);
      // we need to fire second input event to force angular bindings update
      const secondInputEvent = document.createEvent('Event');
      secondInputEvent.initEvent('input', true, true);
      this.element.nativeElement.dispatchEvent(secondInputEvent);
    }
  }
}
