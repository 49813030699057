import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentsComponent } from './payments.component';

const paymentRoutes: Routes = [
  {
    path: '',
    component: PaymentsComponent,
    children: [
      {
        path: 'beneficiary',
        loadChildren: () => import('./pay-beneficiary/pay-beneficiary.module').then((m) => m.PayBeneficiaryModule),
      },
      {
        path: 'unayo',
        loadChildren: () => import('./unayo/unayo.module').then((m) => m.UnayoModule),
      },
      {
        path: 'selfFund',
        loadChildren: () => import('./self-fund/self-fund.module').then((m) => m.SelfFundModule),
      },
      {
        path: 'biller',
        loadChildren: () => import('./pay-biller/pay-biller.module').then((m) => m.PayBillerModule),
      },
      {
        path: 'taxPayment',
        loadChildren: () => import('./tax-payment/tax-payment.module').then((m) => m.TaxPaymentModule),
      },
      {
        path: 'vas',
        loadChildren: () => import('./vas-payment/vas-payment.module').then((m) => m.VasPaymentModule),
      },
      {
        path: 'MobileWallet',
        loadChildren: () => import('./pay-mobile-wallet/pay-mobile-wallet.module').then((m) => m.PayMobileWalletModule),
      },
      {
        path: 'internationalPayment',
        loadChildren: () => import('./international-payment/international-payment.module').then((m) => m.InternationalPaymentModule),
      },
      {
        path: 'bulkFileUpload',
        loadChildren: () => import('./bulkFileUpload/bulkFile.module').then((m) => m.BulkFileUploadModule),
      },
      {
        path: 'cma',
        loadChildren: () => import('./cma-beneficiary-payment/cma-beneficiary-payment.module').then((m) => m.CmaBeneficiaryPaymentModule),
      },
      {
        path: 'gip',
        loadChildren: () => import('./instant-payment/instant-payment.module').then((m) => m.InstantPaymentModule),
      },
      {
        path: 'qr',
        loadChildren: () => import('./qr/qr.module').then((m) => m.QrModule),
      },
      {
        path: 'na-tax-payment',
        loadChildren: () => import('./namibia-tax-payment/namibia-tax-payment.module').then((m) => m.NamibiaTaxPaymentModule),
      },
      {
        path: 'gepg',
        loadChildren: () => import('./gepg-payment/gepg-payment.module').then((m) => m.GepgPaymentModule),
      },
      {
        path: 'na-tax-payment/list',
        redirectTo: '/beneficiary/list',
        pathMatch: 'full',
      },
      {
        path: 'schoolfees',
        loadChildren: () => import('./schoolfees/schoolfees.module').then((m) => m.SchoolFeesModule),
      },
      {
        path: 'crossBorderPayment',
        loadChildren: () =>
          import('./cross-border-payment/international-payment.module').then(
            (m) => m.InternationalPaymentModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(paymentRoutes)],
  exports: [RouterModule],
})
export class PaymentsRoutingModule {}
