import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { DateUtil } from '../../../core/utility-classes/date.util';

@Component({
  selector: 'sbg-copyright-footer',
  templateUrl: './copyright-footer.component.html',
  styleUrls: ['./copyright-footer.component.scss'],
})
export class CopyrightFooterComponent implements OnInit {
  copyrightTxtTierA: string;
  copyrightTxtTierB: string;
  lastLoggedIn: string;
  formattedDate: string;
  constructor(private AppConstant: AppConstantService, private store: Store<any>) {}

  ngOnInit() {
    this.copyrightTxtTierA = this.AppConstant.CopyrightTxtTierA;
    this.copyrightTxtTierB = this.AppConstant.CopyrightTxtTierB;

    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      try {
        this.lastLoggedIn = stateData.authenticateResponse.userProfile.lastLoggedIn;
      // eslint-disable-next-line no-empty
      } catch (e) {}
    });

    this.formattedDate = DateUtil.format(this.lastLoggedIn, DateUtil.DD_MMMM_YYYY_FULL_TIME_A);
  }
}
