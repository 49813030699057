import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sbg-tabs-group',
  templateUrl: './tabs-group.component.html',
  styleUrls: ['./tabs-group.component.scss'],
})
export class TabsGroupComponent implements OnInit {
  @Input() categories: { name: string; url: string }[];
  @Input() isAccountDetails: any;
  @Input() isFormettedBorder: any;
  @Input() payeeIndex: {};
  @Output() selected = new EventEmitter<number>();
  isThisInnerPopupOpen = false;
  isTab = true;
  tabsWidth = 0;
  white = '#fff';
  constructor(private dataSharing: DataSharingService, private route: ActivatedRoute, private router: Router) {}
  ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.dataSharing.getPayeeType().subscribe((value) => {
      // this.payeeIndex = value;
      // console.log(value);
      // this.selectPayeeType(value.selectedPayeeTypeIndex) ;
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.dataSharing.getRouteData().subscribe((value) => {
      // this.isTab = value.tabs;
    });
    this.tabsWidth = 100 / this.categories.length;
  }
  selectPayeeType(index) {
    if (!this.isAccountDetails) {
      this.payeeIndex = {
        selectedPayeeTypeIndex: index,
      };
      this.dataSharing.setPayeeType(this.payeeIndex);
      // emiiting it in case if the parent has some function to perform
      this.selected.emit(index);
      // this.router.navigate([ this.categories[index].url]);
    } else {
      this.payeeIndex = {
        selectedPayeeTypeIndex: index,
      };
      this.selected.emit(index);
    }
  }
}
