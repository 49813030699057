import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ServiceRequestTypeMapping {
  public serviceRequestTypes = [
    {
      key: 'AMR',
      responseObject: 'accountModificationServiceRequestDetails',
      title: 'Account Modification',
    },
    {
      key: 'LOR',
      responseObject: 'applyForLoanServiceRequestDetails',
      title: 'Apply for Loan',
    },
    {
      key: 'SLR',
      responseObject: 'applyForSimpleLoanServiceRequestDetails',
      title: 'Apply for Simple Loan',
    },
    {
      key: 'ADR',
      responseObject: 'atmDebitCardServiceRequestDetails',
      title: 'ATM Debit Card',
    },
    {
      key: 'ALA',
      responseObject: 'atmLimitAdjustmentServiceRequestDetails',
      title: 'ATM Limit Adjustment',
    },
    {
      key: 'INR',
      responseObject: 'bankAssuranceServiceRequestDetails',
      title: 'Bank Assurance',
    },
    {
      key: 'IAI',
      responseObject: 'bankAssuranceServiceRequestDetails',
      title: 'Bank Insurance',
    },
    {
      key: 'ODR',
      responseObject: 'bankCertifiedChequeServiceRequestDetails',
      title: 'Bank Certified Cheque',
    },
    {
      key: 'BSR',
      responseObject: 'blueSkyServiceRequestDetails',
      title: 'Blue Sky',
    },
    {
      key: 'CNG',
      responseObject: 'cancelNoticeServiceRequestDetails',
      title: 'Cancel Notice',
    },
    {
      key: 'CHK',
      responseObject: 'chequeBookServiceRequestDetails',
      title: 'Cheque Book',
    },
    {
      key: 'CCR',
      responseObject: 'creditCardServiceRequestDetails',
      title: 'Credit Card',
    },
    {
      key: 'CRS',
      responseObject: 'creditCardStatementServiceRequestDetails',
      title: 'Credit Card Statement',
    },
    {
      key: 'DPB',
      responseObject: 'depositBookServiceRequestDetails',
      title: 'Deposit Book',
    },
    {
      key: 'LAR',
      responseObject: 'ebankingLimitAdjustmentServiceRequestDetails',
      title: 'eBanking Limit Adjustment',
    },
    {
      key: 'FBR',
      responseObject: 'fixedDepositBreakageServiceRequestDetails',
      title: 'Fixed Deposit Breakage',
    },
    {
      key: 'IRR',
      responseObject: 'fixedDepositInterestRateServiceRequestDetails',
      title: 'Fixed Deposit Interest Rate',
    },
    {
      key: 'NWN',
      responseObject: 'giveNoticeServiceRequestDetails',
      title: 'Give Notice',
    },
    {
      key: 'ODA',
      responseObject: 'openCurrentAccountServiceRequestDetails',
      title: 'Current Account',
    },
    {
      key: 'OFD',
      responseObject: 'openFixedDepositAccountServiceRequestDetails',
      title: 'Fixed Deposit Account',
    },
    {
      key: 'SAO',
      responseObject: 'openSavingsAccountServiceRequestDetails',
      title: 'Savings Account',
    },
    {
      key: 'OLC',
      responseObject: 'overdraftLimitChangeServiceRequestDetails',
      title: 'Over Draft Limit',
    },
    {
      key: 'POR',
      responseObject: 'posReversalsServiceRequestDetails',
      title: 'POS Reversal',
    },
    {
      key: 'RTD',
      responseObject: 'renewFixedDepositAccountServiceRequestDetails',
      title: 'Fixed Deposit Account',
    },
    {
      key: 'SAR',
      responseObject: 'renewSibekeloAccountServiceRequestDetails',
      title: 'Sibekelo Account',
    },
    {
      key: 'SCR',
      responseObject: 'stopChequeServiceRequestDetails',
      title: 'Stop Cheque',
    },
    {
      key: 'SMA',
      responseObject: 'switchMailingAddressServiceRequestDetails',
      title: 'Switch Mailing Address',
    },
  ];
}
