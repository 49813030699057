import { OfferInterface } from './../interfaces/offer';
export class SelectedOffer {
  static readonly type = '[offer] Selected';

  constructor(public payload: OfferInterface) {}
}

export class GetOffer {
  static readonly type = '[Offer] Get';
}
export class Mvp2UplOfferDetails {
  static readonly type = '[Mvp2UplOfferDetails] Get';
  constructor(public payload: any) {}
}
export class Mvp2UplFreeInsurance {
  static readonly type = '[mvp2UplFreeInsurance] Get';
  constructor(public payload: any) {}
}
export class Mvp2UplRedrawLoadPayload {
  static readonly type = '[Mvp2UplRedrawLoadPayload] Get';
  constructor(public payload: any) {}
}
