import { Component, Input } from '@angular/core';

@Component({
  selector: 'sbg-from-account-data',
  templateUrl: './from-account-data.component.html',
  styleUrls: ['./from-account-data.component.scss'],
})
export class FromAccountdataComponent {
  @Input() data: any;
  @Input() showAvailBal: any;

}
