import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PayBillerListComponent } from './pay-biller-list/pay-biller-list.component';
import { PayBillerDetailsComponent } from './pay-biller-details/pay-biller-details.component';
import { PayBillerReviewComponent } from './pay-biller-review/pay-biller-review.component';
import { PayBillerReceiptComponent } from './pay-biller-receipt/pay-biller-receipt.component';
import { OnceOffBillerPaymentComponent } from './once-off-biller-payment/once-off-biller-payment.component';

const payBillerRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'once-off-biller-details',
      },
      {
        path: 'once-off-biller-details',
        component: OnceOffBillerPaymentComponent,
      },
      {
        path: 'list',
        component: PayBillerListComponent,
      },
      {
        path: 'details',
        component: PayBillerDetailsComponent,
      },
      {
        path: 'review',
        component: PayBillerReviewComponent,
      },
      {
        path: 'receipt',
        component: PayBillerReceiptComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(payBillerRoutes)],
  exports: [RouterModule],
})
export class PayBillerRoutingModule {}
