import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'sbg-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DropDownComponent implements OnInit {
  @Input() name: string;
  @Input() placeHolderName: string;
  @Input() prefixSelectedItem: string;
  @Input() option: any[];
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedValue: any;
  

  ngOnInit() {
    if (!this.name) {
      this.name = 'from';
    }
  }
  public selectOnChange() {
    this.selectChange.emit(this.selectedValue);
  }

  
}
