export class GetDebitCards {
  static readonly type = '[API] getDebitCards';
  constructor(public payload: any) {}
}

export class GetDebitCardsSuccess {
  static readonly type = '[API] getDebitCards success';
  constructor(public debitCards: any) {}
}

export class DebitCardsCacheEvict {
  static readonly type = '[API] getDebitCards cache evict';
  
}

