import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { AppConstantService } from '../../../../core/app-constant/app-constant.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { Amount } from '../../../../core/data/amount';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { formatDate } from '@angular/common';
import { DateUtil } from '../../../../core/utility-classes/date.util';
import { McaHeader } from '../../../../core/data/mca-header';
import { McaResponseType } from '../../../../core/data/mca-response-type';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'sbg-pay-biller-receipt',
  templateUrl: './pay-biller-receipt.component.html',
  styleUrls: ['./pay-biller-receipt.component.scss'],
})
export class PayBillerReceiptComponent implements OnInit, OnDestroy {
  mainHeading;
  printData;
  transData;
  bankLogo;
  finalArray = [];
  totalPaymentAmount = 0;
  message = '';
  reciept;
  successArray = [];
  errorArray = [];
  totalTransactions;
  completedTransactions;
  date;
  config = environment.config;
  receiptTransData;
  receiptModel;
  transferData;
  payloadData;
  transactionStatus;
  transactionMessage;
  responseMsg;
  initiateItToday;
  targetsName;
  isPaymentScheduled: any;
  isReccuringFirstDateToday: any;
  destroyed$ = new Subject<boolean>();
  isSmeMode = false;
  constructor(
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private transactionService: TransactionService,
    private appConstant: AppConstantService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.store
      .select('appReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        this.transData = _.cloneDeep(stateData.billerPaymentsReducer.payBillerRecieptData);
      });
      store.select('appReducer', 'loginReducer').subscribe((data) => {
        if (data) {
          this.isSmeMode = data.isSmeMode;
        }
      });

    if (this.transData) {
      if (this.transData.paymentModel.currentFlow === 'new-transaction' || this.transData.paymentModel.currentFlow === 'once-off-pay') {
        this.mainHeading = 'Pay';
      } else {
        this.mainHeading = 'Edit Pay';
      }
    } else {
      this.mainHeading = 'Pay';
    }

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: false,
      module: 'payBiller',
    });
  }

  ngOnInit() {
    this.bankLogo = this.appConstant.BankLogo;

    if (this.transData.paymentModel.currentFlow === 'new-transaction' || this.transData.paymentModel.currentFlow === 'once-off-pay') {
      if (this.transData.paymentModel.currentFlow === 'once-off-pay') {
        this.isPaymentScheduled = !DateUtil.areSame(new Date(), this.transData.paymentModel.scheduleDate, 'days');
      }

      this.reciept =
        this.transData.transactions.rOABillerPayments || this.transData.transactions.cdiPayments || this.transData.transactions.onceOffCdiPayments;

      this.message = this.transData.response.transactionResults[0].responseCode.message;
      if (this.transData.paymentModel.scheduleData) {
        this.isPaymentScheduled = this.transData.paymentModel.scheduleData.isPaymentScheduled;
        if (this.transData.paymentModel.scheduleData.numberOfPayments > 1) {
          this.isReccuringFirstDateToday = DateUtil.areSame(new Date(), this.transData.paymentModel.scheduleData.scheduleDate, 'days');
          if (this.isReccuringFirstDateToday) {
            this.isPaymentScheduled = false;
          }
        }
      }

      this.calculateAmount();
      this.displayReceipts();
    } else {
      if (this.transData) {
        // this.transData.paymnetModel.name = this.transData.paymentModel.editData.name;
        this.receiptTransData = this.transData.response;
        this.receiptModel = this.receiptTransData.body.scheduledTransaction;

        this.transactionStatus = this.transData.response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);

        this.responseMsg = this.transData.response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);

        if (this.transactionStatus === McaResponseType.SUCCESS) {
          if(this.isSmeMode){
            this.transactionMessage =
              'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' submitted successfully and is pending for corporate user approval';
          }
          else{
            this.transactionMessage =
            'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' submitted successfully and is pending for processing';
          }
        } else {
          this.transactionMessage = this.responseMsg || 'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' failed';
        }
        if (DateUtil.areSame(new Date(), this.receiptModel.nextPaymentDate, 'days')) {
          this.initiateItToday = true;
        }
        if (this.receiptModel.beneficiary && this.receiptModel.beneficiary.name) {
          this.targetsName = this.receiptModel.beneficiary.name;
        }
      }
    }
  }

  getStatus(): string {
    return this.finalArray[0].tileInfo.responseCode.responseType;
  }

  getMessage(): string {
    return this.message;
  }

  getAmount(): Amount {
    return this.finalArray[0].tileDetailInfo.amount;
  }

  getReceiptHeaders(): string[] {
    const receiptHeaders = [];
    if (this.finalArray[0].tileDetailInfo.recipient) {
      receiptHeaders.push(this.finalArray[0].tileDetailInfo.recipient.name);
    }
    if (this.finalArray[0].tileDetailInfo.biller) {
      receiptHeaders.push(this.finalArray[0].tileDetailInfo.biller.name);
    }
    return receiptHeaders;
  }

  getReceiptDetails(): ReceiptDetail[] {
    const resultData = [];
    if (this.finalArray[0].tileInfo.txnId) {
      resultData.push({
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.finalArray[0].tileInfo.txnId,
      });
    }
    resultData.push(
      {
        header: ReceiptHeaders.FROM,
        value: this.transData.response.account[0].accountFullName,
      },
      {
        header: ReceiptHeaders.DATE,
        value: formatDate(this.date, 'dd MMMM yyyy', 'en-US'),
      }
    );
    resultData.push({
      header: ReceiptHeaders.MY_REFERENCE,
      value: this.config.isBillerCDI
        ? this.finalArray[0].tileDetailInfo.recipient.customerReference
        : this.finalArray[0].tileDetailInfo.biller.userRef +
          ' ' +
          (this.finalArray[0].tileDetailInfo.biller.customerReference ? this.finalArray[0].tileDetailInfo.biller.customerReference : ''),
    });
    if (this.config.isBillerCDI) {
      resultData.push({
        header: ReceiptHeaders.COMPANY_REFERENCE,
        value: this.finalArray[0].tileDetailInfo.recipient.recipientReference,
      });
    }
    if (!this.config.isBillerCDI) {
      this.finalArray[0].tileDetailInfo.biller.customFields.forEach((item) =>
        resultData.push({
          header: item.fieldName,
          value: item.value,
        })
      );
    }
    return resultData;
  }

  calculateAmount() {
    const obj =
      this.transData.transactions.rOABillerPayments || this.transData.transactions.onceOffCdiPayments || this.transData.transactions.cdiPayments;
    obj.forEach((value) => {
      this.totalPaymentAmount += value.amount.amount;
    });
    this.totalPaymentAmount = parseFloat(this.totalPaymentAmount.toFixed(2));
  }

  displayReceipts() {
    this.date = this.transData.paymentModel.scheduleDate;
    this.transData.response.transactionResults.forEach((val, index) => {
      const mergedObject = { tileInfo: { txnId: '' }, tileDetailInfo: {} };
      if (val.responseCode.responseType === McaResponseType.ERROR) {
        mergedObject.tileInfo = val;
        mergedObject.tileInfo.txnId = this.transactionService.getTxnId(val);
        mergedObject.tileDetailInfo = this.reciept[index];
        this.errorArray.push(mergedObject);
      } else {
        mergedObject.tileInfo = val;
        mergedObject.tileInfo.txnId = this.transactionService.getTxnId(val);
        mergedObject.tileDetailInfo = this.reciept[index];
        this.successArray.push(mergedObject);
      }
    });
    this.successArray.forEach((val) => {
      this.errorArray.push(val);
    });
    this.finalArray = this.errorArray;

    this.totalTransactions = this.finalArray.length;
    this.completedTransactions = this.successArray.length;
  }

  makeNewPayment() {
    this.router.navigate(['/payments/biller/list'], { relativeTo: this.activatedRoute });
  }

  goToDetails(): void {
    this.router.navigate(['./scheduleTransaction']);
  }
  ngOnDestroy() {
    this.dataSharingService.selectedApprover = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
