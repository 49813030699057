// defining constants for file validations
export const FieldValidationMessage = {
  messages: {
    AMOUNT: 'Amount',
    EXPECTEDAMOUNT: 'Expected amount',
    INSUFFICIENTFUNDS: 'Insufficient funds',
    AMOUNTINVALID: 'Not A Valid Amount',
    DAILYEITHDRAWALLIMITCROSSED: 'Daily transaction limit exceeded',
    MONTHLYWEITHDRAWALLIMITCROSSED: 'Monthly withdrawal limit exceeded',
    BENEFICIARYNAME: 'Beneficiary name',
    BILLERNICKNAME: 'Biller nick name',
    RECIPIENTNAME: 'Recipient name',
    BANKNAME: 'Bank name',
    BRANCHNAME: 'Branch name',
    COUNTRY: 'Country name',
    BENEFICIARYADDRESS: 'Beneficiary address',
    WALLETOPERATOR: 'Mobile wallet operator',
    ACCOUNTNUMBER: 'Account number',
    REFERENCE: 'Reference',
    MYREFERENCE: 'My reference',
    THEIRREFERENCE: 'Their reference',
    MIN04: 'Minimum of 4 digits are required',
    MIN10: 'Minimum of 10 digits are required',
    MIN09: 'Minimum of 9 digits are required',
    MIN08: 'Minimum of 8 digits are required',
    MIN07: 'Minimum of 7 digits are required',
    MIN06: 'Minimum of 6 digits are required',
    MIN11: 'Minimum of 11 digits are required',
    MOBILESTART0: 'Mobile number should start with a 0',
    MOBILENOTSTART0: 'Mobile number should not start with a 0',
    MOBILEWALLETSTART0: 'Mobile wallet account number should start with a 0',
    MOBILENUMBER: 'Mobile number',
    EMAILADDRESS: 'Email address',
    VALIDEMAIL: 'Valid email address',
    FROMACCOUNT: 'From account',
    COUNTRYCODE: 'Country code',
    OTHERBANKPAYMENT: 'Other bank payments allowed in currency account only',
    TOACCOUNT: 'To account',
    GROUPNAME: 'Group name',
    NICKNAMEEXISTS: 'Nickname exists',
    USERID: 'User ID',
    ACCOUNT10: '10 character account number is required',
    DAILYLIMITNONLOCAL: 'Please note your daily limit is USD 10,000',
    FILENAME: 'File name',
    PAYMENTREFERENCE: 'Payment reference',
    NOTINRANGE: 'Not in the range for the selected provider',
    REMARKS: 'Remarks are required',
    VOUCHERTYPE: 'Vocher type',
    MOBILEWALLETNUMBER: 'Mobile Wallet Number',
    ACCOUNTTYPE: 'Account type',
    IPTRANSACTIONCURRENCY: 'Transaction currency',
    IPPAYMENTREASON: 'Reason for Payment',
    NICKNAME: 'Nickname',
    TAXCODE: 'Tax Code',
    LIMITNOTINRANGE: 'Entered amount not in range',
    BUNDLETYPE: 'Bundle Type',
    BUNDLENAME: 'Bundle Name',
    BOUQUETTYPE: 'Bouquet Type',
    PURPOSEOFPAYMENT: 'Purpose of Payment',
  },
  errorSuffixes: 'is required',
  requiredFeilds: [
    'AMOUNT',
    'BENEFICIARYNAME',
    'RECIPIENTNAME',
    'BANKNAME',
    'BRANCHNAME',
    'WALLETOPERATOR',
    'ACCOUNTNUMBER',
    'BENEFICIARYADDRESS',
    'REFERENCE',
    'THEIRREFERENCE',
    'MYREFERENCE',
    'MOBILENUMBER',
    'EMAILADDRESS',
    'FROMACCOUNT',
    'COUNTRYCODE',
    'VALIDEMAIL',
    'TOACCOUNT',
    'ACCOUNTTYPE',
    'COUNTRY',
    'EXPECTEDAMOUNT',
    'GROUPNAME',
    'BILLERNICKNAME',
    'USERID',
    'FILENAME',
    'PAYMENTREFERENCE',
    'VOUCHERTYPE',
    'MOBILEWALLETNUMBER',
    'IPTRANSACTIONCURRENCY',
    'IPPAYMENTREASON',
    'NICKNAME',
    'PURPOSEOFPAYMENT',
  ],
  validationFields: [
    'INSUFFICIENTFUNDS',
    'AMOUNTINVALID',
    'DAILYEITHDRAWALLIMITCROSSED',
    'MONTHLYWEITHDRAWALLIMITCROSSED',
    'MIN10',
    'MIN09',
    'MIN07',
    'MIN08',
    'MIN04',
    'MIN06',
    'MIN11',
    'OTHERBANKPAYMENT',
    'MOBILESTART0',
    'MOBILEWALLETSTART0',
    'MOBILENOTSTART0',
    'NICKNAMEEXISTS',
    'ACCOUNT10',
    'DAILYLIMITNONLOCAL',
    'NOTINRANGE',
    'REMARKS',
    'LIMITNOTINRANGE',
  ],
};
