
export class PaymentNotification {
  address: string;
  type: "EMAIL" | "FAX" | "SMS" | "NONE";

  constructor(address: string, type: "EMAIL" | "FAX" | "SMS" | "NONE") {
    this.address = address;
    this.type = type;
  }
}
