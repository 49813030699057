import {
  setBeneficiaryGroupData,
  setBeneficiaryRecipientData,
  setSelectedBeneficiaryData,
} from '../../../../shared/store-utilities/actions/recipient.action';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';

import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { Store } from '@ngrx/store';

import { setSelectedReceipientsData } from '../../../../shared/store-utilities/actions/pay-beneficiary.action';
import { delay } from 'rxjs/operators';
import { PaginatedRequest } from '../../../../core/data/paginated-request';
import { environment } from '../../../../../environments/environment';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { SmeHelperService } from '../../../../services/sme-helper/sme-helper.service';

@Component({
  selector: 'sbg-pay-beneficiary-list',
  templateUrl: './pay-beneficiary-list.component.html',
  styleUrls: ['./pay-beneficiary-list.component.scss'],
})
export class PayBeneficiaryListComponent implements OnInit {


  recipientList;
  editDetails;
  groupList = [];
  onceOffBarWidth: string;
  clearGroupFilter = {
    name: 'Clear',
    oldName: 'Clear',
    orderIndex: 0,
  };
  filteredData;
  rightButtonLeftSideIcon = 'add';
  rightBtnTxt = 'Once off Payment';
  listType;
  config = environment.config;
  screenSize: string;
  promptMessage: string;

  private readonly GIP_NETWORK_TYPE = 'GIP';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private recipientService: RecipientService,
    private deviceDetectorService: DeviceDetectorService,
    private store: Store<any>,
    private smeHelper: SmeHelperService,
  ) {
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;

      this.dataSharingService.setHeadPagination({
        mainTitle: 'Pay & Transfer',
        subTitle: '',
        showBack: this.screenSize === 'small',
        showClose: true,
        steps: false,
        showBackFn: this.goBack.bind(this),
      });

    });
  }

  ngOnInit() {
    this.getRecipient();
    this.getGroups();
    this.listType = this.smeHelper.isSmeMode() && !this.config.isAllowedMultipleBenefciaryPaymentsForSme ? 'radio' : 'checkbox';
    if (this.smeHelper.isSmeMode() && this.config.countryName !== 'Swaziland') {
      this.promptMessage = 'Select beneficiary from the list below to make payment.';
    } else {
      this.promptMessage = 'Select two or more beneficiaries from the list below to make multiple payments.';
    }
  }

  goBack() {
    return this.router.navigate(['/menu/payments']);
  }

  goToOnceOff() {
    this.dataSharingService.dataFromFirstStep = null;
    return this.router.navigate(['../once-off-beneficiary-details'], {
      relativeTo: this.activatedRoute,
    });
  }

  goToDetails(selectedArr) {
    this.store.dispatch(setSelectedReceipientsData({ selectedReceipientsData: selectedArr }));
    return this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  beneficiaryClicked(data) {
    if (data.type === 'checkbox') {
      const length = data.selected.length;
      if (length) {
        this.rightButtonLeftSideIcon = '';
        if (length === 1) {
          this.rightBtnTxt = 'Pay ' + data.selected[0].name;
        } else if (length > 10) {
          this.rightBtnTxt = 'Cannot pay more than 10 beneficiaries';
        } else {
          this.rightBtnTxt = 'Pay ' + length + ' Selected';
        }
      } else {
        this.rightButtonLeftSideIcon = 'add';
        this.rightBtnTxt = 'Once off Payment';
      }
    } else {
      if (data.data) {
        this.rightButtonLeftSideIcon = '';
        this.rightBtnTxt = 'Pay ' + data.data.name;
      } else {
        this.rightButtonLeftSideIcon = 'add';
        this.rightBtnTxt = 'Once off Payment';
      }
    }
  }

  getGroups(): void {
    this.recipientService.getGroups().subscribe((response) => {
      this.store.dispatch(setBeneficiaryGroupData({ beneficiaryGroups: response.groups }));
      this.groupList = [...response.groups, this.clearGroupFilter];
    });
  }

  setElementWidth() {
    return {
      width: this.onceOffBarWidth,
    };
  }

  navigateToDetailsPage() {
    this.dataSharingService.currentFlow = 'recipient-managment';
    this.dataSharingService.resetStepData();
    return this.router.navigate(['/recipients-management/add-beneficiary/add-beneficiary-details']);
  }

  navigateToEditDetailsPage() {
    this.dataSharingService.currentFlow = 'recipient-managment';
    this.dataSharingService.dataFromFirstStep = this.editDetails;
    return this.router.navigate(['../../edit-beneficiary/edit-beneficiary-details'], {
      relativeTo: this.activatedRoute,
    });
  }

  navigateToBeneDetails(selectedArr) {
    if (selectedArr.length === 0) {
      return this.goToOnceOff();
    } else {
      return this.goToDetails(selectedArr);
    }
  }

  navigateToAddBene() {
    this.dataSharingService.resetStepData();
    return this.router.navigate(['/recipients-management/beneficiary/add-beneficiary-details']);
  }

  groupChangedFn(group) {
    if (this.clearGroupFilter.orderIndex === group.orderIndex) {
      this.filteredData = this.recipientList;
    } else {
      this.filteredData = this.recipientList.filter((recipient) => recipient.recipientGroup.name === group.name);
    }
  }

  infoIconClicked(data) {
    this.dataSharingService.currentModuleName = 'Pay';
    this.store.dispatch(setSelectedBeneficiaryData({ selectedBeneficiary: data }));
    return this.router.navigate(['/recipients-management/beneficiary/details-beneficiary']);
  }

  private getRecipient(): void {
    const benificiaryOnloadPayload: PaginatedRequest = {
      pagination: false,
      startIndex: 0,
    };
    this.recipientService
      .getRecipient(benificiaryOnloadPayload)
      .subscribe((response) => {
        this.recipientList = response.body.beneficiaries.filter(beneficiary => beneficiary.route.networkType !== this.GIP_NETWORK_TYPE);
        this.store.dispatch(setBeneficiaryRecipientData({ beneficiaries: this.recipientList }));
        this.filteredData = [...this.recipientList];
      });
  }
}
