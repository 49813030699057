import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RegistrationService } from '../../../../services/RegistrationService/registration.service';
import { KeyValueMetadata } from '../../../../core/data/key-value-metadata';
import { ChangePasswordRequest } from '../../../../services/RegistrationService/data/change-password-request';
import { RegistrationState } from '../../../../shared/store-utilities/state/registration.state';
import { ChangePassword } from '../data/change-password';
import { SecurityQuestion } from '../../../../services/RegistrationService/data/security-question';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import * as _ from 'lodash';

export abstract class BaseChangePassword {
  userName: string;
  keyValueMetadata: KeyValueMetadata[];
  passwordsMismatch = false;
  oldPassword: string;
  securityChallengeItems: SecurityQuestion[];
  otpLogin = false;
  prevUrl: string;

  constructor(
    public dataSharing: DataSharingService,
    public router: Router,
    public registrationService: RegistrationService,
    public store: Store<{ appReducer: { registrationReducer: RegistrationState; loginReducer } }>,
    public routeState: RouterStateService,
  ) {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.loginUser && value.loginUser.stepUp) {
        this.otpLogin = true;
      }
    });
  }

  public createPayloadForChangePassword(changePassword: ChangePassword): ChangePasswordRequest {
    const oldPassword: string = changePassword.currentPassword == null ? this.oldPassword : changePassword.currentPassword;
    const routeHistory = this.routeState.getHistory();
    this.prevUrl = _.last(routeHistory);
    if ( this.otpLogin && this.prevUrl && ( this.prevUrl.search('/forcedChange') !== -1  ) ) {
      const payload: ChangePasswordRequest = {
        securityChallenge: {
          password: btoa(changePassword.newPassword),
          oldPassword: (oldPassword),
          digitalId: this.userName,
          securityChallengeItems: this.securityChallengeItems,
        },
        keyValueMetadata: this.keyValueMetadata,
      };
      return payload;
    } else {
      const payload: ChangePasswordRequest = {
        securityChallenge: {
          password: btoa(changePassword.newPassword),
          oldPassword: btoa(oldPassword),
          digitalId: this.userName,
          securityChallengeItems: this.securityChallengeItems,
        },
        keyValueMetadata: this.keyValueMetadata,
      };
      return payload;
    }
  }

  public completeChangePassword(changePassword: ChangePassword) {
    if (changePassword.newPassword === changePassword.confirmPassword) {
      const payload = this.createPayloadForChangePassword(changePassword);
      this.postService(payload);
      this.dataSharing.setPostService(this.postService.bind(this));
    } else {
      this.passwordsMismatch = true;
    }
  }

  public postService(payload: ChangePasswordRequest) {
    this.registrationService.changePassword(payload).subscribe((response) => {
      this.responseProccessor(response);
    });
  }

  public abstract responseProccessor(response?: any);
}
