import { Injectable } from '@angular/core';

@Injectable()
export class NameInitialsService {
  getInitials(name: string): string {
    if (name) {
      let initials;
      const nameCopy = JSON.parse(JSON.stringify(name));

      name = name.replace(/[^A-Z ]+/gi, '');
      name = name.replace(/\s*$/, '');
      name = name.replace(/^\s*/, '');
      const lastWord = name.substring(name.lastIndexOf(' ') + 1);

      if (name.length <= 0) {
        name = JSON.parse(JSON.stringify(nameCopy));
      }

      if (name.indexOf(' ') >= 0) {
        initials = name.charAt(0) + lastWord.charAt(0);
      } else {
        initials = name.charAt(0);
      }
      return initials.toUpperCase();
    }
  }
}
