import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { AccountService } from '../../../core/account-service/account-service.service';
import { Router } from '@angular/router';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';

@Component({
  selector: 'app-service-block',
  templateUrl: './service-block.component.html',
  styleUrls: ['./service-block.component.scss'],
})
export class ServiceBlockComponent implements OnInit {
  @Output() toggleAccountSecondaryNav = new EventEmitter();
  serviceRequestTypes: any;
  noticeAccoutList;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService
  ) {}
  ngOnInit() {
    this.getServiceRequestTypes();
  }

  getServiceRequestTypes() {
    this.accountService.getServiceRequestTypes().subscribe((response: any) => {
      if (response.statusText === 'OK') {
        const res = response.body;
        if (res.types && res.types.length) {
          this.serviceRequestTypes = res.types;
        }
      }
    });
  }

  openSelectedServiceRequest(serviceReq: any) {
    switch (serviceReq.type.key) {
      case 'AMR':
        this.router.navigate(['./serviceRequests/accountModificationRequest']);
        break;
      case 'SLR':
        this.router.navigate(['./serviceRequests/loanRequest']);
        break;
      case 'BSR':
        this.router.navigate(['./serviceRequests/blueSkyRequest']);
        break;
      case 'DPB':
        this.router.navigate(['./serviceRequests/depositBookRequest']);
        break;
      case 'FBR':
        this.router.navigate(['./serviceRequests/fixedDepositBreakage']);
        break;
      case 'RTD':{
        const renewAccountList = this.accountsIterator.getFixedDepositAccounts();
        this.handleServiceRequest(renewAccountList.length, './serviceRequests/renewFixedDeposit', 'a fixed deposit account');
        break;}
      case 'OLC':{
        const overDraftAccountList = this.accountsIterator.filterkeyValType('overDraft');
        this.handleServiceRequest(overDraftAccountList.length, './serviceRequests/overDraftRequest', 'an overdraft account');
        break;}
      case 'CHK':
        this.router.navigate(['./serviceRequests/chequeBookRequest']);
        break;
      case 'SCR':
        this.router.navigate(['./serviceRequests/stopChequeRequest']);
        break;
      case 'ADR':
        this.router.navigate(['./serviceRequests/atmDebitCardRequest']);
        break;
      case 'LAR':
        this.router.navigate(['./serviceRequests/eBankingLimitRequest']);
        break;
      case 'ODR':
        this.router.navigate(['./serviceRequests/bankCertifiedCheque']);
        break;
      case 'ALA':
        this.router.navigate(['./serviceRequests/atmLimitRequest']);
        break;
      case 'CCR':
        this.router.navigate(['./serviceRequests/creditCardRequest']);
        break;
      case 'SAR':
        this.router.navigate(['./serviceRequests/renewSibekeloAccountRequest']);
        break;
      case 'LOR':
        this.router.navigate(['./serviceRequests/loanRequest']);
        break;
      case 'IAI':
        this.router.navigate(['./serviceRequests/requestInsurance']);
        break;
      case 'CNG':
        this.noticeAccoutList = this.accountsIterator.filterkeyValType('noticeAccount');
        this.handleServiceRequest(this.noticeAccoutList.length, './serviceRequests/cancelNoticeGivenAccount', 'a notice deposit account');
        break;
      case 'NWN':
        this.noticeAccoutList = this.accountsIterator.filterkeyValType('noticeAccount');
        this.handleServiceRequest(this.noticeAccoutList.length, './serviceRequests/giveNoticeOnNoticeDeposit', 'a notice deposit account');
        break;
      case 'IRR':
        this.router.navigate(['./serviceRequests/fixedDepositIntrestRate']);
        break;
      case 'POS':
        this.router.navigate(['./serviceRequests/POSReversal']);
        break;
      default:
        this.router.navigate(['./serviceRequests/accountModificationRequest']);
        break;
    }
  }

  toggleAccountSecondaryNavFn(type: any, val: any) {
    const values = {
      type,
      val,
    };
    this.toggleAccountSecondaryNav.emit(values);
  }

  handleServiceRequest(isServiceExist, pageLink, errorMsg) {
    if (isServiceExist) {
      this.router.navigate([pageLink]);
    } else {
      this.dataSharingService.setErrorModal(true);
      this.dataSharingService.setErrorMessage(`You do not have ${errorMsg}`);
    }
  }
}
