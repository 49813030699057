import { SessionStore } from '../../services/SessionStore/SessionStore';
import { Observable, throwError as observableThrowError, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, map, tap, takeWhile } from 'rxjs/operators';

import { ApiEndpointService } from '../api-endpoint/api-endpoint.service';

import { TokenService } from '../token/token.service';
import { environment } from '../../../environments/environment';
import { McaMessage } from '../data/mca-message';

declare type EndpointName = keyof typeof ApiEndpointService.API_ENDPOINTS.endpoints;

@Injectable()
export class ServiceEndpointsService {
  cancelRequests$ = new BehaviorSubject<boolean>(false);

  private environment = environment;
  private baseUrl = ApiEndpointService.API_ENDPOINTS.baseURL;
  private endPoints = ApiEndpointService.API_ENDPOINTS.endpoints;

  constructor(private http: HttpClient, private token: TokenService, private sessionStore: SessionStore) {}

  private static handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead

    return observableThrowError(errMsg);
  }

  post<Rq = any, Rs = any>(endpointName: EndpointName, data: Rq, customHeaders?: { [key: string]: string }): Observable<HttpResponse<Rs>> {
    return this.http
      .post<Rs>(this.resolveEndpoint(endpointName), data, {
        headers: { ...customHeaders },
        observe: 'response',
      })
      .pipe(
        tap((r) => this.processData(r)),
        catchError(ServiceEndpointsService.handleError)
      );
  }

  /**
   * Make http PUT request to desired endpoint adding required MCA headers.
   *
   * @param endpointName - endpoint name to access
   * @param data - payload which will be passed as json
   * @param customHeaders - custom headers to add to request
   */
  put<Rq = any, Rs = any>(endpointName: EndpointName, data: Rq, customHeaders?: { [key: string]: string }): Observable<HttpResponse<Rs>> {
    return this.http
      .put<Rs>(this.resolveEndpoint(endpointName), data, {
        headers: { ...customHeaders },
        observe: 'response',
      })
      .pipe(
        tap((r) => this.processData(r)),
        catchError(ServiceEndpointsService.handleError)
      );
  }

  /**
   * Make http GET request to desired endpoint adding required MCA headers.
   *
   * @param endpointName - endpoint name to access
   * @param customHeaders - custom headers to add to request
   */
  get<Rs = any>(endpointName: EndpointName, customHeaders?: { [key: string]: string }): Observable<HttpResponse<Rs>> {
    return this.http
      .get<Rs>(this.resolveEndpoint(endpointName), {
        headers: { ...customHeaders },
        observe: 'response',
      })
      .pipe(
        tap((r) => this.processData(r)),
        catchError(ServiceEndpointsService.handleError)
      );
  }

  makeGetRequest(model: object, endpointName: string): Observable<{}> {
    const options = this.getRequestOptions(endpointName, model);
    return this.http
      .request(options.method, options.url, {
        body: options.body,
        headers: options.headers,
        observe: 'response',
      })
      .pipe(map(this.processData.bind(this)), catchError(this.handleError));
  }
  makeRequest(model: object, endpointName: string): Observable<{}> {
    const options = this.getRequestOptions(endpointName, model);
    const responseType = endpointName === 'bankofnamibiadatadsl' ? 'text' : 'json';
    return this.http
      .request(options.method, options.url, {
        body: options.body, 
        headers: options.headers,
        observe: 'response',
        responseType: responseType as 'json'
      })
      .pipe(
        map(this.processData.bind(this)),
        catchError(this.handleError),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        takeWhile((_) => !this.cancelRequests$.value)
      );
  }

  parseDataToParser(res: McaMessage) {
    return res ? JSON.parse(JSON.stringify(res)).body : this.handleError(res);
  }

  private processHeaders(res: HttpResponse<any>) {
    const sbgToken = res.headers.get('x-sbg-token');
    if (sbgToken) {
      this.token.setToken(sbgToken);
    }
  }

  private processData(res: HttpResponse<any>) {
    const body = res;
    this.processHeaders.call(this, res);
    return body || [];
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = error.message ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    return observableThrowError(errMsg);
  }

  private resolveEndpoint(endpointName: EndpointName) {
    return this.baseUrl + this.endPoints[endpointName].url;
  }

  private getRequestOptions(endpointName: string, body: object) {
    const endpointDetails = this.endPoints[endpointName];
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    return {
      url: this.baseUrl + endpointDetails.url,
      method: endpointDetails.method,
      body,
      headers,
    };
  }
}
