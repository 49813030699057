import { Component } from '@angular/core';

@Component({
  selector: 'sbg-beneficiary',
  templateUrl: './beneficiary.component.html',
  styleUrls: ['./beneficiary.component.scss'],
})
export class BeneficiaryComponent {
 
}
