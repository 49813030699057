import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _ from 'lodash';
import { DateUtil } from '../../../core/utility-classes/date.util';

//  See the Moment.js docs for the meaning of these formats:
//  https:// momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

enum EmitType {
  MAIN_FILTER = 'mainFilter',
  STATEMENT_RANGE_SELECTED = 'statementRangeSelected',
}

@Component({
  selector: 'app-list-full-filter',
  templateUrl: './list-full-filter.component.html',
  styleUrls: ['./list-full-filter.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ListFullFilterComponent implements OnInit, OnChanges {
  
  @Output() statementRangeFilter: EventEmitter<any> = new EventEmitter();
  @Output() statementMainFilter: EventEmitter<any> = new EventEmitter();
  @Output() searchFilter: EventEmitter<any> = new EventEmitter();
  @Output() pageChangeNum: EventEmitter<any> = new EventEmitter();
  @Input() uniqAccountsData: any;
  @Input() noBorder: any;
  @Input() listType: any;
  @Input() onlyBottomBorder: any;
  @Input() ifPagination: any;
  @Input() ifPaginationHide: any;
  @Input() ifNext: any;
  @Input() boxStyle: any;
  @Input() hideDateRangeFilter: any;
  @Input() isVerticalFilter: any;
  @Input() pageCont: any;
  @Input() pageNumber: any;
  @Input() itemsCount: any;
  @Input() resetFilter: any;
  @Input() allServiceRequestTypes: any;
  @Input() clearSearchFilter: any;
  mainFilter: any;
  nextTxnsRadio: any;
  searchBoxValue = {} as any;
  filterAmountIsInvalid = false;
  filterActive = false;
  statementRangeSelected: any;
  isReset = false;

  isAllowAmountRangeFilter = false;
  allowedListTypeForAmountFilter = ['accountDetails'];

  isAllowAccountsFilter = false;
  allowedListTypeForAccountsFilter = ['ScheduleTransaction'];

  isAllowTransactionTypeFilter = false;
  allowedListTypeForTransactionTypeFilter = ['accountDetails'];

  isAllowStatmentWiseListFilter = false;
  allowedListTypeForStatmentWiseListFilter = ['ScheduleTransaction', 'ReceiptList'];

  isAllowRequestTypeFilter = false;
  allowedListTypeForRequestTypeFilter = ['serviceRequests'];

  isAllowTransactionStatusFilter = false;
  allowedListTypeForTransactionStatusFilter = ['ReceiptList'];

  nextStatmentWiseList = [
    { value: 'nextWeek', label: 'Next week' },
    { value: 'nextMonth', label: 'Next month' },
    { value: 'next3Month', label: 'Next 3 month' },
    { value: 'next6Month', label: 'Next 6 month' },
  ];

  lastStatmentWiseList = [
    { value: 'lastWeek', label: 'Last week' },
    { value: 'lastMonth', label: 'Last month' },
    { value: 'last3Month', label: 'Last 3 month' },
    { value: 'last6Month', label: 'Last 6 month' },
  ];

  requestStatusList = [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Sent',
      value: 'Sent',
      dependency: ['CLO', 'SUC', 'ENT', 'AAC'],
    },
    {
      name: 'Not Sent',
      value: 'NotSent',
      dependency: ['SUS', 'FAL'],
    },
    {
      name: 'Reject',
      value: 'Reject',
      dependency: ['CAN', 'REJ', 'REC'],
    },
    {
      name: 'In Progress',
      value: 'InProgress',
      dependency: ['APR', 'WFR', 'PEN'],
    },
  ];


  ngOnInit() {
    this.mainFilter = {
      txnDateOrder: 'desc',
      transactionFilter: 'SortAll',
      transactionStatus: 'TxnStatusAll',
    };
    this.setFilterToggle();
  }

  ngOnChanges(change: any) {
    if (change['clearSearchFilter']) {
      if (this.clearSearchFilter) {
        this.searchBoxValue = {};
      }
    }
    if (change['pageCont']) {
      this.pageCont = this.pageCont || [];
    }
    if (change['itemsCount']) {
      if (this.mainFilter && !this.mainFilter.requestType) {
        this.mainFilter.requestType = this.allServiceRequestTypes && this.allServiceRequestTypes[0] ? this.allServiceRequestTypes[0] : null;
        this.mainFilter.requestStatus = this.requestStatusList && this.requestStatusList[0] ? this.requestStatusList[0] : null;
      }
    }
    if (change['resetFilter']) {
      if (this.resetFilter) {
        this.toggleFilteringOption(false);
        this.clearFilterFn();
      }
    }
  }

  setFilterToggle() {
    // Amount range filter
    if (this.allowedListTypeForAmountFilter.indexOf(this.listType) > -1) {
      this.isAllowAmountRangeFilter = true;
    }

    // Accounts filter
    if (this.allowedListTypeForAccountsFilter.indexOf(this.listType) > -1) {
      this.isAllowAccountsFilter = true;
    }

    // Transaction Type
    if (this.allowedListTypeForTransactionTypeFilter.indexOf(this.listType) > -1) {
      this.isAllowTransactionTypeFilter = true;
    }

    // Statement Wise List
    if (this.allowedListTypeForStatmentWiseListFilter.indexOf(this.listType) > -1) {
      this.isAllowStatmentWiseListFilter = true;
    }

    // Request Type
    if (this.allowedListTypeForRequestTypeFilter.indexOf(this.listType) > -1) {
      this.isAllowRequestTypeFilter = true;
      this.mainFilter.requestType = this.allServiceRequestTypes[0];
    }
    // Transaction Status
    if (this.allowedListTypeForTransactionStatusFilter.indexOf(this.listType) > -1) {
      this.isAllowTransactionStatusFilter = true;
    }
  }

  getFilterCompValues(values: any) {
    if (values) {
      this.filterActive = values.filterActive; // Flag filterActive or not
    }

    this.searchBoxValue.searchText = values.searchText;
    this.searchFilter.emit(this.searchBoxValue);
  }

  toggleFilteringOption(isfilter: boolean) {
    this.filterActive = isfilter;
  }

  pageChangeNumber(page) {
    this.pageChangeNum.emit(page);
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeAllFilters() {}

  getMinData() {
    if (this.listType === 'ScheduleTransaction') {
      return DateUtil.format(new Date(), DateUtil.ISO_YYYY_MM_DD);
    }
  }

  getMaxData() {
    if (this.listType === 'ScheduleTransaction') {
      return '';
    } else {
      return DateUtil.format(new Date(), DateUtil.ISO_YYYY_MM_DD);
    }
  }

  emitFilter(data: any, emitType: any) {
    switch (emitType) {
      case EmitType.STATEMENT_RANGE_SELECTED:
        this.statementRangeFilter.emit(data);
        break;
      case EmitType.MAIN_FILTER:
        this.statementMainFilter.emit(data);
        break;
      default:
    }
  }

  statementDateRangeValues(dateRangeVal: any) {
    this.clearFilterFn();
    this.statementRangeSelected = dateRangeVal;
    this.emitFilter(this.statementRangeSelected, EmitType.STATEMENT_RANGE_SELECTED);
  }

  sortOrderChange(event: any) {
    this.mainFilter.txnDateOrder = event.value;
    this.emitMainFilterFn();
  }

  sortInOutChange(event: any) {
    this.mainFilter.transactionFilter = event.value;
    this.emitMainFilterFn();
  }

  txnStatusChange(event: any) {
    this.mainFilter.transactionStatus = event.value;
    this.emitMainFilterFn();
  }

  requestStatusChangeFn(event: any) {
    this.mainFilter.requestStatus = event.value;
    this.emitMainFilterFn();
  }

  requestTypeDropDownChanged(event: any) {
    this.mainFilter.requestType = event;
    this.emitMainFilterFn();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  accountChangeCheckedFn(item: any, event: any) {
    this.mainFilter.uniqAccountsData = _.filter(this.uniqAccountsData, (itm: any) => {
      return itm.checked;
    });
    this.emitMainFilterFn();
  }

  numberOfPaymentsChanged() {
    if (this.mainFilter.minAmount && this.mainFilter.maxAmount) {
      const minAmount = parseFloat(this.mainFilter.minAmount);
      const maxAmount = parseFloat(this.mainFilter.maxAmount);
      this.filterAmountIsInvalid = minAmount > maxAmount;
    } else if (this.mainFilter.minAmount === null && this.mainFilter.maxAmount === null) {
      this.filterAmountIsInvalid = false;
    }
    this.emitMainFilterFn();
  }

  dateChanged() {
    this.emitMainFilterFn();
  }

  nextStatementChange(event) {
    this.nextTxnsRadio = event.value;
    this.mainFilter.fromDate = DateUtil.format(new Date(), DateUtil.ISO_YYYY_MM_DD);
    switch (this.nextTxnsRadio) {
      case 'nextWeek':
        this.mainFilter.toDate = DateUtil.format(DateUtil.add(new Date(), 7, 'days'), DateUtil.ISO_YYYY_MM_DD);
        break;
      case 'nextMonth':
        this.mainFilter.toDate = DateUtil.format(DateUtil.add(new Date(), 1, 'months'), DateUtil.ISO_YYYY_MM_DD);
        break;
      case 'next3Month':
        this.mainFilter.toDate = DateUtil.format(DateUtil.add(new Date(), 3, 'months'), DateUtil.ISO_YYYY_MM_DD);
        break;
      case 'next6Month':
        this.mainFilter.toDate = DateUtil.format(DateUtil.add(new Date(), 6, 'months'), DateUtil.ISO_YYYY_MM_DD);
        break;
    }

    this.emitMainFilterFn();
  }

  lastStatementChange(event) {
    this.nextTxnsRadio = event.value;
    this.mainFilter.toDate = DateUtil.format(new Date(), DateUtil.ISO_YYYY_MM_DD);
    switch (this.nextTxnsRadio) {
      case 'lastWeek':
        this.mainFilter.fromDate = DateUtil.format(DateUtil.subtract(new Date(), 7, 'days'), DateUtil.ISO_YYYY_MM_DD);
        break;
      case 'lastMonth':
        this.mainFilter.fromDate = DateUtil.format(DateUtil.subtract(new Date(), 1, 'months'), DateUtil.ISO_YYYY_MM_DD);
        break;
      case 'last3Month':
        this.mainFilter.fromDate = DateUtil.format(DateUtil.subtract(new Date(), 3, 'months'), DateUtil.ISO_YYYY_MM_DD);
        break;
      case 'last6Month':
        this.mainFilter.fromDate = DateUtil.format(DateUtil.subtract(new Date(), 6, 'months'), DateUtil.ISO_YYYY_MM_DD);
        break;
    }

    this.emitMainFilterFn();
  }

  emitMainFilterFn() {
    this.isReset = false;
    this.emitFilter(this.mainFilter, EmitType.MAIN_FILTER);
  }

  clearFilterFn() {
    this.isReset = false;
    setTimeout(() => {
      this.isReset = true;
    }, 100);

    if (this.uniqAccountsData && this.uniqAccountsData.length) {
      this.uniqAccountsData.forEach((element) => {
        element.checked = true;
      });
    }
    this.mainFilter = {
      isReset: this.isReset,
      requestType: this.allServiceRequestTypes && this.allServiceRequestTypes[0] ? this.allServiceRequestTypes[0] : null,
      requestStatus: this.requestStatusList && this.requestStatusList[0] ? this.requestStatusList[0] : null,
      txnDateOrder: 'desc',
      transactionFilter: 'SortAll',
      transactionStatus: 'TxnStatusAll',
    };

    this.nextTxnsRadio = '';
    this.filterAmountIsInvalid = false;
    this.emitFilter(this.mainFilter, EmitType.MAIN_FILTER);
  }
}
