import { Component, Input, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MatExpansionModule } from '@angular/material/expansion';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MatTabsModule } from '@angular/material/tabs';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';

@Component({
  selector: 'sbg-list-table-detail',
  templateUrl: './list-table-detail.component.html',
  styleUrls: ['./list-table-detail.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class ListTableDetailComponent {
  
  @Input() recipientDetail: any;

  showOptions = false;
  constructor(
    private router: Router,
    private _eref: ElementRef,
    private activatedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService
  ) {}
  navigateToPayments(): void {
    this.dataSharingService.currentFlow = 'pay-beneficiary';
    this.dataSharingService.dataFromFirstStep = [];
    this.dataSharingService.dataFromFirstStep[0] = this.recipientDetail;
    this.router.navigate(['/payments/beneficiary/details'], { relativeTo: this.activatedRoute });
    // this.router.navigate(['/payments/beneficiary']);
  }
  showOptionsBlock() {
    this.showOptions = !this.showOptions;
  }
  onClick(event) {
    if (!this._eref.nativeElement.contains(event.target)) {
      //  or some similar check
      this.showOptions = false;
    }
  }
  navigateToEditDetailsPage(): void {
    this.dataSharingService.currentFlow = 'recipient-managment';
    this.dataSharingService.dataFromFirstStep = this.recipientDetail;
    this.router.navigate(['/recipients-management/edit-beneficiary/edit-beneficiary-details'], {
      relativeTo: this.activatedRoute,
    });
    // this.router.navigate(['../../edit-beneficiary/edit-beneficiary-details'], { relativeTo: this.activatedRoute });
  }
}
