import { createAction, props } from '@ngrx/store';
import { QrDetails, ValidateQrDetails } from '../interfaces/qrPayments.model';
export const setQrId = createAction(
  '[Qr Once off Component] setQrID',
  props<{ qrId: string }>()
);

export const setValidateQrDetails = createAction(
  '[Qr OnceOff Component] setValidateQrDetails ',
  props<{ validateQrDetails: ValidateQrDetails }>()
);

export const setQrDetails = createAction(
  '[Qr Details Component] setQrDetails',
  props<{ qrDetails: QrDetails }>()
);

export const setQrResponse = createAction(
  '[Qr Review Component] setQrResponse',
  props<{ qrResponse: any }>()
);
