import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { Store } from '@ngrx/store';
import { RegistrationState } from '../../../shared/store-utilities/state/registration.state';
import { take } from 'rxjs/operators';
import { BaseChangePassword } from '../../../shared/components/change-password/util/base-change-password';
import { Subscription } from 'rxjs';
import { AuthenticateService } from '../../../core/authenticate-service/authenticate-service.service';
import { TokenService } from '../../../core/token/token.service';
import { McaHeader } from '../../../core/data/mca-header';
import { McaResponseType } from '../../../core/data/mca-response-type';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent extends BaseChangePassword implements OnInit {
  subscription: Subscription;

  constructor(
    public dataSharing: DataSharingService,
    public router: Router,
    public registrationService: RegistrationService,
    public store: Store<{ appReducer: { registrationReducer: RegistrationState; loginReducer } }>,
    private authenticateService: AuthenticateService,
    private token: TokenService,
    public routeState: RouterStateService
  ) {
    super(dataSharing, router, registrationService, store, routeState);
  }

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Registration',
      subTitle: 'Create your Password',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'registration',
    });

    this.store
      .select('appReducer', 'registrationReducer')
      .pipe(take(1))
      .subscribe((value) => {
        this.oldPassword = value.oldPassword;
      });

    this.store
      .select('appReducer', 'loginReducer')
      .pipe(take(1))
      .subscribe((value) => {
        if (value.authenticateResponse) {
          this.keyValueMetadata = value.authenticateResponse.keyValueMetadata;
          this.userName = value.authenticateResponse.userProfile.corporateId;
        }
      });
  }

  responseProccessor(response: any) {
    if (response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS) {
      this.router.navigate(['/dashboard']);
      this.token.setToken(response.headers.get(McaHeader.X_SBG_TOKEN));
    }
  }
}
