import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'sbg-disclaimer',
  styleUrls: ['./disclaimer.component.scss'],
  templateUrl: '../templates/ModelDisclaimer.html',
})
export class DisclaimerComponent {
  
  title = ' Disclaimer template';
  preLoginSmeMode = this.document['preLoginMode'] === 'SME';
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {}
}
