import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { CmaBeneficiaryListComponent } from "./cma-beneficiary-list/cma-beneficiary-list.component";
import { CmaBeneficiaryPaymentDetailsComponent } from "./cma-beneficiary-payment-details/cma-beneficiary-payment-details.component";
import { CmaBeneficiaryPaymentReviewComponent } from "./cma-beneficiary-payment-review/cma-beneficiary-payment-review.component";
import { CmaBeneficiaryPaymentReceiptComponent } from "./cma-beneficiary-payment-receipt/cma-beneficiary-payment-receipt.component";

const cmaBeneficiaryPaymentRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: '',
        pathMatch: "full",
        redirectTo: "list",
      },
      {
        path: "list",
        component: CmaBeneficiaryListComponent
      },
      {
        path: "details",
        component: CmaBeneficiaryPaymentDetailsComponent
      },
      {
        path: "review",
        component: CmaBeneficiaryPaymentReviewComponent
      },
      {
        path: "receipt",
        component: CmaBeneficiaryPaymentReceiptComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(cmaBeneficiaryPaymentRoutes)],
  exports: [RouterModule],
})
export class CmaBeneficiaryPaymentRoutingModule {
}
