import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import print from 'print-js';
import html2canvas from 'html2canvas';

import { disclaimer } from '../../constants/Logos/logosVariable';
import { AppConstantService } from '../app-constant/app-constant.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  public readonly RECEIPT_PDF = 'receipt.pdf';
  // subtract 8 for y coordinate
  private readonly PAGE_FORMAT = 'a4';
  private readonly PAGE_UNIT = 'mm';
  private readonly PAGE_ORIENTATION = 'p';

  private readonly HEADER_RECT_HEIGHT = 42;
  private readonly HEADER_X = 105;
  private readonly HEADER_Y = 54;
  private readonly HEADER_FONT_SIZE = 18;
  private readonly HEADER_TEXT = 'Receipt';
  private readonly CENTER_ALIGNMENT = 'center';

  private readonly PNG = 'PNG';
  private readonly COMPRESSION = 'FAST';

  private readonly LOGO_X = 15;
  private readonly LOGO_Y = 22;
  private readonly LOGO_HEIGHT = 12;

  private readonly DISCLAIMER_X = 18;
  private readonly DISCLAIMER_Y = 232;
  private readonly DISCLAIMER_WIDTH = 173;
  private readonly DISCLAIMER_HEIGHT = 42;

  private readonly VERTICAL_SPACE = 8;
  private readonly CONTENT_MAX_WIDTH_MM = 100;
  private readonly CONTENT_MAX_HEIGHT_MM = this.DISCLAIMER_Y - this.HEADER_Y - 2 * this.VERTICAL_SPACE;
  private readonly CONTENT_SELECTOR = '.print-data';
  private readonly CONTENT_MIME_TYPE = 'image/png';

  constructor(private appConstant: AppConstantService) {}

  async downloadReceipt(dom: any = document, selector = this.CONTENT_SELECTOR): Promise<void> {
    const doc = await this.buildDoc(dom, selector);
    doc.save(this.RECEIPT_PDF);
  }

  async downloadAllReceipts(dom: any = document): Promise<void> {
    const doc = await this.buildDoc(dom);
    doc.save(this.RECEIPT_PDF);
  }

  async printReceipt(dom: any = document, selector = this.CONTENT_SELECTOR): Promise<void> {
    const doc = await this.buildDoc(dom, selector);
    print(doc.output('bloburl', this.RECEIPT_PDF));
  }

  async printAllReceipts(dom: any = document): Promise<void> {
    const doc = await this.buildDoc(dom);

    print(doc.output('bloburl', this.RECEIPT_PDF));
  }
  private async calculateImageRatio(imgBase64: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img.width / img.height);
      img.onerror = (err) => reject(err);
      img.src = imgBase64;
    });
  }

  private async addReceiptContentAsync(page: jsPDF, content: HTMLElement): Promise<void> {
    const rect = content.getBoundingClientRect() as DOMRect;
    const canvas: HTMLCanvasElement = await html2canvas(content, {
      x: 0,
      y: 0,
      scrollY: 0,
      scrollX: 0,
      scale: 1,
      ignoreElements: (element: HTMLElement) => {
        const isRequired = ['head', 'body', 'style', 'script', 'link', 'html', 'sbg-root', 'meta', 'base'].includes(element.tagName.toLowerCase());
        return !(isRequired || element.contains(content) || content.contains(element));
      },
      onclone: async (document) => {
        document.body.classList.add('no-animation');
      },
      windowWidth: rect.width,
      windowHeight: rect.height,
    });
    const contentDataUrl = canvas.toDataURL(this.CONTENT_MIME_TYPE);
    const imgRatio = await this.calculateImageRatio(contentDataUrl);
    const maxRatio = this.CONTENT_MAX_WIDTH_MM / this.CONTENT_MAX_HEIGHT_MM;

    let contentWidth: number;
    let contentHeight: number;
    if (imgRatio < maxRatio) {
      contentHeight = this.CONTENT_MAX_HEIGHT_MM;
      contentWidth = contentHeight * imgRatio;
    } else {
      contentWidth = this.CONTENT_MAX_WIDTH_MM;
      contentHeight = contentWidth / imgRatio;
    }
    const contentX = page.internal.pageSize.getWidth() / 2 - contentWidth / 2;
    const contentY = this.HEADER_Y + this.VERTICAL_SPACE;

    page.addImage(contentDataUrl, this.PNG, contentX, contentY, contentWidth, contentHeight, '', this.COMPRESSION);
  }

  private async addReceiptHeader(page: jsPDF): Promise<void> {
    page.setFillColor(240, 243, 248);
    const headerRectWidth = page.internal.pageSize.getWidth();
    const headerRectHeight = this.HEADER_RECT_HEIGHT;
    page.rect(0, 0, headerRectWidth, headerRectHeight, 'F');
    const logoRatio = await this.calculateImageRatio(this.appConstant.BankLogo);
    const logoWidth = this.LOGO_HEIGHT * logoRatio;
    page.addImage(this.appConstant.BankLogo, this.PNG, this.LOGO_X, this.LOGO_Y, logoWidth, this.LOGO_HEIGHT, '', this.COMPRESSION);

    page.setFontSize(this.HEADER_FONT_SIZE);
    page.setTextColor(0, 0, 0);
    page.text(this.HEADER_TEXT, this.HEADER_X, this.HEADER_Y, { align: this.CENTER_ALIGNMENT });
  }

  private addReceiptDisclaimer(page: jsPDF): void {
    page.addImage(disclaimer, this.PNG, this.DISCLAIMER_X, this.DISCLAIMER_Y, this.DISCLAIMER_WIDTH, this.DISCLAIMER_HEIGHT, '', this.COMPRESSION);
  }

  private addEmptyPage(doc?): jsPDF {
    if (doc) {
      doc.addPage(this.PAGE_FORMAT, this.PAGE_ORIENTATION);
    } else {
      return new jsPDF({
        orientation: this.PAGE_ORIENTATION,
        unit: this.PAGE_UNIT,
        format: this.PAGE_FORMAT,
      });
    }
    return doc;
  }

  private async addPageData(page: jsPDF, element: any = document): Promise<void> {
    await this.addReceiptHeader(page);
    await this.addReceiptContentAsync(page, element);
    this.addReceiptDisclaimer(page);
  }

  

  private async buildDoc(dom: any = document, selector = this.CONTENT_SELECTOR): Promise<any> {
    const printDataList = dom.querySelectorAll(selector);
    let doc: jsPDF;
    for (const printData of printDataList) {
      doc = this.addEmptyPage(doc);
      await this.addPageData(doc, printData);
    }
    return doc;
  }
}
