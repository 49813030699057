import { createReducer, on } from '@ngrx/store';
import { setAccountSummaryFilterData } from '../actions/accSummary.action';
import { AccSummaryState } from '../state/accSummary.state';

const accSummFilterDataInitState = {};

const selfAccData: AccSummaryState = {
  accountSummaryFilterData: accSummFilterDataInitState,
};
export const accSummaryReducer = createReducer(
  selfAccData,
  on(setAccountSummaryFilterData, (state, { accountSummaryFilterData }) => ({ ...state, accountSummaryFilterData }))
);
