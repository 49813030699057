import { BulkFileReceiptComponent } from './partials/bulkFileUploadReceipt/bulkFileUploadReceipt.component';
import { BulkFileUploadReviewComponent } from './partials/bulkFileUploadReview/bulkFileUploadReview.component';
import { BulkFileUploadListComponent } from './partials/bulkFileUploadList/bulkFileUploadList.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BulkFileUploadDetailsComponent } from './partials/bulkFileUploadDetails/bulkFileUploadDetails.component';

const bulkFileRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'list',
      },
      {
        path: 'list',
        component: BulkFileUploadListComponent,
      },
      {
        path: 'details',
        component: BulkFileUploadDetailsComponent,
      },
      {
        path: 'review',
        component: BulkFileUploadReviewComponent,
      },
      {
        path: 'receipt',
        component: BulkFileReceiptComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(bulkFileRoutes)],
  exports: [RouterModule],
})
export class BulkFileRoutingModule {}
