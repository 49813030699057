import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CmaPayment } from '../../../../core/transaction-service/data/cma-payment';
import { getDefaultPaymentSchedule } from '../../../../core/transaction-service/data/payment-schedule';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Amount } from '../../../../core/data/amount';
import { PaymentNotification } from '../../../../core/recipient-service/data/payment-notification';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { RecipientState } from '../../../../shared/store-utilities/state/recipient.state';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { BankingMetadataService } from '../../../../core/banking-metadata/banking-metadata.service';
import { KeyValueMetadata } from '../../../../core/data/key-value-metadata';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { Beneficiary } from '../../../../core/recipient-service/data/beneficiary';
import { CountryCode } from '../../../../core/banking-metadata/data/country-code';
import { Bank } from '../../../../core/banking-metadata/data/bank';
import { Branch } from '../../../../core/banking-metadata/data/branch';
import { OnceOffCmaPayment } from '../../../../core/transaction-service/data/once-off-cma-payment';
import { delay, take, takeUntil } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { SmeHelperService } from '../../../../services/sme-helper/sme-helper.service';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-cma-beneficiary-payment-details',
  templateUrl: './cma-beneficiary-payment-details.component.html',
  styleUrls: ['./cma-beneficiary-payment-details.component.scss'],
})
export class CmaBeneficiaryPaymentDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('amountField') amountInput: NgModel;
  isSme = false;
  isOnceOff = false;
  monthlyLimit: Amount;
  dailyLimit: Amount;
  utilisedLimit: Amount;
  monthlyUtilisedLimit: Amount;
  availableLimit: any;
  availableDailyLimit: number;
  availableMonthlyLimit: number;
  accounts: any[];
  isPaymentScheduled: boolean;
  payment: CmaPayment | OnceOffCmaPayment = {
    amount: {
      amount: undefined,
      currency: environment.config.localCurrency,
    },
    beneficiary: undefined,
    futureDatedInstruction: getDefaultPaymentSchedule(),
    passportNumber: '',
    purposeCode: undefined,
    subPurposeCode: undefined,
    transactionId: this.uuidGeneratorService.getUUID(),
  };
  transactionRequest: TransactionsRequest = {
    account: undefined,
    transactions: {
      cmaPayments: [this.payment],
    },
  };
  countryCodes: CountryCode[] = [];
  selectedCountryCode: CountryCode;
  cmaPurposes: KeyValueMetadata[] = [];
  cmaSubPurposes: KeyValueMetadata[] = [];
  paymentConfirmationTypes = ['SMS', 'Email', 'None'];
  emailNotificationAddress: string;
  smsNotificationPhone: string;
  isAmountEntered = false;
  activeNotificationDetails: PaymentNotification = new PaymentNotification('', 'NONE');

  isAllowedScheduledPayment = this.paymentUtilities.checkIfScheduledPaymentsIsAllowedForTransactionType('CMA_BENEFICIARY');
  destroyed$ = new Subject<boolean>();
  // Once-off specific
  cmaBeneficiaryTypes: KeyValueMetadata[] = [];
  cmaCountryCodes: CountryCode[] = [];
  selectedCmaCountry: CountryCode;
  cmaBanks: Bank[];
  filteredCmaBanks: Bank[] = [];
  selectedBank: Bank;
  bankBranches: Branch[] = [];
  selectedBranch: Branch;
  searchBankCtrl = '';
  searchBranchCtrl = '';
  searchCodeCtrl = '';
  screenSize: string;

  private accountChanged = false;

  constructor(
    private store: Store<{
      appReducer: {
        recipientReducer: RecipientState;
        loginReducer: { authenticateResponse };
      };
    }>,
    private smeHelper: SmeHelperService,
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private accountService: AccountService,
    private bankingMetadataService: BankingMetadataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uuidGeneratorService: UuidGeneratorService,
    private paymentUtilities: PaymentUtilitiesService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;
      this.dataSharingService.setHeadPagination({
        mainTitle: 'Pay',
        subTitle: 'Details',
        showBack: this.screenSize === 'small',
        showClose: true,
        steps: true,
        module: 'payCmaBeneficiary',
        showBackFn: this.goBackToMenu.bind(this),
      });
    });
  }

  ngOnInit() {
    this.isSme = this.smeHelper.isSmeMode();
    if (this.dataSharingService.dataFromFirstStep) {
      this.transactionRequest = this.dataSharingService.dataFromFirstStep;
      this.fillDataFromTransaction();
    } else {
      this.loadBeneficiary();
    }
    this.store
      .select((state) => state.appReducer.loginReducer.authenticateResponse)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (!this.payment.passportNumber && data && data.userProfile && data.userProfile.passportNumber) {
          this.payment.passportNumber = data.userProfile.passportNumber;
        }
      });
    this.getCountryCodes();
    this.getAccounts();
    this.getLimits();
    this.getCmaPurposes();
    // console.log(this.payment);
    // this.updateHeader();
  }
  goBackToMenu() {
    return this.router.navigate(['/menu/payments']);
  }
  ngAfterViewChecked() {
    if (this.accountChanged) {
      this.amountInput.control.updateValueAndValidity();
      this.accountChanged = false;
    }
  }

  filterBanksBySelectedCountry(countryCode: string) {
    this.filteredCmaBanks = this.cmaBanks.filter((bank) => bank.country.code === countryCode);
    if (this.selectedBank && !this.filteredCmaBanks.find((bank) => bank.code === this.selectedBank.code)) {
      this.selectedBank = undefined;
    }
  }

  checkAmount() {
    this.isAmountEntered = !!this.payment.amount.amount;
    if (this.isAmountEntered && !this.amountInput.control.touched) {
      this.amountInput.control.markAsTouched();
    }
  }

  updateAccount(account: any) {
    this.transactionRequest.account = account;
    this.accountChanged = true;
  }

  getCmaSubPurposes(cmaPurpose: KeyValueMetadata) {
    this.bankingMetadataService.getCmaSubPurposeCodes(cmaPurpose).subscribe((value) => (this.cmaSubPurposes = value.cmaSubPurposes));
  }

  showPhoneNumberInput(): boolean {
    return this.activeNotificationDetails.type === 'SMS';
  }

  showEmailInput(): boolean {
    return this.activeNotificationDetails.type === 'EMAIL';
  }

  updateTransactionSchedule(values) {
    if (values.isPaymentScheduled) {
      this.payment.futureDatedInstruction.repeatNumber = values.numberOfPayments;
      this.payment.futureDatedInstruction.fromDate = new Date(values.scheduleDate);
      this.amountInput.control.setErrors(null);
      this.amountInput.control.markAsTouched();
    } else {
      this.payment.futureDatedInstruction = getDefaultPaymentSchedule();
    }
    this.isPaymentScheduled = values.isPaymentScheduled;
  }

  fetchBranches() {
    this.bankingMetadataService.getBankBranches(this.selectedBank, 'CMA').subscribe((response) => {
      if (response.body && response.body.banks && response.body.banks.length) {
        this.bankBranches = response.body.banks[0].branches;
        if (this.payment.beneficiary && this.payment.beneficiary.route && this.payment.beneficiary.route.branch) {
          this.selectedBranch = this.bankBranches.find((branch) => this.payment.beneficiary.route.branch.code === branch.code);
        } else if (this.selectedBranch) {
          this.selectedBranch = this.bankBranches.find((branch) => this.selectedBranch.code === branch.code);
        }
      }
    });
  }

  getRoute() {
    this.bankingMetadataService.getBranchRoutes([this.selectedBranch]).subscribe((response) => {
      if (response.body && response.body.branches && response.body.branches.length) {
        this.payment.beneficiary.route = JSON.parse(JSON.stringify(response.body.branches[0].routes[0]));
        this.payment.beneficiary.route.branch = JSON.parse(JSON.stringify(this.selectedBranch));
        this.payment.beneficiary.route.branch.bank = this.selectedBank;
      }
    });
  }

  goBack() {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  proceedToReview() {
    this.updateNotificationTypeData();
    this.dataSharingService.dataFromFirstStep = this.transactionRequest;
    return this.router.navigate(['../review'], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private fillDataFromTransaction() {
    if (this.transactionRequest.transactions.onceOffCmaPayments) {
      this.payment = this.transactionRequest.transactions.onceOffCmaPayments[0];
      this.switchToOnceOff();
      this.selectedBank = this.payment.beneficiary.route.branch.bank;
      this.fetchBranches();
    } else {
      this.payment = this.transactionRequest.transactions.cmaPayments[0];
    }
    this.getCmaSubPurposes(this.payment.purposeCode);
    this.payment.futureDatedInstruction.fromDate = new Date(this.payment.futureDatedInstruction.fromDate);
    this.updatePaymentConfirmationDetails();
  }

  private loadBeneficiary() {
    this.store
      .select((state) => state.appReducer.recipientReducer.selectedCmaBeneficiary)
      .pipe(take(1), takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (!this.payment.beneficiary) {
          if (value) {
            this.payment.beneficiary = JSON.parse(JSON.stringify(value));
          } else {
            this.switchToOnceOff();
          }
        }
        this.updatePaymentConfirmationDetails();
      });
  }

  private getBanks() {
    this.bankingMetadataService.getBanksList('CMA').subscribe((response) => {
      if (response.body) {
        this.cmaBanks = response.body.banks;
        if (this.selectedCmaCountry) {
          this.filterBanksBySelectedCountry(this.selectedCmaCountry.code);
          if (
            this.payment.beneficiary &&
            this.payment.beneficiary.route &&
            this.payment.beneficiary.route.branch &&
            this.payment.beneficiary.route.branch.bank
          ) {
            this.selectedBank = this.filteredCmaBanks.find((bank) => this.payment.beneficiary.route.branch.bank.code === bank.code);
          }
        }
      }
    });
  }

  private updateHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'payCmaBeneficiary',
    });
  }

  private switchToOnceOff() {
    this.isOnceOff = true;
    if (!this.payment.beneficiary) {
      this.payment.beneficiary = {
        address: {},
      } as Beneficiary;
    }
    this.transactionRequest.transactions.onceOffCmaPayments = [this.payment];
    this.transactionRequest.transactions.cmaPayments = undefined;
    this.getCmaBeneficiaryTypes();
    this.getCmaCountryCodes();
    this.getBanks();
  }

  private getCmaBeneficiaryTypes() {
    this.bankingMetadataService
      .getCmaBeneficiaryTypes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((beneficiaryTypes) => {
        this.cmaBeneficiaryTypes = beneficiaryTypes.cmaBeneficiaryTypes;
        this.payment.beneficiary.cmaBeneficiaryType = this.cmaBeneficiaryTypes.filter((value) => value.key === 'I')[0];
      });
  }

  private getCountryCodes() {
    this.bankingMetadataService
      .getCountryCodes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response) {
          this.countryCodes = response.countryCodes;
        }
        if (this.activeNotificationDetails.type === 'SMS' && this.activeNotificationDetails.address) {
          const code = this.activeNotificationDetails.address.split('-');
          if (code[0]) {
            code[0] = code[0].trim();
            this.selectedCountryCode = this.countryCodes.find((c) => c.code === code[0]);
          }
        }
      });
  }

  private getCmaCountryCodes() {
    this.bankingMetadataService
      .getCmaCountryCodes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((countryCodes) => {
        this.cmaCountryCodes = countryCodes.countryCodes;
        if (
          this.payment.beneficiary &&
          this.payment.beneficiary.route &&
          this.payment.beneficiary.route.branch &&
          this.payment.beneficiary.route.branch.bank
        ) {
          this.selectedCmaCountry = this.cmaCountryCodes.find((code) => this.payment.beneficiary.route.branch.bank.country.code === code.code);
        }
      });
  }

  private updateNotificationTypeData() {
    switch (this.activeNotificationDetails.type) {
      case 'SMS':
        this.activeNotificationDetails.address = this.selectedCountryCode.code + ' - ' + this.smsNotificationPhone;
        break;
      case 'EMAIL':
        this.activeNotificationDetails.address = this.emailNotificationAddress;
        break;
    }
  }

  private getAccounts() {
    this.accounts = this.accountsIterator.getPaymentFromAccounts('local');
    if (!this.transactionRequest.account) {
      this.transactionRequest.account = this.accounts[0];
    }
  }

  private getLimits() {
    this.monthlyLimit = this.accountService.getLimit('MONTHLY');
    this.monthlyUtilisedLimit = this.accountService.getLimit('MONTHLY_UTILIZED');
    this.dailyLimit = this.accountService.getLimit('DAILY');
    this.utilisedLimit = this.accountService.getLimit('DAILY_UTILIZED');

    if (this.dailyLimit) {
      this.availableDailyLimit = this.dailyLimit.amount;
      if (this.utilisedLimit) {
        this.availableLimit = this.availableDailyLimit - this.utilisedLimit.amount;
        this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
      }
    }
    if (this.monthlyLimit) {
      this.availableMonthlyLimit = this.monthlyLimit.amount;
      if (this.monthlyUtilisedLimit) {
        this.availableLimit = this.availableMonthlyLimit - this.monthlyUtilisedLimit.amount;
        this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
      }
    }
  }

  private getCmaPurposes() {
    this.bankingMetadataService
      .getCmaPurposeCodes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => (this.cmaPurposes = value.cmaPurposes));
  }

  private updatePaymentConfirmationDetails() {
    if (!this.payment.beneficiary.paymentConfirmation) {
      this.payment.beneficiary.paymentConfirmation = {
        notifications: [this.activeNotificationDetails],
      };
    } else {
      this.activeNotificationDetails = this.payment.beneficiary.paymentConfirmation.notifications[0];
      switch (this.activeNotificationDetails.type) {
        case 'EMAIL':
          this.emailNotificationAddress = this.activeNotificationDetails.address;
          break;
        case 'SMS':{
          const phoneParts = this.activeNotificationDetails.address.split('-');
          if (phoneParts[1]) {
            this.smsNotificationPhone = phoneParts[1].trim();
          }
          break;}
      }
    }
  }
  
}
