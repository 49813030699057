import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { TransactionManagementComponent } from './transaction-management.component';
import { TransactionManagementRoutingModule } from './transaction-management-routing.module';
import { IndividualReceiptComponent } from './individual-receipt/individual-receipt.component';

@NgModule({
  declarations: [TransactionManagementComponent, IndividualReceiptComponent],
  imports: [SharedModule, TransactionManagementRoutingModule],
})
export class TransactionManagementModule {}
