import { OtpComponent } from './components/otp/otp.component';
import { MenuComponent } from './components/menu/menu.component';
import {
  NgModule,
  NO_ERRORS_SCHEMA,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthGuardService } from './core/auth-guard/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { SelectivePreloadingStrategy } from './selective-preloading-strategy';
import { ReceiptComponent } from './shared/components/receipt/receipt.component';
import { PrintLayoutComponent } from './shared/components/print-layout/print-layout.component';
import { NotifyComponent } from './components/notification/notifylist/notifylist.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'enterprise',
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'otp',
    component: OtpComponent,
  },
  {
    path: 'error',
    pathMatch: 'full',
    component: ErrorModalComponent,
  },
  {
    path: 'menu/:flow',
    pathMatch: 'full',
    component: MenuComponent,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    data: { preload: true },
  },
  {
    path: 'accountDetails',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/account-details/account-details.module').then((m) => m.AccountDetailsModule),
  },
  {
    path: 'payments',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/payments/payments.module').then(
        (m) => m.PaymentsModule
      ),
  },
  {
    path: 'buy',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: 'recipients-management',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        './components/recipients-management/recipients-management.module'
      ).then((m) => m.RecipientsManagementModule),
  },
  {
    path: 'sendMoney',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/send-money/send-money.module').then(
        (m) => m.SendMoneyModule
      ),
  },
  {
    path: 'debitCards',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/debit-cards/debit-cards.module').then(
        (m) => m.DebitCardsModule
      ),
  },
  {
    path: 'transaction-management',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        './components/transaction-management/transaction-management.module'
      ).then((m) => m.TransactionManagementModule),
  },
  {
    path: 'serviceRequests',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/service-requests/service-requests.module').then(
        (m) => m.ServiceRequestsModule
      ),
  },
  {
    path: 'profileAndSettings',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/profile-settings/profile-settings.module').then(
        (m) => m.ProfileSettingsModule
      ),
  },
  {
    path: 'scheduleTransaction',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        './components/schedule-transaction/schedule-transaction.module'
      ).then((m) => m.ScheduleTransactionModule),
  },
  {
    path: 'offers',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/notification/upl/upl.module').then(
        (m) => m.UplModule
      ),
  },
  {
    path: 'notification',
    component: NotifyComponent,
    pathMatch: 'full',
  },
  {
    path: 'overdraft',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        './components/notification/notifylist/overdraft/overdraft.module'
      ).then((m) => m.OverdraftModule),
  },
  {
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [{ path: 'invoice/:invoiceIds', component: ReceiptComponent }],
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./components/registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'forgotPassword',
    loadChildren: () =>
      import('./components/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'open-account',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/account-opening/account-opening.module').then(
        (m) => m.AccountOpeningModule
      ),
  },
  {
    path: 'forcedChange',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import('./components/forced-scenarios/forced-scenarios.module').then(
        (m) => m.ForcedScenariosModule
      ),
  },
  {
    path: 'approvals',
    canActivate: [AuthGuardService],
    // loadChildren: () => import('./components/approvals/approvals.module').then((m) => m.ApprovalsModule),
    loadChildren: () =>
      import('./components/approvals/approvals.module').then(
        (m) => m.ApprovalsModule
      ),
  },
  {
    path: 'onlineServiceRequests',
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import(
        './components/online-service-requests/online-service-requests.module'
      ).then((m) => m.OnlineServiceRequestsModule),
  },
  // These routes should be at the bottom
  // { path: '', redirectTo: '/enterprise', pathMatch: 'full' },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: SelectivePreloadingStrategy,
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
    FlexLayoutModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [RouterModule],
  providers: [SelectivePreloadingStrategy],
})
export class AppRoutingModule {}
