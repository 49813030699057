import { Injectable } from '@angular/core';
import { ServiceEndpointsService } from '../../core/service-endpoints/service-endpoints.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SmeServiceService {
  constructor(private serviceEndpoint: ServiceEndpointsService) {}
  getApprovals(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'getApprovals').pipe(map((res) => res));
  }
  getDocumentLists(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'documentFileList').pipe(map((res) => res));
  }
  documentDownloadFile(payload): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'documentDownloadFile').pipe(map((res) => res));
  }
  approval(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'approval').pipe(map((res) => res));
  }
  bulkInstantMoney(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'bulkInstantMoney').pipe(map((res) => res));
  }
  downloadFile(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'DownloadFile').pipe(map((res) => res));
  }
  downloadBulkInstantFile(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'DownloadBulkInstantFile').pipe(map((res) => res));
  }
  serviceRequestDetails(payload: object): Observable<{}> {
    return this.serviceEndpoint.makeRequest(payload, 'ServiceRequestDetails').pipe(map((res) => res));
  }
}
