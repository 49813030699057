import { OtpDetails } from './../actions/otp.action';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
export class OtpStateModel {
  componentDetails: any;
}
@Injectable()
@State<OtpStateModel>({
  name: 'Otp',
  defaults: {
    componentDetails: null,
  },
})
export class OtpDetailsState {
  @Selector()
  static getOtpDetails(state: OtpStateModel) {
    return state.componentDetails;
  }
  @Action(OtpDetails)
  addOtpDetails(
    { getState, setState }: StateContext<OtpStateModel>,
    { payload }: OtpDetails
  ) {
    const state = getState();
    setState({
      ...state,
      componentDetails: payload,
    });
  }
}
