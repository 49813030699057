import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sbg-receipt-heading-info',
  templateUrl: './receipt-heading-info.component.html',
  styleUrls: ['./receipt-heading-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReceiptHeadingInfoComponentComponent {

  @Input() currency: string;
  @Input() amount: PaymentCurrencyAmount;
  @Input() charges: string;
  @Input() message: string;

}
