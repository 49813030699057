import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as recipientAction from '../../../shared/store-utilities/actions/recipient.action';
import { Beneficiary } from '../../recipient-service/data/beneficiary';
import { take } from 'rxjs/operators';
import { RecipientGroup } from '../../recipient-service/data/recipient-group';
import { environment } from '../../../../environments/environment';

@Injectable()
export class GroupUtilitiesService {
  beneficiaries = [];
  groups = [];
  config = environment.config;

  constructor(private store: Store<any>) {
    this.store
      .select('appReducer', 'recipientReducer')
      .subscribe((stateData) => {
        this.beneficiaries = [...stateData.beneficiaries];
        if (stateData.beneficiaryGroups) {
          this.groups = [...stateData.beneficiaryGroups];
        }
      });
  }

  public updateBillersInGroupDetails(groupDetails, billers) {
    let allBillers;
    let complementArray;
    let updatedBillers;
    let newGroupBillers;

    if (!groupDetails.billers) {
      groupDetails = { billers: [] };
    }

    this.store
      .select('appReducer', 'recipientReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        allBillers = [...stateData.billers];
      });

    let deletedBillers;
    if (this.config.isBillerCDI) {
      deletedBillers = billers.filter(
        (item) => item.recipientGroup.name == null
      );
    } else {
      deletedBillers = billers.filter((item) => item.group.name == null);
    }

    if (deletedBillers.length > 0) {
      const check = this.complementBillerArrays(
        groupDetails.billers,
        deletedBillers
      );
      newGroupBillers = [...check];
      complementArray = this.complementBillerArrays(allBillers, billers);
      updatedBillers = [...complementArray, ...billers];
    } else {
      const complementExistingAndNewBillers = this.complementBillerArrays(
        groupDetails.billers,
        billers
      );
      newGroupBillers = [...complementExistingAndNewBillers, ...billers];
      complementArray = this.complementBillerArrays(
        allBillers,
        newGroupBillers
      );
      updatedBillers = [...complementArray, ...newGroupBillers];
    }

    const newBillerNames = newGroupBillers.map((item) => item.name);
    const newGroupDetails = {
      ...groupDetails,
      billers: newGroupBillers,
      billerNames: newBillerNames,
    };

    this.store.dispatch(
      recipientAction.setBillerRecipientData({ billers: updatedBillers })
    );
    this.store.dispatch(
      recipientAction.setSelectedBillerGroupData({
        billerGroupDetails: newGroupDetails,
      })
    );
  }

  public recipientInGroups(groups, bene, type = 'beneficiary') {
    // for ( let i = groups.length - 1; i >= 0; i-- ) {
    //     let value = groups[ i ];
    //     value.beneficiaryNames = [];
    //     value.beneficiaries = [];
    //     for ( let j = beneficiaries.length - 1; j >= 0; j-- ) {
    //         let val = beneficiaries[ j ];
    //         if ( val.recipientGroup !== null && value.name === val.recipientGroup.name ) {
    //             value.beneficiaries.push( val );
    //             value.beneficiaryNames.push( val.name );
    //         }
    //     }
    //     value.namelength = value.beneficiaryNames.length;
    // }
    //     return groups;
    // this.groups = groups;
    const tempBeneficiaries = {};
    const tempBeneficiariesNames = {};
    if (!bene) {
      return [];
    }
    bene.forEach((beneficiary) => {
      if (beneficiary.group && beneficiary.group.name) {
        if (!tempBeneficiaries[beneficiary.group.name]) {
          tempBeneficiaries[beneficiary.group.name] = [];
        }
        if (!tempBeneficiariesNames[beneficiary.group.name]) {
          tempBeneficiariesNames[beneficiary.group.name] = [];
        }
        tempBeneficiaries[beneficiary.group.name].push(beneficiary);
        tempBeneficiariesNames[beneficiary.group.name].push(beneficiary.name);
      }
      if (beneficiary.recipientGroup && beneficiary.recipientGroup.name) {
        if (!tempBeneficiaries[beneficiary.recipientGroup.name]) {
          tempBeneficiaries[beneficiary.recipientGroup.name] = [];
        }
        if (!tempBeneficiariesNames[beneficiary.recipientGroup.name]) {
          tempBeneficiariesNames[beneficiary.recipientGroup.name] = [];
        }
        tempBeneficiaries[beneficiary.recipientGroup.name].push(beneficiary);
        tempBeneficiariesNames[beneficiary.recipientGroup.name].push(
          beneficiary.name
        );
      }
    });
    const tempGroups = [];
    groups.forEach((group) => {
      let tempObj;
      let beneLength;
      switch (type) {
        case 'biller': {
          const billers = tempBeneficiaries[group.name]
            ? tempBeneficiaries[group.name]
            : [];
          const billerNames = tempBeneficiariesNames[group.name]
            ? tempBeneficiariesNames[group.name]
            : [];
          let typeName;
          if (this.config.isBillerCDI) {
            typeName = billers.length > 1 ? ' companies' : ' company';
          } else {
            typeName = billers.length > 1 ? ' billers' : ' biller';
          }
          beneLength = billers.length + typeName;
          tempObj = { ...group, billers, billerNames, beneLength };
          break;
        }
        case 'beneficiary': {
          const beneficiaries = tempBeneficiaries[group.name]
            ? tempBeneficiaries[group.name]
            : [];
          const beneficiaryNames = tempBeneficiariesNames[group.name]
            ? tempBeneficiariesNames[group.name]
            : [];
          beneLength =
            beneficiaries.length +
            (beneficiaries.length > 1 ? ' beneficiaries' : ' beneficiary');
          tempObj = { ...group, beneficiaries, beneficiaryNames, beneLength };
          break;
        }
      }
      tempGroups.push(tempObj);
    });
    // console.log(tempGroups);
    return tempGroups;
  }

  // this.billersInGroups = function( groups, billers ) {
  //     for ( var i = groups.length - 1; i >= 0; i-- ) {
  //         var value = groups[ i ];
  //         value.billerNames = [];
  //         value.billers = [];
  //         for ( var j = billers.length - 1; j >= 0; j-- ) {
  //             var val = billers[ j ];
  //             if ( val.group ) {
  //                 if ( val.group !== null && value.name === val.group.name ) {
  //                     value.billers.push( val );
  //                     value.billerNames.push( val.nickName );
  //                 }
  //             }
  //             if ( val.recipientGroup ) {
  //                 if ( val.recipientGroup !== null && value.name === val.recipientGroup.name ) {
  //                     value.billers.push( val );
  //                     value.billerNames.push( val.nickName );
  //                 }
  //             }
  //         }
  //         value.namelength = value.billerNames.length;
  //     }
  //     return groups;
  // };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public billersInGroups(groups, billers) {
    // const tempBillers = {};
    // const tempBillersNames = {};
    // billers.forEach()
    // groups.map(group => {
    //     const tempValue = {
    //         billerNames: [],
    //         billers: []
    //     };
    //     const value = { ...group, tempValue };
    // });
  }

  public updateGroupsAndRecipientWithNewName(
    oldGroup: RecipientGroup,
    newGroup: RecipientGroup
  ) {
    this.store
      .select('appReducer', 'recipientReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        this.beneficiaries = [...stateData.beneficiaries];
        this.groups = [...stateData.beneficiaryGroups];
      });
    const bene = this.beneficiaries.map((beneficiary: Beneficiary) => {
      const temp = { ...beneficiary };
      const newGroupData = { ...newGroup };
      if (
        temp.recipientGroup &&
        temp.recipientGroup.name &&
        temp.recipientGroup.name.toLowerCase() === oldGroup.name.toLowerCase()
      ) {
        temp.recipientGroup = newGroupData;
        temp.recipientGroup.name = temp.recipientGroup.name.toUpperCase();
      }
      return temp;
    });
    this.store.dispatch(
      recipientAction.setBeneficiaryRecipientData({ beneficiaries: bene })
    );
    const grp = this.groups.map((group: RecipientGroup) => {
      const temp = { ...group };
      if (temp.name === oldGroup.name) {
        oldGroup.name = temp.name = newGroup.name.toUpperCase();
        oldGroup.oldName = temp.oldName = newGroup.oldName;
        oldGroup.orderIndex = temp.orderIndex = newGroup.orderIndex;
      }
      return temp;
    });
    this.store.dispatch(
      recipientAction.setBeneficiaryGroupData({ beneficiaryGroups: grp })
    );
    return oldGroup;
  }

  updateGroupsAndBillersWithNewName(oldGroup, newGroup) {
    let billers;
    let groups;
    this.store
      .select('appReducer', 'recipientReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        // console.log(stateData);
        billers = [...stateData.billers];
        groups = [...stateData.billerGroups];
      });
    const b = billers.map((biller: { group }) => {
      const temp: any = { ...biller };
      // console.log(temp);
      if (
        temp.recipientGroup &&
        temp.recipientGroup.name.toLowerCase() === oldGroup.name.toLowerCase()
      ) {
        temp.recipientGroup = newGroup;
        temp.recipientGroup = {
          ...temp.recipientGroup,
          name: temp.recipientGroup.name.toUpperCase(),
        };
      }
      return temp;
    });
    this.store.dispatch(recipientAction.setBillerRecipientData({ billers: b }));
    const grp = groups.map(
      (group: { name; recipientGroup; orderIndex; oldName }) => {
        const temp = { ...group };
        if (temp.name === oldGroup.name) {
          oldGroup.name = temp.name = newGroup.name.toUpperCase();
          oldGroup.oldName = temp.oldName = newGroup.oldName;
          oldGroup.orderIndex = temp.orderIndex = newGroup.orderIndex;
        }
        return temp;
      }
    );
    this.store.dispatch(
      recipientAction.setBillerGroupData({ billerGroups: grp })
    );
    return oldGroup;
  }

  public updateCMAGroupsAndRecipientWithNewName(oldGroup, newGroup) {
    this.store
      .select('appReducer', 'recipientReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        this.beneficiaries = [...stateData.cmaBeneficiaries];
        this.groups = [...stateData.cmaBeneficiaryGroups];
      });

    const bene = this.beneficiaries.map((beneficiary: Beneficiary) => {
      const temp = { ...beneficiary };
      if (
        temp.recipientGroup &&
        temp.recipientGroup.name &&
        temp.recipientGroup.name.toLowerCase() === oldGroup.name.toLowerCase()
      ) {
        temp.recipientGroup = { ...newGroup };
        temp.recipientGroup.name = temp.recipientGroup.name.toUpperCase();
      }
      return temp;
    });
    this.store.dispatch(
      recipientAction.setCmaBeneficiaryRecipientData({ cmaBeneficiaries: bene })
    );
    const grp = this.groups.map(
      (group: { name; recipientGroup; orderIndex; oldName }) => {
        const temp = { ...group };
        if (temp.name === oldGroup.name) {
          oldGroup.name = temp.name = newGroup.name.toUpperCase();
          oldGroup.oldName = temp.oldName = newGroup.oldName;
          oldGroup.orderIndex = temp.orderIndex = newGroup.orderIndex;
        }
        return temp;
      }
    );
    this.store.dispatch(
      recipientAction.setCmaBeneficiaryGroupData({ cmaBeneficiaryGroups: grp })
    );
    return oldGroup;
  }
  private complementBillerArrays(firstArray, secondArray): [] {
    if (this.config.isBillerCDI) {
      return firstArray.filter(
        (biller) =>
          !secondArray
            .map((item) => item.recipientId)
            .includes(biller.recipientId)
      );
    } else {
      return firstArray.filter(
        (biller) =>
          !secondArray
            .map((item) => item.roaBillerId)
            .includes(biller.roaBillerId)
      );
    }
  }
}
