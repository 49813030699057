import { Route } from '../banking-metadata/data/route';

export class OnceOffBenefDetailsModel {
  benefName: string;
  banks: object[];
  branches: object[];
  selectedBank: {};
  selectedBranch: {};
  accountNumber: number;
}

export class OnceOffPaymentDetailsModel {
  amount: number;
  mobileNumber: number;
  email: string;
  routes: object[];
  selectedRoute: {};
  benefAddress: string;
  selectedPaymentProof: string;
  paymentConfirmation: object;
  constructor(public routeNetworkType: any) {}
}

export class SelfFundDetailsModel {
  fromAccounts: object[];
  toAccounts: object[];
  amount: number;
  reference: string;
  selectedFromAccount: object;
  selectedToAccount: object;
  requiredRate: number;
  totalAmount: object;
  paymentScheduleFrequency: object;
}

export class AddBeneDetailsModel {
  accountNumber: number;
  routes: Route[];
  isEditBeneficiary: boolean;
  beneGroup: object[];
  selectBeneGroup: object;
  banks: object[];
  selectedCountryCode: { code: string };
  selectedBank: { name: string };
  branches: object[];

  benefName: string;

  selectedBranch: {};
  email: string;
  rechargeNumber: number;
  group: any;
  groups: any;
  mobileNumber: number;
  yourReference: number;
  myReference: string;
  theirReference: string;

  emailAddress: string;
  firstRoutes: any;
}

export class OnceOffBillerModel {
  fromAccounts: object[];
  amount: number;
  selectedFromAccount: object;
  selectedBiller: object;
  myReference: string;
  theirReference: string;
  futureDatedPay: object;
  choosenBank: string;
}
// pay beneficairy details model
export class PayBeneficiaryDetailsModel {
  benefName: string;
  banks: object[];
  branches: object[];
  selectedBank: {};
  selectedBranch: {};
  accountNumber: number;
  email: string;
  mobileNumber: number;
  myReference: string;
  theirReference: string;
  beneGroup: object[];
  selectBeneGroup: object;
  routes: object[];
}
export class BuyAirtime {
  fromAccounts: object[];
  isAmountEntered: boolean;
  mobileNumber: string;
  selectedFromAccount: object[];
  selectedProvider: object[];
  voucherAmount: number;
}
export class IncrementAmount {
  amount: number;
}

export class AddCMABeneDetailsModel {
  isEditCMABeneficiary: boolean;
  benefName: string;
  benefSurName: string;
  banks: object[];
  branches: object[];
  selectedBank: { name: string };
  selectedBranch: {};
  accountNumber: number;
  email: string;
  rechargeNumber: number;
  cmagroup: any;
  cmagroups: any;
  mobileNumber: number;
  yourReference: number;
  myReference: string;
  theirReference: string;
  beneCMAGroup: object[];
  selectBeneCMAGroup: object;
  routes: Route;
  emailAddress: string;
  selectedCountryCode: { code: string };
  country: { code: string };
  beneType: object;
  city: string;
  town: string;
  street: string;
}
