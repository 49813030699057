import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';

import { NameInitialsService } from '../../../../core/name-initials/name-initials.service';
//  import { _ } from 'underscore';

@Component({
  selector: 'sbg-list-table-buy',
  templateUrl: './list-table-buy.component.html',
  styleUrls: ['./list-table-buy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListTableBuyComponent implements OnInit, AfterViewInit {
  
  @ViewChild('listTableIdentifier') listTableIdentifierEl: ElementRef;

  @Input() recipients: object[];
  @Input() selectButtonType: string; //  is it radio or checkbox
  @Input() nameInitialKey: string; //  initial to be displayed in list
  @Input() nameSubText: string; //  subtxt to be displayed in list
  @Input() addItemText: string;
  @Input() listType: string; //  type of list items like Beneficiary or biller or prepaid
  @Input() selectPromptMessage: string;
  @Input() isAddItemRequired: boolean;
  @Input() navigateGroups: boolean;
  @Input() isInfoIconRequired: boolean;
  @Input() enableOnceOffPay: boolean;
  @Input() isSearchNeeded: boolean;

  @Input() dropDownData: object[];

  @Output() clickInfoFunction = new EventEmitter<any>();
  @Output() addItemFunction = new EventEmitter<any>();
  @Output() goToPayFunction = new EventEmitter<any>();
  searchText: string;
  selected = [];
  // selectItem;
  selectedGroup;
  onceOffBarWidth;
  checkedIndex = -1;
  panelOpenState = false;
  selectedRecipientName: string;
  constructor(private nameInitialsService: NameInitialsService) {}

  ngOnInit() {
    this.selectItem = this.selectButtonType === 'checkbox' ? this.selectCheckboxItems : this.selectRadioItems;
  }
  ngAfterViewInit() {
    this.onceOffBarWidth = document.getElementById('listTableIdentifier').offsetWidth + 'px';
  }

  getNameInitials(name): string {
    return this.nameInitialsService.getInitials(name);
  }
  inputChanged(str) {
    this.searchText = str;
  }

  addItemToListFunction() {
    this.addItemFunction.emit(event);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToPay(event) {
    this.goToPayFunction.emit(this.selected);
  }
  setElementWidth() {
    return {
      width: this.onceOffBarWidth,
    };
  }
  selectItem(recipient, index) {
    this.checkedIndex = index;
    this.clickInfoFunction.emit(recipient);
  }

  selectItemEmit(recipient) {
    if (this.isPresentInSelectedList(recipient)) {
      this.clickInfoFunction.emit(recipient);
    } else {
      this.clickInfoFunction.emit(undefined);
    }
  }
  
  private selectCheckboxItems(item) {
    if (this.selected.indexOf(item) !== -1) {
      this.selected.splice(this.selected.indexOf(item), 1);
    } else {
      this.selected.push(item);
    }
    this.getPayButtonText();
  }

  private isPresentInSelectedList(item) {
    return this.selected.indexOf(item) !== -1;
  }
  private selectRadioItems(item) {
    const inut = document.querySelector('input[type="radio"]:checked') as HTMLInputElement;
    this.selected = [];
    if (this.isPresentInSelectedList(item)) {
      inut.checked = false;
    } else {
      this.selected.push(item);
    }
    this.getPayButtonText();
  }

  private getPayButtonText() {
    if (this.selected.length === 0) {
      this.selectedRecipientName = 'Once-off ' + this.listType;
    } else {
      this.selectedRecipientName =
        this.selected.length > 1 ? 'Pay ' + this.selected.length + ' selected' : 'Pay ' + this.selected[0][this.nameInitialKey];
    }
  }
}
