import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../core/transaction-service/transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { SearchFilterPipe } from '../../shared/pipes/search-filter/search-filter.pipe';
import { SortByPipe } from '../../shared/pipes/sort/sort-by.pipe';
import { AccountsIteratorService } from '../../core/accounts-iterator/accounts-iterator.service';

import * as _ from 'lodash';
import { MaskingService } from '../../core/masking-service/masking.service';
import { InstantMoney, Modules, Payments } from '../../core/masking-service/masking-constants';
import { environment } from '../../../environments/environment';
import { DateUtil } from '../../core/utility-classes/date.util';
import { McaHeader } from '../../core/data/mca-header';
import { McaResponseType } from '../../core/data/mca-response-type';
import { Store } from '@ngrx/store';
import { setSelectedTab } from '../../shared/store-utilities/actions/activeTab.action';

@Component({
  selector: 'app-transaction-management',
  templateUrl: './transaction-management.component.html',
  styleUrls: ['./transaction-management.component.scss'],
  providers: [SearchFilterPipe],
})
export class TransactionManagementComponent implements OnInit {
  mobileView;
  selectedTab;
  selectedType;
  payload;
  receiptData;
  fetchFailed;
  isListPage = true;
  receiptDatesArray = [];
  receiptUniqueDatesArray;
  finalReceiptData;
  ifPaginationHide = true;
  receiptDataClone;
  uniqAccountsData = [] as any;
  config = environment.config;
  isBillerCDI = this.config.isBillerCDI;

  receiptList: any = [
    {
      feature: Payments.BENEFICIARY_PAYMENTS,
      name: this.config.receiptNameBene,
      type: 'BANK_BENEFICIARY',
    },
    {
      feature: Payments.CMA_BENEFICIARY_PAYMENTS,
      name: 'CMA Beneficiaries',
      type: 'CMA_BENEFICIARY',
    },
    {
      feature: Payments.BENEFICIARY_PAYMENTS,
      name: this.config.receiptNameAllBene,
      type: 'ONCE_OFF_BANK_BENEFICIARY',
    },
    {
      feature: Payments.CMA_BENEFICIARY_PAYMENTS,
      name: 'Once-off CMA Payment',
      type: 'ONCE_OFF_CMA_BENEFICIARY',
    },
    {
      feature: Payments.ONCE_OFF_COMPANY,
      name: 'Once off Company payments',
      type: 'ONCE_OFF_COMPANY',
    },
    {
      feature: Payments.CROSS_BORDER_PAYMENTS,
      name: 'Cross Border Payments',
      type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
    },
    {
      feature: Payments.ACCOUNT_TRANSFERS,
      name: 'Account transfers',
      type: 'SELF_FUNDS',
    },
    {
      feature: Payments.BILLER_PAYMENTS,
      name: this.isBillerCDI ? 'Company' : 'Billers',
      type: this.isBillerCDI ? 'COMPANY' : 'BILLER',
    },
    {
      feature: Payments.VALUE_ADDED,
      name: this.config.localCurrency === 'LSL' ? 'VAS Payments' : 'Pre / Post paid payments',
      type: 'VAS',
    },
    {
      feature: Payments.TAX_PAYMENT,
      name: 'Tax Payments',
      type: 'TAX_PAYMENT',
    },
    {
      feature: InstantMoney.INSTANT_MONEY,
      name: this.isBillerCDI ? 'Blue Voucher' : this.config.countryName === 'Malawi' ? 'Quickash Voucher' : 'Instant Money Voucher',
      type: 'INSTANT_MONEY',
    },
    {
      feature: Payments.INTERNATIONAL_PAYMENTS,
      name: 'International Payments',
      type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
    },
    {
      feature: Payments.QR_PAYMENTS,
      name: 'GHQR Payments',
      type: 'QR_PAYMENT',
    },
    {
      feature: Payments.GEPG,
      name: 'Biller Payment (GEPG)',
      type: 'GEPG',
    },
    {
      feature: Payments.UNAYO_PAYMENTS,
      name: 'Unayo Payment',
      type: 'UNAYO_PAYMENT',
    },
    {
      feature: Payments.SCHOOLFEES,
      name: 'Fees Payment',
      type: 'SCHOOL_FEE',
    },
  ];
  selectedFeature;
  sortByVal;
  searchText;
  fromDate;
  toDate;
  transactionStatus;
  uniqAccountsDataFiltered;
  accounts;
  subheader = 'Receipts';

  constructor(
    private transactionService: TransactionService,
    private router: Router,
    private searchFilterPipe: SearchFilterPipe,
    private sortByPipe: SortByPipe,
    private accountsIterator: AccountsIteratorService,
    private activatedRoute: ActivatedRoute,
    private dataSharingService: DataSharingService,
    private maskingService: MaskingService,
    private store: Store<any>
  ) {
    window['inner']('other');
  }

  ngOnInit() {
    this.receiptList = this.maskingService.removeMaskedFeatures(this.receiptList, Modules.RECEIPTS);
    this.accounts = this.accountsIterator.getAllAccounts();

    this.store.select('appReducer', 'selectedTabReducer').subscribe((data) => {
      this.selectedTab = data.selectedTab;
      this.selectedFeature = this.receiptList[this.selectedTab];
    });
    this.fetchReciepts(this.selectedTab, this.receiptList[this.selectedTab].type);
  }

  featureValueChanged() {
    this.fetchReciepts(_.findIndex(this.receiptList, this.selectedFeature), this.selectedFeature.type);
  }

  searchFilter(searchValue: any) {
    if (searchValue) {
      this.searchText = searchValue.searchText;
    }
    this.applyFilterFn();
  }

  statementMainFilter(mainFilterValues: any) {
    if (mainFilterValues) {
      this.sortByVal = mainFilterValues.txnDateOrder;
      this.fromDate = new Date(mainFilterValues.fromDate);
      this.toDate = new Date(mainFilterValues.toDate);
      this.transactionStatus = mainFilterValues.transactionStatus;
      this.uniqAccountsDataFiltered = mainFilterValues.uniqAccountsData;
    }

    this.applyFilterFn();
  }

  // Apply final filter
  applyFilterFn() {
    let filteredData;
    const mainData = _.cloneDeep(this.receiptDataClone);
    if (this.searchText) {
      filteredData = this.searchFilterPipe.transform(mainData, this.searchText, 'description');
    } else {
      filteredData = mainData;
    }

    if (this.sortByVal) {
      filteredData = this.sortByPipe.transform(filteredData, this.sortByVal, 'date');
    }

    // apply transaction in out filter
    if (this.transactionStatus && this.transactionStatus !== 'TxnStatusAll') {
      filteredData = this.filterTxnInOut(filteredData);
    }

    // apply date from-to filter
    if (this.fromDate && this.toDate) {
      const start = this.fromDate.getTime();
      const end = this.toDate.getTime();
      if (start && end) {
        filteredData = this.filterDate(filteredData, start, end);
      }
    }

    if (this.uniqAccountsDataFiltered && this.uniqAccountsDataFiltered.length) {
      const filteredUniqAcc: any = [];

      this.uniqAccountsDataFiltered.forEach((accData) => {
        filteredData.forEach((recData) => {
          if (recData.initiatorAccount === accData.accountNumber) {
            filteredUniqAcc.push(recData);
          }
        });
      });
      filteredData = filteredUniqAcc;
    } else if (this.uniqAccountsDataFiltered && !this.uniqAccountsDataFiltered.length) {
      filteredData = [];
    }
    this.alignDataFn(filteredData);
  }

  //  Filter : Transaction : All/In/Out
  filterTxnInOut(accountsData: any) {
    const txnStatusFilteredData = [];
    accountsData.forEach((account) => {
      if (this.transactionStatus === 'TxnStatusSuccess' && (account.status === 'SUCCESS' || account.status === 'ISSUED' || account.voucherStatus === 'ISSUED' || account.voucherStatus === 'PARTIALLY-REDEEMED' || account.voucherStatus === 'REDEEMED')) {
        txnStatusFilteredData.push(account);
      } else if (this.transactionStatus === 'TxnStatusFailed' && account.status !== 'SUCCESS' && account.status !== 'ISSUED') {
        if(this.selectedType === 'INSTANT_MONEY'){
          if(( account.voucherStatus !== 'ISSUED' && account.voucherStatus !== 'PARTIALLY-REDEEMED' && account.voucherStatus !== 'REDEEMED')){
            txnStatusFilteredData.push(account);
          }
        }
        else{
          txnStatusFilteredData.push(account);
        }
      }
    });

    return txnStatusFilteredData;
  }

  // Filter : Date : from-to
  filterDate(recipientsData: any, start: any, end: any) {
    const dateFilteredData = [];
    const recipientsClone = _.cloneDeep(recipientsData);

    recipientsClone.forEach((item) => {
      const requiredDate = item.date;
      const formattedDate = DateUtil.format(requiredDate, DateUtil.MMM_DD_YYYY);
      const d = new Date(formattedDate);
      const checkDate = d.getTime();
      const localOffset = d.getTimezoneOffset() * 60000;
      const checkDateEnd = d.getTime() + localOffset;

      if (checkDate >= start && checkDateEnd <= end) {
        dateFilteredData.push(item);
      }
    });

    return dateFilteredData;
  }

  getAccountnickName(accNo) {
    let accNickName: any;
    accNickName = _.result(
      _.find(this.accounts, (data: any) => {
        return data.number === accNo;
      }),
      'customName'
    );
    if (!accNickName) {
      accNickName = accNo;
    }
    return accNickName;
  }

  fetchReciepts(tab, type) {
    this.finalReceiptData = [];
    this.store.dispatch(setSelectedTab({ selectedTab: tab }));
    this.selectedType = type;
    switch (type) {
      case 'VAS':
        this.payload = {
          pagination: false,
        };
        this.transactionService.getVASReceipt(this.payload).subscribe((response) => {
          this.showReceipts(response);
        });
        break;
      case 'GEPG':
        this.payload = {
          pagination: false,
        };
        this.transactionService.fetchGepgReceipts(this.payload).subscribe((response) => {
          this.showReceipts(response, type);
        });
        break;
      case 'TAX_PAYMENT':
      case 'UNAYO_PAYMENT':
        this.payload = {
          pagination: false,
          transactionType: type,
        };
        this.transactionService.getVASReceipt(this.payload).subscribe((response) => {
          this.showReceipts(response);
        });
        break;
      case 'INSTANT_MONEY':
        this.transactionService.getInstantMoneyReceipt().subscribe((response) => {
          this.showReceipts(response);
        });
        break;
      case 'QR_PAYMENT':
        this.payload = {
          pagination: true,
          transactionType: type,
        };
        this.transactionService.getReceipt(this.payload).subscribe((response) => {
          this.showReceipts(response);
        });
        break;
      case 'SCHOOL_FEE':
        this.payload = {
          pagination: false,
          transactionType: type,
        };
        this.transactionService.getVASReceipt(this.payload).subscribe((response) => {
          this.showReceipts(response);
        });
        break;
      default:
        this.payload = {
          pagination: false,
          transactionType: type,
        };
        this.transactionService.getReceipt(this.payload).subscribe((response) => {
          this.showReceipts(response);
        });
        break;
    }
    // to ensure when switched to  mobile device the dropdown is updated
    this.selectedFeature = this.receiptList[tab];
  }

  showReceipts(data, type?) {
    const resType = data.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);
    if (resType === McaResponseType.SUCCESS) {
      this.receiptData = _.cloneDeep(data.body.receipts || data.body.vasReceipts || data.body.instantMoneyReceipts);
      if (type === 'GEPG') {
        this.receiptData.forEach((element: any) => {
          element.typeName = 'Biller Payment (GEPG)';
          element.description = element.paymentDetails.serviceProvider.serviceProviderName;
        });
      } else {
        this.receiptData.forEach((element: any) => {
          element.typeName = this.typeNameTxrFn(element.type);
        });
      }
      this.receiptDataClone = _.cloneDeep(this.receiptData);
      this.getUniqueAccounts(this.receiptDataClone);
      this.setInitiatorAccountName(this.receiptDataClone);
      this.alignDataFn(this.receiptDataClone);
    } else {
      this.fetchFailed = true;
    }
  }

  typeNameTxrFn(type) {
    const splitedName = type.split('_');
    const typeName = splitedName.join(' ');
    return typeName.toLowerCase();
  }

  getUniqueAccounts(data: any) {
    this.uniqAccountsData = [];
    const uniqueAccounts = _.uniqBy(data, 'initiatorAccount');

    _.forEach(uniqueAccounts, (account: any) => {
      const nickName = this.getAccountnickName(account.initiatorAccount);
      const accountData = {
        accountNumber: account.initiatorAccount,
        name: nickName || account.initiatorAccount,
        checked: true,
      };
      this.uniqAccountsData.push(accountData);
    });
  }

  setInitiatorAccountName(data: any) {
    _.forEach(data, (receipt: any) => {
      if (this.config.transactionManagement.showAccountName) {
        const account = this.uniqAccountsData.find((it) => receipt.initiatorAccount === it.accountNumber);
        receipt.initiatorAccountName = account.name;
      } else {
        receipt.initiatorAccountName = receipt.initiatorAccount;
      }
    });
  }

  alignDataFn(data) {
    // all Dates array
    let receiptDatesArrayTemp = [];
    this.receiptDatesArray = [];
    data.map((s) => receiptDatesArrayTemp.push(s.date));

    if (this.sortByVal) {
      receiptDatesArrayTemp = this.sortByPipe.transform(receiptDatesArrayTemp, this.sortByVal, 'date');
    }

    receiptDatesArrayTemp.forEach((element) => {
      this.receiptDatesArray.push(DateUtil.format(element, DateUtil.DD_MMMM_YYYY));
    });

    this.receiptUniqueDatesArray = _.uniq(this.receiptDatesArray);
    // Uniq dates from array
    /* this.receiptUniqueDatesArray = this.receiptDatesArray
      .map((s) => s.toString())
      .filter((s, i, a) => a.indexOf(s) === i)
      .map((s) => new Date(s))
      .map((s) => moment(s).format('DD MMMM YYYY')); */

    const sortedReceiptDataObject = [];

    // Loop through uniq dates
    for (let i = 0; i <= this.receiptUniqueDatesArray.length - 1; i++) {
      const dateObject = {
        transactionDate: this.receiptUniqueDatesArray[i],
        transactionDetails: [],
      };
      // Loop through aal data
      for (let j = 0; j <= data.length - 1; j++) {
        if (this.receiptUniqueDatesArray[i] === DateUtil.format(data[j].date, DateUtil.DD_MMMM_YYYY)) {
          const receiptObject: any = {
            amount: data[j].amount,
            description: data[j].description,
            initiatorAccount: data[j].initiatorAccount,
            initiatorAccountName: data[j].initiatorAccountName,
            referenceId: data[j].referenceId,
            status: data[j].status,
            transactionId: data[j].transactionId,
            type: data[j].type,
            typeName: data[j].typeName,
            date: data[j].date,
          };

          if (data[j].recordSerialNumber) {
            receiptObject.recordSerialNumber = data[j].recordSerialNumber;
          }

          if (data[j].type === 'UNAYO_PAYMENT') {
            receiptObject.description = data[j].rechargeNumber;
            receiptObject.recipientReference = data[j].recipientReference;
            receiptObject.customerReference = data[j].customerReference;
            receiptObject.rechargeNumber = data[j].rechargeNumber;
          }
          if (data[j].type === 'SCHOOL_FEE') {
            receiptObject.description = data[j].institution.name;
            receiptObject.customerName = data[j].customerName;
            receiptObject.institutionName = data[j].institution.name;
            receiptObject.rechargeNumber = data[j].rechargeNumber;
          }
          if (data[j].type === 'TAX_PAYMENT') {
            receiptObject.description = data[j].basePrepaidProvider.friendlyName;
            receiptObject.customerName = data[j].customerName;
            receiptObject.taxCode = data[j].taxCode;
            receiptObject.region = data[j].region;
            receiptObject.taxOfficeCode = data[j].taxOfficeCode;
            receiptObject.transactionId = data[j].transactionId;
            receiptObject.customerNumber = data[j].customerNumber;
          }

          if (data[j].basePrepaidProvider) {
            receiptObject.basePrepaidProvider = data[j].basePrepaidProvider;
            receiptObject.date = data[j].date;
            receiptObject.recipientNumber = data[j].rechargeNumber;
          }

          if (data[j].paymentDetails) {
            receiptObject.providerName = data[j].paymentDetails.serviceProvider.serviceProviderName;
            receiptObject.controlNumber = data[j].paymentDetails.controlNumber;
          }

          if (data[j].type === 'INSTANT_MONEY') {
            receiptObject.mobileNumber = data[j].mobileNumber;
            receiptObject.date = data[j].date;
            receiptObject.expiryDate = data[j].expiryDate;
            receiptObject.voucherNumber = data[j].voucherNumber;
            receiptObject.voucherStatus = data[j].voucherStatus;
          }

          if (this.isBillerCDI && data[j].type === 'ACCOUNT_TO_MOBILE_WALLET') {
            receiptObject.typeName = 'Account To PayPulse';
          }
          dateObject.transactionDetails.push(receiptObject);
        }
      }
      sortedReceiptDataObject.push(dateObject);
    }
    this.finalReceiptData = _.cloneDeep(sortedReceiptDataObject);
    this.finalReceiptData = sortedReceiptDataObject.filter((s) => s.transactionDetails && s.transactionDetails.length !== 0).sort(this.dateSorter);
    // console.log("finalReceiptData:", this.finalReceiptData);
  }

  dateSorter(i, j) {
    if (DateUtil.isBefore(i.transactionDate, j.transactionDate)) {
      return 1;
    } else {
      return -1;
    }
  }

  fetchIndividualReciepts(individualReceiptData) {
    if (
      this.selectedType === 'UNAYO_PAYMENT' ||
      this.selectedType === 'VAS' ||
      this.selectedType === 'TAX_PAYMENT' ||
      this.selectedType === 'INSTANT_MONEY' ||
      this.selectedType === 'QR_PAYMENT' ||
      this.selectedType === 'GEPG' ||
      this.selectedType === 'SCHOOL_FEE'
    ) {
      individualReceiptData.selectedType = this.selectedType;
      this.navigateToReceipt(individualReceiptData);
    } else {
      this.payload = {
        referenceId: individualReceiptData.referenceId,
        transactionId: individualReceiptData.transactionId,
        transactionType: individualReceiptData.type,
      };

      if (individualReceiptData.recordSerialNumber) {
        this.payload.recordSerialNumber = individualReceiptData.recordSerialNumber;
      }

      this.transactionService.getIndividualReceiptDetails(this.payload).subscribe((response) => {
        this.navigateToReceipt(response);
      });
    }
  }

  navigateToReceipt(data) {
    this.dataSharingService.setIndividualReceiptData(data.body ? data.body.receiptDetails : data);
    this.router.navigate(['./individual-receipt'], {
      relativeTo: this.activatedRoute,
    });
  }
}
