import { Component, Input, OnChanges, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { delay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Event, NavigationEnd, Router } from '@angular/router';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { SignOutService } from '../../../core/sign-out/sign-out.service';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../../cms/footer-navigation/footer-navigation.component';
import * as _ from 'lodash';
import { DeviceDetectorService } from '../../../services/device-detector/device-detector.service';
import { Modules, SideNav } from '../../../core/masking-service/masking-constants';
import { MaskingService } from '../../../core/masking-service/masking.service';
import { environment } from '../../../../environments/environment';
import { setSelectedTab } from '../../../shared/store-utilities/actions/activeTab.action';

@Component({
  selector: 'sbg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [SignOutService, MatSidenav],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnChanges {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @Input() accountHolder: any;
  @Input() accountnumber: any;
  @Input() headerName: any;
  config = environment.config;
  
  title;
  // innerWidth;
  readOnlyMode: boolean;
  hideHeaderForPopupScreen = false;
  exitSurveyOptOut;
  isSmeMode: boolean;
  isSmall: boolean;
  headerTitlePages = [];

  headerTitle = {
    status: false,
    header: '',
    url: '',
    nav: '',
    subHeader: '',
  };

  maskOpenAcc: boolean;

  sideNavLinks: any = [
    {
      feature: SideNav.RECEIPTS,
      name: 'Receipts',
      classes: '',
      onClickNavFunction: this.goToReceipts.bind(this),
    },
    {
      feature: SideNav.RECIPIENTS,
      name: 'Recipients',
      classes: '',
      onClickNavFunction: this.goToRecipient.bind(this),
    },
    {
      feature: SideNav.DEBIT_CARDS,
      name: 'Debit Cards',
      classes: '',
      onClickNavFunction: this.debitCardsNav.bind(this),
    },
    {
      feature: SideNav.SERVICE_REQUESTS,
      name: 'Service Requests',
      classes: '',
      onClickNavFunction: this.goToServiceRequests.bind(this),
    },
    {
      feature: SideNav.PROFILE_AND_SETTINGS,
      name: 'Profile & Settings',
      classes: '',
      onClickNavFunction: this.goToProfile_Settings.bind(this),
    },
    {
      feature: SideNav.SCHEDULED_PAYMENTS,
      name: 'Scheduled Payments',
      classes: '',
      onClickNavFunction: this.goToScheduteTxn.bind(this),
    },
    {
      feature: SideNav.APPROVAL_LIST,
      name: 'Approval List',
      classes: '',
      onClickNavFunction: this.goToApprovalList.bind(this),
    },
    {
      feature: SideNav.GIVE_FEEDBACK,
      name: 'Give Feedback',
      classes: '',
      onClickNavFunction: () => this.handleSignout('Feedback'),
    },
    {
      feature: SideNav.OPEN_BANK_ACCOUNT,
      name: 'Open a Bank Account',
      classes: ['mt-16'],
      onClickNavFunction: this.goToOpenBankAccount.bind(this),
    },
  ];
  constructor(
    private signOut: SignOutService,
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private router: Router,
    private maskingService: MaskingService,
    // private DeviceDetector: DeviceDetectorService,
    private AppConstant: AppConstantService,
    private dialog: MatDialog,
    private deviceDetectorService: DeviceDetectorService
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.checkHeaderStyle();
      }
    });
  }
 

  ngOnInit() {
    this.sideNavLinks = this.maskingService.removeMaskedFeatures(this.sideNavLinks, Modules.SIDE_NAV);

    this.headerTitlePages = [
      {
        header: this.accountHolder,
        subHeader: this.accountnumber,
        url: '/accountDetails',
        nav: '/dashboard',
      },
      {
        header: 'Receipts',
        url: '/transaction-management',
        nav: '/dashboard',
      },
      {
        header: 'Schedule transactions',
        url: '/scheduleTransaction',
        nav: '/dashboard',
      },
      {
        header: 'Profile and Settings',
        url: '/profileAndSettings',
        nav: '/dashboard',
      },
    ];
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      if (value.authenticateResponse) {
        this.exitSurveyOptOut = value.authenticateResponse.userProfile.isExitSurveyOptingOut;
      }
      this.isSmeMode = value.isSmeMode;
    });
    // this.onResize({});
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.isSmall = val === 'small';
      this.checkHeaderStyle();
    });

    this.dataSharingService.getMaskFinancialFlows().subscribe((data) => {
      this.maskOpenAcc = data;
    });
  }

  ngOnChanges(change: any) {
    if (change.accountnumber || change.accountHolder || change.headerName) {
      this.accountHolder = this.headerName ? this.headerName : this.accountHolder;
      const headerObj = {
        header: this.accountHolder,
        subHeader: this.accountnumber,
        url: '/accountDetails',
        nav: '/dashboard',
      };
      
      const routerBreakdown = this.router.url.split('/');
      const index = _.findIndex(this.headerTitlePages, { url: '/' + routerBreakdown[1] });

      if (index >= 0) {
        const updatedAccountsArray = this.headerTitlePages.slice(0);
        updatedAccountsArray[index] = headerObj;
        this.headerTitlePages = _.cloneDeep(updatedAccountsArray);
        if (this.isSmall) {
          this.headerTitle = { ...this.headerTitlePages[index], ...{ status: true } };
        }
      }
    }
  }

  handleSignout(param) {
    this.dataSharingService.setErrorMessage(null);
    if (param === 'logout') {
      if (!this.config.showExitSurvey) {
        this.signOut.signout();
        this.store.dispatch({ type: 'USER_LOGOUT' });
      } else {       
          this.openDialog('Online Banking Feedback Survey');
      }
      this.dataSharingService.setlogOutMsg(true);
    } else {
      this.dataSharingService.setUserRequestedFeedback(true);
      this.openDialog('Online Banking Feedback Survey');
    }
  }

  open() {
    this.sidenav.open();
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.sidenav.close();
    document.body.style.overflow = 'unset';
  }

  goToRecipient(): void {
    this.router.navigate(['/recipients-management/beneficiary/list-beneficiary']);
    document.body.style.overflow = 'unset';
  }
  goToReceipts(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate(['/transaction-management']);

    document.body.style.overflow = 'unset';
  }

  goToServiceRequests(): void {
    this.router.navigate(['/serviceRequests']);
    document.body.style.overflow = 'unset';
  }

  goToProfile_Settings(): void {
    this.router.navigate(['/profileAndSettings']);
    document.body.style.overflow = 'unset';
  }

  goToScheduteTxn(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate(['/scheduleTransaction']);
    document.body.style.overflow = 'unset';
  }

  goToOpenBankAccount(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate(['/open-account']);
    document.body.style.overflow = 'unset';
  }
  goToApprovalList(): void {
    this.router.navigate(['/approvals']);
    document.body.style.overflow = 'unset';
  }
  goBack() {
    this.dataSharingService.setHeaderTitle('');
    this.router.navigate(['/dashboard']);
  }

  dashboardNav() {
    this.router.navigate(['/dashboard']);
    this.sidenav.close();
    document.body.style.overflow = 'unset';
  }

  checkHeaderStyle() {
    this.hideHeaderForPopupScreen = this.AppConstant.NonPopUpInnerPages.indexOf(this.router.url) === -1;
    const routerBreakdown = this.router.url.split('/');
    const index = _.findIndex(this.headerTitlePages, { url: '/' + routerBreakdown[1] });
    if (index !== -1 && this.isSmall) {
      this.headerTitle = { ...this.headerTitlePages[index], ...{ status: true } };
    } else {
      this.headerTitle = {
        status: false,
        header: '',
        url: '',
        nav: '',
        subHeader: '',
      };
    }
  }

  debitCardsNav() {
    this.router.navigate(['/menu/debit']);
    document.body.style.overflow = 'unset';
  }

  openDialog(type: string): void {
    this.dataSharingService.dialogType = type;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }
}
