import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { Store } from '@ngrx/store';
import { setSelectedTab } from '../../../../shared/store-utilities/actions/payment.action';

@Component({
  selector: 'app-tax-payment-details',
  templateUrl: './namibia-tax-payment-details.component.html',
  styleUrls: ['./namibia-tax-payment-details.component.scss'],
})
export class NamibiaTaxPaymentDetailsComponent implements OnInit {
  config = environment.config;
  readonly allTaxTypes = this.config.payments.namibiaTaxPayment.taxTypes;
  nraPaymentsAvailable = this.config.payments.namibiaTaxPayment.NRAavailable;
  nraInitialData = this.config.payments.namibiaTaxPayment.NRAinitialData;
  readonly namibType = 'Namibia Revenue Agency';

  taxModel;
  exchangeRates;
  amountValidator = {};
  tinNumber;

  availableLimit: any;
  withdrawalLimit: any;
  usedLimit: any;
  isSmeMode = false;
  isBillerCDI = this.config.isBillerCDI;
  // country constants
  private showMonthlyTransactionLimit = this.config.showMonthlyTransactionLimit;
  private weights = [23, 19, 17, 13, 7, 5, 3];
  private tinLength = 8;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private accountService: AccountService,
    private store: Store<{ appReducer }>,
    private uuidGenerator: UuidGeneratorService,
  ) {
    dataSharingService.setHeadPagination({
      mainTitle: 'Tax Payment',
      subTitle: 'Details',
      showBack: false, 
      showClose: true,
      steps: true,
      module: 'taxPaymentCDI',
    });
    this.store
        .select('appReducer', 'loginReducer')
        .subscribe((data) => {
          if (data) {
            this.isSmeMode = data.isSmeMode;
          }
        });
  }

  ngOnInit() {
    this.taxModel = this.dataSharingService.dataFromFirstStep || {};
    this.taxModel.fromAccounts = this.accountsIterator.getPaymentFromAccounts('local');
    if (!this.taxModel.selectedFromAccount) {
      this.taxModel.selectedFromAccount = this.taxModel.fromAccounts[0];
    }
    this.setViewValues();
    this.taxModel.choosenBank = null;
    this.getAmountWithdrawalLimit();
  }

  setViewValues() {
    if (this.nraPaymentsAvailable) {
      if (this.taxModel.selectedBiller) {
        return;
      }
      this.taxModel.selectedBiller = this.nraInitialData;
      this.taxModel.regOfficeCodes = this.config.payments.namibiaTaxPayment.IRDRegOfficeCode;
      this.taxModel.accountTypes = this.config.payments.namibiaTaxPayment.accountType;
      this.taxModel.months = this.config.payments.namibiaTaxPayment.MonthArray;
      this.taxModel.date = new Date();
      this.generateYears();
    }
  }

  fromAccountChange(fromAccount): void {
    this.taxModel.selectedFromAccount = fromAccount;
    this.taxModel.amount = null;
    this.amountValidator = {};
  }

  bankAccountChange() {
    this.taxModel.taxTypes = this.allTaxTypes.filter((it) => {
      return this.taxModel.selectedBankAccount.name === it.category;
    });
    switch (this.taxModel.selectedBankAccount.name) {
      case 'Main':
        this.taxModel.selectedBiller.name = 'Namibia Revenue Agency';
        break;
      case 'VAT':
        this.taxModel.selectedBiller.name = 'Namibia Revenue Agency VAT';
        break;
      default:
        this.taxModel.taxTypes = this.allTaxTypes;
        this.taxModel.selectedBiller.name = 'Namibia Revenue Agency AMNESTY';
    }
    this.resetPeriodSelected();
    this.taxModel.selectedPaymentType = null;
    this.taxModel.selectedTaxType = null;
  }

  taxTypeChanged() {
    const selectedTaxType = this.taxModel.selectedTaxType;
    if (selectedTaxType.taxPeriodPay && selectedTaxType.taxAccountPay) {
      this.taxModel.allPayTypes = this.config.payments.namibiaTaxPayment.PaymentTypes;
    } else if (!selectedTaxType.taxPeriodPay && selectedTaxType.taxAccountPay) {
      this.taxModel.showPeriod = false;
      this.taxModel.allPayTypes = [{ name: 'Tax Account Payment' }];
      this.taxModel.selectedPaymentType = this.taxModel.allPayTypes[0];
      this.paymentTypeChanged();
    }
  }

  tinChanged() {
    this.taxModel.invalidTin = false;
  }

  paymentTypeChanged() {
    if (this.taxModel.selectedPaymentType.name === 'Tax Period Payment') {
      this.resetPeriodSelected();
      this.taxModel.months = this.config.payments.namibiaTaxPayment.incomeTaxPeriodValues;
      if (this.taxModel.selectedTaxType.period === 'select') {
        this.taxModel.months = this.config.payments.namibiaTaxPayment.incomeTaxPeriodValues;
      } else {
        this.taxModel.months = this.config.payments.namibiaTaxPayment.MonthArray;
      }
    } else {
      this.resetPeriodSelected();
    }
  }

  resetPeriodSelected() {
    this.taxModel.selectedYear = 9999;
    this.taxModel.selectedMonth = {
      value: '99',
    };
  }

  isNamibType(): boolean {
    return this.taxModel.selectedBiller.type === this.namibType;
  }

  isShowPeriod(): boolean {
    return this.taxModel.selectedPaymentType && this.taxModel.selectedPaymentType.name === 'Tax Period Payment';
  }

  isTaxTypeAll(): boolean {
    return this.taxModel.selectedPaymentType && this.taxModel.selectedPaymentType.name === 'All';
  }

  getTaxRefNumber(): string {
    return (
      `1${this.taxModel.selectedRegOfficeCode.code}${this.taxModel.tin}` +
      `${this.taxModel.selectedTaxType.value}${this.taxModel.selectedYear}` +
      `${this.taxModel.selectedMonth.value}`
    );
  }

  goBackToPayments(): void {
    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
    this.router.navigate([this.isSmeMode ?'../list' :'/menu/payments'], { relativeTo: this.activatedRoute });
  }

  goToBillerConfirmation(): void {
    if (this.verifyTIN()) {
      this.taxModel.trn = this.getTaxRefNumber();
      this.setTransationPayloadInfoToModel();
      this.dataSharingService.dataFromFirstStep = this.taxModel;
      this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
    }
  }

  private setTransationPayloadInfoToModel() {
    const payload = {
      account: this.taxModel.selectedFromAccount,
      transactions: {
        onceOffCdiPayments: [
          {
            recipient: {
              customerReference: this.taxModel.myReference,
              keyValueMetadata: [],
              name: this.taxModel.selectedBiller.name.toUpperCase(),
              recipientId: this.taxModel.selectedBiller.recipientId,
              recipientReference: this.taxModel.trn,
            },
            futureDatedInstruction: {
              repeatInterval: 'SINGLE',
              repeatNumber: 1,
              fromDate: this.taxModel.date,
            },
            transactionId: this.uuidGenerator.getUUID(),
            amount: {
              currency: this.taxModel.selectedFromAccount.availableBalance.currency,
              amount: this.taxModel.amount.toString(),
            },
          },
        ],
      },
    };

    this.taxModel.payload = payload;
  }

  private generateYears() {
    const currentYear = new Date().getFullYear();
    this.taxModel.taxYears = [];
    for (let i = currentYear + 1; i >= 1970; i--) {
      this.taxModel.taxYears.push(i);
    }
  }

  private verifyTIN(): boolean {
    const tin: number = this.taxModel.tin;
    if (tin && tin.toString().length === 8) {
      if (this.tinValidation(tin)) {
        this.taxModel.invalidTin = false;
        return true;
      } else {
        this.taxModel.invalidTin = true;
        return false;
      }
    } else if (tin && tin.toString().length === 7) {
      this.taxModel.tin = '0' + this.taxModel.tin;
      return this.verifyTIN();
    } else {
      this.taxModel.invalidTin = true;
      return false;
    }
  }

  private tinValidation(tin: number): boolean {
    const numArr = [];
    for (let i = 0; i < this.tinLength; i++) {
      numArr.unshift(tin % 10);
      tin = Math.floor(tin / 10);
    }
    const remain = numArr[this.tinLength - 1];
    numArr.pop();
    let sum = 0;
    for (let j = 0; j < this.tinLength - 1; j++) {
      sum = sum + numArr[j] * this.weights[j];
    }
    return Math.floor(sum % 11) === remain;
  }

  private getAmountWithdrawalLimit(): void {
    this.withdrawalLimit = this.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyWithdrawalLimit
      : this.accountService.cardProfile.dailyWithdrawalLimit;
    this.usedLimit = this.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyUsedLimit
      : this.accountService.cardProfile.usedEAPLimit;
    if (this.withdrawalLimit && this.usedLimit) {
      this.availableLimit = this.withdrawalLimit.amount - this.usedLimit.amount;
      this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
    }
  }
}
