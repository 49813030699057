import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { SecurityQuestion } from '../../../services/RegistrationService/data/security-question';
import { ResetPasswordRequest } from '../../../services/RegistrationService/data/reset-password-request';
import { RegistrationState } from '../../../shared/store-utilities/state/registration.state';
import { take } from 'rxjs/operators';
import { ChangePassword } from '../../../shared/components/change-password/data/change-password';
import { FooterNavigationComponent } from '../../../cms/footer-navigation/footer-navigation.component';
import { environment } from '../../../../environments/environment';
import { McaHeader } from '../../../core/data/mca-header';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  userName: string;
  oldPassword: string;
  passwordsMismatch = false;
  newPassword: string;
  confirmPassword: string;
  accountNumber: string;
  securityQuestions: SecurityQuestion[];
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;

  constructor(
    private dataSharing: DataSharingService,
    private dialog: MatDialog,
    private router: Router,
    private registration: RegistrationService,
    private store: Store<{ appReducer: { registrationReducer: RegistrationState } }>
  ) {}

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Forgot Password',
      subTitle: 'Reset Password',
      showBack: true,
      showClose: true,
      steps: true,
      module: 'forgotPassword',
    });

    this.store
      .select((state) => state.appReducer.registrationReducer)
      .pipe(take(1))
      .subscribe((value) => {
        if (value.userNameRegistered !== '') {
          this.userName = value.userNameRegistered;
        }
        if (value.accountNumberEntered !== '') {
          this.accountNumber = value.accountNumberEntered;
        }
        this.securityQuestions = value.securityQuestionAnswers;
      });
  }

  completeResetPassword(changePassword: ChangePassword) {
    if (changePassword.newPassword === changePassword.confirmPassword) {
      const payload = this.createPayloadForResetPassword(changePassword);
      this.postService(payload);
      this.dataSharing.setPostService(this.postService.bind(this));
    } else {
      this.passwordsMismatch = true;
    }
  }

 

  createPayloadForResetPassword(changePassword: ChangePassword): ResetPasswordRequest {
    const payload: ResetPasswordRequest = {
      userId: this.userName,
      accountNumber: this.accountNumber,
      newPassword: btoa(changePassword.newPassword),
      confirmPassword: btoa(changePassword.confirmPassword),
      securityQuestions: this.securityQuestions,
    };
    return payload;
  }

  openDialog(type: string, condition: string): void {
    this.dataSharing.dialogType = type;
    this.dataSharing.modelDetails = condition;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }

  private postService(payload: ResetPasswordRequest) {
    this.registration.resetPassword(payload).subscribe((response) => {
      this.dataSharing.setChangePasswordMessage(response.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE));
      this.router.navigate(['./login']);
    });
  }
}
