import { createAction, props } from '@ngrx/store';
import { UnayoFormData, UnayoReceiptData, SelectedReceipientsData } from '../interfaces/unayo.model';

export const setSelectedReceipientsData = createAction(
  '[pay bene Component] setSelectedReceipientsData',
  props<{ selectedReceipientsData: SelectedReceipientsData }>()
);

export const setUnayoFormData = createAction(
  '[SelfAccountTransfer Component] setUnayoFormData',
  props<{ unayoFormData: UnayoFormData }>()
);

export const setUnayoReceiptData = createAction(
  '[SelfAccountTransfer Component] setUnayoReceiptData',
  props<{ unayoReceiptData: UnayoReceiptData }>()
);
