import { environment } from '../../../environments/environment';
import { InputValidation } from '../../constants/InputValidation/inputValidation.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class DragAndDropService {
  instantMoneyVoucherListData;
  fileMetaData: any = {};
  totalTransferAmount = 0;
  envConfig = environment.config;
  protoObj: any;
  ruleForBulkInstantMoney;
  constructor(private store: Store<{ appReducer }>) {
    this.store
      .select('appReducer', 'instantMoenyReducer')
      .subscribe((stateData) => {
        this.instantMoneyVoucherListData =
          stateData.instantMoneyVoucherListData;
      });
  }

  resetTotalTransferAmount() {
    this.totalTransferAmount = 0;
  }
  checkForAmountValid(val, validator) {
    const isValid =
      parseFloat(val) <= parseFloat(validator.maxAmount) &&
      parseFloat(val) >= parseFloat(validator.minAmount) &&
      parseFloat(val) % parseFloat(validator.denomination) === 0;
    return isValid;
  }

  handleUpload(files) {
    const ourFile = files[0];
    const errorInFile = [];
    const fileMeta = {
      name: ourFile.name,
      size: ourFile.size,
      type: ourFile.type,
    };

    this.fileMetaData = fileMeta;
    const splitFileName = this.fileMetaData.name.split('.');
    const splitFileNameSize = splitFileName.length;
    const extension = splitFileName[splitFileNameSize - 1];
    const specialChars = InputValidation.fileName;
    const checkSpecialChars = this.fileMetaData.name.match(specialChars);
    if (extension !== 'csv' && extension !== 'txt') {
      errorInFile.push('Invalid file type');
      return this.returnError(errorInFile);
    }

    if (this.fileMetaData.name.length > 35) {
      errorInFile.push(
        'File name should not be longer than 35 characters. Current length : ' +
          this.fileMetaData.name.length
      );
    }
    if (this.fileMetaData.size > 1048576) {
      errorInFile.push(
        'File size should not be greater than 1,048,576 bytes. Current size : ' +
          this.fileMetaData.size
      );
    }
    if (checkSpecialChars !== null) {
      errorInFile.push('File name should not contain any special characters.');
    }

    if (errorInFile.length > 0) {
      return this.returnError(errorInFile);
    }

    return fileMeta;
  }
  parseCSV(binary) {
    const errorInFile = [];
    // number of rows
    this.resetTotalTransferAmount();
    const collection = binary.split('\n');
    let entries = 0;
    const collectionLength = collection.length;
    if (collection[collectionLength - 1]) {
      entries = collectionLength;
    } else {
      entries = collectionLength - 1;
    }
    this.fileMetaData.rows = entries;
    if (entries <= 1 && (!collection[0] || collection[0].trim() === '')) {
      errorInFile.push('File should contain atleast one record');
      return this.returnError(errorInFile);
    }
    if (entries > 2000) {
      errorInFile.push('File should contain maximum of 2000 records');
      return this.returnError(errorInFile);
    }
    // deliminator
    const firstRow = collection[0];
    const reg = /(,)/;
    const deliminator = firstRow.match(reg);
    // number of deliminators should be equal per row
    if (deliminator === null) {
      errorInFile.push('File should not contain any headers');
      return this.returnError(errorInFile);
    }
    // columns
    const columns = this.envConfig.bulkFileLength;
    this.fileMetaData.columns = columns;
    const y = entries;
    const z = columns;
    let columnAmount = 0;
    for (let x = 0; x < y; x++) {
      // columns should be equal
      const columnEntries = collection[x].split(',');
      if (columnEntries.length > 1) {
        columnAmount = parseFloat(columnEntries[1]);
        if (columnAmount % 1 !== 0 && this.envConfig.decimalAllowedInBulkFile) {
          errorInFile.push(
            'Line number ' +
              (x + 1) +
              ':Please remove any decimal points from the amount(s) entered'
          );
        } else {
          if (columnAmount && columnEntries[1]) {
            this.totalTransferAmount += parseFloat(columnEntries[1]);
          } else if (columnEntries[1]) {
            errorInFile.push('Line number ' + (x + 1) + ': amount invalid');
          } else {
            // do nothing
          }
        }
        const columnsInRow = columnEntries.length;
        let isMissingRecord = false;
        if (columnsInRow !== z) {
          errorInFile.push(
            'Line number ' + (x + 1) + ': Some fields are missing'
          );
          isMissingRecord = true;
        }
        if (!isMissingRecord) {
          if (columnEntries[0].charAt(0) !== '#') {
            errorInFile.push(
              'Line number ' + (x + 1) + ': Account number should start with #'
            );
          }
          if (columnEntries[5].charAt(0) !== '#') {
            errorInFile.push(
              'Line number ' +
                (x + 1) +
                ': Bank sort code number should start with #'
            );
          }
          if (
            columnEntries[6] &&
            columnEntries[6].charAt(0) !== '#' &&
            this.envConfig.payments.bulkFileUpload.validateEightFieldsBulkFile
          ) {
            errorInFile.push(
              'Line number ' +
                (x + 1) +
                ': Bank reference Number should start with #'
            );
          }
          for (let i = 0; i < columnEntries.length; i++) {
            if (columnEntries[i] === '') {
              errorInFile.push(
                'Line number ' + (x + 1) + ': Some fields are missing'
              );
            }
          }
        }
      } else {
        if (x !== y - 1) {
          errorInFile.push('Line number ' + (x + 1) + ': empty record');
        }
      }
    }

    if (errorInFile.length > 0) {
      // Filter array for unique errors

      const returnErrorInFile = errorInFile.filter((item, pos) => {
        return errorInFile.indexOf(item) === pos;
      });
      return this.returnError(returnErrorInFile);
    }

    return this.fileMetaData.rows;
  }
  sendBulkMoneyFile(binaryString) {
    this.resetTotalTransferAmount();
    const localCode = this.envConfig.mobileCountryCode.slice(
      1,
      this.envConfig.mobileCountryCode.length
    );
    // let ruleForSbm = this.ruleForBulkInstantMoney;
    const columns = 2;
    const errorInFile = [];
    const rows = binaryString.split('\n');

    if (!rows[0].match(/(,)/)) {
      errorInFile.push('File should not contain any headers');
      return this.returnError(errorInFile);
    }
    if (rows.length > 101) {
      errorInFile.push('File should contain maximum of 100 records');
      return this.returnError(errorInFile);
    }
    if (rows.length <= 1 && rows[0].trim() === '') {
      errorInFile.push('File should contain atleast one record');
      return this.returnError(errorInFile);
    }
    const fileContent = [];
    // this adjustment for csv format correction
    const actualRows = rows[rows.length - 1] ? rows.length : rows.length - 1;
    for (let i = 0; i < actualRows; i++) {
      const protoObj = {
        mobileNumber: null,
        amount: { amount: null, currency: null },
      };
      const columnItems = rows[i].split(',');
      if (columnItems.length === columns) {
        const mobNumber = columnItems[0].replace(/\s/g, '');
        if (mobNumber.match(/^[0-9]*$/)) {
          if (mobNumber.charAt(0) === '0') {
            errorInFile.push(
              'Line number ' + (i + 1) + ': mobile number cannot start with 0'
            );
          } else if (
            mobNumber.length !==
            this.envConfig.payments.bulkFileUpload.mobileNumberLengthAtBulkFile
          ) {
            errorInFile.push(
              'Line number ' + (i + 1) + ': mobile number invalid '
            );
          } else {
            protoObj.mobileNumber = localCode + mobNumber;
          }
        } else {
          errorInFile.push(
            'Line number ' + (i + 1) + ': mobile number invalid '
          );
        }
        if (!isNaN(Number(columnItems[1]))) {
          const amountValid = this.checkForAmountValid(
            Number(columnItems[1]),
            this.instantMoneyVoucherListData
          );
          const ruleForSbm = this.instantMoneyVoucherListData;
          if (!amountValid) {
            errorInFile.push(
              'Line number ' +
                (i + 1) +
                ':Please enter amount in the range of ' +
                ruleForSbm.minAmount +
                ' & ' +
                ruleForSbm.maxAmount +
                ' & in multiple of ' +
                ruleForSbm.denomination
            );
          } else {
            this.totalTransferAmount += parseFloat(columnItems[1]);
            protoObj.amount.amount = Number(columnItems[1]);
            protoObj.amount.currency = this.envConfig.localCurrency;
          }
        } else {
          errorInFile.push('Line number ' + (i + 1) + ': amount invalid');
        }
        fileContent.push(protoObj);
        // fileContent.push(this.protoObj);
      } else if (columnItems.length > 1 && columnItems.length < columns) {
        errorInFile.push(
          'Line number ' + (i + 1) + ': Some fields are missing'
        );
      } else if (columnItems.length > columns) {
        errorInFile.push(
          'Line number ' + (i + 1) + ': Some unnecessary fields are present'
        );
      } else {
        errorInFile.push('Line number ' + (i + 1) + ':empty record');
      }
    }
    if (errorInFile.length > 0) {
      return this.returnError(errorInFile);
    } else {
      return fileContent;
    }
  }
  returnError(error) {
    return {
      error,
    };
  }
  getTotalTransferAmount() {
    return this.totalTransferAmount.toFixed(2);
  }
  showMetaData() {
    return {
      show: () => {
        //  code for snackbar
        // Notifications.toast( passMsg );
        // return fileMetaData.rows;
      },
    };
  }
}
