import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { AccountService } from '../../../core/account-service/account-service.service';
import { MatInput } from '@angular/material/input';
import { Store } from '@ngrx/store';
import * as dashboardActions from '../../../shared/store-utilities/actions/dashboard.action';

import * as _ from 'lodash';
import { Router } from '@angular/router';
import { environment } from '@environment';

@Component({
  selector: 'app-account-summary-details',
  templateUrl: './account-summary-details.component.html',
  styleUrls: ['./account-summary-details.component.scss'],
})
export class AccountSummaryDetailsComponent implements OnInit {
  @Input() detailSeparatorTitle: any;
  @Input() nextDetailsType: any;
  @Input() detailsShowList: any;
  @Input() detailsShowListNext: any;
  @Input() selectedAccData: any;
  @Output() toggleAccountSecondaryNav: EventEmitter<any> = new EventEmitter();
  @Output() updateHeader: EventEmitter<any> = new EventEmitter();
  @ViewChild('firstname') nameInput: MatInput;
  dashboardData;
  accountsList;
  config = environment.config;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private store: Store<any>
  ) {
    this.store
      .select('appReducer', 'dashboardReducer')
      .subscribe((stateData) => {
        this.dashboardData = _.cloneDeep(stateData.accounts);
        if (
          stateData.accounts &&
          stateData.accounts.body &&
          stateData.accounts.body.accounts.length
        ) {
          this.accountsList = _.cloneDeep(stateData.accounts.body.accounts);
        }
      });
  }

  ngOnInit() {
    this.detailSeparatorTitle = this.detailSeparatorTitle || '';
    this.detailsShowList = this.detailsShowList || [];
    this.detailsShowListNext = this.detailsShowListNext || [];
  }

  editIconClickFn() {
    this.nameInput.focus();
  }

  toggleAccountSecondaryNavFn(type: any, val: any) {
    const values = {
      type,
      val,
    };
    this.toggleAccountSecondaryNav.emit(values);
  }

  avoideSpaces(e) {
    if (e.key === ' ' && this.config.countryName === 'Namibia') {
      e.preventDefault();
    }
  }

  changeTextVal(event: any, item: any) {
    if (item.val !== '' && item.val !== this.selectedAccData.customName) {
      
      //trimming the spaces in the nickname and removing special characters if any.
      let customName = item.val.trim();
      customName = customName.at(-1) === '.'? customName.slice(0,-1): customName;

      const renamePayload = {
        account: this.selectedAccData,
        customName: customName,
      };

      this.accountService
        .renameAccount(renamePayload)
        .subscribe((response: any) => {
          if (response.statusText === 'OK') {
            this.updateHeader.emit(item.val);
            this.selectedAccData.customName = item.val;

            let index;
            if (this.accountsList && this.accountsList.length) {
              index = _.findIndex(this.accountsList, {
                number: this.selectedAccData.number,
              });
            }
            if (index >= 0) {
              this.accountsList[index].name = item.val;
              this.accountsList[index].customName = item.val;
              this.accountsList[index].productName = item.val;
              this.dashboardData.body.accounts = _.cloneDeep(this.accountsList);

              this.store.dispatch(
                dashboardActions.setAccountData({
                  accounts: this.dashboardData,
                })
              );
            }
          } else {
            const ind = _.findIndex(this.detailsShowList, (o: any) => {
              return o.key === item.key;
            });
            if (ind >= 0) {
              this.detailsShowList[ind]['val'] =
                this.selectedAccData.customName;
              this.updateHeader.emit(this.selectedAccData.customName);
            }
          }
        });
    } else {
      const ind = _.findIndex(this.detailsShowList, (o: any) => {
        return o.key === item.key;
      });
      if (ind >= 0) {
        this.detailsShowList[ind]['val'] = this.selectedAccData.customName;
        this.updateHeader.emit(this.selectedAccData.customName);
      }
    }
  }

  updateTermDepositReinvestment() {
    return this.router.navigate([
      '/onlineServiceRequests/termDepositReinvestment/details',
    ]);
  }
}
