import { Component, OnInit } from '@angular/core';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.scss'],
})
export class PrintLayoutComponent implements OnInit {
  bankLogo: string;
  disclaimer: string;

  constructor(private appConstant: AppConstantService) {}

  ngOnInit() {
    this.bankLogo = this.appConstant.logoWithText;
    this.disclaimer = this.appConstant.printDisclaimer;
  }
}
