import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonServiceService } from '../../../core/common-service/common-service.service';
import * as _ from 'lodash';
import { DateUtil } from '../../../core/utility-classes/date.util';

@Component({
  selector: 'app-pagination-block',
  templateUrl: './pagination-block.component.html',
  styleUrls: ['./pagination-block.component.scss'],
})
export class PaginationBlockComponent implements OnChanges {
  @Output() pageChangeNumber: EventEmitter<any> = new EventEmitter();

  @Input() statementRangeSelected: any;
  @Input() filterActive: any;
  @Input() ifPagination: any;
  @Input() ifNext: any;
  @Input() pageCont: any;
  @Input() pageNumber: any;

  pageCounts: any;
  currentDateRange: string;

  ifPrev = false;

  constructor(private commonService: CommonServiceService) {}

  ngOnChanges(change) {
    if (change.statementRangeSelected) {
      if (this.statementRangeSelected) {
        const dateRange = this.commonService.getDateRange(this.statementRangeSelected);

        let fromDate = '';
        if (dateRange) {
          fromDate = DateUtil.format(dateRange.dateFrom, DateUtil.DD_MMMM_YYYY);
          this.currentDateRange = fromDate + ' to today';
        }
      }
    }

    if (this.pageCont.length > 3) {
      const pageContClone = _.cloneDeep(this.pageCont);
      let from: any;
      let to: any;

      if (this.pageNumber > 1) {
        from = this.pageNumber - 2;
      } else {
        from = 0;
      }

      if (from > 0) {
        to = this.pageNumber + 1;
      } else {
        to = 3;
      }

      this.pageCounts = pageContClone.slice(from, to);
    } else {
      this.pageCounts = _.cloneDeep(this.pageCont);
    }
    this.ifPrev = this.pageNumber > 1 ? true : false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByFn(index, item) {
    return index; // or item.id
  }

  pageChangeFn(page: any) {
    this.pageChangeNumber.emit(page);
  }

  nextPageChangeFn() {
    this.pageNumber++;
    this.pageChangeNumber.emit(this.pageNumber);
  }
  prevPageChangeFn() {
    this.pageNumber--;
    this.pageChangeNumber.emit(this.pageNumber);
  }
}
