import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sbg-label-button',
  templateUrl: './label-button.component.html',
  styleUrls: ['./label-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabelButtonComponent {
  @Input() buttonText: string;
  @Input() ariaLabel: string;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() isDisabled: boolean;
  @Input() isRaisedButton: boolean;
  @Input() buttonStyle: string;
  @Input() disableRipple: boolean;
  @Input() buttonType: string;
  @Output() clickFunction = new EventEmitter<any>();

  

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getButtonStyle(buttonStyle: string) {
    return {
      'button__basic--primary-color': this.buttonStyle === 'basic',
      'button__basic--secondary-color': this.buttonStyle === 'secondary',
      button__outline: this.buttonStyle === 'outline',
      button__transparent: this.buttonStyle === 'transparent',
      'button__navigation-link': this.buttonStyle === 'navigation-link',
      'button__warn-prompt--negative-color': this.buttonStyle === 'warn',
    };
  }
  getButtonType(buttonType: string): string {
    if (buttonType === 'primary') {
      return 'submit';
    } else {
      return 'button';
    }
  }
  buttonClicked(event) {
    this.clickFunction.emit(event);
  }
}
