import { DataSharingService } from './../../../core/data-sharing/data-sharing.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sbg-display-approval-rules',
  templateUrl: './display-approval-rules.component.html',
  styleUrls: ['./display-approval-rules.component.scss'],
})
export class DisplayApprovalRulesComponent implements OnInit {
  selectedApprover;
  constructor(private dataSharingService: DataSharingService) {}
 
  ngOnInit() {
    this.selectedApprover = this.dataSharingService.selectedApprover
      ? this.dataSharingService.selectedApprover
      : undefined;
  }
}
