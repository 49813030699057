import { ActivatedRoute, Router } from '@angular/router';
import { setPayBillerData } from '../../../../shared/store-utilities/actions/payment.action';
import { SortByPipe } from '../../../../shared/pipes/sort/sort-by.pipe';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { environment } from '../../../../../environments/environment';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UuidGeneratorService } from '../../../../core/UUID-generator/uuid-generator.service';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { PrepaidService } from '../../../../core/prepaid-service/prepaid-service.service';
import { PrepaidUtilitiesService } from '../../../../core/prepaid-utilities/prepaid-utilities.service';
import { setMobileWalletDetailsData } from '../../../../shared/store-utilities/actions/mobileWallet.action';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { delay } from 'rxjs/operators';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'sbg-pay-mobile-wallet-details',
  templateUrl: './pay-mobile-wallet-details.component.html',
  styleUrls: ['./pay-mobile-wallet-details.component.scss'],
})
export class PayMobileWalletDetailsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  withdrawalLimit;
  availableLimit;
  usedLimit;

  availableTransferBalance;
  serviceProviders;
  mainHeading: any;
  routeHistory: any;

  mainTitle: string;
  userAccountDetails;
  modifyArray;
  refineProducts;
  toggleStatus = 'open';
  manualEntryAmount;
  permissibleTransferAmount;
  initialUserBalance;
  paymentScheduleFrequency;
  lastProduct;
  reviewHeader: Array<{}> = [{}];

  totalPayableAmount = 0;
  dataForNextPage: any;
  payBillerData;
  loginState;
  maxLimit;
  minLimit;
  walletPlaceHolder;
  config = environment.config;
  paymentModel: {
    amount;
    walletselected;
    scheduleDate;
    oftenSchedule;
    numberOfPayments;
    selectedFromAccount;
    selectedUserDetails;
    name;
    eachListAmountEntry;
    permissibleTransferAmount;
    currentDate;
    currentFlow;
    mobileWalletNumber;
  } = {
    amount: '',
    walletselected: '',
    scheduleDate: '',
    oftenSchedule: '',
    numberOfPayments: '',
    selectedFromAccount: {},
    selectedUserDetails: {},
    name: '',
    eachListAmountEntry: [],
    permissibleTransferAmount: '',
    currentDate: new Date(),
    currentFlow: 'new-transaction',
    mobileWalletNumber: '',
  };
  screenSize: string;
  mobile: string;

  constructor(
    private store: Store<any>,
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private paymentUtilities: PaymentUtilitiesService,
    private recipientService: RecipientService,
    private sortBy: SortByPipe,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private prepaidService: PrepaidService,
    private prepaidUtilities: PrepaidUtilitiesService,
    private uuidService: UuidGeneratorService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private routeState: RouterStateService,
    private accountService: AccountService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.routeHistory = this.routeState.getHistory();
    const prevUrl = _.last(this.routeHistory);

    this.store
      .select('appReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        if (prevUrl.search('review') !== -1) {
          this.setDailyLimit();
          const tempDataPaymentModel = _.cloneDeep(stateData.mobileWalletReducer.mobileWalletFormData.paymentModel);
          this.paymentModel = _.cloneDeep(tempDataPaymentModel);
        } else if (prevUrl.search('scheduleTransaction/receipt') !== -1) {
          this.modifyArray = _.cloneDeep([stateData.editTxnScheduleReducer.editPayBiller]);

          this.setDailyLimit();

          if (this.paymentModel.currentFlow !== 'edit-transaction') {
            this.paymentModel.currentFlow = 'edit-transaction';
          }
        } else {
          this.modifyArray = _.cloneDeep(stateData.billerPaymentsReducer.selectedBiller);

          this.setDailyLimit();

          if (Object.keys(stateData.billerPaymentsReducer.payBillerData).length) {
            const tempBillerData = _.cloneDeep(stateData.billerPaymentsReducer.payBillerData);
            this.payBillerData = tempBillerData.transactions.rOABillerPayments || tempBillerData.transactions.cdiPayments;
          }
        }

        if (this.config.countryName === 'Uganda') {
          const length = this.config.mobileCountryCode.substr(1).length;
          const vall = _.cloneDeep(stateData.loginReducer.authenticateResponse.userProfile.mobileNumber.substr(length));
          this.paymentModel = { ...this.paymentModel, mobileWalletNumber: vall };
        }

        if (stateData.loginReducer) {
          this.loginState = stateData.loginReducer;
        }
      });

    this.mainHeading = this.paymentModel.currentFlow === 'new-transaction' ? 'Pay' : 'Edit Pay';

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'payMobileWallet',
    });
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;

      this.dataSharingService.setHeadPagination({
        mainTitle: this.mainHeading,
        subTitle: 'Details',
        showBack: this.screenSize === 'small',
        showClose: true,
        steps: true,
        module: 'payMobileWallet',
        showBackFn: this.goBackToMenu.bind(this),
      });
    });
  }

  ngOnInit() {
    this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts('local');
    if (!Object.keys(this.paymentModel.selectedFromAccount).length) {
      this.paymentModel.selectedFromAccount = this.userAccountDetails[0];
    } else {
      const transactionsFilter = this.userAccountDetails.filter((acc) => {
        return acc.number === this.paymentModel.selectedFromAccount.number;
      });
      this.paymentModel.selectedFromAccount = transactionsFilter[0];
    }

    this.prepaidService.getProviderDetails().subscribe((response: any) => {
      const responseData = response.prepaidProviders;
      this.serviceProviders = this.prepaidUtilities.getPrepaidType(responseData, 'MOBILEWALLET', 'prepaidType', 'PREPAID');

      if (this.serviceProviders && this.serviceProviders.length) {
        if (!this.paymentModel.walletselected) {
          this.paymentModel.walletselected = this.serviceProviders[0];
          this.maxLimit = this.paymentModel.walletselected.prepaidProduct.range.max.amount;
          this.minLimit = this.paymentModel.walletselected.prepaidProduct.range.min.amount;
        } else {
          const seviceFilter = this.serviceProviders.filter((acc) => {
            return acc.prepaidProviderId === this.paymentModel.walletselected.prepaidProviderId;
          });

          this.paymentModel.walletselected = seviceFilter[0];

          this.maxLimit = this.paymentModel.walletselected.prepaidProduct.range.max.amount;
          this.minLimit = this.paymentModel.walletselected.prepaidProduct.range.min.amount;
        }
      }
    });

    if (this.config.payments.payMobileWallet.accountToMobileWallet) {
      if (!Object.keys(this.paymentModel.selectedUserDetails).length) {
        // edit schedule condition here
        if (this.modifyArray[0].isFlowEditSchedule) {
          this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts(this.modifyArray[0].fromAccount.accountCurrency.currencyCode);

          const initialEditScheduleAcc = this.userAccountDetails.filter((value) => {
            return value.number === this.modifyArray[0].fromAccount.number;
          });

          this.paymentModel.selectedFromAccount = initialEditScheduleAcc[0] || this.userAccountDetails[0];
        }

        try {
          this.iteratorModifyArray(this.modifyArray);
          if (this.modifyArray[0].products) {
            this.modifyArray[0].products = this.refineProducts;
          }

          this.paymentModel.selectedUserDetails = this.modifyArray;

          this.getPastPayments();
          this.paymentModel.eachListAmountEntry = [];
          this.permissibleTransferAmount = this.userAccountDetails[0].availableBalance.amount;
          this.initialUserBalance = this.permissibleTransferAmount;
          this.paymentModel.permissibleTransferAmount = this.permissibleTransferAmount;
        } catch (ex) {
          console.error(ex);
          // error message for no accounts
        }
      }
    } else {
      this.permissibleTransferAmount = this.userAccountDetails[0].availableBalance.amount;
      this.initialUserBalance = this.permissibleTransferAmount;
    }

    if (this.config.hideFromAccFromReciept) {
      this.paymentModel.selectedFromAccount = '';
    }

    if (this.modifyArray && this.modifyArray[0] && this.modifyArray[0].isFlowEditSchedule) {
      this.userAccountDetails = this.accountsIterator.getPaymentFromAccounts(this.paymentModel.selectedFromAccount.accountCurrency.currencyCode);
      const initialEditScheduleAccount = this.userAccountDetails.filter((value) => {
        return value.number === this.paymentModel.selectedFromAccount.number;
      });
      this.paymentModel.selectedFromAccount = initialEditScheduleAccount[0];
      this.permissibleTransferAmount = this.paymentModel.selectedFromAccount;
    }

    // set data for next page
    if (this.config.isBillerCDI) {
      this.walletPlaceHolder = 'PayPulse Number';
      this.dataForNextPage = {
        account: this.userAccountDetails[0],
        transactions: {
          cdiPayments: [],
        },
      };
    } else {
      this.walletPlaceHolder = 'Mobile Wallet Number';
      this.dataForNextPage = {
        account: this.userAccountDetails[0],
        transactions: {
          rOABillerPayments: [],
        },
      };
    }
    this.setDailyLimit();
  }

  accountValueChanged(data) {
    this.paymentModel.selectedFromAccount = data;

    this.permissibleTransferAmount = data.availableBalance.amount;
    this.paymentModel.permissibleTransferAmount = this.permissibleTransferAmount;
    this.initialUserBalance = this.permissibleTransferAmount;
    this.availableTransferBalance = this.permissibleTransferAmount;
    this.setDailyLimit();
  }

  setDailyLimit() {
    const account = this.paymentModel.selectedFromAccount;
    const currency = account && account.accountCurrency ? account.accountCurrency.currencyCode : this.config.localCurrency;
    const monthly = this.accountService.getLimit('MONTHLY', currency);
    const monthlyUtilised = this.accountService.getLimit('MONTHLY_UTILIZED', currency);
    const daily = this.accountService.getLimit('DAILY', currency);
    const dailyUtilised = this.accountService.getLimit('DAILY_UTILIZED', currency);

    if (this.config.showMonthlyTransactionLimit) {
      this.withdrawalLimit = monthly;
      this.usedLimit = monthlyUtilised;
    } else {
      this.withdrawalLimit = daily;
      this.usedLimit = dailyUtilised;
    }

    if (this.withdrawalLimit && this.usedLimit && parseFloat(this.withdrawalLimit.amount) && parseFloat(this.usedLimit.amount)) {
      this.availableLimit = this.withdrawalLimit.amount - this.usedLimit.amount;
      this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
    } else {
      this.availableLimit = null;
    }
  }

  amountChanged(amount) {
    this.totalPayableAmount = amount ? amount : 0;
  }

  getPastPayments() {
    let recipientType;
    if (this.config.isBillerCDI === true) {
      recipientType = 'COMPANY';
    } else {
      recipientType = 'BILLER';
    }
    this.modifyArray[0].recipientId = this.modifyArray[0].roaBillerId || this.modifyArray[0].recipientId;
    const pastPaymentsRequest = this.paymentUtilities.fetchPreviousPaymentDetails(this.modifyArray, recipientType);
    this.recipientService.callRecipient(pastPaymentsRequest).subscribe((response) => {
      const data = response.body;
      if (data.recipientPayments[0] && data.recipientPayments[0].recentPayments.length > 0) {
        const sortPayByDate = this.sortBy.transform(data.recipientPayments[0].recentPayments, 'desc', 'date');
        this.paymentModel.selectedUserDetails[0].pastDetails = sortPayByDate[0];
        this.reviewHeader[0] = {
          name: this.paymentModel.selectedUserDetails[0].nickName,
          first:
            this.paymentModel.selectedUserDetails[0].pastDetails.amount.currency +
            ' ' +
            this.decimalPipe.transform(this.paymentModel.selectedUserDetails[0].pastDetails.amount.amount, '1.2-2'),
          second:
            this.datePipe.transform(this.paymentModel.selectedUserDetails[0].pastDetails.date, 'mediumDate') +
            ', ' +
            this.datePipe.transform(this.paymentModel.selectedUserDetails[0].pastDetails.date, 'shortTime'),
        };
      }
    });
  }

  iteratorModifyArray(data) {
    data.forEach((value, index) => {
      if (!this.modifyArray[0].isFlowEditSchedule) {
        this.modifyArray[index].amount = {
          currency: this.userAccountDetails[0].availableBalance.currency,
          amount: this.payBillerData ? this.payBillerData[index].amount.amount : '',
        };
      } else {
        this.modifyArray[index].amount = {
          currency: this.userAccountDetails[0].availableBalance.currency,
          amount: this.modifyArray[index].scheduledAmount.amount.toString(),
        };
      }
      if (value.products && value.products[0]) {
        this.refineProducts = [];
        this.lastProduct = value.products[value.products.length - 1];
        value.products.forEach((val) => {
          if (val.name === 'Specify Amount') {
            this.manualEntryAmount = val;
          } else {
            this.refineProducts.push(val);
          }
        });
        this.sortProducts();
      }
    });
  }

  sortProducts() {
    this.refineProducts = this.sortBy.transform(this.refineProducts, 'asc', 'name');
    this.refineProducts.push(this.manualEntryAmount);
  }

  goBack() {
    return this.router.navigate(['/payments/beneficiary/list'], { relativeTo: this.activatedRouter });
  }
  goBackToMenu() {
    return this.router.navigate(['/menu/payments']);
  }
  isReadonly(fieldName) {
    let returnBool = false;
    if (fieldName === 'Phone Number') {
      returnBool = this.paymentModel.selectedUserDetails[0].name === 'Airtel Bill Payment (Postpaid)';
    }
    return returnBool;
  }

  getScheduleValues(values) {
    if (values) {
      this.paymentModel = {
        ...this.paymentModel,
        ...values,
      };
    }
  }

  goToConfirmation() {
     const objectCopy = _.cloneDeep(this.paymentModel.selectedUserDetails);
     objectCopy.forEach((value, index) => {
      let thisProduct;
      const thisAmount = value.amount;
      // eslint-disable-next-line no-empty
      if (value.products) {
      } else {
        thisProduct = value.products;
      }
      delete value.amount;
      delete value.products;
      delete value.recentPayment;
      delete value.beneLetters;
      delete value.pastDetails;
      delete value.recipientId;

      if (this.paymentModel.oftenSchedule.value === 'Just Once') {
        this.paymentModel.oftenSchedule.value = 'Single';
        this.paymentModel.numberOfPayments = 1;
      }

      const futureDatedData = {
        repeatInterval: this.paymentModel.oftenSchedule.value.toUpperCase(),
        repeatNumber: parseInt(this.paymentModel.numberOfPayments, 10),
        fromDate: this.paymentModel.scheduleDate,
      };
      if (this.config.isBillerCDI === true) {
        this.dataForNextPage.transactions.cdiPayments[index] = {
          recipient: value,
          futureDatedInstruction: futureDatedData,
          transactionId: this.uuidService.getUUID(),
          amount: thisAmount,
          product: thisProduct,
        };
        if (this.modifyArray[0].isFlowEditSchedule) {
          this.dataForNextPage.transactions.cdiPayments[index].recentPayments = this.paymentModel.selectedUserDetails[0].recentPayments;
        }
      } else {
        this.dataForNextPage.transactions.rOABillerPayments[index] = {
          biller: value,
          futureDatedInstruction: futureDatedData,
          transactionId: this.uuidService.getUUID(),
          amount: thisAmount,
          product: thisProduct,
        };
        if (this.modifyArray[0].isFlowEditSchedule) {
          this.dataForNextPage.transactions.rOABillerPayments[index].recentPayments = this.paymentModel.selectedUserDetails[0].recentPayments;
        }
      }
    });

    this.dataForNextPage.paymentModel = this.paymentModel;
    this.dataForNextPage.paymentModel.modifyArray = this.modifyArray;
    this.dataForNextPage.paymentModel.amount = this.paymentModel.selectedUserDetails[0].amount;
    this.dataForNextPage.account = this.paymentModel.selectedFromAccount;

    this.store.dispatch(setPayBillerData({ payBillerData: this.dataForNextPage }));
    this.router.navigate(['../review'], { relativeTo: this.activatedRouter });
  }

  goToConfirmationFn(data, type) {
    const prepaidProviderId = data.walletselected.prepaidProviderId;
    const countryCode = this.config.mobileCountryCode;
    let trimmedCountryCode = countryCode.substr(1);
    const providerName = data.walletselected.friendlyName;
    // for Busy 4G provider in Ghana, pass mobile number without country code
    if (providerName === 'BUSY 4G' && this.config.localCurrency === 'GHS') {
      trimmedCountryCode = '';
    }

    let userProfileData;
    if (
      this.loginState.authenticateResponse &&
      this.loginState.authenticateResponse.userProfile &&
      this.loginState.authenticateResponse.userProfile.mobileNumber
    ) {
      userProfileData = this.loginState.authenticateResponse.userProfile.mobileNumber;
    }

    const payload = {
      paymentModel: this.paymentModel,
      account: data.selectedFromAccount,
      transactions: {
        prepaidPurchases: [
          {
            transactionId: this.uuidService.getUUID(),

            productCode: '',
            rechargeNumber: trimmedCountryCode + data.mobileWalletNumber,
            amount: {
              amount: data.amount,
              currency: data.selectedFromAccount.availableBalance.currency,
            },
            basePrepaidProvider: {
              friendlyName: providerName,
              prepaidProviderId: prepaidProviderId.toString(),
              prepaidType: type,
            },
            paymentConfirmation: {
              notifications: [{ address: userProfileData, type: 'SMS' }],
            },
            autoSave: false,
          },
        ],
      },
    };

    this.store.dispatch(setMobileWalletDetailsData({ mobileWalletFormData: payload }));
    return this.router.navigate(['../review'], { relativeTo: this.activatedRouter });
  }

  toggleClicked(data) {
    this.toggleStatus = data;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
