import { Injectable } from '@angular/core';
import { DataSharingService } from '../data-sharing/data-sharing.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ConfirmationScreenService {
  constructor(private dataSharingService: DataSharingService) {}

  showConfirmationScreen(
    proceedButtonText: string,
    showApproversIfSme: boolean,
    smeTransactionType?: string,
    prompt?: string,
    line2?: string,
    line3?: string
  ): Observable<boolean> {
    const result = new Subject<boolean>();
    this.dataSharingService.setCloseCurrentModule({
      message: {
        line1: prompt ? prompt : 'Are you sure?',
        line2: line2 ? line2 : 'This action cannot be undone',
        line3
      },
      buttonOneText: 'Cancel',
      buttonTwoText: proceedButtonText,
      buttonTwoDanger: true,
      showModule: true,
      showApproverSelectionIfSme: showApproversIfSme,
      financeType: 'NON_FINANCIAL_RULES',
      smeTransactionType,
    });
    this.dataSharingService
      .getCloseCurrentModule()
      .pipe(takeUntil(result))
      .subscribe((value) => {
        if (value.stopScheduleFnCall === true) {
          result.next(true);
          result.complete();
        } else if (value.showModule === false) {
          result.next(false);
          result.complete();
        }
      });
    return result;
  }
}
