import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import 'rxjs/Rx';
import { Observable } from 'rxjs';
import { RootStore } from '../state-management/state/root-state';
import { Store as ngrxStore } from '@ngrx/store';
import * as loginAction from '../../shared/store-utilities/actions/login.action';
import { ServiceEndpointsService } from '../service-endpoints/service-endpoints.service';
import { DataSharingService } from '../data-sharing/data-sharing.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class SignOutService {
  authenticate: Observable<RootStore>;
  config = environment.config;

  constructor(
    private router: Router,
    private serviceEndpointsService: ServiceEndpointsService,
    private ngrx: ngrxStore<{ appReducer }>,
    private dataSharing: DataSharingService
  ) {}

  signout() {
    this.dataSharing.setErrorMessage(null);
    this.serviceEndpointsService.cancelRequests$.next(true);
    this.dataSharing.setReadOnlyMode(false);
    this.ngrx.dispatch({ type: 'USER_LOGOUT' });
    this.ngrx.dispatch(loginAction.setLogoutData({ login: { auth: 'log out' } }));
    this.dataSharing.setChangeLimitReview(null);
    this.router.navigate(['/login']);
  }
}
