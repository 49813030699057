import { Injectable } from '@angular/core';
import { WorkflowsService } from '../workflows/workflows.service';

@Injectable({
  providedIn: 'root',
})
export class FlowsService {
  flows = {};
  flowConfigurations = {};

  constructor(private Workflows: WorkflowsService) {
    this.defineFlows();
  }

  defineFlows() {
    this.flows = {};
    const workflows = this.Workflows.flows;
    workflows.forEach((item) => {
      this.addFlow(item.name, item.steps, item.config);
    });
  }

  addFlow(name, flowArray, config) {
    this.flows[name] = flowArray;
    if (config) {
      this.flowConfigurations[name] = config;
    }
  }
}
