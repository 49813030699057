import { Directive } from '@angular/core';
import { FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';
import { environment } from '../../../../environments/environment';

@Directive({
  selector: '[mobileNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: MobileNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class MobileNumberValidatorDirective implements Validator {
  validator: ValidatorFn;
  config = environment.config;

  constructor() {
    this.validator = this.mobileNumberValidator();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  mobileNumberValidator(): ValidatorFn {
    return (control: FormControl) => {
      const val = control.value;
      if (val) {
        if (val.charAt(0) === '0') {
          return {
            mobilevalidator: {
              status: 'Mobile number should not start with a 0',
            },
          };
        } else if (val.length < this.config.minMobileNumberLength) {
          return {
            mobilevalidator: {
              status: `Minimum of ${this.config.minMobileNumberLength} digits are required`,
            },
          };
        } else {
          return null;
        }
      }
      return null;
    };
  }
}
