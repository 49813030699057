import { environment } from '../../../../../environments/environment';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { setPayBillerData, setPayBillerRecieptData } from '../../../../shared/store-utilities/actions/payment.action';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TransactionsResponse } from '../../../../core/transaction-service/data/transactions-response';

@Component({
  selector: 'sbg-pay-biller-review',
  templateUrl: './pay-biller-review.component.html',
  styleUrls: ['./pay-biller-review.component.scss'],
})
export class PayBillerReviewComponent implements OnInit, OnDestroy {
  billerConfirmModel;
  isSmeSoleProp;
  paymentReview;
  scheduleRelatedData;
  dataToRepeat;
  billerName = [];
  paymentAmount = 0;
  reviewHeader = [];
  mainHeading;
  config = environment.config;
  loginState;
  buttonText: string;
  routeHistory: any[] = [];
  prevUrl;
  destroyed$ = new Subject<boolean>();

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private routeState: RouterStateService,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private store: Store<any>
  ) {
    this.store
      .select('appReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        if (stateData.loginReducer.authenticateResponse) {
          const userIdentity = stateData.loginReducer.authenticateResponse;
          this.isSmeSoleProp = userIdentity.userProfile.isSmeSoleProprietor;
        }
        this.loginState = stateData.loginReducer;
        this.paymentReview = _.cloneDeep(stateData.billerPaymentsReducer.payBillerData);
      });
    if (this.paymentReview.paymentModel.currentFlow === 'new-transaction' || this.paymentReview.paymentModel.currentFlow === 'once-off-pay') {
      this.mainHeading = 'Pay';
    } else {
      this.mainHeading = 'Edit Pay';
    }
    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
      module: 'payBiller',
    });
  }

  ngOnInit() {
    this.dataForConfirmation();
    this.routeHistory = this.routeState.getHistory();
    this.prevUrl = _.last(this.routeHistory);
  }

  dataForConfirmation() {
    if (this.paymentReview.transactions.onceOffCdiPayments) {
      this.billerName[0] = this.paymentReview.transactions.onceOffCdiPayments[0].recipient.name;
      this.paymentAmount = this.paymentReview.transactions.onceOffCdiPayments[0].amount.amount;
      this.dataToRepeat = this.paymentReview.transactions.onceOffCdiPayments;
      this.reviewHeader[0] = {
        name: this.paymentReview.transactions.onceOffCdiPayments[0].recipient.name,
        first: this.paymentReview.transactions.onceOffCdiPayments[0].recipient.nickName,
      };
    } else if (this.paymentReview.transactions.cdiPayments) {
      this.billerName[0] =
        this.paymentReview.transactions.cdiPayments[0].recipient.nickName || this.paymentReview.transactions.cdiPayments[0].recipient.name;
      this.paymentAmount = this.paymentReview.transactions.cdiPayments[0].amount.amount;
      this.dataToRepeat = this.paymentReview.transactions.cdiPayments;
      this.reviewHeader[0] = {
        name: this.paymentReview.transactions.cdiPayments[0].recipient.name,
        first: this.paymentReview.transactions.cdiPayments[0].recipient.nickName,
      };
    } else {
      this.dataToRepeat = this.paymentReview.transactions.rOABillerPayments;
      this.getData(this.dataToRepeat);
    }
    this.setButtonText();
  }

  setButtonText() {
    if (this.loginState.isSmeMode && !this.isSmeSoleProp) {
      this.buttonText = 'Send for Approval';
    } else {
      if (this.paymentReview.paymentModel.currentFlow === 'edit-transaction' || this.paymentReview.paymentModel.currentFlow === 'edit-onceOff') {
        this.buttonText = 'Update';
      } else {
        this.buttonText = 'Pay';
      }
    }
  }

  getData(data) {
    data.forEach((value, index) => {
      this.billerName[index] = value.biller.name;
      this.paymentAmount += value.amount.amount;
      this.reviewHeader[index] = {
        name: value.biller.name,
        first: value.biller.nickName,
      };
    });
    this.paymentAmount = parseFloat(this.paymentAmount.toFixed(2));
  }

  goToReceipt(): void {
    // let objectCopy;
    let payload;
    const paymentModelDetail = _.cloneDeep(this.paymentReview.paymentModel);
   const objectCopy = { ...this.paymentReview };
    let storeObj;
    // [ ]: we can remove this block(135-138) as the varible is initialized as let. 
    // eslint-disable-next-line prefer-const
    storeObj = {
      payload: {},
      response: {},
    };
    if (objectCopy.transactions.rOABillerPayments) {
      storeObj.payload = objectCopy;
      storeObj.payload.paymentModel = paymentModelDetail;
    }

    // fixme: make it as TransactionRequest
    if (objectCopy.transactions.onceOffCdiPayments) {
      payload = {
        account: objectCopy.account,
        transactions: {
          onceOffCdiPayments: [{ ...objectCopy.transactions.onceOffCdiPayments[0] }],
        },
      };
    } else if (objectCopy.transactions.cdiPayments) {
      payload = {
        account: objectCopy.account,
        transactions: {
          cdiPayments: [{ ...objectCopy.transactions.cdiPayments[0] }],
        },
      };
    } else {
      payload = {
        account: objectCopy.account,
        transactions: {
          billerPayments: [{ ...objectCopy.transactions.rOABillerPayments[0] }],
        },
      };
    }
    if (this.loginState.isSmeMode && this.dataSharingService.selectedApprover) {
      payload = { ...payload, ...{ financialRule: this.dataSharingService.selectedApprover } };
      this.dataSharingService.selectedApprover = null;
    }

    this.makeTransaction(payload);
    this.dataSharingService.setPostService(this.makeTransaction.bind(this));
  }

  goToEditConfirmation(): void {
    const paymentModel = this.paymentReview.paymentModel;

    let dataForSchedulePayload = {
      scheduledTransaction: this.transactionService.getEditSchedulePayload(
        this.paymentReview,
        paymentModel.editData ? paymentModel.editData.type : paymentModel.selectedUserDetails[0].type
      ),
    };

    // SME mode
    if (this.loginState.isSmeMode && this.dataSharingService.selectedApprover) {
      dataForSchedulePayload = { ...dataForSchedulePayload, ...{ financialRule: this.dataSharingService.selectedApprover } };
    }

    this.postServiceEdit(dataForSchedulePayload);
    this.dataSharingService.setPostService(this.postServiceEdit.bind(this));
  }

  goBack() {
    return this.router.navigate([this.routeState.getPreviousUrl()]);
  }

  postServiceEdit(payload) {
    return this.transactionService
      .getEditTransactions(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.paymentReview = { ...this.paymentReview, response, payload };
        this.store.dispatch(setPayBillerRecieptData({ payBillerRecieptData: this.paymentReview }));

        this.router.navigate(['../receipt'], {
          relativeTo: this.activatedRoute,
        });
      });
  }

  
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private makeTransaction(payload) {
    // fixme: change it to call to makeTransaction and remove any after that
    this.transactionService
      .makeTransaction(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: TransactionsResponse) => {
        this.paymentReview = { ...this.paymentReview, response };
        this.store.dispatch(setPayBillerRecieptData({ payBillerRecieptData: this.paymentReview }));
        this.store.dispatch(setPayBillerData({ payBillerData: [] }));
        this.router.navigate(['/payments/biller/receipt'], { relativeTo: this.activatedRoute });
      });
  }
}
