import { createReducer, on } from '@ngrx/store';
import * as approverAction from '../actions/approval.action';
// {setFinancialApprovalData, setNonFinancialApprovalData, setSelectedApprovalData}

interface ApprovalData {
  financial: object;
  nonFinancial: object;
  selectedApprovalData: object;
  postApprovalData: object;
  selectedApprovalTab: object;
  approvalPayload: object;
  selectedApprovalType: string;
}
const approvalInitData: ApprovalData = {
  financial: {},
  nonFinancial: {},
  selectedApprovalData: {},
  postApprovalData: {},
  approvalPayload: {},
  selectedApprovalTab: {
    selectedTab: 0,
    selectedSubTab: 0,
  },
  selectedApprovalType: '',
};
export const approvalReducer = createReducer(
  approvalInitData,
  on(approverAction.setFinancialApprovalData, (state, { financial }) => {
    return { ...state, financial };
  }),
  on(approverAction.setNonFinancialApprovalData, (state, { nonFinancial }) => {
    return { ...state, nonFinancial };
  }),
  on(
    approverAction.setSelectedApprovalData,
    (state, { selectedApprovalData }) => {
      return { ...state, selectedApprovalData };
    }
  ),
  on(
    approverAction.setPostApprovalData,
    (state, { approvalPayload, postApprovalData }) => {
      return { ...state, approvalPayload, postApprovalData };
    }
  ),
  on(
    approverAction.setSelectedApprovalTab,
    (state, { selectedApprovalTab }) => {
      return { ...state, selectedApprovalTab };
    }
  ),
  on(
    approverAction.setSelectedApprovalType,
    (state, { selectedApprovalType }) => {
      return { ...state, selectedApprovalType };
    }
  ),
  on(
    approverAction.setPreInitiatedInternationalPaymentData,
    (state, { preInitiatedInternationalPaymentData }) => {
      return { ...state, preInitiatedInternationalPaymentData };
    }
  )
);
