import moment from 'moment';

export class DateUtil {
  static readonly DEFAULT_DATE_FORMAT = 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ';
  static readonly DD_MMM_YY = "DD MMM 'YY";
  static readonly DD_MMM_YYYY = 'DD MMM YYYY';
  static readonly DD_MMMM_YYYY = 'DD MMMM YYYY';
  static readonly DD_MMMM_YYYY_FULL_TIME_A = 'DD MMMM YYYY hh:mm:ss a';
  static readonly DDDD_DO_MMMM_YYYY = 'dddd, Do MMMM YYYY';
  static readonly MM_DD_YY_FULL_TIME_A = 'MM DD YY hh:mm:ss a';
  static readonly H_MM_SS = 'H:mm:ss';
  static readonly HH_MM_SS = 'HH:mm:ss';
  static readonly HH_MM = 'HH:mm';
  static readonly MMM_DD_YYYY = 'MMM DD, YYYY';
  static readonly DO_MMM_YYYY = 'Do MMM YYYY';

  static readonly DEFAULT_ISO = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
  static readonly ISO_YYYY_MM_DD = 'YYYY-MM-DD';
  static readonly ISO_YYYY_MM_DD_TIME = 'YYYY-MM-DD HH:mm';
  static readonly ISO_DD_MM_YYYY_FULL_TIME = 'DD-MM-YYYY HH:mm:ss';
  static readonly ISO_DD_MMMM_YYYY = 'DD-MMMM-YYYY';

  static readonly SLASH_DD_MM_YYYY = 'DD/MM/YYYY';
  static readonly SLASH_MM_DD_YYYY = 'MM/DD/YYYY';

  private static readonly OFFSET_PARSE_REGEX = /([+\-](\d{2})):?(\d{2})?/;

  /**
   * Format date according to format string and utc offset.
   * @param date the date to format
   * @param format the format of formatting
   * @param utcOffset the utc offset
   * @return formatted date
   */
  static format(
    date: Date | string | number,
    format: string,
    utcOffset?: string | number
  ): string {
    const momentDate = moment(date);
    if (utcOffset !== undefined) {
      momentDate.utcOffset(utcOffset);
    }
    return momentDate.format(format);
  }

  /**
   * Parse date according to format.
   * @param date the date to parse
   * @param format the format of date
   * @returns parsed date
   */
  static parse(date: string, format?: string): Date {
    return moment(date, format).toDate();
  }

  /**
   * Change date/time format.
   * @param initialDateString the date to parse
   * @param initialFormat the format to parse
   * @param resultFormat target format
   * @returns formatted date string
   */
  static changeFormat(
    initialDateString: string,
    initialFormat: string,
    resultFormat: string
  ): string {
    return moment(initialDateString, initialFormat).format(resultFormat);
  }

  /**
   * Change the date by the specified number of years, months, etc.
   * @param date the date to change
   * @param amount the delta change
   * @param unit the unit of delta change
   * @returns date after changing
   */
  static add(
    date: Date,
    amount: number,
    unit:
      | 'years'
      | 'months'
      | 'weeks'
      | 'days'
      | 'hours'
      | 'minutes'
      | 'seconds'
      | 'milliseconds'
      | 'quarters'
  ): Date {
    return moment(date).add(amount, unit).toDate();
  }

  /**
   * Change the date by the specified number of years, months, etc.
   * @param date the date to change
   * @param amount the delta change
   * @param unit the unit of delta change
   * @returns date after changing
   */
  static subtract(
    date: Date,
    amount: number,
    unit:
      | 'years'
      | 'months'
      | 'weeks'
      | 'days'
      | 'hours'
      | 'minutes'
      | 'seconds'
      | 'milliseconds'
      | 'quarters'
  ): Date {
    return moment(date).subtract(amount, unit).toDate();
  }

  /**
   * Calculate difference between dates.
   * @param firstDate the left operand of operation
   * @param secondDate the right operand of operation
   * @param unit the unit of difference
   * @return difference between date
   */
  static diff(
    firstDate: Date,
    secondDate: Date,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    unit?:
      | 'years'
      | 'months'
      | 'weeks'
      | 'days'
      | 'hours'
      | 'minutes'
      | 'seconds'
      | 'milliseconds'
      | 'quarters'
  ): number {
    return moment(firstDate).diff(secondDate);
  }

  /**
   * Date equality comparison.
   * @param firstDate the left operand of comparing
   * @param secondDate the right operand of comparing
   * @param granularity the measure of comparing
   * @return true - dates are equal, false - otherwise
   */
  static areSame(
    firstDate: Date | string,
    secondDate: Date | string,
    granularity?:
      | 'years'
      | 'months'
      | 'weeks'
      | 'days'
      | 'hours'
      | 'minutes'
      | 'seconds'
      | 'milliseconds'
      | 'quarters'
  ): boolean {
    return moment(firstDate).isSame(secondDate, granularity);
  }

  /**
   * Date comparison.
   * @param firstDate the left operand of comparing
   * @param secondDate the right operand of comparing
   * @param granularity the measure of comparing
   * @returns true - first date is before second, false - otherwise
   */
  static isBefore(
    firstDate: Date | string,
    secondDate: Date | string,
    granularity?:
      | 'years'
      | 'months'
      | 'weeks'
      | 'days'
      | 'hours'
      | 'minutes'
      | 'seconds'
      | 'milliseconds'
      | 'quarters'
  ): boolean {
    return moment(firstDate).isBefore(secondDate, granularity);
  }

  /**
   * Get day of week without considering locale (0 - Sun, ..., 6 - Sat).
   * @param date the date
   * @return number of day in week
   */
  static getDay(date: Date | string): number {
    return moment(date).day();
  }

  /**
   * Get day of week with considering locale (0 - Sun/Mon, ..., 6 - Sat/Sun).
   * @param date the date
   * @returns number of day in week {number}
   */
  static getWeekday(date: Date): number {
    return moment(date).weekday();
  }

  /**
   * Get names of months.
   * @return array of months
   */
  static getMonths(): string[] {
    return moment.months();
  }

  /**
   * Convert offset string in minutes.
   * @param offset string in Momentjs formats (+00 -00 +00:00 +0000 -00:00)
   * @returns minutes {number}
   */
  static offsetToMinutes(offset: string): number {
    const offsetTokens = offset.match(this.OFFSET_PARSE_REGEX);
    return (
      Math.sign(+offsetTokens[1]) *
      (Math.abs(+offsetTokens[1]) * 60 + +offsetTokens[2])
    );
  }
}
