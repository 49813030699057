import { createReducer, on } from '@ngrx/store';
import { setRefreshFlag } from '../actions/refresh.action';

const refreshFlag = false;
export const refreshReducer = createReducer(
  refreshFlag,
  on(setRefreshFlag, (state, { refreshFlag }) => {
    state = refreshFlag;
    return state;
  })
);
