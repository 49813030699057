import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BankingMetadataService } from '../../../core/banking-metadata/banking-metadata.service';
import { environment } from '../../../../environments/environment';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'sbg-form-notify-by',
  templateUrl: './form-notify-by.component.html',
  styleUrls: ['./form-notify-by.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class FormNotifyByComponent implements OnInit {
  @Input() formName: any;
  @Input() modelName: any;
  @Input() modelData: any;
  @Input() iterateObj: any;
  @Input() index: any;
  @Input() paymentModel: any;
  @Input() isArray: boolean;
  @Input() paymentModelArray: any;
  @Output() notifyValues: EventEmitter<any> = new EventEmitter();

  countryCodes;
  config = environment.config;
  modeOfCommunication = [
    {
      mode: 'SMS',
      display: 'SMS',
    },
    {
      mode: 'EMAIL',
      display: 'Email',
    },
    {
      mode: 'NONE',
      display: 'None',
    },
  ];

  searchCtrl = '';

  constructor(private bankingMetadataService: BankingMetadataService) {}

  ngOnInit() {
    this.modelName = this.modelData;
    this.iterateObj = _.cloneDeep(this.iterateObj);

    if (this.isArray) {
      this.paymentModelArray = _.cloneDeep(this.paymentModelArray);
    }

    this.getCountryCodes();
  }

 

  benefeciaryModeOfCommunication(data, payBene, index) {
    payBene.selectedPaymentProof = data.value;

    // this data will go in send val
    payBene.paymentConfirmation.confirmationType = data.value;

    payBene.showMultiNotificationEmail = false;
    payBene.showMultiNotificationSms = false;

    if (
      data.value === 'SMS' &&
      this.paymentModel &&
      this.paymentModel.addressFlag[index] &&
      this.paymentModel.addressFlag[index].mobile &&
      this.paymentModel.addressFlag[index].mobile.indexOf('-') > -1
    ) {
      const phone = this.paymentModel.addressFlag[index].mobile.split('-');
      payBene.paymentConfirmation.countryCode = phone[0].trim();
      payBene.paymentConfirmation.mobileNumber = phone[1].trim();
    } else if (data.value === 'EMAIL' && this.paymentModel && this.paymentModel.addressFlag[index] && this.paymentModel.addressFlag[index].email) {
      payBene.paymentConfirmation.email = this.paymentModel.addressFlag[index].email;
    } else {
      payBene.paymentConfirmation.countryCode = '';
      payBene.paymentConfirmation.mobileNumber = '';
      payBene.paymentConfirmation.email = '';
    }

    // Send data
    this.addNotificationInPayload(payBene, index);
  }

  multiNotificationChange(payBene, data, index) {
    if (data === 'SMS') {
      payBene.paymentConfirmation.email = '';

      if (!payBene.showMultiNotificationEmail) {
        payBene.showMultiNotificationEmail = true;
        payBene.showMultiNotificationSms = false;
      } else {
        payBene.showMultiNotificationEmail = false;
        payBene.showMultiNotificationSms = false;
      }
    } else if (data === 'EMAIL') {
      payBene.paymentConfirmation.mobileNumber = '';
      payBene.paymentConfirmation.countryCode = '';

      if (!payBene.showMultiNotificationSms) {
        payBene.showMultiNotificationSms = true;
        payBene.showMultiNotificationEmail = false;
      } else {
        payBene.showMultiNotificationEmail = false;
        payBene.showMultiNotificationSms = false;
      }
    }

    // Send data
    this.addNotificationInPayload(payBene, index);
  }

  modalValueChanges(payBene, index) {
    // Send data
    this.addNotificationInPayload(payBene, index);
  }

  addNotificationInPayload(payBene, index) {
    const value: any = _.cloneDeep(payBene);

    value.paymentConfirmation.notifications = [];

    /* Set email */
    const email = value.paymentConfirmation.email;

    /* Set mobile */
    let newMobileNumber;
    const mobileNumber = value.paymentConfirmation.mobileNumber;
    if (value.paymentConfirmation.countryCode && mobileNumber) {
      newMobileNumber = '+' + value.paymentConfirmation.countryCode + mobileNumber;
    }

    if (mobileNumber && email) {
      value.paymentConfirmation.notifications = [
        {
          address: newMobileNumber,
          type: 'SMS',
        },
        {
          address: email,
          type: 'EMAIL',
        },
      ];
    } else if (mobileNumber) {
      value.paymentConfirmation.notifications = [
        {
          address: newMobileNumber,
          type: 'SMS',
        },
      ];
    } else if (email) {
      value.paymentConfirmation.notifications = [
        {
          address: email,
          type: 'EMAIL',
        },
      ];
    } else {
      value.paymentConfirmation.notifications = [
        {
          address: null,
          type: 'None',
        },
      ];
    }

    this.sendValues(value, index);
  }

  sendValues(value, index) {
    const values: any = {
      index,
      value,
    };

    this.notifyValues.emit(values);
  }
   /* Country codes */
   private getCountryCodes() {
    this.bankingMetadataService.getCountryCodes().subscribe((response) => {
      this.countryCodes = response.countryCodes;
    });
  }
}
