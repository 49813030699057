import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-print-download',
  templateUrl: './print-download.component.html',
  styleUrls: ['./print-download.component.scss'],
})
export class PrintDownloadComponent {
  @Output() printFn: EventEmitter<any> = new EventEmitter();
  @Output() downloadFn: EventEmitter<any> = new EventEmitter();


  print() {
    this.printFn.emit();
  }
  download() {
    this.downloadFn.emit();
  }
}
