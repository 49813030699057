import { ServiceEndpointsService } from './../../core/service-endpoints/service-endpoints.service';
import { SnackBarService } from './../../services/snack-bar-service/snackBarService';
import { OtpService } from './../../services/OtpService/otp.service';
import { OtpDetailsState } from './../../shared/store-utilities/state/otp.state';
import { Select } from '@ngxs/store';
import { Store } from '@ngrx/store';
import { environment } from './../../../environments/environment';
import { DataSharingService } from './../../core/data-sharing/data-sharing.service';
import { Observable, Subscription } from 'rxjs';
import { OnInit, Component, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { OtpType } from './otp';
import { NgForm } from '@angular/forms';
import { McaHeader } from '../../core/data/mca-header';

@Component({
  selector: 'sbg-app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit, OnDestroy {
  @ViewChild('oref', { static: true }) otpRef: ElementRef;
  @ViewChild('ngform', { static: true }) f: NgForm;

  @Select(OtpDetailsState.getOtpDetails) receiptModel$: Observable<any>;
  show = false;
  env = environment.config;
  otpInputFields = {} as any;
  singleOtpBox = false;
  detailsReceived = {} as any;
  isSmeMode;
  showMailLink;
  statusDetails;
  message: string;
  otpModel = new OtpType();
  receiptModel;
  showBothContacts = false;
  subscription: Subscription[] = [];
  subs$1: Subscription;
  subs$2: Subscription;
  otpcode = {} as any;
  contactMethod = '';
  maskedMail;
  constructor(
    private ngrxstore: Store<any>,
    private dataSharingService: DataSharingService,
    public otpService: OtpService,
    public snack: SnackBarService,
    public serviceEndPoint: ServiceEndpointsService
  ) {}
  ngOnInit() {
    const header = this.dataSharingService.getHeadPagination();
    this.dataSharingService.setHeadPagination({
      // [ ] : source is deprecated. need to find alternative
      // @ts-ignore
      mainTitle: header.source.value.mainTitle,
      subTitle: 'One Time PIN',
      showBack: false,
      showClose: true,
      steps: false,
    });
    this.subs$1 = this.receiptModel$.subscribe((data) => {
      if (data) {
        // this is comming from else of http-interceptor
        this.detailsReceived = { ...data };
        this.statusDetails = this.detailsReceived;
        if (data.event !== undefined) {
          const eventData = data.event;
          let makeData;
          let msgData = '';

          if (eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE) === undefined) {
            msgData = '';
            makeData = {
              msg: msgData,
              type: eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_TYPE),
              body: eventData.body,
            };
          } else {
            makeData = {
              msg: eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE),
              type: eventData.headers.headers.get(McaHeader.X_SBG_RESPONSE_TYPE),
              body: eventData.body,
            };
          }
          this.receiptModel = makeData;
          this.message = this.receiptModel.msg[0];
        }
      }
    });
    this.subscription.push(this.subs$1);
    if (this.detailsReceived.stepUp.contactMethod === 'EMAIL') {
      this.showMailLink = false;
    } else {
      this.showMailLink = true;
    }
    this.ngrxstore.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });
    this.resetOtpFields();
    this.defaultDetails();
  }

  getOtpDetails(event) {
    /* eslint-disable */
    console.log(event);
  }

  tabTrigger($event, $index) {
    // if the value pressed is "", reset
    // on key-up get input value first
    // input value before transformation

    const inputValue = $event.target.value;

    if (inputValue !== '*') {
      if (inputValue !== '') {
        this.otpInputFields.intakeReal[$index] = inputValue;
        this.otpInputFields.intakeMasked[$index] = '*';
        if ($index < 4) {
          this.otpRef.nativeElement[$index + 1].focus();
        }
      } else {
        // if not backspace
        // we rely on html 5 here to make sure we only get numeric values of single digits [0-9]
        this.resetOtpFields();
        // and focus on the first child
        this.otpRef.nativeElement[0].focus();
      }
    }
  }
  goNext() {
    const takeOtp = { ...this.detailsReceived.stepUp, code: this.otpInputFields.intakeReal.join('') };
    const preparePayload = {
      ...this.detailsReceived.body,
      stepUp: takeOtp,
    };
    this.subs$2 = this.dataSharingService.getPostService().subscribe((data) => {
      data(preparePayload);
    });
    this.subscription.push(this.subs$2);
    this.resetOtpFields();
    this.otpRef.nativeElement[0].focus();
  }
  reSendOtp(type) {
    this.resetOtpFields();
    let wewdataReveived = {} as any;
    if (this.detailsReceived.event !== undefined) {
      wewdataReveived = { ...this.detailsReceived.event.body };
      this.sendDetails(type, wewdataReveived);
    } else {
      wewdataReveived = { ...this.detailsReceived.body };
      this.sendDetails(type, wewdataReveived);
    }
  }

  sendDetails(type, newotpcode) {
    const { stepUp, keyValueMetadata } = newotpcode;
    const modifiedstepUpcode = { ...stepUp, code: '' };
    let resendPayload = {} as any;
    resendPayload = {
      keyValueMetadata,
      stepUp: modifiedstepUpcode,
    };
    if (type) {
      resendPayload.stepUp.contactMethod = 'EMAIL';
      this.showBothContacts = true;
    } else {
      resendPayload.stepUp.contactMethod = 'SMS';
      this.showBothContacts = false;
    }
    this.otpService.resendOtp(resendPayload).subscribe(
      (res) => {
        if (res) {
          const response = this.serviceEndPoint.parseDataToParser(res);
          if (response.stepUp.code === 'OTP generated successfully' && response.stepUp.contactMethod === 'EMAIL') {
            if (response.stepUp.maskedAddress.length === 1) {
              this.maskedMail = response.stepUp.maskedAddress[0];
            } else {
              this.maskedMail = response.stepUp.maskedAddress[1];
            }
            this.snack.open(`One-Time PIN sent to your registered E-mail address  ${this.maskedMail}`, 'success');
          }

          if (
            response.stepUp.code === 'OTP generated successfully' &&
            (response.stepUp.contactMethod === 'SMS_EMAIL' || response.stepUp.contactMethod === 'SMS')
          ) {
            this.snack.open('One-Time PIN sent to your registered number', 'success');
          }

          if (response.stepUp.code !== 'OTP generated successfully') {
            this.snack.open('One-Time PIN could not be resent', 'error');
          }
          this.focusFirstInput();
        }
      },
      (err) => {
        if (err) {
          this.snack.open('One-Time PIN could not be resent', 'error');
          this.focusFirstInput();
        }
      }
    );
  }
  resetOtpFields() {
    const sudoApp = ['', '', '', '', ''];
    this.otpInputFields.intakeReal = [];
    this.otpInputFields.intakeMasked = [];
    sudoApp.forEach((item) => {
      this.otpInputFields.intakeMasked.push(item);
    });
  }
  focusFirstInput() {
    this.otpRef.nativeElement[0].focus();
  }
  defaultDetails() {
    if (this.detailsReceived.stepUp.contactMethod && this.detailsReceived.stepUp.contactMethod.toLowerCase() === 'email') {
      this.contactMethod = 'email';
    } else {
      this.contactMethod = 'number';
    }
    this.focusFirstInput();
  }
  ngOnDestroy(): void {
    this.subscription.forEach((item) => {
      if (item !== undefined) {
        item.unsubscribe();
      }
    });
  }
}
