import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Recipient } from '../../../../core/recipient-service/data/billers/biller-response';
import { TransactionManagementService } from '../../../../core/transaction-management/transaction-management.service';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import BuyUtilities from '../../../buy/buy-utilities.service';

@Component({
  selector: 'sbg-schoolfees-list',
  templateUrl: './schoolfees-list.component.html',
  styleUrls: ['./schoolfees-list.component.scss'],
})
export class SchoolFeesListComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  recipientList: Array<{}>;
  rightBtnTxt: string;
  selectedRecipient: Recipient;

  private readonly SCHOOL_FEE_TRANSACTION_TYPE = 'SCHOOL_FEE';

  constructor(
    private router: Router,
    private dataSharingService: DataSharingService,
    private transactionManagementService: TransactionManagementService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((screenSize) => {
      this.dataSharingService.setHeadPagination({
        mainTitle: 'Fees Payment',
        subTitle: '',
        showBack: screenSize === 'small',
        showClose: true,
        steps: false,
        showBackFn: this.goBackToMenu.bind(this),
      });
    });
  }

  ngOnInit() {
    this.dataSharingService.resetStepData();
    this.dataSharingService.selectedApprover = null;
    this.getRecipient();
  }

  
  listItemClicked(recipient) {
    if (recipient.data) {
      this.selectedRecipient = recipient.data;
      this.rightBtnTxt = 'Pay Fee for ' + recipient.data.customerName;
    } else {
      this.selectedRecipient = null;
      this.rightBtnTxt = 'Pay Fee';
    }
  }

  goBackToMenu() {
    return this.router.navigate(['/menu/payments']);
  }

  goToDetails() {
    if (this.selectedRecipient) {
      this.dataSharingService.dataFromFirstStep = this.selectedRecipient;
      this.router.navigate(['./payments/schoolfees/invoice']);
    } else {
      this.router.navigate(['./payments/schoolfees/details']);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getRecipient(): void {
    const payload = {
      transactionType: this.SCHOOL_FEE_TRANSACTION_TYPE,
      onlyTopFrequentlyPaid: true,
    };

    this.transactionManagementService
      .getVasReceipt(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.recipientList = new BuyUtilities().formatRecipientList(response.body.vasReceipts);
        if (this.recipientList.length === 0) {
          this.rightBtnTxt = 'Make new Payment';
        } else {
          this.rightBtnTxt = 'Pay Fee';
        }
      });
  }

}
