import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';

@Injectable()
export class AccountsIteratorService {
  accountsObject: { body };
  data;
  dashboardData;
  config = environment.config;
  allUserAccountsWithUSD: any;
  userAccountsWithUSD = [] as any;

  constructor(private ngrxStore: Store<{ appReducer }>) {
    // this.serviceEndpointsService.cancelRequests$.subscribe((val) => console.log('cancelled ?', val));
    this.ngrxStore.select('appReducer', 'dashboardReducer').subscribe((stateData) => {
      this.dashboardData = stateData;
      this.accountsObject = this.dashboardData.accounts;
    });
  }

  getAccountsMainObj() {
    return this.accountsObject;
  }

  getAllAccounts() {
    let allAccounts = [];
    if (this.accountsObject && this.accountsObject.body && this.accountsObject.body.accounts && this.accountsObject.body.accounts.length) {
      allAccounts = this.accountsObject.body.accounts;
    }
    return allAccounts;
  }

  getPaymentFromAccounts(currencyRequired: string, feature?: string) {
    const accounts = this.accountsObject.body.accounts;
    let filteredAccounts = [];
    let accountCurrencyType = true;
    // this would change once the country specific config is done
    const localCurrency = this.config.localCurrency;
    filteredAccounts = accounts.filter((account) => {
      if (currencyRequired === 'local') {
        accountCurrencyType = account.accountCurrency && account.accountCurrency.currencyCode === localCurrency;
      } else if (currencyRequired === 'foreign') {
        accountCurrencyType = account.accountCurrency && account.accountCurrency.currencyCode !== localCurrency;
      } else if (currencyRequired !== 'all') {
        if (currencyRequired === 'USD') {
          accountCurrencyType = account.accountCurrency && account.accountCurrency.currencyCode === 'USD';
        } else {
          accountCurrencyType =
            account.accountCurrency &&
            (account.accountCurrency.currencyCode === currencyRequired || account.accountCurrency.currencyCode === localCurrency);
        }
      } else {
        console.error('Value ' + currencyRequired + ' for required currency is unkown');
      }

      if (accountCurrencyType && this.validAccountFeature(account.accountFeature, feature)) {
        return true;
      }
      return false;
    });
    return filteredAccounts;
  }

  getUSDAccounts() {
    this.allUserAccountsWithUSD = this.getPaymentFromAccounts('USD');

    if (this.allUserAccountsWithUSD && this.allUserAccountsWithUSD.length) {
      this.allUserAccountsWithUSD.forEach((account) => {
        const isAccountWithCode = !!account.keyValueMetadata.find((element) => element.value === 'OD008');

        if (isAccountWithCode) {
          this.userAccountsWithUSD.push(account);
        }
      });
    }

    return this.userAccountsWithUSD;
  }

   // writitng a new function to filter accounts based on array of currencies supplies
   filterAccountsBasedOnCurrency(currencyArr) {
    const accounts = this.accountsObject.body.accounts;
    const filteredAccounts = accounts.filter((account) => {
      return currencyArr.indexOf(account.accountCurrency.currencyCode) !== -1;
    });
    return filteredAccounts;
  }

  getFixedDepositAccounts() {
    const fixedDepositAccount = [];
    if (this.accountsObject.body.accounts && this.accountsObject.body.accounts.length) {
      for (let i = 0; i < this.accountsObject.body.accounts.length; i++) {
        if (this.accountsObject.body.accounts[i].accountType && this.accountsObject.body.accounts[i].accountType === 'FIXED_TERM_INVESTMENT') {
          fixedDepositAccount.push(this.accountsObject.body.accounts[i]);
        }
      }
      return fixedDepositAccount;
    }
  }

  filterkeyValType(type) {
    const requiredAccount = [];
    const accounts = this.accountsObject.body.accounts;
    for (let i = 0; i < accounts.length; i++) {
      if (this.getRequiredAcount(accounts[i].keyValueMetadata, type)) {
        requiredAccount.push(accounts[i]);
      }
    }
    return requiredAccount;
  }
  filterAccountsByType() {
    const accounts = this.accountsObject.body.accounts;
    const filteredAccounts = [];
    const localCurrency = this.config.localCurrency;
    for (let i = 0; i < accounts.length; i++) {
      if (
        accounts[i].accountStatus &&
        accounts[i].accountStatus !== 'DORMANT' &&
        this.getRequiredAcount(accounts[i].keyValueMetadata, '') &&
        accounts[i].accountCurrency.currency === localCurrency
      ) {
        filteredAccounts.push(accounts[i]);
      }
    }
    return filteredAccounts;
  }

  getRequiredAcount(keyValMetadata, type) {
    let keyValTyp;
    if (type === 'overDraft') {
      keyValTyp = ['OD001', 'OD002', 'OD003', 'OD004', 'OD005', 'OD006', 'OD008'];
    } else if (type === 'noticeAccount') {
      keyValTyp = ['NDA'];
    } else {
      keyValTyp = ['OD001', 'OD002', 'OD003', 'OD004', 'OD005', 'OD006', 'OD008', 'CAA'];
    }
    let checkForRequiredAccount = false;
    for (let j = 0; j < keyValMetadata.length; j++) {
      if (keyValMetadata[j].key === 'FEB_PRODUCT_CODE' && _.includes(keyValTyp, keyValMetadata[j].value)) {
        checkForRequiredAccount = true;
      }
    }
    return checkForRequiredAccount;
  }
  getAccountFromNumber(accountNumber) {
    return this.accountsObject.body.accounts.filter((account) => {
      return account.number === accountNumber;
    });
  }

  filterAccountsByStatus(data, status) {
    const filteredAccObject = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].accountStatus !== status) {
        filteredAccObject.push(data[i]);
      }
    }
    return filteredAccObject;
  }

  filterSavingsAndOverdraftAccounts(chosenAccount) {
    const allowedAccountType = ['SBA', 'ODA'];
    for (let j = 0; j < chosenAccount.keyValueMetadata.length; j++) {
      if (
        chosenAccount.keyValueMetadata[j].key === 'FEB_ACCOUNT_TYPE_CODE' &&
        _.contains(allowedAccountType, chosenAccount.keyValueMetadata[j].value)
      ) {
        return chosenAccount.keyValueMetadata[j];
      }
    }
  }
  filterPrimaryCifAccounts(accounts) {
    let customerID;
    const primaryCifAccounts = [];
    this.ngrxStore.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.authenticateResponse) {
        customerID = stateData.authenticateResponse.userProfile.customerId;
        for (let i = 0; i < accounts.length; i++) {
          if (accounts[i].customerId === customerID) {
            primaryCifAccounts.push(accounts[i]);
          }
        }
      }
    });
    return primaryCifAccounts;
  }

  private getCurrencyType(currencyCode: string, localCurrency: string, currencyRequired: string): boolean {
    let isCurrency = false;
    switch (currencyRequired) {
      case 'local':
        isCurrency = currencyCode === localCurrency;
        break;
      case 'foreign':
        isCurrency = currencyCode !== localCurrency;
        break;
      case 'all':
        isCurrency = true;
        break;
      default:
        break;
    }
    return isCurrency;
  }

  private validAccountFeature(accFeatures: object[], feature: string): boolean {
    let checkForFromFeature = false;
    feature = feature || 'PAYMENTFROM';
    const validFromAccount = {
      feature,
      value: true,
    };
    for (let i = 0; i < accFeatures.length; i++) {
      if (JSON.stringify(validFromAccount) === JSON.stringify(accFeatures[i])) {
        checkForFromFeature = true;
        break;
      }
    }
    return checkForFromFeature;
  }

 
}
