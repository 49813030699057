import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Institution } from '@core/banking-metadata/data/institution';
import { FinancialRule } from '@core/data/financial-rule';
import { InstitutionBillDetails } from '@core/transaction-service/data/school-institutions/institution-bill-details';
import { VasPayment } from '@core/transaction-service/data/vas-payment';
import { SmeHelperService } from 'src/app/services/sme-helper/sme-helper.service';
import { DataSharingService } from '../../../.../../../core/data-sharing/data-sharing.service';
import { TransactionService } from '../../../.../../../core/transaction-service/transaction.service';
import { CheckedInvoice } from '../schoolfees-invoice/schoolfees-invoice.component';
import { environment } from './../../../../../environments/environment';
import { AccountService } from './../../../../core/account-service/account-service.service';
import { TransactionsRequest } from './../../../../core/transaction-service/data/transactions-request';

@Component({
  selector: 'sbg-schoolfees-review',
  templateUrl: './schoolfees-review.component.html',
  styleUrls: ['./schoolfees-review.component.scss'],
})
export class SchoolFeesReviewComponent implements OnInit {
  transactionRequest: TransactionsRequest;
  payments: VasPayment[];
  isInvoicePayment = false;
  invoices: CheckedInvoice[];
  totalAmount: number;
  billDetails: InstitutionBillDetails;
  selectedInstitution: Institution;
  headerDetails: any;
  isSme = false;
  proceedButtonText = 'Confirm';
  isAllowedMultipleVasPaymentsForSme = environment.config.isAllowedMultipleVasPaymentsForSme;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private accountService: AccountService,
    private smeHelper: SmeHelperService
  ) {}

  ngOnInit() {
    this.isSme = this.smeHelper.isSmeMode();
    this.updateProceedButtonText(this.isSme, this.smeHelper.isUserSmeSoleProprietor());
    this.updateHeader();

    if (this.dataSharingService.dataFromThirdStep) {
      this.transactionRequest = this.dataSharingService.dataFromThirdStep.transactionRequest;
      this.payments = this.dataSharingService.dataFromThirdStep.payments;
      this.isInvoicePayment = this.dataSharingService.dataFromThirdStep.isInvoicePayment;
      this.invoices = this.dataSharingService.dataFromThirdStep.invoices.filter((invoice) => invoice.isChecked);
      this.totalAmount = parseFloat(parseFloat(this.dataSharingService.dataFromThirdStep.totalAmount).toFixed(2));
      this.billDetails = this.dataSharingService.dataFromThirdStep.billDetails;
      this.selectedInstitution = this.dataSharingService.dataFromThirdStep.selectedInstitution;
    }

    this.headerDetails = {
      name: this.billDetails.entityName,
      first: 'Fee Payment',
    };
  }

  goBack() {
    return this.router.navigate(['../invoice'], { relativeTo: this.activatedRoute });
  }

  proceedToReceipt() {
    this.initiateFinancialRule();
    this.makeTransaction(this.transactionRequest);
    this.dataSharingService.setPostService(this.makeTransaction.bind(this));
  }

  private updateHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay Fee',
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'schoolFees',
    });
  }

 

  private initiateFinancialRule() {
    const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;
    if (this.isAllowedMultipleVasPaymentsForSme) {
      this.transactionRequest.transactions.prepaidPurchases = [
        ...this.transactionRequest.transactions.prepaidPurchases.map((prepaidPurchase) => {
          return {
            ...prepaidPurchase,
            financialRule: financialRule,
          };
        }),
      ];
    } else {
      this.transactionRequest.financialRule = financialRule;
    }
  }

  private updateProceedButtonText(isSme: boolean, isSmeSoleProprietor: boolean) {
    if (isSme && !isSmeSoleProprietor) {
      this.proceedButtonText = 'Send for Approval';
    } else {
      this.proceedButtonText = 'Confirm';
    }
  }

  private makeTransaction(request: TransactionsRequest) {
    this.transactionService.makeTransaction(request).subscribe((value) => {
      if (!value.stepUp) {
        if (value.cardProfile) {
          this.accountService.cardProfile = value.cardProfile;
        }
        this.dataSharingService.dataForReceiptPage = {
          responseData: value,
          requestData: request,
          billDetails: this.billDetails,
          selectedInstitution: this.selectedInstitution,
          isInvoicePayment: this.isInvoicePayment,
          totalAmount: this.totalAmount,
        };
        this.router.navigate(['/payments/schoolfees/receipt']);
      }
    });
  }
}
