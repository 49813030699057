import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { DragAndDropService } from './bulkFileValidation.service';
import { environment } from '../../../environments/environment';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ElementRef,
  Renderer2,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'sbg-roa-fileUploader',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragDropComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input() bulkModuleType;
  @Input() showErrorMsg: boolean;
  @Input() showRules: boolean;
  @Output() rulesChanged = new EventEmitter<boolean>();
  @ViewChild('file') file: ElementRef;
  @ViewChild('dragOnMe') dragOnMe: ElementRef;
  destroyed$ = new Subject<boolean>();

  envConfig = environment.config;
  localCurrency = this.envConfig.localCurrency;
  validateEightFields =
    this.envConfig.payments.bulkFileUpload.validateEightFieldsBulkFile;

  fileData;
  chooseFile;
  dropbox;
  fileDetail: any = {};
  uploadSuccess = false;
  selectedFileMetaData;
  // bulkdata;
  fileErrors;

  bankSortCodeFile =
    'assets/Documents/' + this.localCurrency + '/bank-sort-code.xlsx';

  constructor(
    private dragAndDropService: DragAndDropService,
    private render: Renderer2,
    private dataShare: DataSharingService
  ) {}

  ngOnInit() {
    this.dataShare.getBulkFileData().subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.showErrorMsg = data.showErrorMsg;
        this.uploadSuccess = data.uploadSuccess;
        this.fileErrors = data.fileErrors;

        if (data && data.fileDetail && data.fileDetail.fileName) {
          this.bulkModuleType = data.bulkModuleType;
          this.fileDetail = data.fileDetail;
          this.selectedFileMetaData = data.selectedFileMetaData;
        }
      }
    });
  }

  ngOnChanges(change: any) {
    if (change.showErrorMsg) {
      // eslint-disable-next-line no-self-assign
      this.showErrorMsg = this.showErrorMsg;
    }
  }

  ngAfterViewInit() {
    if (this.dragOnMe && this.dragOnMe.nativeElement) {
      this.dropbox = this.render.listen(
        this.dragOnMe.nativeElement,
        'dragenter',
        this.dragenter.bind(this)
      );
      this.dropbox = this.render.listen(
        this.dragOnMe.nativeElement,
        'dragover',
        this.dragover.bind(this)
      );
      this.dropbox = this.render.listen(
        this.dragOnMe.nativeElement,
        'drop',
        this.drop.bind(this)
      );
      this.dropbox = this.render.listen(
        this.dragOnMe.nativeElement,
        'dragend',
        this.resetBorder.bind(this)
      );
    }
  }

  rulesClicked() {
    this.showRules = !this.showRules;
    this.rulesChanged.emit(this.showRules);
  }

  setBorder() {
    this.render.setStyle(
      this.dragOnMe.nativeElement,
      'border',
      '1px dashed #999'
    );
  }

  resetBorder() {
    this.render.setStyle(this.dragOnMe.nativeElement, 'border', 'none');
  }

  dragenter(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setBorder();
  }

  dragover(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  drop(e) {
    e.stopPropagation();
    e.preventDefault();
    const dataTransfer = e.dataTransfer;
    this.resetBorder();
    this.fileNameChanged(dataTransfer);
  }

  selectFile() {
    const inputElement: HTMLElement = this.file.nativeElement as HTMLElement;
    inputElement.click();
  }

  validateFile(files, fileData) {
    const reader = new FileReader();
    let bulkFileContent;
    let indexOfFileType;
    let actualFileName;
    let totalTransferAmount;
    reader.onload = () => {
      const binaryString = reader.result;
      indexOfFileType = fileData.name.length - 4;
      actualFileName = fileData.name.substring(0, indexOfFileType);
      if (this.bulkModuleType === 'payBulkBene') {
        bulkFileContent = this.dragAndDropService.parseCSV(binaryString);
      } else if (this.bulkModuleType === 'sendBulkMoney') {
        bulkFileContent =
          this.dragAndDropService.sendBulkMoneyFile(binaryString);
      }
      totalTransferAmount = this.dragAndDropService.getTotalTransferAmount();
      if (!bulkFileContent.error) {
        this.uploadSuccess = true;
        this.fileDetail = {
          fileType: fileData.name,
          fileName: actualFileName,
        };
        if (this.bulkModuleType === 'payBulkBene') {
          this.fileDetail.records = bulkFileContent;
        } else if (this.bulkModuleType === 'sendBulkMoney') {
          this.fileDetail.fileContent = bulkFileContent;
        }
        this.fileDetail.totalTransferAmount = totalTransferAmount;
      } else {
        this.uploadSuccess = false;
        this.file.nativeElement.value = '';
        this.fileDetail = {
          fileName: '',
        };
        this.showError(bulkFileContent);
      }
      this.updateService();
    };
    reader.readAsText(files[0]);
  }

  showError(fileData) {
    this.showRules = true;
    this.fileErrors = fileData;
  }

  fileNameChanged(filePassed) {
    this.showRules = false;
    this.showErrorMsg = false;
    this.fileErrors = null;
    let files;
    if (filePassed.hasOwnProperty('file')) {
      files = filePassed.file.nativeElement.files;
    } else {
      files = filePassed.files;
    }
    this.selectedFileMetaData = files;
    this.fileData = this.dragAndDropService.handleUpload(files);

    if (this.fileData.error) {
      this.showError(this.fileData);
      this.updateService();
    } else {
      this.validateFile(files, this.fileData);
    }
  }
  updateService() {
    // store
    const showErrorMsgCopy = this.showErrorMsg;
    const fileDetailCopy = { ...this.fileDetail };
    const bulkModuleTypeCopy = this.bulkModuleType;
    const fileErrorsCopy = { ...this.fileErrors };
    const selectedFileMetaDataCopy = { ...this.selectedFileMetaData };
    const uploadSuccessCopy = this.uploadSuccess;
    const showRulesCopy = this.showRules;

    this.dataShare.setBulkFileData({
      showErrorMsg: showErrorMsgCopy,
      fileDetail: fileDetailCopy,
      bulkModuleType: bulkModuleTypeCopy,
      fileErrors: fileErrorsCopy,
      selectedFileMetaData: selectedFileMetaDataCopy,
      uploadSuccess: uploadSuccessCopy,
      showRules: showRulesCopy,
    });
  }
  ngOnDestroy() {
    if (this.dragOnMe && this.dragOnMe.nativeElement) {
      this.dragOnMe.nativeElement.removeEventListener(
        'dragenter',
        this.dragenter
      );
      this.dragOnMe.nativeElement.removeEventListener(
        'dragover',
        this.dragover
      );
      this.dragOnMe.nativeElement.removeEventListener('drop', this.drop);
      this.dragOnMe.nativeElement.removeEventListener(
        'dragend',
        this.resetBorder
      );
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
