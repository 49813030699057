import { Component, OnInit } from '@angular/core';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { PreparedBiller } from '../gepg-payment.model';

@Component({
  selector: 'sbg-gepg-payment-details',
  templateUrl: './gepg-payment-details.component.html',
  styleUrls: ['./gepg-payment-details.component.scss'],
})
export class GepgPaymentDetailsComponent implements OnInit {

  selectedBiller: PreparedBiller;

  constructor(private store: Store<any>,
              private accountsIterator: AccountsIteratorService,
              private dataSharingService: DataSharingService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.updateHeader();
    this.selectedBiller = this.dataSharingService.dataFromFirstStep.selectedBiller;
  }


 

  goBack() {
    this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }

  goNext() {
    this.dataSharingService.dataFromFirstStep = {selectedBiller: this.selectedBiller};
    this.router.navigate(['../amount'], { relativeTo: this.activatedRoute });
  }
  
  private updateHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Biller Payment (GEPG)',
      subTitle: 'Your Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'gepg',
    });
  }
}
