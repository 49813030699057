import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';

@Component({
  selector: 'app-new-service-request',
  templateUrl: './new-service-request.component.html',
  styleUrls: ['./new-service-request.component.scss'],
})
export class NewServiceRequestComponent implements OnInit {
  newServiceRequestTypes;
  noticeAccoutList;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequest: ServiceRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'New Service Request',
      subTitle: '',
      showBack: true,
      showClose: true,
      steps: false,
      module: 'serviceRequest',
    });

    this.serviceRequest.newServiceRequest().subscribe((response) => {
      this.newServiceRequestListData(response);
    });
  }

  newServiceRequestListData(data) {
    this.newServiceRequestTypes = data.body.types;
  }

  raiseThisServiceRequest(serviceReqType) {
    switch (serviceReqType.type.key) {
      case 'AMR':
        this.router.navigate(['./accountModificationRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'SLR':
        this.router.navigate(['./loanRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'BSR':
        this.router.navigate(['./blueSkyRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'DPB':
        this.router.navigate(['./depositBookRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'FBR':
        this.router.navigate(['./fixedDepositBreakage'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'RTD':{
        const renewAccountList = this.accountsIterator.getFixedDepositAccounts();
        this.handleServiceRequest(renewAccountList.length, './renewFixedDeposit', 'a fixed deposit account');
        break;}
      case 'OLC':{
        const overDraftAccountList = this.accountsIterator.filterkeyValType('overDraft');
        this.handleServiceRequest(overDraftAccountList.length, './overDraftRequest', 'an overdraft account');
        break;}
      case 'CHK':
        this.router.navigate(['./chequeBookRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'SCR':
        this.router.navigate(['./stopChequeRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'ADR':
        this.router.navigate(['./atmDebitCardRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'LAR':
        this.router.navigate(['./eBankingLimitRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'ODR':
        this.router.navigate(['./bankCertifiedCheque'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'ALA':
        this.router.navigate(['./atmLimitRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'CCR':
        this.router.navigate(['./creditCardRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'SAR':
        this.router.navigate(['./renewSibekeloAccountRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'IAI':
        this.router.navigate(['./requestInsurance'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'IRR':
        this.router.navigate(['./fixedDepositIntrestRate'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'CNG':
        this.noticeAccoutList = this.accountsIterator.filterkeyValType('noticeAccount');
        this.handleServiceRequest(this.noticeAccoutList.length, './cancelNoticeGivenAccount', 'a notice deposit account');
        break;
      case 'NWN':
        this.noticeAccoutList = this.accountsIterator.filterkeyValType('noticeAccount');
        this.handleServiceRequest(this.noticeAccoutList.length, './giveNoticeOnNoticeDeposit', 'a notice deposit account');
        break;
      case 'LOR':
        this.router.navigate(['./loanRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      case 'POS':
        this.router.navigate(['./POSReversal'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
      default:
        this.router.navigate(['./accountModificationRequest'], {
          relativeTo: this.activatedRoute.parent,
        });
        break;
    }
  }

  handleServiceRequest(isServiceExist, pageLink, errorMsg) {
    if (isServiceExist) {
      this.router.navigate([pageLink], {
        relativeTo: this.activatedRoute.parent,
      });
    } else {
      this.dataSharingService.setErrorModal(true);
      this.dataSharingService.setErrorMessage(`You do not have ${errorMsg}`);
    }
  }
}
