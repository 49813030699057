export enum ReceiptHeaders {
  ACCOUNT_NUMBER = 'Account Number',
  ADDRESS = 'Address',
  AMOUNT = 'Amount',
  AVAILABLE_BALANCE = 'Available balance',
  BANK_NAME = 'Bank Name',
  BENEFICIARY_ADDRESS = 'Beneficiary Address',
  BENEFICIARY_NAME = 'Beneficiary Name',
  BENEFICIARY_ACCOUNT_NUMBER = 'Beneficiary Account Number',
  BILL_DESCRIPTION = 'Bill Description',
  BILLER_CODE = 'Biller Code',
  BUSINESS_PARTNER_NAME = 'Business Partner Name',
  BRANCH_NAME = 'Branch Name',
  CARD_HOLDER_NAME = 'Card Holder Name',
  CARD_NUMBER = 'Card Number',
  COMPANY_REFERENCE = 'Company Reference',
  COUNTRY = 'Country',
  DATA_NUMBER = 'Data Number',
  DATE = 'Date',
  DOMESTIC_TAX_OFFICE = 'Domestic Tax Office',
  ENTITY_NUMBER = 'Entity Number',
  FILE_NAME = 'File Name',
  FROM = 'From',
  FROM_ACCOUNT = 'From Account',
  IMTT_CHARGE_APPLICABLE = 'IMTT Charge Applicable',
  MAX_AMT_LIMITS = 'Max. Daily ATM Limit',
  MAX_POS_LIMITS = 'Max. Daily POS Limit',
  METER_NUMBER = 'Meter Number',
  MOBILE_NUMBER = 'Mobile Number',
  MOBILE_WALLET_NUMBER = 'Mobile Wallet Number',
  MOBILE_WALLET_OPERATOR = 'Mobile Wallet Operator',
  RECIPIENT_NICK_NAME = '',
  WALLET_OPERATOR = 'Wallet Operator',
  MY_REFERENCE = 'My Reference',
  PASSPORT_NUMBER = 'Passport Number',
  PAYPULSE_NUMBER = 'PayPulse Number',
  PAYMENT_PRIORITY = 'Payment Priority',
  PROCESSING_DATE = 'Processing Date',
  PRODUCT_TYPE = 'Product type',
  PURPOSE_CODE = 'Purpose Code',
  PURPOSE_OF_FUNDS = 'Purpose of funds',
  PURPOSE_OF_PAYMENT = 'Purpose of payment',
  PROVINCE = 'Province',
  PORT_OF_ENTRY = 'Port of Entry',
  REFERENCE_ID = 'Reference ID',
  REFERENCE_NUMBER = 'Reference Number',
  CONTROL_NUMBER = 'Control Number',
  SERVICE_PROVIDER = 'Service Provider',
  SUB_PURPOSE_CODE = 'Sub Purpose Code',
  SWIFT_CODE = 'Swift Code',
  THEIR_REFERENCE = 'Their Reference',
  TO = 'To',
  TOTAL_AMOUNT = 'Total Amount',
  TRANSACTION_AMOUNT = 'Transaction Amount',
  TRANSACTION_ID = 'Transaction ID',
  UNAYO_NUMBER = 'Unayo Account Number',
  TRANSACTION_TYPE = 'Transaction Type',
  TAX_MODE = 'Tax Mode',
  TYPE = 'Type',
  VOUCHER_NAME = 'Voucher Number',
  AMOUNT_IN_LOCAL_CURRENCY = 'Amount in Local Curreny',
  EXCHANGE_RATE = 'Exchange Rate',
  CUSTOMER_NAME = 'Customer Name',
  TRADE_ID = 'Trade ID',
  INVOICE_TYPE = 'Invoice Type',
  INSTITUTION_NAME = 'Institution Name',
  NAME = 'Name',
  EMAIL = 'Email ID',
  FILE_FORMAT = 'File format',
  DOWNLOAD_AS = 'Download as',
  E_STAMPING = 'E-Stamping',
  RECEIVER_NAME = 'Receiver Name',
  RECIEVER_ADDRESS = "Receiver's Address",

  BENEFICIARY_RECEIVES = 'Beneficiary Receives',
  AMOUNT_DEBITED = 'Amount Debited',
  BENEFICIARY_COUNTRY = 'Beneficiary country',
  TRANS_FEE_PAID_BY = 'Transaction Fee to be paid by',

  BILLER_NAME = 'Biller Name',
  AMOUNT_PAID = 'Amount Paid',

  OVERDRAFT_FEES = 'Fees',
  OVERDRAFT_AMOUNT = 'Overdraft Amount',
  OVERDRAFT_INTEREST = 'Interest Rate',
  OVERDRAFT_TERM = 'Term',
  OVERDRAFT_DATE = 'Expiry Date',
  OVERDRAFT_ACCOUNT = 'Credit Account',
  TIN_NUMBER = 'TIN number',
  ASSESSMENT_NUMBER = 'Assessment Number',
  TP_NAME = 'Taxpayer Name',
  VALUE_DATE = 'Request Date',
  TRANSACTION_DATE = 'Transaction Date',
  REMARK = 'Remarks',
}
