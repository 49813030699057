import { Directive, ElementRef, HostListener } from '@angular/core';
import { environment } from '../../../../environments/environment';
@Directive({
  selector: 'input[numbersOnly]',
})
export class NumbersOnlyDirective {
  config = environment.config;
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange(event) {
    if(this.config.countryName !== 'Tanzania'){
      const initalValue = this.el.nativeElement.value;
      this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
      if (parseFloat(initalValue) !== parseFloat(this.el.nativeElement.value)) {
        event.stopPropagation();
      }
    }else{
      const initalValue = this.el.nativeElement.value;
      this.el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9]*/g, '');
      if (parseFloat(initalValue) !== parseFloat(this.el.nativeElement.value)) {
        event.stopPropagation();
      }
    }
  }
}
