import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  /*  transform(value: any, args?: any): any {
     return null;
   } */

  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    if (!args) {
      return value;
    }

    args = args.toLowerCase();

    const filteredAccounts = [];
    const accountsClone = _.cloneDeep(value);
    accountsClone.forEach((account) => {
      if (JSON.stringify(account).toLowerCase().includes(args)) {
        const transactions = account.TransDetails.filter((transaction) => {
          try {
            return transaction.description.toLowerCase().includes(args);
          } catch (error) {
            console.error('error', error);
          }
        });

        if (transactions && transactions.length) {
          account.TransDetails = transactions;
          filteredAccounts.push(account);
        }
      }
    });
    return filteredAccounts;

    /* return value.filter(function (item: any) {
       console.log('JSON.stringify(item)', JSON.stringify(item));
       console.log('JSON.stringify(item).toLowerCase()', JSON.stringify(item).toLowerCase());
       console.log('JSON.stringify(item).toLowerCase().includes(args)', JSON.stringify(item).toLowerCase().includes(args));
      return JSON.stringify(item).toLowerCase().includes(args);
    }); */
  }
}
