import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { DownloadService } from '../../../core/download-service/download.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-individual-receipt',
  templateUrl: './individual-receipt.component.html',
  styleUrls: ['./individual-receipt.component.scss'],
})
export class IndividualReceiptComponent implements OnInit {
  bankLogo: string;
  individualReceiptData;
  printData;
  fromAccount;
  accounts;

  config = environment.config;
  isBillerCDI = this.config.isBillerCDI;

  constructor(
    private dataSharingService: DataSharingService,
    private accountsIterator: AccountsIteratorService,
    private appConstant: AppConstantService,
    private downloadService: DownloadService
  ) {
    this.accounts = this.accountsIterator.getAllAccounts();
  }

  ngOnInit() {
    this.dataSharingService.getindividualReceiptData().subscribe((value) => {
      this.individualReceiptData = value;
    });

    if (this.individualReceiptData.fromAccount) {
      this.fromAccount = _.find(this.accounts, { number: this.individualReceiptData.fromAccount.number });
    }

    this.bankLogo = this.appConstant.BankLogo;
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Receipt',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: false,
      module: 'receipts',
    });
  }

  printReceipt() {
    this.downloadService.printReceipt();
  }

  downloadReceipt() {
    this.downloadService.downloadReceipt();
  }
}
