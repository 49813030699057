import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-amount',
  templateUrl: './payment-amount.component.html',
  styleUrls: ['./payment-amount.component.scss']
})
export class PaymentAmountComponent {

  @Input() amount: number;
  @Input() currency: string;

}
