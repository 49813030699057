//   import {ActionReducer, Action} from '@ngrx/store';
//   import {RootStore, initialRootStore} from '../state/root-state';
//   import {State, intitialState} from '../state/main-state';
//   import { loggingStatus, LOGGING_IN, LOGGING_OUT, SESSION_DATA, LoginActions, sessionData } from '../actions/main-action-creator';
//   import thunk from 'redux-thunk';

//   export const loginReducer: ActionReducer<RootStore[]> =
//     (state = [], action: LoginActions) => {

//       switch (action.type) {
//         case LOGGING_IN: {
//           const auth = action.payload;
//           // return Object.assign({}, state, { loggedIn:true });
//           return {
//             ...state,
//             loggedIn: true,
//             authenticateResponse: auth.authenticateResponse,
//             token: auth.token,
//             user: auth.loginUser
//           };
//         }
//         case LOGGING_OUT: {
//           return {
//             ...state,
//             loggedIn: false
//           };
//         }
//         case SESSION_DATA: {
//           return {
//             ...state,
//             sessionData : action
//           };
//         }
//         default: {
//           return state;
//         }
//       }
//     };

import { createAction, props } from '@ngrx/store';
// import {ElectricityData, SelectedElectricityData, ElectricityReceiptData} from '../interfaces/buy.model';

// export const setSelectedElectricityData = createAction('[ElectricityList Component] selectElectricity',
//                 props<{selectedElectricityData: SelectedElectricityData}>());

// export const setElectricityReceiptListData = createAction('[ElectricityList Component] setReceiptList',
//                 props<{receiptList: Array<any>}>());

// export const setElectricityData = createAction('[ElectricityList Component] setElectricityData',
//                 props<{electricityData: ElectricityData}>());

// export const setElectricityReceiptData = createAction('[ElectricityList Component] setElectricityReceiptData',
//                 props<{electricityReceiptData: ElectricityReceiptData}>());

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoginData {}

export const setLoginData = createAction(
  '[LoginComponent] setLoginData',
  props<{ login: LoginData }>()
);
export const setLogoutData = createAction(
  '[LoginComponent] setLogoutData',
  props<{ login: LoginData }>()
);
export const setSessionData = createAction(
  '[LoginComponent] setSessionData',
  props<{ sessionData: LoginData }>()
);
export const isSmeMode = createAction(
  '[LoginComponent] isSmeMode',
  props<{ isSmeMode: LoginData }>()
);
export const isfirstTimeLogin = createAction(
  '[LoginComponent] isfirstTimeLogin',
  props<{ isfirstTimeLogin: LoginData }>()
);
export const forcedSecurityQuestionReset = createAction(
  '[LoginComponent] forcedSecurityQuestionReset',
  props<{ forcedSecurityQuestionReset: LoginData }>()
);
export const userName = createAction(
  '[LoginComponent] userName',
  props<{ userName: LoginData }>()
);
export const eapLimitScheme = createAction(
  '[LoginComponent] eapLimitScheme',
  props<{ eapLimitScheme: LoginData }>()
);
