import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';

import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { CreatePayloadService } from '../../../../core/create-payload/create-payload.service';
import { setSelfTransferReceiptData } from '../../../../shared/store-utilities/actions/selfTransfer.action';
import * as _ from 'lodash';

import { Store } from '@ngrx/store';
import { McaHeader } from '../../../../core/data/mca-header';
import { McaResponseType } from '../../../../core/data/mca-response-type';

@Component({
  selector: 'sbg-self-fund-confirm',
  templateUrl: './self-fund-confirm.component.html',
  styleUrls: ['./self-fund-confirm.component.scss'],
})
export class SelfFundConfirmComponent implements OnInit {
  reviewModel;
  reviewHeaderObj;
  mainHeading;
  selfAccountFormData;
  isSmeMode;
  isSmeSoleProp;
  buttonText;
  config = environment.config;
  showPaymentPriority: any;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private createPayloadService: CreatePayloadService,
    private store: Store<{ appReducer }>
  ) {
    store.select('appReducer', 'selfTransferReducer').subscribe((stateData) => {
      this.selfAccountFormData = _.cloneDeep(stateData.selfAccountFormData);
    });
    store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.authenticateResponse) {
        const userIdentity = stateData.authenticateResponse;
        this.isSmeSoleProp = userIdentity.userProfile.isSmeSoleProprietor;
      }
      this.isSmeMode = stateData.isSmeMode;
    });

    this.mainHeading = this.selfAccountFormData.currentFlow === 'new-transaction' ? 'Transfer' : 'Transfer';

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Review',
      showBack: false,
      steps: true,
      module: 'selfFundTransferDetails',
    });
    this.setButtonText();
  }

  ngOnInit() {
    if (this.selfAccountFormData) {
      this.reviewModel = this.selfAccountFormData;
    }

    this.reviewHeaderObj = {
      name: this.reviewModel.selectedToAccount.customName,
      first: this.reviewModel.selectedToAccount.accountType,
      second: this.reviewModel.selectedToAccount.number,
    };
    if (this.config.countryName === 'Zimbabwe') {
      this.showPaymentPriority = true;
    }
  }

  goBack() {
    return this.router.navigate(['./payments/selfFund/details']);
  }

  setButtonText(): void {
    if (this.isSmeMode && !this.isSmeSoleProp) {
      this.buttonText = 'Send for Approval';
    } else if (this.selfAccountFormData.currentFlow === 'edit-transaction') {
      this.buttonText = 'Update';
    } else {
      this.buttonText = 'Transfer';
    }
  }

  goToConfirmation(): void {
    // this.changePositionFieldMoneyTransferTaxCode();
    const payload = this.createPayloadService.getSelfFundTransfer(this.reviewModel);
    this.postService(payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
    if (this.dataSharingService.selectedApprover) {
      this.dataSharingService.selectedApprover = null;
    }
    /* this.transactionService.getTransactions(payload).subscribe((response: any) => {
        response.payload = payload;
        response.reviewModel = this.reviewModel;

        this.store.dispatch(setSelfTransferReceiptData({ setSelfAccTransferReceiptData: response }));

        this.router.navigate(['/payments/selfFund/receipt'], { relativeTo: this.activatedRoute });
    }); */
  }
  changePositionFieldMoneyTransferTaxCode() {
    if (
      this.config.intermediatedMoneyTransferTax &&
      this.reviewModel.transactions.beneficiaryPayments
    ) {
      delete this.reviewModel.transactions.beneficiaryPayments[0].beneficiary.moneyTransferTaxCode;
      delete this.reviewModel.transactions.beneficiaryPayments[0].beneficiary.taxCodeMessage;
    }
  }

  postService(payload) {
    return this.transactionService.getTransactions(payload).subscribe((response: any) => {
      if (response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS) {
        response.payload = payload;
        response.reviewModel = this.reviewModel;

        this.store.dispatch(setSelfTransferReceiptData({ setSelfAccTransferReceiptData: response }));

        this.router.navigate(['/payments/selfFund/receipt'], { relativeTo: this.activatedRoute });
      }
    });
  }

  goToEditConfirmation(): void {
    const dataForSchedulePayload: any = {
      scheduledTransaction: this.transactionService.getEditSchedulePayload(this.reviewModel, this.reviewModel.editData.type),
    };

    if (this.dataSharingService.selectedApprover) {
      dataForSchedulePayload.financialRule = this.dataSharingService.selectedApprover;
      this.dataSharingService.selectedApprover = null;
    }

    this.postServiceEdit(dataForSchedulePayload);
    this.dataSharingService.setPostService(this.postServiceEdit.bind(this));
  }

  postServiceEdit(payload) {
    return this.transactionService.getEditTransactions(payload).subscribe((response: any) => {
      response.payload = payload;
      response.reviewModel = this.reviewModel;
      this.store.dispatch(setSelfTransferReceiptData({ setSelfAccTransferReceiptData: response }));

      this.router.navigate(['../receipt'], {
        relativeTo: this.activatedRoute,
      });
    });
  }
}
