import { NotificationDetailsState } from './../../store-utilities/state/notification.state';
import { Subscription, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { setSelectedTab } from '../../store-utilities/actions/payment.action';
import { setSelectedApprovalTab } from '../../store-utilities/actions/approval.action';
import { Select } from '@ngxs/store';
import { DeviceDetectorService } from '../../../services/device-detector/device-detector.service';
import { Modules, SubHeader } from '../../../core/masking-service/masking-constants';
import { MaskingService } from '../../../core/masking-service/masking.service';
import { setSelectedBuy } from '../../store-utilities/actions/buy.action';

@Component({
  selector: 'sbg-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SubHeaderComponent implements OnInit, OnChanges, OnDestroy {
  @Select(NotificationDetailsState.getNotificationDetails) $subscriptionNotify: Observable<any>;
  @Input() accountHolder: any;
  @Input() accountnumber: any;
  @Input() headerName: any;
  @Input() overflowNameRemove: boolean;
  notification = '';
  env = environment;
  config = environment.config;
  readOnlyMode: boolean;
  isSmeMode = false;
  subscriptionsNotify: Subscription;
  subscriptionsReadOnly: Subscription;
  subscriptionSme: Subscription;
  sendMoneyButtonText;
  screenSize: string;
  subHeaderLinks: any = [
    {
      feature: SubHeader.PAY_AND_TRANSFER,
      name: 'Pay & Transfer',
      classes: '',
      onClickNavFunction: this.goToPayments.bind(this),
    },
    {
      feature: SubHeader.APPROVE,
      name: 'Approve',
      classes: '',
      onClickNavFunction: this.goToApprovals.bind(this),
    },
    {
      feature: SubHeader.BUY_PREPAID,
      name: this.config.VAS_Header,
      classes: '',
      onClickNavFunction: this.goToBuy.bind(this),
    },
    {
      feature: SubHeader.INSTANT_MONEY,
      // name: (this.config.localCurrency === 'NAD') ? 'Blue Voucher' : this.config.instantMoneySubHeader,
      name: this.config.instantMoneySubHeader,

      classes: '',
      onClickNavFunction: this.goToSend.bind(this),
    },
  ];
  maskFinancialServices: boolean;
  

  constructor(
    private router: Router,
    private dataSharing: DataSharingService,
    private maskingService: MaskingService,
    private store: Store<any>,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;
    });
  }

  ngOnInit() {
    this.subHeaderLinks = this.maskingService.removeMaskedFeatures(this.subHeaderLinks, Modules.SUB_HEADER);

    this.subscriptionsNotify = this.$subscriptionNotify.subscribe((data) => {
      if (data) {
        this.notification = data.notification;
      }
    });
    this.subscriptionsReadOnly = this.dataSharing.getReadOnlyMode().subscribe((value) => {
      this.readOnlyMode = value;
    });
    this.subscriptionSme = this.store.select('appReducer', 'loginReducer').subscribe((state) => {
      this.isSmeMode = state.isSmeMode;
    });

    this.sendMoneyButtonText = 'Instant Money';
    if (this.config.countryName === 'Malawi') {
      this.sendMoneyButtonText = 'Quickash';
    } else if (this.config.localCurrency === 'NAD') {
      this.sendMoneyButtonText = 'Send Money';
      if(this.isSmeMode){
        this.sendMoneyButtonText = 'Blue Voucher';
      }
    }

    this.dataSharing.getMaskFinancialFlows().subscribe((data) => {
      this.maskFinancialServices = data;
    });
  }

  ngOnChanges(change: any) {
    if (change.headerName && this.headerName) {
      this.accountHolder = this.headerName;
    }
  }

  navigateToDashPage(): void {
    this.router.navigate(['/dashboard']);
  }

  goToPayments(): void {
    if (this.screenSize === 'small') {
      this.router.navigate(['/menu/payments']);
    } else {
      this.router.navigate(['/payments/beneficiary/list']);
    }

    this.store.dispatch(setSelectedTab({ selectedTab: 0 }));
  }

  goToInstantMoney() {
    this.router.navigate(['/instantMoney/new']);
  }

  goToSend() {
    if (this.env) {
        this.router.navigate(['/sendMoney/cancelInstantMoney/list']);
    }
  }

  goToBuy(): void {
    if (this.screenSize === 'small') {
      this.router.navigate(['/menu/buy']);
    } else {
        this.router.navigate(['/buy/airtime/list']);
    }
    this.store.dispatch(setSelectedBuy({ selectedIndex: 0 }));
  }

  goToApprovals(): void {
    this.store.dispatch(setSelectedApprovalTab({ selectedApprovalTab: { selectedTab: 0, selectedSubTab: 0 } }));
    this.router.navigate(['./approvals']);
  }

  goToNofications() {
    this.router.navigate(['/notification']);
  }

  ngOnDestroy() {
    this.subscriptionsNotify.unsubscribe();
    this.subscriptionsReadOnly.unsubscribe();
    this.subscriptionSme.unsubscribe();
  }
}
