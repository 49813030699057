import { Component, Input } from '@angular/core';

@Component({
  selector: 'sbg-account-icon',
  templateUrl: './account-icon.component.html',
  styleUrls: ['./account-icon.component.scss'],
})
export class AccountIconComponent {
  @Input() accountType: string;
  private iconClasses: {};
}
