import { environment } from '../../../../environments/environment';
import { Component, Input } from '@angular/core';
@Component({
  selector: 'sbg-limit-display',
  templateUrl: './limit-display.component.html',
  styleUrls: ['./limit-display.component.scss'],
})
export class LimitDisplayComponent {
  @Input() withdrawalLimit: any;
  @Input() usedLimit: any;

  config = environment.config;

 
}
