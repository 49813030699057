import { createReducer, on } from '@ngrx/store';

import * as paymentActions from '../actions/payment.action';
export const initialState = 0;

const addBeneReducer = createReducer(
  initialState,
  on(paymentActions.addBene, (state) => state + 1)
);

export function addBeneficiaryReducer(state, action) {
  return addBeneReducer(state, action);
}

interface OnceOffInitState {
  onceOffData: {};
}
const onceOffBeneData: OnceOffInitState = {
  onceOffData: {},
};
export const onceOffPaymentsReducer = createReducer(
  onceOffBeneData,
  on(paymentActions.onceOffBeneficiaryPayments, (state, { onceOffData }) => ({
    ...state,
    onceOffData,
  }))
);

interface BillerInitState {
  selectedBiller: any;
  payBillerData: {};
  payBillerRecieptData: {};
}

const initData: BillerInitState = {
  selectedBiller: [],
  payBillerData: {},
  payBillerRecieptData: {},
};

export const billerPaymentsReducer = createReducer(
  initData,
  on(paymentActions.selectBillerData, (state, { selectedBiller }) => ({
    ...state,
    selectedBiller,
  })),
  on(paymentActions.setPayBillerData, (state, { payBillerData }) => ({
    ...state,
    payBillerData,
  })),
  on(paymentActions.setPayBillerRecieptData, (state, { payBillerRecieptData }) => ({
    ...state,
    payBillerRecieptData,
  }))
);

const selectedTabInitData = {
  selectedTab: 0,
};

export const selectedPaymentsTabReducer = createReducer(
  selectedTabInitData,
  on(paymentActions.setSelectedTab, (state, { selectedTab }) => ({
    ...state,
    selectedTab,
  }))
);

const selectedVasTypeData = {
  selectedVasType: {},
};
export const selectedVasTypeReducer = createReducer(
  selectedVasTypeData,
  on(paymentActions.setSelectedVasType, (state, { selectedVasType }) => ({
    ...state,
    selectedVasType,
  }))
);

const exchangeRateValue = {
  exchangeRate: '',
};
export const exchangeRateReducer = createReducer(
  exchangeRateValue,
  on(paymentActions.setExchangeRateData, (state, { exchangeRate }) => ({
    ...state,
    exchangeRate,
  }))
);
