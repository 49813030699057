import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ApproverDataService {
  structuredData = {};
  approvalRulesData;
  constructor(private router: Router) {}

  public dataStructuring(dataResponse) {
    this.approvalRulesData = dataResponse;
    this.structuredData = {};

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dataResponse.forEach((value, i) => {
      if (!this.structuredData[value.transactionType]) {
        this.structuredData[value.transactionType] = [];
        const temp = {};
        temp[value.transactionType] = [];
        this.structuredData = { ...this.structuredData, ...temp };
        // let transactionType = value.transactionType;
        // this.structuredData = {...this.structuredData, };
      }
      this.structuredData[value.transactionType].push(value);
    });
    return this.structuredData;
  }
}
