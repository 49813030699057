import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';
import { ConfirmationScreenService } from '../../../../core/confirmation-screen-service/confirmation-screen.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { CmaRecipientService } from '../../../../core/recipient-service/cma-recipient.service';
import { RecipientManagementRequest } from '../../../../core/recipient-service/data/recipient-management-request';
import { CmaPayment } from '../../../../core/transaction-service/data/cma-payment';
import { PaymentSchedule } from '../../../../core/transaction-service/data/payment-schedule';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';
import { TransactionsResponse } from '../../../../core/transaction-service/data/transactions-response';
import { SmeHelperService } from '../../../../services/sme-helper/sme-helper.service';
import { SnackBarService } from '../../../../services/snack-bar-service/snackBarService';
import { Amount } from '../../../../core/data/amount';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { DateUtil } from '../../../../core/utility-classes/date.util';

@Component({
  selector: 'app-cma-beneficiary-payment-receipt',
  templateUrl: './cma-beneficiary-payment-receipt.component.html',
  styleUrls: ['./cma-beneficiary-payment-receipt.component.scss'],
})
export class CmaBeneficiaryPaymentReceiptComponent implements OnInit {
  transactionRequest: TransactionsRequest;
  transactionResponse: TransactionsResponse;
  payment: CmaPayment;
  isPaymentScheduled = false;
  isOnceOff = false;
  showAddBeneficiaryButton = true;
  bankLogo: string;
  date = new Date();
  paymentCountDescription = {
    value: 'Just Once',
    placeholder: 'once',
    id: '1',
  };
  printData;
  private isSme = false;
  private isSmeSoleProprietor = false;
  private isEntityNumberDisplayed = false;
  private readonly TRANSACTION_RESULT_KEY_REFERENCE = 'REFERENCE';

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private cmaRecipientService: CmaRecipientService,
    private snack: SnackBarService,
    private confirmationScreenService: ConfirmationScreenService,
    private smeHelper: SmeHelperService
  ) {}

  ngOnInit() {
    this.isSme = this.smeHelper.isSmeMode();
    this.isSmeSoleProprietor = this.smeHelper.isUserSmeSoleProprietor();
    this.isEntityNumberDisplayed = 
      environment.config.payments.cmaBeneficiaryPayment.isEntityNumberDisplayed && this.isSme && !this.isSmeSoleProprietor;
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay',
      subTitle: 'Receipt',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'payCmaBeneficiary',
    });
    if (this.dataSharingService.showBeneficiaryAddedToast) {
      this.dataSharingService.showBeneficiaryAddedToast = false;
      this.showAddBeneficiaryButton = false;
    }
    if (this.dataSharingService.dataFromThirdStep) {
      this.transactionRequest = this.dataSharingService.dataFromThirdStep;
    } else {
      this.transactionRequest = this.dataSharingService.dataFromFirstStep;
    }
    this.transactionResponse = this.dataSharingService.dataFromSecondStep;
    if (this.transactionRequest.transactions.cmaPayments) {
      this.payment = this.transactionRequest.transactions.cmaPayments[0];
    }
    if (this.transactionRequest.transactions.onceOffCmaPayments) {
      this.isOnceOff = true;
      this.payment = this.transactionRequest.transactions.onceOffCmaPayments[0];
    }
    this.checkPaymentSchedule(this.payment.futureDatedInstruction);
  }

  get passNumLabel(): string {
    return this.isEntityNumberDisplayed ? ReceiptHeaders.ENTITY_NUMBER : ReceiptHeaders.PASSPORT_NUMBER;
  }

  showAddBeneficiaryPopup() {
    if (this.isSme && !this.isSmeSoleProprietor) {
      const decision = this.confirmationScreenService.showConfirmationScreen(
        'Add Beneficiary',
        true,
        'BENEFICIARY_MANAGEMENT',
        'Please select approver'
      );
      decision.subscribe((value) => {
        if (value) {
          this.addBeneficiary(this.dataSharingService.selectedApprover);
        }
      });
    } else {
      this.addBeneficiary();
    }
  }

  navigateToNewPayment() {
    if (this.dataSharingService.dataFromFirstStep) {
      this.dataSharingService.dataFromThirdStep = this.dataSharingService.dataFromFirstStep;
      this.dataSharingService.dataFromFirstStep = undefined;
    }
    return this.router.navigate(['/payments/cma/list']);
  }

  getTransactionReference() {
    const reference = this.transactionResponse.transactionResults[0].transactionResultMetaData.find(
      (value) => value.transactionResultKey === this.TRANSACTION_RESULT_KEY_REFERENCE
    );
    if (reference) {
      return reference.value;
    }
    return undefined;
  }

  getStatus(): string {
    return this.transactionResponse.transactionResults[0].responseCode.responseType;
  }

  getMessage(): string {
    return this.transactionResponse.transactionResults[0].responseCode.message;
  }

  getAmount(): Amount {
    return this.payment.amount;
  }

  getReceiptHeaders(): string[] {
    return [this.payment.beneficiary.name, this.payment.beneficiary.route.branch.bank.name + ' | ' + this.payment.beneficiary.accountNumber];
  }

  getReceiptDetails(): ReceiptDetail[] {
    const resultData = [];
    resultData.push({
      header: ReceiptHeaders.TRANSACTION_ID,
      value: this.getTransactionReference(),
    });
    if (this.transactionRequest.account && this.transactionRequest.account.accountFullName) {
      resultData.push({
        header: ReceiptHeaders.FROM,
        value: this.transactionRequest.account.accountFullName || '-',
      });
    }
    resultData.push(
      {
        header: ReceiptHeaders.DATE,
        value: formatDate(this.date, 'dd MMMM yyyy', 'en-US'),
      },
      {
        header: ReceiptHeaders.MY_REFERENCE,
        value: this.payment.beneficiary.customerReference,
      },
      {
        header: ReceiptHeaders.THEIR_REFERENCE,
        value: this.payment.beneficiary.recipientReference,
      },
      {
        header: this.passNumLabel,
        value: this.payment.passportNumber,
      },
      {
        header: ReceiptHeaders.ADDRESS,
        value:
          this.payment.beneficiary.address.streetName + ' ' + this.payment.beneficiary.address.town + ' ' + this.payment.beneficiary.address.city,
      },
      {
        header: ReceiptHeaders.PURPOSE_CODE,
        value: this.payment.purposeCode.value,
      },
      {
        header: ReceiptHeaders.SUB_PURPOSE_CODE,
        value: this.payment.subPurposeCode.value,
      }
    );
    return resultData;
  }

  private addBeneficiary(rule?: any) {
    this.dataSharingService.dataFromThirdStep = this.dataSharingService.dataFromFirstStep;
    this.saveBeneficiary({ beneficiaries: [this.payment.beneficiary], nonFinancialRule: rule });
    this.dataSharingService.setPostService(this.saveBeneficiary.bind(this));
    this.dataSharingService.selectedApprover = null;
  }

  private saveBeneficiary(request: RecipientManagementRequest) {
    this.cmaRecipientService.addCmaRecipient(request).subscribe((response) => {
      if (!response.body || !response.body.stepUp) {
        this.dataSharingService.showBeneficiaryAddedToast = true;
        this.snack.open(this.payment.beneficiary.name + ' added', this.snack.TYPE_SUCCESS);
        this.router.navigate(['/payments/cma/receipt']);
      }
    });
  }

  private checkPaymentSchedule(schedule: PaymentSchedule) {
    const today = new Date();
    this.isPaymentScheduled = !DateUtil.areSame(today, schedule.fromDate, 'days') && DateUtil.diff(today, schedule.fromDate) <= 0;
  }
}
