import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { PrepaidService } from '../../../../core/prepaid-service/prepaid-service.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { BankingMetadataService } from '../../../../core/banking-metadata/banking-metadata.service';
import { TaxPaymentModel } from '../tax-payment.model';
import { CreatePayloadService } from '../../../../core/create-payload/create-payload.service';
import { setTaxPaymentFormData } from '../../../../shared/store-utilities/actions/taxPayment.action';

@Component({
  selector: 'app-tax-payment-details',
  templateUrl: './tax-payment-details.component.html',
  styleUrls: ['./tax-payment-details.component.scss'],
})
export class TaxPaymentDetailsComponent implements OnInit {
  userAccounts: object[] = [];
  taxPaymentModel: TaxPaymentModel = new TaxPaymentModel();
  storedTaxPaymentModel: TaxPaymentModel;
  showTransferWindow = false;
  amountValidator = {};
  portOfEntryOrDomesticTaxCodes = [];
  regions: string[] = [];
  yearRange = [];
  taxOfficeCodesInput = true;
  taxPaymentTheirReference: string;
  isSmeMode: boolean;
  domesticRegions = {};
  customsRegions = {};
  fetchTaxOfficeCodes = {};
  fetchRegions = {
    DOMESTIC: [],
    CUSTOM: [],
  };
  taxMessage = {};
  taxTypeMessage = {
    DOMESTIC: {
      header: 'Domestic Tax:',
      desc: 'Taxes related to Payee, Withholding Tax, Corporate Tax, VAT etc.',
    },
    CUSTOM: {
      header: 'Customs:',
      desc: 'Taxes related to Import & Export',
    },
  };
  isDomesticDisabled = false;
  isCustomDisabled = true;

  constructor(
    private accountsIterator: AccountsIteratorService,
    private dataSharingService: DataSharingService,
    private prepaidService: PrepaidService,
    private bankingMetadata: BankingMetadataService,
    private createPayloadService: CreatePayloadService,
    private router: Router,
    private store: Store<any>
  ) {
    store.select('appReducer', 'taxPaymentReducer').subscribe((stateData) => {
      this.storedTaxPaymentModel = _.cloneDeep(stateData.taxPaymentModel);
      if (this.storedTaxPaymentModel) {
        this.taxPaymentModel = this.storedTaxPaymentModel;
      }
    });

    store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData) {
        this.isSmeMode = stateData.isSmeMode;
      }
    });

    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay & Transfer',
      subTitle: 'Details',
      showClose: true,
      showBack: false,
      steps: true,
      module: 'taxPayment',
    });
  }

  ngOnInit() {
    this.userAccounts = this.accountsIterator.getPaymentFromAccounts('all', 'TRANSFERFROM');
    if (this.taxPaymentModel.selectedFromAccount) {
      this.taxPaymentModel.selectedFromAccount = this.userAccounts[0];
      this.taxPaymentModel.taxPaymentAmount.currency = this.taxPaymentModel.selectedFromAccount.accountCurrency.currency;
    }
    this.taxPaymentModel.beneficiaryName = 'Zimbabwe Revenue Authority (ZIMRA)';
    this.fetchZimraTaxAccounts();
    this.fetchZimraTaxCodes();
    this.fetchZimraTaxOfficeCodes();
    this.fetchProviderDetails();
    this.taxTypeDisable(this.taxPaymentModel.taxMode);
    this.setAssessmentYear();
    this.setTheirRefence();
  }

  setBeneAcccountNumber(currency) {
    this.taxPaymentModel.beneficiaryAccountNumber = this.taxPaymentModel.zimraTaxAccounts[currency].number;
  }

  fetchZimraTaxAccounts() {
    this.bankingMetadata.getZimraTaxAccounts().subscribe((response) => {
      const tempAccounts = {};
      response.accounts.forEach((value) => {
        tempAccounts[value.accountCurrency.currencyCode] = value;
      });
      this.taxPaymentModel.zimraTaxAccounts = tempAccounts;
      this.setBeneAcccountNumber(this.taxPaymentModel.selectedFromAccount.accountCurrency.currency);
    });
  }

  fetchZimraTaxCodes() {
    this.bankingMetadata.getZimraTaxCodes().subscribe((response) => {
      this.taxPaymentModel.taxCodes = response.taxCodes;
    });
  }

  fetchZimraTaxOfficeCodes() {
    this.bankingMetadata.getZimraTaxOfficeCodes().subscribe((response) => {
      response.taxCodes.forEach((value) => {
        this.taxPaymentModel.taxOfficeCodes = response.taxCodes;
        value.type.forEach((type) => {
          // region
          if (this.fetchRegions[type].indexOf(value.region) === -1) {
            this.fetchRegions[type].push(value.region);
          }
          // tax office code
          if (!this.fetchTaxOfficeCodes[value.region]) {
            this.fetchTaxOfficeCodes[value.region] = {
              DOMESTIC: [],
              CUSTOM: [],
            };
          }
          this.fetchTaxOfficeCodes[value.region][type].push(value);
        });
      });
      this.domesticRegions = this.fetchRegions.DOMESTIC;
      this.customsRegions = this.fetchRegions.CUSTOM;
    });
  }

  accountDropDownChanged(account) {
    this.taxPaymentModel.selectedFromAccount = account;
    this.setBeneAcccountNumber(account.accountCurrency.currency);
  }

  fetchProviderDetails() {
    this.prepaidService.getProviderDetails().subscribe((response: any) => {
      const prepaidProviders = response.prepaidProviders;
      prepaidProviders.forEach((provider) => {
        if (provider.prepaidType === 'TAXES') {
          if (provider.prepaidProduct.range.min) {
            this.taxPaymentModel.providerDetails[provider.prepaidProduct.range.min.currency] = provider;
          } else if (provider.prepaidProduct.range.max) {
            this.taxPaymentModel.providerDetails[provider.prepaidProduct.range.max.currency] = provider;
          }
        }
      });
    });
  }

  setAssessmentYear() {
    let currentYear = new Date().getFullYear();
    let yearRange = 10;
    this.yearRange = [];
    while (yearRange > 0) {
      this.yearRange.push(currentYear--);
      yearRange--;
    }
  }

  taxModeChanged(taxMode) {
    this.taxPaymentModel.taxMode = taxMode;
    this.taxTypeDisable(taxMode);
    if (this.taxPaymentModel.taxMode === 'bpn' && this.taxPaymentModel.selectedTaxCode) {
      this.taxCodeChanged(this.taxPaymentModel.selectedTaxCode, true);
    }
    if (taxMode === 'an') {
      this.taxTypeChanged(this.taxPaymentModel.selectedTaxType);
    }
    this.setTheirRefence();
  }

  bpNumberChanged() {
    this.setTheirRefence();
  }

  assessmentNumberChanged() {
    this.setTheirRefence();
  }

  setTheirRefence() {
    switch (this.taxPaymentModel.taxMode) {
      case 'an':
        this.taxPaymentTheirReference = this.taxPaymentModel.assessmentNumber;
        break;
      case 'bpn':
        this.taxPaymentTheirReference = this.taxPaymentModel.businessPartnerNumber;
        break;
      default:
        this.taxPaymentTheirReference = '';
        break;
    }
  }

  regionChanged(region) {
    if (region) {
      const listOfCodes = [];
      this.fetchTaxOfficeCodes[region][this.taxPaymentModel.selectedTaxType].forEach((value) => {
        listOfCodes.push(value.taxCode);
      });
      this.portOfEntryOrDomesticTaxCodes = listOfCodes;
      if (!this.taxOfficeCodesInput) {
        this.taxPaymentModel.selectedTaxOfficeCode = this.portOfEntryOrDomesticTaxCodes[0];
      }
    } else {
      this.taxOfficeCodesInput = true;
    }
  }

  taxTypeDisable(taxMode) {
    switch (taxMode) {
      case 'an':
        this.isDomesticDisabled = true;
        this.isCustomDisabled = false;
        break;
      case 'bpn':
        if (this.taxPaymentModel.selectedTaxCode) {
          this.isDomesticDisabled = false;
          this.isCustomDisabled = false;
        } else {
          this.isDomesticDisabled = true;
          this.isCustomDisabled = true;
        }
        break;
    }
  }

  taxCodeChanged(taxCode, taxModeChanged) {
    this.taxPaymentModel.selectedTaxCode = taxCode;
    this.isDomesticDisabled = false;
    this.isCustomDisabled = true;
    taxCode.type.forEach((value) => {
      switch (value) {
        case 'CUSTOM':
          this.isCustomDisabled = false;
          break;
        case 'DOMESTIC':
          this.isDomesticDisabled = false;
          break;
      }
      if (!taxModeChanged) {
        this.taxPaymentModel.selectedTaxType = value;
        this.taxTypeChanged(value);
      }
    });
  }

  taxTypeChanged(taxType) {
    this.taxMessage = this.taxTypeMessage[taxType];
    this.regionChanged(this.taxPaymentModel.region);
  }

  goToConfirmation() {
    // call the validate vas input
    const ValidateVasPayload = this.createPayloadService.getValidTaxPaymentInputData(this.taxPaymentModel);

    this.prepaidService.validateVasInput(ValidateVasPayload).subscribe((response) => {
      if (response) {
        this.taxPaymentModel.taxPaymentAmount.currency = this.taxPaymentModel.selectedFromAccount.accountCurrency.currency;
        if (response.payer) {
          this.taxPaymentModel.businessPartnerName = response.payer;
        } else {
          delete this.taxPaymentModel.businessPartnerName;
        }
        if (response.amount) {
          this.taxPaymentModel.taxPaymentAmount.amount = response.amount.amount;
          this.taxPaymentModel.taxPaymentAmount.currency = response.amount.currency;
        }
        this.store.dispatch(setTaxPaymentFormData({ taxPaymentModel: this.taxPaymentModel }));
        this.router.navigate(['./payments/taxPayment/confirmation']);
      }
    });
  }

  goBack() {
    this.store.dispatch(setTaxPaymentFormData({ taxPaymentModel: null }));
    this.router.navigate(['menu/payments']);
  }
}
