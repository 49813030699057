import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BankingMetadataService } from '../../../core/banking-metadata/banking-metadata.service';

@Component({
  selector: 'sbg-exchange-rates',
  templateUrl: './exchange-rates.component.html',
  styleUrls: ['./exchange-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExchangeRatesComponent {
  _payerCurrency: string;
  _payeeCurrency: string;
  _payableAmount: any;
  rate: string;

  @Input()
  set payerCurrency(newCurrency: string) {
    this._payerCurrency = newCurrency;
    this.fetchExchangeRate();
  }

  get payerCurrency(): string {
    return this._payerCurrency;
  }

  @Input()
  set payeeCurrency(newCurrency: string) {
    this._payeeCurrency = newCurrency;
    this.fetchExchangeRate();
  }

  get payeeCurrency(): string {
    return this._payeeCurrency;
  }

  @Input()
  set payableAmount(newAmount: any) {
    this._payableAmount = parseFloat(newAmount);
    if (!this.rate) {
      this.fetchExchangeRate();
    }
  }

  get payableAmount(): any {
    return this._payableAmount;
  }

  constructor(private bankingMetadataService: BankingMetadataService, private ref: ChangeDetectorRef) {}

  fetchExchangeRate() {
    if (this.payableAmount && this.payerCurrency && this.payeeCurrency &&
        this.payerCurrency !== this.payeeCurrency) {

      const payload = {
        baseCurrency: this.payerCurrency,
      };

      this.bankingMetadataService.getExchangeRates(payload).subscribe((response) => {
        response.forEach((value) => {
          if (value.toCurrency === this.payeeCurrency) {
            if (value.toCurrency === value.fixedCurrency) {
              this.rate = (1 / value.rate).toFixed(5);
            } else {
              this.rate = value.rate;
            }
          }
        });
        this.ref.markForCheck();
      });
    }
  }
}
