import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchFilterPipe } from './../../shared/pipes/search-filter/search-filter.pipe';
import * as _ from 'lodash';
import { DateUtil } from '../../core/utility-classes/date.util';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss'],
  providers: [SearchFilterPipe],
})
export class ServiceRequestsComponent implements OnInit {
  previousServiceRequestsClone;
  previousServiceRequests;
  allServiceRequestTypesName = [] as any;
  allServiceRequestTypes = [] as any;
  allRequests = {
    key: '000',
    value: 'All requests',
  };
  maskNewServiceRequest: boolean;

  ifPaginationHide = true;
  resetFilter = false;

  searchText;
  toDate;
  fromDate;
  sortByVal;
  requestStatus;
  requestType;

  statusSent = {
    type: 'Sent',
    dependency: ['CLO', 'SUC', 'ENT', 'AAC'],
  };
  statusNotSent = {
    type: 'NotSent',
    dependency: ['SUS', 'FAL'],
  };
  statusRejected = {
    type: 'Reject',
    dependency: ['CAN', 'REJ', 'REC'],
  };
  statusInProgress = {
    type: 'InProgress',
    dependency: ['APR', 'WFR', 'PEN'],
  };

  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequest: ServiceRequestService,
    private router: Router,
    private searchFilter: SearchFilterPipe,
    private activatedRoute: ActivatedRoute
  ) {
    window['inner']('other');
  }

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Service Requests',
      subTitle: '',
      showBack: false,
      showClose: true,
      steps: false,
    });

    this.serviceRequest.getServiceRequests().subscribe((response) => {
      this.serviceRequestsList(response);
    });

    this.dataSharingService.getMaskFinancialFlows().subscribe((data) => {
      this.maskNewServiceRequest = data;
    });
  }

  serviceRequestsList(requests) {
    this.previousServiceRequests = _.cloneDeep(requests.body.requests);
    this.previousServiceRequests.forEach((element: any) => {
      let searchVal = '';
      const date = DateUtil.format(element.date, DateUtil.DD_MMM_YYYY);
      const val1 = element.type.value;
      searchVal = date + val1;
      element.searchVal = searchVal;
    });
    this.previousServiceRequests.sort(this.sortRequestsByDate);

    this.previousServiceRequestsClone = JSON.parse(JSON.stringify(this.previousServiceRequests));

    this.previousServiceRequests.forEach((element) => {
      if (element.type && element.type.key) {
        const keyName = element.type.key;
        if (this.allServiceRequestTypesName.indexOf(keyName) === -1) {
          this.allServiceRequestTypesName.push(keyName);
          this.allServiceRequestTypes.push(element.type);
        }
      }
    });

    this.allServiceRequestTypes.unshift(this.allRequests);
  }

  sortRequestsByDate(i, j) {
    if (DateUtil.isBefore(i.date, j.date)) {
      return 1;
    } else {
      return -1;
    }
  }

  goToNewServiceRequest() {
    this.router.navigate(['./newServiceRequest'], {
      relativeTo: this.activatedRoute,
    });
  }

  goToIndividualServiceRequest(requestData) {
    this.dataSharingService.setIndividualServiceRequestData(requestData);
    this.router.navigate(['./individualServiceRequest'], {
      relativeTo: this.activatedRoute,
    });
  }

  statementMainFilter(mainFilterValues: any) {
    if (mainFilterValues) {
      this.sortByVal = mainFilterValues.txnDateOrder;
      this.fromDate = new Date(mainFilterValues.fromDate);
      this.toDate = new Date(mainFilterValues.toDate);

      this.requestType = mainFilterValues.requestType;
      this.requestStatus = mainFilterValues.requestStatus;
      this.applyFilterFn();
    }
  }

  searchFilterFn(searchValue: any) {
    if (searchValue) {
      this.searchText = searchValue.searchText;
    }
  }

  // Apply final filter
  applyFilterFn() {
    // apply date from-to filter
    if (this.fromDate && this.toDate) {
      const start = this.fromDate.getTime();
      const end = this.toDate.getTime();
      if (start && end) {
        this.filterDate(this.previousServiceRequestsClone, start, end);
      } else {
        this.previousServiceRequests = JSON.parse(JSON.stringify(this.previousServiceRequestsClone));
      }
    } else {
      this.previousServiceRequests = JSON.parse(JSON.stringify(this.previousServiceRequestsClone));
    }

    // apply transaction in out filter
    if (this.requestStatus && this.requestStatus.value !== 'All') {
      if (this.previousServiceRequests && this.previousServiceRequests.length) {
        this.previousServiceRequests = this.previousServiceRequests.filter((ele) => {
          return this.requestStatus.dependency.indexOf(ele.status.key) > -1;
        });
      }
    }
    // apply transaction in out filter
    if (this.requestType && this.requestType.key !== '000') {
      if (this.previousServiceRequests && this.previousServiceRequests.length) {
        this.previousServiceRequests = this.previousServiceRequests.filter((ele) => {
          return this.requestType.key === ele.type.key;
        });
      }
    }
  }

  //  Filter : Date : from-to
  filterDate(accountsData: any, start: any, end: any) {
    const filteredData = [];
    const accountsClone = JSON.parse(JSON.stringify(accountsData));

    accountsClone.forEach((account) => {
      const requiredDate = account.date;
      const formattedDate = DateUtil.format(requiredDate, DateUtil.MMM_DD_YYYY);
      const d = new Date(formattedDate);
      const checkDate = d.getTime();
      const localOffset = d.getTimezoneOffset() * 60000;
      const checkDateEnd = d.getTime() + localOffset;

      if (checkDate >= start && checkDateEnd <= end) {
        filteredData.push(account);
      }
    });

    this.previousServiceRequests = filteredData;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  filterRequestStatus(data) {}

  //////////////////// Filter : end /////////////////////////////////
}
