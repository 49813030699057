import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { BankingMetadataService } from '../../../../core/banking-metadata/banking-metadata.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { setSelfAccountFormData } from '../../../../shared/store-utilities/actions/selfTransfer.action';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { environment } from '../../../../../environments/environment';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { NgForm, FormGroup } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { Account } from '@data/account';

@Component({
  selector: 'sbg-self-fund-details',
  templateUrl: './self-fund-details.component.html',
  styleUrls: ['./self-fund-details.component.scss'],
})
export class SelfFundDetailsComponent implements OnInit {
  @ViewChild('paymentDetailsForm') formRef: NgForm;
  readonly RCP = 'RCP';
  readonly PRCP = 'PRCP';
  readonly FEB_PRODUCT_CODE = 'FEB_PRODUCT_CODE';
  isPRCPAccount = false;
  mainHeading;

  withdrawalLimit;
  usedLimit;
  availableLimit = null;
  routeHistory: any[] = [];
  editReviewModel: any;

  monthlyLimits: any = {};

  toCurrency;

  isAmountEntered = false;

  availableMonthlyLimit: any;
  showIMTTMessage: any;
  taxCodeMessage: string;
  taxCodes: any;

  approversAmountErrorMsg = 'The amount entered is not in range. Please enter an amount within the approval amount range.';

  isHoliday: boolean;
  publicHolidays: { date: string };

  toAccounts: Account[] = [];
  fromAccounts: Account[] = [];
  preSelectFromAccount: Account;
  preSelectToAccount: Account;
  originalToAccounts: Account[];
  originalFromAccounts: Account[];
  isSmeSoleProp;
  isSmeMode;
  config = environment.config;

  isAllowedScheduledPayment = this.paymentUtilitiesService.checkIfScheduledPaymentsIsAllowedForTransactionType('SELF_FUNDS');

  scheduleFrequency = [
    { value: 'Just Once', placeholder: 'once', id: '1' },
    { value: 'Daily', placeholder: 'daily', id: '2' },
    { value: 'Weekly', placeholder: 'weekly', id: '3' },
    { value: 'Monthly', placeholder: 'monthly', id: '4' },
    { value: 'Quarterly', placeholder: 'quarterly', id: '5' },
    { value: 'Yearly', placeholder: 'yearly', id: '6' },
  ];

  selfFundModel: any = {
    fromAccounts: [],
    amount: '',
    reference: '',
    selectedFromAccount: null,
    selectedToAccount: null,
    requiredRate: 0,
    totalAmount: 0,
    paymentScheduleFrequency: {},
    currentDate: new Date(),
  };
  screenSize: string;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private accountsIteratorService: AccountsIteratorService,
    private bankingMetadataService: BankingMetadataService,
    private dataSharingService: DataSharingService,
    private paymentUtilitiesService: PaymentUtilitiesService,
    private routeState: RouterStateService,
    private store: Store<any>,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.routeHistory = this.routeState.getHistory();
    const prevUrl = _.last(this.routeHistory);

    if (prevUrl.search('confirm') !== -1) {
      store.select('appReducer', 'selfTransferReducer').subscribe((stateData) => {
        this.selfFundModel = _.cloneDeep(stateData.selfAccountFormData);
        this.isAmountEntered = true;
      });
    } else if (prevUrl.search('scheduleTransaction/receipt') !== -1) {
      store.select('appReducer', 'editTxnScheduleReducer').subscribe((stateData) => {
        this.editReviewModel = _.cloneDeep(stateData.editSelfFund);
        this.selfFundModel.currentFlow = 'edit-transaction';
        this.isAmountEntered = true;
      });
    } else {
      this.selfFundModel.currentFlow = 'new-transaction';
    }
    store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.authenticateResponse) {
        const userIdentity = stateData.authenticateResponse || {};
        this.isSmeSoleProp = userIdentity.userProfile ? userIdentity.userProfile.isSmeSoleProprietor : false;
      }
      this.isSmeMode = stateData.isSmeMode;
    });

    this.mainHeading = this.selfFundModel.currentFlow === 'new-transaction' ? 'Transfer' : 'Transfer';

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Details',
      showClose: true,
      showBack: false,
      steps: true,
      module: 'selfFundTransferDetails',
    });
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;

      this.dataSharingService.setHeadPagination({
        mainTitle: this.mainHeading,
        subTitle: 'Details',
        showClose: true,
        showBack: this.screenSize === 'small',
        steps: true,
        module: 'selfFundTransferDetails',
        showBackFn: this.goBackToMenu.bind(this),
      });
    });
  }

  get afcRequired(): boolean {
    return !this.selfFundModel.isPaymentScheduled && !(this.config.isBillerCDI && this.isPRCPAccount);
  }
  

  ngOnInit() {
    if (this.config.showMonthlyTransactionLimit && this.config.payments.selfFund.limitsForTransfer) {
      this.getMonthlyLimits();
    }

    this.selfFundModel.fromAccounts = this.accountsIteratorService.getPaymentFromAccounts(this.config.transferAccount, 'TRANSFERFROM');
    this.selfFundModel.toAccounts = this.accountsIteratorService.getPaymentFromAccounts(this.config.transferAccount, 'TRANSFERTO');
    if (this.config.localCurrency === 'SZL') {
      this.selfFundModel.toAccounts = this.accountsIteratorService.getPaymentFromAccounts('local', 'TRANSFERTO');
    }
    this.originalToAccounts = _.cloneDeep(this.selfFundModel.toAccounts);
    this.originalFromAccounts = _.cloneDeep(this.selfFundModel.fromAccounts);

    if (this.editReviewModel) {
      this.selfFundModel.editReviewModel = _.cloneDeep(this.editReviewModel);

      const editSelectedFromAccountDetails = _.find(this.selfFundModel.fromAccounts, {
        number: this.editReviewModel.fromAccount.number,
      });
      this.selfFundModel.selectedFromAccount = editSelectedFromAccountDetails || this.selfFundModel.fromAccounts[0];

      this.selfFundModel.reference = this.editReviewModel.customerReference;
      this.selfFundModel.amount = this.editReviewModel.scheduledAmount.amount;
      this.fromAccountChange(this.selfFundModel.selectedFromAccount);
    } else if (!this.selfFundModel.selectedFromAccount) {
      this.selfFundModel.selectedFromAccount = this.selfFundModel.fromAccounts[0];
      this.fromAccountChange(this.selfFundModel.selectedFromAccount);
    } else if (this.selfFundModel.selectedFromAccount) {
      const editSelectedFromAccountDetails = _.find(this.selfFundModel.fromAccounts, {
        number: this.selfFundModel.selectedFromAccount.number,
      });
      if (editSelectedFromAccountDetails) {
        this.selfFundModel.selectedFromAccount = editSelectedFromAccountDetails;
      }

      const editSelectedToAccountDetails = _.find(this.selfFundModel.toAccounts, {
        number: this.selfFundModel.selectedToAccount.number,
      });
      if (editSelectedToAccountDetails) {
        this.selfFundModel.selectedToAccount = editSelectedToAccountDetails;
      }
    }

    if (this.config.checkMultiCurrencyHoliday) {
      this.fetchPublicHolidaysList();
    }
    if (this.config.countryName === 'Zimbabwe') {
      this.fetchMoneyTransferTaxCodes();
    }
  }
  fetchMoneyTransferTaxCodes() {
    this.bankingMetadataService.getMoneyTransferTaxCode().subscribe((response) => {
      this.taxCodes = response.taxCodes;
      this.selfFundModel.moneyTransferTaxCode = this.taxCodes[8];
      this.moneyTransferTaxCodeChanged(response.taxCodes[8]);
      // if (this.isIMTTApplicable(this.selfFundModel.selectedFromAccount)) {
      //   if (!this.selfFundModel.moneyTransferTaxCode) {
      //     this.moneyTransferTaxCodeChanged(response.taxCodes[8]);
      //   }
      // } else {
      //   delete this.selfFundModel.moneyTransferTaxCode;
      // }
    });
  }

  // isIMTTApplicable(account: any) {
  //   this.showIMTTMessage = this.config.localCurrency === account.accountCurrency.currency;
  //   return this.showIMTTMessage;
  // }

  moneyTransferTaxCodeChanged(taxCode: any) {
    switch (taxCode.key) {
      case '009':
        this.taxCodeMessage = 'A ' + this.config.intermediatedMoneyTransferTax + '% IMTT charge will be levied on the transaction amount.';
        break;
      default:
        this.taxCodeMessage =
          'Please note that you have selected not to pay IMTT on this transaction. ' +
          'Tax evasion is a crime and you are liable for any fines and penalties that may arise from this choice.';
        break;
    }
    this.selfFundModel.moneyTransferTaxCode = taxCode;
  }
  goBackToMenu() {
    return this.router.navigate(['/menu/payments']);
  }
  getMonthlyLimits() {
    this.withdrawalLimit = this.accountService.cardProfile.monthlySFTWithdrawalLimit;

    this.usedLimit = this.accountService.cardProfile.monthlySFTUsedLimit;

    if (this.withdrawalLimit && this.usedLimit) {
      this.availableLimit = this.withdrawalLimit.amount - this.usedLimit.amount;
      this.availableLimit = parseFloat(parseFloat(this.availableLimit).toFixed(2));
    }
  }

  isTransactionAllowedForCurrency(fromCurrency: any, toCurrency: any) {
    if (this.config.localToForeignAccount && fromCurrency === this.config.localCurrency) {
      return true;
    } else if (this.config.foreignToOtherForeignAccount) {
      return true;
    } else {
      return fromCurrency === toCurrency || toCurrency === this.config.localCurrency;
    }
  }

  getScheduleValues(values) {
    if (values) {
      this.selfFundModel.allFieldsShow = values.allFieldsShow;
      this.selfFundModel.isPaymentScheduled = values.isPaymentScheduled;
      this.selfFundModel.oftenSchedule = values.oftenSchedule;
      this.selfFundModel.numberOfPayments = values.numberOfPayments;
      this.selfFundModel.scheduleDate = values.scheduleDate;
      this.selfFundModel.module = values.module || '';
      this.selfFundModel.editData = values.editData || {};
      this.selfFundModel.scheduleData = values;

      if (this.formRef.form.value.amount) {
        (this.formRef.form.controls.amount as FormGroup).setErrors(null);
      }
    }
  }

  fromAccountChange(fromAccount): void {
    this.selfFundModel.selectedFromAccount = fromAccount;

    if (this.selfFundModel.selectedToAccount) {
      if (this.selfFundModel.selectedToAccount.number === fromAccount.number) {
        this.selfFundModel.selectedToAccount = null;
      } else if (
        !this.isTransactionAllowedForCurrency(
          fromAccount.accountCurrency.currencyCode,
          this.selfFundModel.selectedToAccount.accountCurrency.currencyCode
        )
      ) {
        this.selfFundModel.selectedToAccount = null;
      }
    }

    this.selfFundModel.availableTransferLimit = fromAccount.availableBalance.amount;

    this.selfFundModel.toAccounts = this.filterOutAccount(this.originalToAccounts, fromAccount);

    if (this.editReviewModel) {
      const editSelectedToAccountDetails = _.find(this.selfFundModel.toAccounts, {
        number: this.editReviewModel.toAccount.number,
      });
      this.selfFundModel.selectedToAccount = editSelectedToAccountDetails || this.selfFundModel.toAccounts[0];
      this.toAccountChange(this.selfFundModel.selectedToAccount);
    }

    this.amountChangeFn(this.selfFundModel.amount);
    this.isPRCPAccount = this.checkPRCPAccount();
  }

  toAccountChange(toAccount): void {
    this.selfFundModel.selectedToAccount = toAccount;
    this.selfFundModel.totalAmount = {
      currency: this.selfFundModel.selectedToAccount.accountCurrency.currencyCode,
      amount: this.selfFundModel.amount || 0,
    };
  }

  amountChangeFn(amount: any) {
    if (
      this.selfFundModel.selectedFromAccount &&
      this.selfFundModel.selectedFromAccount.availableBalance &&
      !this.selfFundModel.selectedFromAccount.availableBalance.amount
    ) {
      this.selfFundModel.amount = this.config.isBillerCDI && this.isPRCPAccount ? this.selfFundModel.amount : '';
    }

    if (this.selfFundModel.currentFlow === 'edit-transaction') {
      this.editReviewModel.scheduledAmount.amount = amount || 0;
    }
  }

  amountFocusOut() {
    if (parseFloat(this.selfFundModel.amount)) {
      this.isAmountEntered = true;
    }
  }

  refChangeFn(newRef) {
    if (this.selfFundModel.currentFlow === 'edit-transaction') {
      this.editReviewModel.customerReference = newRef || '';
    }
  }

  goBack() {
    if (this.selfFundModel.currentFlow === 'edit-transaction') {
      return this.router.navigate(['scheduleTransaction/receipt']);
    } else {
      return this.router.navigate(['menu/payments']);
    }
  }

  goToConfirmation() {
    if (!this.selfFundModel.isPaymentScheduled) {
      this.selfFundModel.oftenSchedule = this.scheduleFrequency[0];
      this.selfFundModel.numberOfPayments = 1;
      this.selfFundModel.scheduleDate = new Date();
    }

    if (this.selfFundModel.currentFlow === 'edit-transaction') {
      this.selfFundModel.editData.amount = this.editReviewModel.scheduledAmount;
    }

    if (this.config.checkMultiCurrencyHoliday) {
      this.isHoliday = this.paymentUtilitiesService.checkPublicHoliday(this.publicHolidays, this.selfFundModel.currentDate);

      const fromCurrency = this.selfFundModel.selectedFromAccount.availableBalance.currency;
      const toCurrency = this.selfFundModel.selectedToAccount.availableBalance.currency;

      if (
        this.isHoliday &&
        this.config.allowRtgsWeekend &&
        (fromCurrency !== this.config.localCurrency || toCurrency !== this.config.localCurrency)
      ) {
        if (
          this.config.localCurrency === 'ZWL' &&
          (fromCurrency === 'USD' || fromCurrency === 'ZWL') &&
          (toCurrency === 'USD' || toCurrency === 'ZWL')
        ) {
          const selfFundModelCopy = _.cloneDeep(this.selfFundModel);
          this.store.dispatch(setSelfAccountFormData({ selfAccountFormData: selfFundModelCopy }));
          return this.router.navigate(['./payments/selfFund/confirm']);
        } else {
          this.dataSharingService.setCloseCurrentModule({
            message: {
              line1: '<p>Please note that cross foreign currency</p>' + '<p>payments are only allowed on a</p>' + '<p>business day</p>',
            },
            buttonOneText: 'OK',
            routeBackTo: null,
            showModule: true,
          });
        }
      } else {
        const selfFundModelCopy = _.cloneDeep(this.selfFundModel);
        this.store.dispatch(setSelfAccountFormData({ selfAccountFormData: selfFundModelCopy }));
        return this.router.navigate(['./payments/selfFund/confirm']);
      }
    } else {
      const selfFundModelCopy = _.cloneDeep(this.selfFundModel);
      this.store.dispatch(setSelfAccountFormData({ selfAccountFormData: selfFundModelCopy }));
      return this.router.navigate(['./payments/selfFund/confirm']);
    }
  }
  fetchPublicHolidaysList() {
    this.bankingMetadataService.publicHolidaysList().subscribe((response) => {
      if (response.length) {
        this.publicHolidays = response;
      }
    });
  }

  private filterOutAccount(accounts, selectedAccount) {
    const filteredAccountsArray = [];
    accounts.forEach((account) => {
      if (
        account.number !== selectedAccount.number &&
        this.isTransactionAllowedForCurrency(selectedAccount.accountCurrency.currencyCode, account.accountCurrency.currencyCode)
      ) {
        filteredAccountsArray.push(account);
      }
    });
    return filteredAccountsArray;
  }

  
  private checkPRCPAccount(): boolean {
    if (!this.selfFundModel.selectedFromAccount || !this.selfFundModel.selectedFromAccount.keyValueMetadata) {
      return false;
    }
    const isAccTypeRcp = this.selfFundModel.selectedFromAccount.accountType === this.RCP;

    const isPRCPProductCode = !!this.selfFundModel.selectedFromAccount.keyValueMetadata.find(
      (element) => element.key === this.FEB_PRODUCT_CODE && element.value === this.PRCP
    );
    return isAccTypeRcp && isPRCPProductCode;
  }
}
