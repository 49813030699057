import { PrepaidUtilitiesService } from '../../core/prepaid-utilities/prepaid-utilities.service';
import { SnackBarService } from '../../services/snack-bar-service/snackBarService';
import { SmeServiceService } from '../../services/sme-service/sme-service.service';
import { ApprovalListService } from '../../services/approval-list-service/approval-list.service';
import { Router } from '@angular/router';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { Component, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { DateUtil } from '../../core/utility-classes/date.util';

// tslint:disable-next-line: max-line-length
import {
  setSelectedApprovalData,
  setPostApprovalData,
  setSelectedApprovalTab,
  setSelectedApprovalType,
} from '../../shared/store-utilities/actions/approval.action';
import { environment } from '../../../environments/environment';
import { delay } from 'rxjs/operators';
import { DeviceDetectorService } from '../../services/device-detector/device-detector.service';
import { RouterStateService } from '../../services/route-state/route-state.service';
import { TransactionService } from '../../core/transaction-service/transaction.service';

@Component({
  selector: 'sbg-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss'],
})
export class ApprovalsComponent implements OnInit {
  config = environment.config;
  readonly RADIO = 'radio';
  readonly CHECKBOX = 'checkbox';
  readonly ADD_ACTION = 'ADD';
  requestDate;
  processingDate;

  currentDate;
  todayDate: any = new Date();
  showMobileMenu = false;
  approveDate;
  showFooter: boolean;
  singleApprove = this.config.singleApprove;

  smeAllowMultipleApprovals = this.config.multipleApprovalsSME;

  activeApproveButton;
  screenSize: string;

 

  isListPage = true;
  selectedTab = 0;
  selectedSubTab = 0;
  subList = [
    {
      item: 'Financial Transactions',
    },
    {
      item: 'Bulk Upload',
    },
    {
      item: 'Non Financial Transactions',
    },
  ];
  mobileMenu = [
    {
      feature: 'Pending on me',
      subList: this.subList,
    },
    {
      feature: 'Pending on others',
      subList: this.subList,
    },
  ];
  listOfFeatures = [
    {
      feature: 'Pending on me',
    },
    {
      feature: 'Pending on others',
    },
  ];
  isLoaded = false;
  disableRightButton: boolean;

  transactionTypes = ['ALL_FINANCIAL_TRANSACTIONS', 'ALL_BULK_FILE_TRANSACTIONS', 'ALL_NON_FINANCIAL_TRANSACTIONS'];
  featureTypes = ['pendingonme', 'pendingonothers'];
  appprovalData: ApprovalData;
  listData;
  filteredData = [];
  showRightSecondaryButton = true;
  showRightButton = true;
  rightSecondaryButtonText = 'Reject All';
  rightButtonText = 'Approve All';
  selectionMode = this.config.multipleApprovalsSME ? this.CHECKBOX : this.RADIO;
  headerTitle = '';
  hideList = false;
  remarkModal = {
    inputHeader: '',
  };
  recordId;
  selectedData;
  approvalStatusType;
  transactionType;
  isRequired = true;
  selectedItems = [];
  constructor(
    private dataSharingService: DataSharingService,
    private smeService: SmeServiceService,
    private router: Router,
    private approverListService: ApprovalListService,
    private decimalPipe: DecimalPipe,
    private store: Store<any>,
    private snack: SnackBarService,
    private prepaidUtilities: PrepaidUtilitiesService,
    private deviceDetectorService: DeviceDetectorService,
    private routeState: RouterStateService,
    private transactionService: TransactionService
  ) {
    window['inner']('other');
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;
      this.showMobileMenu = this.screenSize === 'small' && this.routeState.getPreviousUrl() !== '/approvals/approval-details';
      let showBack = !this.showMobileMenu;
      if (this.screenSize !== 'small') {
        showBack = false;
      }
      if (this.routeState.getCurrentUrl() === '/approvals') {
        dataSharingService.setHeadPagination({
          mainTitle: 'Approval List',
          showBack,
          subTitle: '',
          showBackFn: this.goBack.bind(this),
          showClose: true,
          steps: true,
          module: 'approvals',
        });
      }
      this.isLoaded = true;
    });
    window['inner']('other');
  }
  ngOnInit() {
    this.appprovalData = {
      ALL_FINANCIAL_TRANSACTIONS: {},
      ALL_BULK_FILE_TRANSACTIONS: {
        // pendingonme: [],
        // pendingonother: []
      },
      ALL_NON_FINANCIAL_TRANSACTIONS: {
        // pendingonme: [],
        // pendingonother: []
      },
    };
    let selectedTabData;
    this.store.select('appReducer', 'approvalReducer').subscribe((stateData) => {
      selectedTabData = stateData.selectedApprovalTab;
      // if ( !this.showMobileMenu) {
      //   this.dataSharingService.setHeadPagination({
      //     subTitle: this.subList[selectedTabData.selectedSubTab].item,
      //   });
      // }
    });
    this.featureClicked(selectedTabData.selectedTab, selectedTabData.selectedSubTab);
    this.headerTitleChanges();
    // if ( this.screenSize !== 'small'){
    //   this.showMobileMenu = true;
    // }
  }

  goBack() {
    if (this.screenSize === 'small' && !this.showMobileMenu) {
      this.showMobileMenu = true;
      this.dataSharingService.setHeadPagination({
        subTitle: '',
        showBack: false,
      });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  headerTitleChanges() {
    if (this.selectionMode === this.RADIO) {
      if (this.selectedTab === 0) {
        this.headerTitle = 'Select a transaction to approve or reject.';
      } else {
        this.headerTitle = 'Select a transaction to recall.';
      }
    } else {
      if (this.selectedTab === 0) {
        this.headerTitle = 'Select multiple transactions to approve or reject.';
      } else {
        this.headerTitle = 'Select multiple transactions to recall.';
      }
    }
  }

  featureClicked(index, subIndex = 0) {
    this.selectedTab = index;
    this.selectedSubTab = subIndex;
    this.subFeatureClicked(subIndex, false);
    this.headerTitleChanges();
  }

  buttonChanges(index, data = []) {
    const textS = data.length ? (data.length === 1 ? '' : data.length + ' Selected') : 'All';
    const text = data.length ? (data.length === 1 ? data[0].title : data.length + ' Selected') : 'All';
    if (index) {
      // pending on others
      this.showRightSecondaryButton = false;
      this.rightButtonText = 'Recall';
    } else {
      // pending on me
      this.showRightSecondaryButton = true;
      this.rightSecondaryButtonText = `Reject ${this.screenSize === 'small' ? '' : textS}`;
      this.rightButtonText = `Approve ${this.screenSize === 'small' ? '' : text}`;
    }

    // Disable button Approve All/Reject All/Recall All if option is false
    if (!this.smeAllowMultipleApprovals) {
      if (!index) {
        this.showRightSecondaryButton = !!(this.selectedData && this.selectedData.length);
      }
      this.showRightButton = !!(this.selectedData && this.selectedData.length);
    }
  }

  subFeatureClicked(index, refreshData) {
    this.selectedSubTab = index;
    this.selectedData = [];
    this.store.dispatch(
      setSelectedApprovalTab({
        selectedApprovalTab: {
          selectedTab: this.selectedTab,
          selectedSubTab: this.selectedSubTab,
        },
      })
    );
    if (!this.showMobileMenu) {
      this.dataSharingService.setHeadPagination({
        subTitle: this.subList[index].item,
      });
    }
    const transactionType = this.transactionTypes[index];
    const featureType = this.featureTypes[this.selectedTab];

    if (Object.keys(this.appprovalData[transactionType]).length && !refreshData) {
      this.listData = this.appprovalData[transactionType][featureType];
      this.showFooter = this.setShowFooter();
      this.buttonChanges(this.selectedTab, this.selectedData);
    } else {
      const payload = {
        approvalStatusType: 'PENDING',
        approvalTransactionCategoryType: this.transactionTypes[index],
        pagination: true,
      };

      this.smeService.getApprovals(payload).subscribe((response) => {
        this.postServiceData(response);
      });
    }
  }
  setShowFooter() {
    // for mutli approval country check if list data has data.BEcause if it empty then show the list
    // It does not matter if selected item has anything ..as if no item is selected then 'all' option is shown
    if (this.selectionMode === this.CHECKBOX) {
      return this.listData.length ? true : false;
    } else {
      // for single approval country check if selected item has value or not
      return this.listData.length && this.selectedItems.length ? true : false;
    }
  }

  postServiceData(response) {
    this.buttonChanges(this.selectedTab, this.selectedData);
    if (!response.body) {
      return;
    }
    const type = this.transactionTypes[this.selectedSubTab];
    const featureType = this.featureTypes[this.selectedTab];
    const pendingonme = [];
    const pendingonothers = [];
    switch (type) {
      case 'ALL_FINANCIAL_TRANSACTIONS':{
        const financialTransactions = response.body.financialTransaction.financialTransactionApprovals || [];
        financialTransactions.forEach((value) => {
          const data = this.approverListService.getTransactionName(value.financialTransaction, value.financialTransaction.type);

          const val = {
            title: typeof data === 'object' ? data.title : data,
            subTitle: typeof data === 'object' ? data.subTitle : '',
            amount: value.financialTransaction.amount.currency + ' ' + this.decimalPipe.transform(value.financialTransaction.amount.amount, '1.2-2'),
            date: value.financialTransaction.transactionDate || value.financialTransaction.requestDate,
            type: 'financialTransactions',
            keyValueMetadata: response.body.keyValueMetadata,
            data: value,
          };
          this.transactionType = value.financialTransaction.type;

          if (value.initiatedBy === 'ME') {
            pendingonothers.push(val);
          } else {
            pendingonme.push(val);
          }
        });
        this.appprovalData[type] = { pendingonme, pendingonothers };
        this.listData = this.appprovalData[type][featureType];
        break;
      }
      case 'ALL_NON_FINANCIAL_TRANSACTIONS':{
        const nonFinancialTransactions = response.body.nonFinancialTransaction || [];

        Object.keys(nonFinancialTransactions).forEach((value) => {
          if (Array.isArray(nonFinancialTransactions[value])) {
            nonFinancialTransactions[value].forEach((item) => {
              let titleKey;
              if (value === 'beneficiaryManagementApprovals' || value === 'billerManagementApprovals') {
                titleKey = item.action;
              } else {
                titleKey = item.transactionType;
              }

              this.transactionType = item.transactionType;
              const data = this.approverListService.getTransactionName(item, titleKey);
              const val = {
                title: typeof data === 'object' ? data.title : data,
                subTitle: typeof data === 'object' ? data.subTitle : '',
                account: this.getNonFinancialAccount(item, value),
                expiryDate:
                  item.servicerequest && item.servicerequest.limitScheme && item.servicerequest.limitScheme.expiryDate
                    ? item.servicerequest.limitScheme.expiryDate
                    : '',
                date: item.requestDate,
                keyValueMetadata: response.body.keyValueMetadata,
                type: value,
                data: item,
              };
              if (item.initiatedBy === 'ME') {
                pendingonothers.push(val);
              } else {
                pendingonme.push(val);
              }
            });
          }
        });
        this.appprovalData[type] = { pendingonme, pendingonothers };
        this.listData = this.appprovalData[type][featureType];
        break;
      }
      case 'ALL_BULK_FILE_TRANSACTIONS':{
        const bulkFileApprovals = response.body.bulkFile.bulkFileApprovals || [];
        bulkFileApprovals.forEach((value) => {
          const data = this.approverListService.getTransactionName(value, value.transactionType);
          this.transactionType = value.transactionType;
          const amount = value.totalAmount || value.totalAmountWithCurrency;
          const val = {
            title: data.title,
            subTitle: data.subTitle,
            amount: amount.currency + ' ' + this.decimalPipe.transform(amount.amount, '1.2-2'),
            date: value.processingDate,
            keyValueMetadata: response.body.keyValueMetadata,
            type: 'bulkFile',
            data: value,
          };
          if (value.initiatedBy === 'ME') {
            pendingonothers.push(val);
          } else {
            pendingonme.push(val);
          }
        });
        this.appprovalData[type] = { pendingonme, pendingonothers };
        this.listData = this.appprovalData[type][featureType];
        break;
      }
    }
    this.showFooter = this.setShowFooter();
  }

  getNonFinancialAccount(item, key) {
    switch (key) {
      case 'beneficiaryManagementApprovals':
        return item.beneficiary.accountNumber;
      case 'servicerequestApprovals':
        if (item.transactionType === 'EAP_LIMIT_MANAGEMENT') {
          return item.servicerequest.limitScheme.limitAmount.currency + ' ' + item.servicerequest.limitScheme.limitAmount.amount;
          // return "Limit";
        } else {
          return item.servicerequest.referenceId;
        }
      case 'updateReinvestmentDetailsApprovals':
        return item.depositAccountDetails.number;
    }
  }

  listItemClicked(approvalData) {
    this.disableRightButton = false;
    const selectedData = approvalData.selected ? approvalData.selected : approvalData.data ? [approvalData.data] : [];
    this.selectedData = selectedData;

    if (this.selectedTab && approvalData.data && approvalData.data.data.depositAccountDetails) {
      setTimeout(() => {
        this.disableRightButton = approvalData.data.data.transactionType === 'FIXED_TERM_INVESTMENT_INSTRUCTION';
      }, 0);
    }

    if (!this.selectedTab && this.selectedData && this.selectedData.length && this.selectedData[0].data.processingDate) {
      const processDate = this.selectedData[0].data.processingDate;
      setTimeout(() => {
        this.disableRightButton = DateUtil.isBefore(processDate, new Date(), 'days');
      }, 0);
    }

    this.buttonChanges(this.selectedTab, selectedData);
  }

  infoClicked(data) {
    this.store.dispatch(setSelectedApprovalData({ selectedApprovalData: data }));
    this.router.navigate(['/approvals/approval-details']);
  }

  goBackRemarkFn() {
    this.hideList = false;
    //  emptying the list as selectibility is not there right now
    this.selectedData = [];
    this.buttonChanges(this.selectedTab, []);
  }

  approvalOptionClicked(approvalData, type) {
    // there can be two scenarios
    // single approval
    // if in case it is a single approval check for ip ,if yes senf to ip approval
    // if no then continue normally
    // multiple approval
    // no need to check for ip condition , proceed with normal approval flow

    // check if some data came or not, if not ,then it means that approve all was clicked
    if (!approvalData.length) {
      this.selectedItems = this.listData;
    } else {
      this.selectedItems = approvalData;
    }
    this.approvalStatusType = type;
    // if single approval and if it is international payment then fo to ip approval screen
    if (
      this.selectedItems.length === 1 &&
      this.selectedItems[0].data &&
      this.selectedItems[0].data.financialTransaction &&
      this.selectedItems[0].data.financialTransaction.type === 'ONCE_OFF_INTERNATIONAL_PAYMENT'
    ) {
      this.store.dispatch(setSelectedApprovalType({ selectedApprovalType: type }));
      this.store.dispatch(setSelectedApprovalData({ selectedApprovalData: approvalData[0].data }));
      this.router.navigate(['/approvals/international-payment-approval']);
    } else {
      this.hideList = type === 'REJECTED' || type === 'APPROVED';
      this.isRequired = type === 'REJECTED';
      if (!this.hideList) {
        this.approvalAction('');
      }
    }
  }

  approvalAction(remarks) {
    let accountId;
    const approvals = this.selectedItems
      .filter((it) => it.data)
      .map((it) => {
        const result: any = {
          approvalRecordId: this.prepaidUtilities.getRecordId(it.data),
          approvalTransactionType: it.data.transactionType || it.data.financialTransaction.type,
        };
        // Action only for financial transaction
        if (it.data.financialTransaction && it.data.action) {
          // Old UI adding fixed action in this case (ADD)
          result.action = this.ADD_ACTION;
        }
        if (it.data.transactionType === 'FIXED_TERM_INVESTMENT_INSTRUCTION' && it.data.depositAccountDetails) {
          accountId = it.data.depositAccountDetails.number;
        }
        return result;
      });

    let payload = {};
    if (this.singleApprove) {
      payload = {
        approvalStatusType: this.approvalStatusType,
        ...approvals[0],
        keyValueMetadata: [],
        accountId,
      };
    } else {
      payload = {
        approvalStatusType: this.approvalStatusType,
        approvals,
        accountId,
      };
    }

    let action;
    if (this.selectedItems[0].data.financialTransaction && this.selectedItems[0].data.financialTransaction.action) {
      action = this.selectedItems[0].data.financialTransaction.action;
      payload = { ...payload, ...{ action } };
    }

    if (remarks.trim()) {
      payload = { ...payload, ...{ remarks } };
    }
    this.postService(payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    return this.smeService.approval(payload).subscribe((response: any) => {
      switch (this.approvalStatusType) {
        case 'REJECTED':
          this.snack.open('Record has been successfully rejected');
          this.router.navigate(['/approvals']);
          break;
        case 'RECALLED':{
          const responseCode = this.transactionService.getResponseData(response);
          if (response.body && responseCode) {
            this.snack.open(responseCode.message, responseCode.type);
          }
          this.subFeatureClicked(this.selectedSubTab, true);
          this.hideList = false;
          this.router.navigate(['/approvals']);
          break;
        }
        case 'APPROVED':
          this.store.dispatch(setPostApprovalData({ approvalPayload: payload, postApprovalData: response }));
          this.router.navigate(['/approvals/approval-receipt']);
          break;
      }
    });
  }

  subFeatureMenuClicked(indexData) {
    this.showMobileMenu = false;
    // this.selectedTab = indexData.list;
    // this.subFeatureClicked(indexData.subList , true);
    this.dataSharingService.setHeadPagination({
      showBack: true,
    });
    this.featureClicked(indexData.list, indexData.subList);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  featureMenuClicked(index) {}
}

interface ApprovalData {
  ALL_FINANCIAL_TRANSACTIONS: any;
  ALL_BULK_FILE_TRANSACTIONS: any;
  ALL_NON_FINANCIAL_TRANSACTIONS: any;
}
