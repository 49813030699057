import { AppUtilitiesService } from './../utilities/app-utilities/app-utilities.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class PrepaidUtilitiesService {
  config = environment.config;
  optionList = {
    renewalOptions: [
      {
        value: 'Principal amount',
        key: 'P',
      },
      {
        value: 'Maturity amount',
        key: 'M',
      },
      {
        value: 'Fixed amount less than Maturity',
        key: 'F',
      },
      {
        value: 'Fixed amount more than Maturity',
        key: 'A',
      },
    ],
  };
  constructor(private appUtilitiesService: AppUtilitiesService) {}

  public getPrepaidType(data, requiredValue, requiredKey, mode) {
    const filteredProviders = data.filter((val) => {
      // Commented for now because of localCurrency is not configured
      if (val.prepaidType === requiredValue && val.prepaidProduct.range.max.currency === this.config.localCurrency) {
        // if providerMode is not available, consider it as PREPAID by default and sort
        return val.providerMode === mode || (!val.providerMode && mode === 'PREPAID');
      } else {
        return false;
      }
    });
    return filteredProviders;
  }

  public previouslyUsedProviderDetails(allProviders, selectedProvider) {
    let serviceProvider;

    for (let i = 0; i < allProviders.length; i++) {
      if (
        allProviders[i].prepaidProviderId === selectedProvider.basePrepaidProvider.prepaidProviderId &&
        allProviders[i].friendlyName === selectedProvider.basePrepaidProvider.friendlyName
      ) {
        serviceProvider = allProviders[i];
        break;
      }
    }
    return serviceProvider;
  }

  public structurePrepaidDataForApprovalList(vasType, providerMode) {
    const vasTypeDataObject = { VasTransactionType: '', customerReferenceFieldName: '' };
    const vasTypesObj = {
      SCHOOL_FEE: {
        transactionTypeName: 'Fees Payment',
        customerRefFieldName: 'Customer Reference',
      },
      UNAYO_PAYMENT: {
        transactionTypeName: 'Unayo Payment',
        customerRefFieldName: 'Recharge number',
      },
      TV_SUBSCRIPTION: {
        transactionTypeName: 'Television Subscription',
        customerRefFieldName: 'Smart Card Number',
      },
      TV_BOUQUET: {
        transactionTypeName: 'Television Bouquet Subscription',
        customerRefFieldName: 'Smart Card Number',
      },
      AIRTIME_TOP_UP: {
        transactionTypeName: 'Airtime',
        customerRefFieldName: 'Mobile Number',
      },
      ELECTRICITY: {
        transactionTypeName: this.appUtilitiesService.capitalizeFirstLetter(providerMode) + ' Electricity',
        customerRefFieldName: 'Meter Number',
      },
      WATER: {
        transactionTypeName: this.appUtilitiesService.capitalizeFirstLetter(providerMode) + ' Water',
        customerRefFieldName: 'Reference Number',
      },
      RATES_AND_TAXES: {
        transactionTypeName: this.appUtilitiesService.capitalizeFirstLetter(providerMode) + ' Rates and Taxes',
        customerRefFieldName: 'Reference Number',
      },
      ACCOUNT_TO_MOBILE_WALLET: {
        transactionTypeName: 'Mobile Wallet',
        customerRefFieldName: 'Mobile Number',
      },
      DATA_BUNDLE_TOP_UP: {
        transactionTypeName: 'Data Bundle',
        customerRefFieldName: 'Mobile number',
      },
      TAX_PAYMENT: {
        transactionTypeName: 'Tax Payment',
        customerRefFieldName: 'Tax Payment'
    }
    };
    if (providerMode === 'POSTPAID') {
      vasTypesObj[vasType].customerRefFieldName = 'Reference Number';
    }

    vasTypeDataObject.VasTransactionType = vasTypesObj[vasType].transactionTypeName;
    vasTypeDataObject.customerReferenceFieldName = vasTypesObj[vasType].customerRefFieldName;
    return vasTypeDataObject;
  }
  getAccountCreditAmount(accountType) {
    const minFundingObj = {
      OPEN_NOTICE_DEPOSIT_ACCOUNT: 'minAmountForNoticeDeposit',
      OPEN_CALL_DEPOSIT_ACCOUNT: 'minAmountForCallDeposit',
      OPEN_CURRENT_ACCOUNT: 'minAmountForCurrentAccount',
    };
    return minFundingObj[accountType];
  }
  getRecordId(approvalDetail) {
    let recordId = '';
    if (approvalDetail.document) {
      recordId = approvalDetail.document.id;
    } else if (approvalDetail.financialTransaction) {
      recordId = approvalDetail.financialTransaction.referenceId;
    } else if (
      approvalDetail.beneficiary ||
      approvalDetail.biller ||
      approvalDetail.cmaBeneficiary ||
      approvalDetail.servicerequest ||
      approvalDetail.depositAccountDetails
    ) {
      recordId =
        (approvalDetail.beneficiary && approvalDetail.beneficiary.recipientId) ||
        (approvalDetail.biller && approvalDetail.biller.roaBillerId) ||
        (approvalDetail.cmaBeneficiary && approvalDetail.cmaBeneficiary.recipientId) ||
        (approvalDetail.servicerequest && approvalDetail.servicerequest.referenceId) ||
        (approvalDetail.depositAccountDetails && approvalDetail.approvalReferenceId);
    }
    return recordId;
  }

  returnRenewalOptionValue(key) {
    const options = this.optionList.renewalOptions;
    for (let i = 0; i < options.length; i++) {
      if (options[i].key === key) {
        return options[i];
      }
    }
  }
  renewalOptions() {
    return this.optionList;
  }
}
