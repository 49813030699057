import { Component, OnInit } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { AppConstantService } from '../../../../core/app-constant/app-constant.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { DownloadService } from '../../../../core/download-service/download.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import * as _ from 'lodash';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { formatNumber } from '@angular/common';
import { DateUtil } from '../../../../core/utility-classes/date.util';
import { McaHeader } from '../../../../core/data/mca-header';
import { McaResponseType } from '../../../../core/data/mca-response-type';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { isSmeMode } from '@shared/store-utilities/actions/login.action';

//  See the Moment.js docs for the meaning of these formats:
//  https:// momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'dddd, DD MMM YY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'sbg-self-fund-receipt',
  templateUrl: './self-fund-receipt.component.html',
  styleUrls: ['./self-fund-receipt.component.scss'],
  providers: [
    //  `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    //  application's root module. We provide it at the component level here, due to limitations of
    //  our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SelfFundReceiptComponent implements OnInit {
  isReccuringFirstDateToday;
  oftenScheduleData;
  fromAccountUpdatedData;
  isPaymentScheduled;
  reviewModel;
  transactionId;
  transferData;
  receiptTransData;
  receiptModel;
  responseMsg: any;
  initiateItToday: any;
  transactionMessage: any;
  payloadData: any;
  targetsName: any;
  responseCode: any;
  mainHeading: any;
  transactionStatus;
  setSelfAccTransferReceiptData;
  bankLogo: string;
  currentDate: any = DateUtil.format(new Date(), DateUtil.DD_MMMM_YYYY);
  toAccUpdatedData: any;
  accountsList: any;
  receiptHeaders: string[];
  receiptDetails: ReceiptDetail[];
  isSmeMode = false;
  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private appConstant: AppConstantService,
    private store: Store<{ appReducer }>,
    private downloadService: DownloadService,
    private transactionService: TransactionService,
  ) {
    this.store.select('appReducer', 'selfTransferReducer').subscribe((stateData) => {
      this.setSelfAccTransferReceiptData = stateData.setSelfAccTransferReceiptData;
    });

    this.store.select('appReducer', 'dashboardReducer').subscribe((stateData) => {
      if (stateData.accounts && stateData.accounts.body && stateData.accounts.body.accounts.length) {
        this.accountsList = stateData.accounts.body.accounts;
      }
    });

    store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });

    if (this.setSelfAccTransferReceiptData.reviewModel) {
      this.mainHeading = this.setSelfAccTransferReceiptData.reviewModel.currentFlow === 'new-transaction' ? 'Transfer' : 'Transfer';
    } else {
      this.mainHeading = 'Transfer';
    }

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'selfFundTransferDetails',
    });
  }

  ngOnInit() {
    this.bankLogo = this.appConstant.BankLogo;
    if (this.setSelfAccTransferReceiptData.reviewModel.currentFlow === 'new-transaction') {
      this.setSelfTransferReceipt();
    } else {
      this.setEditSelfTransferReceipt();
    }
    this.receiptHeaders = this.getReceiptHeaders();
    this.receiptDetails = this.getReceiptDetails();
  }

  getReceiptHeaders(): string[] {
    return [this.payloadData.toAccount.accountType, this.payloadData.toAccount.number];
  }

  getReceiptDetails(): ReceiptDetail[] {
    return [
      {
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.transactionId,
      },
      {
        header: ReceiptHeaders.AVAILABLE_BALANCE,
        value: `${this.toAccUpdatedData.availableBalance.currency}
              ${formatNumber(this.toAccUpdatedData.availableBalance.amount, 'en-US', '1.2-2')}`,
      },
      {
        header: ReceiptHeaders.DATE,
        value: this.currentDate,
      },
      {
        header: ReceiptHeaders.MY_REFERENCE,
        value: this.payloadData.customerReference,
      },
    ];
  }

  setSelfTransferReceipt(): void {
    if (this.setSelfAccTransferReceiptData) {
      this.receiptTransData = this.setSelfAccTransferReceiptData.body;
      this.receiptModel = this.receiptTransData.transactionResults[0];
      this.transactionId = this.transactionService.getTxnId(this.receiptModel);

      this.transferData = this.setSelfAccTransferReceiptData.payload;
      this.payloadData = this.transferData.transactions.transfers[0];

      let indexFrom;
      if (this.accountsList && this.accountsList.length && this.transferData.account && this.transferData.account.number) {
        indexFrom = _.findIndex(this.accountsList, { number: this.transferData.account.number });
      }

      if (indexFrom >= 0) {
        this.fromAccountUpdatedData = this.accountsList[indexFrom];
      }

      let index;
      if (this.accountsList && this.accountsList.length && this.payloadData.toAccount && this.payloadData.toAccount.number) {
        index = _.findIndex(this.accountsList, { number: this.payloadData.toAccount.number });
      }

      if (index >= 0) {
        this.toAccUpdatedData = this.accountsList[index];
      }

      this.reviewModel = this.setSelfAccTransferReceiptData.reviewModel;

      if (this.reviewModel.scheduleData) {
        this.isPaymentScheduled = this.reviewModel.scheduleData.isPaymentScheduled;

        if (this.reviewModel.scheduleData.numberOfPayments > 1) {
          this.isReccuringFirstDateToday = DateUtil.areSame(new Date(), this.reviewModel.scheduleData.scheduleDate, 'days');
          if (this.isReccuringFirstDateToday) {
            this.isPaymentScheduled = false;
          }
        }
      }
    }
  }

  setEditSelfTransferReceipt(): void {
    if (this.setSelfAccTransferReceiptData) {
      this.receiptTransData = this.setSelfAccTransferReceiptData.body;
      this.receiptModel = this.receiptTransData.scheduledTransaction;

      this.transferData = this.setSelfAccTransferReceiptData.payload;
      this.payloadData = this.transferData.scheduledTransaction;

      this.transactionStatus = this.setSelfAccTransferReceiptData.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);

      this.responseMsg = this.setSelfAccTransferReceiptData.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);

      if (this.transactionStatus === McaResponseType.SUCCESS) {
        this.transactionMessage = 'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' processed successfully';
      } else {
        this.transactionMessage = this.responseMsg || 'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' failed';
      }
      if (DateUtil.areSame(new Date(), this.receiptModel.nextPaymentDate, 'days')) {
        this.initiateItToday = true;
      } else {
        if(this.isSmeMode){
          this.transactionMessage =
            'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' submitted successfully and is pending for corporate user approval';
        }
        else{
          this.transactionMessage =
            'Transaction with Reference ID ' + this.receiptModel.futureDatedId + ' submitted successfully and is pending for processing';
        }
      }

      this.targetsName = this.receiptModel.beneficiary.name;
    }
  }

  finishTransaction() {
    this.dataSharingService.resetStepData();
    return this.router.navigate(['/dashboard']);
  }

  printReceipt() {
    this.downloadService.printReceipt();
  }

  downloadReceipt() {
    this.downloadService.downloadReceipt();
  }

  goToDetails() {
    return this.router.navigate(['./scheduleTransaction']);
  }

  goToReview() {
    return this.router.navigate(['./payments/selfFund/details']);
  }
}
