import { NgModule } from '@angular/core';
import { DecimalPipe, DatePipe } from '@angular/common';

import { SharedModule } from '../../../shared/shared.module';
import { PayMobileWalletRoutingModule } from './pay-mobile-wallet-routing.module';
import { PayMobileWalletListComponent } from './pay-mobile-wallet-list/pay-mobile-wallet-list.component';
import { PayMobileWalletDetailsComponent } from './pay-mobile-wallet-details/pay-mobile-wallet-details.component';
import { PayMobileWalletReviewComponent } from './pay-mobile-wallet-review/pay-mobile-wallet-review.component';
import { PayMobileWalletReceiptComponent } from './pay-mobile-wallet-receipt/pay-mobile-wallet-receipt.component';
import { OnceOffMobileWalletPaymentComponent } from './once-off-mobile-wallet-payment/once-off-mobile-wallet-payment.component';

@NgModule({
  providers: [DecimalPipe, DatePipe],
  declarations: [
    PayMobileWalletListComponent,
    PayMobileWalletDetailsComponent,
    PayMobileWalletReviewComponent,
    PayMobileWalletReceiptComponent,
    OnceOffMobileWalletPaymentComponent,
  ],
  imports: [SharedModule, PayMobileWalletRoutingModule],
})
export class PayMobileWalletModule {}
