import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { Store } from '@ngrx/store';

@Injectable()
export class AuthGuardService implements CanActivate {
  private authenticationData;

  constructor(
    private router: Router,
    private ngrxStore: Store<{ appReducer }>
  ) {
    this.ngrxStore
      .select('appReducer', 'loginReducer')
      .subscribe((stateData) => {
        this.authenticationData = { ...stateData.authenticateResponse };
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkLogin();
  }

  checkLogin(): boolean {
    if (Object.keys(this.authenticationData).length !== 0) {
      return true;
    }

    // Navigate to the login page
    this.router.navigate(['/login']);
    return false;
  }
}
