import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { McaResponseType } from '@data/mca-response-type';
import { McaHeader} from '@data/mca-header';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-change-username',
  templateUrl: './change-username.component.html',
  styleUrls: ['./change-username.component.scss']
})
export class ChangeUsernameComponent implements OnInit {
  @Output() childSubmit = new EventEmitter<string>();
  readonly USERNAME_VALID = "User name is valid, Please go ahead.";
  readonly USERNAME_NOT_UNIQ = "Sorry this username has been taken. Please Try Another.";
  readonly USERNAME_MIN_LENGTH = 8;
  readonly USERNAME_MIN_CHARS_PERCENT = 0.3;
  newUsername: string;
  isUserNameClientValidationPassed: boolean;
  suggestedUsername: string;
  isUsernameValidationDisplayed: boolean;
  isMinLength: boolean;
  isNumChar: boolean;
  isNonNumChar: boolean;
  usernameSuggestionList$: Observable<Array<string>>;
  validationMsg: string;
  private isUserNameUniq: boolean;
  constructor(private registrationService: RegistrationService) { }

  get isNewUsernameValid() {
    return this.isUserNameClientValidationPassed && this.isUserNameUniq;
  }

  ngOnInit() {
    this.usernameSuggestionList$ = this.registrationService.fetchUsernameSuggestions();
  }

  setSuggestedUsername() {
    this.newUsername = this.suggestedUsername;
    this.setUsernameValidationDisplayed(true);
    this.newUsernameValidityCheck();
    this.isUserNameClientValidationPassed = this.isUserNameUniq = true;
    this.validationMsg = this.USERNAME_VALID;
  }

  setUsernameValidationDisplayed(isUsernameValidationDisplayed: boolean) {
    this.isUsernameValidationDisplayed = isUsernameValidationDisplayed;
  }

  resetValidation() {
    this.validationMsg = '';
    this.isUserNameClientValidationPassed = false;
    this.isUserNameUniq = false;
  }

  newUsernameValidityCheck() {
    if (this.newUsername !== this.suggestedUsername) {
      this.suggestedUsername = '';
    }
    this.resetValidation();
    const minChars = Math.ceil(this.newUsername.length * this.USERNAME_MIN_CHARS_PERCENT);
    this.isMinLength = this.newUsername.length >= this.USERNAME_MIN_LENGTH;
    const minNumCharRegExp = new RegExp('[0-9]{' + minChars + ',}');
    this.isNumChar = this.newUsername && minNumCharRegExp.test(this.newUsername);
    const minNonNumRegExp = new RegExp('[^0-9]{' + minChars + ',}');
    this.isNonNumChar = this.newUsername && minNonNumRegExp.test(this.newUsername);

    this.isUserNameClientValidationPassed = this.newUsername && this.isMinLength && this.isNumChar && this.isNonNumChar;
  }

  validateUsername() {
    const newUsername = this.newUsername;

    this.registrationService.validateUsername({ newUsername }).subscribe(response => {
      this.isUserNameUniq = response.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.SUCCESS;
      this.validationMsg = this.isUserNameUniq ? this.USERNAME_VALID : this.USERNAME_NOT_UNIQ;
    });
  }

  childFormSubmit() {
    if (!this.isUserNameUniq) {
      this.validateUsername();
      return;
    }
    this.childSubmit.emit(this.newUsername);
  }
}
