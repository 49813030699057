export enum Modules {
  SIDE_NAV = 'sideNav',
  SUB_HEADER = 'subHeader',
  DEBIT_CARDS = 'debitCard',
  PAYMENTS = 'payments',
  PROFILE_SETTINGS = 'profileSettings',
  VALUE_ADDED_SERVICES = 'vas',
  SCHEDULED_PAYMENTS = 'scheduledPayments',
  RECEIPTS = 'receipts',
  INSTANT_MONEY = 'instantMoney',
  BUY = 'buy',
  ONLINE_SERVICE_REQUEST = 'onlineServiceRequests',
}

export enum SideNav {
  RECEIPTS = 'receipts',
  RECIPIENTS = 'recipients',
  DEBIT_CARDS = 'debit-cards',
  SERVICE_REQUESTS = 'service-requests',
  PROFILE_AND_SETTINGS = 'profile-and-settings',
  SCHEDULED_PAYMENTS = 'scheduled-payments',
  APPROVAL_LIST = 'approvals',
  GIVE_FEEDBACK = 'give-feedback',
  OPEN_BANK_ACCOUNT = 'open-bank-account',
}

export enum SubHeader {
  PAY_AND_TRANSFER = 'pay-and-transfer',
  APPROVE = 'approvals',
  BUY_PREPAID = 'buy-prepaid',
  INSTANT_MONEY = 'instant-money',
}

export enum DebitCard {
  STOP_CARD = 'stop-card',
  ACTIVATE_CARD = 'activate-card',
  UPDATE_LIMIT = 'update-limit',
}

export enum Payments {
  BENEFICIARY_PAYMENTS = 'beneficiary-payments',
  UNAYO_PAYMENTS = 'unayo-payments',
  BILLER_PAYMENTS = 'biller-payments',
  PAY_WATER = 'pay-water',
  PAY_RATES_AND_TAXES = 'pay-rates-and-taxes',
  CMA_BENEFICIARY_PAYMENTS = 'cma-payments',
  BULK_FILE_UPLOAD = 'bulk-file-upload',
  INTERNATIONAL_PAYMENTS = 'international-payments',
  PAY_ELECTRICITY = 'pay-electricity',
  INSTANT_PAYMENTS = 'instant-payments',
  TAX_PAYMENT = 'tax-payment',
  ACCOUNT_TRANSFERS = 'account-transfers',
  VALUE_ADDED = 'vas',
  MOBILE_WALLET = 'mobile-wallet',
  GHANA_QR = 'ghana-qr',
  ONCE_OFF_COMPANY = 'once-off-company-payments',
  QR_PAYMENTS = 'qr-payments',
  NAMIBIA_TAX_PAYMENT = 'namibia-tax-payment',
  PAY_GOODS_AND_SERVICES = 'pay-goods-and-services',
  GEPG = 'gepg',
  SCHOOLFEES = 'schoolfees',
  CROSS_BORDER_PAYMENTS = 'cross-border-payments',
}

export enum ProfileSettings {
  CHANGE_USERNAME = 'change-username',
  CHANGE_PASSWORD = 'change-password',
  SECURITY_QUESTIONS = 'security-questions',
  EAP_LIMIT = 'eap-limit',
}

export enum InstantMoney {
  INSTANT_MONEY = 'instant-money',
  BULK_INSTANT_MONEY = 'bulk-instant-money',
}

export enum OnlineServiceRequests {
  NOTICE_DEPOSIT = 'noticeDeposit',
  TERM_DEPOSIT_REINVESTMENT = 'termDepositReinvestment',
}

export enum ValueAddedServices {}
