import { Injectable } from '@angular/core';
import { AppConstantService } from '../../core/app-constant/app-constant.service';

@Injectable({
  providedIn: 'root',
})
export class NumberFormatService {
  private readonly CARD_NUBMER_LENGTH_16 = 16;
  private readonly DIGITS_START = 6;
  private readonly DIGITS_END = 4;
  private readonly MASKING_PART = '******';
  private readonly DELIMITER = ' ';
  constructor(private appConstant: AppConstantService) {}

  maskCardNumber(number: string): string {
    const last = number.slice(-this.DIGITS_END);
    const first = number.slice(0, this.DIGITS_START);
    return first + this.MASKING_PART + last;
  }

  formatCardNumber(number: string): string {
    if (!number || number.length < this.CARD_NUBMER_LENGTH_16) {
      return number;
    }

    const trimmedNumber = this.trimCardNumber(number);

    // check if card number valid
    if (!this.isCardNumber(trimmedNumber)) {
      return number;
    }

    if (!this.appConstant.envConfig.cardNumberMasking) {
      return trimmedNumber;
    }

    number = this.maskCardNumber(trimmedNumber);
    return [
      number.slice(0, 4),
      number.slice(4, 8),
      number.slice(8, 12),
      number.slice(-4),
    ].join(this.DELIMITER);
  }

  private isCardNumber(accNumber) {
    const nonNumRegExp = new RegExp('[^0-9]');
    const isNonNumChar = nonNumRegExp.test(accNumber);
    return !isNonNumChar;
  }

  private trimCardNumber(number: string): string {
    if (number.length > this.CARD_NUBMER_LENGTH_16) {
      return number.slice(0, this.CARD_NUBMER_LENGTH_16 - number.length);
    }
    return number;
  }
}
