import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';

import { SelfFundRoutingModule } from './self-fund-routing.module';
import { SelfFundConfirmComponent } from './self-fund-confirm/self-fund-confirm.component';
import { SelfFundDetailsComponent } from './self-fund-details/self-fund-details.component';
import { SelfFundReceiptComponent } from './self-fund-receipt/self-fund-receipt.component';

@NgModule({
  imports: [SharedModule, SelfFundRoutingModule],
  declarations: [SelfFundDetailsComponent, SelfFundConfirmComponent, SelfFundReceiptComponent],
})
export class SelfFundModule {}
