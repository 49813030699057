import { createAction, props } from '@ngrx/store';
import {
  BulkInstantMoneyFormData,
  InstantMoneyFormData,
  InstantMoneyReceiptData,
  CancelInstantMoneyData,
  InstantMoneyVoucherListData,
  BulkReceiptData,
  CancelInstantMoneyReceiptData,
  sendMoneyTab
} from '../interfaces/instantMoney.model';

export const setInstantMoneyFormData = createAction(
  '[SelfAccountTransfer Component] setInstantMoneyFormData',
  props<{ instantMoneyFormData: InstantMoneyFormData }>()
);

export const setBulkInstantMoneyFormData = createAction(
  '[SelfAccountTransfer Component] setInstantMoneyFormData',
  props<{ bulkInstantMoneyFormData: BulkInstantMoneyFormData }>()
);

export const setInstantMoneyReceiptData = createAction(
  '[SelfAccountTransfer Component] setInstantMoneyReceiptData',
  props<{ instantMoneyReceiptData: InstantMoneyReceiptData }>()
);

export const setCancelInstantMoneyData = createAction(
  '[SelfAccountTransfer Component] setCancelInstantMoneyData',
  props<{ cancelInstantMoneyData: CancelInstantMoneyData }>()
);

export const setCancelInstantMoneyReceiptData = createAction(
  '[SelfAccountTransfer Component] setCancelInstantMoneyReceiptData',
  props<{ cancelInstantMoneyReceiptData: CancelInstantMoneyReceiptData }>()
);
export const setBulkReceiptData = createAction(
  '[SelfAccountTransfer Component] setCancelInstantMoneyReceiptData',
  props<{ bulkReceiptData: BulkReceiptData }>()
);

export const setInstantMoneyVoucherListData = createAction(
  '[SelfAccountTransfer Component] setInstantMoneyVoucherListData',
  props<{ instantMoneyVoucherListData: InstantMoneyVoucherListData }>()
);

export const setSendMoneyTab = createAction(
  '[SelfAccountTransfer Component] setSendMoneyTab',
  props<{ sendMoneyTab: sendMoneyTab }>()
);
