import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { SecurityQuestion } from '../../../services/RegistrationService/data/security-question';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-change-security-questions',
  templateUrl: './change-security-questions.component.html',
  styleUrls: ['./change-security-questions.component.scss'],
})
export class ChangeSecurityQuestionsComponent implements OnInit {
  @Input() securityQuestions: SecurityQuestion[];
  @Input() isBackButtonAvailable = false;
  @Input() moduleRef: any;
  @Input() messageOne: any;
  @Input() messageTwo: any;
  @Output() childSubmit = new EventEmitter<Map<number, SecurityQuestion>>();
  @Output() backSubmit = new EventEmitter<void>();
  routeHistory;
  checkURL;
  questionAndAnswerMap: Map<number, SecurityQuestion> = new Map();

  constructor(
    private dataSharing: DataSharingService,
    private routeState: RouterStateService
  ) {
    this.routeHistory = this.routeState.getHistory();
    this.checkURL = this.isEditRouteFn();
  }

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      subTitle: 'Register Security Questions',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'registration',
    });
  }
  isEditRouteFn() {
    const prevUrl = this.routeHistory[this.routeHistory.length - 1];
    return prevUrl.search('/registration') !== -1;
  }

  isOptionSelected(numberOfQuestion: number, question: SecurityQuestion) {
    let flag = false;
    const listOfExceptedKeys = [...this.questionAndAnswerMap.keys()].filter(
      (key) => numberOfQuestion !== key
    );
    listOfExceptedKeys.forEach((exceptedQuestion) => {
      if (
        this.questionAndAnswerMap.get(exceptedQuestion).key === question.key
      ) {
        flag = true;
      }
    });
    return flag;
  }

  addQuestion(num: number, securityQuestion: SecurityQuestion) {
    if (this.questionAndAnswerMap.has(num)) {
      let updatedQuestion: SecurityQuestion =
        this.questionAndAnswerMap.get(num);
      updatedQuestion = {
        ...updatedQuestion,
        key: securityQuestion.key,
        orderIndex: securityQuestion.orderIndex,
        question: securityQuestion.question,
      };
      this.questionAndAnswerMap.set(num, updatedQuestion);
    } else {
      this.questionAndAnswerMap.set(num, securityQuestion);
    }
  }

  addAnswer(numberOfQuestion: number, answerForQuestion: string) {
    let securityQuestion: SecurityQuestion =
      this.questionAndAnswerMap.get(numberOfQuestion);
    securityQuestion = { ...securityQuestion, answer: answerForQuestion };
    this.questionAndAnswerMap.set(numberOfQuestion, securityQuestion);
  }

  childFormSubmit() {
    this.childSubmit.emit(this.questionAndAnswerMap);
  }

  backToSubmit() {
    this.backSubmit.emit();
  }
}
