import { delay, filter } from 'rxjs/operators';
import { TransactionService } from './../../../../core/transaction-service/transaction.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from '../../../../services/device-detector/device-detector.service';
import { DataSharingService } from '@core/data-sharing/data-sharing.service';

@Component({
  selector: 'sbg-namibia-tax-payment-list',
  templateUrl: './namibia-tax-payment-list.component.html',
  styleUrls: ['./namibia-tax-payment-list.component.scss'],
})
export class NamibiaTaxPaymentListComponent implements OnInit {
  filteredData;
  screenSize;
  constructor(
    private transactionService: TransactionService,
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    private dataSharingService: DataSharingService
  ) {
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;
      this.dataSharingService.setHeadPagination({
        mainTitle: 'Pay & Transfer',
        subTitle: '',
        showBack: this.screenSize === 'small',
        showClose: true,
        steps: false,
        showBackFn: this.goBack.bind(this),
      });
    });
  }

  ngOnInit() {
    let payload = {
      pagination: false,
      transactionType: 'ONCE_OFF_COMPANY',
    };
    this.transactionService.getReceipt(payload).subscribe((response) => {
      let receipts = response['body'].receipts;
      this.filteredData = receipts.filter(
        (item) =>
          item.description === 'INLAND REVENUE NAMIB' ||
          item.description === 'INLAND REVENUE NAMIB AMNESTY' ||
          item.description === 'INLAND REVENUE NAMIB VAT'
      );
      //show first 10 recent payments
      this.filteredData = this.filteredData.slice(0, 10);
    });
  }
  makeNewTaxPayment(event) {
    this.router.navigate(['/payments/na-tax-payment/details']);
  }
  goBack() {
    return this.router.navigate(['/menu/payments']);
  }
}
