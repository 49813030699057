import { createReducer, on } from '@ngrx/store';
import { setVasPaymentFormData, setVasPaymentReceiptData } from '../actions/vasPayment.action';
import { VasPaymentState } from '../state/vasPayment.state';

const vasPaymentInitState = {};

const setVasPaymentReceiptState = {};

const vasPaymentData: VasPaymentState = {
  vasPaymentFormData: vasPaymentInitState,
  vasPaymentReceiptData: setVasPaymentReceiptState,
};
export const vasPaymentReducer = createReducer(
  vasPaymentData,
  on(setVasPaymentFormData, (state, { vasPaymentFormData }) => ({ ...state, vasPaymentFormData })),

  on(setVasPaymentReceiptData, (state, { vasPaymentReceiptData }) => ({
    ...state,
    vasPaymentReceiptData,
  }))
);
