import { TransactionManagementService } from '../../../../../core/transaction-management/transaction-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BulkFileState } from '../../../../../shared/store-utilities/state/bulkFile.state';
import { DataSharingService } from '../../../../../core/data-sharing/data-sharing.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DateUtil } from '../../../../../core/utility-classes/date.util'
@Component({
  templateUrl: './bulkFileUploadReview.component.html',
  styleUrls: ['./bulkFileUploadReview.component.scss'],
})
export class BulkFileUploadReviewComponent implements OnInit {
  @Select(BulkFileState.getBulkData) receiptModel$: Observable<any>;
  userIdentity;
  isSmeSoleProp: boolean;
  isSmeMode: boolean;
  fileDetails;
  selectedFile;
  formdata;

  constructor(
    private dataSharingService: DataSharingService,
    private activatedRoute: ActivatedRoute,
    private rxjsStore: Store<any>,
    public router: Router,
    public transaction: TransactionManagementService,
  ) {
  }

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Bulk file upload',
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'bulkFile',
    });

    this.receiptModel$.subscribe((data) => {
      if (data) {
        this.fileDetails = {...data};
        if (parseFloat(this.fileDetails.totalAmount)) {
          const totalAmount = {...this.fileDetails.totalAmount,
            amount: this.updateAmount(this.fileDetails.totalAmount.amount) };
            this.fileDetails = {...this.fileDetails, totalAmount};
        }
      }
    });
    this.rxjsStore.select('appReducer').subscribe((data) => {
      if (data) {
        this.userIdentity = data.loginReducer.authenticateResponse;
        this.isSmeSoleProp = this.userIdentity.userProfile.isSmeSoleProprietor;
        this.isSmeMode = data.loginReducer.isSmeMode;
      }
    });
    this.dataSharingService.getSelectedFile().subscribe((value) => {
      this.selectedFile = value;
    });
  }

  updateAmount(amount) {
    return amount.toFixed(2);
  }
  goBack() {
    return this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  goToReceipt() {
    this.formdata = new FormData();
    const files = this.selectedFile;
    this.formdata.append('uploadedFile', files[0]);
    this.fileDetails.processingDate = DateUtil.format(this.fileDetails.processingDate, DateUtil.DEFAULT_ISO);
    this.formdata.append('uploadedFileDetails', JSON.stringify(this.fileDetails));
    this.postService(this.formdata);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    if (payload.stepUp) {
      this.formdata.append('uploadedFileStepUp', JSON.stringify(payload.stepUp));
    }
    return this.transaction.uploadDocument(this.formdata).subscribe((response: any) => {
      this.dataSharingService.setHeaderResponse(response.headers);
      this.router.navigate(['/payments/bulkFileUpload/receipt']);
    });
  }
}
