import { SafePipe } from './shared/pipes/safeDom/safe.pipe';
import { LogoutState } from './shared/store-utilities/state/logout.state';
import { LogoutMiddleware } from './shared/store-utilities/middleware/logout.service';
import { FormsModule } from '@angular/forms';
import { OtpService } from './services/OtpService/otp.service';
import { OverDraftDetails } from './shared/store-utilities/state/overdraft.state';
import { AccountSummaryState } from './shared/store-utilities/state/accountSum.state';
import { NotificationDetailsState } from './shared/store-utilities/state/notification.state';
import { NotifyComponent } from './components/notification/notifylist/notifylist.component';
import { FieldValidationService } from './services/fieldValidation/fieldValidation.service';
import { ApprovalsModule } from './components/approvals/approvals.module';
import { OtpDetailsState } from './shared/store-utilities/state/otp.state';
import { OtpComponent } from './components/otp/otp.component';
import { MenuComponent } from './components/menu/menu.component';
import { environment } from './../environments/environment';
import { OfferState } from './shared/store-utilities/state/offer.state';
import { FooterNavigationComponent } from './cms/footer-navigation/footer-navigation.component';
import { TermsAndConditionComponent } from './cms/termsAndConditions/termsandconditions.component';
import { ExitSurveyComponent } from './cms/exitSurvey/exitSurvey.component';
import { PrivacyAndSecurityComponent } from './cms/privacyAndSecurity/privacyandsecurity';
import { NeedHelpComponent } from './cms/needHelp/needhelp.component';
import { InformationComponent } from './cms/information/information.component';
import { DisclaimerComponent } from './cms/disclaimer/disclaimer.component';
import { LoaderInterceptorService } from './components/loader/loader-interceptor.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
//  component
import { AppComponent } from './app.component';

// services

import { HttpInterceptorService } from './core/http-interceptor/http-interceptor.service';
import { TranslateService } from './core/translate-service/translate.service';
import { ErrorHandlerService } from './core/error-handler/error-handler.service';
import { SignOutService } from './core/sign-out/sign-out.service';

// modules
import { StoreModule } from '@ngrx/store';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { appReducer } from './core/state-management/reducers/root-reducer';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';

// custom modules
import { AppRoutingModule } from './app-routing.module';
import { LoginModule } from './components/login/login.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ServicesModule } from './services/services.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoaderComponent } from './components/loader/loader.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { TransactionManagementModule } from './components/transaction-management/transaction-management.module';
import { ServiceRequestsModule } from './components/service-requests/service-requests.module';
import { PaymentsModule } from './components/payments/payments.module';
import { BuyModule } from './components/buy/buy.module';
import { ScheduleTransactionModule } from './components/schedule-transaction/schedule-transaction.module';
import { RegistrationModule } from './components/registration/registration.module';
import { ForgotPasswordModule } from './components/forgot-password/forgot-password.module';
import { ForcedScenariosModule } from './components/forced-scenarios/forced-scenarios.module';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { UplConfirmState } from './shared/store-utilities/state/uplConfirm.state';
import { CloseCurrentModuleComponent } from './components/close-current-module/close-current-module.component';

import { IdleTimeoutModuleComponent } from './components/idle-timeout-module/idle-timeout-module.component';
import { BulkFileState } from './shared/store-utilities/state/bulkFile.state';
import { RippleGlobalOptions, MAT_RIPPLE_GLOBAL_OPTIONS, ShowOnDirtyErrorStateMatcher, ErrorStateMatcher } from '@angular/material/core';
import { ApiState } from './shared/store-utilities/state/apiState';

const setupTranslateFactory = (service: TranslateService) => {
  return () => service.use('en');
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    OtpComponent,
    ErrorModalComponent,
    NotifyComponent,
    DisclaimerComponent,
    InformationComponent,
    NeedHelpComponent,
    PrivacyAndSecurityComponent,
    TermsAndConditionComponent,
    ExitSurveyComponent,
    FooterNavigationComponent,
    MenuComponent,
    CloseCurrentModuleComponent,
    IdleTimeoutModuleComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CoreModule,
    MatSliderModule,
    ServicesModule,
    BrowserAnimationsModule,
    FlexLayoutModule.withConfig({
      useColumnBasisZero: false,
      printWithBreakpoints: ['md', 'lt-lg', 'lt-xl', 'gt-sm', 'gt-xs'],
    }),
    // adding custom routes on hierarchical, for the routes to work efficient
    AppRoutingModule,
    SharedModule,
    LoginModule,
    PaymentsModule,
    BuyModule,
    ApprovalsModule,
    TransactionManagementModule,
    ServiceRequestsModule,
    HttpClientModule,
    ScheduleTransactionModule,
    RegistrationModule,
    ForgotPasswordModule,
    ForcedScenariosModule,
    NgxsModule.forRoot(
      [
        OfferState,
        UplConfirmState,
        OtpDetailsState,
        BulkFileState,
        NotificationDetailsState,
        AccountSummaryState,
        OverDraftDetails,
        LogoutState,
        ApiState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsLoggerPluginModule.forRoot({
      disabled: true,
    }),
    StoreModule.forRoot(
      {
        appReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    TranslateService,
    SignOutService,
    {
      provide: NGXS_PLUGINS,
      useValue: LogoutMiddleware,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/#',
    },
    OtpService,
    FieldValidationService,
    Title,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  entryComponents: [FooterNavigationComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
