import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServiceRequestsComponent } from './service-requests.component';
import { CommonModule } from '@angular/common';
import { NewServiceRequestComponent } from './new-service-request/new-service-request.component';
import { IndividualServiceRequestComponent } from './individual-service-request/individual-service-request.component';
import { AccountModificationRequestComponent } from './account-modification-request/account-modification-request.component';
import { ServiceRequestResultComponent } from './service-request-result/service-request-result.component';
import { LoanRequestComponent } from './loan-request/loan-request.component';
import { BlueSkyRequestComponent } from './blue-sky-request/blue-sky-request.component';
import { DepositBookRequestComponent } from './deposit-book-request/deposit-book-request.component';
import { FixedDepositBreakageComponent } from './fixed-deposit-breakage/fixed-deposit-breakage.component';
import { RenewFixedDepositComponent } from './renew-fixed-deposit/renew-fixed-deposit.component';
import { OverDraftRequestComponent } from './over-draft-request/over-draft-request.component';
import { ChequeBookRequestComponent } from './cheque-book-request/cheque-book-request.component';
import { StopChequeRequestComponent } from './stop-cheque-request/stop-cheque-request.component';
import { AtmDebitCardComponent } from './atm-debit-card/atm-debit-card.component';
import { EbankingLimitAdjustmentComponent } from './ebanking-limit-adjustment/ebanking-limit-adjustment.component';
import { BankCertifiedChequeComponent } from './bank-certified-cheque/bank-certified-cheque.component';
import { AtmLimitAdjustmentComponent } from './atm-limit-adjustment/atm-limit-adjustment.component';
import { CreditCardRequestComponent } from './credit-card-request/credit-card-request.component';
import { RenewSibekeloAccountComponent } from './renew-sibekelo-account/renew-sibekelo-account.component';
import { RequestInsuranceComponent } from './request-insurance/request-insurance.component';
import { FixedDepositInterestRateComponent } from './fixed-deposit-interest-rate/fixed-deposit-interest-rate.component';
import { CancelNoticeGivenAccountComponent } from './cancel-notice-given-account/cancel-notice-given-account.component';
import { GiveNoticeComponent } from './give-notice/give-notice.component';
import { PosReversalComponent } from './pos-reversal/pos-reversal.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ServiceRequestsComponent,
      },
      {
        path: 'newServiceRequest',
        component: NewServiceRequestComponent,
      },
      {
        path: 'individualServiceRequest',
        component: IndividualServiceRequestComponent,
      },
      {
        path: 'accountModificationRequest',
        component: AccountModificationRequestComponent,
      },
      {
        path: 'serviceRequestResult',
        component: ServiceRequestResultComponent,
      },
      {
        path: 'loanRequest',
        component: LoanRequestComponent,
      },
      {
        path: 'blueSkyRequest',
        component: BlueSkyRequestComponent,
      },
      {
        path: 'depositBookRequest',
        component: DepositBookRequestComponent,
      },
      {
        path: 'fixedDepositBreakage',
        component: FixedDepositBreakageComponent,
      },
      {
        path: 'renewFixedDeposit',
        component: RenewFixedDepositComponent,
      },
      {
        path: 'overDraftRequest',
        component: OverDraftRequestComponent,
      },
      {
        path: 'chequeBookRequest',
        component: ChequeBookRequestComponent,
      },
      {
        path: 'stopChequeRequest',
        component: StopChequeRequestComponent,
      },
      {
        path: 'atmDebitCardRequest',
        component: AtmDebitCardComponent,
      },
      {
        path: 'eBankingLimitRequest',
        component: EbankingLimitAdjustmentComponent,
      },
      {
        path: 'bankCertifiedCheque',
        component: BankCertifiedChequeComponent,
      },
      {
        path: 'atmLimitRequest',
        component: AtmLimitAdjustmentComponent,
      },
      {
        path: 'creditCardRequest',
        component: CreditCardRequestComponent,
      },
      {
        path: 'renewSibekeloAccountRequest',
        component: RenewSibekeloAccountComponent,
      },
      {
        path: 'requestInsurance',
        component: RequestInsuranceComponent,
      },
      {
        path: 'fixedDepositIntrestRate',
        component: FixedDepositInterestRateComponent,
      },
      {
        path: 'cancelNoticeGivenAccount',
        component: CancelNoticeGivenAccountComponent,
      },
      {
        path: 'giveNoticeOnNoticeDeposit',
        component: GiveNoticeComponent,
      },
      {
        path: 'POSReversal',
        component: PosReversalComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class ServiceRequestsRoutingModule {}
