import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { BuyRoutingModule } from './buy-routing.module';
import { BuyComponent } from './buy.component';
import { PrepaidService } from '../../core/prepaid-service/prepaid-service.service';

@NgModule({
  declarations: [BuyComponent],
  imports: [CommonModule, SharedModule, BuyRoutingModule],
  providers: [PrepaidService],
})
export class BuyModule {}
