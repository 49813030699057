import { Router } from '@angular/router';
import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { environment } from '../../../../environments/environment';

import { Store } from '@ngrx/store';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';

import { Component, OnInit } from '@angular/core';
import { McaHeader } from '../../../core/data/mca-header';
import { McaResponseType } from '../../../core/data/mca-response-type';

@Component({
  selector: 'app-approval-receipt',
  templateUrl: './approval-receipt.component.html',
  styleUrls: ['./approval-receipt.component.scss'],
})
export class ApprovalReceiptComponent implements OnInit {
 
  postApprovalData;
  bankLogo;
  responseCode;
  responseResult;
  processType: string;
  response = {
    type: '',
    message: '',
  };
  parseResponse = {
    success: [],
    failed: [],
  };
  config = environment.config;
  singleApprove = this.config.singleApprove;

  constructor(
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private appConstant: AppConstantService,
    private route: Router
  ) {}
  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Approval List',
      subTitle: 'Result',
      showBack: true,
      showBackFn: this.redirectToApproval.bind(this),
      showClose: true,
      steps: true,
      module: 'approvals',
    });
    this.bankLogo = this.appConstant.BankLogo;

    this.store.select('appReducer', 'approvalReducer').subscribe((stateData) => {
      this.postApprovalData = stateData.postApprovalData;

      if (stateData.approvalPayload.approvalStatusType) {
        this.processType = stateData.approvalPayload.approvalStatusType.toLowerCase();
      }

      // namibia only
      if (this.singleApprove && stateData.postApprovalData && stateData.postApprovalData.headers) {
        this.response = {
          type: stateData.postApprovalData.headers.get(McaHeader.X_SBG_RESPONSE_TYPE),
          message: stateData.postApprovalData.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE),
        };
      }

      if (
        !this.singleApprove &&
        stateData.postApprovalData &&
        stateData.postApprovalData.headers.get(McaHeader.X_SBG_RESPONSE_TYPE) === McaResponseType.ERROR
      ) {
        this.response = {
          type: McaResponseType.ERROR,
          message: 'There was a problem with the transaction. Please check your approval list after 10 min to confirm and Try again.',
        };
      }
      if (!this.singleApprove && this.postApprovalData && this.postApprovalData.body && this.postApprovalData.body.transactionResults) {
        this.postApprovalData.body.transactionResults.forEach((transaction) => {
          if (transaction.responseCode.responseType === McaResponseType.ERROR) {
            this.parseResponse.failed.push(transaction);
          } else {
            this.parseResponse.success.push(transaction);
          }
        });
      }
    });
  }
  redirectToApproval() {
    this.route.navigate(['/approvals']);
  }
}
