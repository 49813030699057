import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SelfFundDetailsComponent } from './self-fund-details/self-fund-details.component';
import { SelfFundConfirmComponent } from './self-fund-confirm/self-fund-confirm.component';
import { SelfFundReceiptComponent } from './self-fund-receipt/self-fund-receipt.component';

const selfFundRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'details',
        component: SelfFundDetailsComponent,
      },
      {
        path: 'confirm',
        component: SelfFundConfirmComponent,
      },
      {
        path: 'receipt',
        component: SelfFundReceiptComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(selfFundRoutes)],
  exports: [RouterModule],
})
export class SelfFundRoutingModule {}
