import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { CommonServiceService } from '../../../../core/common-service/common-service.service';
import { ConfirmationScreenService } from '../../../../core/confirmation-screen-service/confirmation-screen.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { DownloadService } from '../../../../core/download-service/download.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { SnackBarService } from '../../../../services/snack-bar-service/snackBarService';
import { setFinalRecieptData } from '../../../../shared/store-utilities/actions/pay-beneficiary.action';
import { Amount } from '../../../../core/data/amount';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { formatDate } from '@angular/common';
import { DateUtil } from '../../../../core/utility-classes/date.util';

export interface ScheduleTimes {
  value?: string;
  placeholder?: string;
  id?: string;
}

@Component({
  selector: 'sbg-pay-beneficiary-receipt',
  templateUrl: './pay-beneficiary-receipt.component.html',
  styleUrls: ['./pay-beneficiary-receipt.component.scss'],
})
export class PayBeneficiaryReceiptComponent implements OnInit, OnDestroy {
  totalTransactionsMsg = '';
  fromAccountUpdatedData;
  isReccuringFirstDateToday: any;
  finalRecieptData: any;
  mainHeading: any;
  isPaymentScheduled: any;
  transPayload: any;
  PaymentReciept: any;
  paymentAmount: any = 0;
  totalPayment: any = 0;
  prevModel: any;
  totalTransactions: any = 0;
  completedTransactions: any = 0;

  date = new Date();
  transactionStatus: any;
  receipt: any;
  responseData: any;
  config = environment.config;
  bankLogo = this.config.bankLogo;
  beneficiaryName: string;

  errorArray: any[] = [];
  accounts: any[] = [];
  successArray: any[] = [];
  finalArray: any[] = [];
  txnStatusInfo: any;
  currentFlow: any;
  targetsName: any;
  transactionMessage: any;
  initiateItToday = false;
  detailedInfo: any;
  oftenSchedule: any;
  scheduleDate: any;
  numberOfPayments: any;
  transactionId;
  destroyed$ = new Subject<boolean>();

  scheduleFrequency: ScheduleTimes[] = [
    { value: 'Just Once', placeholder: 'once', id: '1' },
    { value: 'Daily', placeholder: 'daily', id: '2' },
    { value: 'Weekly', placeholder: 'weekly', id: '3' },
    { value: 'Monthly', placeholder: 'monthly', id: '4' },
    { value: 'Quarterly', placeholder: 'quarterly', id: '5' },
    { value: 'Yearly', placeholder: 'yearly', id: '6' },
  ];

  printData;

  isSme = false;
  private isSmeSoleProprietor = false;

  private readonly ONCE_OFF_PAY_FLOW = 'once-off-pay';

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private paymentUtilitiesService: PaymentUtilitiesService,
    private recipientService: RecipientService,
    private commonService: CommonServiceService,
    private snack: SnackBarService,
    private store: Store<{ appReducer }>,
    private elementRef: ElementRef,
    private confirmationScreenService: ConfirmationScreenService,
    private downloadService: DownloadService
  ) {
    store
      .select('appReducer', 'payBeneficiaryReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        this.PaymentReciept = _.cloneDeep(stateData.payBeneReviewData);
        this.currentFlow = this.PaymentReciept.currentFlow;
        this.fromAccountUpdatedData = this.isEditFlow() ? this.PaymentReciept.scheduledTransaction.fromAccount : this.PaymentReciept.account[0];

        this.finalRecieptData = _.cloneDeep(stateData.finalRecieptData);
        this.finalRecieptData.isData = !this.finalRecieptData.isData;

        if (this.currentFlow === this.ONCE_OFF_PAY_FLOW || this.currentFlow === 'edit-once-off') {
          this.getOnceOffData();
        } else {
          this.transPayload = stateData.payBeneDetailData;
          this.prevModel = stateData.payBeneDetailData.paymentModel;
          this.accounts = this.prevModel.accounts;
          this.totalPayment = this.prevModel.totalPayableAmount || 0;
          this.beneficiaryName = this.transPayload.transactions.beneficiaryPayments[0].beneficiary.name;
        }
        // Todo : require here -> $scope.requiredRate = DataCache.returnData("requiredRate");
      });
    this.store
      .select('appReducer', 'loginReducer')
      .pipe(take(1))
      .subscribe((stateData) => {
        if (stateData) {
          this.isSme = !!stateData.isSmeMode;
          if (stateData.authenticateResponse) {
            this.isSmeSoleProprietor = !!stateData.authenticateResponse.userProfile.isSmeSoleProprietor;
          }
        }
      });
      


    if (this.currentFlow === 'new-transaction') {
      this.mainHeading = 'Pay beneficiary';
    } else if (this.currentFlow === this.ONCE_OFF_PAY_FLOW) {
      this.mainHeading = 'Pay new recipient';
    } else {
      this.mainHeading = `Edit ${this.beneficiaryName} schedule`;
    }

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'payBeneficiary',
    });
  }

  ngOnInit() {
    if (this.isEditFlow()) {
      if (this.PaymentReciept.transactionResults && this.PaymentReciept.transactionResults.length > 0) {
        this.txnStatusInfo = this.PaymentReciept.transactionResults[0].responseCode;
      }

      this.detailedInfo = _.cloneDeep(this.PaymentReciept.scheduledTransaction);
      if (this.detailedInfo.futureDatedInstruction && this.prevModel.editReviewModel && this.prevModel.editReviewModel.futureDatedInstruction) {
        this.detailedInfo.futureDatedInstruction = this.prevModel.editReviewModel.futureDatedInstruction;
      } else if (
        this.transPayload &&
        this.transPayload.transactions &&
        this.transPayload.transactions.onceOffPayments &&
        this.transPayload.transactions.onceOffPayments.length &&
        this.transPayload.transactions.onceOffPayments[0].futureDatedInstruction
      ) {
        this.detailedInfo.futureDatedInstruction = this.transPayload.transactions.onceOffPayments[0].futureDatedInstruction;
      }
      this.detailedInfo.scheduledAmount = this.detailedInfo.amount;

      if (
        this.PaymentReciept.transactionResults &&
        this.PaymentReciept.transactionResults.length &&
        this.PaymentReciept.transactionResults[0].responseCode.message
      ) {
        this.transactionId = this.transactionService.getTxnId(this.PaymentReciept.transactionResults[0]);

        this.transactionMessage = this.PaymentReciept.transactionResults[0].responseCode.message;
        this.detailedInfo.responseType = this.PaymentReciept.transactionResults[0].responseCode.responseType;
      } else {
        if (DateUtil.areSame(new Date(), this.detailedInfo.nextPaymentDate, 'days')) {
          this.transactionMessage = 'Transaction with Reference ID ' + this.detailedInfo.futureDatedId + ' processed successfully';
        } else {
          if(this.isSme){
            this.transactionMessage =
              'Transaction with Reference ID ' + this.detailedInfo.futureDatedId + ' submitted successfully and is pending for corporate user approval';
          }
          else{
            this.transactionMessage =
            'Transaction with Reference ID ' + this.detailedInfo.futureDatedId + ' submitted successfully and is pending for processing';
          }
        }
      }

      if (this.prevModel.scheduleData && this.prevModel.scheduleData.numberOfPayments > 1) {
        this.isReccuringFirstDateToday = DateUtil.areSame(new Date(), this.prevModel.scheduleData.scheduleDate, 'days');
      }

      if (DateUtil.areSame(new Date(), this.detailedInfo.nextPaymentDate, 'days')) {
        this.initiateItToday = true;
      }

      this.numberOfPayments =
        this.detailedInfo.futureDatedInstruction && this.detailedInfo.futureDatedInstruction.repeatNumber
          ? this.detailedInfo.futureDatedInstruction.repeatNumber
          : 1;

      this.scheduleDate = moment(this.detailedInfo.nextPaymentDate);

      if (this.detailedInfo.futureDatedInstruction && this.detailedInfo.futureDatedInstruction.repeatInterval) {
        const oftenSchedule = this.scheduleFrequency.filter((val: any) => {
          return val.value.toUpperCase() === this.detailedInfo.futureDatedInstruction.repeatInterval.toUpperCase();
        });
        this.oftenSchedule = oftenSchedule[0];
      } else {
        this.oftenSchedule = this.scheduleFrequency[0];
      }
    } else {
      // Todo : to show total amount
      this.totalPayment = this.prevModel.totalPayableAmount;

      if (this.currentFlow === 'once-off-pay') {
        this.isPaymentScheduled = !DateUtil.areSame(new Date(), this.prevModel.currentDate, 'days');
      }

      this.receipt =
        this.currentFlow === 'once-off-pay' ? this.transPayload.transactions.onceOffPayments : this.transPayload.transactions.beneficiaryPayments;

      const totalCalculatedCharge = this.prevModel.totalCalculatedCharge || 0;
      this.paymentAmount = this.totalPayment - totalCalculatedCharge;
      this.paymentAmount = parseFloat(parseFloat(this.paymentAmount).toFixed(2));

      if (this.prevModel.scheduleData) {
        this.isPaymentScheduled = this.prevModel.scheduleData.isPaymentScheduled;

        if (this.prevModel.scheduleData.numberOfPayments > 1) {
          this.isReccuringFirstDateToday = DateUtil.areSame(new Date(), this.prevModel.scheduleData.scheduleDate, 'days');
          if (this.isReccuringFirstDateToday) {
            this.isPaymentScheduled = false;
          }
        }

        if (this.isPaymentScheduled) {
          this.transactionMessage =
            this.PaymentReciept.transactionResults &&
            this.PaymentReciept.transactionResults.length &&
            this.PaymentReciept.transactionResults[0].responseCode.message;
        }
      }

      this.displayReceipts();
    }
  }

  isEditFlow() {
    return this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off';
  }

  getOnceOffData() {
    this.store.select('appReducer', 'onceOffPayBeneficiaryReducer').subscribe((stateData) => {
      if (!this.transPayload) {
        this.transPayload = _.cloneDeep(stateData.onceOffPayBeneDetailData);
      }
      this.prevModel = this.transPayload.paymentModel;
      this.accounts = this.prevModel.accounts;
      this.prevModel.scheduleDate = this.transPayload.paymentModel.currentDate;
      this.totalPayment = this.prevModel.totalPayableAmount || 0;
      this.transactionMessage =
        this.PaymentReciept.transactionResults &&
        this.PaymentReciept.transactionResults.length &&
        this.PaymentReciept.transactionResults[0].responseCode.message;

      this.commonService.goToAccountUpdate(this.fromAccountUpdatedData);
      this.beneficiaryName = this.prevModel.benefName;
    });
  }

  displayReceipts() {
    this.PaymentReciept.transactionResults.forEach((val) => {
      let mergedObject: any = {};
      if (val.responseCode.responseType === 'ERROR') {
        mergedObject = {
          ...mergedObject,
          ...{
            responseType: 'ERROR',
            tileInfo: val,
            tileDetailInfo: this.paymentUtilitiesService.matchTransactionId(val, this.receipt),
          },
        };

        const tempObj: any = {
          ...mergedObject.tileInfo,
          txnId: this.transactionService.getTxnId(val),
          isRetry: val.availablePaymentNetworks && val.availablePaymentNetworks.length > 0,
        };

        if (
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation &&
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation.notifications &&
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation.notifications.length
        ) {
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation.notifications.forEach((element) => {
            if (element.type === 'SMS') {
              tempObj.mobile = element.address;
            } else if (element.type === 'EMAIL') {
              tempObj.email = element.address;
            }
          });
        }

        mergedObject = {
          ...mergedObject,
          tileInfo: tempObj,
        };
        this.errorArray.push(mergedObject);
      } else {
        mergedObject = {
          ...mergedObject,
          ...{
            responseType: 'SUCCESS',
            tileInfo: val,
            tileDetailInfo: this.paymentUtilitiesService.matchTransactionId(val, this.receipt),
          },
        };

        const tempObj: any = {
          ...mergedObject.tileInfo,
          txnId: this.transactionService.getTxnId(val),
        };

        if (
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation &&
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation.notifications &&
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation.notifications.length
        ) {
          mergedObject.tileDetailInfo.beneficiary.paymentConfirmation.notifications.forEach((element) => {
            if (element.type === 'SMS') {
              tempObj.mobile = element.address;
            } else if (element.type === 'EMAIL') {
              tempObj.email = element.address;
            }
          });
        }

        mergedObject = {
          ...mergedObject,
          tileInfo: tempObj,
        };

        this.successArray.push(mergedObject);
      }
    });

    if (this.errorArray && this.errorArray.length) {
      this.errorArray.forEach((val) => {
        this.successArray.push(val);
      });
    }

    this.finalArray = _.cloneDeep(this.successArray);
    this.finalArray.forEach((val) => {
      if (val.tileDetailInfo) {
        this.displayPaymentPriority(val, this.finalArray);
      }
    });
    this.totalTransactions = this.finalArray.length;
    this.completedTransactions = this.successArray.length;

    if (this.totalTransactions > 1 && this.currentFlow !== 'once-off-pay') {
      this.totalTransactionsMsg = this.completedTransactions + ' out of ' + this.totalTransactions + ' transactions completed.';
    }
  }

  displayPaymentPriority(val, finalArray) {
    //  TODO: Need to fix this part
    val.tileDetailInfo.beneficiary.showPaymentPriority =
      val.tileDetailInfo.beneficiary.route.networkType !== 'WIB' &&
      this.config.recipientsManagement.branchDropdown &&
      (this.config.multipleRtgPaymentAllowed || finalArray.length === 1);
    if (val.tileDetailInfo.beneficiary.showPaymentPriority) {
      val.tileDetailInfo.beneficiary.paymentType = this.setExpressPayOptions(val);
    }
  }

  setExpressPayOptions(val) {
    let paymentType;    
    const networkType = val.tileDetailInfo.beneficiary.route.networkType;
    const amount = parseFloat(val.tileDetailInfo.amount.amount);
    if (networkType === 'ACH' && (!this.config.onlyShowACHToRTGChangeNote || amount < this.config.amountLimitForAchToRtg)) {
      paymentType = 'Normal';
    } else if (networkType === 'RTG' || (this.config.onlyShowACHToRTGChangeNote && amount >= this.config.amountLimitForAchToRtg)) {
      paymentType = 'Express';
    }
    return paymentType;
  }

  showAddBeneficiaryPopup(i: number) {
    if (this.isSme && !this.isSmeSoleProprietor) {
      const decision = this.confirmationScreenService.showConfirmationScreen(
        'Add Beneficiary',
        true,
        'BENEFICIARY_MANAGEMENT',
        'Please select approver'
      );
      decision.subscribe((value) => {
        if (value) {
          this.addBeneficiaryFromOnceoff(i, this.dataSharingService.selectedApprover);
        }
      });
    } else {
      this.addBeneficiaryFromOnceoff(i);
    }
  }

  addBeneficiaryFromOnceoff(i: number, rule?: any) {
    const benef = this.finalArray[i].tileDetailInfo.beneficiary;

    const payload: any = {
      beneficiaries: [
        {
          recentPayment: [],
          name: benef.name,
          accountHolderFullName: benef.accountHolderFullName,
          accountNumber: benef.accountNumber,
          emailAddress: '',
          phoneNumber: '',
          recipientGroup: {
            name: null,
            orderIndex: 1,
          },
          customerReference: benef.customerReference,
          paymentConfirmation: {
            address: benef.paymentConfirmation && benef.paymentConfirmation.address,
            confirmationType: benef.paymentConfirmation && benef.paymentConfirmation.confirmationType,
            sendFutureDated: false,
          },
          keyValueMetadata: [],
          favourite: benef.favourite,
          recipientReference: benef.recipientReference,
          route: benef.route,
        },
      ],
      keyValueMetadata: [],
    };

    this.dataSharingService.setHeadPagination({
      mainTitle: 'Add New Beneficiary',
    });

    const routeToBeAdded = this.prevModel.networkTypes[0];
    payload.beneficiaries[0].route.networkType = routeToBeAdded.networkType;
    payload.beneficiaries[0].route.routingNumber = routeToBeAdded.routingNumber;

    if (rule) {
      payload.nonFinancialRule = rule;
    }

    this.postService(payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    return this.recipientService.putRecipient(payload).subscribe(() => {
      const beneName = payload.beneficiaries[0].name;
      this.snack.open(beneName + ' added successfully');
      this.store.dispatch(setFinalRecieptData({ finalRecieptData: { isData: true } }));
      this.router.navigate(['/payments/beneficiary/receipt']);
    });
  }

  getStatus(i: number): string {
    if (this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off') {
      return this.detailedInfo.responseType;
    } else {
      return this.finalArray[i].responseType;
    }
  }

  getMessage(i: number): string {
    if (this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off') {
      return this.detailedInfo.transactionMessage;
    } else {
      return this.finalArray[i].tileInfo.responseCode.message;
    }
  }

  getAmount(i: number): Amount {
    if (this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off') {
      return this.detailedInfo.amount;
    } else {
      return this.finalArray[i].tileDetailInfo.amount;
    }
  }

  getReceiptHeaders(i: number): string[] {
    const receiptHeaders = [];
    if (this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off') {
      receiptHeaders.push(
        this.detailedInfo.beneficiary.name,
        this.detailedInfo.beneficiary.route.branch.bank.name + '|' + this.detailedInfo.beneficiary.accountNumber
      );
    } else {
      if (this.transPayload.transactions.onceOffPayments) {
        receiptHeaders.push(
          this.finalArray[i].tileDetailInfo.beneficiary.accountHolderFullName || this.transPayload.transactions.onceOffPayments[0].beneficiary.name
        );
      }
      if (this.transPayload.transactions.beneficiaryPayments) {
        receiptHeaders.push(this.finalArray[i].tileDetailInfo.beneficiary.name);
      }
      if (
        this.finalArray[i].tileDetailInfo.beneficiary &&
        this.finalArray[i].tileDetailInfo.beneficiary.route &&
        this.finalArray[i].tileDetailInfo.beneficiary.route.branch &&
        this.finalArray[i].tileDetailInfo.beneficiary.route.branch.bank &&
        this.finalArray[i].tileDetailInfo.beneficiary.route.branch.bank.name
      ) {
        receiptHeaders.push(this.finalArray[i].tileDetailInfo.beneficiary.route.branch.bank.name);
      }
      if (this.finalArray[i].tileDetailInfo.beneficiary && this.finalArray[i].tileDetailInfo.beneficiary.accountNumber) {
        receiptHeaders.push(this.finalArray[i].tileDetailInfo.beneficiary.accountNumber);
      }
      if (
        this.config.recipientsManagement &&
        this.config.recipientsManagement.branchDropdown &&
        this.transPayload.transactions.onceOffPayments &&
        !this.finalArray[i].tileDetailInfo.beneficiary.isExpressPay &&
        this.finalArray[i].tileDetailInfo.beneficiary &&
        this.finalArray[i].tileDetailInfo.beneficiary.route &&
        this.finalArray[i].tileDetailInfo.beneficiary.route.branch
      ) {
        receiptHeaders.push(
          (this.finalArray[i].tileDetailInfo.beneficiary.route.branch.name
            ? this.finalArray[i].tileDetailInfo.beneficiary.route.branch.name + '|'
            : '') + this.finalArray[i].tileDetailInfo.beneficiary.route.branch.number
            ? this.finalArray[i].tileDetailInfo.beneficiary.route.branch.number
            : ''
        );
      }
    }
    return receiptHeaders;
  }

  getReceiptDetails(i: number): ReceiptDetail[] {
    const resultData = [];
    if (this.currentFlow === 'edit-transaction' || this.currentFlow === 'edit-once-off') {
      resultData.push({
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.transactionId,
      });
      if (this.transPayload.account && this.transPayload.account.accountFullName) {
        resultData.push({
          header: ReceiptHeaders.FROM,
          value: this.transPayload.account.accountFullName || '-',
        });
      }
      resultData.push({
        header: ReceiptHeaders.DATE,
        value: formatDate(this.scheduleDate, 'dd MMMM yyyy', 'en-US'),
      });
      if (this.config.intermediatedMoneyTransferTax && !this.transPayload.transactions.onceOffPayments) {
        resultData.push({
          header: ReceiptHeaders.PURPOSE_OF_PAYMENT,
          value: this.transPayload.transactions.beneficiaryPayments[0].beneficiary.moneyTransferTaxCode.value,
        });
      }
      if (
        this.detailedInfo &&
        this.detailedInfo.beneficiary &&
        this.detailedInfo.beneficiary.paymentType &&
        this.detailedInfo.beneficiary.route.networkType !== 'WIB' &&
        !this.config.recipientPaymentPriority
      ) {
        resultData.push({
          header: ReceiptHeaders.PAYMENT_PRIORITY,
          value: this.detailedInfo.beneficiary.paymentType,
        });
      }
      if (
        this.detailedInfo &&
        this.detailedInfo.beneficiary &&
        this.detailedInfo.beneficiary.address &&
        this.detailedInfo.beneficiary.address.addressLine1
      ) {
        resultData.push({
          header: ReceiptHeaders.BENEFICIARY_ADDRESS,
          value: this.detailedInfo.beneficiary.address.addressLine1,
        });
      }
      resultData.push(
        {
          header: ReceiptHeaders.MY_REFERENCE,
          value: this.detailedInfo.beneficiary.customerReference,
        },
        {
          header: ReceiptHeaders.THEIR_REFERENCE,
          value: this.detailedInfo.beneficiary.recipientReference,
        }
      );
    } else {
      if (this.finalArray[i] && this.finalArray[i].tileInfo && this.finalArray[i].tileInfo.txnId) {
        resultData.push({
          header: ReceiptHeaders.TRANSACTION_ID,
          value: this.finalArray[i].tileInfo.txnId,
        });
      }
      if (this.transPayload.account && this.transPayload.account.accountFullName) {
        resultData.push({
          header: ReceiptHeaders.FROM,
          value: this.transPayload.account.accountFullName,
        });
      }
      resultData.push({
        header: ReceiptHeaders.DATE,
        value: formatDate(this.date.toString(), 'mediumDate', 'en-US', this.config.timeOffsetInHolidays),
      });
      if (this.config.intermediatedMoneyTransferTax && this.currentFlow !== this.ONCE_OFF_PAY_FLOW) {
        resultData.push({
          header: ReceiptHeaders.PURPOSE_OF_PAYMENT,
          value: this.finalArray[i].tileDetailInfo.moneyTransferTaxCode.value,
        });
      }
      if (
        this.finalArray[i].tileDetailInfo.beneficiary.route &&
        this.finalArray[i].tileDetailInfo.beneficiary.route.networkType !== 'WIB' &&
        !this.config.recipientPaymentPriority
      ) {
        resultData.push({
          header: ReceiptHeaders.PAYMENT_PRIORITY,
          value: this.finalArray[i].tileDetailInfo.beneficiary.paymentType,
        });
      }
      if (
        this.finalArray[i].tileDetailInfo &&
        this.finalArray[i].tileDetailInfo.beneficiary &&
        this.finalArray[i].tileDetailInfo.beneficiary.address &&
        this.finalArray[i].tileDetailInfo.beneficiary.address.addressLine1
      ) {
        resultData.push({
          header: ReceiptHeaders.BENEFICIARY_ADDRESS,
          value: this.finalArray[i].tileDetailInfo.beneficiary.address.addressLine1,
        });
      }
      resultData.push(
        {
          header: ReceiptHeaders.MY_REFERENCE,
          value: this.finalArray[i].tileDetailInfo.beneficiary.customerReference,
        },
        {
          header: ReceiptHeaders.THEIR_REFERENCE,
          value: this.finalArray[i].tileDetailInfo.beneficiary.recipientReference,
        }
      );
    }
    return resultData;
  }

  goToDetails() {
    if (this.isEditFlow()) {
      return this.router.navigate(['./scheduleTransaction']);
    } else if (this.currentFlow === 'once-off-pay' || this.currentFlow === 'new-transaction') {
      return this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
    } else {
      return this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
    }
  }

  printReceipt(index) {
    this.downloadService.printReceipt(this.elementRef.nativeElement, '.print-data-' + index);
  }

  downloadReceipt(index) {
    this.downloadService.downloadReceipt(this.elementRef.nativeElement, '.print-data-' + index);
  }

  ngOnDestroy() {
    this.dataSharingService.selectedApprover = null;
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
