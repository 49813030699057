import { createReducer, on } from '@ngrx/store';

import * as tvSubAction from '../actions/tvsubscription.action';
import { TVSubState } from '../state/tvsubscription.state';

const selectedTVSubInitState = {
  amount: {},
  basePrepaidProvider: {},
  date: null,
  description: '',
  fileSequenceNumber: '',
  initiatorAccount: '',
  rechargeNumber: '',
  referenceId: '',
  status: '',
  transactionId: '',
  type: '',
};

const tvSubInitData: TVSubState = {
  selectedTVSubData: selectedTVSubInitState,
  receiptList: [{}],
  tvSubData: null,
  tvSubReceiptData: {},
};
export const tvSubReducer = createReducer(
  tvSubInitData,
  on(tvSubAction.setSelectedTVSubData, (state, { selectedTVSubData }) => ({ ...state, selectedTVSubData })),
  on(tvSubAction.setTVSubReceiptListData, (state, { receiptList }) => {
    // console.log(receiptList);
    return { ...state, receiptList };
  }),
  on(tvSubAction.setTVSubData, (state, { tvSubData }) => {
    return { ...state, tvSubData };
  }),
  on(tvSubAction.setTVSubReceiptData, (state, { tvSubReceiptData }) => {
    return { ...state, tvSubReceiptData };
  })
);
