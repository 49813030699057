import { createReducer, on } from '@ngrx/store';
import * as intPaymentAction from '../actions/internationalPayment.action';
import { InternationalPaymentState } from '../state/internationalPayment.state';

const intPaymentInitData: InternationalPaymentState = {
  beneDetails: {},
  routeData: {},
  preInitiateData: {},
  libraData: {},
  BopEvaluationResponseData: {},
  receiptData: {},
  beneficiaryPayload: {},
};
export const intPaymentReducer = createReducer(
  intPaymentInitData,
  on(intPaymentAction.beneDetails, (state, { beneDetails }) => ({
    ...state,
    beneDetails,
  })),
  on(intPaymentAction.routeData, (state, { routeData }) => ({
    ...state,
    routeData,
  })),
  on(intPaymentAction.preInitiateData, (state, { preInitiateData }) => ({
    ...state,
    preInitiateData,
  })),
  on(intPaymentAction.libraData, (state, { libraData }) => ({
    ...state,
    libraData,
  })),
  on(intPaymentAction.BopEvaluationResponseData, (state, { BopEvaluationResponseData }) => ({
    ...state,
    BopEvaluationResponseData,
  })),
  on(intPaymentAction.receiptData, (state, { receiptData }) => ({
    ...state,
    receiptData,
  })),
  on(intPaymentAction.exchangeRateType, (state, { exchangeRateType }) => ({
    ...state,
    exchangeRateType,
  })),
  on(intPaymentAction.paymentPayload, (state, { paymentPayload }) => ({
    ...state,
    paymentPayload,
  })),
  on(intPaymentAction.beneficiaryPayload, (state, { beneficiaryPayload }) => ({
    ...state,
    beneficiaryPayload,
  })),
);
