import { Subject, Subscription, takeUntil } from 'rxjs';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { BankingMetadataService } from '../../../core/banking-metadata/banking-metadata.service';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { environment } from '../../../../environments/environment';
import { PaymentUtilitiesService } from '../../../core/payment-utilities/payment-utilities.service';
import { SmeServiceService } from '../../../services/sme-service/sme-service.service';
import { setPostApprovalData, setPreInitiatedInternationalPaymentData } from '../../../shared/store-utilities/actions/approval.action';
import * as _ from 'lodash';
import { DateUtil } from '../../../core/utility-classes/date.util';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-international-payment-approval',
  templateUrl: './international-payment-approval.component.html',
  styleUrls: ['./international-payment-approval.component.scss'],
})
export class InternationalPaymentApprovalComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  selectedApprovalData: any;
  bopCodes;
  transactionData: any;
  headerDetails: any;
  accountName: any;
  selectedAccount: any;
  config = environment.config;
  selectedApprovalType: any;
  timer = 90;
  remarkModal = {
    inputHeader: '',
  };
  isRequired = true;
  subcriptions: Subscription[] = [];
  subs1$: Subscription;
  subs2$: Subscription;
  timerEnabled: boolean = environment.config.payments.internationalPayment.timerEnabled;
  exchangeRateEnabled: boolean = environment.config.payments.internationalPayment.exchangeRateEnabled;
  dataToBeDisplayed;
  validate_5kUser: boolean;
  otherData: any;
  documentList: any;
  disableRightButton: boolean;
  window: any;
  tradeId: any;
  
  constructor(
    private store: Store<any>,
    private dataSharingService: DataSharingService,
    private router: Router,
    private bankingMetadata: BankingMetadataService,
    private accountsIterator: AccountsIteratorService,
    private paymentUtilities: PaymentUtilitiesService,
    private smeService: SmeServiceService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;

    window['inner']('other');
  }

  ngOnInit() {
    this.store
      .select('appReducer', 'approvalReducer')
      .subscribe((stateData) => {
        this.selectedApprovalData = _.cloneDeep(stateData.selectedApprovalData);
        this.selectedApprovalType = stateData.selectedApprovalType;
        this.bopCodes = this.paymentUtilities.getPurposeCode(this.selectedApprovalData);
        this.selectedAccount = this.accountsIterator.getAccountFromNumber(this.selectedApprovalData.financialTransaction.fromAccount.number);
        this.dataToBeDisplayed = {
          ...this.selectedApprovalData.financialTransaction,
        };
        if(this.config.trade === 'mvp1' && this.config.countryName !== 'Namibia') {
          this.setPayloadIpay(this.selectedApprovalData);
        } else if (this.config.countryName !== 'Namibia') {
          this.setPayload(this.selectedApprovalData);
        }
      })
      .unsubscribe();
    // this.paymentUtilities.getPurposeCode(this.selectedApprovalData);
    // this.subs1$ = this.dataSharingService.getBopCode().subscribe((bopCodeData) => {
    //   if (bopCodeData && this.config.localCurrency === 'ZMW') {
    //     this.bopCodes = bopCodeData;
    //     console.log(this.bopCodes);
    //   } else {
    //     this.bopCodes = {};
    //   }
    // });
    // this.subcriptions.push(this.subs1$);
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Approve',
      subTitle: 'Confirm details',
      showBack: true,
      showBackFn: this.goBack.bind(this),
      showClose: true,
      steps: true,
      module: 'internationalPaymentsApproval',
    });
    this.setHeaderDetails();

    // Timer is not applicable in Lesotho
    if (this.config.localCurrency !== 'LSL') {
      setInterval(this.startTicker.bind(this), 1000);
    }
    if (this.config.countryName === 'Zambia' || this.config.countryName === 'Tanzania'|| this.config.countryName === 'Namibia') {
      const valueValidatePayload = {
        amount: {
          amount: this.dataToBeDisplayed.amount.amount,
          currency: this.dataToBeDisplayed.amount.currency,
        },
      };
      if(!this.dataToBeDisplayed.cmaCountry){
      this.bankingMetadata
        .getValueValidations(valueValidatePayload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          if (data.body.documentUploadRequired) {
            this.validate_5kUser = true;
          }
          if (this.validate_5kUser) {
            const documentpayload = {
              transactionId: this.dataToBeDisplayed.referenceId || this.otherData.referenceId,
            };
            const preInitiateDataPayload = {
              ...this.selectedApprovalData,
              ...{
                indicativeRateRequired: data.body.indicativeRateRequired,
                convertedAmount: data.body.convertedAmount,
              },
            };
            this.setPayloadIpay(preInitiateDataPayload);
            this.smeService
              .getDocumentLists(documentpayload)
              .pipe(takeUntil(this.destroyed$))
              .subscribe((data: any) => {
                if (data.body) {
                  this.documentList = data['body'].internationalPaymentDocuments;
                }
              });
            if (this.validate_5kUser && this.documentList?.length === 0) {
              this.disableRightButton = false;
            }
          }
        });
      }
    }
  }
  startTicker() {
    if (this.timer > 0) {
      this.timer = this.timer - 1;
    }
  }

  setHeaderDetails() {
    this.headerDetails = {
      name: this.selectedApprovalData.financialTransaction.toBeneficiary.accountHolderFullName,
      first: this.selectedApprovalData.financialTransaction.toBeneficiary.accountNumber,
    };
  }
  setPayload(dataforPayload) {
    const payload = {
      baseBeneficiaryPayment: {
        transactionId: dataforPayload.financialTransaction.referenceId,
        type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
        amount: {
          amount: dataforPayload.financialTransaction.amount.amount,
          currency: dataforPayload.financialTransaction.amount.currency,
        },
        beneficiary: {
          name: dataforPayload.financialTransaction.toBeneficiary.name,
          address: {
            addressLine1: dataforPayload.financialTransaction.toBeneficiary.address.addressLine1,
          },
          accountNumber: dataforPayload.financialTransaction.toBeneficiary.accountNumber,
          recipientReference: dataforPayload.financialTransaction.toBeneficiary.recipientReference,
          customerReference: dataforPayload.financialTransaction.toBeneficiary.customerReference,
          route: dataforPayload.financialTransaction.toBeneficiary.route,
          keyValueMetadata: [
            {
              key: 'FEB_ACCOUNT_TYPE_CODE',
              value: 'SBA',
            },
          ],
        },
      },
      accountFrom: {
        number: dataforPayload.financialTransaction.fromAccount.number,
        accountCurrency: {
          currencyCode: dataforPayload.financialTransaction.accountCurrencyTransactionAmount.currency,
        },
        availableBalance: {
          amount: this.selectedAccount[0].availableBalance.amount,
          currency: this.selectedAccount[0].availableBalance.currency,
        },
        route: {
          routingNumber: dataforPayload.financialTransaction.toBeneficiary.route.routingNumber,
        },
        keyValueMetadata: [
          {
            key: 'FEB_ACCOUNT_TYPE_CODE',
            value: 'SBA',
          },
        ],
      },
      reasonForTransaction: {
        key: 'OFT',
        value: '',
      },
      sourceOfFunds: {
        key: 'S',
        value: '',
      },
      documentReference: 'OFL',
      autoSave: false,
    };
    if(!this.dataToBeDisplayed.cmaCountry){
    this.subs2$ = this.bankingMetadata
      .internationalPaymentPreInitialisation(payload)
      .subscribe((response) => {
        this.transactionData = response.body.preInitiatedData;
        this.selectedApprovalData.ipamounts = _.cloneDeep(response.body.preInitiatedData);
        this.selectedApprovalData.ipamounts.globalMarketValueDate = DateUtil.format(
          this.selectedApprovalData.ipamounts.globalMarketValueDate,
          DateUtil.ISO_DD_MM_YYYY_FULL_TIME
        );
        this.store.dispatch(setPreInitiatedInternationalPaymentData({ preInitiatedInternationalPaymentData: response }));
      });
      this.subcriptions.push(this.subs2$);
    }
  }
  setPayloadIpay(dataforPayload) {
    if (this.config.countryName !== 'Namibia') {
      const valueValidatePayload = {
        amount: {
          amount: this.dataToBeDisplayed.amount.amount,
          currency: this.dataToBeDisplayed.amount.currency,
        },
      };
      if(!this.dataToBeDisplayed.cmaCountry){
      this.bankingMetadata
        .getValueValidations(valueValidatePayload)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          const payload = {
            baseBeneficiaryPayment: {
              transactionId: dataforPayload.financialTransaction.referenceId,
              type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
              amount: {
                amount: dataforPayload.financialTransaction.amount.amount,
                currency: dataforPayload.financialTransaction.amount.currency,
              },
              beneficiary: {
                name: dataforPayload.financialTransaction.toBeneficiary.name,
                address: {
                  addressLine1: dataforPayload.financialTransaction.toBeneficiary.address.addressLine1,
                },
                accountNumber: dataforPayload.financialTransaction.toBeneficiary.accountNumber,
                recipientReference: dataforPayload.financialTransaction.toBeneficiary.recipientReference,
                customerReference: dataforPayload.financialTransaction.toBeneficiary.customerReference,
                route: dataforPayload.financialTransaction.toBeneficiary.route,
                keyValueMetadata: [
                  {
                    key: 'FEB_ACCOUNT_TYPE_CODE',
                    value: 'SBA',
                  },
                ],
              },
            },
            accountFrom: {
              number: dataforPayload.financialTransaction.fromAccount.number,
              accountCurrency: {
                currencyCode: dataforPayload.financialTransaction.accountCurrencyTransactionAmount.currency,
              },
              availableBalance: {
                amount: this.selectedAccount[0].availableBalance.amount,
                currency: this.selectedAccount[0].availableBalance.currency,
              },
              route: {
                routingNumber: dataforPayload.financialTransaction.toBeneficiary.route.routingNumber,
              },
              keyValueMetadata: [
                {
                  key: 'FEB_ACCOUNT_TYPE_CODE',
                  value: 'SBA',
                },
              ],
            },
            reasonForTransaction: {
              key: 'OFT',
              value: '',
            },
            sourceOfFunds: {
              key: 'S',
              value: '',
            },
            documentReference: 'OFL',
            autoSave: false,
          };
          const preInitiateDataPayload = {
            ...payload,
            ...{ indicativeRateRequired: data.body.indicativeRateRequired, convertedAmount: data.body.convertedAmount },
          };
          if(!this.dataToBeDisplayed.cmaCountry){
          this.subs2$ = this.bankingMetadata.internationalPaymentPreInitialisation(preInitiateDataPayload).subscribe((response) => {
              this.transactionData = response.body.preInitiatedData;
              this.tradeId = this.transactionData.metroTradeId;
              this.selectedApprovalData.ipamounts = _.cloneDeep(response.body.preInitiatedData);
              this.selectedApprovalData.ipamounts.globalMarketValueDate = DateUtil.format(
                this.selectedApprovalData.ipamounts.globalMarketValueDate,
                DateUtil.ISO_DD_MM_YYYY_FULL_TIME
              );
              this.store.dispatch(setPreInitiatedInternationalPaymentData({ preInitiatedInternationalPaymentData: response }));
            });
          }
        });
      }
    }
    if (this.config.countryName === 'Namibia') {
      const payload = {
        baseBeneficiaryPayment: {
          transactionId: dataforPayload.financialTransaction.referenceId,
          type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
          amount: {
            amount: dataforPayload.financialTransaction.amount.amount,
            currency: dataforPayload.financialTransaction.amount.currency,
          },
          beneficiary: {
            name: dataforPayload.financialTransaction.toBeneficiary.name,
            address: {
              addressLine1: dataforPayload.financialTransaction.toBeneficiary.address.addressLine1,
            },
            accountNumber: dataforPayload.financialTransaction.toBeneficiary.accountNumber,
            recipientReference: dataforPayload.financialTransaction.toBeneficiary.recipientReference,
            customerReference: dataforPayload.financialTransaction.toBeneficiary.customerReference,
            route: dataforPayload.financialTransaction.toBeneficiary.route,
            keyValueMetadata: [
              {
                key: 'FEB_ACCOUNT_TYPE_CODE',
                value: 'SBA',
              },
            ],
          },
        },
        accountFrom: {
          number: dataforPayload.financialTransaction.fromAccount.number,
          accountCurrency: {
            currencyCode: dataforPayload.financialTransaction.accountCurrencyTransactionAmount.currency,
          },
          availableBalance: {
            amount: this.selectedAccount[0].availableBalance.amount,
            currency: this.selectedAccount[0].availableBalance.currency,
          },
          route: {
            routingNumber: dataforPayload.financialTransaction.toBeneficiary.route.routingNumber,
          },
          keyValueMetadata: [
            {
              key: 'FEB_ACCOUNT_TYPE_CODE',
              value: 'SBA',
            },
          ],
        },
        reasonForTransaction: {
          key: 'OFT',
          value: '',
        },
        sourceOfFunds: {
          key: 'S',
          value: '',
        },
        documentReference: 'OFL',
        autoSave: false,
      };
      const preInitiateDataPayload = {
        ...payload,
        ...{
          indicativeRateRequired: dataforPayload.indicativeRateRequired,
          convertedAmount: dataforPayload.convertedAmount,
        },
      };
if(!this.dataToBeDisplayed.cmaCountry){
      this.subs2$ = this.bankingMetadata
        .internationalPaymentPreInitialisation(preInitiateDataPayload)
        .subscribe((response) => {
          this.transactionData = response.body.preInitiatedData;
          this.tradeId = this.transactionData.metroTradeId;
          this.selectedApprovalData.ipamounts = _.cloneDeep(response.body.preInitiatedData);
          this.selectedApprovalData.ipamounts.globalMarketValueDate = DateUtil.format(
            this.selectedApprovalData.ipamounts.globalMarketValueDate,
            DateUtil.ISO_DD_MM_YYYY_FULL_TIME
          );
          this.store.dispatch(
            setPreInitiatedInternationalPaymentData({
              preInitiatedInternationalPaymentData: response,
            })
          );
        });
        this.subcriptions.push(this.subs2$);
      }
    }
  }
  goBack() {
    this.router.navigate(['/approvals']);
  }

  approvalAction(remarks) {
    const approvals = [];
    if(!this.dataToBeDisplayed.cmaCountry){
    this.selectedApprovalData.ipamounts.accountCurrencyTransactionAmount =
     this.selectedApprovalData.financialTransaction.accountCurrencyTransactionAmount;
    }
    const payloadData = {
      // action: this.selectedApprovalData.action,
      approvalRecordId: this.selectedApprovalData.financialTransaction.referenceId,
      approvalTransactionType: this.selectedApprovalData.financialTransaction.type,
      internationalPaymentPreInitiatedData: this.selectedApprovalData.ipamounts,
      lastApprover: this.selectedApprovalData.financialTransaction.lastApprover,
    };
    approvals.push(payloadData);
    let payload = {
      approvalStatusType: this.selectedApprovalType,
      approvals,
      // selectedApprovalId
    };
    if (remarks.trim()) {
      payload = { ...payload, ...{ remarks } };
    }
    this.approvalPostService(payload);
    this.dataSharingService.setPostService(this.approvalPostService.bind(this));
  }

  approvalPostService(payload) {
    this.smeService.approval(payload).subscribe((response: any) => {
      this.store.dispatch(setPostApprovalData({ approvalPayload: payload, postApprovalData: response }));
      this.router.navigate(['/approvals/international-payment-approval-receipt']);
    });
  }
  ngOnDestroy() {
    this.subcriptions.forEach((subs) => {
      if (subs !== undefined) {
        subs.unsubscribe();
      }
    });
  }
}
