import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DataSharingService } from '../../../../../core/data-sharing/data-sharing.service';
import { BulkFileState } from '../../../../../shared/store-utilities/state/bulkFile.state';
import { OtpDetailsState } from '../../../../../shared/store-utilities/state/otp.state';
import { Amount } from '../../../../../core/data/amount';
import { ReceiptDetail } from '../../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../../shared/components/receipt/receipt-headers';
import { DateUtil } from '../../../../../core/utility-classes/date.util';
import { McaHeader } from '../../../../../core/data/mca-header';
import { environment } from '../../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-bulkfile-receipt',
  templateUrl: './bulkFileUploadReceipt.component.html',
  styleUrls: ['./bulkFileUploadReceipt.component.scss'],
})
export class BulkFileReceiptComponent implements OnInit {
  @Select(BulkFileState.getBulkData) getBulkDataModel$: Observable<any>;
  @Select(OtpDetailsState.getOtpDetails) receiptModel$: Observable<any>;
  fileDetails;
  responseMessage;
  responseType;
  config = environment.config;

  constructor(private dataSharing: DataSharingService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Bulk file upload',
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'bulkFile',
    });
    this.getBulkDataModel$.subscribe((data) => {
      if (data) {
        this.fileDetails = data;
      }
    });
    this.dataSharing.getHeaderResponse().subscribe((data) => {
      this.responseMessage = data.get(McaHeader.X_SBG_RESPONSE_MESSAGE);
      this.responseType = data.get(McaHeader.X_SBG_RESPONSE_TYPE);
    });
  }

  getStatus(): string {
    return this.responseType;
  }

  getMessage(): string {
    return this.responseMessage;
  }

  getAmount(): Amount {
    return this.fileDetails.totalAmount;
  }

  getReceiptDetails(): ReceiptDetail[] {
    const resultData = [];
    resultData.push(
      {
        header: ReceiptHeaders.FILE_NAME,
        value: this.fileDetails.document.description,
      },
      {
        header: ReceiptHeaders.PROCESSING_DATE,
        value: DateUtil.format(this.fileDetails.processingDate, DateUtil.DD_MMMM_YYYY),
      }
    );
    if (this.config.intermediatedMoneyTransferTax) {
      resultData.push({
        header: ReceiptHeaders.IMTT_CHARGE_APPLICABLE,
        value: this.fileDetails.moneyTransferTaxCharge ? 'Yes' : 'No',
      });
    }
    return resultData;
  }

  goToDetails() {
    this.dataSharing.setBulkFileData({});
    this.router.navigate(['../details'], {
      relativeTo: this.route,
    });
  }
}
