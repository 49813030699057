import { Pipe, PipeTransform } from '@angular/core';
import { NumberFormatService } from '../../../services/number-format/number-format.service';
@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private numberFormatService: NumberFormatService) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(number: string, args?: any): string {
    return this.numberFormatService.formatCardNumber(number);
  }
}
