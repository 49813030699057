import { Component, Inject, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../../cms/footer-navigation/footer-navigation.component';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-registration-footer',
  templateUrl: './registration-footer.component.html',
  styleUrls: ['./registration-footer.component.scss'],
})
export class RegistrationFooterComponent implements OnInit {
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  loginMode = this.document['preLoginMode'];
  constructor(
    public dataSharing: DataSharingService,
    public dialog: MatDialog,
    private store: Store<any>,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    if (this.loginMode === 'SME') {
      this.contactNumber = this.config.localSMEcontactNumber;
      this.mailId = this.config.localSMEEmail;
    }
  }

  openDialog(type: string, condition: string): void {
    this.dataSharing.dialogType = type;
    this.dataSharing.modelDetails = condition;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }
}
