import { environment } from '../../../../../environments/environment';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { setPayBillerRecieptData } from '../../../../shared/store-utilities/actions/payment.action';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { setMobileWalletReceiptData } from '../../../../shared/store-utilities/actions/mobileWallet.action';
import { TransactionsResponse } from '../../../../core/transaction-service/data/transactions-response';
import { FinancialRule } from '@core/data/financial-rule';

@Component({
  selector: 'sbg-pay-mobile-wallet-review',
  templateUrl: './pay-mobile-wallet-review.component.html',
  styleUrls: ['./pay-mobile-wallet-review.component.scss'],
})
export class PayMobileWalletReviewComponent implements OnInit {
  billerConfirmModel;
  isSmeSoleProp;
  paymentReview;
  scheduleRelatedData;
  dataToRepeat;
  billerName = [];
  paymentAmount = 0;
  reviewHeader: any;
  totalPayment;
  mainHeading;

  config = environment.config;

  loginState;
  isAllowedMultipleVasPaymentsForSme = environment.config.isAllowedMultipleVasPaymentsForSme;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private store: Store<any>
  ) {
    this.store.select('appReducer').subscribe((stateData) => {
      if (stateData.loginReducer.authenticateResponse) {
        const userIdentity = stateData.loginReducer.authenticateResponse;
        this.isSmeSoleProp = userIdentity.userProfile.isSmeSoleProprietor;
      }
      this.loginState = stateData.loginReducer;
      this.paymentReview = _.cloneDeep(stateData.mobileWalletReducer.mobileWalletFormData);
    });
    this.mainHeading = this.paymentReview.paymentModel.currentFlow === 'new-transaction' ? 'Pay' : 'Edit Pay';

    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      showBackFn: this.goBack.bind(this),
      module: 'payMobileWallet',
    });
  }

  ngOnInit() {
    this.dataForConfirmation();
  }

  dataForConfirmation() {
    this.reviewHeader = {
      nameInit: this.paymentReview.paymentModel.walletselected.friendlyName,
    };
    this.paymentAmount = this.paymentReview.paymentModel.amount || 0;
  }

  getData(data) {
    data.forEach((value, index) => {
      this.billerName[index] = value.biller.name;
      this.paymentAmount += value.amount.amount;
      this.reviewHeader[index] = {
        name: value.biller.name,
        first: value.biller.nickName,
      };
    });
  }

  goToReceipt(): void {
    let objectCopy;

    const paymentModelDetail = _.cloneDeep(this.paymentReview.paymentModel);
    delete this.paymentReview.paymentModel;
    if (this.loginState.isSmeMode && this.dataSharingService.selectedApprover) {
      const rule = { financialRule: this.dataSharingService.selectedApprover };
      objectCopy = { ...this.paymentReview, ...rule };
    } else {
      objectCopy = { ...this.paymentReview };
    }
    let storeObj;
    // [ ]: we can remove this block(100-103) as the varible is initialized as let. 
    // eslint-disable-next-line prefer-const
    storeObj = {
      payload: {},
      response: {},
    };
    if (objectCopy.transactions.rOABillerPayments) {
      storeObj.payload = objectCopy;
      storeObj.payload.paymentModel = paymentModelDetail;
    }

    this.transactionService.makeTransaction(objectCopy).subscribe((response: TransactionsResponse) => {
      storeObj.response = response;
      this.store.dispatch(setPayBillerRecieptData({ payBillerRecieptData: storeObj }));
      this.router.navigate(['../receipt'], { relativeTo: this.activatedRoute });
    });
  }

  goToReceiptFn() {
    const financialRule: FinancialRule = this.dataSharingService.selectedApprover as FinancialRule;

    let payload = {
      account: this.paymentReview.account,
      transactions: this.paymentReview.transactions,
    };

    if (this.loginState.isSmeMode && this.dataSharingService.selectedApprover) {
      if (this.isAllowedMultipleVasPaymentsForSme) {
        payload.transactions.prepaidPurchases = [
          ...payload.transactions.prepaidPurchases.map((prepaidPurchase) => {
            return {
              ...prepaidPurchase,
              financialRule: financialRule,
            };
          }),
        ];
      } else {
        payload = { ...payload, ...{ financialRule: financialRule } };
      }
    }
    this.postService(payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    return this.transactionService.getTransactions(payload).subscribe((response: TransactionsResponse) => {
      response = {
        ...response,
        payload: payload,
        reviewModel: this.paymentReview,
      };
      this.store.dispatch(setMobileWalletReceiptData({ mobileWalletReviewData: response }));
      this.router.navigate(['/payments/MobileWallet/receipt'], {
        relativeTo: this.activatedRoute,
      });
    });
  }

  goToEditConfirmation(): void {
    const dataForSchedulePayload = {
      scheduledTransaction: this.transactionService.getEditSchedulePayload(this.paymentReview, this.paymentReview.paymentModel.editData.type),
    };

    this.postServiceEdit(dataForSchedulePayload);
    this.dataSharingService.setPostService(this.postServiceEdit.bind(this));
  }

  goBack() {
    return this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  postServiceEdit(payload) {
    return this.transactionService.getEditTransactions(payload).subscribe((response: any) => {
      response = {
        ...response,
        payload: payload,
        reviewModel: this.paymentReview,
      };
      this.store.dispatch(setPayBillerRecieptData({ payBillerRecieptData: response }));

      this.router.navigate(['../receipt'], {
        relativeTo: this.activatedRoute,
      });
    });
  }
}
