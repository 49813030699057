import { RouterStateService } from './../../../services/route-state/route-state.service';
import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../../cms/footer-navigation/footer-navigation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { securityQuestionAnswers } from '../../../shared/store-utilities/actions/registration.action';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-answer-security-questions',
  templateUrl: './answer-security-questions.component.html',
  styleUrls: ['./answer-security-questions.component.scss'],
})
export class AnswerSecurityQuestionsComponent implements OnInit {
  securityQuestions;
  answers = [];
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  constructor(
    private dataSharing: DataSharingService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<any>,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
    });

    this.dataSharing.setHeadPagination({
      mainTitle: 'Forgot Password',
      subTitle: 'Answer Secret Questions',
      showBack: true,
      showClose: true,
      steps: true,
      module: 'forgotPassword',
    });

    this.store.select('appReducer', 'registrationReducer').subscribe((value) => {
      this.securityQuestions = value.registerSecurityQuestions;
    });

    if (this.routeState.getPreviousUrl() === '/forgotPassword/changePassword') {
      this.store.select('appReducer', 'registrationReducer').subscribe((value) => {
        for (let i = 0; i < 3; i++) {
          if (value.securityQuestionAnswers[i].answer !== '') {
            this.answers[i] = value.securityQuestionAnswers[i].answer;
          }
        }
      });
    }
  }

  openDialog(type: string): void {
    this.dataSharing.dialogType = type;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }

  goToSelectPassword() {
    const dataForNextState = {
      securityQuestions: [
        {
          key: this.securityQuestions.securityChallenge.securityChallengeItems[0].key,
          answer: this.answers[0],
        },
        {
          key: this.securityQuestions.securityChallenge.securityChallengeItems[1].key,
          answer: this.answers[1],
        },
        {
          key: this.securityQuestions.securityChallenge.securityChallengeItems[2].key,
          answer: this.answers[2],
        },
      ],
    };
    this.store.dispatch(securityQuestionAnswers({ securityQuestionAnswers: dataForNextState.securityQuestions }));
    this.router.navigate(['./changePassword'], {
      relativeTo: this.activatedRoute.parent,
    });
  }
}
