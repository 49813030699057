import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { BankingMetadataService } from '../../../../core/banking-metadata/banking-metadata.service';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { AccountService } from '../../../../core/account-service/account-service.service';
import { PaymentUtilitiesService } from '../../../../core/payment-utilities/payment-utilities.service';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { SortByPipe } from '../../../../shared/pipes/sort/sort-by.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { setPayBeneDetailData } from '../../../../shared/store-utilities/actions/pay-beneficiary.action';
import { RouterStateService } from '../../../../services/route-state/route-state.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { NgForm, FormGroup } from '@angular/forms';
import { CutOffTimeService } from '../../../../services/cut-off-time-service/cut-off-time.service';
import { setExchangeRateData } from '@shared/store-utilities/actions/payment.action';
@Component({
  selector: 'sbg-pay-beneficiary-details',
  templateUrl: './pay-beneficiary-details.component.html',
  styleUrls: ['./pay-beneficiary-details.component.scss'],
})
export class PayBeneficiaryDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('paymentDetailsForm') paymentDetailsForm: NgForm;
  usedLimit: any;
  withdrawalLimit: any;
  availableLimit = 0;

  destroyed$ = new Subject<boolean>();
  countryCodes;
  showNamPayMsg = false;
  isNrtAvailable = false;
  amountIsZero: any[] = [];
  isAmountExceeding: any;
  triggerOnDailyWithdrawlLimit: any[] = [];
  amountIsInvalid: any[] = [];
  transactionLimitCrossed: any;
  transactionCharges: any;
  mainHeading: any;
  hidePaymentPriority: any;
  initialUserBalance: any;
  availableTransferBalance: any;
  permissibleTransferAmount: any;
  localBank: any;
  localCurrency: any;
  currentFlow: any;
  previousStepData: any;
  accounts: any;
  cardProfileData: any;
  paymentModel = {} as any;
  notifyType: string;
  availableMonthlyLimits: number;
  taxCodes: any;

  isAmountEntered = false;
  displayConditions = true;
  showMonthlyTransactionLimit = false;
  config = environment.config;
  toggleStatus = 'open';
  routeHistory: any[] = [];
  accountsData;
  isSmeMode = false;
  isSmeMultiBeneApplicable = false;
  prevUrl;
  routeDetails = {
    ACH: {
      name: 'Normal',
      description: 'Payment will be processed in 1-3 business days',
    },
    RTG: {
      name: 'Express',
      description: 'Same day payment(extra charge)',
    },
  };

  isAllowedScheduledPayment = this.paymentUtilities.checkIfScheduledPaymentsIsAllowedForTransactionType('BANK_BENEFICIARY');
  showIMTTMessage: any;
  payerCurrency: string;
  payeeCurrency: string;
  showPaymentPriorityTz = false;

  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private sortBy: SortByPipe,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private bankingMetadataService: BankingMetadataService,
    private accountsIterator: AccountsIteratorService,
    private accountService: AccountService,
    private recipientService: RecipientService,
    private paymentUtilities: PaymentUtilitiesService,
    private routeState: RouterStateService,
    private cutOffTimeService: CutOffTimeService,
    private store: Store<{ appReducer }>
  ) {
    this.routeHistory = this.routeState.getHistory();
    this.prevUrl = _.last(this.routeHistory);

    /* Back from review page */
    if (this.prevUrl.search('review') !== -1) {
      this.isAmountEntered = true;
      store
        .select('appReducer', 'payBeneficiaryReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          const reviewModel = _.cloneDeep(stateData.payBeneDetailData);
          this.previousStepData = _.map(reviewModel.transactions.beneficiaryPayments, (beneData: any) => {
            return beneData.beneficiary;
          });

          this.paymentModel = _.cloneDeep(reviewModel.paymentModel);
        });
    } else if (this.prevUrl.search('scheduleTransaction/receipt') !== -1) {
      /* Back from schedule receipt page */
      store
        .select('appReducer', 'editTxnScheduleReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          this.previousStepData = _.cloneDeep([stateData.editBankBeneficiary]);

          this.previousStepData.forEach((value) => {
            value.isFlowEditSchedule = true;

            if (!value.accountCurrency) {
              value.accountCurrency = value.scheduledAmount && value.scheduledAmount.currency ? value.scheduledAmount.currency : '';
            }
          });
          this.paymentModel = _.cloneDeep(this.paymentModel);
          this.paymentModel.currentFlow = 'edit-transaction';
        });
    } else {
      store
        .select('appReducer', 'payBeneficiaryReducer')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((stateData) => {
          this.previousStepData = _.cloneDeep(stateData.selectedReceipientsData);
          this.paymentModel = _.cloneDeep(this.paymentModel);
          this.paymentModel.currentFlow = 'new-transaction';
        });
    }

    store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });

    /* Main heading */
    if (this.previousStepData && this.previousStepData.length > 1) {
      this.mainHeading = 'Pay ' + this.previousStepData.length + ' recipients';
    } else if (this.previousStepData && this.previousStepData.length === 1) {
      this.mainHeading = this.paymentModel.currentFlow === 'new-transaction' ? 'Pay beneficiary' : `Edit ${this.previousStepData[0].name} schedule`;
    }

    /* Set header */
    this.dataSharingService.setHeadPagination({
      mainTitle: this.mainHeading,
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'payBeneficiary',
    });
  }

  ngOnInit() {
    this.localBank = this.config.localBank;
    this.localCurrency = this.config.localCurrency;

    this.isSmeMultiBeneApplicable = this.isSmeMode && this.config.localCurrency === 'SZL';

    this.notifyType = 'None';
    /* Public holidays */
    if (this.config.payments.payBeneficiary.checkPublicHolidays || this.config.checkMultiCurrencyHoliday) {
      this.fetchPublicHolidaysList();
    }

    this.getCountryCodes();
    this.getAmountWithdrawalLimit();
    this.getAccounts();
    this.getPastPayments();
  }

  /* Holiday list */
  fetchPublicHolidaysList() {
    this.bankingMetadataService.publicHolidaysList().subscribe((response) => {
      if (response.length) {
        this.paymentModel.publicHolidays = response;
      }
    });
  }

  fetchRtgsCutOffTimings() {
    this.bankingMetadataService.getCutOffTimes().subscribe((response: any) => {
      const cutOffTimeInfo = this.cutOffTimeService.checkCutOffTime(response.body.setting);
      this.isNrtAvailable = cutOffTimeInfo.isNrtAvailable;
      this.fetchRoutesByBranch();
    });
  }

  /* Limit set */
  getAmountWithdrawalLimit() {
    this.withdrawalLimit = this.config.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyWithdrawalLimit
      : this.accountService.cardProfile.dailyWithdrawalLimit;

    this.usedLimit = this.config.showMonthlyTransactionLimit
      ? this.accountService.cardProfile.monthlyUsedLimit
      : this.accountService.cardProfile.usedEAPLimit;

    if (this.withdrawalLimit && this.usedLimit) {
      this.availableLimit = this.withdrawalLimit.amount - this.usedLimit.amount;
      this.availableLimit = parseFloat(this.availableLimit.toFixed(2));
    }
  }

  /* Get Accounts */
  getAccounts() {
    if (this.config.localCurrency === 'BWP') {
      if (this.previousStepData[0].route.branch.bank.name === this.config.localBank) {
        if (this.previousStepData[0].accountCurrency !== 'BWP') {
          this.accounts = this.accountsIterator.getPaymentFromAccounts(this.previousStepData[0].accountCurrency);
        } else {
          this.accounts = this.accountsIterator.getPaymentFromAccounts('all');
        }
      } else {
        this.accounts = this.accountsIterator.getPaymentFromAccounts('local');
      }
    } else if (this.config.localCurrency === 'USD') {
      if (this.previousStepData[0].route.branch.bank.name === this.config.localBank) {
        this.accounts = this.accountsIterator.getPaymentFromAccounts(this.previousStepData[0].accountCurrency);
      } else {
        this.accounts = this.accountsIterator.getPaymentFromAccounts('all');
      }
    } else {
      this.accounts = this.accountsIterator.getPaymentFromAccounts(this.config.payments.payBeneficiary.beneficiaryPaymentCurrency);
    }

    this.paymentModel.accounts = this.accounts;
  }

  getPastPayments() {
    const pastPaymentsRequest = this.paymentUtilities.fetchPreviousPaymentDetails(this.previousStepData, 'BENEFICIARY');

    this.recipientService.callRecipientForPayment(pastPaymentsRequest).subscribe((response) => {
      const res = response.body;
      this.previousStepData = _.map(this.previousStepData, (value: any) => {
        const returnedData = res.recipientPayments.filter((val: any) => {
          return parseFloat(val.recipientId) === value.recipientId;
        });

        if (returnedData.length > 0) {
          const sortPayByDate = this.sortBy.transform(returnedData[0].recentPayments, 'desc', 'date');

          value = {
            ...value,
            ...{ pastDetails: sortPayByDate[0] },
          };
        }

        return value;
      });
      this.setDataFn();
      if (this.config.localCurrency === 'TZS' && this.previousStepData.length === 1) {
        this.showExchangeRatesTZS();
      } else if (this.config.localCurrency !== 'TZS' && this.previousStepData.length === 1) {
        this.payerCurrency = this.paymentModel.selectedFromAccount.accountCurrency.currencyCode;
        this.payeeCurrency = this.previousStepData[0].accountCurrency;
      }
    });
  }

  setDataFn() {
    /* Set selectedFromAccount */
    if (!this.paymentModel.selectedFromAccount) {
      this.paymentModel.selectedFromAccount = this.accounts[0];

      if (this.previousStepData[0].isFlowEditSchedule && this.previousStepData[0].fromAccount && this.previousStepData[0].fromAccount.number) {
        const initialEditScheduleAcc = this.accounts.filter((value) => {
          return value.number === this.previousStepData[0].fromAccount.number;
        });
        if (initialEditScheduleAcc && initialEditScheduleAcc.length) {
          this.paymentModel.selectedFromAccount = initialEditScheduleAcc[0];
        }
      }
    } else if (this.paymentModel.selectedFromAccount && this.paymentModel.selectedFromAccount.number) {
      const findAccs = this.accounts.filter((value) => {
        return value.number === this.paymentModel.selectedFromAccount.number;
      });
      if (findAccs && findAccs.length) {
        this.paymentModel.selectedFromAccount = findAccs[0];
      }
    } else {
      this.paymentModel.selectedFromAccount = this.accounts[0];
    }

    /* Set accounts */
    this.paymentModel.accounts = this.accounts;

    if (this.previousStepData.length > 1) {
      if (this.config.showOnlyLocalForMultiplePayment) {
        this.accounts = this.accountsIterator.getPaymentFromAccounts('local');
        this.paymentModel.selectedFromAccount = this.accounts[0];
      }
    }

    this.previousStepData.forEach((beneficiary) => {
      if (beneficiary.route.networkType !== 'WIB') {
        this.showNamPayMsg = true;
      }
    });

    if (!this.paymentModel.selectedUserDetails) {
      const modifiedData = this.paymentUtilities.iteratorModifyArray(
        this.previousStepData,
        this.paymentModel,
        this.paymentModel.selectedFromAccount.availableBalance.currency
      );

      this.paymentModel = _.cloneDeep(modifiedData.modalData);
      this.paymentModel = {
        ...this.paymentModel,
        ...{ currentDate: new Date() },
      };

      this.previousStepData = modifiedData.modifyArray;
      if(this.config.countryName === 'Tanzania'){
        this.previousStepData.forEach((item,index)=>{
          this.previousStepData[index].accountNumber = this.previousStepData[index].accountNumber.replace(/[^a-zA-Z0-9]*/g, '');
        })
      }else{
        this.previousStepData.forEach((item,index)=>{
          this.previousStepData[index].accountNumber = this.previousStepData[index].accountNumber.replace(/[^0-9]*/g, '');
        })
      }
      this.paymentModel.selectedUserDetails = this.previousStepData;

      if (!this.paymentModel.selectedUserDetails[0].address) {
        this.paymentModel.selectedUserDetails[0].address = {
          addressLine1: '',
        };
      }

      this.paymentModel = {
        ...this.paymentModel,
        ...{
          eachListAmountEntry: [],
          eachListAmountWithoutCharges: [],
        },
      };

      if (!this.paymentModel.singlePayeeSelected) {
        this.paymentModel.validateAmount = true;
      }
      if (
        this.config.countryName === 'Tanzania' &&
        this.paymentModel.selectedUserDetails[0] &&
        this.paymentModel.selectedUserDetails[0].route &&
        this.paymentModel.selectedUserDetails[0].route.networkType &&
        this.paymentModel.selectedUserDetails[0].route.networkType !== 'WIB' &&
        this.paymentModel.selectedFromAccount.accountCurrency.currencyCode !== 'TZS'
      ) {
        this.showPaymentPriorityTz = true;
      }

      if (this.paymentModel.currentFlow === 'new-transaction') {
        if (this.config.allowNrtCutOffTime) {
          this.fetchRtgsCutOffTimings();
        } else {
          this.fetchRoutesByBranch();
        }
      }
    }

    this.permissibleTransferAmount = this.paymentModel.selectedFromAccount.availableBalance.amount;

    this.initialUserBalance = this.permissibleTransferAmount;
    this.availableTransferBalance = this.permissibleTransferAmount;

    this.paymentModel.permissibleTransferAmount = this.permissibleTransferAmount;

    if (this.config.hideFromAccFromReciept) {
      this.paymentModel.selectedFromAccount = '';
    }

    if (this.config.recipientPaymentPriority) {
      this.hidePaymentPriority = this.paymentModel.selectedUserDetails.length > 1 && !this.config.multipleRtgPaymentAllowed;
    }

    this.paymentModel.selectedUserDetails = _.map(this.paymentModel.selectedUserDetails, (value: any) => {
      const dataObj = {
        headerDetails: {
          name: value.name,
          first: value.pastDetails
            ? value.pastDetails.amount.currency + ' ' + this.decimalPipe.transform(value.pastDetails.amount.amount, '1.2-2')
            : '',
          second: value.pastDetails
            ? this.datePipe.transform(value.pastDetails.date, 'mediumDate') + ', ' + this.datePipe.transform(value.pastDetails.date, 'shortTime')
            : '',
        },
      };

      value = {
        ...value,
        ...dataObj,
      };

      return value;
    });

    if (this.paymentModel.currentFlow === 'edit-transaction') {
      this.amountValueChanged(
        0,
        this.paymentModel.selectedUserDetails[0].amount.amount,
        this.paymentModel.selectedFromAccount,
        !this.paymentModel.validateAmount
      );
    }

    if (this.config.countryName === 'Zimbabwe') {
      this.fetchMoneyTransferTaxCodes();
    }
  }

  fetchRoutesByBranch() {
    const branchList = [];
    for (let i = 0; i < this.previousStepData.length; i++) {
      const branch = {
        address: this.previousStepData[i].route.branch.address,
        code: this.previousStepData[i].route.branch.code,
        name: this.previousStepData[i].route.branch.name,
      };
      branchList.push(branch);
    }
    this.bankingMetadataService.getBranchRoutes(branchList).subscribe((res) => {
      const response = res.body;
      let objNRT;
      let intRouteObjNRT;
      let indexOfCMAType;
      const routeResponse = response.branches;
      if (routeResponse.length) {
        this.paymentModel.selectedUserDetails = _.map(this.paymentModel.selectedUserDetails, (payVal: any) => {
          _.forEach(routeResponse, (routeObj: any) => {
            if (this.paymentUtilities.getRecipientBankDetails(payVal).code === routeObj.branch.code) {
              payVal.networkTypes = routeObj.routes;
            }
          });

          indexOfCMAType = -1;

          payVal.networkTypes = _.map(payVal.networkTypes, (intRouteObj: any, intRouteInd: any) => {
            objNRT = _.cloneDeep(intRouteObj);

            if (intRouteObj.networkType === 'ACH') {
              // let intRouteObjACH = _.cloneDeep(intRouteObj);

              intRouteObj = {
                ...intRouteObj,
                ...{
                  countryList: true,
                  name: 'Normal',
                  description: 'Payment will be processed in 1-3 business days',
                },
              };

              intRouteObjNRT = {
                ...objNRT,
                ...{
                  countryList: true,
                  networkType: 'NRT',
                  name: 'NRT',
                  description: 'Payment will be processed near real-time (premium charge)',
                },
              };

              payVal.routeObjectACH = intRouteObj;
            } else if (intRouteObj.networkType === 'RTG') {
              intRouteObj = {
                ...intRouteObj,
                ...{
                  countryList: !this.config.zimToggle,
                  name: 'Express',
                  description: 'Same day payment(extra charge)',
                },
              };

              payVal.routeObjectRTG = intRouteObj;
            } else if (intRouteObj.networkType === 'WIB') {
              payVal.isWithinBank = true;
              payVal.route.networkType = intRouteObj.networkType;
              payVal.route.routingNumber = intRouteObj.routingNumber;
            } else {
              indexOfCMAType = intRouteInd;
            }

            return intRouteObj;
          });

          // if (this.isNrtAvailable && payVal.routeObjectNRT) {
          //   payVal.networkTypes.push(payVal.routeObjectNRT);
          // }

          if (this.isNrtAvailable && payVal.routeObjectACH) {
            payVal.routeObjectNRT = intRouteObjNRT;
            payVal.networkTypes.push(payVal.routeObjectNRT);
          }

          if (indexOfCMAType >= 0) {
            payVal.networkTypes.splice(indexOfCMAType, 1);
          }

          if (!payVal.isWithinBank) {
            if (!payVal.selectedRoute) {
              payVal = {
                ...payVal,
                selectedRoute: {},
              };
            }

            if (payVal.routeObjectACH) {
              payVal.selectedRoute = payVal.routeObjectACH;
            }

            if (this.localCurrency === 'USD' && payVal.routeObjectRTG) {
              payVal.selectedRoute = payVal.routeObjectRTG;
            }

            if ((!payVal.selectedRoute || !payVal.selectedRoute.networkType || payVal.selectedRoute.networkType === '') && payVal.routeObjectRTG) {
              payVal.selectedRoute = payVal.routeObjectRTG;
            }

            if (payVal.selectedRoute && (payVal.selectedRoute.networkType || payVal.selectedRoute.routingNumber)) {
              const tempObj = {
                ...payVal.route,
                networkType: payVal.selectedRoute.networkType,
                routingNumber: payVal.selectedRoute.routingNumber,
              };

              payVal = {
                ...payVal,
                route: tempObj,
              };
            }
          }

          if (this.showPaymentPriorityTz && payVal.routeObjectRTG) {
            payVal.route.networkType = payVal.routeObjectRTG.networkType;
            payVal.route.routingNumber = payVal.routeObjectRTG.routingNumber;
            payVal.selectedRoute = payVal.route;
            this.previousStepData[0].route.networkType = payVal.routeObjectRTG.networkType;
          } else if (this.showPaymentPriorityTz && !payVal.routeObjectRTG) {
            payVal.route.networkType = 'RTG';
            payVal.selectedRoute = payVal.route;
            this.previousStepData[0].route.networkType = 'RTG';
          }
          if (this.config.localCurrency === 'TZS' && this.previousStepData.length === 1) {
            this.showExchangeRatesTZS();
          }
          return payVal;
        });
      }
    });
  }

  fetchMoneyTransferTaxCodes() {
    this.bankingMetadataService.getMoneyTransferTaxCode().subscribe((response) => {
      this.taxCodes = response.taxCodes;
      this.paymentModel.moneyTransferTaxCode = this.taxCodes[0];
      this.moneyTransferTaxCodeChanged(response.taxCodes[0], 0);
      const isIMTTApplicable = this.isIMTTApplicable(this.paymentModel.selectedFromAccount);
      this.paymentModel.selectedUserDetails.forEach((userDetails, index) => {
        if (isIMTTApplicable) {
          if (!userDetails.moneyTransferTaxCode) {
            this.moneyTransferTaxCodeChanged(response.taxCodes[0], index);
          }
        } else {
          delete userDetails.moneyTransferTaxCode;
        }
      });
    });
  }

  isIMTTApplicable(account: any) {
    this.showIMTTMessage = this.config.localCurrency === account.accountCurrency.currency;
    return this.showIMTTMessage;
  }

  moneyTransferTaxCodeChanged(taxCode: any, index: any) {
    switch (taxCode.key) {
      case '001':
        this.paymentModel.selectedUserDetails[index].taxCodeMessage =
          'A ' + this.config.intermediatedMoneyTransferTax + '% IMTT charge will be levied on the transaction amount.';
        break;
      default:
        this.paymentModel.selectedUserDetails[index].taxCodeMessage =
          'Please note that you have selected not to pay ' +
          'IMTT on this transaction.Tax evasion is a crime and you are liable for any fines and penalties that may arise from this choice.';
        break;
    }
    this.paymentModel.selectedUserDetails[index].moneyTransferTaxCode = taxCode;
  }

  amountValueChanged(index: any, newValue: any, account: any, isNotValidatingAmount: any) {
    this.paymentModel.thisTxnCharge = this.paymentModel.thisTxnCharge || [];

    let totalSum = 0;
    let totatSumWithoutCharges = 0;
    let applicableCharges = 0;

    this.paymentModel.thisTxnCharge[index] = '';
    this.amountIsZero[index] = parseFloat(newValue) === 0;

    if (!newValue) {
      newValue = 0;
    }
    this.transactionLimitCrossed = true;

    if (!this.config.paymentCharges) {
      this.paymentModel.benificiaryTxnCharge = {};
      applicableCharges = 0;
      this.paymentModel.thisTxnCharge[index] = null;
    }

    this.paymentModel.eachListAmountWithoutCharges[index] = parseFloat(newValue);
    this.paymentModel.eachListAmountEntry[index] = parseFloat(newValue) + applicableCharges;

    this.paymentUtilities.changeObjAllProperty(this.triggerOnDailyWithdrawlLimit, false);
    this.paymentUtilities.changeObjAllProperty(this.amountIsInvalid, false);
    this.isAmountExceeding = false;

    if (newValue <= parseFloat(this.permissibleTransferAmount)) {
      this.transactionLimitCrossed = false;
      this.amountIsInvalid[index] = false;
      this.availableTransferBalance = this.permissibleTransferAmount - newValue - applicableCharges;
      this.availableTransferBalance = parseFloat(parseFloat(this.availableTransferBalance).toFixed(2));
    }

    for (let i = 0; i < this.paymentModel.eachListAmountEntry.length; i++) {
      if (!this.paymentModel.eachListAmountEntry[i]) {
        this.paymentModel.eachListAmountEntry[i] = 0;
      }
      if (!this.paymentModel.eachListAmountWithoutCharges[i]) {
        this.paymentModel.eachListAmountWithoutCharges[i] = 0;
      }

      totalSum = totalSum + parseFloat(this.paymentModel.eachListAmountEntry[i]);
      totatSumWithoutCharges = totatSumWithoutCharges + parseFloat(this.paymentModel.eachListAmountWithoutCharges[i]);
      totalSum = parseFloat(totalSum.toFixed(2));
      totatSumWithoutCharges = parseFloat(totatSumWithoutCharges.toFixed(2));
    }

    if (totatSumWithoutCharges > this.availableLimit && account.accountCurrency.currency === this.config.localCurrency) {
      this.triggerOnDailyWithdrawlLimit[index] = true;
      this.isAmountExceeding = true;
    }

    if (totalSum > parseFloat(this.initialUserBalance)) {
      this.amountIsInvalid[index] = true;
      this.isAmountExceeding = true;
    }

    this.paymentModel.clearanceForValidAmount = !(
      totalSum === 0 ||
      totatSumWithoutCharges > this.availableLimit ||
      totalSum > this.initialUserBalance
    );

    this.paymentModel.totalPayableAmount = totalSum;
    this.paymentModel.amountForSchedule = totalSum;

    if (this.paymentModel.selectedUserDetails) {
      this.paymentModel.currencyForSchedule = this.paymentModel.selectedUserDetails[0].amount.currency;
    }
    if (account.accountCurrency.currency !== this.config.localCurrency) {
      this.paymentModel.clearanceForValidAmount = !(totalSum === 0 || totalSum > this.initialUserBalance);
    }

    if (isNotValidatingAmount) {
      this.triggerOnDailyWithdrawlLimit[index] = false;
      this.amountIsInvalid[index] = false;
      this.isAmountExceeding = false;
      this.paymentModel.clearanceForValidAmount = true;
    }

    if (this.paymentModel.selectedUserDetails) {
      const currentUserDetails = this.paymentModel.selectedUserDetails[index];
      if (
        !this.previousStepData[0].isFlowEditSchedule &&
        !this.hidePaymentPriority &&
        currentUserDetails &&
        currentUserDetails.networkTypes &&
        currentUserDetails.networkTypes.length &&
        currentUserDetails.networkTypes[0].networkType !== 'WIB'
      ) {
        // should not be called for within bank payments
        // todo later
        this.paymentModel = this.paymentUtilities.switchNetworkType(this.paymentModel, newValue, index);
        if (currentUserDetails.selectedRoute) {
          currentUserDetails.route.networkType = currentUserDetails.selectedRoute.networkType;
          currentUserDetails.route.routingNumber = currentUserDetails.selectedRoute.routingNumber;
        }
      }
    }
  }

  amountFocusOut() {
    if (parseFloat(this.paymentModel.totalPayableAmount)) {
      this.isAmountEntered = true;
    }
  }

  accountValueChanged(newValue) {
    this.paymentModel.selectedFromAccount = newValue;
    this.paymentModel.selectedUserDetails.forEach((selectedUserDetail) => {
      selectedUserDetail.amount = {
        currency: this.paymentModel.selectedFromAccount.availableBalance.currency,
        amount: null,
      };
    });
    if (this.config.localCurrency === 'TZS' && this.previousStepData.length === 1) {
      this.showExchangeRatesTZS();
    } else if (this.config.localCurrency !== 'TZS' && this.previousStepData.length === 1) {
      this.payerCurrency = this.paymentModel.selectedFromAccount.accountCurrency.currencyCode;
      this.payeeCurrency = this.previousStepData[0].accountCurrency;
    }
    if (
      this.config.countryName === 'Tanzania' &&
      this.paymentModel.selectedUserDetails[0] &&
      this.paymentModel.selectedUserDetails[0].route &&
      this.paymentModel.selectedUserDetails[0].route.networkType &&
      this.paymentModel.selectedUserDetails[0].route.networkType !== 'WIB' &&
      this.paymentModel.selectedFromAccount.accountCurrency.currencyCode !== 'TZS'
    ) {
      this.showPaymentPriorityTz = true;
      this.paymentModel.selectedUserDetails.forEach((selectedUserDetail) => {
        if (selectedUserDetail.routeObjectRTG) {
          selectedUserDetail.route.networkType = selectedUserDetail.routeObjectRTG.networkType;
          selectedUserDetail.route.routingNumber = selectedUserDetail.routeObjectRTG.routingNumber;
          selectedUserDetail.selectedRoute = selectedUserDetail.route;
          this.previousStepData[0].route.networkType = selectedUserDetail.routeObjectRTG.networkType;
        } else {
          selectedUserDetail.route.networkType = 'RTG';
          selectedUserDetail.selectedRoute = selectedUserDetail.route;
          this.previousStepData[0].route.networkType = 'RTG';
        }
      });
    } else {
      this.showPaymentPriorityTz = false;
    }
  }

  toggleClicked(data) {
    this.toggleStatus = data;
  }

  routeValueChanged(data, index): void {
    const currentUserDetails = this.paymentModel.selectedUserDetails[index];
    currentUserDetails.selectedRoute = data;
    currentUserDetails.route.networkType = data.networkType;
    currentUserDetails.route.routingNumber = data.routingNumber;
    if (this.config.localCurrency === 'TZS' && this.previousStepData.length === 1) {
      this.previousStepData[0].route.networkType = data.networkType;
      this.showExchangeRatesTZS();
    }
  }

  getScheduleValues(values) {
    if (values) {
      this.paymentModel.isPaymentScheduled = values.isPaymentScheduled;
      this.paymentModel.oftenSchedule = values.oftenSchedule;
      this.paymentModel.numberOfPayments = values.numberOfPayments;
      this.paymentModel.scheduleDate = values.scheduleDate;
      this.paymentModel.module = values.module || '';
      this.paymentModel.editData = values.editData || '';
      this.paymentModel.scheduleData = values;

      this.paymentModel.selectedUserDetails.forEach((elem, index) => {
        if (this.paymentDetailsForm.form.controls['amount' + index]) {
          (this.paymentDetailsForm.form.controls['amount' + index] as FormGroup).setErrors(null);
        }
      });
    }
  }

  getRouteDetails(networkType): string {
    return this.routeDetails[networkType].name;
  }

  checkPaymentHoliday(isHoliday: any) {
    let showHolidayError = false;
    if (isHoliday) {
      if (this.paymentModel.singlePayeeSelected) {
        if (
          this.paymentUtilities.getRecipientBankDetails(this.paymentModel.selectedUserDetails[0]).bankName === this.config.localBank ||
          this.paymentModel.selectedUserDetails[0].route.networkType === 'ACH'
        ) {
          showHolidayError = false;
        } else if (this.paymentModel.selectedUserDetails[0].route.networkType === 'RTG' && !this.config.allowRtgsWeekend) {
          showHolidayError = true;
        }
      } else {
        if (this.config.multipleRtgPaymentAllowed && !this.config.allowRtgsWeekend) {
          for (let i = 0; i < this.paymentModel.selectedUserDetails.length; i++) {
            if (this.paymentModel.selectedUserDetails[i].route.networkType === 'RTG') {
              showHolidayError = true;
            }
          }
        }
      }
    }
    return showHolidayError;
  }

  checkMultiCurrencyHoliday(isHoliday: any) {
    let showMultiCurrencyHolidayError: any;
    let toCurrency: any;
    if (this.previousStepData[0].isFlowEditSchedule) {
      toCurrency = this.paymentModel.selectedUserDetails[0].scheduledAmount.currency;
    } else {
      toCurrency = this.paymentModel.selectedUserDetails[0].accountCurrency;
    }
    if (isHoliday) {
      if (this.paymentModel.singlePayeeSelected) {
        showMultiCurrencyHolidayError = !(
          toCurrency === this.localCurrency && this.paymentModel.selectedFromAccount.accountCurrency.currency === this.localCurrency
        );
      } else {
        for (let j = 0; j < this.paymentModel.selectedUserDetails.length; j++) {
          if (
            this.paymentModel.selectedUserDetails[j].accountCurrency === this.localCurrency &&
            this.paymentModel.selectedFromAccount.accountCurrency.currency === this.localCurrency
          ) {
            showMultiCurrencyHolidayError = true;
          }
        }
      }
    }
    return showMultiCurrencyHolidayError;
  }

  goToReview(): void {
    let isHoliday = false;
    let isHolidayError: any;
    let isMultiCurrencyHolidayError: any;
    if (this.config.payments.payBeneficiary.checkPublicHolidays || this.config.checkMultiCurrencyHoliday) {
      if (this.paymentModel.publicHolidays && this.paymentModel.publicHolidays.length) {
        isHoliday = this.paymentUtilities.checkPublicHoliday(this.paymentModel.publicHolidays, this.paymentModel.currentDate);
      }
    }

    if (this.config.payments.payBeneficiary.checkPublicHolidays) {
      isHolidayError = this.checkPaymentHoliday(isHoliday);
    }

    if (this.config.checkMultiCurrencyHoliday) {
      isMultiCurrencyHolidayError = this.checkMultiCurrencyHoliday(isHoliday);
    }

    if (isHolidayError) {
      const lineOneText = '<p>You cannot make express payments</p><p>on weekends and public holidays.</p>';
      const lineTwoText = '<p>Please select the normal payment</p><p>priority option to continue</p>';

      this.dataSharingService.setCloseCurrentModule({
        message: {
          line1: lineOneText,
          line2: lineTwoText,
        },
        buttonOneText: 'OK',
        routeBackTo: null,
        showModule: true,
      });
    } else if (isMultiCurrencyHolidayError) {
      if (this.localCurrency === 'ZWL' && this.payerCurrency === 'USD') {
        const nextStepData: any = this.paymentUtilities.benificiaryPaymentReviewData(this.paymentModel, this.paymentModel.currentFlow);

        nextStepData.accounts = this.accounts;
        const exchangeRateObject = {
          payerCurrency: this.payerCurrency,
          payeeCurrency: this.payeeCurrency,
        };
        nextStepData.exchangeRateObject = exchangeRateObject;
        this.store.dispatch(setPayBeneDetailData({ payBeneDetailData: nextStepData }));

        this.router.navigate(['../review'], {
          relativeTo: this.activatedRoute,
        });
      } else {
        const lineOneText = '<p>Please note that cross foreign currency</p><p>payments are only allowed on a</p><p>business day</p>';

        this.dataSharingService.setCloseCurrentModule({
          message: {
            line1: lineOneText,
          },
          buttonOneText: 'OK',
          routeBackTo: null,
          showModule: true,
        });
      }
    } else {
      const nextStepData: any = this.paymentUtilities.benificiaryPaymentReviewData(this.paymentModel, this.paymentModel.currentFlow);

      nextStepData.accounts = this.accounts;
      const exchangeRateObject = {
        payerCurrency: this.payerCurrency,
        payeeCurrency: this.payeeCurrency,
      };
      nextStepData.exchangeRateObject = exchangeRateObject;
      this.store.dispatch(setPayBeneDetailData({ payBeneDetailData: nextStepData }));

      this.router.navigate(['../review'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  goBack() {
    if (this.prevUrl.search('details-beneficiary') !== -1) {
      return this.router.navigate(['/recipients-management/beneficiary/details-beneficiary']);
    } else if (this.paymentModel.currentFlow === 'edit-transaction') {
      return this.router.navigate(['scheduleTransaction/receipt']);
    } else {
      this.store.dispatch(setExchangeRateData({ exchangeRate: '' }));
      return this.router.navigate(['../list'], {
        relativeTo: this.activatedRoute,
      });
    }
  }

  getNotifyValues(values) {
    if (values) {
      const index = _.cloneDeep(values.index);
      this.paymentModel.selectedUserDetails[index].selectedPaymentProof = values.value.selectedPaymentProof;
      this.paymentModel.selectedUserDetails[index].paymentConfirmation = values.value.paymentConfirmation;
      this.paymentModel.selectedUserDetails[index].showMultiNotificationEmail = values.value.showMultiNotificationEmail;
      this.paymentModel.selectedUserDetails[index].showMultiNotificationSms = values.value.showMultiNotificationSms;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  restrictZero(e) {
    if (e.target.value.length) {
      if (e.target.selectionStart === 0 && e.key === '0') {
        e.preventDefault();
      }
    } else if (e.key === '0') {
      e.preventDefault();
    }
  }
  removeLeadingZeros(value: any) {
    value.srcElement.value = value.srcElement.value.replace(/^0+/, '');
  }

  
  showExchangeRatesTZS() {
    const sourceCurrency = this.paymentModel.selectedFromAccount.accountCurrency.currencyCode;
    switch (this.previousStepData[0].route.networkType) {
      case 'WIB':
        this.payerCurrency = '';
        this.payeeCurrency = '';
        break;
      case 'ACH':
        this.payerCurrency = sourceCurrency;
        this.payeeCurrency = this.config.localCurrency;
        break;
      case 'RTG':
        this.payerCurrency = sourceCurrency;
        this.payeeCurrency = 'USD';
        break;
    }
  }

  /* Country codes */
  private getCountryCodes() {
    this.bankingMetadataService.getCountryCodes().subscribe((response) => {
      this.countryCodes = response.countryCodes;
    });
  }

}
