import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { PreparedBiller } from '../gepg-payment.model';

@Component({
  selector: 'sbg-gepg-payment-list',
  templateUrl: './gepg-payment-list.component.html',
  styleUrls: ['./gepg-payment-list.component.scss'],
})
export class GepgPaymentListComponent implements OnInit {
  gepgPaidBillers: PreparedBiller[];
  gepgOtherBillers: PreparedBiller[];
  buttonText: string;

  constructor(
    private recipientService: RecipientService,
    private transactionService: TransactionService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.fetchOtherBillers();
    this.fetchPaidBillers();
  }

  fetchPaidBillers() {
    this.transactionService
      .fetchGepgReceipts({ pagination: false })
      .pipe(take(1))
      .subscribe((response) => {
        this.gepgPaidBillers = this.getPreparedList(response.body.receipts);
      });
  }

  fetchOtherBillers() {
    this.recipientService
      .getGepgBillers()
      .pipe(take(1))
      .subscribe((response) => {
        this.gepgOtherBillers = this.getPreparedList(response.billers);
      });
  }

  getPreparedList(list): PreparedBiller[] {
    const filterList = [];
    list.forEach((item) => {
      filterList.push({
        amount: {
          amount: item.amount ? item.amount.amount : 0,
          currency: item.amount ? item.amount.currency : item.currency,
        },
        date: item.date,
        serviceProviderName: item.paymentDetails ? item.paymentDetails.serviceProvider.serviceProviderName : item.serviceProvider.serviceProviderName,
        serviceProviderCode: item.paymentDetails ? item.paymentDetails.serviceProvider.serviceProviderCode : item.serviceProvider.serviceProviderCode,
        controlNumber: item.paymentDetails ? item.paymentDetails.controlNumber : '',
      });
    });
    return filterList;
  }

  toNextPage(data) {
    this.dataSharingService.resetStepData();
    this.dataSharingService.dataFromFirstStep = { selectedBiller: data[0] };
    if (data[0].controlNumber) {
      this.router.navigate(['../amount'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
    }
  }

  itemClicked(data) {
    this.buttonText = `Pay ${data.data.serviceProviderCode}`;
  }
}
