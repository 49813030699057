import { createAction, props } from '@ngrx/store';
import { SelectedDebitCardData, SelectedDebitCardConfirmData, SelectedDebitCardReceiptData } from '../interfaces/debitCard.model';

export const setSelectedDebitCardData = createAction(
  '[SelfAccountTransfer Component] setSelectedDebitCardData',
  props<{ selectedDebitCardData: SelectedDebitCardData }>()
);

export const setSelectedDebitCardConfirmData = createAction(
  '[SelfAccountTransfer Component] setSelectedDebitCardConfirmData',
  props<{ selectedDebitCardConfirmData: SelectedDebitCardConfirmData }>()
);

export const setSelectedDebitCardReceiptData = createAction(
  '[SelfAccountTransfer Component] setSelectedDebitCardReceiptData',
  props<{ selectedDebitCardReceiptData: SelectedDebitCardReceiptData }>()
);
