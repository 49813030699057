import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'sbg-custom-progress-bar',
  templateUrl: './custom-progress-bar.component.html',
  styleUrls: ['./custom-progress-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomProgressBarComponent {
  @Input() barType;
  @Input() AccountType: string;
  progressValue;
  progressBar;

 
  getProgressBarColor() {
    return {
      'current-acct__progress-bar--color': this.AccountType === 'CURRENT',
      'rcp-account__progress-bar--color': this.AccountType === 'RCP',
      'term-loan-acct__progress-bar--color': this.AccountType === 'TERM_LOAN',
      'savings-acct__progress-bar--color': this.AccountType === 'SAVINGS',
      'credit-card-acct__progress-bar--color': this.AccountType === 'CREDIT_CARD',
      'fixed-term-investment__progress-bar--color': this.AccountType === 'FIXED-TERM-INVESTMENT',
      'home-loan__progress-bar--color': this.AccountType === 'HOME_LOAN',
    };
  }
}
