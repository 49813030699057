import { BulkFileUploadModule } from './bulkFileUpload/bulkFile.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PaymentsComponent } from './payments.component';
import { PaymentsRoutingModule } from './payments-routing.module';
import { PayBeneficiaryModule } from './pay-beneficiary/pay-beneficiary.module';
import { SelfFundModule } from './self-fund/self-fund.module';
import { PayBillerModule } from './pay-biller/pay-biller.module';
import { PayMobileWalletModule } from './pay-mobile-wallet/pay-mobile-wallet.module';
import { CmaBeneficiaryPaymentModule } from './cma-beneficiary-payment/cma-beneficiary-payment.module';
import { TaxPaymentModule } from './tax-payment/tax-payment.module';
import { NamibiaTaxPaymentModule } from './namibia-tax-payment/namibia-tax-payment.module';
import { GepgPaymentModule } from './gepg-payment/gepg-payment.module';
import { SchoolFeesModule } from './schoolfees/schoolfees.module';

@NgModule({
  declarations: [PaymentsComponent],
  imports: [
    SharedModule,
    BulkFileUploadModule,
    SelfFundModule,
    PayBeneficiaryModule,
    PayBillerModule,
    CmaBeneficiaryPaymentModule,
    PaymentsRoutingModule,
    PayMobileWalletModule,
    NamibiaTaxPaymentModule,
    TaxPaymentModule,
    GepgPaymentModule,
    SchoolFeesModule,
  ],
  providers: [],
})
export class PaymentsModule {}
