import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: 'input[ngModel][sbgSpaceRemover]',
  providers: [NgModel],
})
export class SpaceRemoverDirective {
  constructor(private model: NgModel, private el: ElementRef) {}
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initalValue.replace(' ', '');
    this.model.valueAccessor.writeValue(this.el.nativeElement.value);
    this.model.viewToModelUpdate(this.el.nativeElement.value);
    if (initalValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
