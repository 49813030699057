import { NgModule } from '@angular/core';
import { NamibiaTaxPaymentDetailsComponent } from './namibia-tax-payment-details/namibia-tax-payment-details.component';
import { SharedModule } from '../../../shared/shared.module';
import { NamibiaTaxPaymentRoutingModule } from './namibia-tax-payment-routing.module';
import { NamibiaTaxPaymentReviewComponent } from './namibia-tax-payment-review/namibia-tax-payment-review.component';
import { NamibiaTaxPaymentReceiptComponent } from './namibia-tax-payment-receipt/namibia-tax-payment-receipt.component';
import { NamibiaTaxPaymentListComponent } from './namibia-tax-payment-list/namibia-tax-payment-list.component';

@NgModule({
  declarations: [NamibiaTaxPaymentDetailsComponent, NamibiaTaxPaymentReviewComponent, NamibiaTaxPaymentReceiptComponent, NamibiaTaxPaymentListComponent],
  imports: [SharedModule, NamibiaTaxPaymentRoutingModule],
})
export class NamibiaTaxPaymentModule {}
