import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { RegistrationState } from '../../../shared/store-utilities/state/registration.state';
import { take } from 'rxjs/operators';
import { BaseChangePassword } from '../../../shared/components/change-password/util/base-change-password';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent extends BaseChangePassword implements OnInit {

  constructor(
    public dataSharing: DataSharingService,
    public router: Router,
    public registrationService: RegistrationService,
    public store: Store<{ appReducer: { registrationReducer: RegistrationState; loginReducer } }>,
    public routeState: RouterStateService,
  ) {
    super(dataSharing, router, registrationService, store, routeState);
  }

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Change Your Password',
      subTitle: '',
      showBack: false,
      showClose: false,
      steps: false,
      module: 'registration',
    });

    this.store
      .select('appReducer', 'loginReducer')
      .pipe(take(1))
      .subscribe((value) => {
        this.userName = value.userName.userName;
        this.oldPassword = value.loginUser.digitalId.password;
        this.keyValueMetadata = value.authenticateResponse.keyValueMetadata;
      });
  }

  responseProccessor() {
      this.router.navigate(['/dashboard']);
  }
}
