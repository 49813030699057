import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionService } from '../../../../core/transaction-service/transaction.service';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-tax-payment-review',
  templateUrl: './namibia-tax-payment-review.component.html',
  styleUrls: ['./namibia-tax-payment-review.component.scss'],
})
export class NamibiaTaxPaymentReviewComponent implements OnInit {
  reviewModel: any;
  reviewHeaderObj;
  isSmeMode = false;
  btnTxt;
  constructor(
    private dataSharingService: DataSharingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private store: Store<{ appReducer }>,

  ) {
    dataSharingService.setHeadPagination({
      mainTitle: 'Tax Payment',
      subTitle: 'Review',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'taxPaymentCDI',
    });
    store
        .select('appReducer', 'loginReducer')
        .subscribe((data) => {
          if (data) {
            this.isSmeMode = data.isSmeMode;
          }
        });
    this.reviewModel = this.dataSharingService.dataFromFirstStep;
  }

  ngOnInit() {
    this.reviewHeaderObj = {
      name: this.reviewModel.selectedBiller.name,
    };
    this.setBtnTxt();
  }
  setBtnTxt(){
    if(this.isSmeMode){
      this.btnTxt = 'Send for Approval';
    }
    else{
      this.btnTxt = 'Pay';
    }
  }
  goToReceipt(): void {
    if(this.isSmeMode && this.dataSharingService.selectedApprover){
      const financialRule = { financialRule: this.dataSharingService.selectedApprover }
      this.reviewModel.payload = { ...this.reviewModel.payload , ...financialRule }
    }
    else{
      this.reviewModel.payload = { ...this.reviewModel.payload }
    }
    this.postService(this.reviewModel.payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    return this.transactionService.makeTransaction(payload).subscribe((response) => {
      this.reviewModel.response = response;
      this.dataSharingService.dataFromSecondStep = this.reviewModel;
      this.dataSharingService.dataFromFirstStep = {};
      this.router.navigate(['./payments/na-tax-payment/receipt']);
    });
  }

  goBack(): void {
    this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }
}
