import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NamibiaTaxPaymentDetailsComponent } from './namibia-tax-payment-details/namibia-tax-payment-details.component';
import { NamibiaTaxPaymentReviewComponent } from './namibia-tax-payment-review/namibia-tax-payment-review.component';
import { NamibiaTaxPaymentReceiptComponent } from './namibia-tax-payment-receipt/namibia-tax-payment-receipt.component';
import { NamibiaTaxPaymentListComponent } from './namibia-tax-payment-list/namibia-tax-payment-list.component';

const taxPaymentRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'details',
        component: NamibiaTaxPaymentDetailsComponent,
      },
      {
        path: 'review',
        component: NamibiaTaxPaymentReviewComponent,
      },
      {
        path: 'receipt',
        component: NamibiaTaxPaymentReceiptComponent,
      },
      {
        path:'list',
        component:NamibiaTaxPaymentListComponent
      }
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(taxPaymentRoutes)],
  exports: [RouterModule],
})
export class NamibiaTaxPaymentRoutingModule {}
