import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DateUtil } from '../../core/utility-classes/date.util';

@Injectable({
  providedIn: 'root',
})
export class CutOffTimeService {
  readonly NRT_START_TIME = 'NRT_PAYMENTS_START_TIME';
  readonly NRT_END_TIME = 'NRT_PAYMENTS_END_TIME';
  readonly END_TIME_FRIDAY = 'RTGS_PAYMENTS_END_TIME_FRIDAY';
  readonly END_TIME_WEEKDAYS = 'RTGS_PAYMENTS_END_TIME_WEEKDAYS';
  readonly START_TIME_FRIDAY = 'RTGS_PAYMENTS_START_TIME_FRIDAY';
  readonly START_TIME_WEEKDAYS = 'RTGS_PAYMENTS_START_TIME_WEEKDAYS';
  readonly END_TIME_MULTI_CURRENCY = 'MULTI_CURRENCY_SUPPORT_END_TIME';
  readonly START_TIME_MULTI_CURRENCY = 'MULTI_CURRENCY_SUPPORT_START_TIME';

  timeOffsetInHolidays = environment.config.timeOffsetInHolidays;

  checkCutOffTime(cutOffTimes) {
    const currentTime = this.getMinutes(
      DateUtil.format(new Date(), DateUtil.H_MM_SS, this.timeOffsetInHolidays)
    );
    const isThursday = this.checkDay(4);
    const isFriday = this.checkDay(5);
    const nrtStartTime = this.getCutOffTimeValue(
      cutOffTimes,
      this.NRT_START_TIME
    );
    const nrtEndTime = this.getCutOffTimeValue(cutOffTimes, this.NRT_END_TIME);
    const fridayStartTime = this.getCutOffTimeValue(
      cutOffTimes,
      this.START_TIME_FRIDAY
    );
    const fridayEndTime = this.getCutOffTimeValue(
      cutOffTimes,
      this.END_TIME_FRIDAY
    );
    const weekdayStartTime = this.getCutOffTimeValue(
      cutOffTimes,
      this.START_TIME_WEEKDAYS
    );
    const weekdayEndTime = this.getCutOffTimeValue(
      cutOffTimes,
      this.END_TIME_WEEKDAYS
    );
    let startTime = isFriday ? fridayStartTime : weekdayStartTime;
    let endTime = isFriday ? fridayEndTime : weekdayEndTime;

    let isNrtAvailable = false;
    if (nrtStartTime && nrtEndTime) {
      let nrtStartTimeMinutes: any = null;
      let nrtEndTimeMinutes: any = null;
      if (nrtStartTime) {
        nrtStartTimeMinutes = this.getMinutes(nrtStartTime);
      }
      if (nrtEndTime) {
        nrtEndTimeMinutes = this.getMinutes(nrtEndTime);
      }
      isNrtAvailable =
        currentTime > nrtStartTimeMinutes && currentTime < nrtEndTimeMinutes;
    }

    if (!startTime) {
      startTime = this.getCutOffTimeValue(
        cutOffTimes,
        this.START_TIME_MULTI_CURRENCY
      );
      endTime = this.getCutOffTimeValue(
        cutOffTimes,
        this.END_TIME_MULTI_CURRENCY
      );
    }

    return {
      startTime,
      endTime,
      weekdayStartTime,
      weekdayEndTime,
      nextBusinessDayStartTime: isThursday ? fridayStartTime : weekdayStartTime,
      inOnCutOffTime:
        currentTime > this.getMinutes(startTime) &&
        currentTime < this.getMinutes(endTime),
      isNrtAvailable,
    };
  }

  getCutOffTimeValue(settings, key) {
    const foundSetting = settings.find(
      (setting) => setting.key3 === key && !!setting.value
    );
    return foundSetting && foundSetting.value;
  }

  getMinutes(time): number {
    const timeSplit = time.split(':');
    return timeSplit[0] * 60 + Number(timeSplit[1]);
  }

  checkDay(dayNumber: number): boolean {
    const today = new Date();
    // For timezone "+0300" getTimezoneOffset() returns -180 minutes
    const timezoneDiff =
      today.getTimezoneOffset() +
      DateUtil.offsetToMinutes(this.timeOffsetInHolidays);
    const dateWithOffsetInHolidays = DateUtil.add(
      today,
      timezoneDiff,
      'minutes'
    );
    return DateUtil.getWeekday(dateWithOffsetInHolidays) === dayNumber;
  }
}
