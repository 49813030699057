import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationComponent } from './registration.component';
import { CommonModule } from '@angular/common';
import { RegisterSecurityQuestionsComponent } from './register-security-questions/register-security-questions.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { CreatePasswordComponent } from './create-password/create-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RegistrationComponent,
      },
      {
        path: 'securityQuestions',
        component: RegisterSecurityQuestionsComponent,
      },
      {
        path: 'verifyUser',
        component: VerifyUserComponent,
      },
      {
        path: 'createPassword',
        component: CreatePasswordComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class RegistrationRoutingModule { }
