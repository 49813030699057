import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameInitialsServices } from './name-initials-service/name-initials.service';

@NgModule({
  declarations: [],
  providers: [NameInitialsServices],
  imports: [CommonModule],
})
export class ServicesModule {}
