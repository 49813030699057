import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RegistrationComponent } from './registration.component';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegisterSecurityQuestionsComponent } from './register-security-questions/register-security-questions.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
import { CreatePasswordComponent } from './create-password/create-password.component';

@NgModule({
  declarations: [
    RegistrationComponent,
    RegisterSecurityQuestionsComponent,
    VerifyUserComponent,
    CreatePasswordComponent,
  ],
  imports: [SharedModule, RegistrationRoutingModule],
})
export class RegistrationModule { }
