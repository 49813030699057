import { Component, OnInit } from '@angular/core';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { AppConstantService } from '../../../../core/app-constant/app-constant.service';
import { formatDate } from '@angular/common';
import { Amount } from '../../../../core/data/amount';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-tax-payment-receipt',
  templateUrl: './namibia-tax-payment-receipt.component.html',
  styleUrls: ['./namibia-tax-payment-receipt.component.scss'],
})
export class NamibiaTaxPaymentReceiptComponent implements OnInit {
  transactionStatus: string;
  receiptModel: any;
  bankLogo: string;
  date: Date = new Date();

  receiptDetails: ReceiptDetail[];
  receiptHeaders: string[];
  isSmeMode:boolean;
  constructor(
    private dataSharingService: DataSharingService,
    private appConstant: AppConstantService,
    private store: Store<{ appReducer }>,
  ) {
    dataSharingService.setHeadPagination({
      mainTitle: 'Tax Payment',
      subTitle: 'Receipt',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'taxPaymentCDI',
    });

    this.receiptModel = dataSharingService.dataFromSecondStep;
    dataSharingService.dataFromFirstStep = null;
    dataSharingService.dataFromSecondStep = null;
    this.store.select('appReducer', 'loginReducer').subscribe((data) => {
      if (data) {
        this.isSmeMode = data.isSmeMode;
      }
    });
  }

  ngOnInit() {
    this.bankLogo = this.appConstant.BankLogo;
    this.receiptDetails = this.getReceiptData();
    this.receiptHeaders = this.getAdditionalReceiptHeaders();
  }

  getReceiptData(): ReceiptDetail[] {
    const receiptDetails = [];

    if (
      this.receiptModel.response.transactionResults &&
      this.receiptModel.response.transactionResults[0].transactionResultMetaData &&
      this.receiptModel.response.transactionResults[0].transactionResultMetaData[0].transactionResultKey === 'REFERENCE' &&
      this.receiptModel.response.transactionResults[0].transactionResultMetaData[0].value
    ) {
      receiptDetails.push({
        header: 'Transaction ID',
        value: this.receiptModel.response.transactionResults[0].transactionResultMetaData[0].value,
      });
    }

    receiptDetails.push(
      {
        header: 'From',
        value: this.receiptModel.selectedFromAccount.accountFullName,
      },
      {
        header: 'Date',
        value: formatDate(this.date, 'dd MMMM yyyy', 'en-US'),
      },
      {
        header: 'NamRA Registration Office',
        value: this.receiptModel.selectedRegOfficeCode.name,
      },
      {
        header: 'NamRA Bank Account',
        value: this.receiptModel.selectedBankAccount.name,
      },
      {
        header: 'Tax Type',
        value: this.receiptModel.selectedTaxType.type,
      },
      {
        header: 'Tax Identification Number',
        value: this.receiptModel.tin,
      }
    );

    if (this.receiptModel.selectedPaymentType.name === 'Tax Period Payment') {
      receiptDetails.push(
        {
          header: 'Tax Period',
          value: `${this.receiptModel.selectedMonth.value} - ${this.receiptModel.selectedYear}`,
        });
    }

    receiptDetails.push(
      {
        header: 'My Reference',
        value: this.receiptModel.myReference,
      });
    return receiptDetails;
  }

  getAdditionalReceiptHeaders(): string[] {
    return ['TRN' + this.receiptModel.trn, this.receiptModel.selectedBiller.name];
  }

  getTaxPaymentAmount(): Amount {
    return new Amount(this.receiptModel.amount, this.receiptModel.selectedFromAccount.availableBalance.currency);
  }

  getStatus(): string {
    return this.receiptModel.transactionResults[0].responseCode.responseType;
  }
}
