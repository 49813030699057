import { NgModule } from '@angular/core';

import { TaxPaymentRoutingModule } from './tax-payment-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { TaxPaymentDetailsComponent } from './tax-payment-details/tax-payment-details.component';
import { TaxPaymentConfirmationComponent } from './tax-payment-confirmation/tax-payment-confirmation.component';
import { TaxPaymentReceiptComponent } from './tax-payment-receipt/tax-payment-receipt.component';

@NgModule({
  declarations: [TaxPaymentDetailsComponent, TaxPaymentConfirmationComponent, TaxPaymentReceiptComponent],
  imports: [SharedModule, TaxPaymentRoutingModule],
})
export class TaxPaymentModule {}
