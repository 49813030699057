import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuyComponent } from './buy.component';
import { BuyTabRoutes } from './prepaid-models.module';

const buyRoutes: Routes = [
  {
    path: '',
    component: BuyComponent,
    children: [
      {
        path: BuyTabRoutes.AIRTIME,
        // loadChildren: () => import('app/components/payments/buy/buy-airtime/buy-airtime.module').then(m => m.BuyAirtimeModule)
        loadChildren: () => import('./airtime/airtime.module').then((m) => m.AirtimeModule),
      },
      {
        path: BuyTabRoutes.DATA,
        loadChildren: () => import('./data/data.module').then((m) =>m.DataModule),
      },
      {
        path: BuyTabRoutes.TV_SUBSCRIPTION,
        loadChildren: () => import('./tvsubscription/tvsubscription.module').then((m) => m.TVSubscriptionModule),
      },
      {
        path: BuyTabRoutes.ELECTRICITY,
        loadChildren: () => import('./electricity/electricity.module').then((m) => m.ElectricityModule),
      },
      {
        path: BuyTabRoutes.WATER,
        loadChildren: () => import('./water/water.module').then((m) => m.WaterModule),
      },
      {
        path: BuyTabRoutes.RATESANDTAXES,
        loadChildren: () => import('./ratesandtaxes/ratesandtaxes.module').then((m) => m.RatesAndTaxesModule),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(buyRoutes)],
  exports: [RouterModule],
})
export class BuyRoutingModule {}
