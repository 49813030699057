import { DataSharingService } from '../../../../../core/data-sharing/data-sharing.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bulkFileList',
  templateUrl: './bulkFileUploadList.component.html',
  styleUrls: ['./bulkFileUploadList.component.scss'],
})
export class BulkFileUploadListComponent implements OnInit {
  recipientList;
  editDetails;
  groupList = [];
  constructor(private router: Router, private dataSharingService: DataSharingService) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay & Transfer',
      subTitle: '',
      showBack: false,
      showClose: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
  }
  goBack() {
    return this.router.navigate(['/menu/payments']);
  }

  addNewFile() {
    this.dataSharingService.setBulkFileData({});
    return this.router.navigate(['/payments/bulkFileUpload/details']);
  }
}
