import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankAccount } from '@core/account-service/data/bank-account';
import { Institution } from '@core/banking-metadata/data/institution';
import { SchoolFeeDetails } from '@core/prepaid-service/data/school-fee-details';
import { VasProvider } from '@core/prepaid-service/data/vas-provider';
import { PaymentConfirmation } from '@core/recipient-service/data/payment-confirmation';
import { InstitutionBillDetails } from '@core/transaction-service/data/school-institutions/institution-bill-details';
import { InstitutionBillDetailsRequest } from '@core/transaction-service/data/school-institutions/institution-bill-details-request';
import { InstitutionsBillInvoice } from '@core/transaction-service/data/school-institutions/institution-bill-invoice';
import { VasPayment } from '@core/transaction-service/data/vas-payment';
import { TransactionService } from '@core/transaction-service/transaction.service';
import { UuidGeneratorService } from '@core/UUID-generator/uuid-generator.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AccountsIteratorService } from '../../../../core/accounts-iterator/accounts-iterator.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { PrepaidService } from '../../../../core/prepaid-service/prepaid-service.service';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';

export interface CheckedInvoice {
  invoice: InstitutionsBillInvoice;
  isChecked: boolean;
  enteredAmount: number;
}

@Component({
  selector: 'sbg-schoolfees-invoice',
  templateUrl: './schoolfees-invoice.component.html',
  styleUrls: ['./schoolfees-invoice.component.scss'],
})
export class SchoolFeesInvoiceComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();

  reviewHeader: any;
  billDetails: InstitutionBillDetails;
  accounts: BankAccount[] = [];
  vasProvider: VasProvider;
  transactionRequest: TransactionsRequest = {
    account: undefined,
    transactions: {},
  };
  totalAmount: number;
  invoices: Array<CheckedInvoice> = [];

  isPaymentAllowed = true;
  isInvoicePayment = false;
  selectedInstitution: Institution;
  paymentConfirmation: PaymentConfirmation;
  returnToDetailsScreen = true;

  private readonly SCHOOL_FEE_TYPE = 'SCHOOLFEE';

  private readonly INVOICE_PAYMENT_TYPE = 'INVOICE';

  private readonly INSTITUTION_TYPE = 'SCHOOL';

  private readonly ACCOUNT_CURRENCY = 'local';

  private readonly NOTIFICATION_TYPE = 'SMS';

  private readonly FULL_PAYMENT = 'FULL';

  constructor(
    private accountsIterator: AccountsIteratorService,
    private dataSharingService: DataSharingService,
    private router: Router,
    private prepaidService: PrepaidService,
    private activatedRoute: ActivatedRoute,
    private transactionService: TransactionService,
    private uuidGeneratorService: UuidGeneratorService,
    private store: Store<{ appReducer }>
  ) {
    dataSharingService.setHeadPagination({
      mainTitle: 'Pay Fee',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'schoolFees',
    });
    this.getAccounts();
  }

  ngOnInit() {
    //in case you move back from review screen
    if (this.dataSharingService.dataFromThirdStep) {
      this.transactionRequest = this.dataSharingService.dataFromThirdStep.transactionRequest;
      this.selectedInstitution = this.dataSharingService.dataFromThirdStep.selectedInstitution;
      this.isInvoicePayment = this.selectedInstitution.paymentType == this.INVOICE_PAYMENT_TYPE;
      this.billDetails = this.dataSharingService.dataFromThirdStep.billDetails;
      this.invoices = this.dataSharingService.dataFromThirdStep.invoices;
      this.totalAmount = parseFloat(this.dataSharingService.dataFromThirdStep.totalAmount);
      this.isPaymentAllowed = this.selectedInstitution.paymentType == this.FULL_PAYMENT ? false : this.billDetails.paymentAllowed;
      this.returnToDetailsScreen = this.dataSharingService.dataFromThirdStep.returnToDetailsScreen;
    } else {
      //in case you come from details screen
      let institutionBillDetailsRequest: InstitutionBillDetailsRequest;
      if (this.dataSharingService.dataFromSecondStep) {
        this.selectedInstitution = this.dataSharingService.dataFromSecondStep.selectedInstitution;
        this.isInvoicePayment = this.INVOICE_PAYMENT_TYPE == this.selectedInstitution.paymentType;
        institutionBillDetailsRequest = {
          entityReferenceId: this.dataSharingService.dataFromSecondStep.rollNumber,
          institutionId: this.selectedInstitution.code,
          institutionType: this.INSTITUTION_TYPE,
        };
      }
      //in case you are paying for existing receipt
      if (this.dataSharingService.dataFromFirstStep) {
        this.returnToDetailsScreen = false;
        this.selectedInstitution = this.dataSharingService.dataFromFirstStep.institution;
        this.isInvoicePayment = this.INVOICE_PAYMENT_TYPE == this.selectedInstitution.paymentType;
        institutionBillDetailsRequest = {
          entityReferenceId: this.dataSharingService.dataFromFirstStep.rechargeNumber,
          institutionId: this.selectedInstitution.code,
          institutionType: this.INSTITUTION_TYPE,
        };
      }
      this.getBillDetails(institutionBillDetailsRequest);
    }

    this.prepaidService
      .getProviderDetails()
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.vasProvider = response.prepaidProviders.find((provider) => this.SCHOOL_FEE_TYPE === provider.prepaidType);
        }
      });
  }

 

  updateAccount(account: BankAccount) {
    this.transactionRequest.account = account;
  }

  isCheckBoxesClicked(): boolean {
    return this.invoices.filter((checkedInvoice) => checkedInvoice.isChecked).length > 0;
  }

  updateCheckBoxes(event, invoiceId: string) {
    this.invoices = this.invoices.map((checkedInvoice) => {
      return invoiceId == checkedInvoice.invoice.id
        ? {
            ...checkedInvoice,
            isChecked: event,
          }
        : checkedInvoice;
    });

    this.updateTotalInvoiceAmount();
  }

  updateInvoiceAmount(amount: number, invoiceId: string) {
    if (amount) {
      this.invoices = this.invoices.map((checkedInvoice) => {
        return invoiceId == checkedInvoice.invoice.id
          ? {
              ...checkedInvoice,
              enteredAmount: amount,
            }
          : checkedInvoice;
      });

      this.updateTotalInvoiceAmount();
    }
  }

  updateTotalInvoiceAmount() {
    this.totalAmount = 0;
    this.invoices
      .filter((checkedInvoice) => checkedInvoice.isChecked)
      .map((checkedInvoice) => (this.totalAmount += Number(checkedInvoice.enteredAmount)));
  }

  updateTotalAmount(amount: number) {
    if (amount) {
      this.totalAmount = amount;
    }
  }

  createPaymentRequest() {
    this.store
      .select((state) => state.appReducer.loginReducer.authenticateResponse)
      .pipe(take(1))
      .subscribe((data) => {
        if (data && data.userProfile && data.userProfile.mobileNumber) {
          this.paymentConfirmation = {
            notifications: [
              {
                address: data.userProfile.mobileNumber,
                type: this.NOTIFICATION_TYPE,
              },
            ],
          };
        }
      });

    if (this.isInvoicePayment) {
      const payments: VasPayment[] = this.invoices
        .filter((checkedInvoice) => checkedInvoice.isChecked)
        .map((checkedInvoice) => {
          return this.createSinglePayment(checkedInvoice);
        });
      this.transactionRequest = { ...this.transactionRequest, transactions: { prepaidPurchases: payments } };
    } else {
      const payment = this.createSinglePayment();
      this.transactionRequest = { ...this.transactionRequest, transactions: { prepaidPurchases: [payment] } };
    }
  }

  

  goBack() {
    if (this.returnToDetailsScreen) {
      this.dataSharingService.dataFromThirdStep = null;
      this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
    } else {
      this.router.navigate(['/payments/schoolfees/list']);
    }
  }

  goToConfirmation() {
    this.createPaymentRequest();
    this.dataSharingService.dataFromThirdStep = {
      transactionRequest: this.transactionRequest,
      payments: this.transactionRequest.transactions.prepaidPurchases,
      selectedInstitution: this.selectedInstitution,
      billDetails: this.billDetails,
      invoices: this.invoices,
      isInvoicePayment: this.isInvoicePayment,
      totalAmount: this.totalAmount,
      returnToDetailsScreen: this.returnToDetailsScreen,
    };
    this.router.navigate(['../review'], { relativeTo: this.activatedRoute });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getBillDetails(institutionBillDetailsRequest: InstitutionBillDetailsRequest) {
    this.transactionService
      .getInstitutionBillDetails(institutionBillDetailsRequest)
      .pipe(take(1))
      .subscribe((value) => {
        if (value.billDetails) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.billDetails = { ...value.billDetails! };
          this.setDataFromBillDetails();
        }
      });
  }

  private setDataFromBillDetails() {
    this.invoices = this.billDetails.invoices.map((invoice) => {
      return {
        invoice: invoice,
        isChecked: false,
        enteredAmount: invoice.amount.amount,
      };
    });

    if (!this.isInvoicePayment) {
      this.totalAmount = this.billDetails.outstandingBalance.amount;
    }
    this.isPaymentAllowed = this.selectedInstitution.paymentType == this.FULL_PAYMENT ? false : this.billDetails.paymentAllowed;
  }

  private getAccounts() {
    this.accounts = this.accountsIterator.getPaymentFromAccounts(this.ACCOUNT_CURRENCY);
    this.transactionRequest.account = this.accounts[0];
  }

  private createSinglePayment(checkedInvoice?: CheckedInvoice): VasPayment {
    let payment: VasPayment = {
      transactionId: this.uuidGeneratorService.getUUID(),
      rechargeNumber: this.billDetails.entityReferenceId,
      customerName: this.billDetails.entityName,
      basePrepaidProvider: this.convertVasProviderToBaseProvider(),
      schoolFee: this.createSchoolFeeDetailsForPayment(checkedInvoice),
      autoSave: false,
      paymentConfirmation: this.paymentConfirmation,
      amount: { amount: this.totalAmount, currency: this.transactionRequest.account.accountCurrency.currency },
    };

    if (this.isInvoicePayment) {
      payment = { ...payment, amount: { amount: checkedInvoice.enteredAmount, currency: this.transactionRequest.account.accountCurrency.currency } };
    }
    return payment;
  }

  private createSchoolFeeDetailsForPayment(checkedInvoice?: CheckedInvoice): SchoolFeeDetails {
    let schoolFeeDetails: SchoolFeeDetails = {
      schoolCode: this.selectedInstitution.code,
      schoolName: this.selectedInstitution.name,
      outstandingBalance: this.billDetails.outstandingBalance,
    };

    if (checkedInvoice) {
      const updatedInvoice: InstitutionsBillInvoice = {
        ...checkedInvoice.invoice,
        amount: { amount: checkedInvoice.enteredAmount, currency: checkedInvoice.invoice.amount.currency },
      };

      schoolFeeDetails = {
        ...schoolFeeDetails,
        invoice: updatedInvoice,
      };
    }

    return schoolFeeDetails;
  }

  private convertVasProviderToBaseProvider() {
    return {
      friendlyName: this.vasProvider.friendlyName,
      prepaidProviderId: this.vasProvider.prepaidProviderId,
      prepaidType: this.vasProvider.prepaidType,
      providerMode: this.vasProvider.providerMode,
    };
  }
}
