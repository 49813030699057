import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { SnackBarService } from '../../../../services/snack-bar-service/snackBarService';

import * as _ from 'lodash';

import { NameInitialsService } from '../../../../core/name-initials/name-initials.service';
import { SearchFilterPipe } from '../../../pipes/search-filter/search-filter.pipe';
import { RecipientGroup } from '../../../../core/recipient-service/data/recipient-group';
import { TransactionTypeService } from '../../../../core/transaction-type/transaction-type.service';
import { Store } from '@ngrx/store';
import { DateUtil } from '../../../../core/utility-classes/date.util';

@Component({
  selector: 'sbg-list-table-recipient',
  templateUrl: './list-table-recipient.component.html',
  styleUrls: ['./list-table-recipient.component.scss'],
  providers: [SearchFilterPipe],
  encapsulation: ViewEncapsulation.None,
})
export class ListRecipientTableComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @ViewChild('listTableIdentifier')
  listTableIdentifierEl: ElementRef<HTMLInputElement>;
  @ViewChild('footer') footer: ElementRef<HTMLInputElement>;

  @Input() recipients: any;
  @Input() isLimitExceed: any;
  @Input() isUSDAccountsAvailable: any;
  @Input() listSubType: any;
  @Input() recipientsSecondGroup: any;
  @Input() searchText: string;
  @Input() selectButtonType: string; // is it radio or checkbox
  @Input() nameInitialKey: string; // initial to be displayed in list
  @Input() nameSubText: string; // subtxt to be displayed in list
  @Input() nameInitialKeySecondGroup: string; // initial for second list
  @Input() nameSubTextSecondGroup: string; // subtxt to be displayed in second list
  @Input() rightTextUpTitle: string; // Right side text (in up) to be displayed in list
  @Input() rightTextUpKey: string; // Right side text (in up) to be displayed in list
  @Input() rightTextDownTitle: string; // Right side text (in down) to be displayed in list
  @Input() rightTextDownKey: string; // Right side text (in down) to be displayed in list
  @Input() addItemText: string;
  @Input() listType: string; // type of list items like Beneficiary or biller or prepaid
  @Input() selectPromptMessage: string;
  @Input() secondPromptMessage: string;
  @Input() isAddItemRequired: boolean;
  @Input() navigateGroups: boolean;
  @Input() isInfoIconRequired: boolean;
  @Input() isSearchNeeded: boolean;
  @Input() dropDownData: RecipientGroup[];
  @Input() filterOpt: boolean;
  @Input() leftButtonText: string;
  @Input() showLeftButton: boolean;
  @Input() rightButtonText: string;
  @Input() rightButtonLeftSideIcon: string;
  @Input() showRightButton: boolean;
  @Input() leftSecondaryButtonText: string;
  @Input() showLeftSecondaryButton: boolean;
  @Input() rightSecondaryButtonText: string;
  @Input() showRightSecondaryButton: boolean;
  @Input() disableRightButton: boolean;
  @Input() showFooter: boolean;
  @Input() voucher: string; // initial to be displayed in list in case of blueVoucher
  @Input() voucherRefrenceId: string;
  @Input() blueVoucherStatus: string;
  @Input() ticketStatus: string;
  @Input() showVoucherFilter: boolean;

  @Input() route: string;

  @Input() searchFilterHide: boolean; // It is used for hide 1st(Search section)

  @Input() enableOnceOffPay: boolean;
  // this needed to change the right btn text for groups
  // @Input() routeMode: string;

  @Output() clickInfoFunction = new EventEmitter<any>();
  @Output() addItemFunction = new EventEmitter<any>();
  @Output() getInfoFunction = new EventEmitter<any>();
  // @Output() goToPayFunction = new EventEmitter<any>();
  @Output() goToManageGroups = new EventEmitter<any>();
  @Output() rightButtonClicked = new EventEmitter<any>();
  @Output() leftButtonClicked = new EventEmitter<any>();
  @Output() leftSecondaryButtonClicked = new EventEmitter<any>();
  @Output() rightSecondaryButtonClicked = new EventEmitter<any>();
  @Output() itemClicked = new EventEmitter<any>();
  @Input() group: RecipientGroup;
  @Output() groupChanged = new EventEmitter<RecipientGroup>();
  @Output() voucherFilterClicked = new EventEmitter<any>();
  @Output() toggleFilterClicked = new EventEmitter<any>();

  @Input() fromDate: any;
  @Input() toDate: any;
  @Input() uniqAccountsDataFiltered: any;

  @Input() sortByFilterValues: string;

  @Input() isMaxData = false;
  config = environment.config;
  tabData: any;
  initiatedBy = '';
  requestDate;
  processingDate;

  currentDate;
  todayDate = new Date();

  approveDate;
  test;

  activeApproveButton;
  recipientsClone: any[] = [];

  selected = [];
  disableSelectedCard;
  routeBasedChanges = {
    showGroupDropdown: false,
    showGroupNav: false,
    fixedFooter: false,
    showFooter: false,
  };
  isSmeMode = false;
  recipientsCopy = [];

  voucherFilterValue = 'Show Recent Vouchers >';
  voucherType = 'BlueVoucher';
  promptMessage = 'BlueVoucher Transactions';
  toggle = false;
  toggleBtnList = ['Redeemed', 'Cancelled', 'Expired'];
  toggleButton: any;

  disable = {
    leftButton: false,
  };
  dateSortKey;
  sortByVal;
  listWidth: number;
  listTableFilterWidth = false;
  dropDownPlaceHolder;
  searchPlaceHolder;
  constructor(
    private nameInitialsService: NameInitialsService,
    private searchFilter: SearchFilterPipe,
    private transactionTypeService: TransactionTypeService,
    private snack: SnackBarService,
    private store: Store<{ appReducer }>
  ) {
    this.store
      .select('appReducer', 'approvalReducer')
      .subscribe((stateData) => {
        this.tabData = stateData.selectedApprovalTab;
      });
  }

  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onResize(event) {
    this.listWidth = this.listTableIdentifierEl.nativeElement.offsetWidth;
  }

  toggleVoucherFilter() {
    this.voucherFilterValue =
      this.voucherFilterValue == 'Show Recent Vouchers >'
        ? '< Show Active Vouchers'
        : 'Show Recent Vouchers >';
    this.voucherType =
      this.voucherType == 'Active Voucher'
        ? 'Recent Vouchers'
        : 'Active Voucher';
    this.promptMessage =
      this.voucherType == 'Active Voucher'
        ? 'Select an Active Voucher to cancel.'
        : 'This list shows inactive vouchers from last 30 days. Select a voucher to pay again.';
    this.toggle = !this.toggle;
    this.cleraTogglefilter();

    this.voucherFilterClicked.emit(this.voucherType);
    // console.log(this.voucherType);
  }

  toggleClicked() {
    this.toggleFilterClicked.emit(this.toggleButton);
  }

  cleraTogglefilter() {
    this.toggleButton = [];
    this.toggleClicked();
  }

  ngOnInit() {
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.isSmeMode = value.isSmeMode;
    });

    if (this.listType === 'ScheduleTransaction') {
      this.dateSortKey = 'nextPaymentDate';
    }
    if (this.config.isBillerCDI && this.isSmeMode && this.showVoucherFilter) {
      this.dropDownPlaceHolder = 'Sort Vouchers By';
      this.searchPlaceHolder = 'Search by Voucher No.';
    } else {
      this.dropDownPlaceHolder = 'Groups';
      this.searchPlaceHolder = 'Search';
    }
  }
  selectItem(event, recipeint) {
    if (this.selectButtonType === 'checkbox') {
      this.selectCheckboxItems(event, recipeint);
    } else {
      if (
        this.listType !== 'debitCard' ||
        (this.listType === 'debitCard' &&
          !this.greyOutCard(recipeint.cardStatus))
      ) {
        this.selectRadioItems(event, recipeint);
      }
    }
  }

  ngAfterViewInit() {
    this.listWidth = this.listTableIdentifierEl.nativeElement.offsetWidth;
    this.routeSpecificChanges();
  }

  routeSpecificChanges() {
    const groupDropdownPages = [
      'pay-beneficiary',
      'pay-biller',
      'cancel-payment-list',
    ];
    const groupNavPages = [
      'recipient-list-beneficiary',
      'recipient-list-biller',
      'recipient-list-cma-beneficiary',
    ];
    const showFooterPages = [
      'pay-beneficiary',
      'group-details',
      'unayo',
      'schedule-payments',
      'select-beneficiaries',
      'select-billers',
      'select-cma-beneficiaries',
      'buy',
      'approvals',
      'cancel-payment-list',
      'na-tax-payment',
    ];
    if (this.config.isBillerCDI) {
      showFooterPages.push('pay-biller');
    }
    if (groupDropdownPages.indexOf(this.route) === -1) {
      this.routeBasedChanges.showGroupDropdown = false;
      // this.routeBasedChanges.showGroupNav = true;
    } else {
      this.routeBasedChanges.showGroupDropdown = true;
      // this.routeBasedChanges.showGroupNav = false;
    }
    if (groupNavPages.indexOf(this.route) === -1) {
      this.routeBasedChanges.showGroupNav = false;
    } else {
      this.routeBasedChanges.showGroupNav = true;
    }
    /* if (this.route === 'schedule-payments') {
      this.routeBasedChanges.fixedFooter = false;
    } else {
      this.routeBasedChanges.fixedFooter = true;
    } */
    if (
      this.config.isBillerCDI &&
      this.isSmeMode &&
      this.route === 'cancel-payment-list'
    ) {
      this.routeBasedChanges.fixedFooter = false;
    } else {
      this.routeBasedChanges.fixedFooter = true;
    }

    if (showFooterPages.indexOf(this.route) === -1) {
      this.routeBasedChanges.showFooter = false;
    } else {
      this.routeBasedChanges.showFooter = true;
    }
    if (
      this.routeBasedChanges.showGroupDropdown ||
      this.routeBasedChanges.showGroupNav ||
      this.listType === 'NA_TAX_PAYMENT'
    ) {
      this.listTableFilterWidth = true;
    } else {
      this.listTableFilterWidth = false;
    }
    // hot fix for Approvals ..needs to be improved
    if (this.listType === 'Approval') {
      this.routeBasedChanges.showFooter = this.showFooter;
    }
  }
  ngOnChanges(change: any) {
    if (change.disableRightButton) {
      this.disableRightButton = change.disableRightButton.currentValue;
      // this.sortByVal = this.sortByFilterValues;
    }

    // Disable recall button for non-financial transactions (Pending on me)
    this.disableRightButton = !!(
      this.tabData &&
      this.tabData.selectedTab === 1 &&
      this.tabData.selectedSubTab &&
      this.tabData.selectedSubTab === 2
    );

    if (change.recipients) {
      this.recipients = this.recipients || [];
      this.recipientsClone = _.cloneDeep(this.recipients);

      this.selected = [];
      this.leftButtonText = 'Select All';
    }

    if (change.sortByFilterValues) {
      this.sortByVal = this.sortByFilterValues;
    }

    if (change.uniqAccountsDataFiltered || change.fromDate || change.toDate) {
      this.fromDate = new Date(this.fromDate);
      this.toDate = new Date(this.toDate);

      this.applyFilterFn();
    }

    if (this.config.isBillerCDI && !this.showRightButton) {
      this.routeBasedChanges.showGroupDropdown = false;
    }

    if (this.config.isBillerCDI && this.isMaxData) {
      this.recipientsCopy = [...this.recipients];
      this.recipients = [];
      this.selectPromptMessage =
        'Please enter atleast three characters to start searching';
    }
    // hot fix for Approvals ..needs to be improved
    if (this.listType === 'Approval') {
      this.routeBasedChanges.showFooter = this.showFooter;
    }
  }
  // Apply final filter
  applyFilterFn() {
    // apply date from-to filter
    if (this.fromDate && this.toDate) {
      const start = this.fromDate.getTime();
      const end = this.toDate.getTime();
      if (start && end) {
        this.filterDate(this.recipientsClone, start, end);
      } else {
        this.recipients = _.cloneDeep(this.recipientsClone);
      }
    } else {
      this.recipients = _.cloneDeep(this.recipientsClone);
    }

    if (this.uniqAccountsDataFiltered && this.uniqAccountsDataFiltered.length) {
      const filteredAcc: any = [];

      this.uniqAccountsDataFiltered.forEach((accData) => {
        this.recipients.forEach((recData) => {
          if (recData.fromAccount === accData.accountNumber) {
            filteredAcc.push(recData);
          }
        });
      });
      this.recipients = filteredAcc;
    } else if (
      this.listType === 'ScheduleTransaction' &&
      this.uniqAccountsDataFiltered &&
      !this.uniqAccountsDataFiltered.length
    ) {
      this.recipients = [];
    }
  }

  // Filter : Date : from-to
  filterDate(recipientsData: any, start: any, end: any) {
    const filteredData = [];
    const recipientsClone = _.cloneDeep(recipientsData);

    recipientsClone.forEach((item) => {
      let requiredDate: any;
      if (this.listType === 'ScheduleTransaction') {
        requiredDate = item.nextPaymentDate;
      }
      const formattedDate = DateUtil.format(requiredDate, DateUtil.MMM_DD_YYYY);
      const d = new Date(formattedDate);
      const checkDate = d.getTime();
      const localOffset = d.getTimezoneOffset() * 60000;
      const checkDateEnd = d.getTime() + localOffset;

      if (checkDate >= start && checkDateEnd <= end) {
        filteredData.push(item);
      }
    });

    this.recipients = filteredData;
  }

  clearInput() {
    this.searchText = '';
  }

  getNameInitials(name): string {
    if (this.listType === 'Unayo') {
      return 'UP';
    } else {
      return this.nameInitialsService.getInitials(name);
    }
  }
  manageGroupsClicked() {
    this.goToManageGroups.emit();
  }
  inputChanged(str) {
    let filteredRecipients;
    if (this.config.isBillerCDI && this.isMaxData) {
      this.recipients = [...this.recipientsCopy];
    }
    this.searchText = str;
    filteredRecipients = this.searchFilter.transform(
      this.recipients,
      str,
      'name'
    );
    if (
      (!filteredRecipients.length || filteredRecipients.length === 0) &&
      this.config.isBillerCDI &&
      this.isMaxData
    ) {
      this.snack.open('No Data Found!', this.snack.TYPE_ERROR);
    }

    if (filteredRecipients.length !== this.recipients.length) {
      if (['Select All', 'De-Select All'].indexOf(this.leftButtonText) !== -1) {
        this.disable.leftButton = true;
      } else {
        this.disable.leftButton = false;
      }
    } else {
      this.disable.leftButton = false;
    }
  }

  addItemToListFunction() {
    // this.addItemFunction.emit(event);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToPay(event) {
    // this.goToPayFunction.emit(this.selected);
  }

  showHideButtons() {
    // this.showRightButton = this.selected && this.selected.length ? true : false;
    if (this.listType === 'Approval') {
      this.showRightButton = true;
    }
  }

  selectAll() {
    if (this.selected.length === this.recipients.length) {
      this.selected = [];
      this.leftButtonText = 'Select All';
    } else {
      this.selected = [...this.recipients];
      this.leftButtonText = 'De-Select All';
    }

    this.showHideButtons();
  }

  removeOffset(date) {
    return DateUtil.format(
      date,
      DateUtil.ISO_DD_MMMM_YYYY,
      this.config.timeOffsetInHolidays
    );
  }

  rightBtnClicked() {
    this.rightButtonClicked.emit(this.selected);
  }
  rightSecondaryBtnClicked() {
    this.rightSecondaryButtonClicked.emit(this.selected);
  }

  leftBtnClicked() {
    if (['Select All', 'De-Select All'].indexOf(this.leftButtonText) !== -1) {
      this.selectAll();
    }
    const dataObj = {
      type: this.selectButtonType,
      data: {},
      selected: this.selected,
    };
    this.leftButtonClicked.emit(dataObj);
  }

  leftSecondaryBtnClicked() {
    this.leftSecondaryButtonClicked.emit(this.selected);
  }

  addItemClicked(event) {
    this.addItemFunction.emit(event);
  }

  getInfo(event, data) {
    // event.preventDefault();
    event.stopPropagation();
    this.getInfoFunction.emit(data);
  }

  groupChangedFn() {
    this.groupChanged.emit(this.group);
  }

  private greyOutCard(cardStatus) {
    if (this.listType === 'debitCard') {
      status = this.transactionTypeService.getDebitCardStatus(cardStatus);
      if (status === 'Undefined' || status === 'Stopped') {
        this.disableSelectedCard = true;
      } else if (
        status === 'Temporarily Blocked' ||
        status === 'Active' ||
        status === 'Issued'
      ) {
        this.disableSelectedCard = false;
      }
      return this.disableSelectedCard;
    }
  }
  private selectCheckboxItems(event, item) {
    if (item) {
      if (this.selected.indexOf(item) !== -1) {
        this.selected.splice(this.selected.indexOf(item), 1);
      } else {
        this.selected.push(item);
      }
    }

    const dataObj = {
      type: this.selectButtonType,
      data: item || {},
      selected: this.selected,
    };

    this.itemClicked.emit(dataObj);
    if (this.route !== 'pay-beneficiary') {
      this.showHideButtons();
    }
    if (this.route === 'pay-beneficiary') {
      if (this.selected.length > 10) {
        this.disableRightButton = true;
      } else {
        this.disableRightButton = false;
      }
    }

    if (
      this.config.localCurrency === 'SZL' &&
      this.tabData &&
      this.tabData.selectedTab === 1 &&
      this.tabData.selectedSubTab &&
      this.tabData.selectedSubTab === 2
    ) {
      this.disableRightButton = true;
    }
  }

  private isPresentInSelectedList(item) {
    return this.selected.indexOf(item) !== -1;
  }

  private selectRadioItems(event, item) {
    if (this.listType === 'Approval') {
      this.initiatedBy = item.data.initiatedBy;
      if (
        this.initiatedBy === 'ME' &&
        this.tabData &&
        this.tabData.selectedSubTab &&
        this.tabData.selectedSubTab === 2
      ) {
        this.disableRightButton = true;
      } else {
        this.disableRightButton = false;
        if (this.tabData && this.tabData.selectedTab === 0) {
          let processingDate;
          if (this.tabData.selectedSubTab === 0) {
            processingDate = this.removeOffset(
              item.data.financialTransaction.transactionDate
            );
            this.requestDate = this.removeOffset(
              item.data.financialTransaction.requestDate
            );
          } else if (this.tabData.selectedSubTab === 1) {
            processingDate = this.removeOffset(item.processingDate);
            this.requestDate = this.removeOffset(item.uploadDate);
          } else if (this.tabData.selectedSubTab === 2) {
            this.requestDate = this.removeOffset(item.requestDate);
          }

          this.currentDate = DateUtil.format(
            this.todayDate,
            null,
            this.config.timeOffsetInHolidays
          );
          this.approveDate = DateUtil.format(
            processingDate,
            null,
            this.config.timeOffsetInHolidays
          );
          this.test = DateUtil.areSame(
            this.approveDate,
            this.currentDate,
            'days'
          );
          if (this.test) {
            this.activeApproveButton = false;
          } else {
            this.activeApproveButton = DateUtil.isBefore(
              this.approveDate,
              this.currentDate
            );
          }

          if (this.activeApproveButton && this.config.localCurrency !== 'SZL') {
            this.disableRightButton = true;
          } else {
            this.disableRightButton = false;
          }
        }
      }
    }
    event.stopPropagation();
    const dataObj = {
      type: this.selectButtonType,
      data: item,
    };
    if (this.isPresentInSelectedList(item)) {
      this.selected = [];
      dataObj.data = null;
    } else {
      this.selected = [item];
    }

    this.itemClicked.emit(dataObj);
  }
}
