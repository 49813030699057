import { Injectable } from '@angular/core';
import { BuyModule } from './buy.module';
import { AirtimeModel, Duration, TvModel } from './prepaid-models.module';

@Injectable({
  providedIn: BuyModule,
})
export default class BuyUtilities {
  static getMonths(): Duration[] {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push({
        key: i,
        value: `${i} ${i === 1 ? 'Month' : 'Months'}`,
      });
    }
    return months;
  }

  static getDefaultTvModel(): TvModel {
    return {
      amount: {
        amount: '',
        currency: '',
      },
      selectedAccount: {
        accountCurrency: {
          currency: '',
          currencyCode: '',
          currencyName: '',
        },
        availableBalance: {
          amount: 0,
          currency: '',
        },
      },
      verified: false,
      showVoucher: false,
      selectedProvider: {
        friendlyName: '',
        prepaidProviderId: '',
        providerMode: '',
        prepaidProduct: {
          prepaidPreset: [],
          range: {
            max: { amount: 0, currency: '' },
            min: { amount: 0, currency: '' },
          },
          vouchers: [],
        },
        validate: false,
      },
      payload: {
        autoSave: false,
        customerName: undefined,
        productCode: '',
        rechargeNumber: '',
        paymentConfirmation: {
          notifications: [],
        },
      },
    };
  }

  formatRecipientList(recipientList) {
    return recipientList.map((recipient) => {
      return {
        ...recipient,
        ...{ providerName: recipient.basePrepaidProvider.friendlyName },
      };
    });
  }

  getDefaultAirtimeModel(): AirtimeModel {
    return {
      amount: {
        amount: '',
        currency: '',
      },
      selectedAccount: null,
      rechargeNumber: '',
      selectedProvider: null,
      basePrepaidProvider: {},
      paymentConfirmation: {
        notifications: [],
      },
    };
  }
}
