import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionsRequest } from '../../../../core/transaction-service/data/transactions-request';
import { Amount } from '../../../../core/data/amount';
import { TransactionsResponse } from '../../../../core/transaction-service/data/transactions-response';
import { ReceiptDetail } from '../../../../shared/components/receipt/receipt-detail';
import { ReceiptHeaders } from '../../../../shared/components/receipt/receipt-headers';
import { PaymentDetails } from '../gepg-payment.model';

@Component({
  selector: 'sbg-gepg-payment-receipt',
  templateUrl: './gepg-payment-receipt.component.html',
  styleUrls: ['./gepg-payment-receipt.component.scss'],
})
export class GepgPaymentReceiptComponent implements OnInit {
  transactionRequest: TransactionsRequest;
  transactionResponse: TransactionsResponse;
  amount: Amount;
  receiptDetails: ReceiptDetail[];
  paymentDetails: PaymentDetails;

  constructor(private dataSharingService: DataSharingService, private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.transactionRequest = this.dataSharingService.dataFromThirdStep.transactionRequest;
    this.transactionResponse = this.dataSharingService.dataFromThirdStep.transactionResponse;
    this.amount = this.transactionRequest.transactions.gepgBillerPayments[0].amount;
    this.paymentDetails = this.transactionRequest.transactions.gepgBillerPayments[0].paymentDetails;
    this.receiptDetails = this.getReceiptData();
    this.updateHeader();
  }

  updateHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Biller Payment (GEPG)',
      subTitle: 'Receipt',
      showBack: false,
      showClose: true,
      steps: false,
      module: '',
    });
  }

  getReceiptData(): ReceiptDetail[] {
    return [
      {
        header: ReceiptHeaders.TO,
        value: this.paymentDetails.serviceProvider.serviceProviderName,
      },
      {
        header: ReceiptHeaders.REFERENCE_NUMBER,
        value: this.paymentDetails.controlNumber,
      },
      {
        header: ReceiptHeaders.TRANSACTION_ID,
        value: this.transactionRequest.transactions.gepgBillerPayments[0].transactionId,
      },
    ];
  }

  updatePaymentsMenuHeader() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay & Transfer',
      subTitle: '',
      showBack: false,
      showClose: true,
      steps: false,
      module: '',
    });
  }

  navigateToNewPayment() {
    this.dataSharingService.resetStepData();
    this.updatePaymentsMenuHeader();
    return this.router.navigate(['../list'], { relativeTo: this.activatedRoute });
  }
}
