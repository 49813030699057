import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { FooterNavigationComponent } from '../../../cms/footer-navigation/footer-navigation.component';
import { Router, ActivatedRoute } from '@angular/router';
import { RegistrationService } from '../../../services/RegistrationService/registration.service';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { AuthenticateService } from '../../../core/authenticate-service/authenticate-service.service';
import { setRefreshFlag } from '../../../shared/store-utilities/actions/refresh.action';
import * as loginAction from '../../../shared/store-utilities/actions/login.action';
import { SessionStore } from '.././../../services/SessionStore/SessionStore';
import { environment } from './../../../../environments/environment';

import { McaHeader } from '../../../core/data/mca-header';
import { McaResponseType } from '../../../core/data/mca-response-type';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss'],
})
export class VerifyUserComponent implements OnInit {
  config = environment.config;
  contactNumber = environment.config.localOtpCustomerCare;
  mailId = environment.config.localEmailCustomerCare;
  errorMsg = '';
  userName;
  password;
  constructor(
    private dataSharing: DataSharingService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private store: Store<any>,
    private location: Location,
    private authenticate: AuthenticateService,
    private sessionStore: SessionStore
  ) {}

  ngOnInit() {
    this.dataSharing.setHeadPagination({
      mainTitle: 'Registration',
      subTitle: 'Verify',
      showBack: false,
      showClose: false,
      steps: true,
      module: 'registration',
    });

    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      if (stateData.isSmeMode) {
        this.contactNumber = this.config.localSMEcontactNumber;
        this.mailId = this.config.localSMEEmail;
      }
    });

    this.store.select('appReducer', 'registrationReducer').subscribe((value) => {
      this.userName = value.userNameRegistered;
    });
  }

  openDialog(type: string): void {
    this.dataSharing.dialogType = type;
    this.dialog.open(FooterNavigationComponent, {
      width: '56vw',
      height: '84vh',
    });
  }

  goToSetPassword() {
    const payload = {
      digitalId: {
        password: this.password,
        username: this.userName,
      },
    };
    this.postService(payload);
    this.dataSharing.setPostService(this.postService.bind(this));
  }

  postService(payload) {
    this.authenticate.login(payload).subscribe((response) => {
      this.handleLogin(payload, response);
    });
  }

  private handleLogin(model, res) {
    for (let i = 0; i < res.body.keyValueMetadata.length; i++) {
      if (res.body.keyValueMetadata[i].value === 'OR_RET' && res.body.keyValueMetadata[i].key === 'MENU_PROFILE_ID') {
        this.dataSharing.setReadOnlyMode(true);
      } else if (res.body.keyValueMetadata[i].value === 'OR_CORP' && res.body.keyValueMetadata[i].key === 'MENU_PROFILE_ID') {
        this.dataSharing.setReadOnlyMode(true);
      } 
    }
    const resType = res.headers.get(McaHeader.X_SBG_RESPONSE_TYPE);
    if (resType === McaResponseType.SUCCESS) {
      // const statementData = this.store.getState().loginReducer.sessionData;
      this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const statementData = stateData;
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
      this.store.select('appReducer').subscribe((stateData) => {});

      const getSession = this.sessionStore.getItem('SessionID');
      const result = {
        p: {
          p: res.body.userProfile.permanentUserId,
        },
        u: {
          p: [model.digitalId.username.toLowerCase()],
        },
        c: getSession.PinnedText,
      };
      const loginData = {
        authenticateResponse: res.body,
        token: res.headers.get(McaHeader.X_SBG_TOKEN),
        loginUser: model,
      };
      this.store.dispatch(loginAction.setLoginData({ login: loginData }));
      this.store.dispatch(setRefreshFlag({ refreshFlag: true }));
      this.router.navigate(['/registration/createPassword']);
      return result;
    } else {
      this.errorMsg = res.headers.get(McaHeader.X_SBG_RESPONSE_MESSAGE);
    }
  }

  
}
