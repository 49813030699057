import { AppConstantService } from '../../../core/app-constant/app-constant.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';

// import { RecipientService } from '../../../core/recipient-service/recipient-service.service';

@Component({
  selector: 'sbg-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Input() menuName;
  @Input() menuList;
  @Input() isFeatureListSelectable;
  @Output() subFeatureFn = new EventEmitter<any>();
  @Output() featureFn = new EventEmitter<any>();
  innerWidth: number;
  list;
  clickedItems = {
    list: 0,
    subList: 0,
  };
  flows = {
    payments: {
      name: 'paymentFlows',
      goBack: '/dashboard',
      mainTitle: 'Pay & Transfer',
    },
    buy: {
      name: 'buyFlows',
      goBack: '/dashboard',
      mainTitle: 'Buy',
    },
    debit: {
      name: 'debitCardFlows',
      goBack: '/dashboard',
      mainTitle: 'Debit Cards',
    },
    scheduleTransactions: {
      name: 'scheduleTransactions',
      goBack: '/dashboard',
      mainTitle: 'Schedule Transactions',
    },
    approvals: {
      name: 'approvals',
      goBack: '/dashboard',
      mainTitle: 'Approval',
    },
  };
  selectedFlow;
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   // this.checkWidth(event.target.innerWidth);
  // }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appConstant: AppConstantService,
    private dataSharing: DataSharingService
  ) {}
  ngOnInit() {
    // const flow = this.activatedRoute.snapshot.paramMap.get('flow');
    // this.selectedFlow = this.flows['approvalFlows'];
    // this.list = this.appConstant[this.selectedFlow.name];

    this.list = this.menuList;

    // this.checkWidth(window.innerWidth);
    // this.setHeadPaginationData();
  }
  checkWidth(width) {
    this.innerWidth = width;
    // check if width is greater than 600 then redirect to first index
    if (this.innerWidth > 600) {
      this.listItemClicked(0);
    }
  }
  listItemClicked(index) {
    // this.selectedTab = index;
    this.router.navigate([this.list[index].nav]);
  }
  goBack() {
    const flow = this.activatedRoute.snapshot.paramMap.get('flow');
    this.router.navigate([this.flows[flow].goBack]);
  }
  setHeadPaginationData() {
    this.dataSharing.setHeadPagination({
      mainTitle: this.selectedFlow.mainTitle,
      subTitle: '',
      showBack: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
  }
  featureClicked(feature, index) {
    if (!this.isFeatureListSelectable) {
      return;
    }
    this.clickedItems = { ...this.clickedItems, ...{ list: index } };

    if (this.menuName === 'accountsOpening') {
      this.featureFn.emit(feature);
    } else {
      this.featureFn.emit(index);
    }
  }
  subFeatureClicked(listIndex, subListIndex) {
    const obj = {
      list: listIndex,
      subList: subListIndex,
    };
    this.clickedItems = { ...this.clickedItems, ...obj };
    this.subFeatureFn.emit(this.clickedItems);
  }
}
