import { ZimraTaxCode } from '../../../core/banking-metadata/data/zimra-tax-codes-response';
import { BankAccount } from '../../../core/account-service/data/bank-account';
import { ZimraTaxAccount } from '../../../core/banking-metadata/data/zimra-tax-accounts-response';
import { SelectedProvider } from '../../buy/prepaid-models.module';
import { ZimraTaxOfficeCode } from '../../../core/banking-metadata/data/zimra-tax-officeCodes-response';
import { Amount } from '../../../core/data/amount';
import { KeyValueMetadata } from '../../../core/data/key-value-metadata';

export class TaxPaymentModel {
  taxPaymentAmount: Amount;
  beneficiaryName: string;
  beneficiaryAccountNumber: string;
  selectedZimraTaxAccount: ZimraTaxAccount;
  zimraTaxAccounts = {};
  selectedTaxCode: ZimraTaxCode;
  taxCodes: ZimraTaxCode[];
  selectedTaxOfficeCode: KeyValueMetadata;
  taxOfficeCodes: ZimraTaxOfficeCode[];
  selectedFromAccount: BankAccount;
  selectedProvider: SelectedProvider;
  portOfEntryOrDomesticTax: ZimraTaxCode;
  providerDetails = {};
  selectedTaxType: string;
  selectAssessmentYear: number;
  taxType: {
    DOMESTIC: 'DOMESTIC',
    CUSTOM: 'CUSTOM'
  };
  taxMode: string;
  region: string;
  selectedRegion: string;
  businessPartnerName: string;
  businessPartnerNumber: string;
  assessmentNumber: string;
  assessmentYear: string;
  assessmentAmount: Amount;

  constructor() {
    this.selectedFromAccount = {
      availableBalance: {
        amount: null,
        currency: '',
      },
    };
    this.taxPaymentAmount = {
      amount: null,
      currency: ''
    };
    this.beneficiaryAccountNumber = '';
    this.selectedZimraTaxAccount = {
      accountCurrency: {
        currencyCode: ''
      },
      number: ''
    };
    this.selectedTaxOfficeCode = {
      key: '',
      value: ''
    };
    this.selectedFromAccount = {
      accountCurrency: {
        currency: '',
        currencyName: '',
        currencyCode: ''
      }
    };
    this.region = '';
    this.taxMode = 'bpn';
    this.businessPartnerNumber = '';
    this.assessmentNumber = '';
  }
}
