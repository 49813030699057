import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ServiceRequestsComponent } from './service-requests.component';
import { ServiceRequestsRoutingModule } from './service-requests-routing.module';
import { NewServiceRequestComponent } from './new-service-request/new-service-request.component';
import { IndividualServiceRequestComponent } from './individual-service-request/individual-service-request.component';
import { AccountModificationRequestComponent } from './account-modification-request/account-modification-request.component';
import { ServiceRequestResultComponent } from './service-request-result/service-request-result.component';
import { LoanRequestComponent } from './loan-request/loan-request.component';
import { BlueSkyRequestComponent } from './blue-sky-request/blue-sky-request.component';
import { DepositBookRequestComponent } from './deposit-book-request/deposit-book-request.component';
import { FixedDepositBreakageComponent } from './fixed-deposit-breakage/fixed-deposit-breakage.component';
import { RenewFixedDepositComponent } from './renew-fixed-deposit/renew-fixed-deposit.component';
import { OverDraftRequestComponent } from './over-draft-request/over-draft-request.component';
import { ChequeBookRequestComponent } from './cheque-book-request/cheque-book-request.component';
import { StopChequeRequestComponent } from './stop-cheque-request/stop-cheque-request.component';
import { AtmDebitCardComponent } from './atm-debit-card/atm-debit-card.component';
import { EbankingLimitAdjustmentComponent } from './ebanking-limit-adjustment/ebanking-limit-adjustment.component';
import { BankCertifiedChequeComponent } from './bank-certified-cheque/bank-certified-cheque.component';
import { AtmLimitAdjustmentComponent } from './atm-limit-adjustment/atm-limit-adjustment.component';
import { CreditCardRequestComponent } from './credit-card-request/credit-card-request.component';
import { RenewSibekeloAccountComponent } from './renew-sibekelo-account/renew-sibekelo-account.component';
import { FixedDepositInterestRateComponent } from './fixed-deposit-interest-rate/fixed-deposit-interest-rate.component';
import { RequestInsuranceComponent } from './request-insurance/request-insurance.component';
import { CancelNoticeGivenAccountComponent } from './cancel-notice-given-account/cancel-notice-given-account.component';
import { GiveNoticeComponent } from './give-notice/give-notice.component';
import { PosReversalComponent } from './pos-reversal/pos-reversal.component';

@NgModule({
  declarations: [
    ServiceRequestsComponent,
    NewServiceRequestComponent,
    IndividualServiceRequestComponent,
    AccountModificationRequestComponent,
    ServiceRequestResultComponent,
    LoanRequestComponent,
    BlueSkyRequestComponent,
    DepositBookRequestComponent,
    FixedDepositBreakageComponent,
    RenewFixedDepositComponent,
    OverDraftRequestComponent,
    ChequeBookRequestComponent,
    StopChequeRequestComponent,
    AtmDebitCardComponent,
    EbankingLimitAdjustmentComponent,
    BankCertifiedChequeComponent,
    AtmLimitAdjustmentComponent,
    CreditCardRequestComponent,
    RenewSibekeloAccountComponent,
    FixedDepositInterestRateComponent,
    RequestInsuranceComponent,
    CancelNoticeGivenAccountComponent,
    GiveNoticeComponent,
    PosReversalComponent,
  ],
  imports: [SharedModule, ServiceRequestsRoutingModule],
})
export class ServiceRequestsModule {}
