import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: number | string): string {
    try {
      return this.formatPhoneNumber(phoneValue);
    } catch (error) {
      return phoneValue.toString();
    }
  }

  formatPhoneNumber(phoneNumber) {
    const countryCode = phoneNumber.substring(0, 3);
    const phone = phoneNumber.substring(3);
    return `+${countryCode}-${phone}`;
  }

}
