export interface PaymentSchedule {
  fromDate: Date;
  repeatNumber: number;
  repeatInterval: "DAILY" | "MONTHLY" | "SINGLE" | "WEEKLY" | "QUARTERLY" | "HALF_YEARLY" | "YEARLY";
}

export function getDefaultPaymentSchedule(): PaymentSchedule {
  return {
    fromDate: new Date(),
    repeatInterval: "SINGLE",
    repeatNumber: 1,
  };
}
