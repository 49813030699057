import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import * as _ from 'lodash';
import { RouterStateService } from '../../../services/route-state/route-state.service';

@Component({
  selector: 'app-cheque-book-request',
  templateUrl: './cheque-book-request.component.html',
  styleUrls: ['./cheque-book-request.component.scss'],
})
export class ChequeBookRequestComponent implements OnInit {
  collectionBranch: any;
  numberLeaves: any;
  allAccounts: Array<any>;
  accounts: Array<any>;
  selectedAccount;
  customerId;
  limit;
  numberOfLeavesResponse;
  numberOfLeavesData: Array<{}>;
  chequeBookBranchesData;
  numberOfLeaves;
  chequeBookBranches;
  remarks;
  keyValueType: Array<any>;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Request Cheque Book',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });
    this.serviceRequestService.numberOfLeaves().subscribe((response) => {
      this.numberOfLeavesList(response);
    });

    this.serviceRequestService.chequeBookBranches().subscribe((response) => {
      this.chequeBookBranchesList(response);
    });
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });
  }

  numberOfLeavesList(data) {
    this.numberOfLeavesResponse = data.body.numberOfLeavesList;
    const keyValueType = [];
    for (let i = 0; i < this.numberOfLeavesResponse.length; i++) {
      keyValueType[i] = Object.values(this.numberOfLeavesResponse[i])[0];
    }
    this.allAccounts = this.accountsIterator.getPaymentFromAccounts('local');
    this.accounts = this.allAccounts.filter((acc) => {
      for (let i = 0; i < acc.keyValueMetadata.length; i++) {
        if (acc.keyValueMetadata[i].key === 'FEB_PRODUCT_CODE' && _.includes(keyValueType, acc.keyValueMetadata[i].value)) {
          return acc;
        }
      }
    });
    this.selectedAccount = this.accounts[0];
    this.keyValueType = keyValueType;
    this.filterNumberofLeaves();
  }

  filterNumberofLeaves() {
    for (let index = 0; index < this.selectedAccount.keyValueMetadata.length; index++) {
      if (
        this.selectedAccount.keyValueMetadata[index].key === 'FEB_PRODUCT_CODE' &&
        _.includes(this.keyValueType, this.selectedAccount.keyValueMetadata[index].value)
      ) {
        for (let iterate = 0; iterate < this.numberOfLeavesResponse.length; iterate++) {
          if (this.selectedAccount.keyValueMetadata[index].value === this.numberOfLeavesResponse[iterate].key) {
            this.numberOfLeaves = this.numberOfLeavesResponse[iterate].value.split(',');
            this.numberOfLeavesData = this.numberOfLeaves[0];
          }
        }
      }
    }
  }

  

  collectionBranchChanged(data) {
    this.chequeBookBranchesData = data;
  }

  numberOfLeavesChanged(data) {
    this.numberOfLeavesData = data;
  }

  accountValueChanged(data) {
    this.selectedAccount = data;
    this.filterNumberofLeaves();
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      account: this.selectedAccount,
      noOfLeaves: this.numberOfLeavesData,
      collectionBranchRoute: {
        branch: {
          name: this.chequeBookBranchesData.name,
        },
        routingNumber: this.chequeBookBranchesData.code,
      },
      remarks: this.remarks,
    };
    this.serviceRequestService.chequeBookRequest(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Cheque Book Request',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
  private chequeBookBranchesList(data) {
    this.chequeBookBranches = data.body.branches;
  }
}
