import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForcedScenariosComponent } from './forced-scenarios.component';
import { CommonModule } from '@angular/common';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { PasswordComponent } from './password/password.component';
import { UsernameComponent } from './username/username.component';
import { UsernameSuccessComponent } from './username-success/username-success.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ForcedScenariosComponent,
      },
      {
        path: 'securityQuestions',
        component: SecurityQuestionsComponent,
      },
      {
        path: 'password',
        component: PasswordComponent,
      },
      {
        path: 'username',
        component: UsernameComponent,
      },
      {
        path: 'username/usernameChangeSuccess',
        component: UsernameSuccessComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class ForcedScenariosRoutingModule {}
