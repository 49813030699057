import { setSelectedBillerData } from '../../../../shared/store-utilities/actions/recipient.action';
import { GroupUtilitiesService } from '../../../../core/utilities/group-utilities/group-utilities.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecipientService } from '../../../../core/recipient-service/recipient-service.service';
import { DataSharingService } from '../../../../core/data-sharing/data-sharing.service';
import { Store } from '@ngrx/store';
import { selectBillerData } from '../../../../shared/store-utilities/actions/payment.action';

@Component({
  selector: 'sbg-pay-mobile-wallet-list',
  templateUrl: './pay-mobile-wallet-list.component.html',
  styleUrls: ['./pay-mobile-wallet-list.component.scss'],
})
export class PayMobileWalletListComponent implements OnInit {
  registeredBillerList;
  storeState;
  groups;
  allBillers;
  showUnregisteredBillers = false;
  showRightButton = false;
  rightBtnTxt;
  clearGroupFilter = {
    name: 'Clear',
    oldName: 'Clear',
    orderIndex: 0,
  };
  filteredData;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recipientService: RecipientService,
    private dataSharingService: DataSharingService,
    private groupUtilities: GroupUtilitiesService,
    private store: Store<any>,
  ) {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Pay',
      subTitle: '',
      showBack: false,
      showClose: true,
      steps: false,
      showBackFn: this.goBack.bind(this),
    });
  }

  ngOnInit() {
    this.getBillers();
    this.getBillerGroupService();
  }

  addBiller() {
    return this.router.navigate(['/recipients-management/biller/add-biller']);
  }

  goBack() {
    return this.router.navigate(['/menu/payments']);
  }

  billerClicked(billerData) {
    this.store.dispatch(selectBillerData({ selectedBiller: [billerData.data] }));
    return this.router.navigate(['../details'], { relativeTo: this.activatedRoute });
  }

  groupChangedFn(group) {
    if (group.name === 'Clear') {
      this.filteredData = this.registeredBillerList;
      return;
    }
    const selectedGroup = this.groups.filter((groupEle) => {
      return groupEle.name === group.name;
    });
    this.filteredData = selectedGroup[0].beneficiaries;
  }

  infoIconClicked(data) {
    this.store.dispatch(setSelectedBillerData({ selectedBiller: data }));
    return this.router.navigate(['/recipients-management/biller/details-biller']);
  }

  private getBillers(): void {
    this.recipientService.getBillers({}).subscribe((response) => {
      this.registeredBillerList = response.body.billers || response.body.recipients;
      this.filteredData = [...this.registeredBillerList];
    });
  }

  private getBillerGroupService(): void {
    this.recipientService.getBillerGroup('billerGroup').subscribe((response) => {
      if (response.body.groups) {
        this.groups = [...response.body.groups, this.clearGroupFilter];
        this.groups = this.groupUtilities.recipientInGroups(this.groups, this.registeredBillerList);
      }
    });
  }
}
