import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeFirstLetterOfWord',
})
export class CapitalizeFirstLetterOfWordPipe implements PipeTransform {
  transform(value: string): string {
    return value.replace(/\w\S*/g,  (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
    });
  }
}
