import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import _moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { RouterStateService } from '../../../services/route-state/route-state.service';

// tslint:disable-next-line:no-duplicate-imports

import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-renew-fixed-deposit',
  templateUrl: './renew-fixed-deposit.component.html',
  styleUrls: ['./renew-fixed-deposit.component.scss'],
})
export class RenewFixedDepositComponent implements OnInit {
  selectedRenewal: any;
  selectedRenewalPeriod: any;
  fixedDepositAccounts: Array<{}>;
  selectedAccount;
  renewalInstruction;
  availablePeriod;
  renewalInstructionData;
  availablePeriodData;
  renewalDate;
  customerId;
  currentDate;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService,
    private routeState: RouterStateService
  ) {}

  ngOnInit() {
    this.dataSharingService.setHeadPagination({
      mainTitle: 'Renew Fixed Deposit',
      subTitle: 'Details',
      showBack: false,
      showClose: true,
      steps: true,
      module: 'newServiceRequest',
    });

    this.fixedDepositAccounts = this.accountsIterator.getFixedDepositAccounts();
    this.selectedAccount = this.fixedDepositAccounts[0];

    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.customerId = value.authenticateResponse.userProfile.customerId;
    });

    this.serviceRequestService.availableFixedDepositPeriods().subscribe((response) => {
      this.availableFixedDepositPeriodList(response);
    });

    this.serviceRequestService.renewalInstructions().subscribe((response) => {
      this.renewalInstructionsList(response);
    });
  }

  

  renewalInstructionsChanged(data) {
    this.renewalInstructionData = data;
  }

  availableFixedDepositPeriodsChanged(data) {
    this.availablePeriodData = data;
    this.currentDate = moment().add('months', this.availablePeriodData);
    this.renewalDate = this.currentDate;
  }

  accountValueChanged(data) {
    this.selectedAccount = data;
  }

  submitRequest() {
    const payload = {
      customerId: this.customerId,
      depositAccount: {
        number: this.selectedAccount.number,
      },
      renewalInstructions: this.renewalInstructionData,
      renewalPeriod: this.availablePeriodData,
    };
    this.serviceRequestService.renewFixedDeposit(payload).subscribe((response) => {
      const dataForNextStep = {
        response,
        requestType: 'Renew Fixed Deposit',
        dataFromPreviousStep: payload,
      };
      this.dataSharingService.setServiceRequestResultData(dataForNextStep);
      this.router.navigate(['./serviceRequestResult'], {
        relativeTo: this.activatedRoute.parent,
      });
    });
  }

  goBack() {
    this.serviceRequestService.goBackHandler();
  }
  private renewalInstructionsList(data) {
    this.renewalInstruction = data.body.renewalInstructionList;
    this.renewalInstructionData = this.renewalInstruction[0];
  }

  private availableFixedDepositPeriodList(data) {
    this.availablePeriod = data.body.availablePeriodList;
    this.availablePeriodData = this.availablePeriod[0];
    this.currentDate = moment().add('months', this.availablePeriodData);
    this.renewalDate = this.currentDate;
  }
}
