import { createReducer, on } from '@ngrx/store';
import {
  setEditScheduleData,
  setEditOnceOffScheduleData,
  setEditSelfFundScheduleData,
  setEditPayBillerScheduleData,
} from '../actions/edit-txn-schedule.action';
import { EditTxnState } from '../state/edit-txn-schedule.state';

const editBankBenfInitState = {};
const editOnceOffBankBenfInitState = {};
const editSelfFundInitState = {};
const editPayBillerInitState = {};

const editedData: EditTxnState = {
  editBankBeneficiary: editBankBenfInitState,
  editOnceOffBankBeneficiary: editOnceOffBankBenfInitState,
  editSelfFund: editSelfFundInitState,
  editPayBiller: editPayBillerInitState,
};

export const editTxnScheduleReducer = createReducer(
  editedData,
  on(setEditScheduleData, (state, { editBankBeneficiary }) => ({
    ...state,
    editBankBeneficiary,
  })),

  on(setEditOnceOffScheduleData, (state, { editOnceOffBankBeneficiary }) => ({
    ...state,
    editOnceOffBankBeneficiary,
  })),

  on(setEditSelfFundScheduleData, (state, { editSelfFund }) => ({
    ...state,
    editSelfFund,
  })),

  on(setEditPayBillerScheduleData, (state, { editPayBiller }) => ({
    ...state,
    editPayBiller,
  }))
);
