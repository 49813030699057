import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TransactionManagementComponent } from './transaction-management.component';
import { IndividualReceiptComponent } from './individual-receipt/individual-receipt.component';
import { CommonModule } from '@angular/common';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: TransactionManagementComponent,
      },
      {
        path: 'individual-receipt',
        component: IndividualReceiptComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [RouterModule],
})
export class TransactionManagementRoutingModule {}
