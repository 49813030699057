import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
@Injectable()
export class GlobalDataUtilityService {
  isLargeScreenDevice: boolean;
  today: Date;
  globalWindow: Window;

  private maxSmallWidth = 640;
  // all stuff that are going to be set on load and need to be used
  // across the app(isSmeMode,session timer initialization etc.,) can be included in this service
  constructor(windowRef: WindowRefService, public snackBar: MatSnackBar) {
    this.globalWindow = windowRef.nativeWindow;
    this.isLargeScreenDevice = this.globalWindow.innerWidth >= this.maxSmallWidth;
    this.today = new Date();
  }
  displayToast(errorType: string, message: string, action?: 'string') {
    const config = new MatSnackBarConfig();
    if (errorType !== 'ERROR') {
      config.panelClass = ['success-snackbar'];
    } else {
      config.panelClass = ['error-snackbar'];
    }
    config.announcementMessage = message;
    config.duration = 2000;
    this.snackBar.open(message, action, config);
  }
}
