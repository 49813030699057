import { NgModule } from '@angular/core';

import { SharedModule } from '../../../shared/shared.module';

import { PayBeneficiaryRoutingModule } from './pay-beneficiary-routing.module';

import { PayBeneficiaryReviewComponent } from './pay-beneficiary-review/pay-beneficiary-review.component';
import { PayBeneficiaryReceiptComponent } from './pay-beneficiary-receipt/pay-beneficiary-receipt.component';
import { OnceOffBeneficiaryDetailsComponent } from './once-off-beneficiary-details/once-off-beneficiary-details.component';
import { PayBeneficiaryListComponent } from './pay-beneficiary-list/pay-beneficiary-list.component';
import { PayBeneficiaryDetailsComponent } from './pay-beneficiary-details/pay-beneficiary-details.component';
import { PayBeneficiaryComponent } from './pay-benificiary.component';
import { BeneficiaryComponent } from './../../recipients-management/partials/beneficiary/beneficiary.component';

@NgModule({
  declarations: [
    PayBeneficiaryReviewComponent,
    PayBeneficiaryReceiptComponent,
    OnceOffBeneficiaryDetailsComponent,
    PayBeneficiaryListComponent,
    PayBeneficiaryDetailsComponent,
    BeneficiaryComponent,
    PayBeneficiaryComponent,
  ],
  imports: [SharedModule, PayBeneficiaryRoutingModule],
  providers: [],
})
export class PayBeneficiaryModule {}
