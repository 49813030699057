import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PayBeneficiaryReviewComponent } from './pay-beneficiary-review/pay-beneficiary-review.component';
import { PayBeneficiaryReceiptComponent } from './pay-beneficiary-receipt/pay-beneficiary-receipt.component';
import { OnceOffBeneficiaryDetailsComponent } from './once-off-beneficiary-details/once-off-beneficiary-details.component';
import { PayBeneficiaryListComponent } from './pay-beneficiary-list/pay-beneficiary-list.component';
import { PayBeneficiaryDetailsComponent } from './pay-beneficiary-details/pay-beneficiary-details.component';
import { PayBeneficiaryComponent } from './pay-benificiary.component';

const payBeneficiaryRoutes: Routes = [
  {
    path: '',
    component: PayBeneficiaryComponent,
    children: [
      {
        path: 'once-off-beneficiary-details',
        component: OnceOffBeneficiaryDetailsComponent,
      },
      {
        path: 'list',
        component: PayBeneficiaryListComponent,
      },
      {
        path: 'details',
        component: PayBeneficiaryDetailsComponent,
      },
      {
        path: 'review',
        component: PayBeneficiaryReviewComponent,
      },
      {
        path: 'receipt',
        component: PayBeneficiaryReceiptComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'once-off-beneficiary-details',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(payBeneficiaryRoutes)],
  exports: [RouterModule],
})
export class PayBeneficiaryRoutingModule {}
