import { PaymentUtilitiesService } from './../../../core/payment-utilities/payment-utilities.service';
import { SnackBarService } from '../../../services/snack-bar-service/snackBarService';
import { Router } from '@angular/router';
import { SmeServiceService } from '../../../services/sme-service/sme-service.service';
import { environment } from '../../../../environments/environment';
import { PrepaidUtilitiesService } from '../../../core/prepaid-utilities/prepaid-utilities.service';
import { Store } from '@ngrx/store';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { TransactionService } from '../../../core/transaction-service/transaction.service';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';

// tslint:disable-next-line: max-line-length
import {
  setPostApprovalData,
  setSelectedApprovalData,
  setSelectedApprovalType,
} from '../../../shared/store-utilities/actions/approval.action';
import { DeviceDetectorService } from './../../../services/device-detector/device-detector.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MakeCsvService } from './../../../services/MakeCsv/makeCsvService';
import { DateUtil } from '../../../core/utility-classes/date.util';
import { DOCUMENT } from '@angular/common';


import { BankingMetadataService } from '../../../core/banking-metadata/banking-metadata.service';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
interface doctype {
  mimeType: string;
  name: string;
  documentType: string;
  documentId: string;
}
@Component({
  selector: 'app-approval-details',
  templateUrl: './approval-details.component.html',
  styleUrls: ['./approval-details.component.scss'],
})
export class ApprovalDetailsComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>();
  disableRightButton;
  currentDate;
  todayDate;
  approveDate;
  test;
  activeApproveButton;
  requestDate;
  screenSize: string;
  bopCodes;
  window: any;

  docDetails: doctype;
  documentList: doctype[] = [];
  validate_5kUser = false;
  selectedAccount;
  tradeId;
  
  selectedApprovalData: any;
  dataToBeDisplayed;
  vasTypeDataObject = {};
  transactionType;
  tabData;
  otherData = {
    accountNumber: '',
    minCreditAmount: undefined,
    beneficiary: undefined,
    mobileNumber: undefined,
    referenceId: undefined,
  };
  initiatedBy = '';
  config = environment.config;
  approvalStatusType = '';
  hideList = false;
  remarkModal = {
    inputHeader: '',
  };
  isRequired = true;
  singleApprove = this.config.singleApprove;
  // preInitiateDataPayload;
  constructor(
    private dataSharingService: DataSharingService,
    private store: Store<any>,
    private prepaidUtilities: PrepaidUtilitiesService,
    private smeService: SmeServiceService,
    private router: Router,
    private transactionService: TransactionService,
    private snack: SnackBarService,
    private deviceDetectorService: DeviceDetectorService,
    private http: HttpClient,
    private serviceRequestService: ServiceRequestService,
    private bankingMetadata: BankingMetadataService,
    private makeCsv: MakeCsvService,
    private paymentUtilitiesService: PaymentUtilitiesService,
    private accountsIterator: AccountsIteratorService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.deviceDetectorService.onResize$.pipe(delay(0)).subscribe((val) => {
      this.screenSize = val;
    });
    window['inner']('other');

    // this.storeState = store.getState();
    this.window = this.document.defaultView;

    window['inner']('other');
  }

  ngOnInit() {
    this.store
      .select('appReducer', 'approvalReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stateData) => {
        this.tabData = stateData.selectedApprovalTab;
        this.selectedApprovalData = stateData.selectedApprovalData;
        if (
          this.selectedApprovalData.data &&
          this.selectedApprovalData.data.financialTransaction &&
          this.selectedApprovalData.data.financialTransaction.type === 'ONCE_OFF_INTERNATIONAL_PAYMENT'
        ) {
          this.bopCodes = this.paymentUtilitiesService.getPurposeCode(this.selectedApprovalData.data);
        }
        this.setHeadPaginationData(this.selectedApprovalData.type);
        if (this.selectedApprovalData.data && this.selectedApprovalData.data.financialTransaction) {
          const approverDataRef = this.selectedApprovalData.data.financialTransaction.fromAccount.number;
          this.selectedAccount = this.accountsIterator.getAccountFromNumber(approverDataRef);
        }
        this.initiatedBy = this.selectedApprovalData.data.initiatedBy;
      })
      .unsubscribe();

    // this.dataSharingService
    //   .getBopCode()
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe((bopCodeData) => {
    //     if (bopCodeData && this.config.localCurrency === 'ZMW') {
    //       this.bopCodes = bopCodeData;
    //     } else {
    //       this.bopCodes = {};
    //     }
    //   });

    if (this.config.trade === 'mvp1' && this.transactionType === 'ONCE_OFF_INTERNATIONAL_PAYMENT') {
      const valueValidatePayload = {
        amount: {
          amount: this.dataToBeDisplayed.amount.amount,
          currency: this.dataToBeDisplayed.amount.currency,
        },
      };

      if (this.config.countryName === 'Zambia' || this.config.countryName === 'Namibia' ) {
        if(!this.dataToBeDisplayed.financialTransaction.cmaCountry){
        this.bankingMetadata
          .getValueValidations(valueValidatePayload)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((data) => {
            if (data.body.documentUploadRequired) {
              this.validate_5kUser = true;
            }
            if (this.validate_5kUser) {
              const documentpayload = {
                transactionId: this.dataToBeDisplayed.referenceId || this.otherData.referenceId,
              };
              const preInitiateDataPayload = {
                ...this.selectedApprovalData.data,
                ...{ indicativeRateRequired: data.body.indicativeRateRequired, convertedAmount: data.body.convertedAmount },
              };
              this.setPayloadIpay(preInitiateDataPayload);
              this.smeService
                .getDocumentLists(documentpayload)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((data) => {
                  if (data['body']) {
                    this.documentList = data['body'].internationalPaymentDocuments;
                  }
                });
              if (this.validate_5kUser && this.documentList.length === 0) {
                this.disableRightButton = false;
              }
            }
          });
        }else{
          this.disableRightButton = false;
        }
      } else {
        this.setPayload(this.selectedApprovalData.data);
        if(!this.dataToBeDisplayed.financialTransaction.cmaCountry){
        this.bankingMetadata
          .getValueValidations(valueValidatePayload)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((data) => {
            if (data.body.documentUploadRequired) {
              this.validate_5kUser = true;
            }
            if (this.validate_5kUser) {
              const documentpayload = {
                transactionId: this.dataToBeDisplayed.referenceId || this.otherData.referenceId,
              };
              this.smeService
                .getDocumentLists(documentpayload)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((data) => {
                  if (data['body']) {
                    this.documentList = data['body'].internationalPaymentDocuments;
                  }
                });
              if (this.validate_5kUser && this.documentList.length === 0) {
                this.disableRightButton = false;
              }
            }
          });
        }else{
          this.disableRightButton = false;
        }
      }
    }
  }

  setPayload(dataforPayload) {
    const payload = {
      baseBeneficiaryPayment: {
        transactionId: dataforPayload.financialTransaction.referenceId,
        type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
        amount: {
          amount: dataforPayload.financialTransaction.amount.amount,
          currency: dataforPayload.financialTransaction.amount.currency,
        },
        beneficiary: {
          name: dataforPayload.financialTransaction.toBeneficiary.name,
          address: {
            addressLine1: dataforPayload.financialTransaction.toBeneficiary.address.addressLine1,
          },
          accountNumber: dataforPayload.financialTransaction.toBeneficiary.accountNumber,
          recipientReference: dataforPayload.financialTransaction.toBeneficiary.recipientReference,
          customerReference: dataforPayload.financialTransaction.toBeneficiary.customerReference,
          route: dataforPayload.financialTransaction.toBeneficiary.route,
          keyValueMetadata: [
            {
              key: 'FEB_ACCOUNT_TYPE_CODE',
              value: 'SBA',
            },
          ],
        },
      },
      accountFrom: {
        number: dataforPayload.financialTransaction.fromAccount.number,
        accountCurrency: {
          currencyCode: dataforPayload.financialTransaction.accountCurrencyTransactionAmount.currency,
        },
        availableBalance: {
          amount: this.selectedAccount[0].availableBalance.amount,
          currency: this.selectedAccount[0].availableBalance.currency,
        },
        route: {
          routingNumber: dataforPayload.financialTransaction.toBeneficiary.route.routingNumber,
        },
        keyValueMetadata: [
          {
            key: 'FEB_ACCOUNT_TYPE_CODE',
            value: 'SBA',
          },
        ],
      },
      reasonForTransaction: {
        key: 'OFT',
        value: '',
      },
      sourceOfFunds: {
        key: 'S',
        value: '',
      },
      documentReference: 'OFL',
      autoSave: false,
    };
    if(!this.dataToBeDisplayed.financialTransaction.cmaCountry){
    this.bankingMetadata
      .internationalPaymentPreInitialisation(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response) {
          this.tradeId = response.body.preInitiatedData.metroTradeId;
        }
      });
    }
  }

  setPayloadIpay(dataforPayload) {
    const payload = {
      indicativeRateRequired: dataforPayload.indicativeRateRequired,
      convertedAmount: dataforPayload.convertedAmount,
      baseBeneficiaryPayment: {
        transactionId: dataforPayload.financialTransaction.referenceId,
        type: 'ONCE_OFF_INTERNATIONAL_PAYMENT',
        amount: {
          amount: dataforPayload.financialTransaction.amount.amount,
          currency: dataforPayload.financialTransaction.amount.currency,
        },
        beneficiary: {
          name: dataforPayload.financialTransaction.toBeneficiary.name,
          address: {
            addressLine1: dataforPayload.financialTransaction.toBeneficiary.address.addressLine1,
          },
          accountNumber: dataforPayload.financialTransaction.toBeneficiary.accountNumber,
          recipientReference: dataforPayload.financialTransaction.toBeneficiary.recipientReference,
          customerReference: dataforPayload.financialTransaction.toBeneficiary.customerReference,
          route: dataforPayload.financialTransaction.toBeneficiary.route,
          keyValueMetadata: [
            {
              key: 'FEB_ACCOUNT_TYPE_CODE',
              value: 'SBA',
            },
          ],
        },
      },
      accountFrom: {
        number: dataforPayload.financialTransaction.fromAccount.number,
        accountCurrency: {
          currencyCode: dataforPayload.financialTransaction.accountCurrencyTransactionAmount.currency,
        },
        availableBalance: {
          amount: this.selectedAccount[0].availableBalance.amount,
          currency: this.selectedAccount[0].availableBalance.currency,
        },
        route: {
          routingNumber: dataforPayload.financialTransaction.toBeneficiary.route.routingNumber,
        },
        keyValueMetadata: [
          {
            key: 'FEB_ACCOUNT_TYPE_CODE',
            value: 'SBA',
          },
        ],
      },
      reasonForTransaction: {
        key: 'OFT',
        value: '',
      },
      sourceOfFunds: {
        key: 'S',
        value: '',
      },
      documentReference: 'OFL',
      autoSave: false,
    };
    if(!this.dataToBeDisplayed.financialTransaction.cmaCountry){
    this.bankingMetadata
      .internationalPaymentPreInitialisation(payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response) {
          this.tradeId = response.body.preInitiatedData.metroTradeId;
        }
      });
    }
  }
  filterDoclistbyId(docId) {
    if (this.documentList.length > 0) {
      return this.documentList.filter((item) => {
        return item.documentId === docId;
      });
    }
  }
  getFilenamebyDocType(key): string {
    let result;
    this.config.InternationalPaymentDocumentTypes.forEach((data) => {
      if (data.key === key) {
        result = data.value;
      }
    });
    return result;
  }
  setHeadPaginationData(type) {
    let mainTitle = '';
    switch (type) {
      case 'financialTransactions':
        mainTitle = 'Financial Transaction Details';
        this.dataToBeDisplayed = {
          ...this.selectedApprovalData.data,
          ...this.selectedApprovalData.data.financialTransaction,
        };
        if (this.dataToBeDisplayed.vasCustomerReferenceNumber) {
          this.vasTypeDataObject = this.prepaidUtilities.structurePrepaidDataForApprovalList(
            this.dataToBeDisplayed.type,
            this.dataToBeDisplayed.vasServiceProvider.providerMode
          );
        }
        // this.otherData.beneficiary = this.dataToBeDisplayed.beneficiary || this.dataToBeDisplayed.biller;
        if (this.dataToBeDisplayed.toBeneficiary) {
          this.dataToBeDisplayed.beneficiary = this.dataToBeDisplayed.toBeneficiary;
        }
        this.otherData.mobileNumber =
          (this.dataToBeDisplayed.beneficiary && this.dataToBeDisplayed.beneficiary.phoneNumber) ||
          (this.dataToBeDisplayed.biller && this.dataToBeDisplayed.biller.phoneNumber) ||
          (this.dataToBeDisplayed.instantMoneyReceipt && this.dataToBeDisplayed.instantMoneyReceipt.mobileNumber);
        this.otherData.accountNumber = undefined;
        this.dataSharingService.setHeadPagination({
          mainTitle,
          subTitle: '',
          showBack: true,
          showBackFn: this.goBack.bind(this),
          showClose: true,
          steps: true,
          module: 'approvals',
        });
        this.transactionType = this.dataToBeDisplayed.type || this.dataToBeDisplayed.transactionType;
        break;
      case 'beneficiaryManagementApprovals':
      case 'billerManagementApprovals':
      case 'servicerequestApprovals':
      case 'updateReinvestmentDetailsApprovals':
        mainTitle = 'Non Financial Transaction Details';

        this.dataToBeDisplayed = { ...this.selectedApprovalData.data };
        this.dataToBeDisplayed.servicerequest = this.selectedApprovalData.data.servicerequest || this.selectedApprovalData.data.depositAccountDetails;
        // this.dataToBeDisplayed = {...this.selectedApprovalData.data, ...this.selectedApprovalData.data.servicerequest,
        //   ...this.selectedApprovalData.data.depositAccountDetails};
        if (this.dataToBeDisplayed.beneficiary) {
          this.otherData.accountNumber = this.dataToBeDisplayed.beneficiary.accountNumber;
          this.otherData.mobileNumber =
            (this.dataToBeDisplayed.beneficiary && this.dataToBeDisplayed.beneficiary.phoneNumber) ||
            (this.dataToBeDisplayed.biller && this.dataToBeDisplayed.biller.phoneNumber);
        }
        this.dataSharingService.setHeadPagination({
          mainTitle,
          subTitle: '',
          showBack: true,
          showBackFn: this.goBack.bind(this),
          showClose: true,
          steps: true,
          module: 'approvals',
        });
        this.transactionType = this.dataToBeDisplayed.type || this.dataToBeDisplayed.transactionType;
        if (this.dataToBeDisplayed.biller) {
          this.otherData.accountNumber = this.dataToBeDisplayed.biller.accountNumber;
          break;
        }

        if (this.selectedApprovalData.data.servicerequest) {
          if (this.transactionType === 'EAP_LIMIT_MANAGEMENT') {
            this.getServiceRequestDetails(this.selectedApprovalData.data.servicerequest.referenceId);
            // let serviceRequestDetails = this.getServiceRequestDetails(this.selectedApprovalData.data.servicerequest.referenceId);
            // console.log('After serviceRequestDetails: ', serviceRequestDetails);
          }
          const tType = this.selectedApprovalData.data.transactionType;
          if (tType === 'OPEN_NOTICE_DEPOSIT_ACCOUNT' || tType === 'OPEN_CALL_DEPOSIT_ACCOUNT' || tType === 'OPEN_CURRENT_ACCOUNT') {
            const key = this.prepaidUtilities.getAccountCreditAmount(tType);
            this.otherData.minCreditAmount = this.config[key];
          }
        }
        if (this.dataToBeDisplayed.servicerequest && this.dataToBeDisplayed.servicerequest.referenceId) {
          this.otherData.referenceId = this.dataToBeDisplayed.servicerequest.referenceId;
        } else if (this.dataToBeDisplayed.approvalReferenceId) {
          this.otherData.referenceId = this.dataToBeDisplayed.approvalReferenceId;
        }

        break;
      case 'bulkFile':
        mainTitle = 'Bulk Upload Details';
        this.dataToBeDisplayed = { ...this.selectedApprovalData.data };
        this.otherData.accountNumber = this.dataToBeDisplayed.accountNumber;
        this.otherData.referenceId = this.dataToBeDisplayed.document.id;
        this.dataSharingService.setHeadPagination({
          mainTitle,
          subTitle: '',
          showBack: true,
          showBackFn: this.goBack.bind(this),
          showClose: true,
          steps: true,
          module: 'approvals',
        });
        this.transactionType = this.dataToBeDisplayed.type || this.dataToBeDisplayed.transactionType;
        break;
      default:
        mainTitle = 'Approval Details';
        break;
    }

    if (!this.initiatedBy) {
      this.disableRightButton = false;
      if (this.tabData && this.tabData.selectedTab === 0) {
        let processingDate;
        if (this.tabData.selectedSubTab === 0) {
          processingDate = this.removeOffset(this.selectedApprovalData.data.financialTransaction.transactionDate);
          this.requestDate = this.removeOffset(this.selectedApprovalData.data.financialTransaction.requestDate);
        } else if (this.tabData.selectedSubTab === 1) {
          processingDate = this.selectedApprovalData.data.processingDate && this.removeOffset(this.selectedApprovalData.data.processingDate);
          this.requestDate = this.removeOffset(this.selectedApprovalData.data.uploadDate);
        } else if (this.tabData.selectedSubTab === 2) {
          this.requestDate = this.removeOffset(this.selectedApprovalData.requestDate);
        }

        this.currentDate = DateUtil.format(this.todayDate, null, this.config.timeOffsetInHolidays);
        
        if (processingDate) {
          this.approveDate = DateUtil.format(processingDate, null, this.config.timeOffsetInHolidays);
        }

        this.test = DateUtil.areSame(this.approveDate, this.currentDate, 'days');
        if (this.test) {
          this.activeApproveButton = false;
        } else if (this.approveDate) {
          this.activeApproveButton = DateUtil.isBefore(this.approveDate, this.currentDate);
        }

        if (this.activeApproveButton && this.config.localCurrency !== 'SZL') {
          this.disableRightButton = true;
        } else {
          this.disableRightButton = false;
        }
      }
    }
  }

  getServiceRequestDetails(refId) {
    const payload = {
      details: {
        referenceId: refId,
        type: {
          key: 'EAP',
        },
      },
    };

    this.serviceRequestService.getIndividualServiceRequests(payload).subscribe((response: any) => {
      if (response.statusText === 'OK') {
        this.dataToBeDisplayed = {
          ...this.dataToBeDisplayed,
          ...response.body,
        };
      }
    });
  }
  approvalOptionClicked(type) {
    this.approvalStatusType = type;
    // if single approval and if it is international payment then fo to ip approval screen
    if (
      this.selectedApprovalData.data &&
      this.selectedApprovalData.data.financialTransaction &&
      this.selectedApprovalData.data.financialTransaction.type === 'ONCE_OFF_INTERNATIONAL_PAYMENT'
    ) {
      this.store.dispatch(setSelectedApprovalType({ selectedApprovalType: type }));
      this.store.dispatch(setSelectedApprovalData({ selectedApprovalData: this.selectedApprovalData.data }));
      this.router.navigate(['/approvals/international-payment-approval']);
    } else {
      this.hideList = type === 'REJECTED' || type === 'APPROVED';
      this.isRequired = type === 'REJECTED';
      if (!this.hideList) {
        this.approvalAction('');
      }
    }
  }

  removeOffset(date) {
    return DateUtil.format(date, DateUtil.ISO_DD_MMMM_YYYY, this.config.timeOffsetInHolidays);
  }
  // function will come when we add xls and docx file in future.
  createFileWithExtensions(name, mimeType): string {
    const splitFile = mimeType.split('/');
    let extension = '';
    if (splitFile[1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      extension = 'xlsx';
    } else if (splitFile[1] === 'vnd.ms-excel') {
      extension = 'xls';
    } else {
      extension = splitFile[1];
    }
    return name + '.' + extension;
  }
  callFilebase64(documentID) {
    const docDetails = this.filterDoclistbyId(documentID);

    const documentDetails = {
      document: {
        documentId: docDetails[0]['documentId'],
        transactionId: this.dataToBeDisplayed.referenceId || this.otherData.referenceId,
        tradeId: this.tradeId,
      },
    };
    if(!this.dataToBeDisplayed.financialTransaction.cmaCountry){
    this.smeService.documentDownloadFile(documentDetails).subscribe((data) => {
      if (data) {
        const base64String = data['body'].document.encodedData;
        const url = `data:${docDetails[0]['mimeType']};base64,${base64String}`;
        // const filename = this.createFileWithExtensions(docDetails[0]['name'], docDetails[0]['mimeType']);
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            // const file = new File([blob], docDetails[0]['name'], { type: docDetails[0]['mimeType'] });
            // console.log(file);
            // let link = this.window.document.createElement('a');
            const url = this.window.URL.createObjectURL(blob, { type: docDetails[0]['mimeType'] });
            // link.download = docDetails[0]['name'];
            // document.body.appendChild(link);
            this.window.open(url, '_blank').focus();
            // document.body.removeChild(link);
          });
      }
    });
  }
  }
  
  goBack() {
    this.router.navigate(['/approvals']);
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  remarkChanged() {}
  approvalAction(remarks) {
    let payload = {
      approvalStatusType: this.approvalStatusType,
    };

    const approvals = [
      {
        approvalRecordId: this.prepaidUtilities.getRecordId(this.selectedApprovalData.data),
        approvalTransactionType: this.transactionType,
      },
    ];   
      if (this.singleApprove) {
        payload = { ...payload, ...approvals[0], ...{ keyValueMetadata: [] } };
      } else {
        payload = { ...payload, ...{ approvals } };
      }    

    let action;
    if (this.selectedApprovalData.data.financialTransaction && this.selectedApprovalData.data.financialTransaction.action) {
      action = this.selectedApprovalData.data.financialTransaction.action;
      payload = { ...payload, ...{ action } };
    }

    if (remarks.trim()) {
      payload = { ...payload, ...{ remarks } };
    }
    if (this.transactionType === 'FIXED_TERM_INVESTMENT_INSTRUCTION') {
      const accountId = this.selectedApprovalData.data.depositAccountDetails.number;
      payload = { ...payload, ...{ accountId } };
    }
    this.postService(payload);
    this.dataSharingService.setPostService(this.postService.bind(this));
  }
  postService(payload) {
    return this.smeService.approval(payload).subscribe((response: any) => {
      switch (this.approvalStatusType) {
        case 'REJECTED':
        case 'RECALLED':{
          this.router.navigate(['/approvals']);
          const responseCode = this.transactionService.getResponseData(response);

          this.snack.open(responseCode.message, responseCode.type);
          this.hideList = false;

          break;}
        case 'APPROVED':
          this.store.dispatch(setPostApprovalData({ approvalPayload: payload, postApprovalData: response }));
          this.router.navigate(['/approvals/approval-receipt']);
          break;
      }
    });
  }

  downloadFile(data) {
    const payload = {
      document: {
        id: data.document.id,
      },
    };
    if (data.transactionType === 'BULK_INSTANT_MONEY') {
      this.smeService.downloadBulkInstantFile(payload).subscribe((response: any) => {
        const csvData = response.body.bulkInstantMoney;
        const fileData = {
          columns: {},
          data: {},
        };
        const rowData = [];
        fileData.columns = [
          {
            title: 'Mobile Number',
            key: 'mobileNumber',
          },
          {
            title: 'Amount',
            key: 'amount',
          },
        ];

        for (let i = 0; i < csvData.length; i++) {
          const obj = {
            mobileNumber: '',
            amount: '',
          };
          obj.mobileNumber = csvData[i].mobileNumber;
          obj.amount = csvData[i].amount.amount;
          rowData.push(obj);
        }

        fileData.data = rowData;
        const COLUMN_MAP = ['mobileNumber', 'amount'];
        const csvBlob = this.makeCsv.export(fileData, COLUMN_MAP);
        saveAs(csvBlob, data.document.name);
      });
    } else {
      const mediaType = 'text/csv';
      this.http.post('/roa-mobile/rest/SmeService/DownloadDocument', payload, { responseType: 'blob' }).subscribe(
        (response) => {
          const blob = new Blob([response], { type: mediaType });
          saveAs(blob, data.document.name);
        },
        (e) => {
          console.error(e);
        }
      );
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
