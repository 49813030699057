import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { setSelectedBuy } from '../../shared/store-utilities/actions/buy.action';
import { PrepaidService } from '../../core/prepaid-service/prepaid-service.service';
import { setProviderDetails } from '../../shared/store-utilities/actions/buy.action';
import { environment } from '../../../environments/environment';
import { Modules } from '../../core/masking-service/masking-constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BuyTabRoutes } from './prepaid-models.module';
import { MaskingService } from '../../core/masking-service/masking.service';

@Component({
  selector: 'sbg-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
})
export class BuyComponent implements OnInit, OnDestroy {
  isSmeMode = false;
  config = environment.config;
  mobileView = false;
  isListPage = false;
  selectedTab = 0;
  destroyed$ = new Subject<boolean>();

  listOfTabs = [
    {
      feature: BuyTabRoutes.AIRTIME,
      name: 'Airtime',
      onClickNavFunction: (index) => this.goToTargetModule(index, './buy/airtime/list'),
    },
    {
      feature: BuyTabRoutes.DATA,
      name: 'Data',
      onClickNavFunction: (index) => this.goToTargetModule(index, './buy/data/list'),
    },
    {
      feature: BuyTabRoutes.TV_SUBSCRIPTION,
      name: 'TV Subscription',
      onClickNavFunction: (index) => this.goToTargetModule(index, './buy/tvsubscription/list'),
    },
    {
      feature: BuyTabRoutes.ELECTRICITY,
      name: 'Prepaid Electricity',
      onClickNavFunction: (index) => this.goToTargetModule(index, './buy/electricity/list'),
    },
    {
      feature: BuyTabRoutes.WATER,
      name: 'Water',
      onClickNavFunction: (index) => this.goToTargetModule(index, './buy/water/list'),
    },
    {
      feature: BuyTabRoutes.RATESANDTAXES,
      name: 'Rates And Taxes',
      onClickNavFunction: (index) => this.goToTargetModule(index, './buy/ratesandtaxes/list'),
    },
  ];

  constructor(private router: Router,
              private store: Store<{ appReducer }>,
              private prepaidService: PrepaidService,
              private maskingService: MaskingService) {
                window['inner']('other');
                this.listOfTabs = this.maskingService.removeMaskedFeatures(this.listOfTabs, Modules.BUY);
    router.events.pipe(takeUntil(this.destroyed$))
      .subscribe((event) => {
      this.isListPage = (event instanceof NavigationEnd && event.url.indexOf('list') >= 0);
      if (this.isListPage) {
        const activeTabName = (event as NavigationEnd).url.match(/buy\/(\w+)/)[1];
        this.setSelectedIndex(this.listOfTabs.findIndex((tab) => tab.feature === activeTabName));
      }
    });
    window['inner']('other');
  }

  ngOnInit() {
    this.loadPrepaidProvidersToStore();
    this.store
      .select('appReducer', 'buyReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.selectedTab = value.selectedIndex ? value.selectedIndex : this.selectedTab;
      });
  }

  goToTargetModule(index: number, routeUrl: string) {
    this.setSelectedIndex(index);
    return this.router.navigate([routeUrl]);
  }

  setSelectedIndex(index) {
    this.selectedTab = index;
    this.store.dispatch(setSelectedBuy({ selectedIndex: this.selectedTab }));
  }

  loadPrepaidProvidersToStore() {
    let providerDetails: Array<{}>;
    this.store
      .select('appReducer', 'buyReducer')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        providerDetails = value.providerDetails;
      });

    if (providerDetails.length === 0) {
      this.prepaidService
        .getProviderDetails()
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.store.dispatch(
            setProviderDetails({
              providerDetails: response.prepaidProviders,
            })
          );
        });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
