import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild, ViewEncapsulation, Renderer2 } from '@angular/core';

import { NameInitialsService } from '../../../../core/name-initials/name-initials.service';
import { Store } from '@ngrx/store';
export interface Groups {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'sbg-list-table',
  templateUrl: './list-table.component.html',
  styleUrls: ['./list-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListTableComponent implements OnInit, AfterViewInit {
  

  @ViewChild('listTableIdentifier') listTableIdentifier: ElementRef<HTMLInputElement>;

  @Input() recipients: object[];
  @Input() selectButtonType: string; // is it radio or checkbox
  @Input() nameInitialKey: string; // initial to be displayed in list
  @Input() nameSubText: string; // subtxt to be displayed in list
  @Input() addItemText: string;
  @Input() listType: string; // type of list items like Beneficiary or biller or prepaid
  @Input() selectPromptMessage: string;
  @Input() isAddItemRequired: boolean;
  @Input() navigateGroups: boolean;
  @Input() isInfoIconRequired: boolean;
  @Input() enableOnceOffPay: boolean;
  @Input() isSearchNeeded: boolean;
  @Input() dropDownData: Groups;
  @Input() filterOpt: boolean;

  @Output() clickInfoFunction = new EventEmitter<any>();
  @Output() addItemFunction = new EventEmitter<any>();
  @Output() goToPayFunction = new EventEmitter<any>();
  @Output() groupChanged = new EventEmitter<any>();

  searchText: string;
  selected = [];
  selectItem;
  selectedGroup;
  onceOffBarWidth;
  panelOpenState = false;
  selectedRecipientName: string;
  filteredList;
  isSmeMode = false;
  constructor(private nameInitialsService: NameInitialsService, private render: Renderer2, private store: Store<{ appReducer }>) {}

  ngOnInit() {
    this.selectItem = this.selectButtonType === 'checkbox' ? this.selectCheckboxItems : this.selectRadioItems;
    this.store.select('appReducer', 'loginReducer').subscribe((value) => {
      this.isSmeMode = value.isSmeMode;
    });
  }
  ngAfterViewInit() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const s = this.render.selectRootElement('#listTableIdentifier');
    setTimeout(() => {
      this.onceOffBarWidth = document.getElementById('listTableIdentifier').offsetWidth + 'px';
    });
  }

  getNameInitials(name): string {
    return this.nameInitialsService.getInitials(name);
  }
  inputChanged(str) {
    this.searchText = str;
  }
  getInfo(recipient) {
    this.clickInfoFunction.emit(recipient);
  }
  addItemToListFunction() {
    this.addItemFunction.emit(event);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  goToPay(event) {
    this.goToPayFunction.emit(this.selected);
  }
  setElementWidth() {
    return {
      width: this.onceOffBarWidth,
    };
  }
  groupChangedFn() {
    this.groupChanged.emit(this.selectedGroup);
  }
  private selectCheckboxItems(item) {
    if (this.selected.indexOf(item) !== -1) {
      this.selected.splice(this.selected.indexOf(item), 1);
    } else {
      this.selected.push(item);
    }
    this.getPayButtonText();
  }
  
  private isPresentInSelectedList(item) {
    return this.selected.indexOf(item) !== -1;
  }

  private selectRadioItems(item) {
    const inut = document.querySelector('input[type="radio"]:checked') as HTMLInputElement;
    if (this.isPresentInSelectedList(item)) {
      inut.checked = false;
      this.selected = [];
    } else {
      this.selected = [];
      this.selected.push(item);
    }
    this.getPayButtonText();
  }

  private getPayButtonText() {
    if (this.selected.length === 0) {
      this.selectedRecipientName = 'Once-off ' + this.listType;
    } else {
      this.selectedRecipientName =
        this.selected.length > 1 ? 'Pay ' + this.selected.length + ' selected' : 'Pay ' + this.selected[0][this.nameInitialKey];
    }
  }
  
}
