import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../../core/data-sharing/data-sharing.service';
import { ServiceRequestService } from '../../../core/service-request/service-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsIteratorService } from '../../../core/accounts-iterator/accounts-iterator.service';
import { Store } from '@ngrx/store';
import { DateUtil } from '../../../core/utility-classes/date.util';

@Component({
  selector: 'app-service-request-result',
  templateUrl: './service-request-result.component.html',
  styleUrls: ['./service-request-result.component.scss'],
})
export class ServiceRequestResultComponent implements OnInit {
  serviceRequestResultData;
  date;
  maturityDate;
  constructor(
    private dataSharingService: DataSharingService,
    private serviceRequestService: ServiceRequestService,
    private router: Router,
    private store: Store<{ appReducer }>,
    private activatedRoute: ActivatedRoute,
    private accountsIterator: AccountsIteratorService
  ) {}

  ngOnInit() {
    const header = this.dataSharingService.getHeadPagination();
    this.dataSharingService.setHeadPagination({
      // [ ] : source is deprecated. need to find alternative
      // @ts-ignore
      mainTitle: header.source.value.mainTitle,
      subTitle: 'Result',
      showBack: false,
      showClose: true,
      steps: true,
      showBackFn: this.goBack.bind(this),
      module: 'newServiceRequest',
    });

    this.dataSharingService.getServiceRequestResultData().subscribe((value) => {
      this.serviceRequestResultData = value;
    });

    this.date = new Date();
    if (this.serviceRequestResultData.dataFromPreviousStep.renewalPeriod) {
      
      this.maturityDate = DateUtil.add(this.date, this.serviceRequestResultData.dataFromPreviousStep.renewalPeriod, 'months');
    }
  }

  goToNewRequest() {
    this.router.navigate(['./newServiceRequest'], {
      relativeTo: this.activatedRoute.parent,
    });
  }

  goBack() {
    this.router.navigate(['/serviceRequests/newServiceRequest']);
  }
}
